import { ChangeEvent } from "react"
import Checkbox from "@mui/material/Checkbox"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { Box } from "@mui/material"

type CheckboxProps = {
    id: string
    name: string
    disabled?: boolean | undefined
    checked: boolean
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    text?: string | undefined
}

const CheckboxField = ({ id, name, disabled, checked, onChange, text }: CheckboxProps) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="nowrap"
            width={"100%"}>
            <Checkbox
                id={id}
                name={name}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
            />
            <Box>
                <Typography variant="subtitle2" sx={{ paddingLeft: "8px" }}>
                    {text}
                </Typography>
            </Box>
        </Grid>
    )
}

export default CheckboxField
