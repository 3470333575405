import { Select } from "@mui/material"
import Grid from "@mui/material/Grid"
import { CurrencyCodeRecord } from "currency-codes"
import { ReactNode, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useCurrencyMap } from "src/utils/currencies"
import { FieldLabel, StyledMenuItem } from "../styles"

export const CurrencySelect = ({
    onChange,
    defaultValue,
    label,
    error,
}: {
    onChange: (x: CurrencyCodeRecord) => void
    defaultValue?: CurrencyCodeRecord
    label?: ReactNode
    error?: boolean
}) => {
    const currencies = useCurrencyMap()
    const [selected, setSelected] = useState<CurrencyCodeRecord | undefined>(defaultValue)

    const placeHolder = <FormattedMessage id="select_currency" />

    return (
        <Grid container direction="column" sx={{ width: "100%" }}>
            {label ? (
                <Grid>
                    <FieldLabel>{label}</FieldLabel>
                </Grid>
            ) : null}
            <Select
                error={error}
                value={selected?.code || ""}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return placeHolder
                    }

                    return selected
                }}
                displayEmpty
                onChange={(e) => {
                    const x = e.target.value
                    if (x in currencies) {
                        const selected = currencies[x]
                        setSelected(selected)
                        onChange(selected)
                    }
                }}>
                <StyledMenuItem disabled value="">
                    {placeHolder}
                </StyledMenuItem>
                {Object.keys(currencies).map((code) => (
                    <StyledMenuItem key={code} value={code}>
                        {code}
                    </StyledMenuItem>
                ))}
            </Select>
        </Grid>
    )
}
