import { Box, SelectChangeEvent, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import CheckboxField from "src/components/generalComponents/CheckboxField"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import InputField from "src/components/InputField"
import Page from "src/components/layout/Page"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import StyledForm from "src/components/layout/StyledForm"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import useCountryList from "src/utils/countries"
import BottomBanner from "src/components/layout/BottomBanner"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { Address } from "@repo/rezip-client/types"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { useClient } from "src/hooks/useClient.hook"

interface DropDointAccount {
    name: string
    billing_address: Address
    shipping_address: Address
    billing_email: string | null
    shipping_email: string | null
}

const Account = () => {
    const [account, setAccount] = useState<DropDointAccount>()
    const { getSelectedAgreement } = useUser()
    const { dropPointClient } = useClient()
    const countries = useCountryList()
    const [billingCountry, setBillingCountry] = useState<IMenuItemData>(countries[0])
    const [shippingCountry, setShippingCountry] = useState<IMenuItemData>(countries[0])
    const [sameAs, setSameAs] = useState(false)
    const agreement = getSelectedAgreement()
    const intl = useIntl()
    const { enqueueAlert } = useSnackbar()
    const { BlockerAlert, setBlock } = useCustomBlocker()

    const toggleSameAs = useCallback(() => {
        if (!sameAs) {
            setSameAs(true)
            setAccount({
                ...account,
                shipping_address: account?.billing_address,
                shipping_email: account?.billing_email,
            } as DropDointAccount)
        } else {
            setSameAs(false)
        }
    }, [account, sameAs])

    const onBiliingCountryChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const item = countries.find((item) => item.id === event.target.value)
            if (item) {
                setBillingCountry(item)
                if (sameAs) {
                    setShippingCountry(item)
                    setBlock(true)
                }
            }
        },
        [countries, sameAs, setBlock],
    )
    const onShippingCountryChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const item = countries.find((item) => item.id === event.target.value)
            if (item) {
                setShippingCountry(item)
                setBlock(true)
            }
        },
        [countries, setBlock],
    )

    const updateAccount = useCallback(async () => {
        if (!account || !agreement) {
            return
        }
        try {
            await dropPointClient.updateDropPoint(agreement.account.id, {
                ...account,
                billing_address: { ...account.billing_address, country: billingCountry.id },
                shipping_address: { ...account.shipping_address, country: shippingCountry.id },
            })
            enqueueAlert(intl.formatMessage({ id: "droppoint_account_updated" }), "success")
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
            enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "warning")
        }
    }, [
        account,
        agreement,
        billingCountry.id,
        enqueueAlert,
        intl,
        shippingCountry.id,
        dropPointClient,
    ])

    useEffect(() => {
        if (!account && agreement) {
            const fetchAccount = async () => {
                if (!agreement) {
                    return
                }
                const account = await dropPointClient.getDropPoint(agreement.account.id)
                if (!account) {
                    return
                }
                setAccount(account)
                const billingCountry = countries.find(
                    (item) => item.id === account.billing_address.country,
                )
                const shippingCountry = countries.find(
                    (item) => item.id === account.shipping_address.country,
                )

                if (billingCountry) {
                    setBillingCountry(billingCountry)
                }
                if (shippingCountry) {
                    setShippingCountry(shippingCountry)
                }
                if (account.shipping_address.country === null) {
                    setAccount({
                        ...account,
                        shipping_address: account.billing_address,
                        shipping_email: account.billing_email,
                    })
                    setSameAs(true)
                }
            }
            fetchAccount()
        }
    }, [account, agreement, countries, toggleSameAs, dropPointClient])

    const updateShippingAddress = useCallback(
        (value: string, target: keyof Address) => {
            if (account) {
                const updatedShippingAddress = {
                    ...(account.shipping_address ?? {}),
                    [target]: value,
                }
                setAccount({
                    ...account,
                    shipping_address: updatedShippingAddress,
                } as DropDointAccount)
                setBlock(true)
            }
        },
        [account, setBlock],
    )

    const updateBillingAddress = useCallback(
        (value: string, target: keyof Address) => {
            if (account) {
                const updatedBillingAddress = {
                    ...(account.billing_address ?? {}),
                    [target]: value,
                }
                setAccount({
                    ...account,
                    billing_address: updatedBillingAddress,
                } as DropDointAccount)
                setBlock(true)
                if (sameAs) {
                    updateShippingAddress(value, target)
                    setBlock(true)
                }
            }
        },
        [account, sameAs, setBlock, updateShippingAddress],
    )

    return (
        <Page title={intl.formatMessage({ id: "menu_listitem_header_account" })}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "24px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "24px" }}>
                    <DefaultWrapperPaper>
                        <StyledForm>
                            <InputField
                                label={intl.formatMessage({ id: "common_name" })}
                                id={"account_name"}
                                name={"account_name"}
                                type={"text"}
                                value={account?.name}
                                onChange={(e) =>
                                    setAccount({
                                        ...account,
                                        name: e.target.value,
                                    } as DropDointAccount)
                                }
                            />
                        </StyledForm>
                    </DefaultWrapperPaper>
                    <DefaultWrapperPaper
                        sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <Typography variant="h4">
                            <FormattedMessage id="billing_information" />
                        </Typography>
                        <StyledForm>
                            <InputField
                                label={intl.formatMessage({
                                    id: "invoice_email",
                                })}
                                type="text"
                                value={account?.billing_email ?? ""}
                                id={"billing_email"}
                                name={"billing_email"}
                                error={
                                    !account?.billing_email || account?.billing_email.length === 0
                                }
                                errorText={intl.formatMessage({ id: "error_text_invalid_email" })}
                                required
                                onChange={(e) =>
                                    setAccount({
                                        ...account,
                                        billing_email: e.target.value,
                                    } as DropDointAccount)
                                }
                            />

                            <InputField
                                label={intl.formatMessage({
                                    id: "street",
                                })}
                                type="text"
                                value={account?.billing_address?.street ?? ""}
                                id={"billing_street"}
                                name={"Billing_street"}
                                error={
                                    !account?.billing_address?.street ||
                                    account?.billing_address?.street.length === 0
                                }
                                errorText={intl.formatMessage({ id: "error_text_invalid_street" })}
                                required
                                onChange={(e) => updateBillingAddress(e.target.value, "street")}
                            />

                            <InputField
                                label={intl.formatMessage({
                                    id: "city",
                                })}
                                type="text"
                                value={account?.billing_address?.city ?? ""}
                                id={"billing_city"}
                                error={
                                    !account?.billing_address?.city ||
                                    account?.billing_address?.city.length === 0
                                }
                                errorText={intl.formatMessage({ id: "error_text_invalid_city" })}
                                name={""}
                                required
                                onChange={(e) => updateBillingAddress(e.target.value, "city")}
                            />

                            <InputField
                                label={intl.formatMessage({
                                    id: "postal_code",
                                })}
                                type="text"
                                value={account?.billing_address?.postal_code ?? ""}
                                id={"billing_postal_code"}
                                required
                                error={
                                    !account?.billing_address?.postal_code ||
                                    account?.billing_address?.postal_code.length === 0
                                }
                                errorText={intl.formatMessage({
                                    id: "error_text_invalid_postal_code",
                                })}
                                name={"billing_postal_code"}
                                onChange={(e) =>
                                    updateBillingAddress(e.target.value, "postal_code")
                                }
                            />

                            <InputField
                                label={intl.formatMessage({
                                    id: "region",
                                })}
                                type="text"
                                value={account?.billing_address?.region ?? ""}
                                id={"region"}
                                name={""}
                                onChange={(e) => updateBillingAddress(e.target.value, "region")}
                            />
                            <Box sx={{ display: "flex", minWidth: "100%" }}>
                                <SelectField
                                    label={intl.formatMessage({
                                        id: "country",
                                    })}
                                    required
                                    value={billingCountry.id}
                                    menuItems={countries}
                                    onChange={onBiliingCountryChange}
                                    large
                                />
                            </Box>
                        </StyledForm>
                    </DefaultWrapperPaper>
                </Box>
                <DefaultWrapperPaper
                    sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Typography variant="h4">
                        <FormattedMessage id="shipping_information" />
                    </Typography>
                    <StyledForm>
                        <CheckboxField
                            text={intl.formatMessage({
                                id: "shipping_as_billing",
                            })}
                            id={"same-as"}
                            name={"same-as"}
                            checked={sameAs}
                            onChange={toggleSameAs}
                        />

                        <InputField
                            id={"shipping_email"}
                            name={"shipping_email"}
                            label={intl.formatMessage({
                                id: "shipping_email",
                            })}
                            type={"text"}
                            required
                            disabled={sameAs}
                            error={
                                !sameAs &&
                                (!account?.shipping_email || account?.shipping_email.length === 0)
                            }
                            errorText={intl.formatMessage({
                                id: "error_text_invalid_email",
                            })}
                            value={
                                sameAs ? account?.billing_email : (account?.shipping_email ?? "")
                            }
                            onChange={(e) => {
                                setAccount({
                                    ...account,
                                    shipping_email: e.target.value,
                                } as DropDointAccount)
                                setBlock(true)
                            }}
                        />

                        <InputField
                            id={"shipping_street"}
                            name={"shipping_street"}
                            type={"text"}
                            error={
                                !account?.shipping_address?.street ||
                                account?.shipping_address?.street.length === 0
                            }
                            errorText={intl.formatMessage({
                                id: "error_text_invalid_street",
                            })}
                            label={intl.formatMessage({
                                id: "street",
                            })}
                            disabled={sameAs}
                            required
                            value={
                                sameAs
                                    ? account?.billing_address.street
                                    : (account?.shipping_address?.street ?? "")
                            }
                            onChange={(e) => updateShippingAddress(e.target.value, "street")}
                        />

                        <InputField
                            id={"shipping_city"}
                            name={"shipping_city"}
                            type={"text"}
                            error={
                                !sameAs &&
                                (!account?.shipping_address?.city ||
                                    account?.shipping_address?.city.length === 0)
                            }
                            errorText={intl.formatMessage({
                                id: "error_text_invalid_city",
                            })}
                            label={intl.formatMessage({
                                id: "city",
                            })}
                            disabled={sameAs}
                            required
                            value={account?.shipping_address?.city ?? ""}
                            onChange={(e) => updateShippingAddress(e.target.value, "city")}
                        />

                        <InputField
                            id={"shipping_postal_code"}
                            name={"shipping_postal_code"}
                            required
                            error={
                                !sameAs &&
                                (!account?.shipping_address?.postal_code ||
                                    account?.shipping_address?.postal_code.length === 0)
                            }
                            errorText={intl.formatMessage({
                                id: "error_text_invalid_postal_code",
                            })}
                            type={"text"}
                            label={intl.formatMessage({
                                id: "postal_code",
                            })}
                            disabled={sameAs}
                            value={
                                sameAs
                                    ? account?.billing_address.postal_code
                                    : (account?.shipping_address?.postal_code ?? "")
                            }
                            onChange={(e) => updateShippingAddress(e.target.value, "postal_code")}
                        />

                        <InputField
                            id={"shipping_region"}
                            name={"shipping_region"}
                            type={"text"}
                            label={intl.formatMessage({
                                id: "region",
                            })}
                            disabled={sameAs}
                            value={
                                sameAs
                                    ? account?.billing_address.region
                                    : (account?.shipping_address?.region ?? "")
                            }
                            onChange={(e) => updateShippingAddress(e.target.value, "region")}
                        />
                        <Box sx={{ display: "flex", minWidth: "100%" }}>
                            <SelectField
                                label={intl.formatMessage({
                                    id: "country",
                                })}
                                required
                                disabled={sameAs}
                                value={sameAs ? billingCountry.id : shippingCountry.id}
                                menuItems={countries}
                                onChange={onShippingCountryChange}
                                large
                            />
                        </Box>
                    </StyledForm>
                </DefaultWrapperPaper>
            </Box>

            <BottomBanner>
                <CustomButton onClick={updateAccount} text={"Save changes"} />
            </BottomBanner>

            <BlockerAlert />
        </Page>
    )
}

export default Account
