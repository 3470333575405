import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { styled } from "@mui/material"
import { JSX } from "react"

export interface IRow {
    id: string
    cells: Array<ICell>
}

export interface ICell {
    field: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
}

export interface TableHeader {
    label: string
    key: string
    exclude?: boolean
}

export interface NewCustomTableProps<T> {
    headers: TableHeader[]
    items: T[]
    itemsToRow: (item: T, index: number) => JSX.Element
    pageSize?: number
    onNextPage?: () => void
    placeholdertext?: string
}

const sidePadding = "24px"

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    height: "50px",
    border: `none`,
    alignContent: "center",
    whiteSpace: "nowrap",
    padding: "4px 16px",
    ...theme.applyStyles("dark", {
        fontWeight: "500",
    }),
    "&:first-of-type": {
        paddingLeft: sidePadding,
    },
    "&:last-of-type": {
        paddingRight: sidePadding,
    },
    "&:last-of-type button": {
        float: "right",
    },
    "& p.status": {
        color: "#FFFFFF",
        borderRadius: "100px",
        fontSize: "13px",
        textTransform: "uppercase",
        fontWeight: 800,
        maxWidth: "120px",
        textAlign: "center",
    },
    "& p.status.open": {
        backgroundColor: "#36b1fd",
    },
    "& p.status.fulfilled": {
        backgroundColor: "#1bab59",
    },
    "& p.status.invoiced": {
        backgroundColor: "#ffac0b",
    },
    "& p.status.paid": {
        backgroundColor: "#0042df",
    },
}))
export const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.inputField.background,
    "& th": {
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: "800",
    },
    "& th:first-of-type": {
        paddingLeft: sidePadding,
    },
    "& th:last-of-type": {
        paddingRight: sidePadding,
    },
}))
export const StyledBodyRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.inputField.background,
    },
}))
