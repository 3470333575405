import { InputAdornment } from "@mui/material"
import { CurrencyCodeRecord } from "currency-codes"
import { useEffect, useState } from "react"

export const useCurrencyInput = ({
    currency,
    defaultValue,
    onChange,
    setBlock,
}: {
    currency?: CurrencyCodeRecord | null
    defaultValue?: number
    onChange?: (x: number) => void
    setBlock?: (block: boolean) => void
}) => {
    const exponents = currency?.digits
    const [value, setValue] = useState<string>(
        (() => {
            if (defaultValue && exponents) {
                return (defaultValue / Math.pow(10, exponents)).toString()
            }

            return ""
        })(),
    )

    useEffect(() => {
        if (defaultValue && exponents) {
            setValue((defaultValue / Math.pow(10, exponents)).toString())
        }
    }, [defaultValue, exponents])

    const regex = new RegExp(`^([0-9]*[,.]?[0-9]{0,${exponents}})`)

    const handleChange = (input: string) => {
        // !exponents wont work since isk have 0 exponents and 0 === false
        if (exponents === undefined) {
            return
        }
        const parsed = (input.match(regex) || ["", ""])[1]
        setValue(parsed)
        const [pre, rawPost] = parsed.split(/[,.]/, 2)
        const post = (rawPost || "").padEnd(exponents, "0")
        onChange?.(parseInt(`${pre}${post}`))
    }

    // reinterpret the value if the currency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => handleChange(value), [currency, value])
    return {
        slotProps: {
            input: {
                endAdornment: <InputAdornment position="end">{currency?.code}</InputAdornment>,
            },
        },
        value,
        disabled: !currency,
        onChange: (x: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleChange(x.target.value)
            setBlock?.(true)
        },
    }
}
