import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"

import { InviteUserComponent } from "src/components/inviteUser/InviteUser"

const AddUser = () => {
    const { getSelectedAgreement } = useUser()

    const partnerId = getSelectedAgreement()?.account.id

    const { partnerClient } = useClient()

    return <InviteUserComponent client={partnerClient} id={partnerId} />
}

export default AddUser
