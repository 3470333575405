import { Typography, Grid } from "@mui/material"

import { DefaultWrapperPaper } from "./layout/DefaultPageComponents"
import { PermissionGroup, UserAgreementAccount } from "@repo/rezip-client/types"
import StyledForm from "./layout/StyledForm"
import InputField from "./InputField"
import { FormattedMessage, useIntl } from "react-intl"
import dayjs from "dayjs"
import CheckboxField from "./generalComponents/CheckboxField"

import { CustomHelp } from "./generalComponents/CustomHelp"
import { useEffect, useMemo, useState } from "react"
import GenericModal from "./modals/GenericModal"
import { useClient } from "src/hooks/useClient.hook"
import { useUser } from "src/contexts/UserConsumer"
import { CustomButton } from "./generalComponents/Buttons"

interface EditUserComponentProps {
    agreement: UserAgreementAccount | undefined
    setAgreement: (agreement: UserAgreementAccount) => void
    setBlock: (block: boolean) => void
    updateUser: () => void
    optionalComponent?: React.ReactNode
    partnerAccount?: boolean
}

export const EditUserComponent = ({
    agreement,
    setAgreement,
    setBlock,
    updateUser,
    optionalComponent,
    partnerAccount,
}: EditUserComponentProps) => {
    const { selectedClient } = useClient()
    const [groups, setGroups] = useState<PermissionGroup[]>([])
    const [showOwnerWarning, setShowOwnerWarning] = useState<boolean>(false)
    const { getSelectedAgreement } = useUser()
    const partnerId = getSelectedAgreement()?.account.id

    useEffect(() => {
        ;(async () => {
            if (selectedClient && partnerId) {
                setGroups(await selectedClient.permissionGroups(partnerId).getAll())
            }
        })()
    }, [selectedClient, partnerId])

    const userHasGroup = useMemo(() => {
        if (!agreement) {
            return false
        }
        const idk = groups
            .flatMap((item) => item.user_agreement_ids.includes(agreement.id))
            .some((value) => value === true)

        if (idk) {
            return true
        }

        return false
    }, [agreement, groups])

    const intl = useIntl()
    return (
        <DefaultWrapperPaper
            sx={{
                padding: "10px",
                alignSelf: "center",
                flexDirection: "column",
                height: "100%",
            }}>
            <Grid container direction="column" sx={{ padding: "20px", minWidth: "400px" }}>
                {optionalComponent}
                {agreement ? (
                    <StyledForm>
                        <InputField
                            id={"name"}
                            name={"name"}
                            type={"text"}
                            disabled={true}
                            label={intl.formatMessage({
                                id: "common_name",
                            })}
                            value={agreement.user?.name ?? ""}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange={() => {}}
                        />
                        <InputField
                            id={"email"}
                            name={"email"}
                            type={"text"}
                            disabled={true}
                            label={intl.formatMessage({
                                id: "common_email",
                            })}
                            value={agreement.user?.email ?? ""}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange={() => {}}
                        />
                        <InputField
                            id={"Created_at"}
                            name={"Created at"}
                            label={intl.formatMessage({
                                id: "common_created_at",
                            })}
                            type={"text"}
                            disabled
                            value={dayjs(new Date(agreement.created_at ?? "")).format(
                                "DD/MM/YYYY HH:mm",
                            )}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange={() => {}}
                        />
                        <Grid container alignItems="center">
                            <Grid>
                                <Typography variant="body2">
                                    {intl.formatMessage({
                                        id: "common_owner",
                                    })}
                                </Typography>
                            </Grid>
                            <Grid>
                                <CheckboxField
                                    id={"owner"}
                                    name={"owner"}
                                    checked={agreement?.owner}
                                    onChange={() => {
                                        setAgreement({
                                            ...agreement,
                                            owner: !agreement.owner,
                                        })
                                        // Remove partnerAccount check when all accountTypes have permissions
                                        if (partnerAccount && !userHasGroup && agreement.owner) {
                                            setShowOwnerWarning(true)
                                        }
                                        setBlock(true)
                                    }}
                                />
                            </Grid>
                            <CustomHelp title={intl.formatMessage({ id: "owner_explanation" })} />
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid>
                                <Typography variant="body2">
                                    {intl.formatMessage({
                                        id: "common_mfa",
                                    })}
                                </Typography>
                            </Grid>
                            <Grid>
                                <CheckboxField
                                    id={"MFA"}
                                    name={"MFA"}
                                    checked={agreement.mfa_required}
                                    onChange={() => {
                                        setAgreement({
                                            ...agreement,
                                            mfa_required: !agreement.mfa_required,
                                        })
                                        setBlock(true)
                                    }}
                                />
                            </Grid>
                            <CustomHelp title={intl.formatMessage({ id: "mfa_explanation" })} />
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <CustomButton
                                onClick={updateUser}
                                text={intl.formatMessage({ id: "common_save_changes" })}
                            />
                        </Grid>
                    </StyledForm>
                ) : null}
            </Grid>
            <GenericModal
                open={showOwnerWarning}
                CustomContent={
                    <Grid maxWidth={400}>
                        <FormattedMessage id="user_permission_explanation" />
                    </Grid>
                }
                title={intl.formatMessage({ id: "user_no_permissions" })}
                onClose={() => setShowOwnerWarning(false)}
                CustomActions={
                    <Grid>
                        <CustomButton
                            onClick={() => setShowOwnerWarning(false)}
                            text={intl.formatMessage({ id: "common_ok" })}
                        />
                    </Grid>
                }
            />
        </DefaultWrapperPaper>
    )
}
