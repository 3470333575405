import { useCallback, useState } from "react"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { QueryingOptions } from "@repo/rezip-client"
import { useClient } from "src/hooks/useClient.hook"
import { UserOverviewComponent } from "src/components/ViewUserComponent"
import { useIntl } from "react-intl"

export default function DropPointUsers() {
    const { getSelectedAgreement } = useUser()
    const intl = useIntl()
    const accountId = getSelectedAgreement()?.account.id
    const { dropPointClient } = useClient()
    const { enqueueAlert } = useSnackbar()
    const [initialLoad, setInitialLoad] = useState(true)

    const deleteUser = useCallback(
        async (agrementId: string) => {
            if (!accountId) {
                return
            }

            const response = await dropPointClient.deleteAgreement(accountId, agrementId)
            if (response) {
                enqueueAlert(
                    intl.formatMessage({
                        id: "toast_message_user_deleted",
                    }),
                    "success",
                )
                setInitialLoad(true)
                return
            } else {
                enqueueAlert(
                    intl.formatMessage({
                        id: "something_went_wrong",
                    }),
                    "error",
                )
                return
            }
        },
        [accountId, dropPointClient, enqueueAlert, intl],
    )

    const fetchAgreements = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!accountId) {
                return
            }
            const options = {
                filters: { ...filters },
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            return dropPointClient.getAgreements(accountId, options)
        },
        [dropPointClient, accountId],
    )

    return (
        <UserOverviewComponent
            deleteUser={deleteUser}
            fetchAgreements={fetchAgreements}
            to={"/users/edit/"}
            addFunctionTo={"add"}
            initialLoad={initialLoad}
            setInitialLoad={setInitialLoad}
        />
    )
}
