import { SelectChangeEvent, Typography, Grid } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import InputField from "src/components/InputField"
import Page from "src/components/layout/Page"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import StyledForm from "src/components/layout/StyledForm"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import useCountryList from "src/utils/countries"
import { Address } from "@repo/rezip-client/types"
import { Partner } from "@repo/rezip-client/partner_client"
import { useClient } from "src/hooks/useClient.hook"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import BottomBanner from "src/components/layout/BottomBanner"
import { ClientError } from "@repo/rezip-client/client_error"
import { MissingFieldErrorHandler } from "src/utils/missingFieldHandler"
import { errorConverter } from "src/utils/errorConverter"

export default function CompanyInfo() {
    const { partnerClient } = useClient()
    const intl = useIntl()
    const CountryList = useCountryList()
    const [selectedCountry, setSelectedCountry] = useState<IMenuItemData>(CountryList[0])
    const { getSelectedAgreement, getToken } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const token = getToken()
    const [companyInfo, setCompanyInfo] = useState<Partner | null>(null)
    const { BlockerAlert, setBlock, isBlocked } = useCustomBlocker()

    const { enqueueAlert } = useSnackbar()

    const handleInfoChange = <T extends keyof Partner>(field: T, value: Partner[T]) => {
        if (companyInfo) {
            setCompanyInfo({ ...companyInfo, [field]: value })
            setBlock(true)
        }
    }

    const updateCompanyInfo = useCallback(async () => {
        if (!token || !companyInfo) {
            return enqueueAlert(intl.formatMessage({ id: "common_error" }), "error")
        }

        if (partnerId) {
            try {
                const data = await partnerClient.patch(partnerId, {
                    ...companyInfo,
                    billing_address: {
                        ...companyInfo.billing_address,
                        country: selectedCountry.id,
                    },
                })

                if (data) {
                    setBlock(false)
                    setCompanyInfo(data)

                    const currentCountry = CountryList.find(
                        (item) => item.id === data.billing_address.country,
                    )
                    if (currentCountry) {
                        setSelectedCountry(currentCountry)
                    }
                }

                return enqueueAlert(intl.formatMessage({ id: "common_success" }), "success")
            } catch (error) {
                const mappedError = errorConverter(error)
                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                MissingFieldErrorHandler(mappedError, enqueueAlert, intl)
            }
        }
    }, [
        token,
        companyInfo,
        partnerId,
        enqueueAlert,
        partnerClient,
        selectedCountry.id,
        setBlock,
        CountryList,
        intl,
    ])

    const handleAddressChange = (field: keyof Address, value: string) => {
        setCompanyInfo((prev) =>
            prev
                ? {
                      ...prev,
                      billing_address: {
                          ...prev.billing_address,
                          [field]: value,
                      },
                  }
                : null,
        )
        setBlock(true)
    }

    const onCountryChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const item = CountryList.find((item) => item.id === event.target.value)
            if (item && companyInfo && companyInfo) {
                setSelectedCountry(item)
                setBlock(true)
            }
        },
        [CountryList, companyInfo, setBlock],
    )

    useEffect(() => {
        if (!companyInfo || !token) {
            const fetchData = async () => {
                if (partnerId) {
                    const companyInfo = await partnerClient.get(partnerId)
                    if (companyInfo) {
                        setCompanyInfo(companyInfo)

                        const currentCountry = CountryList.find(
                            (item) => item.id === companyInfo.billing_address.country,
                        )
                        if (currentCountry) {
                            setSelectedCountry(currentCountry)
                        }
                    }
                }
            }
            fetchData()
        }
    }, [CountryList, companyInfo, partnerId, token, partnerClient])

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_company_info",
            })}>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 12, lg: 6 }}>
                    <DefaultWrapperPaper>
                        <StyledForm>
                            <Grid container spacing={1}>
                                <Typography variant="h4">
                                    <FormattedMessage id="core_information" />
                                </Typography>

                                <InputField
                                    required
                                    label={intl.formatMessage({
                                        id: "name",
                                        defaultMessage: "Name",
                                    })}
                                    value={companyInfo?.name ?? ""}
                                    onChange={(e) => handleInfoChange("name", e.target.value)}
                                    id="name"
                                    name="name"
                                    type={"Text"}
                                />

                                <InputField
                                    label={intl.formatMessage({
                                        id: "phone",
                                        defaultMessage: "Phone",
                                    })}
                                    value={companyInfo?.phone ?? ""}
                                    onChange={(e) => handleInfoChange("phone", e.target.value)}
                                    id="phone"
                                    name="phone"
                                    type={"Text"}
                                />

                                <InputField
                                    label={intl.formatMessage({
                                        id: "website",
                                        defaultMessage: "Website",
                                    })}
                                    value={companyInfo?.website ?? ""}
                                    onChange={(e) => handleInfoChange("website", e.target.value)}
                                    id="website"
                                    name="website"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "support_email",
                                        defaultMessage: "Support Email",
                                    })}
                                    value={companyInfo?.support_email ?? ""}
                                    onChange={(e) =>
                                        handleInfoChange("support_email", e.target.value)
                                    }
                                    required
                                    id="support_email"
                                    name="support_email"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "support_phone",
                                        defaultMessage: "Support Phone",
                                    })}
                                    value={companyInfo?.support_phone ?? ""}
                                    onChange={(e) =>
                                        handleInfoChange("support_phone", e.target.value)
                                    }
                                    required
                                    id="support_phone"
                                    name="support_phone"
                                    type={"Text"}
                                />
                            </Grid>
                        </StyledForm>
                    </DefaultWrapperPaper>

                    <Grid pt={2}>
                        <DefaultWrapperPaper>
                            <StyledForm>
                                <Typography variant="h4">
                                    <FormattedMessage id="api_information" />
                                </Typography>
                                <Typography>
                                    {intl.formatMessage(
                                        { id: "company_info_id" },
                                        { id: partnerId },
                                    )}
                                </Typography>
                            </StyledForm>
                        </DefaultWrapperPaper>
                    </Grid>
                </Grid>

                <Grid size={{ xs: 12, md: 12, lg: 6 }}>
                    <DefaultWrapperPaper>
                        <StyledForm>
                            <Grid container spacing={1}>
                                <Typography variant="h4">
                                    <FormattedMessage id="billing_information" />
                                </Typography>

                                <InputField
                                    required
                                    label={intl.formatMessage({
                                        id: "billing_email",
                                        defaultMessage: "Billing Email",
                                    })}
                                    value={companyInfo?.billing_email ?? ""}
                                    onChange={(e) =>
                                        handleInfoChange("billing_email", e.target.value)
                                    }
                                    id="billing_street"
                                    name="billing_street"
                                    type={"Text"}
                                />

                                <InputField
                                    required
                                    label={intl.formatMessage({
                                        id: "street",
                                        defaultMessage: "Street",
                                    })}
                                    value={companyInfo?.billing_address?.street ?? ""}
                                    onChange={(e) => handleAddressChange("street", e.target.value)}
                                    id="billing_street"
                                    name="billing_street"
                                    type={"Text"}
                                />
                                <InputField
                                    required
                                    label={intl.formatMessage({
                                        id: "city",
                                        defaultMessage: "City",
                                    })}
                                    value={companyInfo?.billing_address?.city ?? ""}
                                    onChange={(e) => handleAddressChange("city", e.target.value)}
                                    id="billing_city"
                                    name="billing_city"
                                    type={"Text"}
                                />
                                <InputField
                                    required
                                    label={intl.formatMessage({
                                        id: "postal_code",
                                        defaultMessage: "Postal Code",
                                    })}
                                    value={companyInfo?.billing_address?.postal_code ?? ""}
                                    onChange={(e) =>
                                        handleAddressChange("postal_code", e.target.value)
                                    }
                                    id="billing_postal_code"
                                    name="billing_postal_code"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "region",
                                        defaultMessage: "Region",
                                    })}
                                    value={companyInfo?.billing_address?.region ?? ""}
                                    onChange={(e) => handleAddressChange("region", e.target.value)}
                                    id="billing_region"
                                    name="billing_region"
                                    type={"Text"}
                                />
                                <SelectField
                                    label={intl.formatMessage({
                                        id: "country",
                                    })}
                                    required
                                    value={selectedCountry.id}
                                    onChange={onCountryChange}
                                    menuItems={CountryList}
                                    large
                                />
                                <InputField
                                    required
                                    label={intl.formatMessage({
                                        id: "vat_no",
                                        defaultMessage: "VAT Number",
                                    })}
                                    value={companyInfo?.vat_no ?? ""}
                                    onChange={(e) => handleInfoChange("vat_no", e.target.value)}
                                    id="vat_no"
                                    name="vat_no"
                                    type={"Text"}
                                />
                            </Grid>
                        </StyledForm>
                    </DefaultWrapperPaper>
                </Grid>
            </Grid>

            {isBlocked ? (
                <BottomBanner>
                    <CustomButton
                        text={intl.formatMessage({ id: "common_save" })}
                        onClick={updateCompanyInfo}
                    />
                </BottomBanner>
            ) : null}

            <BlockerAlert />
        </Page>
    )
}
