import { Box, IconButton, Tooltip, Typography, Grid } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import StyledForm from "src/components/layout/StyledForm"
import InputField from "src/components/InputField"
import Page from "src/components/layout/Page"
import SwitchField from "src/components/SwitchField"
import { OrderConfig } from "src/components/shopComponents/OrderConfiguration"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import EditIcon from "@mui/icons-material/Edit"
import { useCurrencyMenuData } from "src/utils/currencies"
import { CurrencySelect } from "src/components/generalComponents/CurrencySelect"
import cc, { CurrencyCodeRecord } from "currency-codes"
import { CurrencyInput } from "src/components/generalComponents/CurrencyInput"
import { useClient } from "src/hooks/useClient.hook"
import { Product } from "@repo/rezip-client/types"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { useCustomBlocker } from "src/utils/useCustomBlocker"

const ProductToRow = ({
    product,
    index,
    editableItem,
    setEditableItem,
    setBlock,
    setInitialLoad,
    currency,
    patchProduct,
}: {
    product: Product
    index: number
    editableItem: string
    setEditableItem: (id: string) => void
    patchProduct: (id: string, obj: Product) => void
    setInitialLoad: (bool: boolean) => void
    setBlock: (block: boolean) => void
    currency: CurrencyCodeRecord | null
}) => {
    const [tempName, setTempName] = useState("")
    const [tempItemCost, setTempItemCost] = useState(0)
    const intl = useIntl()

    const handleInStock = useCallback(
        (product: Product) => {
            const editedItem = { ...product, active: !product.active }
            product = { ...product, active: !product.active }
            setInitialLoad(true)
            patchProduct(editedItem.id, editedItem)
        },
        [patchProduct, setInitialLoad],
    )
    const handleProductUpdate = useCallback(
        (product: Product) => {
            setInitialLoad(true)
            patchProduct(product.id, {
                ...product,
                name: tempName,
                price: tempItemCost,
            })
        },
        [patchProduct, setInitialLoad, tempItemCost, tempName],
    )

    return (
        <StyledBodyRow
            key={`${product.id}-${index}-${product.sku}`}
            id={`${product.id}-${index}-${product.sku}`}>
            <StyledTableCell>
                <InputField
                    id={""}
                    name={""}
                    type={""}
                    disabled={editableItem !== product.id}
                    value={editableItem === product.id ? tempName : product.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setTempName(event.target.value)
                    }
                />
            </StyledTableCell>
            <StyledTableCell>{product.sku}</StyledTableCell>
            <StyledTableCell id={`${index}-Size`}>
                {product.dimension &&
                product.dimension.height &&
                product.dimension.width &&
                product.dimension.depth
                    ? `${product.dimension.height} x ${product.dimension.width} x ${product.dimension.depth}`
                    : "N/A"}
            </StyledTableCell>
            <StyledTableCell sx={{ width: "20%" }}>
                <CurrencyInput
                    currency={currency}
                    defaultValue={editableItem ? tempItemCost : product.price}
                    onChange={setTempItemCost}
                    disabled={editableItem !== product.id}
                    setBlock={setBlock}
                />
            </StyledTableCell>
            <StyledTableCell>{product.sku.search(/AC|AS/i) == -1 ? 10 : 1}</StyledTableCell>
            <StyledTableCell sx={{ width: "5%" }}>
                <SwitchField
                    id={`${product.id}-switch`}
                    value={product.active}
                    checked={product.active}
                    onClick={() => handleInStock(product)}></SwitchField>
            </StyledTableCell>
            <StyledTableCell sx={{ width: "12%" }}>
                {editableItem === product.id ? (
                    <Grid
                        container
                        spacing={1}
                        maxWidth={{ xs: "100%", lg: "80%" }}
                        sx={{ display: "flex", flexDirection: "row" }}>
                        <CustomButton
                            text={intl.formatMessage({
                                id: "common_cancel",
                            })}
                            key={`${index}-cancel`}
                            onClick={() => {
                                setEditableItem("")
                                setTempItemCost(0)
                                setTempName("")
                            }}
                        />
                        <CustomButton
                            text={intl.formatMessage({
                                id: "common_save",
                            })}
                            key={`${index}-save`}
                            onClick={() => handleProductUpdate(product)}
                        />
                    </Grid>
                ) : editableItem !== "" ? (
                    <Tooltip
                        title={intl.formatMessage({ id: "assortment_edit_tooltip" })}
                        disableInteractive>
                        <IconButton>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <IconButton
                        sx={{ "&:disabled": { color: "inherit" } }}
                        disabled={editableItem !== ""}
                        key={`${index}-edit`}
                        onClick={() => {
                            setEditableItem(product.id)
                            setTempItemCost(product.price)
                            setTempName(product.name)
                        }}>
                        <EditIcon />
                    </IconButton>
                )}
            </StyledTableCell>
        </StyledBodyRow>
    )
}

export default function Assortment() {
    const intl = useIntl()
    const [initialLoad, setInitialLoad] = useState<boolean>(true)
    const { getSelectedAgreement } = useUser()
    const { partnerClient } = useClient()
    const partnerId = getSelectedAgreement()?.account.id
    const { enqueueAlert } = useSnackbar()
    const [editableItem, setEditableItem] = useState<string>("")
    const [products, setProducts] = useState<Product[]>()

    const currencies = useCurrencyMenuData()
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCodeRecord | null>(null)
    const [partnerOrdering, setPartnerOrdering] = useState<OrderConfig | null>(null)

    const { BlockerAlert, setBlock } = useCustomBlocker()

    useEffect(() => {
        if (!partnerId) {
            return
        }
        if (!partnerOrdering) {
            ;(async () => {
                try {
                    const config = await partnerClient.getOrdering(partnerId)
                    setPartnerOrdering(
                        config ? { ...config, currency: config.currency || "" } : null,
                    )
                    const currency = config.currency ? cc.code(config.currency) : null
                    if (currency) {
                        setSelectedCurrency(currency)
                    }
                } catch (error) {
                    console.error(error)
                }
            })()
        }
    }, [currencies, partnerId, partnerOrdering, partnerClient])

    const updatePartnerOrdering = useCallback(async () => {
        if (!partnerOrdering || !partnerId) {
            return enqueueAlert(
                intl.formatMessage({
                    id: "toast_something_went_wrong",
                }),
                "error",
            )
        }
        await partnerClient.setOrdering(partnerId, {
            ...partnerOrdering,
            currency: partnerOrdering.currency,
        })
        setBlock(false)
        return enqueueAlert(
            intl.formatMessage({
                id: "order_config_was_updated",
            }),
            "success",
        )
    }, [partnerOrdering, partnerId, partnerClient, setBlock, enqueueAlert, intl])

    const patchProduct = useCallback(
        async (id: string, obj: Product) => {
            if (!partnerId) {
                return
            }
            try {
                await partnerClient
                    .updateProduct({ partnerId, productId: id }, obj)
                    .then((data) => {
                        setProducts((prevProducts = []) =>
                            prevProducts.map((product) =>
                                product.id === id ? { ...data } : product,
                            ),
                        )
                        setInitialLoad(true)
                    })
                setEditableItem("")

                setBlock(false)
                enqueueAlert(
                    intl.formatMessage(
                        {
                            id: "product_was_updated",
                        },
                        { productName: obj.sku },
                    ),
                    "success",
                )

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                enqueueAlert(intl.formatMessage({ id: "something_went_wrong" }), "error")
            }
        },

        [partnerId, partnerClient, setBlock, enqueueAlert, intl],
    )

    const itemToRow = useCallback(
        (product: Product, index: number) => {
            return (
                <ProductToRow
                    product={product}
                    index={index}
                    editableItem={editableItem}
                    setEditableItem={setEditableItem}
                    patchProduct={patchProduct}
                    setInitialLoad={setInitialLoad}
                    setBlock={setBlock}
                    currency={selectedCurrency}
                />
            )
        },
        [editableItem, patchProduct, setBlock, selectedCurrency],
    )

    // for some unkown reason the getProducts gets all products even with pagesize 11
    useEffect(() => {
        if (!products) {
            const fetch = async () => {
                if (!partnerId) {
                    return
                }

                try {
                    const data = await partnerClient.getProducts(partnerId, {
                        filters: { deleted_at: "" },
                    })

                    if (data) {
                        setProducts(data.filter((p) => !p.deleted_at))
                    }
                } catch (error) {
                    console.error(error)
                }
            }
            fetch()
        }
    })

    const header = [
        {
            label: intl.formatMessage({
                id: "common_product",
            }),
            key: "name",
        },
        {
            label: intl.formatMessage({
                id: "common_sku",
            }),
            key: "SKU",
        },
        {
            label: intl.formatMessage({
                id: "common_size",
            }),
            key: "Size",
        },
        {
            label: intl.formatMessage({
                id: "common_item_cost",
            }),
            key: "item-cost",
        },
        {
            label: intl.formatMessage({
                id: "common_moq",
            }),
            key: "MOQ",
        },
        {
            label: intl.formatMessage({
                id: "common_ion_stock",
            }),
            key: "in-stock",
        },
        { label: "", key: "edit_action" },
    ]

    const productGroups = useMemo(() => {
        if (!products) {
            return {}
        }

        const groups: Record<string, Product[]> = {
            "Self-Standing Box": [],
            "Paper Bag": [],
            Bag: [],
            Box: [],
            "Premium Bag": [],
            Accessory: [],
        }

        products.forEach((product) => {
            if (!groups[product.group.name]) {
                groups[product.group.name] = []
            }
            groups[product.group.name].push(product)
        })

        return groups
    }, [products])

    const groupKeys = useMemo(() => {
        return Object.keys(productGroups)
    }, [productGroups])

    if (!partnerId) {
        return <></>
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_assortment",
            })}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <DefaultWrapperPaper sx={{ display: "flex", flexDirection: "column" }}>
                    {partnerOrdering ? (
                        <Grid
                            container
                            flexDirection={"row"}
                            sx={{
                                display: "flex",
                                flexGrow: "1",
                                alignItems: "flex-end",
                                justifyContent: "space-between",
                                gap: "12px",
                            }}>
                            <StyledForm sx={{ flexGrow: "1", justifyContent: "space-between" }}>
                                <Grid
                                    container
                                    flexDirection={"row"}
                                    maxWidth={{ xs: "100%", lg: "80%" }}
                                    sx={{
                                        display: "flex",
                                        flexGrow: "1",
                                        alignItems: "flex-end",
                                        justifyContent: "space-between",
                                    }}>
                                    <Grid
                                        container
                                        flexDirection={"column"}
                                        sx={{ minWidth: "80px" }}>
                                        <CurrencySelect
                                            label={intl.formatMessage({ id: "common_currency" })}
                                            defaultValue={cc.code(partnerOrdering.currency)}
                                            onChange={(e) => {
                                                setSelectedCurrency(e)
                                                setPartnerOrdering({
                                                    ...partnerOrdering,
                                                    currency: e.code || "",
                                                })
                                                setBlock(true)
                                            }}
                                        />
                                    </Grid>
                                    <Grid container flexDirection={"column"}>
                                        <CurrencyInput
                                            currency={selectedCurrency}
                                            defaultValue={partnerOrdering.shipping_base_cost}
                                            label={intl.formatMessage({
                                                id: "orders_shipping_base",
                                            })}
                                            onChange={(e) => {
                                                setPartnerOrdering({
                                                    ...partnerOrdering,
                                                    shipping_base_cost: e,
                                                })
                                            }}
                                            setBlock={setBlock}
                                        />
                                    </Grid>
                                    <Grid container flexDirection={"column"}>
                                        <CurrencyInput
                                            currency={selectedCurrency}
                                            defaultValue={partnerOrdering.shipping_unit_cost}
                                            label={intl.formatMessage({
                                                id: "orders_shipping_unit",
                                            })}
                                            onChange={(e) => {
                                                setPartnerOrdering({
                                                    ...partnerOrdering,
                                                    shipping_unit_cost: e,
                                                })
                                            }}
                                            setBlock={setBlock}
                                        />
                                    </Grid>
                                    <Grid container flexDirection={"column"}>
                                        <InputField
                                            label={intl.formatMessage({ id: "common_vat" })}
                                            id={"vat"}
                                            name={""}
                                            type={"Text"}
                                            value={partnerOrdering?.vat ?? 0}
                                            unitText={"%"}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                setPartnerOrdering({
                                                    ...partnerOrdering,
                                                    vat: Number(event.target.value),
                                                } as OrderConfig)
                                                setBlock(true)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledForm>
                            <Grid container flexDirection={"column"}>
                                <CustomButton
                                    onClick={updatePartnerOrdering}
                                    text={intl.formatMessage({
                                        id: "common_update",
                                    })}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                </DefaultWrapperPaper>

                {groupKeys.map((item) => {
                    if (productGroups[item].length === 0) {
                        return
                    }
                    return (
                        <DefaultWrapperPaper>
                            <Grid container spacing={1} direction={"column"}>
                                <Typography variant="h3">
                                    {item !== "Accessory"
                                        ? intl.formatMessage(
                                              {
                                                  id: `order_plural_item`,
                                              },
                                              {
                                                  item: item,
                                                  suffix: item.endsWith("x")
                                                      ? "es"
                                                      : item.endsWith("s")
                                                        ? ""
                                                        : "s",
                                              },
                                          )
                                        : intl.formatMessage({ id: "order_accessories" })}
                                </Typography>
                                <AllInOneTable<Product>
                                    headers={header}
                                    items={productGroups[item] ?? []}
                                    itemsToRow={itemToRow}
                                    defaultSortBy={"sku"}
                                    hideSearchBar
                                    initialLoad={initialLoad}
                                    setInitialLoad={setInitialLoad}
                                />
                            </Grid>
                        </DefaultWrapperPaper>
                    )
                })}
            </Box>
            <BlockerAlert />
        </Page>
    )
}
