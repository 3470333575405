import { useUser } from "src/contexts/UserConsumer"
import Users from "./account/users/users"
import DropPointUsers from "./dropPoint/users/overview"

import ShopUsers from "./shop/users/overview"

export const UserOverView = () => {
    const { getSelectedAccountType } = useUser()
    const accountType = getSelectedAccountType()

    if (accountType === "Partner") {
        return <Users />
    } else if (accountType === "DropPoint") {
        return <DropPointUsers />
    } else if (accountType === "Shop") {
        return <ShopUsers />
    }

    return <></>
}
