import { SelectChangeEvent, Typography, Grid } from "@mui/material"
import { useIntl } from "react-intl"
import Page from "src/components/layout/Page"
import SelectField from "src/components/SelectField"
import DatePicker from "src/components/datePicker/DatePicker"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { useReportController } from "./reports.controller"
import { ReportingCharts } from "src/components/charts/reportingCharts"
import { useUser } from "src/contexts/UserConsumer"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import SustainabilityClaimsPdf from "../../../assets/DOCUMENTATION.OF.SUSTAINABILITY.CLAIMS.pdf"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import CollapseInformation from "src/components/generalComponents/CollapseInformation"
import { StyledP } from "src/components/styles"

type item = {
    sku: string
    reclaim: number
    fulfill: number
    reward: number
    waste: number
}

export default function Reports() {
    const {
        onTypeChange,
        onSummaryShopChange,
        summary,
        scanData,
        setScanData,
        dropDownShop,
        scanTypes,
        selectedTime,
        onStartDateChange,
        onEndDateChange,
        envStats,
        labels,
        simpleProducts,
        graphsReady,
        correctedSummaryData,
        summedEnvStats,
        setDateUpdated,
        dateUpdated,
        initialLoad,
        setInitialLoad,
        getScansCSV,
    } = useReportController()
    const { getSelectedAccountType } = useUser()
    const accountType = getSelectedAccountType()

    const intl = useIntl()

    const summaryToRow = (item: item, index: number) => {
        return (
            <StyledBodyRow key={index}>
                <StyledTableCell key={`${index}-sku`}>{item.sku ?? ""}</StyledTableCell>
                <StyledTableCell key={`${index}-Reclaimed`}>
                    {intl.formatNumber(item.reclaim) ?? ""}
                </StyledTableCell>
                <StyledTableCell key={`${index}-Fulfilled`}>
                    {intl.formatNumber(item.fulfill) ?? ""}
                </StyledTableCell>
                <StyledTableCell key={`${index}-Rewards`}>
                    {intl.formatNumber(item.reward) ?? ""}
                </StyledTableCell>
                <StyledTableCell key={`${index}-Discarded`}>
                    {intl.formatNumber(item.waste) ?? ""}
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_reporting",
            })}>
            <Grid sx={{ marginBottom: "6px" }}>
                <CollapseInformation
                    header={intl.formatMessage({
                        id: "reports_sustainability_description_header",
                    })}>
                    <StyledP>
                        {intl.formatMessage({
                            id: "reports_sustainability_description_1",
                        })}
                    </StyledP>
                    <StyledP>
                        {intl.formatMessage({
                            id: "reports_sustainability_description_2",
                        })}
                    </StyledP>
                    <StyledP>
                        {intl.formatMessage({
                            id: "reports_sustainability_description_3",
                        })}{" "}
                        <a href={SustainabilityClaimsPdf} target="_blank">
                            {intl.formatMessage({
                                id: "common_click_for_details",
                            })}
                        </a>
                    </StyledP>
                </CollapseInformation>
            </Grid>
            <Grid container spacing={2} direction="column">
                <Grid container>
                    <DefaultWrapperPaper sx={{ display: "flex", padding: "16px 24px" }}>
                        <Grid container spacing={1.5}>
                            <Grid>
                                {accountType === "Shop" ? null : (
                                    <SelectField
                                        label={intl.formatMessage({
                                            id: "reports_shop_accounts",
                                        })}
                                        value={summary.accounts ?? ""}
                                        onChange={(event: SelectChangeEvent<unknown>) => {
                                            onSummaryShopChange(event)
                                            setDateUpdated(true)
                                        }}
                                        menuItems={dropDownShop}
                                    />
                                )}
                            </Grid>
                            <Grid>
                                <DatePicker
                                    date={summary.startDate}
                                    label={intl.formatMessage({
                                        id: "reports_start_date",
                                    })}
                                    onchange={(date: Date) => {
                                        onStartDateChange(date)
                                    }}
                                    maxDate={summary.endDate}
                                />
                            </Grid>
                            <Grid>
                                <DatePicker
                                    date={summary.endDate}
                                    label={intl.formatMessage({
                                        id: "reports_end_date",
                                    })}
                                    minDate={summary.startDate}
                                    maxDate={new Date()}
                                    onchange={(date: Date) => onEndDateChange(date)}
                                />
                            </Grid>
                        </Grid>
                    </DefaultWrapperPaper>
                </Grid>
                <Grid>
                    <ReportingCharts
                        shopId={summary.accounts}
                        startDate={summary.startDate}
                        endDate={summary.endDate}
                        timeFrame={selectedTime.id}
                        envStats={envStats}
                        summedEnvStats={summedEnvStats}
                        labels={labels}
                        simpleProducts={simpleProducts}
                        graphsReady={graphsReady}
                        dateUpdated={dateUpdated}
                        setDateUpdated={setDateUpdated}
                    />
                </Grid>
                {/*
            the endpoint used is only available on partners
            */}
                {accountType === "Partner" ? (
                    <Grid>
                        <DefaultWrapperPaper>
                            <Typography variant="h4">
                                {intl.formatMessage({
                                    id: "reports_activity_log",
                                })}
                            </Typography>

                            <AllInOneTable<item>
                                headers={[
                                    {
                                        key: "sku",
                                        label: intl.formatMessage({
                                            id: "common_sku",
                                        }),
                                        excludeFromSearch: true,
                                    },
                                    {
                                        key: "reclaimed",
                                        label: intl.formatMessage({
                                            id: "common_reclaimed",
                                        }),
                                        excludeFromSearch: true,
                                    },

                                    {
                                        key: "fulfilled",
                                        label: intl.formatMessage({
                                            id: "common_fulfilled",
                                        }),
                                        excludeFromSearch: true,
                                    },

                                    {
                                        key: "rewards",
                                        label: intl.formatMessage({
                                            id: "common_rewards",
                                        }),
                                        excludeFromSearch: true,
                                    },

                                    {
                                        key: "discarded",
                                        label: intl.formatMessage({
                                            id: "common_discarded",
                                        }),
                                        excludeFromSearch: true,
                                    },
                                ]}
                                items={correctedSummaryData}
                                itemsToRow={summaryToRow}
                                defaultSortBy={"sku"}
                                initialLoad={initialLoad}
                                setInitialLoad={setInitialLoad}
                            />
                        </DefaultWrapperPaper>
                    </Grid>
                ) : null}

                {/*
            the endpoint used is only available on partners
            */}
                {accountType === "Partner" ? (
                    <Grid>
                        <DefaultWrapperPaper>
                            <Grid container direction="column" spacing={1}>
                                <Typography variant="h4">
                                    {intl.formatMessage({
                                        id: "reports_scan_data",
                                    })}
                                </Typography>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    size={{ xs: 12, md: 7 }}>
                                    <Grid>
                                        <SelectField
                                            label={intl.formatMessage({
                                                id: "reports_shop_accounts",
                                            })}
                                            value={scanData.accounts ?? ""}
                                            onChange={(event: SelectChangeEvent<unknown>) =>
                                                onSummaryShopChange(event, true)
                                            }
                                            menuItems={dropDownShop}
                                        />
                                    </Grid>
                                    <Grid>
                                        <SelectField
                                            label={intl.formatMessage({
                                                id: "reports_scan_type",
                                            })}
                                            value={scanData.type ?? ""}
                                            onChange={(event: SelectChangeEvent<unknown>) =>
                                                onTypeChange(event)
                                            }
                                            menuItems={scanTypes}
                                        />
                                    </Grid>
                                    <Grid>
                                        <DatePicker
                                            date={scanData.startDate}
                                            label={intl.formatMessage({
                                                id: "reports_start_date",
                                            })}
                                            onchange={(date: Date) => {
                                                setScanData({ ...scanData, startDate: date })
                                            }}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid>
                                        <DatePicker
                                            date={scanData.endDate}
                                            label={intl.formatMessage({
                                                id: "reports_end_date",
                                            })}
                                            minDate={scanData.startDate}
                                            maxDate={new Date()}
                                            onchange={(date: Date) => {
                                                setScanData({ ...scanData, endDate: date })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="flex-end">
                                    <CustomButton
                                        text={intl.formatMessage({
                                            id: "common_download",
                                        })}
                                        onClick={getScansCSV}
                                    />
                                </Grid>
                            </Grid>
                        </DefaultWrapperPaper>
                    </Grid>
                ) : null}
            </Grid>
        </Page>
    )
}
