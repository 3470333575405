import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Page from "src/components/layout/Page"
import { useClient } from "src/hooks/useClient.hook"
import { MotherShipProduct, ProductGroup } from "@repo/rezip-client/mothership_client"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Grid from "@mui/material/Grid"
import StyledForm from "src/components/layout/StyledForm"
import InputField from "src/components/InputField"
import { SelectChangeEvent, Switch } from "@mui/material"

import cc, { CurrencyCodeRecord } from "currency-codes"

import { FormattedMessage, useIntl } from "react-intl"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import BottomBanner from "src/components/layout/BottomBanner"

import SelectField, { IMenuItemData } from "src/components/SelectField"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"
import { ClientError } from "@repo/rezip-client/client_error"
import { CurrencyInput } from "src/components/generalComponents/CurrencyInput"
import { CurrencySelect } from "src/components/generalComponents/CurrencySelect"
import { CustomButton } from "src/components/generalComponents/Buttons"

export const Product = () => {
    const { id } = useParams()
    const [product, setProduct] = useState<MotherShipProduct | null>(null)
    const [productGroups, setProductGroups] = useState<ProductGroup[] | null>(null)
    const { mothershipClient } = useClient()
    const [selectedProductGroup, setSelectedProductGroup] = useState<IMenuItemData | null>(null)
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCodeRecord | null>(null)
    const intl = useIntl()
    const { BlockerAlert, setBlock, isBlocked } = useCustomBlocker()
    const { enqueueAlert } = useSnackbar()

    const handleInfoChange = <T extends keyof MotherShipProduct>(
        field: T,
        value: MotherShipProduct[T],
    ) => {
        if (product) {
            setProduct({ ...product, [field]: value })
            setBlock(true)
        }
    }
    const handleDimensionChange = <T extends keyof MotherShipProduct["dimension"]>(
        field: T,
        value: MotherShipProduct["dimension"][T],
    ) => {
        if (product) {
            setProduct({ ...product, dimension: { ...product.dimension, [field]: value } })
            setBlock(true)
        }
    }

    useEffect(() => {
        if (!product) {
            if (id) {
                const fetch = async () => {
                    const resp = await mothershipClient.getProduct(id)
                    setProduct(resp)
                    const currency = resp?.currency ? cc.code(resp.currency) : null
                    if (currency) {
                        setSelectedCurrency(currency)
                    }
                }
                fetch()
            } else {
                setProduct({
                    name: "",
                    created_at: new Date(),
                    updated_at: new Date(),
                    id: "",
                    active: false,
                    group_id: "",
                    anonymous: false,
                    currency: "",
                    dimension: {
                        depth: null,
                        height: null,
                        width: null,
                    },
                    weight: 0,
                    volume: 0,
                    bundle_size: 0,
                    price: 0,
                    sku: "",
                    deleted_at: null,
                    primary_material: "",
                    surface_area: 0,
                })
            }
        }
    }, [id, mothershipClient, product])

    const patchProduct = useCallback(async () => {
        if (!product || !id) {
            return
        }
        try {
            setBlock(false)
            const resp = await mothershipClient.patchProduct(id, product)
            if (resp) {
                return enqueueAlert("Product has been updated", "success")
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return displayErrorMessage(mappedError, enqueueAlert)
        }
    }, [enqueueAlert, id, mothershipClient, product, setBlock])

    const postProduct = useCallback(async () => {
        if (!product || !selectedCurrency || !selectedProductGroup) {
            return
        }
        try {
            setBlock(false)
            const item = {
                ...product,
                currency: selectedCurrency.code,
                group_id: selectedProductGroup?.id,
            }
            const resp = await mothershipClient.postProduct(item)
            if (resp) {
                return enqueueAlert("Product has been created", "success")
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return displayErrorMessage(mappedError, enqueueAlert)
        }
    }, [enqueueAlert, mothershipClient, product, selectedCurrency, selectedProductGroup, setBlock])

    const onAnonymousChange = useCallback(
        async (product: MotherShipProduct) => {
            if (!product) {
                return
            }
            if (id) {
                const item = { ...product, anonymous: !product.anonymous }
                setBlock(false)
                const resp = await mothershipClient.patchProduct(product.id, item)
                if (resp) {
                    setProduct(resp)
                    return enqueueAlert("Product has been updated", "success")
                }
            } else {
                const item = { ...product, anonymous: !product.anonymous }
                setProduct(item)
            }
        },
        [enqueueAlert, id, mothershipClient, setBlock],
    )
    const onActiveChange = useCallback(
        async (product: MotherShipProduct) => {
            if (!product) {
                return
            }
            if (id) {
                const item = { ...product, active: !product.active }
                setBlock(false)
                const resp = await mothershipClient.patchProduct(product.id, item)
                if (resp) {
                    setProduct(resp)
                    return enqueueAlert("Product has been updated", "success")
                }
            } else {
                const item = { ...product, active: !product.active }
                setProduct(item)
            }
        },
        [enqueueAlert, id, mothershipClient, setBlock],
    )

    useEffect(() => {
        if (!productGroups) {
            const fetch = async () => {
                const resp = await mothershipClient.getProductGroups({ pageSize: 99 })
                setProductGroups(resp)
            }
            fetch()
        }
    })
    const productGroupItems = useMemo(() => {
        if (!productGroups) {
            return []
        }
        return productGroups.map((item) => {
            return { id: item.id, value: item.name } as IMenuItemData
        })
    }, [productGroups])

    useEffect(() => {
        if (!productGroupItems && !product) {
            return
        }
        if (!selectedProductGroup) {
            const group = productGroupItems.find((item) => item.id === product?.group_id)
            if (group) {
                setSelectedProductGroup(group)
            }
        }
    }, [product, productGroupItems, selectedProductGroup])

    const onProductGroupChanged = (e: SelectChangeEvent<unknown>) => {
        const group =
            productGroupItems.find((mi) => mi.id === e.target.value) ?? productGroupItems[0]

        setSelectedProductGroup(group)
    }

    return (
        <Page
            title={
                id
                    ? intl.formatMessage({ id: "mothership_view_product" })
                    : intl.formatMessage({ id: "mothership_create_new_product" })
            }
            backButton>
            <DefaultWrapperPaper>
                {product && productGroups && productGroupItems ? (
                    <Grid container spacing={5}>
                        <Grid
                            container
                            id={"base-product-info"}
                            direction="column"
                            spacing={1}
                            size={{ lg: 6, md: 10, xs: 12 }}>
                            <StyledForm>
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        direction="column"
                                        size={{ lg: 12, md: 12, xs: 12 }}>
                                        <InputField
                                            id={"name"}
                                            name={"name"}
                                            type={"name"}
                                            label={intl.formatMessage({
                                                id: "mothership_product_name",
                                            })}
                                            value={product?.name ?? ""}
                                            onChange={(e) =>
                                                handleInfoChange("name", e.target.value)
                                            }
                                        />
                                        <Grid
                                            justifyContent={"space-between"}
                                            container
                                            direction={"row"}>
                                            <Grid size={6}>
                                                <InputField
                                                    id={"sku"}
                                                    name={"sku"}
                                                    type={"sku"}
                                                    label={intl.formatMessage({ id: "common_sku" })}
                                                    value={product?.sku ?? ""}
                                                    onChange={(e) =>
                                                        handleInfoChange("sku", e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 6, md: 6 }}>
                                                <SelectField
                                                    label={intl.formatMessage({
                                                        id: "mothership_product_product_group",
                                                    })}
                                                    value={selectedProductGroup?.id}
                                                    onChange={onProductGroupChanged}
                                                    menuItems={productGroupItems}
                                                    large
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            justifyContent={"space-between"}
                                            container
                                            direction={"row"}>
                                            <Grid size={6}>
                                                <CurrencySelect
                                                    label={intl.formatMessage({
                                                        id: "common_currency",
                                                    })}
                                                    defaultValue={
                                                        product
                                                            ? cc.code(product.currency)
                                                            : undefined
                                                    }
                                                    onChange={(e) => {
                                                        setSelectedCurrency(e)
                                                        setBlock(true)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid size={6}>
                                                <CurrencyInput
                                                    currency={selectedCurrency}
                                                    label={intl.formatMessage({
                                                        id: "common_unit_price",
                                                    })}
                                                    defaultValue={Number(product?.price)}
                                                    onChange={(e) => {
                                                        setProduct({ ...product, price: e })
                                                    }}
                                                    setBlock={setBlock}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StyledForm>
                        </Grid>
                        <Grid
                            container
                            id={"material-composition"}
                            direction="column"
                            spacing={1}
                            size={{ lg: 6, md: 10, xs: 12 }}>
                            <StyledForm>
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        direction="column"
                                        size={{ lg: 12, md: 12, xs: 12 }}>
                                        <Grid
                                            justifyContent={"space-between"}
                                            container
                                            direction={"row"}>
                                            <Grid size={4}>
                                                <InputField
                                                    id={"width"}
                                                    name={"width"}
                                                    type={"width"}
                                                    label={intl.formatMessage({
                                                        id: "mothership_product_width",
                                                    })}
                                                    value={product.dimension.width}
                                                    onChange={(e) =>
                                                        handleDimensionChange(
                                                            "width",
                                                            Number(e.target.value),
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid size={4}>
                                                <InputField
                                                    id={"height"}
                                                    name={"height"}
                                                    type={"height"}
                                                    label={intl.formatMessage({
                                                        id: "mothership_product_height",
                                                    })}
                                                    value={product.dimension.height}
                                                    onChange={(e) =>
                                                        handleDimensionChange(
                                                            "height",
                                                            Number(e.target.value),
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid size={4}>
                                                <InputField
                                                    id={"depth"}
                                                    name={"depth"}
                                                    type={"depth"}
                                                    label={intl.formatMessage({
                                                        id: "mothership_product_depth",
                                                    })}
                                                    value={product.dimension.depth}
                                                    onChange={(e) =>
                                                        handleDimensionChange(
                                                            "depth",
                                                            Number(e.target.value),
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            justifyContent={"space-between"}
                                            container
                                            direction={"row"}>
                                            <Grid size={6}>
                                                <InputField
                                                    id={"weight"}
                                                    name={"weight"}
                                                    type={"weight"}
                                                    label={intl.formatMessage({
                                                        id: "mothership_product_weight",
                                                    })}
                                                    value={product.weight}
                                                    onChange={(e) => {
                                                        handleInfoChange(
                                                            "weight",
                                                            Number(e.target.value),
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid size={6}>
                                                <InputField
                                                    id={"volume"}
                                                    name={"volume"}
                                                    type={"volume"}
                                                    label={intl.formatMessage({
                                                        id: "mothership_product_volume",
                                                    })}
                                                    value={product.volume}
                                                    onChange={(e) => {
                                                        handleInfoChange(
                                                            "volume",
                                                            Number(e.target.value),
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            justifyContent={"space-between"}
                                            container
                                            direction={"row"}>
                                            <Grid size={6}>
                                                <InputField
                                                    id={"bundle-size"}
                                                    name={"bundle-size"}
                                                    type={"bundle-size"}
                                                    label={intl.formatMessage({
                                                        id: "common_bundle_size",
                                                    })}
                                                    value={product.bundle_size}
                                                    onChange={(e) => {
                                                        handleInfoChange(
                                                            "bundle_size",
                                                            Number(e.target.value),
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={"row"}>
                                            <Grid container direction={"column"} size={4}>
                                                <FormattedMessage id="common_anonymous" />
                                                <Switch
                                                    checked={product.anonymous}
                                                    onClick={() => onAnonymousChange(product)}
                                                />
                                            </Grid>
                                            <Grid container direction={"column"} size={4}>
                                                <FormattedMessage id="common_active" />
                                                <Switch
                                                    onClick={() => onActiveChange(product)}
                                                    checked={product.active}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StyledForm>
                        </Grid>
                        {isBlocked ? (
                            <BottomBanner>
                                <CustomButton
                                    onClick={!id ? () => postProduct() : () => patchProduct()}
                                    text={
                                        !id
                                            ? intl.formatMessage({ id: "common_create" })
                                            : intl.formatMessage({ id: "common_save" })
                                    }
                                />
                            </BottomBanner>
                        ) : null}
                    </Grid>
                ) : null}
            </DefaultWrapperPaper>
            <BlockerAlert />
        </Page>
    )
}
