import { IconButton, Tooltip } from "@mui/material"
import Grid from "@mui/material/Grid"
import { StyledTableCell } from "src/components/table/TableComponents"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import useIntl from "react-intl/src/components/useIntl"

type IdCellProps = {
    id: string
}

const IdCell = ({ id }: IdCellProps) => {
    const intl = useIntl()
    const { enqueueAlert } = useSnackbar()

    const numberOfDigitsToShow = 6

    const handleClick = () => {
        navigator.clipboard.writeText(id)
        enqueueAlert(
            intl.formatMessage({
                id: "toast_message_copied_to_clipboard",
            }),
            "success",
        )
    }

    return (
        <StyledTableCell id={`order-cell-${id}`} sx={{ fontWeight: "600" }}>
            <Tooltip title={id}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <IconButton onClick={handleClick} color="primary">
                        <ContentCopyIcon sx={{ height: "0.8em", width: "0.8em" }} />
                    </IconButton>
                    ...
                    {id.length > numberOfDigitsToShow
                        ? id.substring(id.length - numberOfDigitsToShow)
                        : id}
                </Grid>
            </Tooltip>
        </StyledTableCell>
    )
}

export default IdCell
