import { useContext } from "react"
import { JsLoader, JsLoaderContext } from "./JsLoaderProvider"

export const useJsLoader = (): JsLoader => {
    const context = useContext(JsLoaderContext)
    if (!context) {
        throw new Error("Context not provided")
    }
    return context
}
