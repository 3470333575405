import React, { FocusEvent } from "react"

import Select, { SelectChangeEvent } from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import StyledForm from "./layout/StyledForm"
import { FieldLabel, StyledMenuItem } from "./styles"

type Props = {
    id?: string | undefined
    name?: string | undefined
    label?: string | undefined
    value: unknown
    disabled?: boolean | undefined
    required?: boolean | undefined
    onChange: (event: SelectChangeEvent<unknown>) => void
    menuItems: Array<IMenuItemData>
    onBlur?: (event: FocusEvent<unknown>) => void | undefined
    error?: string | undefined
    placeholder?: string
    additonalColumns?: boolean
    large?: boolean | undefined
}

export interface IMenuItemData {
    id: string
    disabled?: boolean | undefined
    value: string
}

const SelectField = ({
    id,
    name,
    label,
    value,
    disabled,
    required,
    onChange,
    menuItems,
    onBlur,
    error,
    placeholder,
    additonalColumns,
    large,
}: Props) => {
    const [menuOpacity, setMenuOpacity] = React.useState(0)

    const requirement = required ? (
        <Typography
            variant="subtitle2"
            sx={{
                color: "warning.main",
                paddingLeft: "3px",
            }}>
            *
        </Typography>
    ) : (
        <></>
    )

    const errorMessage = error ? (
        <Box
            sx={{
                color: "warning.main",
                marginTop: "8px",
            }}>
            <Typography
                variant="subtitle2"
                component="p"
                sx={{
                    color: "warning.main",
                    marginTop: "8px",
                }}>
                {error}
            </Typography>
        </Box>
    ) : (
        <></>
    )

    return (
        <Box sx={{ minWidth: "inherit" }}>
            <StyledForm>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <FieldLabel>{label}</FieldLabel>
                    {requirement}
                </Grid>
                <Select
                    id={id}
                    name={name}
                    value={value || ""} // Ensure value is either a string or empty
                    disabled={disabled}
                    required={required}
                    onChange={onChange}
                    onBlur={onBlur}
                    displayEmpty // Allow placeholder to show when value is empty
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        sx: {
                            maxHeight: "50vh",
                            maxWidth: "unset",
                            opacity: menuOpacity,
                            minWidth: "inherit",
                        },
                    }}
                    onOpen={() => {
                        setMenuOpacity(1)
                    }}
                    onClose={() => {
                        setMenuOpacity(0)
                    }}
                    error={!!error} // Show error state if there is an error
                    sx={{
                        ".MuiInputBase-input": {
                            fontSize: large ? "" : "14px",
                            padding: large ? "" : "5px 0 6px 14px",
                            zIndex: "1",
                            borderRadius: additonalColumns ? "4px 0px 0px 4px " : "4px",
                        },
                        svg: {
                            zIndex: "1",
                        },
                        [`& fieldset`]: {
                            borderRadius: additonalColumns ? "4px 0px 0px 4px" : "4px",
                        },
                    }}>
                    {/* Add a MenuItem for the placeholder */}
                    {placeholder && !value && (
                        <StyledMenuItem value="" disabled sx={{ fontSize: large ? "" : "14px" }}>
                            {placeholder}
                        </StyledMenuItem>
                    )}
                    {menuItems &&
                        menuItems.length > 0 &&
                        menuItems?.map((option, index) => {
                            return (
                                <StyledMenuItem
                                    value={option.id}
                                    disabled={option.disabled}
                                    key={index}
                                    onBlur={onBlur}
                                    sx={{ fontSize: large ? "" : "14px" }}>
                                    {option.value}
                                </StyledMenuItem>
                            )
                        })}
                </Select>
                {errorMessage}
            </StyledForm>
        </Box>
    )
}

export default SelectField
