import { useCallback, useState } from "react"

import { FormattedMessage, useIntl } from "react-intl"
import Page from "src/components/layout/Page"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    SelectChangeEvent,
    Typography,
    Grid,
} from "@mui/material"
import { CustomButton } from "src/components/generalComponents/Buttons"
import BarcodeScanInput from "src/components/BarcodeScanInputField"
import { StationStatusTableRow, useReceiveController } from "./receive.controller"

import GenericModal from "src/components/modals/GenericModal"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import { AllInOneTable } from "src/components/table/AllInOneTable"

import { CustomHelp } from "src/components/generalComponents/CustomHelp"
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import SelectField from "src/components/SelectField"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { CustomSettings } from "./receiveSettingsIcon"
import { RecieveHeaders } from "./receiveHeaders"

export default function Receive() {
    const {
        itemToRow,
        mappedRowData,
        scanAssignStorageUnit,
        scanBarcode,
        onStationChange,
        showCompletedBundleDialog,
        setShowCompletedBundleDialog,
        showNoStorageUnitAssignedDialog,
        setShowNoStorageUnitAssignedDialog,
        selectedStation,
        stations,
        selectedLocation,
        storageUnitInput,
        setStorageUnitInput,
        scanInput,
        setScanInput,
        selectedBundle,
        setSelectedBundle,
        mapBundle,
        autoComplete,
        setAutoComplete,
        focusWhenSelectedElement,
        showStorageFilledPopup,
        setShowStorageFilledPopup,
        stationModal,
        setStationModal,
        setInitialLoad,
        initialLoad,
        noStationAvailable,
        setNoStationAvailable,
    } = useReceiveController()
    const navigate = useNavigate()
    const { enqueueAlert } = useSnackbar()

    const [stationInput, setStationInput] = useState<string>("")

    const intl = useIntl()

    const onStationScan = useCallback(() => {
        if (!stations) {
            return
        }

        const item = stations.find((item) => {
            return item.id === stationInput
        })

        if (item) {
            onStationChange(item.id)
            setStationModal(false)
        } else {
            return enqueueAlert(
                intl.formatMessage({ id: "receive_station_not_found" }, { item: stationInput }),
                "warning",
            )
        }
    }, [enqueueAlert, intl, onStationChange, setStationModal, stationInput, stations])

    const onStationChanged = (e: SelectChangeEvent<unknown>) => {
        if (!stations) {
            return
        }
        const sort = stations.find((mi) => mi.id === e.target.value)
        if (sort) {
            onStationChange(sort?.id)
            setStationModal(false)
        }
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "receive_page_header",
            })}
            rightSideItem={
                <Grid container alignContent="center" spacing={1}>
                    <Typography sx={{ fontWeight: 600 }}>
                        {intl.formatMessage({ id: "common_station" })}:
                    </Typography>

                    <Typography>{selectedStation?.name}</Typography>
                    <CustomSettings
                        setOpen={setStationModal}
                        autoAdd={autoComplete}
                        toggleAutoAdd={setAutoComplete}
                    />
                </Grid>
            }>
            <Grid container direction="column" spacing={2} sx={{ flexWrap: "nowrap" }}>
                <Grid container spacing={2}>
                    <Grid size={6}>
                        <DefaultWrapperPaper>
                            <Grid container direction="column" spacing={1}>
                                <Grid container spacing={1}>
                                    <Typography variant="h4">
                                        <FormattedMessage id="receive_page_receive_packaging_input_headline" />
                                    </Typography>
                                    <CustomHelp
                                        title={intl.formatMessage({
                                            id: "receive_page_receive_packaging_tooltip",
                                        })}
                                    />
                                </Grid>
                                <BarcodeScanInput
                                    key={"scan-packaging-input"}
                                    value={scanInput}
                                    onScan={scanBarcode}
                                    onChange={(value: string) => {
                                        setScanInput(value)
                                    }}
                                    disabled={
                                        selectedLocation == undefined ||
                                        selectedStation == undefined
                                    }
                                    inputRef={focusWhenSelectedElement}
                                    focused={true}
                                />
                            </Grid>
                        </DefaultWrapperPaper>
                    </Grid>
                    <Grid size={6}>
                        {autoComplete ? (
                            <DefaultWrapperPaper>
                                <Grid container direction="column" spacing={1}>
                                    <Grid container justifyContent="space-between">
                                        <Grid container spacing={1}>
                                            <Typography variant="h4">
                                                <FormattedMessage id="receive_page_Assign_storage_unit_input_headline" />
                                            </Typography>
                                            <CustomHelp
                                                title={intl.formatMessage({
                                                    id: "receive_page_assign_storage_tooltip",
                                                })}
                                            />
                                        </Grid>
                                        <Grid sx={{ marginTop: "-8px" }}>
                                            <CustomButton
                                                text={intl.formatMessage({
                                                    id: "receive_add_new_storage_unit",
                                                })}
                                                onClick={
                                                    selectedStation
                                                        ? () =>
                                                              navigate(
                                                                  `/warehouse/storage-units/create/${selectedStation?.id}`,
                                                              )
                                                        : () =>
                                                              navigate(
                                                                  `/warehouse/storage-units/create/`,
                                                              )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <BarcodeScanInput
                                        key={"assign-storage-unit-input"}
                                        value={storageUnitInput}
                                        onScan={scanAssignStorageUnit}
                                        onChange={(value: string) => {
                                            setStorageUnitInput(value)
                                        }}
                                        disabled={
                                            selectedLocation == undefined ||
                                            selectedStation == undefined
                                        }
                                    />
                                </Grid>
                            </DefaultWrapperPaper>
                        ) : null}
                    </Grid>
                </Grid>
                <DefaultWrapperPaper>
                    <Grid container direction="column" spacing={1}>
                        <Grid container spacing={1}>
                            <Typography variant="h4">
                                <FormattedMessage id="receive_table_header" />
                            </Typography>
                            <CustomHelp
                                title={intl.formatMessage({
                                    id: "receive_page_receive_status_tooltip",
                                })}
                            />
                        </Grid>
                        <AllInOneTable<StationStatusTableRow>
                            headers={RecieveHeaders(autoComplete, intl)}
                            items={mappedRowData}
                            itemsToRow={itemToRow}
                            defaultSortBy={"bundle"}
                            initialLoad={initialLoad}
                            setInitialLoad={setInitialLoad}
                            hideSearchBar
                        />
                    </Grid>
                </DefaultWrapperPaper>
            </Grid>
            <GenericModal
                open={stationModal}
                CustomContent={
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        spacing={2}
                        width={"100%"}>
                        <Grid container direction="column" justifyContent="center">
                            <Typography>
                                {intl.formatMessage({ id: "receive_scan_or_enter_station" })}
                            </Typography>
                            <BarcodeScanInput
                                value={stationInput}
                                onScan={onStationScan}
                                onChange={setStationInput}
                                focused
                            />
                        </Grid>

                        {stations ? (
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} />
                                <AccordionDetails>
                                    <SelectField
                                        value={undefined}
                                        onChange={onStationChanged}
                                        label={intl.formatMessage({ id: "receive_select_station" })}
                                        menuItems={
                                            stations
                                                ? stations.map((item) => {
                                                      return {
                                                          id: item.id,
                                                          value: item.name,
                                                          disabled: false,
                                                      }
                                                  })
                                                : []
                                        }
                                        placeholder={intl.formatMessage({
                                            id: "receive_select_station",
                                        })}
                                        large
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ) : null}
                    </Grid>
                }
                title={""}
                onClose={() => setStationModal(false)}
            />
            {showCompletedBundleDialog ? (
                <GenericModal
                    open={true}
                    CustomContent={
                        showStorageFilledPopup ? (
                            <>
                                <Typography>
                                    {intl.formatMessage({
                                        id: "receive_bundle_and_unit_complete",
                                    })}
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )
                    }
                    CustomActions={
                        <CustomButton
                            autoFocus={true}
                            text={intl.formatMessage({ id: "common_ok" })}
                            onClick={() => {
                                if (showStorageFilledPopup) {
                                    setShowStorageFilledPopup(null)
                                }
                                setShowCompletedBundleDialog(false)
                            }}
                        />
                    }
                    onClose={() => {
                        if (showStorageFilledPopup) {
                            setShowStorageFilledPopup(null)
                        }
                        setShowCompletedBundleDialog(false)
                        setShowNoStorageUnitAssignedDialog(false)
                    }}
                    title={
                        showStorageFilledPopup
                            ? ""
                            : intl.formatMessage({
                                  id: "receive_bundle_complete_dialog_title",
                              })
                    }
                />
            ) : null}
            {showNoStorageUnitAssignedDialog ? (
                <GenericModal
                    open={showNoStorageUnitAssignedDialog}
                    CustomContent={
                        <Typography>
                            {intl.formatMessage({
                                id: "receive_bundle_complete_no_su_assigned_dialog",
                            })}
                        </Typography>
                    }
                    CustomActions={
                        <CustomButton
                            autoFocus={true}
                            text={intl.formatMessage({ id: "common_ok" })}
                            onClick={() => {
                                setShowNoStorageUnitAssignedDialog(false)
                            }}
                        />
                    }
                    onClose={() => {
                        setShowNoStorageUnitAssignedDialog(false)
                    }}
                    title={intl.formatMessage({
                        id: "receive_page_no_su_assigned",
                    })}
                />
            ) : null}
            {!selectedBundle ? null : (
                <GenericModal
                    open={selectedBundle ? true : false}
                    CustomContent={
                        <>
                            <AllInOneTable<string>
                                headers={[
                                    {
                                        key: "ids",
                                        label: intl.formatMessage({
                                            id: "id",
                                        }),
                                    },
                                ]}
                                items={selectedBundle.packaging ?? []}
                                itemsToRow={mapBundle}
                                defaultSortBy={0}
                                initialLoad={initialLoad}
                                setInitialLoad={setInitialLoad}
                                hideSearchBar
                            />
                        </>
                    }
                    title={intl.formatMessage({ id: "receive_bundle_information" })}
                    onClose={() => setSelectedBundle(null)}
                />
            )}
            {!noStationAvailable ? null : (
                <GenericModal
                    open={true}
                    CustomContent={
                        <Grid container>
                            <FormattedMessage id="no_stations_explanation" />
                        </Grid>
                    }
                    CustomActions={
                        <Grid container spacing={2}>
                            <CustomButton
                                text={intl.formatMessage({ id: "create_station" })}
                                onClick={() => navigate("/warehouse/stations/add")}
                            />
                        </Grid>
                    }
                    title={intl.formatMessage({ id: "no_stations_on_account" })}
                    onClose={() => setNoStationAvailable(false)}
                />
            )}
        </Page>
    )
}
