import { ReactNode, createContext, useCallback, useMemo } from "react"
import { useInterval, useSessionStorage } from "usehooks-ts"
import { Location, PartnerClient, StationType } from "@repo/rezip-client/partner_client"
import { AnonymousClient, Me } from "@repo/rezip-client/anonymous_client"
import { ReZipClient } from "@repo/rezip-client"
import { UserAgreementMe, UserAgreementMeWithPermissionGroups } from "@repo/rezip-client/types"
import posthog from "posthog-js"

type Props = {
    children?: ReactNode
}

export interface IUserContext {
    getUser: () => User | null
    removeUser: () => void
    refreshUser: (token: string) => Promise<User | null | undefined>

    selectAgreement: (id: string) => void
    getSelectedAgreement: () => UserAgreementMeWithPermissionGroups | undefined

    token: string | null
    getToken: () => string
    login: (data: BasicCredentials) => Promise<string>
    logout: () => void

    getMfaUsed: () => boolean
    setMfaUsed: (x: boolean) => void

    setSelectedLocationId: (id: string | undefined) => void
    getSelectedLocationId: () => string | undefined
    getSelectedAccountType: () => string | undefined
    setUser: (user: User | null) => void
    setImposter: (a: UserAgreementMe | null) => void
    imposter: UserAgreementMe | null
    getSelectedStation: () => StationType | null
    setSelectedStation: (value: StationType | null) => void
}

export type PartnerLocations = {
    [id: string]: Location[]
}

export type User = Me & {
    agreements: UserAgreementMeWithPermissionGroups[]
}

export type Permission = {
    resource: string
    get: boolean
    put: boolean
    post: boolean
    patch: boolean
    delete: boolean
}

type BasicCredentials = {
    email?: string
    password?: string
}

const initialValue = {
    getUser: () => {
        return {} as User
    },
    removeUser: () => {
        return
    },
    refreshUser: async (token: string) => {
        console.log(`I want to fetch user details for user with token: ${token}`)
        return null
    },
    selectAgreement: (id: string) => {
        console.log(`I want to set agreement with id: ${id} as selected`)
    },
    getSelectedAgreement: () => {
        console.log("I want to get selected agreement or null if no agreement is selected")
        return undefined
    },
    token: null,
    login: async () => {
        console.log("Login failed")
        return ""
    },
    logout: () => {
        console.log("Logout failed")
    },
    getToken: () => {
        return "" as string
    },
    getPartnerLocations: () => {
        return {} as PartnerLocations
    },
    setSelectedLocationId: (id: string | undefined) => {
        return id
    },
    getSelectedLocationId: () => {
        return "" as string
    },
    getSelectedAccountType: () => {
        return "" as string
    },
    setSelectedAccountType: (type: string) => {
        console.log(type)
    },
    setUser: (user: User | null) => {
        return user
    },
    setImposter: (agreement: UserAgreementMe) => {
        console.log(agreement)
    },
    imposter: null,
    getMfaUsed: () => false,
    setMfaUsed: (x: boolean) => {
        console.log(x)
    },
    getSelectedStation: () => {
        return {} as StationType
    },
    setSelectedStation: (type: StationType | null) => {
        console.log("setting station", type)
    },
} as IUserContext

export const UserContext = createContext<IUserContext>(initialValue)

export const UserProvider = ({ children }: Props) => {
    const [user, setUser] = useSessionStorage<User | null>("user", null)
    const [token, setToken] = useSessionStorage("token", "")
    const [mfaUsed, setMfaUsed] = useSessionStorage("mfaUsed", false)

    const [selectedAgreementId, setSelectedAgreementId] = useSessionStorage<string>(
        "selectedAgreement",
        "",
    )
    const [station, setStation] = useSessionStorage<StationType | null>("station", null)

    const [imposter, setImposter] = useSessionStorage<UserAgreementMe | null>("imposter", null)

    const [selectedLocationId, setSelectedLocation] = useSessionStorage<string | undefined>(
        "selectedLocationId",
        "",
    )

    const getUser = useCallback(() => user, [user])

    const removeUser = useCallback(() => {
        if (imposter) {
            setImposter(null)
        }
        setUser(null)
        setSelectedAgreementId("")
        setSelectedLocation("")
    }, [imposter, setUser, setSelectedAgreementId, setSelectedLocation, setImposter])

    const tryAutoAccountSelect = useCallback(
        async (user: User, client: ReZipClient) => {
            if (user.agreements.length === 1) {
                const agreement = user.agreements[0]
                if (agreement.account.type === "Partner") {
                    const partnerClient = new PartnerClient(client)

                    const locations = await partnerClient.getLocations(agreement.account.id, {
                        filters: { deleted_at: "" },
                    })
                    if (locations.length === 1) {
                        setSelectedLocation(locations[0].id)
                    }
                } else {
                    setSelectedAgreementId(agreement.id)
                }
            }
        },
        [setSelectedAgreementId, setSelectedLocation],
    )

    const getMfaUsed = useCallback(() => mfaUsed, [mfaUsed])

    const refreshUser = useCallback(
        async (token: string) => {
            const client = new ReZipClient({ url: import.meta.env.VITE_REZIP_API_URL, token })

            const anonymousClient = new AnonymousClient(client)
            try {
                const [me, rawAgreements] = await Promise.all([
                    anonymousClient.me(),
                    anonymousClient.agreements(),
                ])
                if ((me.mfa_required || me.mfa_required_by_account) && !getMfaUsed()) {
                    setUser({ ...me, agreements: [] })
                    return
                }

                const agreements = await anonymousClient.fetchPermissionGroups(rawAgreements)
                const user = { ...me, agreements }
                setUser(user)

                tryAutoAccountSelect(user, client)
                return user
            } catch (error) {
                console.error(error)
                return null
            }
        },
        [getMfaUsed, setUser, tryAutoAccountSelect],
    )

    const selectAgreement = useCallback(
        (id: string) => {
            if (user !== null) {
                if (user.agreements !== null) {
                    const agreement = user.agreements.find((a) => a.id === id)
                    if (agreement) {
                        setSelectedAgreementId(agreement.id)
                    }
                } else {
                    console.error("Agreements is null")
                }
            } else {
                console.error("User is null")
            }
        },
        [user, setSelectedAgreementId],
    )

    const getSelectedAgreement = useCallback(() => {
        if (user !== null) {
            if (imposter) {
                return { ...imposter, permission_groups: [] }
            }
            if (user.agreements !== null) {
                const agreement = user.agreements.find((a) => a.id == selectedAgreementId)
                if (agreement) {
                    return agreement
                }
            }
        }
    }, [imposter, selectedAgreementId, user])

    const getSelectedLocationId = useCallback(() => selectedLocationId, [selectedLocationId])

    const setSelectedLocationId = useCallback(
        (id: string | undefined) => setSelectedLocation(id),
        [setSelectedLocation],
    )

    const setSelectedStation = useCallback(
        (station: StationType | null) => {
            setStation(station)
        },
        [setStation],
    )
    const getSelectedStation = useCallback(() => station, [station])

    const requestToken = useCallback(
        async (creds: BasicCredentials) => {
            const client = new ReZipClient({ url: import.meta.env.VITE_REZIP_API_URL, token })

            const anonymousClient = new AnonymousClient(client)

            try {
                if (creds.email && creds.password) {
                    const token = await anonymousClient.login({
                        email: creds.email,
                        password: creds.password,
                    })
                    posthog.identify(creds.email)
                    setToken(token)
                    return token
                }
                return ""
            } catch (error) {
                console.error(error)
                return ""
            }
        },
        [setToken, token],
    )

    const login = useCallback(
        async (creds: BasicCredentials) => {
            const token: string = await requestToken(creds)
            return token.length > 0 ? token : ""
        },
        [requestToken],
    )

    const logout = useCallback(() => {
        setToken("")
        setUser(null)
        setMfaUsed(false)
        setSelectedLocation("")
    }, [setToken, setUser, setMfaUsed, setSelectedLocation])

    const getToken = useCallback(() => token, [token])

    const getSelectedAccountType = useCallback(() => {
        if (imposter) {
            return imposter.account.type
        }

        return getSelectedAgreement()?.account.type ?? ""
    }, [getSelectedAgreement, imposter])

    useInterval(
        async () => {
            const client = new ReZipClient({ url: import.meta.env.VITE_REZIP_API_URL, token })

            const anonymousClient = new AnonymousClient(client)

            try {
                const pong = await anonymousClient.ping()
                if (pong.user === "<anonymous>") {
                    logout()
                }
            } catch (e) {
                console.error(e)
            }
        },
        1000 * 60 * 5,
    )

    const value = useMemo(
        () => ({
            getUser,
            removeUser,
            refreshUser,
            selectAgreement,
            getSelectedAgreement,
            token,
            login,
            logout,
            getToken,
            getMfaUsed,
            setMfaUsed,
            setSelectedLocationId,
            getSelectedLocationId,
            getSelectedAccountType,
            setUser,
            setImposter,
            imposter,
            setSelectedStation,
            getSelectedStation,
        }),
        [
            getUser,
            removeUser,
            refreshUser,
            selectAgreement,
            getSelectedAgreement,
            token,
            login,
            logout,
            getToken,
            getMfaUsed,
            setMfaUsed,
            setSelectedLocationId,
            getSelectedLocationId,
            getSelectedAccountType,
            setUser,
            setImposter,
            imposter,
            setSelectedStation,
            getSelectedStation,
        ],
    )

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
