import { Box, IconButton, Typography, Grid } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import InputField from "src/components/InputField"
import Page from "src/components/layout/Page"
import SwitchField from "src/components/SwitchField"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import EditIcon from "@mui/icons-material/Edit"
import { SettingsOrdering } from "@repo/rezip-client/partner_client"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import cc from "currency-codes"
import { useClient } from "src/hooks/useClient.hook"
import { Product } from "@repo/rezip-client/types"
import { CurrencyInput } from "src/components/generalComponents/CurrencyInput"
import { CustomButton } from "src/components/generalComponents/Buttons"

export const ShopAssortment = () => {
    const intl = useIntl()
    const { getSelectedAgreement } = useUser()
    const { partnerClient } = useClient()
    const { id } = useParams()
    const partnerId = getSelectedAgreement()?.account.id
    const [products, setProducts] = useState<Product[]>([])
    const [orderConfig, setOrderConfig] = useState<SettingsOrdering>()
    const [tempName, setTempName] = useState("")
    const [editableItem, setEditableItem] = useState<string>("")
    const [tempItemCost, setTempItemCost] = useState(0)
    const { enqueueAlert } = useSnackbar()
    const [initialLoad, setInititalLoad] = useState(true)

    useEffect(() => {
        if (id && !orderConfig && partnerId) {
            const fetchConfig = async () => {
                setOrderConfig(await partnerClient.getShopOrdering({ partnerId, shopId: id }))
            }
            fetchConfig()
        }
    }, [id, orderConfig, partnerId, partnerClient])

    useEffect(() => {
        if (!partnerId) {
            return
        }

        const fetchAssortments = async () => {
            if (!id || !orderConfig) {
                return
            }
            const [partnerAssortment, shopAssortment] = await Promise.all([
                partnerClient.getProducts(partnerId, {
                    pageSize: 100,
                    filters: { deleted_at: "" },
                }),
                partnerClient.getAssortment(partnerId, id, { pageSize: 100 }),
            ])

            const filteredPartner = (partnerAssortment ?? []).filter((item) => !item.deleted_at)

            const mappedProducts = (filteredPartner ?? []).map((partnerItem) => {
                const shopItem = (shopAssortment ?? []).find((pa) => pa.sku === partnerItem.sku)
                if (shopItem) {
                    return {
                        ...partnerItem,
                        id: shopItem.id,
                        dimension: { ...partnerItem.dimension },
                        price: shopItem.price ?? partnerItem.price,
                        currency: orderConfig?.currency ?? partnerItem.currency,
                        active: true,
                    }
                }
                return {
                    ...partnerItem,
                    currency: orderConfig?.currency ?? partnerItem.currency,
                    price: partnerItem.price,
                    active: false,
                }
            })

            setProducts(mappedProducts)
        }
        fetchAssortments()
    }, [id, partnerId, partnerClient, orderConfig])

    const activateProduct = useCallback(
        (product: Product) => {
            const updatedProducts = products.map((item) => {
                if (item.sku === product.sku) {
                    return { ...item, active: !item.active }
                }
                return item
            })
            setProducts(updatedProducts)
            setInititalLoad(true)
        },
        [products],
    )

    const patchProducts = useCallback(
        async (product: Product) => {
            if (!partnerId || !id) {
                return
            }
            partnerClient.updateAssortment(
                { partnerId, shopId: id, assortmentId: product.id },
                {
                    price: tempItemCost,
                    name: tempName,
                    currency: orderConfig?.currency ?? product.currency ?? "",
                    sku: product.sku,
                },
            )
            enqueueAlert(
                intl.formatMessage(
                    {
                        id: "product_was_updated",
                    },
                    { productName: product.sku },
                ),
                "success",
            )
            const updatedProducts = products.map((item) => {
                if (item.sku === product.sku) {
                    return { ...item, price: tempItemCost, name: tempName }
                }
                return item
            })
            setProducts(updatedProducts)
            setEditableItem("")
            setTempItemCost(0)
            setTempName("")
        },
        [
            enqueueAlert,
            id,
            intl,
            orderConfig?.currency,
            partnerId,
            products,
            tempItemCost,
            tempName,
            partnerClient,
        ],
    )

    const handleSwitch = useCallback(
        async (product: Product) => {
            if (!partnerId || !id) {
                return
            }
            if (product.active) {
                partnerClient.deleteAssortment({ partnerId, shopId: id, assortmentId: product.id })
                activateProduct(product)
                enqueueAlert(
                    intl.formatMessage(
                        {
                            id: "product_was_updated",
                        },
                        { productName: product.sku },
                    ),
                    "success",
                )
            } else {
                partnerClient.createAssortment(
                    { partnerId, shopId: id },
                    {
                        price: product.price,
                        currency: orderConfig?.currency ?? product.currency ?? "",
                        sku: product.sku,
                    },
                )
                activateProduct(product)
                enqueueAlert(
                    intl.formatMessage(
                        {
                            id: "product_was_updated",
                        },
                        { productName: product.sku },
                    ),
                    "success",
                )
            }
        },
        [partnerId, id, activateProduct, enqueueAlert, intl, orderConfig?.currency, partnerClient],
    )

    const productGroups = useMemo(() => {
        if (!products) {
            return {}
        }

        const groups: Record<string, Product[]> = {
            "Self-Standing Box": [],
            "Paper Bag": [],
            Bag: [],
            Box: [],
            "Premium Bag": [],
            Accessory: [],
        }

        products.forEach((product) => {
            if (!groups[product.group.name]) {
                groups[product.group.name] = []
            }
            groups[product.group.name].push(product)
        })

        return groups
    }, [products])

    const groupKeys = useMemo(() => {
        return Object.keys(productGroups)
    }, [productGroups])

    const productToRow = useCallback(
        (product: Product, index: number) => {
            if (!products) return <></>
            return (
                <StyledBodyRow key={`${index}-${product.sku}`}>
                    <StyledTableCell>
                        <InputField
                            id={""}
                            name={""}
                            type={""}
                            disabled={editableItem !== product.id}
                            value={editableItem === product.id ? tempName : product.name}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setTempName(event.target.value)
                            }
                        />
                    </StyledTableCell>
                    <StyledTableCell>{product.sku}</StyledTableCell>
                    <StyledTableCell>
                        {product.dimension &&
                        product.dimension.height &&
                        product.dimension.width &&
                        product.dimension.depth
                            ? `${product.dimension.height} x ${product.dimension.width} x ${product.dimension.depth}`
                            : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "20%" }}>
                        <CurrencyInput
                            disabled={editableItem !== product.id}
                            defaultValue={product.price}
                            currency={product.currency ? cc.code(product.currency) || null : null}
                            onChange={(e) => {
                                setTempItemCost(Number(e))
                            }}
                        />
                    </StyledTableCell>
                    {/* idk stolen from v2, maybe it makes sense?*/}
                    <StyledTableCell>{product.sku.search(/AC|AS/i) == -1 ? 10 : 1}</StyledTableCell>
                    <StyledTableCell sx={{ width: "5%" }}>
                        <SwitchField
                            id={`${product.id}-switch`}
                            value={product.active}
                            checked={product.active}
                            onClick={() => handleSwitch(product)}></SwitchField>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "12%" }}>
                        {editableItem === product.id ? (
                            <Grid
                                container
                                spacing={1}
                                maxWidth={{ xs: "100%", lg: "80%" }}
                                sx={{ display: "flex", flexDirection: "row" }}>
                                <CustomButton
                                    text={intl.formatMessage({
                                        id: "common_cancel",
                                    })}
                                    key={`${index}-cancel`}
                                    onClick={() => {
                                        setEditableItem("")
                                        setTempItemCost(0)
                                        setTempName("")
                                    }}
                                />
                                <CustomButton
                                    text={intl.formatMessage({
                                        id: "common_save",
                                    })}
                                    key={`${index}-save`}
                                    onClick={() => patchProducts(product)}
                                />
                            </Grid>
                        ) : (
                            <IconButton
                                key={`${index}-edit`}
                                onClick={() => {
                                    setEditableItem(product.id)
                                    setTempItemCost(product.price)
                                    setTempName(product.name)
                                }}>
                                <EditIcon />
                            </IconButton>
                        )}
                    </StyledTableCell>
                </StyledBodyRow>
            )
        },
        [products, editableItem, tempName, intl, handleSwitch, patchProducts],
    )

    const header = [
        {
            label: intl.formatMessage({
                id: "common_product",
            }),
            key: "name",
            excludeFromSearch: true,
        },
        {
            label: intl.formatMessage({
                id: "common_sku",
            }),
            key: "SKU",
            excludeFromSearch: true,
        },
        {
            label: intl.formatMessage({
                id: "common_size",
            }),
            key: "Size",
            excludeFromSearch: true,
        },
        {
            label: intl.formatMessage({
                id: "common_item_cost",
            }),
            key: "item-cost",
            excludeFromSearch: true,
        },
        {
            label: intl.formatMessage({
                id: "common_moq",
            }),
            key: "MOQ",
            excludeFromSearch: true,
        },
        {
            label: intl.formatMessage({
                id: "common_ion_stock",
            }),
            key: "in-stock",
            excludeFromSearch: true,
        },
        { label: "", key: "edit_action", excludeFromSearch: true },
    ]

    return (
        <Page
            backButton
            title={intl.formatMessage({
                id: "page_shop_assortment",
            })}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {groupKeys.map((item) => {
                    if (productGroups[item].length === 0) {
                        return
                    }
                    return (
                        <DefaultWrapperPaper>
                            <Grid container spacing={1} direction={"column"}>
                                <Typography variant="h3">
                                    {item !== "Accessory"
                                        ? intl.formatMessage(
                                              {
                                                  id: `order_plural_item`,
                                              },
                                              {
                                                  item: item,
                                                  suffix: item.endsWith("x")
                                                      ? "es"
                                                      : item.endsWith("s")
                                                        ? ""
                                                        : "s",
                                              },
                                          )
                                        : intl.formatMessage({ id: "order_accessories" })}
                                </Typography>
                                <AllInOneTable<Product>
                                    headers={header}
                                    items={productGroups[item] ?? []}
                                    itemsToRow={productToRow}
                                    defaultSortBy={"sku"}
                                    initialLoad={initialLoad}
                                    setInitialLoad={setInititalLoad}
                                    hideSearchBar
                                />
                            </Grid>
                        </DefaultWrapperPaper>
                    )
                })}
            </Box>

            <></>
        </Page>
    )
}
