import { Typography } from "@mui/material"
import { OrderState } from "./commonTypes"
import { FormattedMessage } from "react-intl"

export const getOrderStateTitle = (state: number): React.ReactNode => {
    switch (state) {
        case OrderState.Open:
            return (
                <Typography className={"status open"}>
                    <FormattedMessage id="orders_dropdown_open" />
                </Typography>
            )
        case OrderState.Fulfilled:
            return (
                <Typography className={"status fulfilled"}>
                    <FormattedMessage id="orders_dropdown_fulfilled" />
                </Typography>
            )
        case OrderState.Invoiced:
            return (
                <Typography className={"status invoiced"}>
                    <FormattedMessage id="orders_dropdown_invoiced" />
                </Typography>
            )
        case OrderState.Payed:
            return (
                <Typography className={"status paid"}>
                    <FormattedMessage id="orders_dropdown_paid" />
                </Typography>
            )
        default:
            return "Unknown"
    }
}
