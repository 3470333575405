import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useUser } from "src/contexts/UserConsumer"
import { Box } from "@mui/material"

import Page from "src/components/layout/Page"
import { AccountSelectItem } from "src/components/accountItems/AccountChangerItem"
import { MfaModal } from "src/components/modals/MfaModal"
import { CustomButton } from "src/components/generalComponents/Buttons"

export const AccountChanger = () => {
    const [mfaOpen, setMfaOpen] = useState(true)
    const intl = useIntl()
    const { getUser, getMfaUsed, refreshUser, getToken } = useUser()
    const user = getUser()
    const userAgreements = user?.agreements
    const verify = useCallback(() => {
        return
    }, [])
    const [runOnce, setRunOnce] = useState(false)

    useEffect(() => {
        if (user && getMfaUsed() && !runOnce) {
            refreshUser(getToken())
            setRunOnce(true)
        }
    }, [getMfaUsed, getToken, refreshUser, runOnce, user, userAgreements])

    const content = useMemo(() => {
        if (user && (user.mfa_required || user.mfa_required_by_account) && !getMfaUsed()) {
            return (
                <Box>
                    <CustomButton
                        text={intl.formatMessage({ id: "open_mfa_window" })}
                        onClick={() => setMfaOpen(true)}
                    />
                    <MfaModal open={mfaOpen} setOpen={setMfaOpen} onVerify={verify} />
                </Box>
            )
        } else if (userAgreements && userAgreements.length >= 1) {
            return <AccountSelectItem />
        } else {
            return (
                <FormattedMessage
                    id="frontpage_no_agreements"
                    defaultMessage="You have no agreements."
                />
            )
        }
    }, [getMfaUsed, intl, mfaOpen, user, userAgreements, verify])

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_home",
            })}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                {content}
            </Box>
        </Page>
    )
}
