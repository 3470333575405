import { IMenuItemData } from "src/components/SelectField"
import cc, { CurrencyCodeRecord } from "currency-codes"
import useCountryList from "./countries"

export const useCurrencyMenuData = () => {
    const currencies = useCurrencies()
    const menu = currencies
        .map((x) => x.code)
        .map((item, index) => {
            return { id: `${item}-${index}`, value: item } as IMenuItemData
        })

    return menu
}

export const useCurrencies = (): CurrencyCodeRecord[] => {
    const countries = useCountryList().filter((x) => !x.disabled)
    return [...new Set(countries.flatMap((x) => [...cc.country(x.value), ...cc.country(x.id)]))]
}

export const useCurrencyMap = (): Record<string, CurrencyCodeRecord> => {
    const currencies = useCurrencies()
    return Object.fromEntries(currencies.map((x) => [x.code, x]))
}
