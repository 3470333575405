import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid } from "@mui/material"
import { useIntl } from "react-intl"
import Page from "src/components/layout/Page"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export const Legal = () => {
    const intl = useIntl()

    return (
        <Page title={intl.formatMessage({ id: "menu_listitem_header_legal" })}>
            <Grid container direction="column" spacing={2}>
                <DefaultWrapperPaper>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h2">Terms of Use</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ fontWeight: "500" }}>
                                <h3>1. Introduction</h3>
                                <p>
                                    This website is operated by <strong>RE-ZIP</strong>. The terms
                                    “we”, “us”, and “our” refer to<strong> RE-ZIP</strong>. The use
                                    of The RE-ZIP Manager referred to as “the website” is subject to
                                    the following terms and conditions of use, as amended from time
                                    to time (the “Terms”). The Terms, along with any terms,
                                    conditions, or disclaimers provided in the pages of our website
                                    under the 'Legal' section, are to be read together by you.
                                    Please review the Terms carefully. The Terms apply to all users
                                    of our website. If you access and use this website, you accept
                                    and agree to be bound by and comply with the Terms and our
                                    Privacy Policy. If you do not agree to the Terms or our Privacy
                                    Policy, you are not authorized to access our website, use any of
                                    our website’s services or place an order on our website.
                                </p>
                                <h3>2. System Description and User Instruction</h3>
                                <p>
                                    The RE-ZIP Manager provides a comprehensive system with three
                                    interfaces: Shop, Partner, and Drop Point. Each interface serves
                                    a specific purpose and offers unique functionalities related to
                                    the specific interface.
                                </p>
                                <p>
                                    As a user of the RE-ZIP Manager, it is important to follow the
                                    provided instructions and guidelines outlined in our user
                                    guides. These guides serve as resources to assist you in
                                    utilizing the system and ensuring a seamless user experience.
                                </p>
                                <p>
                                    Please note that any data entered or synchronized within the
                                    website, including company name, link to company website and
                                    company address (solely when utilizing the Drop Point interface)
                                    may be utilized within the RE-ZIP mobile application. This usage
                                    enables the app to offer various features and services,
                                    particularly when providing rewards to customers or guiding them
                                    to the Drop Point.
                                </p>
                                <p>
                                    By using the RE-ZIP platform, you acknowledge and agree to
                                    comply with our use of data.
                                </p>
                                <h3>3. Use of our Website</h3>
                                <p>
                                    You agree to use our website for legitimate purposes and not for
                                    any illegal or unauthorized purpose, including without
                                    limitation, in violation of any intellectual property or privacy
                                    law. By agreeing to the Terms, you represent and warrant that
                                    you are at least the age of majority in your state or province
                                    of residence and are legally capable of entering into a binding
                                    contract. You agree to not use our website to conduct any
                                    activity that would constitute a civil or criminal offence or
                                    violate any law. You agree not to attempt to interfere with our
                                    website’s network or security features or to gain unauthorized
                                    access to our systems. You agree to provide us with accurate
                                    information, such as email address, mailing address and other
                                    contact details in order to complete your order or contact you
                                    as needed. You agree to promptly update your account and
                                    information. You authorize us to collect and use this
                                    information to contact you in accordance with our Privacy
                                    Policy.
                                </p>
                                <h3>4. General Conditions</h3>
                                <p>
                                    We reserve the right to refuse service to anyone, at any time,
                                    for any reason. We reserve the right to make any modifications
                                    to the website, including terminating, changing, suspending or
                                    discontinuing any aspect of the website at any time, without
                                    notice. We may impose additional rules or limits on the use of
                                    our website. You agree to review the Terms regularly and your
                                    continued access or use of our website will mean that you agree
                                    to any changes. You agree that we will not be liable to you or
                                    any third party for any modification, suspension, or
                                    discontinuance of our website or for any service, content,
                                    feature or product offered through our website.
                                </p>
                                <h3>5. Products or Services</h3>
                                <p>
                                    All orders through our website are subject to product
                                    availability. We may, in our sole discretion, limit or cancel
                                    the quantities offered on our website or limit the sales of our
                                    products or services to any person, household, geographic region
                                    or jurisdiction. Prices for our products are subject to change,
                                    without notice. We reserve the right, in our sole discretion, to
                                    refuse orders, including without limitation, orders that appear
                                    to be placed by distributors or resellers. If we believe that
                                    you have made a false or fraudulent order, we will be entitled
                                    to cancel the order and inform the relevant authorities.
                                </p>
                                <h3>6. Links to Third-Party Websites</h3>
                                <p>
                                    Links from or to websites outside our website are meant for
                                    convenience only. We do not review, endorse, approve or control,
                                    and are not responsible for any sites linked from or to our
                                    website, the content of those sites, the third parties named
                                    therein, or their products and services. Linking to any other
                                    site is at your sole risk and we will not be responsible or
                                    liable for any damages in connection with linking. Links to
                                    downloadable software sites are for convenience only and we are
                                    not responsible or liable for any difficulties or consequences
                                    associated with downloading the software. Use of any downloaded
                                    software is governed by the terms of the license agreement, if
                                    any, which accompanies or is provided with the software.
                                </p>
                                <h3>7. Use Comments, Feedback, and Other Submissions</h3>
                                <p>
                                    You acknowledge that you are responsible for the information,
                                    profiles, and any other content (collectively, the “Content”)
                                    that you distribute or share on or through our website or
                                    services available in connection with our website. You further
                                    acknowledge that you have full responsibility for the Content,
                                    including but limited to, with respect to its legality, and its
                                    trademark, copyright and other intellectual property ownership.
                                    You agree that any Content submitted by you in response to a
                                    request by us for a specific submission may be edited, adapted,
                                    modified, recreated, published, or distributed by us. You
                                    further agree that we are under no obligation to maintain any
                                    Content in confidence, to pay compensation for any Content or to
                                    respond to any Content. You agree that you will not post,
                                    distribute, or share any Content on our website that is
                                    protected by copyright, trademark, patent or any other
                                    proprietary right without the express consent of the owner of
                                    such proprietary right. You further agree that your Content will
                                    not be unlawful, abusive or obscene nor will it contain any
                                    malware or computer virus that could affect our website’s
                                    operations. You will be solely liable for any Content that you
                                    make and its accuracy. We have no responsibility and assume no
                                    liability for any Content posted by you or any third-party. We
                                    reserve the right to terminate your ability to post on our
                                    website and to remove and/or delete any Content that we deem
                                    objectionable. You consent to such removal and/or deletion and
                                    waive any claim against us for the removal and/or deletion of
                                    your Content.
                                </p>
                                <h3>8. Your Personal Information</h3>
                                <p>
                                    Please see our Privacy Policy to learn about how we collect,
                                    use, and share your personal information.
                                </p>
                                <h3>9. Errors and Omissions</h3>
                                <p>
                                    Please note that our website may contain typographical errors or
                                    inaccuracies and may not be complete or current. We reserve the
                                    right to correct any errors, inaccuracies, or omissions and to
                                    change or update information at any time, without prior notice
                                    (including after an order has been submitted). Such errors,
                                    inaccuracies or omissions may relate to pricing, availability,
                                    marketing material and guides, and we reserve the right to
                                    cancel or refuse any order placed based on incorrect pricing or
                                    availability information, to the extent permitted by applicable
                                    law. We do not undertake to update, modify or clarify
                                    information on our website, except as required by law.
                                </p>
                                <h3>10. Disclaimer and Limitation of Liability</h3>
                                <p>
                                    You assume all responsibility and risk with respect to your use
                                    of our website, which is provided “as is” without warranties,
                                    representations or conditions of any kind, either express or
                                    implied, with regard to information accessed from or via our
                                    website, including without limitation, all content and
                                    materials, and functions and services provided on our website,
                                    all of which are provided without warranty of any kind,
                                    including but not limited to warranties concerning the
                                    availability, accuracy, completeness or usefulness of content or
                                    information, uninterrupted access, and any warranties of title,
                                    non-infringement, merchantability or fitness for a particular
                                    purpose. We do not warrant that our website or its functioning
                                    or the content and material of the services made available
                                    thereby will be timely, secure, uninterrupted or error-free,
                                    that defects will be corrected, or that our websites or the
                                    servers that make our website available are free of viruses or
                                    other harmful components. The use of our website is at your sole
                                    risk and you assume full responsibility for any costs associated
                                    with your use of our website. We will not be liable for any
                                    damages of any kind related to the use of our website.
                                </p>
                                <p>
                                    In no event will we, or our affiliates, our or their respective
                                    content or service providers, or any of our or their respective
                                    directors, officers, agents, contractors, suppliers or employees
                                    be liable to you for any direct, indirect, special, incidental,
                                    consequential, exemplary or punitive damages, losses or causes
                                    of action, or lost revenue, lost profits, lost business or
                                    sales, or any other type of damage, whether based in contract or
                                    tort (including negligence), strict liability or otherwise,
                                    arising from your use of, or the inability to use, or the
                                    performance of, our website or the content or material or
                                    functionality through our website, even if we are advised of the
                                    possibility of such damages. Certain jurisdictions do not allow
                                    limitation of liability or the exclusion or limitation of
                                    certain damages. In such jurisdictions, some or all of the above
                                    disclaimers, exclusions, or limitations, may not apply to you
                                    and our liability will be limited to the maximum extent
                                    permitted by law.
                                </p>
                                <h3>11. Indemnification</h3>
                                <p>
                                    You agree to defend and indemnify us, and hold us and our
                                    affiliates harmless, and our and their respective directors,
                                    officers, agents, contractors, and employees against any losses,
                                    liabilities, claims, expenses (including legal fees) in any way
                                    arising from, related to or in connection with your use of our
                                    website, your violation of the Terms, or the posting or
                                    transmission of any materials on or through the website by you,
                                    including but not limited to, any third party claim that any
                                    information or materials provided by you infringe upon any third
                                    party proprietary rights.
                                </p>
                                <h3>12. Entire Agreement</h3>
                                <p>
                                    The Terms and any documents expressly referred to in them
                                    represent the entire agreement between you and us in relation to
                                    the subject matter of the Terms and supersede any prior
                                    agreement, understanding or arrangement between you and us,
                                    whether oral or in writing. Both you and we acknowledge that, in
                                    entering into these Terms, neither you nor we have relied on any
                                    representation, undertaking or promise given by the other or
                                    implied from anything said or written between you and us prior
                                    to such Terms, except as expressly stated in the Terms.
                                </p>
                                <h3>13. Waiver</h3>
                                <p>
                                    Our failure to exercise or enforce any right or provision of the
                                    Terms will not constitute a waiver of such right or provision. A
                                    waiver by us of any default will not constitute a waiver of any
                                    subsequent default. No waiver by us is effective unless it is
                                    communicated to you in writing.
                                </p>
                                <h3>14. Headings</h3>
                                <p>Any headings and titles herein are for convenience only.</p>
                                <h3>15. Severability</h3>
                                <p>
                                    If any of the provisions of the Terms are determined by any
                                    competent authority to be invalid, unlawful or unenforceable,
                                    such provision will to that extent be severed from the remaining
                                    Terms, which will continue to be valid and enforceable to the
                                    fullest extent permitted by law.
                                </p>
                                <h3>16. Governing Law</h3>
                                <p>
                                    Any disputes arising out of or relating to the Terms, the
                                    Privacy Policy, use of our website, or our products or services
                                    offered on our website will be resolved in accordance with the
                                    laws of Denmark, without regard to its conflict of law rules.
                                    Any disputes, actions or proceedings relating to the Terms or
                                    your access to or use of our website must be brought before the
                                    courts of Denmark, and you irrevocably consent to the exclusive
                                    jurisdiction and venue of such courts.
                                </p>
                                <h3>17. Questions or Concerns</h3>
                                <p>
                                    Please send all questions, comments and feedback to us at
                                    support@re-zip.com.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </DefaultWrapperPaper>

                <DefaultWrapperPaper>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h2">Data Processing Agreement</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ fontWeight: "500" }}>
                                <p>
                                    This Data Processing Agreement (“<strong>DPA</strong>”) is an
                                    addendum to the Terms of Use (“<strong>Agreement</strong>”)
                                    between RE-ZIP, (“
                                    <strong>RE-ZIP</strong>”) and the Company. RE-ZIP and Company
                                    are individually a “<strong>party</strong>” and, collectively,
                                    the “<strong>parties.</strong>”
                                </p>
                                <p>
                                    This DPA is applicable where and only to the extent that RE-ZIP
                                    processes Personal Data on behalf of the Company in the course
                                    of providing the Services and such Personal Data with the
                                    requirements of the current legal framework in relation to data
                                    processing and with the Regulation (EU) 2016/679 of the European
                                    Parliament and of the Council of 27 April 2016 on the protection
                                    of natural persons with regard to the processing of personal
                                    data and on the free movement of such data, and repealing
                                    Directive 95/46/EC (General Data Protection Regulation).
                                </p>
                                <p>
                                    The Company, acting as a Data Controller, wishes to subcontract
                                    certain Services that involve the processing of personal data to
                                    the Data Processor. The parties agree to comply with the terms
                                    and conditions in this DPA in connection with such Personal
                                    Data.
                                </p>
                                <p>
                                    The duration of the Processing covered by this DPA shall be in
                                    accordance with the duration of the Agreement.
                                </p>
                                <p>IT IS AGREED AS FOLLOWS:</p>
                                <h3>1. Definitions and Interpretation</h3>
                                <p>
                                    1.1 Unless otherwise defined herein, capitalized terms and
                                    expressions used in this Agreement shall have the following
                                    meaning:
                                </p>
                                <div>
                                    <p>
                                        1.1.1 "<strong>Agreement</strong>" means this Data
                                        Processing Agreement and all Schedules;
                                    </p>
                                    <p>
                                        1.1.2 "<strong>Company Personal Data</strong>" means any
                                        Personal Data Processed by a Contracted Processor on behalf
                                        of Company pursuant to or in connection with the Principal
                                        Agreement;
                                    </p>
                                    <p>
                                        1.1.3 "<strong>Contracted Processor</strong>" means a
                                        Subprocessor;
                                    </p>
                                    <p>
                                        1.1.4 "<strong>Data Protection Laws</strong>" means EU Data
                                        Protection Laws and, to the extent applicable, the data
                                        protection or privacy laws of any other country;
                                    </p>
                                    <p>
                                        1.1.5 "<strong>EEA</strong>" means the European Economic
                                        Area;
                                    </p>
                                    <p>
                                        1.1.6 "<strong>EU Data Protection Laws</strong>" means EU
                                        Directive 95/46/EC, as transposed into domestic legislation
                                        of each Member State and as amended, replaced or superseded
                                        from time to time, including by the GDPR and laws
                                        implementing or supplementing the GDPR;
                                    </p>
                                    <p>
                                        1.1.7 "<strong>GDPR</strong>" means EU General Data
                                        Protection Regulation 2016/679;
                                    </p>
                                    <p>
                                        1.1.8 "<strong>Data Transfer</strong>" means:
                                    </p>
                                    <div>
                                        <p>
                                            1.1.8.1 a transfer of Company Personal Data from the
                                            Company to a Contracted Processor; or
                                        </p>
                                        <p>
                                            1.1.8.2 an onward transfer of Company Personal Data from
                                            a Contracted Processor to a Subcontracted Processor, or
                                            between two establishments of a Contracted Processor,
                                        </p>
                                        <p>
                                            in each case, where such transfer would be prohibited by
                                            Data Protection Laws (or by the terms of data transfer
                                            agreements put in place to address the data transfer
                                            restrictions of Data Protection Laws);
                                        </p>
                                    </div>
                                    <p>
                                        1.1.9 "<strong>Services</strong>" means the services the
                                        Company provides in the RE-ZIP Manager.
                                    </p>
                                    <p>
                                        1.1.10 "<strong>Subprocessor</strong>" means any person
                                        appointed by or on behalf of Processor to process Personal
                                        Data on behalf of the Company in connection with the
                                        Agreement.
                                    </p>
                                </div>
                                <p>
                                    1.2 The terms, "<strong>Commission</strong>", "
                                    <strong>Controller</strong>", "<strong>Data Subject</strong>", "
                                    <strong>Member State</strong>", "<strong>Personal Data</strong>
                                    ", "<strong>Personal Data Breach</strong>", "
                                    <strong>Processing</strong>" and "
                                    <strong>Supervisory Authority</strong>" shall have the same
                                    meaning as in the GDPR, and their cognate terms shall be
                                    construed accordingly.
                                </p>
                                <h3>2. Processing of Company Personal Data</h3>
                                <p>2.1 Processor shall:</p>
                                <div>
                                    <p>
                                        2.1.1 comply with all applicable Data Protection Laws in the
                                        Processing of Company Personal Data; and
                                    </p>
                                    <p>
                                        2.1.2 refrain from processing Company Personal Data beyond
                                        the scope of the information provided in Appendix I.
                                    </p>
                                </div>
                                <h3>3. Processor Personnel</h3>
                                <p>
                                    Processor shall take reasonable steps to ensure the reliability
                                    of any employee, agent or contractor of any Contracted Processor
                                    who may have access to the Company Personal Data, ensuring in
                                    each case that access is strictly limited to those individuals
                                    who need to know / access the relevant Company Personal Data, as
                                    strictly necessary for the purposes of the Principal Agreement,
                                    and to comply with Applicable Laws in the context of that
                                    individual's duties to the Contracted Processor, ensuring that
                                    all such individuals are subject to confidentiality undertakings
                                    or professional or statutory obligations of confidentiality.
                                </p>
                                <h3>4. Security</h3>
                                <p>
                                    4.1 Taking into account the state of the art, the costs of
                                    implementation and the nature, scope, context and purposes of
                                    Processing as well as the risk of varying likelihood and
                                    severity for the rights and freedoms of natural persons,
                                    Processor shall in relation to the Company Personal Data
                                    implement appropriate technical and organizational measures to
                                    ensure a level of security appropriate to that risk, including,
                                    as appropriate, the measures referred to in Article 32(1) of the
                                    GDPR.
                                </p>
                                <p>
                                    4.2 In assessing the appropriate level of security, Processor
                                    shall take account in particular of the risks that are presented
                                    by Processing, in particular from a Personal Data Breach.
                                </p>
                                <h3>5. Subprocessing</h3>
                                <p>
                                    The Processor shall not engage the services of any Subprocessor
                                    (or disclose any Company Personal Data to them) unless strictly
                                    necessary.
                                    <br />
                                    The Processor has the general approval of the Company to utilize
                                    subprocessors listed in Appendix 1.
                                </p>
                                <p>
                                    The Processor will specifically notify the Company in writing of
                                    any intended changes to this list, including the addition or
                                    replacement of subprocessors, at least 14 days in advance,
                                    providing the Controller with the opportunity to raise
                                    objections to such changes before any agreement is made with the
                                    respective subprocessor(s).
                                    <br />
                                    The Processor will provide the Company with the necessary
                                    information to exercise its right to object.
                                </p>
                                <h3>6. Data Subject Rights</h3>
                                <p>
                                    6.1 Taking into account the nature of the Processing, Processor
                                    shall assist the Company by implementing appropriate technical
                                    and organizational measures, insofar as this is possible, for
                                    the fulfilment of the Company obligations, as reasonably
                                    understood by Company, to respond to requests to exercise Data
                                    Subject rights under the Data Protection Laws.
                                </p>
                                <p>6.2 Processor shall:</p>
                                <div>
                                    <p>
                                        6.2.1 promptly notify Company if it receives a request from
                                        a Data Subject under any Data Protection Law in respect of
                                        Company Personal Data; and
                                    </p>
                                    <p>
                                        6.2.2 ensure that it does not respond to that request except
                                        on the documented instructions of Company or as required by
                                        Applicable Laws to which the Processor is subject, in which
                                        case Processor shall to the extent permitted by Applicable
                                        Laws inform Company of that legal requirement before the
                                        Contracted Processor responds to the request.
                                    </p>
                                </div>
                                <h3>7. Personal Data Breach</h3>
                                <p>
                                    7.1 Processor shall notify Company without undue delay upon
                                    Processor becoming aware of a Personal Data Breach affecting
                                    Company Personal Data, providing Company with sufficient
                                    information to allow the Company to meet any obligations to
                                    report or inform Data Subjects of the Personal Data Breach under
                                    the Data Protection Laws.
                                </p>
                                <p>
                                    7.2 Processor shall co-operate with the Company and take
                                    reasonable commercial steps as are directed by Company to assist
                                    in the investigation, mitigation, and remediation of each such
                                    Personal Data Breach.
                                </p>
                                <h3>8. Data Protection Impact Assessment and Prior Consultation</h3>
                                <p>
                                    Processor shall provide reasonable assistance to the Company
                                    with any data protection impact assessments, and prior
                                    consultations with Supervising Authorities or other competent
                                    data privacy authorities, which Company reasonably considers to
                                    be required by article 35 or 36 of the GDPR or equivalent
                                    provisions of any other Data Protection Law, in each case solely
                                    in relation to Processing of Company Personal Data by, and
                                    taking into account the nature of the Processing and information
                                    available to, the Contracted Processors.
                                </p>
                                <p>
                                    9.1 Subject to this section 9 Processor shall promptly and in
                                    any event within 10 business days of the date of cessation of
                                    any Services involving the Processing of Company Personal Data
                                    (the "Cessation Date"), delete and procure the deletion of all
                                    copies of those Company Personal Data.
                                </p>
                                <p>
                                    9.2 Processor shall provide written certification to Company
                                    that it has fully complied with this section 9 within 10
                                    business days of the Cessation Date.
                                </p>
                                <h3>10. Audit rights</h3>
                                <p>
                                    10.1 Subject to this section 10, Processor shall make available
                                    to the Company on request all information necessary to
                                    demonstrate compliance with this Agreement, and shall allow for
                                    and contribute to audits, including inspections, by the Company
                                    or an auditor mandated by the Company in relation to the
                                    Processing of the Company Personal Data by the Contracted
                                    Processors.
                                </p>
                                <p>
                                    10.2 Information and audit rights of the Company only arise
                                    under section 10.1 to the extent that the Agreement does not
                                    otherwise give them information and audit rights meeting the
                                    relevant requirements of Data Protection Law.
                                </p>
                                <h3>11. Data Transfer</h3>
                                <p>
                                    11.1 The Processor may not transfer or authorize the transfer of
                                    Data to countries outside the EU and/or the European Economic
                                    Area (EEA) without the prior written consent of the Company. If
                                    personal data processed under this Agreement is transferred from
                                    a country within the European Economic Area to a country outside
                                    the European Economic Area, the Parties shall ensure that the
                                    personal data are adequately protected. To achieve this, the
                                    Parties shall, unless agreed otherwise, rely on EU approved
                                    standard contractual clauses for the transfer of personal data.
                                </p>
                                <h3>Appendix I: Description of treatment</h3>
                                <p>
                                    <strong>
                                        Categories of data subjects whose personal data is
                                        processed:
                                    </strong>
                                    <br />
                                    The categories of data subjects whose personal data is processed
                                    by the data processor include:
                                </p>
                                <li>The customers of the Data Controller</li>
                                <p>
                                    <strong>Categories of personal data processed</strong>
                                    <br />
                                    RE-ZIP acts as a Data Processor, processing categories of
                                    personal data on behalf of the Data Controller. The processed
                                    data can be classified as follows:
                                </p>
                                <li>
                                    Personal data relating to the customers of the Data Controller,
                                    encompassing company information such as the company master
                                    data, including legal name, name, address, and VAT number.
                                </li>
                                <p>
                                    <strong>
                                        Sensitive data processed (if applicable) and implemented
                                        restrictions or safeguards:{" "}
                                    </strong>
                                </p>
                                <p>
                                    There is no processing of sensitive data involved in this
                                    context.
                                </p>
                                <p>
                                    <strong>Nature of the processing:</strong>
                                    <br /> The primary nature of the processing carried out by the
                                    data processor is to enable the provision of specific services
                                    that require customer data, thus, to pursue a legitimate
                                    interest.
                                </p>
                                <p>
                                    <strong>
                                        Purposes for which the personal data is processed on behalf
                                        of the data controller:{" "}
                                    </strong>
                                </p>
                                <p>
                                    The data is used to identify customers as users and provide them
                                    with the option of using the RE-ZIP Manager, a platform offering
                                    functionalities related to packaging orders, waste management,
                                    accessing marketing materials, etc.
                                </p>
                                <p>
                                    <strong>Duration of the processing:</strong>
                                </p>
                                <p>
                                    The processing shall continue until the user deletes their data.
                                </p>
                                <p>
                                    <strong>
                                        When processing (sub)processors, the subject, nature, and
                                        duration of the processing are also specified:{" "}
                                    </strong>
                                </p>
                                <label>
                                    RE-ZIP utilizes Digital Ocean's cloud solution for data
                                    processing purposes. Detailed information regarding Digital
                                    Ocean's Data Processing Agreement can be found at the following
                                    link:
                                </label>{" "}
                                <a
                                    href="https://www.digitalocean.com/legal/data-processing-agreement"
                                    target="_blank">
                                    https://www.digitalocean.com/legal/data-processing-agreement.
                                </a>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </DefaultWrapperPaper>
            </Grid>
        </Page>
    )
}
