import { TableCell, TableHead, TableSortLabel } from "@mui/material"
import { Header, SortOrder } from "./AllInOneTable"
import { StyledHeaderRow } from "./TableComponents"

export const TableHeader = <T,>({
    headers,
    orderBy,
    sortOrder,
    onRequestSort,
}: {
    headers: Header<T>[]
    orderBy: keyof T | null | string
    sortOrder: SortOrder
    onRequestSort: (property: keyof T | string) => void
}) => {
    return (
        <TableHead>
            <StyledHeaderRow>
                {headers.map((header) => (
                    <TableCell
                        key={String(header.key)}
                        sortDirection={orderBy === header.key ? sortOrder : false}
                        sx={{ border: "none" }}>
                        {header.sortable ? (
                            <TableSortLabel
                                active={orderBy === header.key}
                                direction={orderBy === header.key ? sortOrder : "asc"}
                                onClick={() => onRequestSort(header.key)}>
                                {header.label}
                            </TableSortLabel>
                        ) : (
                            <>{header.label}</>
                        )}
                    </TableCell>
                ))}
            </StyledHeaderRow>
        </TableHead>
    )
}
