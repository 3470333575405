import { useCallback } from "react"
import { useUser } from "src/contexts/UserConsumer"
import { DAY, EnhancedEnvStats, EnvStats } from "./reports.types"
import dayjs from "dayjs"
import { combineSum, correctDates, mapSumToEnviormentData } from "./reports.helpers"
import { useClient } from "src/hooks/useClient.hook"
import { PartnerClient } from "@repo/rezip-client/partner_client"
import { ShopClient } from "@repo/rezip-client/shop_client"

export const useReportsHelper = () => {
    const { getSelectedAgreement } = useUser()
    const { selectedClient } = useClient()
    const accountId = getSelectedAgreement()?.account.id
    if (!(selectedClient instanceof PartnerClient || selectedClient instanceof ShopClient)) {
        throw "unreachable"
    }
    if (!accountId) {
        throw "unreachable"
    }
    const reportingClient = selectedClient.reporting(accountId)

    const fetchEnvDataForPeriod = useCallback(
        async (
            allignedStart: dayjs.Dayjs,
            allignedEnd: dayjs.Dayjs,
            precision: number,
            shopId: string,
        ) => {
            const empty: EnvStats[] = []
            const emptySum = {
                time_increment: "",
                transport_co2e: 0,
                transport_water: 0,
                production_co2e: 0,
                production_water: 0,
                destruction_co2e: 0,
                destruction_water: 0,
                assembly_co2e: 0,
                assembly_water: 0,
                sorting_co2e: 0,
                sorting_water: 0,
                waste_kg: 0,
                per_sku: {},
            }

            try {
                const stats = await reportingClient.statistics({
                    precision,
                    from: allignedStart,
                    to: allignedEnd,
                    shopId: shopId === "All" ? undefined : shopId,
                })

                return stats
            } catch (e) {
                return { each: empty, sum: emptySum }
            }
        },
        [reportingClient],
    )

    const fetchEnvData = useCallback(
        async (start?: Date, end?: Date, groups?: string[], interval?: number, shopId?: string) => {
            if (!start || !end || !shopId || !groups || !interval) {
                return
            }
            const { acctualStart, accutualEnd, startOfMiddle, endOfMiddle } = correctDates(
                start,
                end,
                interval,
            )

            //const data = await fetchEnvDataForPeriod(acctualStart, accutualEnd, DAY, shopId)

            const moredata = await Promise.all([
                acctualStart.isSame(startOfMiddle)
                    ? undefined
                    : await fetchEnvDataForPeriod(acctualStart, startOfMiddle, DAY, shopId),
                await fetchEnvDataForPeriod(startOfMiddle, endOfMiddle, interval, shopId),
                accutualEnd.isSame(endOfMiddle)
                    ? undefined
                    : await fetchEnvDataForPeriod(endOfMiddle, accutualEnd, DAY, shopId),
            ]).then((res) => {
                const first = res[0]
                const middle = res[1]
                const end = res[2]
                return { first, middle, end }
            })

            const combinedEach = [
                ...(moredata?.first?.each ?? []),
                ...moredata.middle.each,
                ...(moredata?.end?.each ?? []),
            ]

            const totalSum = combineSum(
                [moredata?.first?.sum, moredata.middle.sum, moredata?.end?.sum].filter(
                    (sum) => !!sum,
                ),
            )

            const mappedSum = mapSumToEnviormentData(totalSum)
            return { each: combinedEach, sum: mappedSum } as EnhancedEnvStats
        },
        [fetchEnvDataForPeriod],
    )

    return { fetchEnvData }
}
