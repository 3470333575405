import { Skeleton, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import { BarAndLineGraph } from "./barAndLineGraph"
import HeatMap from "./heatMap"
import { BarChart } from "@mui/x-charts"
import { CustomPieChart } from "./pieChart"
import { useIntl } from "react-intl"
import ImpactComponent from "./impactComponent"
import { actionGreen, ecoGreen, softBlue, softGreen, warmCoral } from "../colors"
import { DefaultChartPaper } from "../styles"

import { useMemo } from "react"

import { ReportingChartsProps } from "src/routes/administration/reports/reports.types"
import { useReferenceCalculator } from "src/utils/referenceCalculator"

export const ReportingCharts = ({
    startDate,
    endDate,
    shopId,
    envStats,
    labels,
    simpleProducts,
    summedEnvStats,
    graphsReady,
    setDateUpdated,
    dateUpdated,
}: ReportingChartsProps) => {
    const { ReferenceCalculator } = useReferenceCalculator()
    const calculatedReferenceValues = useMemo(() => {
        return ReferenceCalculator(summedEnvStats, simpleProducts)
    }, [ReferenceCalculator, simpleProducts, summedEnvStats])

    const pieData = useMemo(() => {
        let recycled = 0
        let reused = 0

        if (!envStats) {
            return { reusedTotal: reused, recycledTotal: recycled }
        } else {
            envStats.totalCollectedData.recycled.forEach((item) => (recycled += item))
            envStats.totalCollectedData.reused.forEach((item) => (reused += item))

            return { reusedTotal: reused, recycledTotal: recycled }
        }
    }, [envStats])

    const savingsTotal = useMemo(() => {
        if (summedEnvStats && calculatedReferenceValues && envStats) {
            return {
                co2: (calculatedReferenceValues.co2 - summedEnvStats.totalCo2).toFixed(2),
                water: (calculatedReferenceValues.water - summedEnvStats.totalWater).toFixed(2),
                waste: (calculatedReferenceValues.waste - summedEnvStats.totalWaste).toFixed(2),
            }
        }
    }, [calculatedReferenceValues, envStats, summedEnvStats])

    const intl = useIntl()
    return (
        <Grid container direction="column" justifyContent="center" spacing={2}>
            <Grid container spacing={2}>
                <Grid direction="column" size={4}>
                    <DefaultChartPaper>
                        <BarAndLineGraph
                            key={"Co2-Reduction"}
                            title={intl.formatMessage({
                                id: "reports_co2_reduction",
                            })}
                            metric={intl.formatMessage({ id: "metrics_kg", defaultMessage: "kg" })}
                            height={300}
                            total={`${savingsTotal ? savingsTotal.co2 : 0}`}
                            barData={envStats ? envStats.rezipDataPoints.co2.uData : []}
                            xBandValues={labels}
                            barColor={ecoGreen}
                            graphsReady={graphsReady}
                            lineData={envStats ? envStats.rezipDataPoints.co2.pData : []}
                            lineColor={actionGreen}
                        />
                    </DefaultChartPaper>
                </Grid>
                <Grid direction="column" size={4}>
                    <DefaultChartPaper>
                        <BarAndLineGraph
                            key={"Water-Reduction"}
                            title={intl.formatMessage({
                                id: "reports_water_usage_reduction",
                            })}
                            metric={intl.formatMessage({ id: "metrics_l", defaultMessage: "L" })}
                            height={300}
                            total={`${savingsTotal ? savingsTotal.water : 0}`}
                            barData={envStats ? envStats.rezipDataPoints.water.uData : []}
                            xBandValues={labels}
                            barColor={softBlue}
                            graphsReady={graphsReady}
                            lineData={envStats ? envStats.rezipDataPoints.water.pData : []}
                            lineColor={actionGreen}
                        />
                    </DefaultChartPaper>
                </Grid>
                <Grid direction="column" size={4}>
                    <DefaultChartPaper>
                        <BarAndLineGraph
                            key={"Waste-Reduction"}
                            title={intl.formatMessage({
                                id: "reports_waste_reduction",
                            })}
                            metric={intl.formatMessage({ id: "metrics_kg", defaultMessage: "kg" })}
                            height={300}
                            total={`${savingsTotal ? savingsTotal.waste : 0}`}
                            barData={envStats ? envStats.rezipDataPoints.waste.uData : []}
                            xBandValues={labels}
                            barColor={softGreen}
                            graphsReady={graphsReady}
                            lineData={envStats ? envStats.rezipDataPoints.waste.pData : []}
                            lineColor={actionGreen}
                        />
                    </DefaultChartPaper>
                </Grid>
            </Grid>

            <Grid container direction="column" spacing={2}>
                <Grid container spacing={2}>
                    <Grid direction="column" size={6}>
                        <DefaultChartPaper sx={{ display: "flex", height: "100%" }}>
                            <HeatMap
                                shopId={shopId}
                                startDate={startDate}
                                endDate={endDate}
                                dataUpdated={dateUpdated}
                                setDataUpdated={setDateUpdated}
                            />
                        </DefaultChartPaper>
                    </Grid>
                    <Grid container direction="column" size={6}>
                        <Grid container direction="column" size="grow">
                            <DefaultChartPaper sx={{ height: "100%" }}>
                                {!envStats ||
                                !graphsReady ||
                                !envStats.totalCollectedData.fulFilled ? (
                                    <Skeleton
                                        animation={false}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "8px",
                                        }}
                                        variant="rectangular"
                                        width={"100%"}
                                        height={"300px"}>
                                        {intl.formatMessage({
                                            id: "common_loading",
                                        })}
                                    </Skeleton>
                                ) : (
                                    <Grid container direction="column" alignItems="center">
                                        <Grid
                                            container
                                            sx={{ paddingTop: "12px", textAlign: "center" }}>
                                            <Typography variant="h4">
                                                {intl.formatMessage({
                                                    id: "reports_barchart_title",
                                                })}
                                            </Typography>
                                        </Grid>
                                        <BarChart
                                            loading={!graphsReady}
                                            skipAnimation
                                            key={"Reclaimed-Reused-BarChart"}
                                            margin={{ bottom: 110 }}
                                            slotProps={{
                                                legend: {
                                                    direction: "row",
                                                    position: {
                                                        vertical: "bottom",
                                                        horizontal: "middle",
                                                    },
                                                    padding: 4,
                                                    itemGap: 14,
                                                    labelStyle: {
                                                        fontFamily: "Manrope",
                                                        fontSize: "12px",
                                                    },
                                                },
                                                itemContent: {
                                                    sx: { padding: "0px", margin: "10px" },
                                                },
                                            }}
                                            height={300}
                                            series={[
                                                {
                                                    color: ecoGreen,
                                                    data: envStats.totalCollectedData.fulFilled,
                                                    id: "barChart",
                                                    label: intl.formatMessage({
                                                        id: "reports_fulfilled",
                                                    }),
                                                },
                                                {
                                                    color: actionGreen,
                                                    data: envStats.totalCollectedData.reclaimed,
                                                    id: "barChart2",
                                                    label: intl.formatMessage({
                                                        id: "reports_reclaimed",
                                                    }),
                                                },
                                            ]}
                                            xAxis={[
                                                {
                                                    data: labels,
                                                    scaleType: "band",
                                                    tickLabelStyle: {
                                                        angle: -90,
                                                        textAnchor: "end",
                                                    },
                                                },
                                            ]}
                                            yAxis={[
                                                {
                                                    data: envStats.totalCollectedData.reclaimed,
                                                    scaleType: "pow",
                                                },
                                            ]}
                                        />
                                    </Grid>
                                )}
                            </DefaultChartPaper>
                        </Grid>

                        <Grid container size={12} spacing={2}>
                            <Grid direction="column" size={6}>
                                <DefaultChartPaper
                                    sx={{ justifyContent: "center", alignItems: "center" }}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            width: "100%",
                                            alignSelf: "flex-end",
                                            height: "100%",
                                            flexDirection: "column",
                                        }}>
                                        <CustomPieChart
                                            key={"PieChart"}
                                            title={intl.formatMessage({
                                                id: "reports_pie_title",
                                            })}
                                            height={200}
                                            data={[
                                                {
                                                    value: pieData.reusedTotal,
                                                    color: softBlue,
                                                    id: "piechartValue1",
                                                    label: intl.formatMessage({
                                                        id: "reports_reused",
                                                    }),
                                                },
                                                {
                                                    value: pieData.recycledTotal,
                                                    color: warmCoral,
                                                    id: "piechartValue2",
                                                    label: intl.formatMessage({
                                                        id: "reports_recycled",
                                                    }),
                                                },
                                            ]}
                                            graphsReady={graphsReady}
                                        />
                                    </Grid>
                                </DefaultChartPaper>
                            </Grid>
                            <Grid direction="column" size={6}>
                                <DefaultChartPaper sx={{ height: "100%" }}>
                                    <ImpactComponent />
                                </DefaultChartPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
