import { useState } from "react"

const regex = /^([0-9]*[,.]?[0-9]*)/

export const useFloatInput = ({
    defaultValue,
    onChange,
}: {
    defaultValue?: number
    onChange?: (x: number) => void
}) => {
    const [value, setValue] = useState<string>(defaultValue?.toString() ?? "")
    return {
        value,

        onChange: (x: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let value = (x.target.value.match(regex) || ["", ""])[1]
            value = value.startsWith(".") || value.startsWith(",") ? `0${value}` : value
            setValue(value)
            const floatValue = parseFloat(value)
            if (!isNaN(floatValue)) {
                onChange?.(floatValue)
            }
        },
    }
}
