import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useCallback, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useBlocker } from "react-router-dom"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"

export const useCustomBlocker = () => {
    const [isBlocked, setIsBlocked] = useState(false)
    const isBlockedRef = useRef(isBlocked)
    const intl = useIntl()

    const setBlock = useCallback((shouldBlock: boolean) => {
        isBlockedRef.current = shouldBlock
        setIsBlocked(shouldBlock)
    }, [])

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            isBlockedRef.current && currentLocation.pathname !== nextLocation.pathname,
    )

    const BlockerAlert = () => {
        if (blocker.state === "blocked") {
            return (
                <Dialog open={true}>
                    <DialogTitle>
                        <Typography>{intl.formatMessage({ id: "alert_title" })}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            {intl.formatMessage({ id: "alert_additional_text" })}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <CustomButton
                            onClick={() => {
                                setBlock(false) // Ensure blocking is disabled
                                blocker.proceed() // Proceed with navigation
                            }}
                            text={intl.formatMessage({ id: "common_yes" })}
                        />

                        <CustomButton
                            onClick={() => blocker.reset()}
                            text={intl.formatMessage({ id: "common_no" })}
                            colorTheme={ColorThemeName.warning}
                        />
                    </DialogActions>
                </Dialog>
            )
        }
        return null
    }

    return { setBlock, BlockerAlert, isBlocked }
}
