import { AlertColor } from "@mui/material"
import { createContext, useContext } from "react"

type SnackbarContextType = {
    enqueueAlert: (message: string, alertType: AlertColor) => void
}

// this context could potentially become the backgorund worker, or maybe the background worker has to implement this

export const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined)

// eslint-disable-next-line react-refresh/only-export-components
export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext)
    if (!context) {
        throw new Error("useSnackbar must be used within a SnackbarProvider")
    }
    return context
}
