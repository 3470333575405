import { z } from "zod"

type BaseValidator = z.ZodDiscriminatedUnionOption<"type">

export class RequestError extends Error {
    static VALIDATOR: BaseValidator
    constructor({ type }: { type: string }) {
        super(`request error ${type}`)
    }
}
const errorClasses: Record<string, typeof RequestError> = {}

export const PackagingNotAssingedToAccount = (() => {
    const x = "packaging_not_assinged_to_account"
    const validator = z.object({
        type: z.literal(x),
        barcodes_per_account: z.record(z.string(), z.array(z.string())),
    })
    const klass = class extends RequestError {
        static VALIDATOR = validator
        barcodesPerAccount: Record<string, string[]>
        constructor({ barcodes_per_account, type }: z.infer<typeof validator>) {
            super({ type })
            this.barcodesPerAccount = barcodes_per_account
            this.name = "packaging_not_assinged_to_account"
        }
    }
    errorClasses[x] = klass
    return klass
})()

export const BarcodeNotUniqueForPartner = (() => {
    const x = "barcode_not_unique_for_partner"
    const validator = z.object({
        type: z.literal(x),
    })
    const klass = class extends RequestError {
        static VALIDATOR = validator
        constructor({ type }: z.infer<typeof validator>) {
            super({ type })
            this.name = "barcode_not_unique_for_partner"
        }
    }
    errorClasses[x] = klass
    return klass
})()

const validator = z.discriminatedUnion("type", [
    // need first one so typescript dont complain
    z.object({ type: z.literal("_") }),
    ...Object.values(errorClasses).map((x) => x.VALIDATOR),
])

export const tryParseRequestError = (body: unknown): RequestError | null => {
    try {
        const result = validator.parse(body) as { type: string }
        const klass = errorClasses[result.type]
        const errorInstance = new klass(result)
        Object.setPrototypeOf(errorInstance, klass.prototype)

        return new klass(result)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
        return null
    }
}
