import { FunctionComponent, useCallback, useEffect, useState } from "react"

import {
    AdvancedMarker,
    InfoWindow,
    Map,
    MapCameraChangedEvent,
    useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps"

import { ReactComponent as ReZipPin } from "../assets/RE-ZIP_Pin.svg"

import { useIntl } from "react-intl"
import { Circle } from "@mui/icons-material"
import { findNearest } from "geolib"
import { CustomButton } from "./generalComponents/Buttons"
export type Customlocation = {
    location: google.maps.LatLngLiteral
    name: string
}
interface MapProps {
    defaultLocation: google.maps.LatLngLiteral
    locations?: Customlocation[]
    width?: number
    height: number
    showOwnLocation: boolean
    onDragEnd?: (coord: google.maps.MapMouseEvent) => void
    allowDrag?: boolean
}

interface AdvancedMarkerProps {
    location: google.maps.LatLngLiteral
    information: string
    ownLocation?: boolean
    onDragEnd?: (coord: google.maps.MapMouseEvent) => void
    allowDrag?: boolean
}

/* 
Some information about this component.
Overlay is there because fetching user location without them starting the process is bad practice.

I dont know how it would handle user movement, my guess is it doesnt. so if we want to use something like this on a phone, we need to add
some sort of tracker of the device and constantly update the map.


the nearest function might not be needed for a simple version that just shows a warehouse location.

https://visgl.github.io/react-google-maps/docs/api-reference/components/map
*/

const CustomAvancedMarker: FunctionComponent<AdvancedMarkerProps> = ({
    location,
    information,
    ownLocation,
    onDragEnd,
    allowDrag,
}) => {
    const [markerRef, marker] = useAdvancedMarkerRef()
    const [infoWindowShown, setInfoWindowShown] = useState(false)
    return ownLocation ? (
        <AdvancedMarker
            onClick={() => setInfoWindowShown(!infoWindowShown)}
            ref={markerRef}
            position={{ lat: location.lat, lng: location.lng }}>
            <Circle
                radius={4} // Adjust the radius according to your preference
                sx={{
                    stroke: "transparent",
                    fill: "#4285F4",
                    fillOpacity: 1,
                    zIndex: 1,
                }}
            />
            {infoWindowShown ? (
                <InfoWindow onClose={() => setInfoWindowShown(false)} anchor={marker}>
                    {information}
                </InfoWindow>
            ) : null}
        </AdvancedMarker>
    ) : !allowDrag ? (
        <AdvancedMarker
            onClick={() => setInfoWindowShown(!infoWindowShown)}
            ref={markerRef}
            position={{ lat: location.lat, lng: location.lng }}>
            <ReZipPin width={"26px"} height={"38px"} />
            {infoWindowShown ? (
                <InfoWindow onClose={() => setInfoWindowShown(false)} anchor={marker}>
                    {information}
                </InfoWindow>
            ) : null}
        </AdvancedMarker>
    ) : (
        <AdvancedMarker
            onClick={() => setInfoWindowShown(!infoWindowShown)}
            ref={markerRef}
            position={{ lat: location.lat, lng: location.lng }}
            onDragEnd={onDragEnd}>
            <ReZipPin width={"26px"} height={"38px"} />
            {infoWindowShown ? (
                <InfoWindow onClose={() => setInfoWindowShown(false)} anchor={marker}>
                    {information}
                </InfoWindow>
            ) : null}
        </AdvancedMarker>
    )
}

const MapComponent: FunctionComponent<MapProps> = ({
    defaultLocation = { lat: 0, lng: 0 },
    locations,
    width,
    height,
    showOwnLocation,
    onDragEnd,
    allowDrag,
}) => {
    const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null)
    const [cameraLocation, setCameraLocation] = useState<google.maps.LatLngLiteral | null>(null)
    const [nearestMarker, setNearestMarker] = useState<google.maps.LatLngLiteral | null>(null)
    const [loadedLocations, setLoadedLocations] = useState<Customlocation[] | null>(null)
    const [zoom, setZoom] = useState(3)
    const [overlayVisible, setOverlayVisible] = useState(true)
    const [allowGeo, setAllowGeo] = useState(false)
    const intl = useIntl()

    useEffect(() => {
        if (!currentLocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const current = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
                setAllowGeo(true)
                setCurrentLocation(current)
            })
        }
    }, [currentLocation])

    const smoothZoom = useCallback(
        (targetZoom: number, duration: number) => {
            const zoomChange = targetZoom - zoom
            const startTime = Date.now()
            const easing = (t: number) => t * (2 - t)

            const zoomStep = () => {
                const elapsedTime = Date.now() - startTime
                const progress = Math.min(elapsedTime / duration, 1)
                const easedProgress = easing(progress)
                const newZoom = zoom + zoomChange * easedProgress

                setZoom(newZoom)

                if (progress < 1) {
                    requestAnimationFrame(zoomStep)
                }
            }

            zoomStep()
        },
        [zoom],
    )

    const nearestLocation = useCallback(() => {
        if (locations) {
            if (allowGeo && currentLocation && locations.length > 1) {
                // if user has allowed geolocation we can use that to find the nearest, and if there are more than one location in the list

                const nearest = findNearest(
                    currentLocation,
                    locations.map((location) => location.location),
                ) as unknown as google.maps.LatLngLiteral

                setNearestMarker(nearest)
                if (!showOwnLocation) {
                    setCameraLocation(currentLocation)
                } else {
                    setCameraLocation(nearest)
                }
            } else {
                // there is really no point in finding the nearest if lenght === 1
                // else we set the camera and current to locations[0]
                const fixedLocation = {
                    lat: isNaN(locations[0].location.lat) ? 0 : locations[0].location.lat,
                    lng: isNaN(locations[0].location.lng) ? 0 : locations[0].location.lng,
                } as google.maps.LatLngLiteral

                setCameraLocation(fixedLocation)
                setCurrentLocation(fixedLocation)
            }
            smoothZoom(11, 3000)
        }
    }, [allowGeo, currentLocation, locations, showOwnLocation, smoothZoom])

    const loadLocations = useCallback(() => {
        setLoadedLocations(locations || [])
        nearestLocation()
        setOverlayVisible(false)
    }, [nearestLocation, locations])

    return (
        <>
            <div
                key={"mapWrapper"}
                style={{
                    position: "relative",
                    width: width ? `${width}px` : "100%",
                    height: `${height}px`,
                }}>
                {overlayVisible && locations ? (
                    <div
                        key={"mapOverlay"}
                        style={{
                            position: "absolute",
                            width: width ? `${width}px` : "100%",
                            height: `${height}px`,
                            background: "rgba(255, 255, 255, 0.7)",
                            zIndex: 50,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        <CustomButton
                            key={"load-locations-button"}
                            onClick={loadLocations}
                            text={intl.formatMessage({
                                id:
                                    locations.length > 1
                                        ? "map_show_locations"
                                        : "map_show_location",
                            })}
                        />
                    </div>
                ) : null}
                <Map
                    style={{ width: width ? `${width}px` : "100%", height: `${height}px` }}
                    mapId={"rezip-map"}
                    center={cameraLocation}
                    onCenterChanged={(event: MapCameraChangedEvent) =>
                        setCameraLocation(event.detail.center)
                    }
                    onZoomChanged={(event: MapCameraChangedEvent) => setZoom(event.detail.zoom)}
                    defaultCenter={nearestMarker ? nearestMarker : defaultLocation}
                    defaultZoom={8}
                    zoom={zoom}>
                    {loadedLocations ? (
                        locations?.map((location, index) => (
                            <CustomAvancedMarker
                                key={`marker-${index}`}
                                location={location.location}
                                information={location.name}
                                onDragEnd={onDragEnd}
                                allowDrag={allowDrag ?? false}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    {currentLocation && showOwnLocation && allowGeo ? (
                        <CustomAvancedMarker
                            key={`current-location`}
                            location={currentLocation}
                            information={intl.formatMessage({ id: "you_are_here" })}
                            ownLocation
                        />
                    ) : (
                        <></>
                    )}
                </Map>
                <></>
            </div>
        </>
    )
}

export default MapComponent
