import { InviteUserComponent } from "src/components/inviteUser/InviteUser"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"

const AddDropPointUser = () => {
    const { getSelectedAgreement } = useUser()

    const partnerId = getSelectedAgreement()?.account.id

    const { dropPointClient } = useClient()

    return <InviteUserComponent client={dropPointClient} id={partnerId} />
}

export default AddDropPointUser
