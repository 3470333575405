import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useUser } from "src/contexts/UserConsumer"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { Bundle, Location, StationType, StorageUnit } from "@repo/rezip-client/partner_client"
import { Product } from "@repo/rezip-client/types"
import { parseBarcode } from "src/utils/barcodeHelpers"
import { ClientError } from "@repo/rezip-client/client_error"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { StyledMenuItem } from "src/components/styles"
import { useNavigate } from "react-router-dom"
import { RemoveRedEye } from "@mui/icons-material"
import { displayErrorMessage, errorConverter, extractErrorMessage } from "src/utils/errorConverter"

export type StationStatusTableRow = {
    bundle: Bundle | undefined
    su: StorageUnit | undefined
}

export const useReceiveController = () => {
    const intl = useIntl()
    const {
        getSelectedAgreement,
        getToken,
        getSelectedLocationId,
        getSelectedStation,
        setSelectedStation,
    } = useUser()
    const [scanInput, setScanInput] = useState("")
    const [storageUnitInput, setStorageUnitInput] = useState("")
    const [selectedBundle, setSelectedBundle] = useState<Bundle | null>(null)
    const focusWhenSelectedElement = useRef<HTMLInputElement>(null)
    const [locations, setLocations] = useState<Array<Location>>()
    const [selectedLocation, setSelectedLocation] = useState<Location>()

    const [stations, setStations] = useState<Array<StationType>>()
    const selectedStation = useMemo(() => getSelectedStation(), [getSelectedStation])
    const { enqueueAlert } = useSnackbar()
    const [openBundles, setOpenBundles] = useState<Array<Bundle>>(new Array<Bundle>())
    const [products, setProducts] = useState<Array<Product>>()
    const [showCompletedBundleDialog, setShowCompletedBundleDialog] = useState(false)
    const [showNoStorageUnitAssignedDialog, setShowNoStorageUnitAssignedDialog] = useState(false)
    const [autoComplete, setAutoComplete] = useState(true)
    const [openStorageUnits, setOpenStorageUnits] = useState<Array<StorageUnit>>(
        new Array<StorageUnit>(),
    )
    const [initialLoad, setInitialLoad] = useState(true)
    const [showStorageFilledPopup, setShowStorageFilledPopup] = useState<StorageUnit | null>(null)
    const [stationModal, setStationModal] = useState(false)

    const sessionLocation = getSelectedLocationId()
    const { partnerClient } = useClient()
    const navigate = useNavigate()
    const [noStationAvailable, setNoStationAvailable] = useState(false)

    useEffect(() => {
        if (!selectedLocation && sessionLocation !== "" && locations) {
            const item = locations?.find((item) => item.id === sessionLocation)
            if (item) {
                setSelectedLocation(item)
            }
        }
    }, [locations, selectedLocation, sessionLocation])

    const clearBundlesAndStorageUnits = () => {
        setOpenBundles(new Array<Bundle>())
        setOpenStorageUnits(new Array<StorageUnit>())
    }

    const onStationChange = (id: string) => {
        const station = stations?.find((s) => s.id === id)
        if (station) {
            setSelectedStation(station)
            clearBundlesAndStorageUnits()

            setInitialLoad(true)
        }
    }

    const getProductNameFromSKU = useCallback(
        (sku: string) => {
            return products?.find((p) => {
                return p.sku == sku
            })?.name
        },
        [products],
    )

    useEffect(() => {
        const el = focusWhenSelectedElement.current
        if (el) {
            el.focus()
        }
    }, [selectedLocation, selectedStation, focusWhenSelectedElement])

    useEffect(() => {
        const partnerId = getSelectedAgreement()?.account.id
        async function fetchLocations() {
            if (!partnerId) {
                return
            }
            const res = await partnerClient.getLocations(partnerId)

            if (res) {
                const tempArray = new Array<Location>()
                for (let index = 0; index < res.length; index++) {
                    const location = res[index] as Location
                    tempArray.push(location)
                }
                setLocations(tempArray)
            }
        }
        fetchLocations()

        async function fetchProducts() {
            if (!partnerId) {
                return
            }
            const productsRes = await partnerClient.getProducts(partnerId, { pageSize: 999 })

            if (productsRes) {
                const tempArray = new Array<Product>()
                for (let index = 0; index < productsRes.length; index++) {
                    const product = productsRes[index] as Product
                    tempArray.push(product)
                }
                setProducts(tempArray)
            }
        }
        fetchProducts()
    }, [getSelectedAgreement, getToken, partnerClient])

    useEffect(() => {
        const partnerId = getSelectedAgreement()?.account.id
        const locationId = selectedLocation?.id

        if (!partnerId || !locationId) {
            return
        }

        async function fetchStations() {
            if (!partnerId || !locationId) {
                return
            }
            const filter = { deleted_at: "" }
            const options = {
                filters: { ...filter },
            }

            const res = await partnerClient.getStations(partnerId, locationId, options)

            if (res) {
                const tempArray = new Array<StationType>()
                for (let index = 0; index < res.length; index++) {
                    const station = res[index]
                    tempArray.push(station)
                }
                setStations(tempArray)
                if (tempArray.length === 0) {
                    setNoStationAvailable(true)
                }

                if (selectedStation) {
                    const station = tempArray.find((station) => {
                        return station.id === selectedStation.id
                    })
                    if (station) {
                        // case if a station name is updated. doesnt really do much, but migth cause confusion.
                        if (station.name !== selectedStation.name) {
                            setSelectedStation(station)
                        }
                        // case if a station is deleted but selected on a session
                    } else if (!station) {
                        setSelectedStation(null)
                    }
                }

                if (tempArray.length === 1 && !selectedStation) {
                    setSelectedStation(tempArray[0])
                } else if (tempArray.length > 1 && !selectedStation) {
                    setStationModal(true)
                } else {
                    setStationModal(false)
                }
            }
        }
        fetchStations()
    }, [
        getSelectedAgreement,
        getToken,
        partnerClient,
        selectedLocation,
        selectedStation,
        setSelectedStation,
        products,
        locations,
    ])

    useEffect(() => {
        const partnerId = getSelectedAgreement()?.account.id
        const locationId = selectedLocation?.id
        const stationId = selectedStation?.id
        if (!products || !locations) {
            return
        }

        if (!partnerId || !locationId || !stationId) {
            clearBundlesAndStorageUnits()
            return
        }

        const fetchOpenBundles = async () => {
            const res = await partnerClient.getBundles(partnerId, locationId, stationId, {
                filters: { completed: "false" },
            })

            if (res) {
                const tempArray = new Array<Bundle>()
                for (let index = 0; index < res.length; index++) {
                    const bundle = res[index] as Bundle
                    tempArray.push(bundle)
                }
                setOpenBundles(tempArray)
            }
        }

        async function fetchOpenStorageUnits() {
            if (!partnerId || !locationId || !stationId) {
                return
            }
            const res = await partnerClient.getStorageUnitsOnStation(
                partnerId,
                locationId,
                stationId,
                { pageSize: 999, filters: { completed: "false" } },
            )

            if (res) {
                await fetchOpenBundles()
                const tempArray = new Array<StorageUnit>()
                const data = res as StorageUnit[]
                for (let index = 0; index < data.length; index++) {
                    const storageUnit = data[index]
                    tempArray.push(storageUnit)
                }
                setOpenStorageUnits(tempArray)
            }
        }
        fetchOpenStorageUnits()
    }, [
        getSelectedAgreement,
        getToken,
        partnerClient,
        selectedLocation,
        selectedStation,
        products,
        locations,
    ])

    const handleUpdatedBundle = async (updatedBundle: Bundle) => {
        const partnerId = getSelectedAgreement()?.account.id
        if (!selectedStation || !selectedLocation || !partnerId || !sessionLocation) {
            clearBundlesAndStorageUnits()
            return
        }

        const bundleIndex = openBundles.findIndex((bundle) => bundle.id === updatedBundle.id)
        const assignedStorageUnitIndex = openStorageUnits.findIndex((su) => {
            return su.sku == updatedBundle.packaging[0].substring(0, 4)
        })

        if (bundleIndex === -1) {
            // means its a new bundle
            const tempArray = [...openBundles, updatedBundle]
            setOpenBundles(tempArray)
            if (assignedStorageUnitIndex == -1 && autoComplete) {
                setShowNoStorageUnitAssignedDialog(true)
            }
        } else {
            // existing open bundle
            if (updatedBundle.completed) {
                setShowCompletedBundleDialog(true)

                const tempArray = openBundles.filter((bundle) => bundle.id !== updatedBundle.id)
                setOpenBundles(tempArray)

                try {
                    if (assignedStorageUnitIndex != -1 && autoComplete) {
                        const bundleToSURes = await partnerClient.addBundleToStorageUnit(
                            partnerId,
                            selectedLocation.id,
                            openStorageUnits[assignedStorageUnitIndex].id,
                            updatedBundle.packaging[0],
                        )

                        if (bundleToSURes) {
                            const storageUnit = bundleToSURes as StorageUnit
                            const storageUnits = openStorageUnits
                            if (!storageUnit.completed) {
                                storageUnits[assignedStorageUnitIndex] = storageUnit
                            } else {
                                setShowStorageFilledPopup(storageUnit)
                                const res = await partnerClient.patchStorageUnit(
                                    partnerId,
                                    storageUnit.location_id ?? sessionLocation,
                                    storageUnit.id,
                                    { station_id: null },
                                )

                                if (res) {
                                    storageUnits.splice(assignedStorageUnitIndex, 1)
                                }
                            }

                            setOpenStorageUnits(storageUnits)
                            const tempArray = openBundles.filter((bundle) => {
                                if (bundle.id !== updatedBundle.id) {
                                    return updatedBundle
                                }
                            })
                            setOpenBundles(tempArray)
                        }
                        setInitialLoad(true)
                    }
                } catch (error) {
                    const mappedError = errorConverter(error)
                    if (!(mappedError instanceof ClientError)) {
                        throw error
                    }
                    return displayErrorMessage(mappedError, enqueueAlert)
                }
            } else {
                const tempArray = openBundles.map((bundle) => {
                    if (bundle.id === updatedBundle.id) {
                        return updatedBundle
                    } else {
                        return bundle
                    }
                })
                setOpenBundles(tempArray)
            }
        }
    }

    const scanTest = new RegExp("^[0-9]{2}.{2}([0-9A-Z]{26}|[0-9]{2,5})$")

    const scanBarcode = async () => {
        const partnerId = getSelectedAgreement()?.account.id
        const stationId = selectedStation?.id

        if (!partnerId || !stationId) {
            return
        }
        const parsedCode = parseBarcode(scanInput)
        if (!scanTest.test(parsedCode)) {
            return enqueueAlert(intl.formatMessage({ id: "recieve_worng_format" }), "error")
        }

        try {
            const res = await partnerClient.postBundle(partnerId, stationId, parsedCode)

            if (res) {
                setScanInput("") // Clear input field
                handleUpdatedBundle(res as Bundle)
                enqueueAlert(intl.formatMessage({ id: "common_scanned" }), "success")
                setInitialLoad(true)
                return
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            const extractedMessage = extractErrorMessage(mappedError)

            if (
                extractedMessage.length === 1 &&
                extractedMessage[0].includes("present in bundle")
            ) {
                return enqueueAlert(
                    intl.formatMessage(
                        { id: "receieve_multiple_same_barcode_error" },
                        { barcode: parsedCode },
                    ),
                    "error",
                )
            }
            return enqueueAlert(intl.formatMessage({ id: "common_error" }), "error")
        }
    }

    const scanAssignStorageUnit = async () => {
        const partnerId = getSelectedAgreement()?.account.id
        const stationId = selectedStation?.id
        const locationId = selectedLocation?.id

        if (!partnerId || !stationId || !locationId) {
            return
        }

        const storageUnit = await partnerClient.getStorageUnits(partnerId, locationId, {
            pageSize: 1,
            filters: { barcode: `*${storageUnitInput}*` },
        })

        if (storageUnit && storageUnit[0]) {
            if (storageUnit[0].completed) {
                return enqueueAlert(
                    intl.formatMessage({ id: "receive_storage_unit_warning" }),
                    "warning",
                )
            }

            if (
                openStorageUnits.findIndex((su) => {
                    return su.sku === storageUnit[0].sku
                }) === -1
            ) {
                const data = await partnerClient.patchStorageUnit(
                    partnerId,
                    locationId,
                    storageUnit[0].id,
                    {
                        station_id: stationId,
                    },
                )

                if (data) {
                    setStorageUnitInput("")
                    setOpenStorageUnits((prev) => [...prev, data])
                    setInitialLoad(true)
                    return
                }
            } else {
                return enqueueAlert(intl.formatMessage({ id: "receive_sku_warning" }), "warning")
            }
        }
        return enqueueAlert(
            intl.formatMessage({ id: "receive_storageunit_not_found" }, { item: storageUnitInput }),
            "warning",
        )
    }

    const getAssignedStorageUnitWithSameSku = useCallback(
        (bundle: Bundle) => {
            return openStorageUnits.find((su) => su.sku == bundle.packaging[0].substring(0, 4))
        },
        [openStorageUnits],
    )
    const getOpenBundleWithSameSKU = useCallback(
        (storageUnit: StorageUnit) => {
            return openBundles.find((b) => b.packaging[0].substring(0, 4) === storageUnit.sku)
        },
        [openBundles],
    )

    const mappedRowData = useMemo(() => {
        const mapped = new Array<StationStatusTableRow>()

        openBundles.forEach((bundle) => {
            const assignedSU = getAssignedStorageUnitWithSameSku(bundle)
            mapped.push({
                bundle: bundle,
                su: assignedSU,
            } as StationStatusTableRow)
        })
        openStorageUnits.forEach((su) => {
            const openBundleWithSameSKU = getOpenBundleWithSameSKU(su)
            if (openBundleWithSameSKU == undefined) {
                mapped.push({
                    bundle: undefined,
                    su: su,
                } as StationStatusTableRow)
            }
        })

        return mapped.sort((a, b) => {
            const aSKU = a.bundle?.packaging[0].substring(0, 4) ?? a.su?.sku
            const bSKU = b.bundle?.packaging[0].substring(0, 4) ?? b.su?.sku
            if (aSKU && bSKU) {
                return aSKU.localeCompare(bSKU)
            } else {
                return 0
            }
        })
    }, [getAssignedStorageUnitWithSameSku, getOpenBundleWithSameSKU, openBundles, openStorageUnits])

    const releaseSU = useCallback(
        async (storageUnit: StorageUnit) => {
            const partnerId = getSelectedAgreement()?.account.id
            if (!partnerId || !storageUnit || !storageUnit.location_id) {
                return
            }
            const obj = { station_id: null }
            const data = await partnerClient.patchStorageUnit(
                partnerId,
                storageUnit.location_id,
                storageUnit.id,
                obj,
            )

            if (data) {
                const filterSu = openStorageUnits.filter((item) => item.id !== storageUnit.id)

                setOpenStorageUnits(filterSu)
                setInitialLoad(true)
            }
        },
        [getSelectedAgreement, openStorageUnits, partnerClient],
    )

    const itemToRow = useCallback(
        (item: StationStatusTableRow, index: number) => {
            const { bundle, su } = item

            const sku = bundle?.packaging[0].substring(0, 4) ?? su?.sku

            return (
                <StyledBodyRow key={index}>
                    <StyledTableCell>{sku}</StyledTableCell>
                    <StyledTableCell>{getProductNameFromSKU(sku ?? "")}</StyledTableCell>
                    <StyledTableCell>
                        {bundle ? `${bundle.packaging.length} of ${bundle.size}` : "-"}
                    </StyledTableCell>

                    {autoComplete ? (
                        <StyledTableCell>
                            {su != undefined
                                ? su.type
                                : intl.formatMessage({
                                      id: "receive_page_no_su_assigned",
                                  })}
                        </StyledTableCell>
                    ) : null}
                    {autoComplete ? (
                        <StyledTableCell>
                            {su != undefined
                                ? `${su.quantity}/${su.size}`
                                : intl.formatMessage({
                                      id: "receive_page_no_su_assigned",
                                  })}
                        </StyledTableCell>
                    ) : null}

                    <StyledTableCell>
                        <ThreeDotMenu
                            menuItems={[
                                bundle ? (
                                    <StyledMenuItem
                                        key={`${index}-bundle`}
                                        onClick={() => {
                                            setSelectedBundle(bundle)
                                        }}>
                                        {intl.formatMessage({ id: "receive_view_bundle" })}
                                    </StyledMenuItem>
                                ) : null,
                                su ? (
                                    <StyledMenuItem
                                        key={`${index}-su`}
                                        onClick={() =>
                                            navigate(
                                                `/warehouse/storage-units/view/${su?.location_id}/unit/${su?.id}`,
                                            )
                                        }>
                                        <RemoveRedEye width={25} height={25} />
                                        {intl.formatMessage({ id: "common_view" })}
                                    </StyledMenuItem>
                                ) : null,
                                su ? (
                                    <StyledMenuItem
                                        key={`${index}-release`}
                                        disabled={!su}
                                        onClick={() => {
                                            releaseSU(su)
                                        }}>
                                        {intl.formatMessage({ id: "receive_release_su" })}
                                    </StyledMenuItem>
                                ) : null,
                            ]}
                        />
                    </StyledTableCell>
                </StyledBodyRow>
            )
        },
        [autoComplete, getProductNameFromSKU, intl, navigate, releaseSU],
    )

    const mapBundle = (packageId: string, index: number) => {
        return (
            <StyledBodyRow key={index}>
                <StyledTableCell>{packageId}</StyledTableCell>
            </StyledBodyRow>
        )
    }

    return {
        itemToRow,
        mappedRowData,
        scanAssignStorageUnit,
        scanBarcode,
        onStationChange,
        showCompletedBundleDialog,
        setShowCompletedBundleDialog,
        showNoStorageUnitAssignedDialog,
        setShowNoStorageUnitAssignedDialog,
        selectedStation,
        stations,
        selectedLocation,
        locations,
        storageUnitInput,
        setStorageUnitInput,
        scanInput,
        setScanInput,
        selectedBundle,
        setSelectedBundle,
        mapBundle,
        autoComplete,
        setAutoComplete,
        focusWhenSelectedElement,
        showStorageFilledPopup,
        setShowStorageFilledPopup,
        stationModal,
        setStationModal,
        setSelectedStation,
        initialLoad,
        setInitialLoad,
        noStationAvailable,
        setNoStationAvailable,
    }
}
