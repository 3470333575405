import { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { useUser } from "src/contexts/UserConsumer"
import { useNavigate } from "react-router-dom"
import loginImage from "../assets/images/LoginImage.jpg?srcset"
import { useIntl } from "react-intl"
import { IconButton, InputAdornment, styled } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { SourceSetImage } from "@repo/react-utils/sourceSetImage"
import { ReactComponent as Logo } from "../assets/images/RE-ZIP-logo.svg"
import { ButtonType, CustomButton } from "src/components/generalComponents/Buttons"

const ThemeLogo = styled(Logo)(({ theme }) => ({
    filter: "brightness(0%)",
    height: "150px",
    width: "150px",
    ...theme.applyStyles("dark", {
        filter: "brightness(100%)",
    }),
}))

// missing fonts
export default function Login() {
    const [showWrongCredsLabel, setShowWrongCredsLabel] = useState(false)
    const navigate = useNavigate()
    const { refreshUser, login, getUser, getToken, imposter } = useUser()
    const intl = useIntl()
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = () => setShowPassword(!showPassword)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        try {
            const token = await login({
                email: data.get("email")?.toString(),
                password: data.get("password")?.toString(),
            })
            if (token.length > 0) {
                const successfullyFetchedUser = await refreshUser(token)
                if (successfullyFetchedUser) {
                    setShowWrongCredsLabel(false)

                    navigate("/change-account")
                }
            } else {
                setShowWrongCredsLabel(true)
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            setShowWrongCredsLabel(true)
        }
    }

    const user = getUser()
    const token = getToken()

    useEffect(() => {
        if (user && token) {
            if (imposter?.id) {
                navigate("/home")
            } else {
                navigate("/change-account")
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Empty dependency makes the effect run only once

    return (
        <Grid container sx={{ height: "100vh", backgroundColor: "secondaryBackground.main" }}>
            <Grid
                alignContent="flex-start"
                display={{ xs: "none", lg: "flex" }}
                sx={{ marginRight: "10%", overflow: "hidden" }}>
                <SourceSetImage style={{ maxHeight: "100vh" }} sourceSet={loginImage} />
                <Grid sx={{ zIndex: "4", top: "93%", right: 100, position: "relative" }}>
                    <Logo style={{ width: 62, height: 17 }} />

                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "inherit",
                            color: "#f6f6f6",
                        }}>
                        Manager
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={2}
                margin={{ xs: "0 auto", lg: "0" }}
                sx={{ maxWidth: "80vw", width: "380px" }}>
                <Grid display={{ xs: "flex", lg: "none" }} sx={{ margin: "0 auto" }}>
                    <ThemeLogo />
                </Grid>
                <Grid container direction="column">
                    <Typography variant="h1" sx={{ fontFamily: "Changa", color: "text.primary" }}>
                        Log In
                    </Typography>
                    <Grid
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ width: "100%" }}>
                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "common_email" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiInputBase-root-input": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            aria-label="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "common_password" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiOutlinedInput": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            aria-label="password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                        />
                        {showWrongCredsLabel && (
                            <Typography
                                id="failedLoginLabel"
                                sx={{ fontSize: "16px", color: "warning.main" }}>
                                {intl.formatMessage({ id: "wrong_credentials" })}
                            </Typography>
                        )}
                        <Grid container direction="column" alignItems="flex-end">
                            <CustomButton
                                type={ButtonType.submit}
                                text={intl.formatMessage({
                                    id: "login",
                                    defaultMessage: "Log In",
                                })}
                                styles={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    width: "120px",
                                }}
                            />
                        </Grid>
                        <Grid container direction="column" alignItems="flex-end">
                            <Link
                                sx={{
                                    color: "link.primary",
                                    textDecoration: "none",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "22px",
                                }}
                                href="/password-reset"
                                variant="body2">
                                {intl.formatMessage({ id: "forgot_password" })}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
