import { ImgHTMLAttributes } from "react"
import { ModuleExport } from "vite-plugin-srcset"

export type SourceSetImageProps = { sourceSet: ModuleExport } & Omit<
    ImgHTMLAttributes<unknown>,
    "src" | "srcSet"
>

const fixUrl = (x: string) => {
    if (x.startsWith("file://")) {
        return x.split(/dist\/(staging|sandbox|production)\/server/)[2]
    }
    return x
}

export const SourceSetImage = ({ sourceSet, ...rest }: SourceSetImageProps) => {
    return (
        <picture {...rest}>
            {sourceSet.sources.map((s) => {
                const value = s.srcset.split(" ").map(fixUrl).join(" ")
                return <source key={value} type={s.type} srcSet={value} />
            })}
            <img src={fixUrl(sourceSet.fallback)} {...rest} />
        </picture>
    )
}
