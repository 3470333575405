import { Navigate, Outlet } from "react-router-dom"

function Root() {
    if (window.location.pathname === "/") {
        Navigate({ to: "/login" })
    }

    return <Outlet />
}

export default Root
