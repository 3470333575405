import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { CustomHelp } from "src/components/generalComponents/CustomHelp"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import IdCell from "src/components/table/IdCell"
import Page from "src/components/layout/Page"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { QueryingOptions } from "@repo/rezip-client"
import { OrderWithShopName, Shop } from "@repo/rezip-client/partner_client"
import { getPercentageOfOrder, getProgressOfOrder } from "src/utils/orderCalcualations"

export default function Fulfill() {
    const intl = useIntl()
    const navigate = useNavigate()
    const { getSelectedAgreement, getToken } = useUser()
    const [initialLoad, setInitialLoad] = useState(true)
    const partnerId = getSelectedAgreement()?.account.id
    const { partnerClient } = useClient()
    const [shops, setShops] = useState<Shop[] | null>(null)

    useEffect(() => {
        if (!shops && partnerId) {
            const getShopName = async () => {
                const data = await partnerClient.getShops(partnerId, { pageSize: 9999 })

                setShops(data)
            }
            getShopName()
        }
    }, [getToken, partnerClient, partnerId, shops])

    const fetchOrders = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!partnerId || !shops) {
                return null
            }
            // fulfill should only fetch openorders
            const onlyOpen = { state: "0" }
            const options = {
                filters: { ...filters, ...onlyOpen },
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            const resp = await partnerClient.getOrders(partnerId, options)

            if (resp) {
                const allOrders = resp.map((item) => {
                    const found = shops.find((shop) => item.shop_id === shop.id)
                    if (found) {
                        return { ...item, shopName: found.name } as OrderWithShopName
                    } else {
                        return { ...item, shopName: "" } as OrderWithShopName
                    }
                })
                return allOrders
            }
            return null
        },
        [partnerClient, partnerId, shops],
    )

    const itemToRow = (order: OrderWithShopName, index: number) => {
        return (
            <StyledBodyRow key={index}>
                <StyledTableCell key={`${index}-shop_name`}>{order.shopName ?? ""}</StyledTableCell>
                <StyledTableCell key={`${index}-created_at`}>
                    {dayjs(new Date(order.created_at)).format("DD/MM/YYYY")}
                </StyledTableCell>
                <IdCell id={order.id} key={`${index}-shop_id`} />
                <StyledTableCell key={`${index}-shop_numbers`}>
                    {getProgressOfOrder(order)}
                </StyledTableCell>
                <StyledTableCell key={`${index}-shop-percent`}>
                    {getPercentageOfOrder(order)}
                </StyledTableCell>
                <StyledTableCell>
                    <CustomButton
                        onClick={() => navigate(`/warehouse/fulfill/${order.id}`)}
                        text={intl.formatMessage({
                            id: "orders_fulfill_button",
                        })}
                    />
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_fulfill",
            })}>
            <DefaultWrapperPaper>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
                    <Typography variant="h4">
                        {intl.formatMessage({ id: "orders_open_orders" })}
                    </Typography>
                    <CustomHelp title={intl.formatMessage({ id: "fulfill_tooltip" })} />
                </Box>
                <AllInOneTable<OrderWithShopName>
                    headers={[
                        {
                            label: intl.formatMessage({
                                id: "orders_table_shop",
                            }),
                            key: "Shop_name",
                            excludeFromSearch: true,
                        },
                        {
                            label: intl.formatMessage({
                                id: "orders_table_order_date",
                            }),
                            key: "order_date",
                            excludeFromSearch: true,
                        },
                        {
                            label: intl.formatMessage({
                                id: "orders_table_order_number",
                            }),
                            key: "order_number",
                            excludeFromSearch: true,
                        },
                        {
                            key: "progress",
                            label: intl.formatMessage({
                                id: "common_progress",
                            }),
                            excludeFromSearch: true,
                        },
                        {
                            key: "percentage",
                            label: intl.formatMessage({
                                id: "common_percentage",
                            }),
                            excludeFromSearch: true,
                        },
                        {
                            key: "actions",
                            label: "",
                            excludeFromSearch: true,
                        },
                    ]}
                    itemsToRow={itemToRow}
                    defaultSortBy={"created_at"}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    fetchItems={fetchOrders}
                />
            </DefaultWrapperPaper>
        </Page>
    )
}
