import { createTheme, Theme } from "@mui/material/styles"
import "@repo/assets/fonts/fonts.css"
import {
    backgroundColor,
    actionGreen,
    ecoGreen,
    softGreen,
    softBlack,
    lightBlue,
    cozyPurple,
    borderGray,
    lightGray,
    ecoGreenLight,
    dmText,
    dmMediumDark,
    dmDark,
    dmLight,
} from "src/components/colors"
// To add more colors to the palette that use background as its type

declare module "@mui/material/styles" {
    interface Palette {
        custom: Palette["background"]
        infoButton: Palette["primary"]
        required: Palette["primary"]
        link: Palette["text"]
        coreBackground: Palette["primary"]
        secondaryBackground: Palette["primary"]
        standardOutlineButton: Palette["primary"]
        inputField: FieldPalette
        fileUpload: FieldPalette
    }

    interface PaletteOptions {
        custom?: PaletteOptions["background"]
        infoButton: PaletteOptions["primary"]
        required: PaletteOptions["primary"]
        link: PaletteOptions["text"]
        coreBackground: PaletteOptions["primary"]
        secondaryBackground: PaletteOptions["primary"]
        standardOutlineButton: PaletteOptions["primary"]
        inputField: FieldPalette
        fileUpload: FieldPalette
    }

    interface SimplePaletteColorOptions {
        disabled?: string
    }

    interface FieldPalette {
        background?: string
        border?: string
        text?: string
        icon?: string
        focus?: string
        accept?: string
        reject?: string
    }

    interface TypographyVariants {
        label: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        label?: React.CSSProperties
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        label: true
    }
}

// Custom theme
export let theme = createTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: actionGreen,
                    dark: ecoGreen,
                    light: softGreen,
                    contrastText: ecoGreen,
                    disabled: "#EBEBEB",
                },
                secondary: {
                    main: cozyPurple,
                    dark: cozyPurple,
                    contrastText: "#FFFFFF",
                },
                text: {
                    primary: softBlack,
                    secondary: "#95A0A4",
                    disabled: "#BDBDBD",
                },
                error: {
                    main: "#FF0000",
                    dark: "#ffb3be",
                    contrastText: softBlack,
                },
                warning: {
                    main: "#DA373C",
                    light: "#FFDDE8",
                    contrastText: "#FFFFFF",
                },
                success: {
                    main: actionGreen,
                },
                infoButton: {
                    main: "#1bab59",
                    light: "#66d18a",
                    contrastText: "#FFFFFF",
                },
                required: {
                    main: "#ff0052",
                },
                link: {
                    primary: ecoGreenLight,
                },
                coreBackground: {
                    main: backgroundColor,
                },
                secondaryBackground: {
                    main: "#ffffff",
                },
                standardOutlineButton: {
                    main: actionGreen,
                    light: "#ffffff",
                    dark: actionGreen,
                    contrastText: actionGreen,
                },
                inputField: {
                    background: lightBlue,
                    border: borderGray,
                    icon: "#979797",
                },
                action: {
                    disabled: "#EBEBEB", // Styles the border on disabled input fields
                },
                fileUpload: {
                    background: lightGray,
                    border: "#EEEEEE",
                    text: "#8E8E8E",
                    focus: "#2196F3",
                    accept: "#00E676",
                    reject: "#FF1744",
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    main: actionGreen,
                    dark: ecoGreen,
                    light: softGreen,
                    contrastText: ecoGreen,
                    disabled: "#EBEBEB20",
                },
                secondary: {
                    main: dmDark,
                    dark: "#DBDEE1",
                    contrastText: dmText,
                },
                text: {
                    primary: dmText,
                    secondary: "#95A0A4",
                    disabled: "#50595E",
                },
                error: {
                    main: "#FF0000",
                    dark: "#ffb3be",
                    contrastText: softBlack,
                },
                warning: {
                    main: "#DA373C",
                    light: "#FFDDE8",
                    contrastText: "#FFFFFF",
                },
                success: {
                    main: actionGreen,
                },
                infoButton: {
                    main: "#1bab59",
                    light: "#66d18a",
                    contrastText: "#FFFFFF",
                },
                required: {
                    main: "#ff0052",
                },
                link: {
                    primary: "#66B3FF",
                },
                coreBackground: {
                    main: dmMediumDark,
                },
                secondaryBackground: {
                    main: dmLight,
                },
                standardOutlineButton: {
                    main: actionGreen,
                    light: "#FFFFFF",
                    dark: actionGreen,
                    contrastText: actionGreen,
                },
                inputField: {
                    background: dmDark,
                    border: dmLight,
                    icon: "#979797",
                },
                action: {
                    disabled: dmLight, // Styles the border on disabled input fields
                },
                fileUpload: {
                    background: dmMediumDark,
                    border: "#393B3C",
                    text: "#909CA2",
                    focus: "#2196F3",
                    accept: "#00E676",
                    reject: "#FF1744",
                },
            },
        },
    },
    /* Typography styling */
    typography: {
        fontFamily: "Manrope",
        h1: {
            fontSize: "32px",
            lineHeight: 1.2,
            fontWeight: 700,
            letterSpacing: "-0.02em",
            margin: "0px 0px 20px 0px",
        },
        h2: {
            fontSize: "26px",
            fontWeight: "800",
        },
        h3: {
            fontSize: "25px",
            fontWeight: "800",
        },
        h4: {
            fontSize: "20px",
            fontWeight: "800",
            marginBottom: "5px",
        },
        h5: {
            fontSize: "50px",
            fontWeight: "800",
        },
        h6: {
            fontSize: "18px",
            fontWeight: "800",
        },
        subtitle1: {
            fontSize: "16px",
            fontWeight: 500,
            marginTop: "8px",
            marginBottom: "16px",
        },
        subtitle2: {
            fontSize: "14px",
        },
        body1: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
        },
        body2: {
            fontSize: "16px",
            fontWeight: 400,
        },
        button: {
            fontSize: "14px",
        },
        caption: {
            fontSize: "14px",
        },
        label: {
            fontSize: "16px",
            fontWeight: 400,
        },
    },
})

// Component style overrides need to be separate like this in order to utilize the color palette set above
theme = createTheme(theme, {
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                    boxShadow: "1px 1px 3px #00000020",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 100,
                    boxShadow: "none",
                    padding: "5px 20px",
                    ":focus": {
                        outline: "none",
                        boxShadow: "none",
                    },
                    ":hover": {
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: ({ theme }: { theme: Theme }) => ({
                    backgroundColor: theme.palette.secondaryBackground.main,
                    backgroundImage: "none",
                    boxShadow: "1px 1px 3px #00000020",
                    ...theme.applyStyles("dark", {
                        boxShadow: "1px 1px 3px #00000050",
                    }),
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    "& fieldset": {
                        border: "1px solid",
                        borderColor: theme.palette.inputField.border,
                        borderRadius: "3px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid",
                        borderColor: theme.palette.inputField.border,
                    },
                    "& .MuiInputAdornment-root p": {
                        fontSize: "16px",
                    },
                }),
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit",
                    "&::before": {
                        border: "none",
                    },
                    "&:hover": {
                        backgroundColor: "inherit",
                        "&:not(.Mui-disabled, .Mui-error)::before": {
                            border: "none",
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    color: theme.palette.text.primary,
                    fontSize: "14px",
                    backgroundColor: theme.palette.inputField.background,
                }),
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    "& .MuiFormControlLabel-label": {
                        fontSize: "16px",
                        fontWeight: "400",
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ theme }: { theme: Theme }) => ({
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    borderRadius: "8px",
                    boxShadow: "1px 1px 3px #00000010",
                    fontSize: "12px",
                    padding: "12px",
                    ...theme.applyStyles("dark", {
                        boxShadow: "1px 1px 3px #00000050",
                    }),
                }),
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: "20px 0 20px 0",
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    "&:before": {
                        display: "none",
                    },
                    "& .Mui-expanded": {
                        margin: "0",
                    },
                    "& .MuiAccordionSummary-root.Mui-expanded": {
                        minHeight: "0",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "0",
                    },
                },
            },
        },
    },
})
