import { Edit } from "@mui/icons-material"
import { Box, Dialog, DialogActions, DialogContent } from "@mui/material"
import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import IdCell from "src/components/table/IdCell"
import Page from "src/components/layout/Page"
import { StyledMenuItem } from "src/components/styles"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { TrashCan } from "src/components/TrashCan"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { QueryingOptions } from "@repo/rezip-client"
import { Location } from "@repo/rezip-client/partner_client"

export default function WarehouseLocations() {
    const { getSelectedAgreement, setSelectedLocationId, getSelectedLocationId } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const intl = useIntl()
    const navigate = useNavigate()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<string | null>()
    const { enqueueAlert } = useSnackbar()
    const { partnerClient } = useClient()
    const [initialLoad, setInitialLoad] = useState(true)

    const deleteLocation = useCallback(async () => {
        if (!partnerId || !itemToDelete) {
            return
        }

        const resp = await partnerClient.deleteWarehouseLocation(partnerId, itemToDelete)

        if (resp) {
            if (getSelectedLocationId() === itemToDelete) {
                setSelectedLocationId(undefined)
            }
            setDialogOpen(false)
            setItemToDelete(null)
            setInitialLoad(true)
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_message_location_deleted",
                }),
                "success",
            )
        } else {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_something_went_wrong",
                }),
                "error",
            )
        }
    }, [
        enqueueAlert,
        getSelectedLocationId,
        intl,
        itemToDelete,
        partnerClient,
        partnerId,
        setSelectedLocationId,
    ])

    const fetchItems = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!partnerId) {
                return
            }
            filters = { ...filters, deleted_at: "" }
            const options = {
                filters: { ...filters },
                pageFrom: pageFrom,
                pageSize: pageSize,
                sortBy: sortBy,
                sortDir: sortDir,
            }
            return await partnerClient.getWarehouseLocations(partnerId, options)
        },
        [partnerClient, partnerId],
    )

    const mapLocationToRow = (item: Location, index: number) => {
        return (
            <StyledBodyRow key={index}>
                <StyledTableCell>{item.name}</StyledTableCell>
                <IdCell id={item.id} />
                <StyledTableCell>
                    <ThreeDotMenu
                        menuItems={[
                            <StyledMenuItem
                                key={`${index}-view`}
                                onClick={() => navigate(`${item.id}/view`)}>
                                <Edit width={25} height={25} />
                                {intl.formatMessage({
                                    id: "common_edit",
                                })}
                            </StyledMenuItem>,
                            <StyledMenuItem
                                key={`${index}-delete`}
                                sx={{ color: "warning.main" }}
                                onClick={() => {
                                    setDialogOpen(true)
                                    setItemToDelete(item.id)
                                }}>
                                <TrashCan width={25} height={25} />
                                {intl.formatMessage({
                                    id: "common_delete",
                                })}
                            </StyledMenuItem>,
                        ]}
                    />
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_warehouse_locations",
            })}>
            <DefaultWrapperPaper>
                <AllInOneTable<Location>
                    headers={[
                        {
                            key: "name",
                            label: intl.formatMessage({
                                id: "common_name",
                            }),
                            excludeFromSearch: true,
                        },
                        {
                            key: "location_id",
                            label: intl.formatMessage({
                                id: "common_location_id",
                            }),
                            excludeFromSearch: true,
                        },

                        { key: "actions", label: "", excludeFromSearch: true },
                    ]}
                    itemsToRow={mapLocationToRow}
                    defaultSortBy={"id"}
                    fetchItems={fetchItems}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    addFunction={() => navigate("create")}
                />
            </DefaultWrapperPaper>
            <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
                <DialogContent>Are you sure you want to delete this location?</DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            alignSelf: "center",
                        }}>
                        <CustomButton
                            onClick={() => {
                                setDialogOpen(false)
                                setItemToDelete("")
                            }}
                            text={intl.formatMessage({
                                id: "common_no",
                            })}
                            colorTheme={ColorThemeName.standardOutline}
                        />
                        <CustomButton
                            onClick={deleteLocation}
                            text={intl.formatMessage({
                                id: "common_yes",
                            })}
                            colorTheme={ColorThemeName.standardOutline}
                        />
                    </Box>
                </DialogActions>
            </Dialog>
        </Page>
    )
}
