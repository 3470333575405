import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    styled,
} from "@mui/material"
import { useCallback, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import Page from "src/components/layout/Page"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { StyledMenuItem } from "src/components/styles"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { QueryingOptions } from "@repo/rezip-client"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { Agreement, Agreements } from "@repo/rezip-client/partner_client"
import EditIcon from "@mui/icons-material/Edit"
import { TrashCan } from "src/components/TrashCan"
import { JSX } from "react"
import { ColorThemeName, CustomButton } from "./generalComponents/Buttons"

interface StatusElementProps {
    active: number
}

const StatusBox = styled(Box)<StatusElementProps>(({ active, theme }) => ({
    borderRadius: "100px",
    border: active
        ? `1px solid ${theme.palette.infoButton.main}`
        : `1px solid ${theme.palette.warning.main}`,
    display: "flex",
    maxWidth: "100px",
    justifyContent: "center",
    color: active ? `${theme.palette.infoButton.main}` : ` ${theme.palette.warning.main}`,
}))
interface UserOverviewComponentProps {
    deleteUser: (agreementId: string) => void
    fetchAgreements: ({
        pageSize,
        pageFrom,
        sortBy,
        sortDir,
        filters,
    }: QueryingOptions) => Promise<Agreement[] | Agreements | undefined | null>
    to: string
    addFunctionTo: string
    initialLoad: boolean
    setInitialLoad: (value: boolean) => void
    additionalElement?: JSX.Element
}

export const UserOverviewComponent = ({
    deleteUser,
    fetchAgreements,
    to,
    addFunctionTo,
    initialLoad,
    setInitialLoad,
    additionalElement,
}: UserOverviewComponentProps) => {
    const intl = useIntl()
    const [removeId, setRemoveId] = useState<string>("")
    const [dialogOpen, setDialiogOpen] = useState(false)
    const navigate = useNavigate()

    const DeleteDialog = () => {
        return (
            <Dialog open={dialogOpen}>
                <DialogTitle>{intl.formatMessage({ id: "are_you_sure" })}</DialogTitle>
                <DialogContent>
                    {intl.formatMessage({ id: "delete_agreement_message" })}
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        onClick={() => setDialiogOpen(false)}
                        text={intl.formatMessage({ id: "common_cancel" })}
                        colorTheme={ColorThemeName.noBackground}
                    />
                    <CustomButton
                        onClick={() => {
                            deleteUser(removeId)
                            setDialiogOpen(false)
                        }}
                        text={intl.formatMessage({ id: "common_continue" })}
                        colorTheme={ColorThemeName.warning}
                    />
                </DialogActions>
            </Dialog>
        )
    }

    const StatusElement = ({ active }: StatusElementProps) => {
        return (
            <StatusBox active={active}>
                <Typography
                    sx={{
                        fontSize: "15px",
                        padding: "3px 8px",
                        lineHeight: "1.6",
                        fontWeight: "500",
                    }}>
                    {active ? (
                        <FormattedMessage id="users_status_element_accpeted" />
                    ) : (
                        <FormattedMessage id="users_status_element_invited" />
                    )}
                </Typography>
            </StatusBox>
        )
    }

    const mapAgreements = useCallback(
        (item: Agreement, index: number) => {
            return (
                <StyledBodyRow key={index}>
                    <StyledTableCell sx={{ width: "33%" }} key={`name-${index}`}>
                        <Typography> {item.user?.name}</Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "33%" }} key={`email-${index}`}>
                        <Typography>{item.user?.email}</Typography>
                    </StyledTableCell>
                    <StyledTableCell key={`status-${index}`}>
                        <StatusElement active={item.accepted ? 1 : 0} />
                    </StyledTableCell>
                    <StyledTableCell key={`actions-${index}`}>
                        <ThreeDotMenu
                            menuItems={[
                                <StyledMenuItem
                                    key={`${index}-view`}
                                    onClick={() => navigate(`${to}${item.id}`)}>
                                    <EditIcon />
                                    {intl.formatMessage({
                                        id: "users_permissions_action",
                                    })}
                                </StyledMenuItem>,
                                <StyledMenuItem
                                    key={`${index}-delete`}
                                    sx={{ color: "warning.main" }}
                                    onClick={() => {
                                        setRemoveId(item.id)
                                        setDialiogOpen(true)
                                    }}>
                                    <TrashCan width={25} height={25} />
                                    {intl.formatMessage({
                                        id: "users_permissions_remove",
                                    })}
                                </StyledMenuItem>,
                            ]}
                        />
                    </StyledTableCell>
                </StyledBodyRow>
            )
        },
        [intl, navigate, to],
    )

    return (
        <>
            <Page
                title={intl.formatMessage({
                    id: "menu_listitem_users",
                })}>
                {additionalElement}
                <DefaultWrapperPaper>
                    <AllInOneTable<Agreement>
                        headers={[
                            {
                                label: intl.formatMessage({ id: "common_name" }),
                                key: "name",
                                excludeFromSearch: true,
                            },
                            {
                                label: intl.formatMessage({ id: "common_email" }),
                                key: "email",
                                excludeFromSearch: true,
                            },
                            { label: "Status", key: "state", excludeFromSearch: true },
                            { label: "", key: "actions", excludeFromSearch: true },
                        ]}
                        itemsToRow={mapAgreements}
                        defaultSortBy={"id"}
                        fetchItems={fetchAgreements}
                        initialLoad={initialLoad}
                        setInitialLoad={setInitialLoad}
                        addFunction={() => navigate(addFunctionTo)}
                        addButtonText={intl.formatMessage({
                            id: "users_create_invite",
                        })}
                    />
                </DefaultWrapperPaper>
            </Page>
            <DeleteDialog />
        </>
    )
}
