export const refereneceScenario = {
    board: {
        referenceType: "paper",
        refWeight: 124 / 1000,
        co2e: 0.000765301 * 1000,
        water: 0.0000142 * 1000,
    },
    box: {
        referenceType: "cardboard",
        refWeight: 894.1 / 1000,
        co2e: 0.001357785 * 1000,
        water: 0.0000143 * 1000,
    },
    vogue: {
        referenceType: "paper",
        refWeight: 124 / 1000,
        co2e: 0.000765301 * 1000,
        water: 0.0000142 * 1000,
    },
} as const
