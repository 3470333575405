import { Box } from "@mui/material"
import { useCallback, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import CollapseInformation from "src/components/generalComponents/CollapseInformation"

import { StyledP, StyledStrong } from "src/components/styles"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { QueryingOptions } from "@repo/rezip-client"
import { useClient } from "src/hooks/useClient.hook"

import { UserOverviewComponent } from "src/components/ViewUserComponent"

export default function Users() {
    const { getSelectedAgreement } = useUser()
    const intl = useIntl()
    const accountId = getSelectedAgreement()?.account.id

    const { partnerClient } = useClient()
    const { enqueueAlert } = useSnackbar()

    const [initialLoad, setInitialLoad] = useState(true)

    const deleteUser = useCallback(
        async (agrementId: string) => {
            if (!accountId) {
                return
            }

            const response = await partnerClient.deleteAgreement(accountId, agrementId)
            if (response) {
                enqueueAlert(
                    intl.formatMessage({
                        id: "toast_message_user_deleted",
                    }),
                    "success",
                )
                setInitialLoad(true)
                return
            } else {
                enqueueAlert(
                    intl.formatMessage({
                        id: "something_went_wrong",
                    }),
                    "error",
                )
                return
            }
        },
        [accountId, partnerClient, enqueueAlert, intl],
    )

    const fetchAgreements = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!accountId) {
                return
            }
            const options = {
                filters: { ...filters },
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            return await partnerClient.getAgreements(accountId, options)
        },
        [partnerClient, accountId],
    )

    return (
        <>
            <UserOverviewComponent
                deleteUser={deleteUser}
                fetchAgreements={fetchAgreements}
                to={"/account/users/edit/"}
                addFunctionTo={"/account/users/add"}
                initialLoad={initialLoad}
                setInitialLoad={setInitialLoad}
                additionalElement={
                    <CollapseInformation
                        header={<FormattedMessage id="users_information_box_header" />}>
                        <Box>
                            <StyledP>
                                <FormattedMessage id="users_information_box_info" />
                            </StyledP>
                            <StyledP>
                                <StyledStrong>
                                    <FormattedMessage id="users_information_box_invite" />
                                </StyledStrong>
                                <br />
                                <FormattedMessage id="users_information_box_invite_info" />
                            </StyledP>
                            <StyledP>
                                <StyledStrong>
                                    <FormattedMessage id="users_information_box_status" />
                                </StyledStrong>
                                <br />
                                <FormattedMessage id="users_information_box_staus_info" />
                            </StyledP>
                            <StyledP>
                                <StyledStrong>
                                    <FormattedMessage id="users_information_box_permissions" />
                                </StyledStrong>
                                <br />

                                <FormattedMessage id="users_information_box_permissions_info_1" />
                                <br />
                                <br />
                                <FormattedMessage id="users_information_box_permissions_info_2" />
                                <br />
                                <br />
                                <FormattedMessage id="users_information_box_permissions_info_3" />
                                <br />
                                <br />
                                <FormattedMessage id="users_information_box_permissions_info_4" />
                                <br />
                            </StyledP>
                            <StyledP>
                                <StyledStrong>
                                    <FormattedMessage id="users_information_box_remove" />
                                </StyledStrong>
                                <br />
                                <FormattedMessage id="users_information_box_remove_info" />
                            </StyledP>
                        </Box>
                    </CollapseInformation>
                }
            />
        </>
    )
}
