import { useIntl } from "react-intl"
import { IMenuItemData } from "src/components/SelectField"
const countryCodes = [
    "AD",
    "AL",
    "AT",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GB",
    "GG",
    "GI",
    "GL",
    "GR",
    "HR",
    "HU",
    "IE",
    "IM",
    "IS",
    "IT",
    "JE",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "ME",
    "MK",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RS",
    "RU",
    "SE",
    "SI",
    "SK",
    "SM",
    "UA",
    "VA",
]

const useCountryList = () => {
    const intl = useIntl()
    const CountryList: IMenuItemData[] = [
        ...countryCodes.map((x) => ({
            value: intl.formatMessage({ id: `country_name_${x}` }),
            id: x,
        })),
        {
            id: "default",
            value: intl.formatMessage({
                id: "country_select",
            }),
            disabled: true,
        },
    ]

    return CountryList
}

export default useCountryList
