import { Box, SelectChangeEvent, Grid } from "@mui/material"

import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { useClient } from "src/hooks/useClient.hook"
import { useIntl } from "react-intl"
import useCountryList from "src/utils/countries"
import { useCallback, useEffect, useState } from "react"
import { CreatePartner, Partner } from "@repo/rezip-client/mothership_client"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { Address } from "@repo/rezip-client/types"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import StyledForm from "src/components/layout/StyledForm"
import InputField from "src/components/InputField"
import { useNavigate, useParams } from "react-router-dom"
import { errorConverter } from "src/utils/errorConverter"
import { MissingFieldErrorHandler } from "src/utils/missingFieldHandler"
import { ClientError } from "@repo/rezip-client/client_error"
import { CustomButton } from "src/components/generalComponents/Buttons"

export const ViewPartner = ({ creation }: { creation: boolean }) => {
    const { mothershipClient } = useClient()
    const intl = useIntl()
    const CountryList = useCountryList()
    const [selectedCountry, setSelectedCountry] = useState<IMenuItemData | null>(null)
    const { partnerId } = useParams()
    const [companyInfo, setCompanyInfo] = useState<Partner | null>(null)
    const { BlockerAlert, setBlock, isBlocked } = useCustomBlocker()
    const [userEmail, setUserEmail] = useState<string | null>(null)
    const navigate = useNavigate()

    const { enqueueAlert } = useSnackbar()

    const handleInfoChange = <T extends keyof Partner>(field: T, value: Partner[T]) => {
        if (companyInfo) {
            setCompanyInfo({ ...companyInfo, [field]: value })
            setBlock(true)
        }
    }

    const updateCompanyInfo = useCallback(async () => {
        if (!companyInfo || !selectedCountry) {
            return enqueueAlert("error", "error")
        }

        if (partnerId) {
            const data = await mothershipClient.patchPartner(partnerId, {
                ...companyInfo,
                billing_address: { ...companyInfo.billing_address, country: selectedCountry.id },
            })

            if (data) {
                setBlock(false)
                setCompanyInfo(data)

                const currentCountry = CountryList.find(
                    (item) => item.id === data.billing_address.country,
                )
                if (currentCountry) {
                    setSelectedCountry(currentCountry)
                }

                return enqueueAlert("success", "success")
            } else {
                return enqueueAlert("error", "error")
            }
        }
    }, [
        companyInfo,
        selectedCountry,
        partnerId,
        enqueueAlert,
        mothershipClient,
        setBlock,
        CountryList,
    ])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cleanAddressFields = (address?: Record<string, any>): void => {
        if (!address) return // Guard clause for undefined or null

        Object.keys(address).forEach((key) => {
            if (address[key] === null || address[key] === undefined) {
                delete address[key]
            }
        })
    }

    const postPartner = useCallback(async () => {
        if (!userEmail || !companyInfo) {
            return enqueueAlert("error", "error")
        }

        const editedInfo: CreatePartner = { ...companyInfo, created_at: null, updated_at: null }
        if (editedInfo.billing_address) {
            cleanAddressFields(editedInfo.billing_address)
            // Remove billing_address if it is empty after cleaning
            if (Object.keys(editedInfo.billing_address).length === 0) {
                delete editedInfo.billing_address
            }
            if (!editedInfo.billing_email) {
                delete editedInfo.billing_email
            }
            if (!editedInfo.support_email) {
                delete editedInfo.support_email
            }
            if (!editedInfo.vat_no) {
                delete editedInfo.vat_no
            }
            if (!editedInfo.support_phone) {
                delete editedInfo.support_phone
            }

            if (!editedInfo.phone) {
                delete editedInfo.phone
            }
        }

        try {
            const response = await mothershipClient.postPartner(userEmail, {
                ...editedInfo,
            })
            if (response) {
                setBlock(false)
                enqueueAlert("success", "success")
                return navigate(-1)
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            MissingFieldErrorHandler(mappedError, enqueueAlert, intl)
        }
    }, [companyInfo, enqueueAlert, intl, mothershipClient, navigate, setBlock, userEmail])

    const handleAddressChange = (field: keyof Address, value: string) => {
        setCompanyInfo((prev) =>
            prev
                ? {
                      ...prev,
                      billing_address: {
                          ...prev.billing_address,
                          [field]: value,
                      },
                  }
                : null,
        )
        setBlock(true)
    }

    const onCountryChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const item = CountryList.find((item) => item.id === event.target.value)
            if (item && companyInfo && companyInfo) {
                setSelectedCountry(item)
                setBlock(true)
            }
        },
        [CountryList, companyInfo, setBlock],
    )

    useEffect(() => {
        if (!companyInfo) {
            if (!creation) {
                const fetchData = async () => {
                    if (partnerId) {
                        const companyInfo = await mothershipClient.getPartner(partnerId)

                        if (companyInfo) {
                            setCompanyInfo(companyInfo)

                            const currentCountry = CountryList.find(
                                (item) => item.id === companyInfo.billing_address.country,
                            )
                            if (currentCountry) {
                                setSelectedCountry(currentCountry)
                            }
                        }
                    }
                }
                fetchData()
            } else {
                setCompanyInfo({
                    name: "",
                    description: null,
                    phone: null,
                    vat_no: null,
                    website: null,
                    support_email: null,
                    support_phone: null,
                    billing_email: null,
                    billing_address: {
                        street: null,
                        city: null,
                        postal_code: null,
                        region: null,
                        country: null,
                        longitude: null,
                        latitude: null,
                    },
                    created_at: new Date(),
                    updated_at: new Date(),
                    id: "",
                })
            }
        }
    }, [CountryList, companyInfo, creation, mothershipClient, partnerId])

    return (
        <Page
            title={
                creation
                    ? intl.formatMessage({ id: "mothership_create_partner" })
                    : intl.formatMessage({ id: "mothership_edit_partner" })
            }>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                    <>
                        {isBlocked ? (
                            <CustomButton
                                text={
                                    creation
                                        ? intl.formatMessage({ id: "common_create" })
                                        : intl.formatMessage({ id: "common_save" })
                                }
                                onClick={creation ? postPartner : updateCompanyInfo}
                            />
                        ) : null}
                    </>
                </Box>
                <Grid container>
                    <Grid size={{ xs: 10, md: 10, lg: 5 }} sx={{ margin: "10px" }}>
                        <DefaultWrapperPaper sx={{ display: "flex", flexDirection: "column" }}>
                            <StyledForm>
                                {creation ? (
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "user_email",
                                            defaultMessage: "User Email",
                                        })}
                                        value={userEmail}
                                        onChange={(e) => setUserEmail(e.target.value)}
                                        id="user-email"
                                        name="user-email"
                                        required
                                        type={"Text"}
                                    />
                                ) : null}
                                <InputField
                                    label={intl.formatMessage({
                                        id: "name",
                                        defaultMessage: "Name",
                                    })}
                                    value={companyInfo?.name ?? ""}
                                    onChange={(e) => handleInfoChange("name", e.target.value)}
                                    id="name"
                                    name="name"
                                    required
                                    type={"Text"}
                                />

                                <InputField
                                    label={intl.formatMessage({
                                        id: "phone",
                                        defaultMessage: "Phone",
                                    })}
                                    value={companyInfo?.phone ?? ""}
                                    onChange={(e) => handleInfoChange("phone", e.target.value)}
                                    id="phone"
                                    name="phone"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "vat_no",
                                        defaultMessage: "VAT Number",
                                    })}
                                    value={companyInfo?.vat_no ?? ""}
                                    onChange={(e) => handleInfoChange("vat_no", e.target.value)}
                                    id="vat_no"
                                    name="vat_no"
                                    type={"Text"}
                                />
                                <InputField
                                    // todo change to "Terms & Condtions URL"
                                    label={intl.formatMessage({
                                        id: "website",
                                        defaultMessage: "Website",
                                    })}
                                    value={companyInfo?.website ?? ""}
                                    onChange={(e) => handleInfoChange("website", e.target.value)}
                                    id="website"
                                    name="website"
                                    type={"Text"}
                                />

                                <InputField
                                    label={intl.formatMessage({
                                        id: "support_email",
                                        defaultMessage: "Support Email",
                                    })}
                                    value={companyInfo?.support_email ?? ""}
                                    onChange={(e) =>
                                        handleInfoChange("support_email", e.target.value)
                                    }
                                    id="support_email"
                                    name="support_email"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "support_phone",
                                        defaultMessage: "Support Phone",
                                    })}
                                    value={companyInfo?.support_phone ?? ""}
                                    onChange={(e) =>
                                        handleInfoChange("support_phone", e.target.value)
                                    }
                                    id="support_phone"
                                    name="support_phone"
                                    type={"Text"}
                                />
                            </StyledForm>
                        </DefaultWrapperPaper>
                    </Grid>
                    <Grid size={{ xs: 10, md: 10, lg: 5 }} sx={{ margin: "10px" }}>
                        <DefaultWrapperPaper sx={{ display: "flex", flexDirection: "column" }}>
                            <StyledForm sx={{ display: "flex" }}>
                                <InputField
                                    label={intl.formatMessage({
                                        id: "billing_email",
                                        defaultMessage: "Billing Email",
                                    })}
                                    value={companyInfo?.billing_email ?? ""}
                                    onChange={(e) =>
                                        handleInfoChange("billing_email", e.target.value)
                                    }
                                    id="billing_email"
                                    name="billing_email"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "billing_street",
                                        defaultMessage: "Billing Street",
                                    })}
                                    value={companyInfo?.billing_address?.street ?? ""}
                                    onChange={(e) => handleAddressChange("street", e.target.value)}
                                    id="billing_street"
                                    name="billing_street"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "billing_city",
                                        defaultMessage: "Billing City",
                                    })}
                                    value={companyInfo?.billing_address?.city ?? ""}
                                    onChange={(e) => handleAddressChange("city", e.target.value)}
                                    id="billing_city"
                                    name="billing_city"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "billing_postal_code",
                                        defaultMessage: "Billing Postal Code",
                                    })}
                                    value={companyInfo?.billing_address?.postal_code ?? ""}
                                    onChange={(e) =>
                                        handleAddressChange("postal_code", e.target.value)
                                    }
                                    id="billing_postal_code"
                                    name="billing_postal_code"
                                    type={"Text"}
                                />
                                <InputField
                                    label={intl.formatMessage({
                                        id: "billing_region",
                                        defaultMessage: "Billing Region",
                                    })}
                                    value={companyInfo?.billing_address?.region ?? ""}
                                    onChange={(e) => handleAddressChange("region", e.target.value)}
                                    id="billing_region"
                                    name="billing_region"
                                    type={"Text"}
                                />
                                <SelectField
                                    label={intl.formatMessage({
                                        id: "country",
                                    })}
                                    required
                                    value={selectedCountry ? selectedCountry.id : ""}
                                    onChange={onCountryChange}
                                    menuItems={CountryList}
                                />
                            </StyledForm>
                        </DefaultWrapperPaper>
                    </Grid>
                </Grid>
            </Box>
            <BlockerAlert />
        </Page>
    )
}
