import { AlertColor } from "@mui/material"
import { IntlShape } from "react-intl"

interface RoundingHelperProps {
    intl: IntlShape
    number: number
    enqueueAlert: (message: string, alertType: AlertColor) => void
}

export const roundingHelper = (props: RoundingHelperProps) => {
    let nearestTen = Math.round(props.number / 10) * 10
    if (props.number % 10 !== 0) {
        const roundedUp = Math.ceil(props.number / 10) * 10
        const roundedDown = Math.floor(props.number / 10) * 10
        nearestTen = props.number % 10 >= 5 ? roundedUp : roundedDown

        props.enqueueAlert(
            props.intl.formatMessage({
                id:
                    nearestTen === roundedUp
                        ? "rounds_quantity_up_warning"
                        : "rounds_quantity_down_warning",
            }),
            "warning",
        )
    }
    return nearestTen
}
