import { Avatar, List, ListItem, ListItemIcon, Typography, Grid } from "@mui/material"
import { useIntl } from "react-intl"

const ImpactComponent = () => {
    const intl = useIntl()
    const steps = [
        intl.formatMessage({ id: "reports_impact_1", defaultMessage: "Increase conversion" }),
        intl.formatMessage({ id: "reports_impact_2", defaultMessage: "Increase incentive" }),
        intl.formatMessage({
            id: "reports_impact_3",
        }),
        intl.formatMessage({
            id: "reports_impact_4",
        }),
    ]

    return (
        <Grid container direction="column" size="grow" spacing={1}>
            <Grid container direction="column" sx={{ marginTop: "12px", textAlign: "center" }}>
                <Typography variant="h4">
                    {intl.formatMessage({
                        id: "reports_improve_title",
                    })}
                </Typography>
            </Grid>
            <Grid container direction="column" alignSelf="flex-start" sx={{ textAlign: "left" }}>
                <List>
                    {steps.map((step, index) => (
                        <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: "40px" }}>
                                <Avatar
                                    sx={{
                                        backgroundColor: "transparent",
                                        border: "1px solid",
                                        borderColor: "text.primary",
                                        color: "text.primary",
                                        width: 30,
                                        height: 30,
                                        fontSize: "1rem",
                                    }}>
                                    {index + 1}
                                </Avatar>
                            </ListItemIcon>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "14px",
                                }}>
                                {step}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    )
}

export default ImpactComponent
