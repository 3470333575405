import { useEffect, useState } from "react"
import { useUser } from "src/contexts/UserConsumer"

import { useParams } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { Typography, Grid } from "@mui/material"
import { AddressView } from "src/components/Address"

import OrderLinesTable from "src/components/orderComponents/OrderLinesTable"
import { useIntl } from "react-intl"
import { useClient } from "src/hooks/useClient.hook"
import { Shop } from "@repo/rezip-client/partner_client"
import { OrderConfiguration, OrderShopType } from "@repo/rezip-client/shop_client"

const ViewShopOrder = () => {
    const { getSelectedAgreement, getToken } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const token = getToken()
    const { id } = useParams()
    const [order, setOrder] = useState<OrderShopType>()
    const [shop, setShop] = useState<Shop>()
    const intl = useIntl()
    const { shopClient } = useClient()
    const [orderConfig, setOrderConfig] = useState<OrderConfiguration>()

    useEffect(() => {
        if (!order && id && partnerId) {
            const fetchOrders = async () => {
                const data = await shopClient.getOrder(partnerId, id)

                if (data) {
                    setOrder(data)
                }
            }
            fetchOrders()
        }
    }, [partnerId, id, order, token, shopClient])

    useEffect(() => {
        if (!shop && partnerId && order) {
            const fetchShop = async () => {
                const data = await shopClient.getShop(partnerId)
                const config = await shopClient.getOrderConfiguration(partnerId)
                if (data) {
                    setShop(data)
                }
                if (config) {
                    setOrderConfig(config)
                }
            }
            fetchShop()
        }
    }, [partnerId, id, shop, token, order, shopClient])

    return (
        <Page backButton title={intl.formatMessage({ id: "orders_order" })}>
            <DefaultWrapperPaper>
                <Grid container direction="column" spacing={2}>
                    {shop && (
                        <Grid container spacing={4}>
                            <Grid container direction="column" size={6} spacing={1}>
                                <Typography sx={{ fontWeight: "800" }}>
                                    {intl.formatMessage({ id: "billing_address" })}
                                </Typography>
                                <AddressView address={shop.billing_address} />
                            </Grid>
                            <Grid container direction="column" size={6} spacing={1}>
                                <Typography sx={{ fontWeight: "800" }}>
                                    {intl.formatMessage({ id: "shipping_address" })}
                                </Typography>
                                <AddressView address={shop.shipping_address} />
                            </Grid>
                        </Grid>
                    )}
                    {order && (
                        <Grid>
                            <OrderLinesTable
                                currency={orderConfig ? orderConfig?.currency || null : null}
                                lines={order.lines}
                            />
                        </Grid>
                    )}
                </Grid>
            </DefaultWrapperPaper>
        </Page>
    )
}
export default ViewShopOrder
