import { Menu, Typography } from "@mui/material"
import { OrderWithShopName } from "@repo/rezip-client/partner_client"
import { useCallback, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { OrderState } from "src/utils/commonTypes"
import { StyledMenuItem } from "../styles"
import { useClient } from "src/hooks/useClient.hook"
import { useUser } from "src/contexts/UserConsumer"
import { ExpandMore } from "@mui/icons-material"

interface OrderStateProps {
    order: OrderWithShopName
    setInitialLoad: (value: boolean) => void
}

export const OrderStateChange = ({ order, setInitialLoad }: OrderStateProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { partnerClient } = useClient()
    const { getSelectedAgreement } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleStateChange = useCallback(
        (newState: OrderState) => {
            if (!partnerId) {
                return
            }
            partnerClient.patchOrder({
                partnerId: partnerId,
                orderId: order.id,
                body: { state: newState },
            })
            setInitialLoad(true)

            handleClose()
        },
        [order.id, partnerClient, partnerId, setInitialLoad],
    )

    const getStatusColor = (id: number) => {
        switch (id) {
            case 0:
                return "#36b1fd" // Open
            case 1:
                return "#1bab59" // Fulfilled
            case 2:
                return "#ffac0b" // Invoiced
            case 3:
                return "#0042df" // Paid
            default:
                return "#cccccc" // Fallback
        }
    }

    const menuItems = useMemo(() => {
        return [
            {
                id: OrderState.Fulfilled,
                label: "orders_dropdown_fulfilled",
                className: "status fulfilled",
            },
            {
                id: OrderState.Invoiced,
                label: "orders_dropdown_invoiced",
                className: "status invoiced",
            },
            {
                id: OrderState.Payed,
                label: "orders_dropdown_paid",
                className: "status paid",
            },
        ]
            .filter((item) => item.id !== order.state)
            .map((item) => (
                <StyledMenuItem
                    style={{
                        color: "#FFFFFF",
                        borderRadius: "100px",
                        fontSize: "13px",
                        textTransform: "uppercase",
                        fontWeight: 800,
                        maxWidth: "120px",
                        textAlign: "center",
                        margin: "4px",

                        backgroundColor: getStatusColor(item.id),
                    }}
                    key={item.id}
                    onClick={() => handleStateChange(item.id)}
                    className={item.className}
                    sx={{ cursor: "pointer" }}>
                    <FormattedMessage id={item.label} />
                </StyledMenuItem>
            ))
    }, [handleStateChange, order.state])

    const itemShown = useMemo(() => {
        switch (order.state) {
            case OrderState.Open:
                return (
                    <Typography
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "4px",
                            minWidth: "100px",
                        }}
                        onClick={handleClick}
                        className={"status open"}>
                        <FormattedMessage id="orders_dropdown_open" />
                        <ExpandMore></ExpandMore>
                    </Typography>
                )
            case OrderState.Fulfilled:
                return (
                    <Typography
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "4px",
                            minWidth: "100px",
                        }}
                        className={"status fulfilled"}>
                        <FormattedMessage id="orders_dropdown_fulfilled" />
                    </Typography>
                )
            case OrderState.Invoiced:
                return (
                    <Typography
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "4px",
                            minWidth: "100px",
                        }}
                        onClick={handleClick}
                        className={"status invoiced"}>
                        <FormattedMessage id="orders_dropdown_invoiced" />
                        <ExpandMore></ExpandMore>
                    </Typography>
                )
            case OrderState.Payed:
                return (
                    <Typography
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "4px",
                            minWidth: "100px",
                        }}
                        onClick={handleClick}
                        className={"status paid"}>
                        <FormattedMessage id="orders_dropdown_paid" />
                        <ExpandMore></ExpandMore>
                    </Typography>
                )
            default:
                return "Unknown"
        }
    }, [order.state])

    return (
        <>
            {itemShown}
            <Menu
                sx={{ minWidth: "170px" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                {menuItems.map((item) => item)}
            </Menu>
        </>
    )
}
