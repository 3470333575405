import loginImage from "../assets/images/LoginImage.jpg"
import {
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    styled,
    Grid,
} from "@mui/material"
import { ReactComponent as Logo } from "../assets/images/RE-ZIP-logo.svg"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useState } from "react"
import { OTPInput } from "src/components/OTPInput"
import { useClient } from "src/hooks/useClient.hook"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { ButtonType, CustomButton } from "src/components/generalComponents/Buttons"

const ThemeLogo = styled(Logo)(({ theme }) => ({
    filter: "brightness(0%)",
    height: "150px",
    width: "150px",
    ...theme.applyStyles("dark", {
        filter: "brightness(100%)",
    }),
}))

export const ActivatePassword = () => {
    const { email } = useParams()
    const intl = useIntl()
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = () => setShowPassword(!showPassword)
    const [showConfirmPassword, setShowConformPassword] = useState(false)
    const handleClickShowConfirmPassword = () => setShowConformPassword(!showConfirmPassword)
    const handleMouseDownConfirmPassword = () => setShowConformPassword(!showConfirmPassword)
    const [error, setError] = useState(false)
    const [otpValue, setOtpValue] = useState("")
    const { anonymousClient } = useClient()
    const navigate = useNavigate()
    const { enqueueAlert } = useSnackbar()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const confirmPassword = data.get("confirm-password")?.toString()
        const password = data.get("password")?.toString()

        if (!email || !password || !confirmPassword || otpValue.length !== 6) {
            return
        }

        if (confirmPassword !== password || password.length <= 7) {
            setError(true)
            return
        }
        try {
            const resp = await anonymousClient.activate({
                email: email,
                password: password,
                otp: otpValue,
            })
            if (resp) {
                navigate("/login")
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            return enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
        }
    }

    return (
        <Grid
            container
            sx={{
                height: "100vh",
                backgroundColor: "secondaryBackground.main",
                overflow: "scroll",
            }}>
            <Grid
                alignContent="flex-start"
                display={{ xs: "none", lg: "flex" }}
                sx={{ marginRight: "10%", overflow: "hidden" }}>
                <img style={{ maxHeight: "100vh" }} src={loginImage} />
                <Grid sx={{ zIndex: "4", top: "93%", right: 100, position: "relative" }}>
                    <Logo />
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "inherit",
                            color: "#f6f6f6",
                        }}>
                        Manager
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={2}
                margin={{ xs: "0 auto", lg: "0" }}
                sx={{ maxWidth: "80vw", width: "380px", paddingBottom: "20px" }}>
                <Grid display={{ xs: "flex", lg: "none" }} sx={{ margin: "0 auto" }}>
                    <ThemeLogo />
                </Grid>
                <Grid container direction="column">
                    <Grid
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ width: "100%" }}>
                        <Typography
                            component="h1"
                            variant="h2"
                            sx={{ color: "text.primary", marginBottom: "8px" }}>
                            {intl.formatMessage({ id: "password_activaton_activate" })}
                        </Typography>
                        <Typography sx={{ color: "text.primary", fontSize: "14px" }}>
                            {intl.formatMessage({ id: "password_activaton_otp" })}
                        </Typography>
                        <OTPInput onChange={setOtpValue} />

                        <Divider />

                        <Typography
                            component="h2"
                            variant="h2"
                            sx={{ color: "text.primary", marginBottom: "8px" }}>
                            {intl.formatMessage({ id: "password_activaton_setup" })}
                        </Typography>

                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "password_activaton_registered_email" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiInputBase-root-input": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            disabled
                        />
                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "common_password" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiInputBase-root-input": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            autoComplete="password"
                            error={error}
                            type={showPassword ? "text" : "password"}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "password_confirm" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiInputBase-root-input": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="confirm-password"
                            name="confirm-password"
                            autoComplete="confirm-password"
                            type={showConfirmPassword ? "text" : "password"}
                            error={error}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownConfirmPassword}>
                                                {showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                        <Grid container direction="column" alignItems="flex-end">
                            <CustomButton
                                type={ButtonType.submit}
                                text={intl.formatMessage({ id: "password_activate_account" })}
                                styles={{ marginTop: "10px" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
