import { OrderWithShopName } from "@repo/rezip-client/partner_client"
import { OrderShopType } from "@repo/rezip-client/shop_client"

export const getProgressOfOrder = (order: OrderWithShopName | OrderShopType) => {
    let totalPackagedOrdered = 0
    let totalPackagedFulfilled = 0

    order.lines?.forEach((line) => {
        totalPackagedOrdered += line.quantity
        totalPackagedFulfilled += line.fulfilled
    })

    return `${totalPackagedFulfilled} / ${totalPackagedOrdered}`
}

export const getPercentageOfOrder = (order: OrderWithShopName | OrderShopType) => {
    let totalPackagedOrdered = 0
    let totalPackagedFulfilled = 0

    order.lines?.forEach((line) => {
        totalPackagedOrdered += line.quantity
        totalPackagedFulfilled += line.fulfilled
    })

    return `${Math.round((totalPackagedFulfilled / totalPackagedOrdered) * 100)}%`
}
