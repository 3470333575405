import { z } from "zod"

export type ULID = string

export type PatchType<T> = Partial<Omit<Omit<Omit<T, "id">, "created_at">, "updated_at">>

export const AccountType = z.enum(["RE-ZIP", "Partner", "Shop", "DropPoint"])

export type AccountType = z.infer<typeof AccountType>
export const date = z.union([z.string(), z.date()]).transform((x) => {
    if (x instanceof Date) {
        return x
    }
    return new Date(x.replace(" ", "T").replace(" ", ""))
})
export const Address = z.object({
    street: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    postal_code: z.string().optional().nullable(),
    region: z.string().nullable().optional(),
    country: z.string().optional().nullable(),
    longitude: z.coerce.number().nullable(),
    latitude: z.coerce.number().nullable(),
})

export type Address = z.infer<typeof Address>

export const Permission = z.object({
    resource: z.string(),
    get: z.boolean(),
    put: z.boolean(),
    post: z.boolean(),
    patch: z.boolean(),
    delete: z.boolean(),
    query: z.boolean(),
})

export type Permission = z.infer<typeof Permission>

export const BaseUserAgreement = z.object({
    id: z.string(),
    accepted: z.boolean(),
    owner: z.boolean(),
    mfa_required: z.boolean(),
    acl_permissions: z.array(Permission),
    acl_permission_group_ids: z
        .array(z.string())
        .nullish()
        .transform((x) => x ?? []),
    created_at: date.nullable(),
    updated_at: date.nullable(),
    expires_at: date.nullable(),
})

export type BaseUserAgreement = z.infer<typeof BaseUserAgreement>

export const UserAgreementAccount = BaseUserAgreement.extend({
    user: z.object({
        email: z.string(),
        name: z.string().nullish(),
    }),
})

export type UserAgreementAccount = z.infer<typeof UserAgreementAccount>

export const UserAgreementMe = BaseUserAgreement.extend({
    account: z.object({
        id: z.string(),
        type: AccountType,
        name: z.string(),
    }),
})

export type UserAgreementMe = z.infer<typeof UserAgreementMe>

export const PermissionGroup = z.object({
    id: z.string(),
    account_id: z.string(),
    type: z.enum(["managed", "custom"]),
    name: z.string(),
    acl_permissions: z.array(Permission),
    created_at: date.nullable(),
    updated_at: date.nullable(),
    user_agreement_ids: z.array(z.string()),
})

export type PermissionGroup = z.infer<typeof PermissionGroup>

export const BaseShop = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    phone: z.string().nullable(),
    vat_no: z.string().nullable(),
    website: z.string().nullable(),
    billing_address: Address,
    billing_email: z.string().nullable(),
    shipping_address: Address,
    shipping_email: z.string().nullable(),
    created_at: date.nullable(),
    updated_at: date.nullable(),
    operating_countries: z.string().array().nullable(),
})
export type BaseShop = z.infer<typeof BaseShop>

export const CreateShop = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    phone: z.string().nullable(),
    vat_no: z.string().nullable(),
    website: z.string().nullable(),
    billing_address: Address.optional(),
    billing_email: z.string().nullable(),
    shipping_address: Address.optional(),
    shipping_email: z.string().nullable(),
    created_at: date.nullable(),
    updated_at: date.nullable(),
    operating_countries: z.string().array().nullable(),
    user_email: z.string(),
})
export type CreateShop = z.infer<typeof CreateShop>

export type UserAgreementMeWithPermissionGroups = UserAgreementMe & {
    permission_groups: PermissionGroup[]
}

export const BaseProduct = z.object({
    id: z.string(),
    active: z.boolean(),
    currency: z.string().nullable(),
    dimension: z.object({
        depth: z.number().nullable(),
        height: z.number().nullable(),
        width: z.number().nullable(),
    }),
    group: z.object({
        description: z.string(),
        name: z.string(),
    }),
    name: z.string(),
    price: z.number(),
    sku: z.string(),
    volume: z.number().nullable().optional(),
    weight: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    bundle_size: z.number().nullable().optional(),
    created_at: date,
    updated_at: date,
    deleted_at: date.nullable(),
    primary_material: z.string().nullable(),
    surface_area: z.number().nullable(),
})
export type BaseProduct = z.infer<typeof BaseProduct>

export const Product = BaseProduct.extend({
    anonymous: z.boolean(),
})
export type Product = z.infer<typeof Product>
export const Products = z.array(Product)
export type Products = z.infer<typeof Products>

export const Assortment = z.object({
    id: z.string(),
    name: z.string(),
    price: z.number(),
    sku: z.string(),
    account_id: z.string(),
    active: z.boolean(),
    created_at: date,
    updated_at: date,
    deleted_at: date.nullable(),
})
export type Assortment = z.infer<typeof Assortment>
export const Assortments = z.array(Assortment)
export type Assortments = z.infer<typeof Assortments>
export const AgreementPostType = z.object({
    email: z.string(),
    owner: z.boolean(),
})
export type AgreementPostType = z.infer<typeof AgreementPostType>

const Account = z.object({
    id: z.string(),
    type: z.string(),
    name: z.string(),
})

export const Agreement = z.object({
    id: z.string(),
    accepted: z.boolean(),
    owner: z.boolean(),
    account: Account.optional(),
    aclPermissions: z.array(Permission).optional(),
    user: z
        .object({
            name: z.string().nullable(),
            email: z.string().nullable(),
        })
        .optional(),
    created_at: date.optional().nullable(),
    expires_at: date.optional().nullable(),
    mfaRequired: z.boolean().optional(),
})

export type Agreement = z.infer<typeof Agreement>
