import { Box, SelectChangeEvent, Tooltip } from "@mui/material"
import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import Page from "src/components/layout/Page"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useUser } from "src/contexts/UserConsumer"

import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import IdCell from "src/components/table/IdCell"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { QueryingOptions } from "@repo/rezip-client"
import { useClient } from "src/hooks/useClient.hook"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { OrderWithShopName, Shop } from "@repo/rezip-client/partner_client"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import InventoryIcon from "@mui/icons-material/Inventory"
import { StyledMenuItem } from "src/components/styles"
import { getPercentageOfOrder, getProgressOfOrder } from "src/utils/orderCalcualations"
import { useReactToPrint } from "react-to-print"
import dayjs from "dayjs"
import Invoice from "src/components/Invoice"
import PrintIcon from "@mui/icons-material/Print"
import StyledForm from "src/components/layout/StyledForm"
import { OrderStateChange } from "src/components/generalComponents/OrderStateChanger"

const usePartnerOrders = () => {
    const { getSelectedAgreement, getToken } = useUser()
    const { partnerClient } = useClient()
    const agreement = getSelectedAgreement()
    const [shops, setShops] = useState<Shop[]>()
    const partnerId = getSelectedAgreement()?.account.id

    useEffect(() => {
        if (!shops && partnerId) {
            const getShopName = async () => {
                const data = await partnerClient.getShops(partnerId, { pageSize: 9999 })
                if (data) {
                    setShops(data)
                } else {
                    setShops([])
                }
            }
            getShopName()
        }
    }, [agreement, getToken, partnerClient, partnerId, shops])

    return { shops }
}

const Orders = () => {
    const intl = useIntl()
    const { shops } = usePartnerOrders()
    const navigation = useNavigate()
    const { getSelectedAgreement } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const { partnerClient } = useClient()
    const [initialLoad, setInitialLoad] = useState(true)
    const [invoice, setInvoice] = useState<ReactNode | null>(null)
    const [shouldPrint, setShouldPrint] = useState(false)

    const menuItems = [
        {
            id: "All",
            disabled: false,
            value: intl.formatMessage({
                id: "orders_dropdown_all",
            }),
        },
        {
            id: "0",
            disabled: false,
            value: intl.formatMessage({
                id: "orders_dropdown_open",
            }),
        },

        {
            id: "1",
            disabled: false,
            value: intl.formatMessage({
                id: "orders_dropdown_fulfilled",
            }),
        },
        {
            id: "2",
            disabled: false,
            value: intl.formatMessage({
                id: "orders_dropdown_invoiced",
            }),
        },
        {
            id: "3",
            disabled: false,
            value: intl.formatMessage({
                id: "orders_dropdown_paid",
            }),
        },
    ]
    const [selectedSorting, setSelectedSorting] = useState<IMenuItemData>(menuItems[0])

    const fetchOrders = useCallback(
        async ({ pageSize, pageFrom, sortDir, filters }: QueryingOptions) => {
            if (!partnerId || !shops) {
                return
            }
            if (selectedSorting.id !== "All") {
                const state: Record<string, string> = {
                    state: selectedSorting.id,
                }
                filters = { ...filters, ...state }
            }
            const options = {
                filters: { ...filters },
                pageFrom: pageFrom,
                sortBy: "created_at",
                sortDir: sortDir,
                pageSize: pageSize,
            }
            const resp = await partnerClient.getOrders(partnerId, options)

            if (resp) {
                const allOrders = resp.map((item) => {
                    const found = shops.find((shop) => item.shop_id === shop.id)
                    if (found) {
                        return { ...item, shopName: found.name }
                    } else {
                        return { ...item, shopName: "" } as OrderWithShopName
                    }
                })

                return allOrders
            } else {
                return null
            }
        },
        [partnerClient, partnerId, selectedSorting.id, shops],
    )

    const onSortingChanged = (e: SelectChangeEvent<unknown>) => {
        const sort = menuItems.find((mi) => mi.id === e.target.value) ?? menuItems[0]
        setSelectedSorting(sort)
        setInitialLoad(true)
    }

    const contentToPrint = useRef(null)
    const handlePrint = useReactToPrint({
        documentTitle: intl.formatMessage(
            { id: "common_order_note" },
            { date: dayjs().format("YYYY-MM-DD") },
        ),
        contentRef: contentToPrint,
    })
    useEffect(() => {
        if (invoice && shouldPrint) {
            handlePrint()
            setShouldPrint(true)
        }
    }, [handlePrint, invoice, shouldPrint])

    const orderToRow = (order: OrderWithShopName, index: number) => {
        if (!partnerId) {
            return <></>
        }
        const printOrderButton =
            order.state >= 1 ? (
                <StyledMenuItem
                    onClick={async () => {
                        const shop = await partnerClient.getShop({
                            partnerId: partnerId,
                            shopId: order.shop_id,
                        })
                        const partner = await partnerClient.getPartner(partnerId)
                        if (shop && partner) {
                            setInvoice(<Invoice partner={partner} shop={shop} order={order} />)
                            setShouldPrint(true)
                        }
                    }}>
                    <PrintIcon width={25} height={25} />
                    {intl.formatMessage({
                        id: "common_print_order",
                    })}
                </StyledMenuItem>
            ) : null

        return (
            <StyledBodyRow key={index}>
                <StyledTableCell key={`${index}-shop_name`}>{order.shopName ?? ""}</StyledTableCell>
                <StyledTableCell key={`${index}-created_at`}>
                    {dayjs(order.created_at).format("DD/MM/YYYY HH:mm")}
                </StyledTableCell>
                <IdCell id={order.id} key={`${index}-shop_id`} />
                <StyledTableCell key={`${index}-shop_numbers`}>
                    {getProgressOfOrder(order)}
                </StyledTableCell>
                <StyledTableCell key={`${index}-shop-percent`}>
                    {getPercentageOfOrder(order)}
                </StyledTableCell>
                <StyledTableCell key={"status"}>
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({ id: "change_order_status_tooltip" })}>
                        <span>
                            <OrderStateChange order={order} setInitialLoad={setInitialLoad} />
                        </span>
                    </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                    <ThreeDotMenu
                        menuItems={[
                            <StyledMenuItem
                                key={`${index}-view`}
                                onClick={() => navigation(`/administration/orders/${order.id}`)}>
                                <RemoveRedEyeIcon width={25} height={25} />
                                {intl.formatMessage({
                                    id: "orders_view_button",
                                })}
                            </StyledMenuItem>,
                            printOrderButton,
                            <StyledMenuItem
                                key={`${index}-fulfill`}
                                onClick={() => navigation(`/warehouse/fulfill/${order.id}`)}>
                                <InventoryIcon width={25} height={25} />
                                {intl.formatMessage({
                                    id: "orders_fulfill_button",
                                })}
                            </StyledMenuItem>,
                        ]}
                    />
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_orders",
            })}>
            <DefaultWrapperPaper>
                <AllInOneTable<OrderWithShopName>
                    headers={[
                        {
                            label: intl.formatMessage({
                                id: "orders_table_shop",
                            }),
                            key: "Shop_name",
                            excludeFromSearch: true,
                        },
                        {
                            label: intl.formatMessage({
                                id: "orders_table_order_date",
                            }),
                            key: "order_date",
                            excludeFromSearch: true,
                        },
                        {
                            label: intl.formatMessage({
                                id: "orders_table_order_number",
                            }),
                            key: "order_number",
                            excludeFromSearch: true,
                        },
                        {
                            key: "progress",
                            label: intl.formatMessage({
                                id: "common_progress",
                            }),
                            excludeFromSearch: true,
                        },
                        {
                            key: "percentage",
                            label: intl.formatMessage({
                                id: "common_percentage",
                            }),
                            excludeFromSearch: true,
                        },
                        {
                            label: intl.formatMessage({
                                id: "orders_table_status",
                            }),
                            key: "state",
                            excludeFromSearch: true,
                        },
                        { label: "", key: "actions", excludeFromSearch: true },
                    ]}
                    itemsToRow={orderToRow}
                    defaultSortBy={"created_at"}
                    fetchItems={fetchOrders}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    orderDirection="desc"
                    additionalSortColumns={
                        <StyledForm>
                            <SelectField
                                value={selectedSorting.id}
                                onChange={onSortingChanged}
                                menuItems={menuItems}
                                label={intl.formatMessage({
                                    id: "common_status",
                                })}
                            />
                        </StyledForm>
                    }
                />
                <Box sx={{ display: "none" }}>
                    <Box ref={contentToPrint}>{invoice}</Box>
                </Box>
            </DefaultWrapperPaper>
        </Page>
    )
}

export default Orders
