import { Box } from "@mui/material"
import { useIntl } from "react-intl"
import { StyledP, StyledStrong } from "src/components/styles"
import CollapseInformation from "../generalComponents/CollapseInformation"

interface ShopTopBoxProps {
    editPage: boolean
}

const AddShopText = () => {
    const intl = useIntl()
    return (
        <>
            <Box>
                <StyledP>
                    <StyledStrong>
                        {intl.formatMessage({
                            id: "add_user",
                        })}
                    </StyledStrong>
                    <br />
                    {intl.formatMessage({
                        id: "add_user_explanation",
                    })}
                </StyledP>
                <StyledP>
                    {intl.formatMessage({
                        id: "add_user_explanation_2",
                    })}
                </StyledP>
            </Box>
            <Box>
                <StyledP>
                    <StyledStrong>
                        {intl.formatMessage({
                            id: "mandatory_fields",
                        })}
                    </StyledStrong>
                    <br />
                    {intl.formatMessage({
                        id: "mandatory_fields_explanation",
                    })}
                </StyledP>
            </Box>
            <Box>
                <StyledP>
                    <StyledStrong>
                        {intl.formatMessage({
                            id: "external_id",
                        })}
                    </StyledStrong>
                    <br />
                    {intl.formatMessage({
                        id: "external_id_explanation",
                    })}
                </StyledP>
            </Box>
            <Box>
                <StyledP>
                    <StyledStrong>
                        {intl.formatMessage({
                            id: "other",
                        })}
                    </StyledStrong>
                    <br />
                    {intl.formatMessage({
                        id: "other_explanation",
                    })}
                </StyledP>
            </Box>
        </>
    )
}

const ShopDetailsInformationBox = ({ editPage }: ShopTopBoxProps) => {
    const intl = useIntl()

    const Header = intl.formatMessage({ id: "add_new_shop_account" })

    return (
        <>
            {!editPage ? (
                <CollapseInformation header={Header}>
                    <AddShopText />
                </CollapseInformation>
            ) : null}
        </>
    )
}

export default ShopDetailsInformationBox
