import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SelectChangeEvent } from "@mui/material/Select"
import { useUser } from "src/contexts/UserConsumer"
import Page from "src/components/layout/Page"

import {
    IRow,
    StyledBodyRow,
    StyledTableCell,
    TableHeader,
} from "src/components/table/TableComponents"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import Grid from "@mui/material/Grid"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { Location, Packaging } from "@repo/rezip-client/partner_client"
import { useClient } from "src/hooks/useClient.hook"
import { ClientError } from "@repo/rezip-client/client_error"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import BarcodeScanInput from "src/components/BarcodeScanInputField"
import { Typography } from "@mui/material"
import { CustomHelp } from "src/components/generalComponents/CustomHelp"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"

export default function WasteManagement() {
    const intl = useIntl()
    const { getSelectedAgreement, getSelectedLocationId } = useUser()
    const { partnerClient } = useClient()
    const partnerId = getSelectedAgreement()?.account.id
    const [locations, setLocations] = useState<Array<Location>>()
    const [selectedLocation, setSelectedLocation] = useState<Location>()
    const [selectedPackagingCondition, setSelectedPackagingCondition] = useState<IMenuItemData>()
    const [scanBarcode, setScanBarcode] = useState("")
    const [discardedPackaging, setDiscardedPackaging] = useState<Array<Packaging>>(
        new Array<Packaging>(),
    )
    const { enqueueAlert } = useSnackbar()
    const [initialLoad, setInitialLoad] = useState(true)
    const globalLocation = getSelectedLocationId()

    const packagingConditionChooserValue = useMemo(() => {
        return [
            {
                id: "0",
                value: intl.formatMessage({ id: "common_working" }),
            },
            {
                id: "1",
                value: intl.formatMessage({ id: "common_recycled" }),
            },
            {
                id: "2",
                value: intl.formatMessage({ id: "common_landfilled" }),
            },
            {
                id: "3",
                value: intl.formatMessage({ id: "common_incinerated" }),
            },
        ]
    }, [intl])

    const onChangePackagingCondition = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const condition = packagingConditionChooserValue?.find(
                (s) => s.id === (event.target as HTMLTextAreaElement).value,
            )
            setSelectedPackagingCondition(condition)
        },
        [packagingConditionChooserValue],
    )

    const onSubmitChangingPackagingCondition = async () => {
        if (selectedLocation != undefined && scanBarcode != "") {
            if (!partnerId) {
                return
            }
            try {
                const result = await partnerClient.discardPackaging({
                    partnerId,
                    barcode: scanBarcode,
                    locationId: selectedLocation.id,
                    condition: selectedPackagingCondition ? selectedPackagingCondition.id : "1",
                })
                if (result) {
                    setDiscardedPackaging((discardedPackaging) => [result, ...discardedPackaging])
                    setInitialLoad(true)
                }
            } catch (error) {
                const mappedError = errorConverter(error)

                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                return displayErrorMessage(mappedError, enqueueAlert)
            }
        }
    }

    useEffect(() => {
        if (!locations) {
            const fetchLocations = async () => {
                if (!partnerId) {
                    return
                }
                const locations = await partnerClient.getLocations(partnerId)
                setLocations(locations)

                if (locations.length > 0 && globalLocation) {
                    const item = locations.find((item) => {
                        if (globalLocation === item.id) return item
                    })

                    if (item) {
                        setSelectedLocation(item)
                    }
                }
            }
            fetchLocations()
        }
    }, [getSelectedAgreement, partnerClient, globalLocation, locations, partnerId])

    const discardedPackagingHeader: IRow = {
        id: "",
        cells: [
            {
                field: "barcode",
                value: intl.formatMessage({ id: "stockkeeping_barcode" }),
            },
            {
                field: "condition",
                value: intl.formatMessage({ id: "common_status" }),
            },
        ],
    }

    const discardedPackagingRows = new Array<IRow>()
    discardedPackaging.forEach((discard) => {
        const tempArray: IRow = {
            id: discard.id,
            cells: [
                {
                    field: discardedPackagingHeader.cells[0].field,
                    value: discard.barcode,
                },
                {
                    field: discardedPackagingHeader.cells[1].field,
                    value: discard.condition,
                },
            ],
        }

        if (tempArray.cells.length == discardedPackagingHeader.cells.length) {
            discardedPackagingRows.push(tempArray)
        }
    })

    const getCondition = useCallback(
        (condition: number) => {
            switch (condition) {
                case 0:
                    return intl.formatMessage({ id: "common_working" })
                case 1:
                    return intl.formatMessage({ id: "common_recycled" })
                case 2:
                    return intl.formatMessage({ id: "common_landfilled" })
                case 3:
                    return intl.formatMessage({ id: "common_incinerated" })
            }
        },
        [intl],
    )

    const itemToRow = useCallback(
        (item: Packaging, index: number) => {
            return (
                <StyledBodyRow key={`item-${index}`}>
                    <StyledTableCell>{item.barcode}</StyledTableCell>
                    <StyledTableCell>{getCondition(item.condition)}</StyledTableCell>
                </StyledBodyRow>
            )
        },
        [getCondition],
    )

    const headers: TableHeader[] = [
        { key: "barcode", label: intl.formatMessage({ id: "stockkeeping_barcode" }) },
        { key: "condition", label: intl.formatMessage({ id: "waste_management_condition" }) },
    ]

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_waste_management",
            })}>
            <Grid container spacing={2}>
                <Grid size={12}>
                    <DefaultWrapperPaper sx={{ maxWidth: "600px" }}>
                        <Grid container spacing={1}>
                            <Typography variant="h4">
                                {intl.formatMessage({ id: "waste_management_subtitle" })}
                            </Typography>
                            <CustomHelp
                                title={intl.formatMessage({ id: "waste_management_tooltip" })}
                            />
                        </Grid>
                        <Grid container direction="column" spacing={2}>
                            <Grid container spacing={2}>
                                <SelectField
                                    id="wasteManagementConditionSelect"
                                    name="wasteManagementConditionSelect"
                                    label={intl.formatMessage({ id: "waste_management_condition" })}
                                    value={selectedPackagingCondition?.id ?? 1}
                                    onChange={onChangePackagingCondition}
                                    menuItems={
                                        packagingConditionChooserValue
                                            ? packagingConditionChooserValue.map((p) => {
                                                  return { id: p.id, value: p.value }
                                              })
                                            : []
                                    }
                                />
                                <CustomHelp
                                    title={intl.formatMessage({
                                        id: "waste_management_condition_tooltip",
                                    })}
                                />
                            </Grid>
                            <Grid container>
                                <BarcodeScanInput
                                    label={intl.formatMessage({ id: "scan_rezip_tag" })}
                                    value={scanBarcode}
                                    onChange={setScanBarcode}
                                    onScan={onSubmitChangingPackagingCondition}
                                    maxWidth
                                />
                            </Grid>
                        </Grid>
                    </DefaultWrapperPaper>
                </Grid>

                {discardedPackaging.length == 0 ? (
                    <></>
                ) : (
                    <Grid size={12}>
                        <DefaultWrapperPaper>
                            <AllInOneTable
                                headers={headers}
                                items={discardedPackaging}
                                itemsToRow={itemToRow}
                                defaultSortBy={"barcode"}
                                initialLoad={initialLoad}
                                setInitialLoad={setInitialLoad}
                            />
                        </DefaultWrapperPaper>
                    </Grid>
                )}
            </Grid>
        </Page>
    )
}
