import { Button, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ArrowLeft } from "@mui/icons-material"
import { useIntl } from "react-intl"

interface Props {
    title?: string
    children: React.ReactNode
    backButton?: boolean
    rightSideItem?: React.ReactNode
    additionalBackAction?: () => void
}

export default function Page({
    title,
    children,
    backButton,
    rightSideItem,
    additionalBackAction,
}: Props) {
    const intl = useIntl()
    const navigate = useNavigate()
    const back = () => {
        if (additionalBackAction) {
            additionalBackAction()
            navigate(-1)
        } else {
            navigate(-1)
        }
    }

    return (
        <Grid container direction="column">
            {backButton ? (
                <Grid
                    container
                    justifyContent="center"
                    alignSelf="flex-start"
                    sx={{ marginBottom: "12px" }}>
                    <Button
                        onClick={() => back()}
                        sx={{ paddingLeft: "0px", color: "text.primary" }}>
                        <ArrowLeft />
                        {intl.formatMessage({ id: "common_back" })}
                    </Button>
                </Grid>
            ) : null}

            {title ? (
                <Grid container justifyContent="space-between">
                    <Typography variant="h1">{title}</Typography>
                    <Grid>{rightSideItem}</Grid>
                </Grid>
            ) : null}

            {children}
        </Grid>
    )
}
