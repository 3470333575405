import { ReactNode, useState } from "react"
import { useIntl } from "react-intl"
import useCountryList from "src/utils/countries"
import { MultiSelectPlaceholder } from "./MultiSelectPlaceholder"

export interface MultiCountrySelectorProps {
    onChange: (countries: string[] | null) => void
    defaultValue?: string[] | null
}

export const MultiCountrySelector = ({ onChange, defaultValue }: MultiCountrySelectorProps) => {
    const intl = useIntl()
    const [countries, setCountries] = useState<string[]>(defaultValue ? defaultValue : [])
    const allCountries = Object.fromEntries(
        useCountryList()
            .filter((x) => x.id != "default")
            .map((x) => [x.id, x.value]),
    ) as Record<string, ReactNode>

    return (
        <MultiSelectPlaceholder
            label={intl.formatMessage({ id: "operating_countries" })}
            helpText={intl.formatMessage({ id: "operating_countries_help" })}
            selected={countries}
            setSelected={(x) => {
                setCountries(x)
                onChange(x.length === 0 ? null : x)
            }}
            placeholder={<em>{intl.formatMessage({ id: "common_worldwide" })}</em>}
            renderMap={allCountries}
        />
    )
}
