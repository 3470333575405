import { useUser } from "src/contexts/UserConsumer"
import AddDropPointUser from "./dropPoint/users/addUser"
import AddShopUser from "./shop/users/addUser"
import AddPartnerUser from "./account/users/addUser"

export const AddUser = () => {
    const { getSelectedAccountType } = useUser()
    const accountType = getSelectedAccountType()

    if (accountType === "Partner") {
        return <AddPartnerUser />
    } else if (accountType === "DropPoint") {
        return <AddDropPointUser />
    } else if (accountType === "Shop") {
        return <AddShopUser />
    }

    return <></>
}
