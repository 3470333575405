import { useCallback, useEffect, useState } from "react"
import { useJsLoader } from "src/contexts/JsLoaderConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { getBounds } from "geolib"
import { useClient } from "src/hooks/useClient.hook"
import { PartnerClient } from "@repo/rezip-client/partner_client"
import { ShopClient } from "@repo/rezip-client/shop_client"

interface MapLocationStateInner {
    lat: number
    lng: number
    zoom: number
    radius: number
}
export const useLocation = () => {
    const [coords, setCoords] = useState<MapLocationStateInner | undefined>(undefined)
    const [center, setCenter] = useState<google.maps.LatLng | undefined>(undefined)
    const { isLoaded } = useJsLoader()
    const checkPermission = async () => {
        const permissionStatus = await navigator.permissions.query({
            name: "geolocation",
        })
        const hasPermission = permissionStatus.state
        return hasPermission
    }

    useEffect(() => {
        const getPermission = async () => {
            const permission = await checkPermission()
            if (isLoaded) {
                if (permission === "granted") {
                    // Get location immediately
                    navigator.geolocation.getCurrentPosition((position) => {
                        setCoords({
                            zoom: 7,
                            lat: position.coords.latitude ?? 0,
                            lng: position.coords.longitude ?? 0,
                            radius: 50,
                        })
                        setCenter(
                            new google.maps.LatLng(
                                position.coords.latitude ?? 0,
                                position.coords.longitude ?? 0,
                            ),
                        )
                    })
                } else if (permission === "prompt") {
                    // Request location and wait for user to respond
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setCoords({
                                zoom: 7,
                                lat: position.coords.latitude ?? 0,
                                lng: position.coords.longitude ?? 0,
                                radius: 50,
                            })
                            setCenter(
                                new google.maps.LatLng(
                                    position.coords.latitude ?? 0,
                                    position.coords.longitude ?? 0,
                                ),
                            )
                        },
                        () => {
                            // User denied the prompt, set default coords and center
                            setCoords({
                                zoom: 1,
                                lat: 0,
                                lng: 0,
                                radius: 50,
                            })
                            setCenter(new google.maps.LatLng(0, 0))
                        },
                    )
                } else {
                    // Permission denied
                    setCoords({
                        zoom: 1,
                        lat: 0,
                        lng: 0,
                        radius: 50,
                    })
                    setCenter(new google.maps.LatLng(0, 0))
                }
            }
        }
        // Only call getPermission if coords or center are not set
        if (!coords || !center) {
            getPermission()
        }
    }, [coords, center, isLoaded])

    return { coords, setCoords, center, setCenter }
}
interface HeatMapProps {
    startDate?: Date
    endDate?: Date
    shopId?: string
    setUpdateData: (bool: boolean) => void
    updateData: boolean
}

interface HeatMapLocation {
    position: { lat: number; lng: number }
    amount: number
}

export const useHeatMapController = ({
    endDate,
    startDate,
    shopId,
    updateData,
    setUpdateData,
}: HeatMapProps) => {
    const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null)
    const { isLoaded } = useJsLoader()
    const { coords, center } = useLocation()
    const [bounds, setBounds] = useState<google.maps.LatLngBounds>()
    const { getSelectedAgreement } = useUser()
    const [inititalLoad, setInitialLoad] = useState(true)

    const { selectedClient } = useClient()

    const accountId = getSelectedAgreement()?.account.id
    if (!(selectedClient instanceof PartnerClient || selectedClient instanceof ShopClient)) {
        throw "unreachable"
    }
    if (!accountId) {
        throw "unreachable"
    }
    const reportingClient = selectedClient.reporting(accountId)

    const [heatMapLocations, setHeatmapLocations] = useState<HeatMapLocation[]>()

    const fetchLocations = useCallback(
        async (input: google.maps.LatLngBounds) => {
            if (!startDate || !endDate) {
                return
            }

            const ne = input.getNorthEast()
            const sw = input.getSouthWest()

            if (!sw || !ne) {
                return
            }

            const bound = { up: ne.lng(), down: sw.lng(), right: ne.lat(), left: sw.lat() }
            const precision = 100

            try {
                const locations = await reportingClient.activityMap({
                    from: startDate,
                    to: new Date(new Date(endDate.setHours(23)).setMinutes(59)),
                    locationBounds: bound,
                    precision,
                    shopId: shopId === "All" ? undefined : shopId,
                })

                const xWidth = (bound.right - bound.left) / precision
                const yWidth = (bound.up - bound.down) / precision

                const mappedLocations = locations.map((item) => {
                    return {
                        position: {
                            lat: xWidth * item.position.x + bound.left + xWidth / 2,
                            lng: yWidth * item.position.y + bound.down + yWidth / 2,
                        },
                        amount: item.amount,
                    } as HeatMapLocation
                })

                setHeatmapLocations(() => [...mappedLocations])
            } catch (error) {
                console.error("Failed to fetch locations:", error)
            }
        },
        [endDate, shopId, startDate, reportingClient],
    )

    const handleBounds = useCallback(
        (input?: google.maps.LatLngBounds) => {
            if (input && bounds && googleMap) {
                const inputne = input.getNorthEast()
                const inputsw = input.getSouthWest()
                const inputBounds = getBounds([
                    { lat: inputne.lat(), lon: inputne.lng() },
                    { lat: inputsw.lat(), lon: inputsw.lng() },
                ])

                const boundsNe = bounds.getNorthEast()
                const boundsSw = bounds.getSouthWest()
                const currentBounds = getBounds([
                    { lat: boundsNe.lat(), lon: boundsNe.lng() },
                    { lat: boundsSw.lat(), lon: boundsSw.lng() },
                ])
                if (
                    currentBounds.maxLat !== inputBounds.maxLat &&
                    inputBounds.maxLng !== currentBounds.maxLng
                ) {
                    setBounds(input)
                    fetchLocations(input)
                }
            }
            if (!bounds && input && googleMap) {
                setBounds(input)
            }
        },
        [bounds, fetchLocations, googleMap],
    )

    useEffect(() => {
        if (bounds && startDate && endDate && inititalLoad) {
            setInitialLoad(false)
            fetchLocations(bounds)
        }
    }, [bounds, endDate, fetchLocations, googleMap, inititalLoad, shopId, startDate])

    useEffect(() => {
        if (updateData && bounds && startDate && endDate) {
            fetchLocations(bounds)
            setUpdateData(false)
        }
    }, [
        bounds,
        updateData,
        endDate,
        fetchLocations,
        googleMap,
        inititalLoad,
        setUpdateData,
        shopId,
        startDate,
    ])

    return {
        heatMapLocations,
        googleMap,
        isLoaded,
        coords,
        center,
        handleBounds,
        setGoogleMap,
    }
}
