import { SelectChangeEvent } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { IMenuItemData } from "src/components/SelectField"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useStorageCreation } from "src/contexts/StorageCreationConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { ClientError } from "@repo/rezip-client/client_error"
import { StationType, StorageUnitTemplates, StorageUnit } from "@repo/rezip-client/partner_client"
import { Product } from "@repo/rezip-client/types"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { errorConverter, displayErrorMessage, extractMessage } from "src/utils/errorConverter"
import { RequestError } from "@repo/rezip-client/request_error"

export type suCreation = {
    barcode?: string
    storage_unit_template_id: string
    station_id?: string
    sku: string
    location?: string
    type?: string
    size?: number
    quantity?: number
    anonymous?: boolean
}

const useStorageUnitPageController = (
    unitId: string | undefined,
    stationId: string | undefined,
) => {
    // hooks
    const { getSelectedAgreement, getSelectedLocationId } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const locationId = getSelectedLocationId()
    const intl = useIntl()
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()
    const { partnerClient } = useClient()
    const { searchTerm, setSearchTerm } = useStorageCreation()

    const { BlockerAlert, setBlock } = useCustomBlocker()
    // states
    const [useIds, setUseIds] = useState<boolean>(true)
    const [products, setProducts] = useState<Product[]>([])
    const [templates, setTemplates] = useState<StorageUnitTemplates>([])
    const [stations, setStations] = useState<StationType[] | null>(null)
    const [storageUnit, setStorageUnit] = useState<StorageUnit | null>(null)
    const [description, setDescription] = useState<string | null>(storageUnit?.location ?? "")

    const updateStorageUnit = useCallback(async () => {
        if (!locationId || !partnerId || !unitId) {
            return
        }
        const response = await partnerClient.getStorageUnit(partnerId, locationId, unitId)
        if (response) {
            setStorageUnit(response)
        }
    }, [locationId, partnerClient, partnerId, unitId])

    const templatesAsMenuItems = useMemo(() => {
        return (
            templates.map((item) => ({
                id: item.id,
                value: `${item.type}`,
            })) || []
        )
    }, [templates])

    const [selectedType, setSelectedType] = useState<IMenuItemData | null>(null)

    const productsAsMenuItems = useMemo(() => {
        const type = templates.find((item) => item.id === selectedType?.id)

        if (type) {
            return (
                type.config.map((item) => {
                    const product = products.find((product) => item.sku === product.sku)
                    return {
                        id: item.sku,
                        value: `${product?.name ?? ""} (${item.sku})`,
                    }
                }) || []
            )
        }
        return []
    }, [products, selectedType?.id, templates])

    const [selectedSku, setSelectedSku] = useState<IMenuItemData | null>(null)

    const selectSku = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            if (!selectedType) return
            const item = templates.find((p) => p.id === selectedType.id)
            const menuItem = productsAsMenuItems.find((t) => t.id === e.target.value)

            if (item && storageUnit) {
                const config = item.config.find((config) => e.target.value === config.sku)
                if (config) {
                    setStorageUnit({ ...storageUnit, size: config.size })
                }
            }
            if (menuItem) {
                setSelectedSku(menuItem)
            }
        },
        [productsAsMenuItems, selectedType, storageUnit, templates],
    )

    const selectType = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            const item = templates.find((p) => p.id === e.target.value)
            const menuItem = templatesAsMenuItems.find((t) => t.id === e.target.value)
            if (item && storageUnit) {
                if (item.config.length === 1) {
                    setStorageUnit({ ...storageUnit, size: item.config[0].size })
                } else {
                    const config = item.config.find((config) => selectedSku?.id === config.sku)
                    if (config) {
                        setStorageUnit({ ...storageUnit, size: config.size })
                    }
                }
            }
            if (menuItem) {
                setSelectedType(menuItem)
                setBlock(true)
            }
        },
        [selectedSku?.id, setBlock, storageUnit, templates, templatesAsMenuItems],
    )
    const stationMenuItems = useMemo(() => {
        return (
            stations?.map((station) => ({
                id: station.id,
                value: station.name,
            })) || []
        )
    }, [stations])

    const [selectedStation, setSelectedStation] = useState<IMenuItemData | null>(null)

    const selectStation = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            const selectedStation = stationMenuItems.find(
                (station) => station.id === e.target.value,
            )
            setSelectedStation(selectedStation ?? null)
            setBlock(true)
        },
        [setBlock, stationMenuItems],
    )

    const fetchProducts = useCallback(async () => {
        if (products.length === 0 && partnerId) {
            const products = await partnerClient.getProducts(partnerId)

            if (products) {
                setProducts(products)
            } else {
                setProducts([])
            }
        }
    }, [partnerClient, partnerId, products.length])

    const fetchTemplates = useCallback(async () => {
        if (templates.length === 0 && partnerId) {
            const templates = await partnerClient.getStorageUnitTemplates(partnerId, {
                pageSize: 99,
            })
            if (templates) {
                setTemplates(templates)
            } else {
                setTemplates([])
            }
        }
    }, [partnerClient, partnerId, templates.length])

    const fetchStations = useCallback(async () => {
        if (locationId && !stations && partnerId) {
            const data = await partnerClient.getStations(partnerId, locationId)
            setStations(data)
        }
    }, [locationId, partnerClient, partnerId, stations])

    const createStorageUnit = useCallback(
        async (create: boolean) => {
            if (!partnerId || !locationId) {
                return
            }

            if (!selectedType || !selectedSku) {
                enqueueAlert(intl.formatMessage({ id: "invalid_type_or_sku" }), "error")
                return
            }

            const payload: suCreation = {
                storage_unit_template_id: selectedType.id,
                station_id: selectedStation ? selectedStation.id : undefined,
                sku: selectedSku.id,
                anonymous: !useIds,
                ...(description ? { location: description } : {}),
            }

            if (!create && storageUnit) {
                const template = templates.find((item) => item.id === selectedType.id)

                if (template) {
                    payload.type = template.type

                    const config = template.config.find((config) => config.sku === selectedSku.id)
                    if (config && storageUnit.quantity === 0) {
                        payload.size = config.size
                    }
                }

                try {
                    const resp = await partnerClient.patchStorageUnit(
                        partnerId,
                        locationId,
                        storageUnit.id,
                        payload,
                    )
                    if (resp) {
                        setBlock(false)
                        updateStorageUnit()
                        enqueueAlert(
                            intl.formatMessage({ id: "toast_message_storageunit_updated" }),
                            "success",
                        )
                    }
                } catch (error) {
                    const mappedError = errorConverter(error)
                    if (!(mappedError instanceof ClientError)) {
                        throw error
                    }
                    return displayErrorMessage(mappedError, enqueueAlert)
                }
            } else {
                try {
                    payload.quantity = 0
                    payload.barcode = searchTerm
                    if (stationId) {
                        payload.station_id = stationId
                    }
                    const resp = await partnerClient.postStorageUnit(partnerId, locationId, payload)
                    if (resp) {
                        setBlock(false)
                        setSearchTerm("")
                        navigate(-1)
                        return enqueueAlert(
                            intl.formatMessage({ id: "toast_message_storageunit_created" }),
                            "success",
                        )
                    }
                } catch (error) {
                    const mappedError = errorConverter(error)
                    if (!(mappedError instanceof ClientError)) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const requestError = new RequestError({ type: (error as any).name })
                        if (requestError) {
                            return enqueueAlert(
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                intl.formatMessage({ id: `${(error as any).name}` }),
                                "error",
                            )
                        }
                        throw error
                    }
                    return displayErrorMessage(mappedError, enqueueAlert)
                }
            }
        },
        [
            description,
            enqueueAlert,
            intl,
            locationId,
            navigate,
            partnerClient,
            partnerId,
            searchTerm,
            selectedSku,
            selectedStation,
            selectedType,
            setBlock,
            setSearchTerm,
            stationId,
            storageUnit,
            templates,
            updateStorageUnit,
            useIds,
        ],
    )

    const addPackaging = useCallback(
        async (barcode: string) => {
            if (!partnerId || !locationId || !storageUnit) {
                return
            }

            try {
                const data = await partnerClient.addBundleToStorageUnit(
                    partnerId,
                    locationId,
                    storageUnit.id,
                    barcode,
                )

                if (data) {
                    updateStorageUnit()
                    return enqueueAlert(
                        intl.formatMessage({ id: "storage_unit_bundle_added" }),
                        "success",
                    )
                }
            } catch (error) {
                const mappedError = errorConverter(error)
                if (!(mappedError instanceof ClientError)) {
                    throw error
                }

                if (mappedError.status === 404) {
                    return enqueueAlert(
                        intl.formatMessage({ id: "storage_unit_item_not_found" }),
                        "error",
                    )
                }
                if (mappedError.status === 406) {
                    return enqueueAlert(
                        intl.formatMessage({ id: "toast_message_bundle_non_completed" }),
                        "error",
                    )
                }
                displayErrorMessage(mappedError, enqueueAlert)
            }
        },

        [enqueueAlert, intl, locationId, partnerClient, partnerId, storageUnit, updateStorageUnit],
    )
    const removePackaging = useCallback(
        async (barcode: string) => {
            if (!partnerId || !locationId || !storageUnit) {
                return
            }
            try {
                const response = await partnerClient.removeBundleFromStorageUnit(
                    partnerId,
                    locationId,
                    storageUnit.id,
                    barcode,
                )
                if (response) {
                    await updateStorageUnit()
                    enqueueAlert(
                        intl.formatMessage({ id: "storage_unit_bundle_removed" }),
                        "success",
                    )
                }
            } catch (error) {
                const mappedError = errorConverter(error)
                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                if (mappedError.status === 404) {
                    return enqueueAlert(
                        intl.formatMessage({ id: "storage_unit_item_not_found" }),
                        "error",
                    )
                }
                const message = extractMessage(mappedError)

                const errorTest = message.includes("not present")
                if (errorTest) {
                    return enqueueAlert(
                        intl.formatMessage({
                            id: "storage_unit_barcode_not_present",
                        }),
                        "error",
                    )
                }

                return displayErrorMessage(mappedError, enqueueAlert)
            }
        },
        [enqueueAlert, intl, locationId, partnerClient, partnerId, storageUnit, updateStorageUnit],
    )

    const handleQuantity = useCallback(
        async (quantity: number, reduce: boolean) => {
            if (!storageUnit || !partnerId || !locationId) return

            const { size, quantity: currentQuantity } = storageUnit

            // Validation for exceeding size or going below zero
            if (quantity > size || (!reduce && currentQuantity + quantity > size)) {
                return enqueueAlert(
                    intl.formatMessage({ id: "toast_quantity_error_size" }),
                    "warning",
                )
            }

            if (reduce && currentQuantity - quantity < 0) {
                return enqueueAlert(
                    intl.formatMessage({ id: "toast_quantity_less_than_zero" }),
                    "warning",
                )
            }

            const updatedStorageUnit = {
                ...storageUnit,
                anonymous: true,
                quantity: reduce ? currentQuantity - quantity : currentQuantity + quantity,
            }

            try {
                const data = await partnerClient.patchStorageUnit(
                    partnerId,
                    locationId,
                    storageUnit.id,
                    updatedStorageUnit,
                )

                if (data) {
                    setStorageUnit(data)
                    return enqueueAlert(
                        reduce
                            ? intl.formatMessage({ id: "toast_remove_quantity" })
                            : intl.formatMessage({ id: "toast_add_quantity" }),
                        "success",
                    )
                }
            } catch (error) {
                const mappedError = errorConverter(error)

                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                if (mappedError.status === 404) {
                    return enqueueAlert(
                        intl.formatMessage({ id: "storage_unit_item_not_found" }),
                        "error",
                    )
                }
                displayErrorMessage(mappedError, enqueueAlert)
            }
        },
        [enqueueAlert, intl, locationId, partnerClient, partnerId, storageUnit],
    )

    const deleteStorageUnit = useCallback(async () => {
        if (!storageUnit || !partnerId || !locationId) {
            return
        }

        try {
            // First empty SU
            const removeBulkResponse = await partnerClient.removeBulkFromStorageUnit(
                partnerId,
                locationId,
                storageUnit.id,
                "all",
            )

            if (removeBulkResponse) {
                // Then delete SU
                const deleteSUResponse = await partnerClient.deleteSotrageUnit(
                    partnerId,
                    locationId,
                    storageUnit.id,
                )

                if (deleteSUResponse) {
                    navigate(-1)
                    return enqueueAlert(
                        intl.formatMessage({ id: "storage_unit_deleted" }),
                        "success",
                    )
                }
            }
        } catch (error) {
            const mappedError = errorConverter(error)

            if (!(mappedError instanceof ClientError)) {
                throw error
            }

            if (mappedError.status === 404) {
                return enqueueAlert(
                    intl.formatMessage({ id: "storage_unit_item_not_found" }),
                    "error",
                )
            }
            setBlock(false)
            displayErrorMessage(mappedError, enqueueAlert)
        }
    }, [enqueueAlert, intl, locationId, navigate, partnerClient, partnerId, setBlock, storageUnit])

    useEffect(() => {
        if (locationId && unitId && !storageUnit && partnerId) {
            const fetchUnit = async () => {
                const unit = await partnerClient.getStorageUnit(partnerId, locationId, unitId)
                if (unit) {
                    setStorageUnit(unit)
                }
            }
            fetchUnit()
        }
    }, [locationId, partnerClient, partnerId, setSearchTerm, storageUnit, unitId])

    useEffect(() => {
        fetchProducts()
        fetchTemplates()
        fetchStations()
    }, [fetchProducts, fetchStations, fetchTemplates, getSelectedLocationId, storageUnit])

    useEffect(() => {
        if (storageUnit && unitId) {
            // Find corresponding items from menu lists
            const station = stationMenuItems.find((item) => item.id === storageUnit.station_id)
            const sku = productsAsMenuItems.find((item) =>
                item.value.includes(storageUnit.sku ?? ""),
            )
            const type = templatesAsMenuItems.find((item) =>
                item.value.includes(storageUnit.type ?? ""),
            )

            // Set selected values if found
            if (station) setSelectedStation(station)
            if (sku) setSelectedSku(sku)
            if (type) setSelectedType(type)

            setUseIds(!storageUnit.anonymous)
            setDescription(storageUnit.location ?? "")
        }
    }, [storageUnit, stationMenuItems, productsAsMenuItems, templatesAsMenuItems, unitId])

    useEffect(() => {
        if (templates.length === 1) {
            const autoSelectedType = templatesAsMenuItems[0]
            setSelectedType(autoSelectedType)
        }
        if (selectedType && productsAsMenuItems && productsAsMenuItems.length === 1) {
            setSelectedSku(productsAsMenuItems[0] || null)
        }
    }, [templatesAsMenuItems, productsAsMenuItems, templates.length, selectedType])

    return {
        createStorageUnit,
        selectedType,
        setSelectedType,
        selectedSku,
        selectedStation,
        setSelectedStation,
        description,
        setDescription,
        searchTerm,
        setSearchTerm,
        productsAsMenuItems,
        stationMenuItems,
        templatesAsMenuItems,
        storageUnit,
        setStorageUnit,
        addPackaging,
        removePackaging,
        useIds,
        setUseIds,
        selectType,
        handleQuantity,
        deleteStorageUnit,
        selectSku,
        selectStation,
        BlockerAlert,
        setBlock,
    }
}

export default useStorageUnitPageController
