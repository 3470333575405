import { InviteUserComponent } from "src/components/inviteUser/InviteUser"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"

const AddShopUser = () => {
    const { getSelectedAgreement } = useUser()

    const partnerId = getSelectedAgreement()?.account.id

    const { shopClient } = useClient()

    return <InviteUserComponent client={shopClient} id={partnerId} />
}

export default AddShopUser
