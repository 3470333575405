import { Skeleton, Typography, Grid } from "@mui/material"
import {
    DefaultizedPieValueType,
    HighlightScope,
    PieChart,
    pieArcLabelClasses,
} from "@mui/x-charts"
import { useIntl } from "react-intl"

interface PieChartProps {
    title: string
    height: number
    data: { id: string; value: number; label: string; color: string }[]
    graphsReady: boolean
}

export const CustomPieChart = ({ data, height, title, graphsReady }: PieChartProps) => {
    const intl = useIntl()
    let total = 0
    data.forEach((item) => (total += item.value ?? 0))

    const correctedData = data.map((item) => {
        return {
            id: item.id,
            value: item.value,
            color: item.color,
            label: item.label,
        } as DefaultizedPieValueType
    })

    if (!correctedData || !graphsReady) {
        return (
            <Skeleton
                animation={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    padding: "12px",
                }}
                variant="rectangular"
                width={"100%"}
                height={"100%"}>
                {intl.formatMessage({ id: "common_loading", defaultMessage: "Loading" })}
            </Skeleton>
        )
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            sx={{ padding: "12px", textAlign: "center" }}>
            <Typography variant="h4">{title}</Typography>
            <PieChart
                skipAnimation
                loading={!graphsReady}
                height={height}
                margin={{ top: 20, bottom: 40, left: 20, right: 20 }}
                slotProps={{
                    legend: {
                        itemGap: 20,
                        direction: "row",
                        position: { vertical: "bottom", horizontal: "middle" },
                        padding: -5,
                        labelStyle: { fontFamily: "Manrope", fontSize: "12px" },
                    },
                    itemContent: {
                        sx: { padding: "0px", alignSelf: "center" },
                    },
                }}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontWeight: "bold",
                    },
                }}
                series={[
                    {
                        highlighted: { additionalRadius: 10 },
                        data: correctedData,
                        valueFormatter: (item) => `${((item.value / total) * 100).toFixed(2)}%`,
                        type: "pie",
                        highlightScope: {
                            highlight: "item",
                            fade: "global",
                        } as HighlightScope,
                    },
                ]}
            />
        </Grid>
    )
}
