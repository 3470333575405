import { Box, Typography } from "@mui/material"
import { useIntl } from "react-intl"
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone_icon.svg"
import { ReactComponent as MailIcon } from "../../assets/icons/mail_icon.svg"
import { Shop } from "@repo/rezip-client/shop_client"
import { useEffect, useState } from "react"
import { useClient } from "src/hooks/useClient.hook"
import { useUser } from "src/contexts/UserConsumer"

export const ShopSupport = () => {
    const intl = useIntl()
    const { getSelectedAgreement } = useUser()
    const [shop, setShop] = useState<Shop | null>(null)
    const { shopClient } = useClient()
    const id = getSelectedAgreement()?.account.id

    useEffect(() => {
        if (!shop && id) {
            const fetch = async () => {
                const data = await shopClient.getShop(id)
                if (data) {
                    setShop(data)
                }
            }
            fetch()
        }
    }, [id, shop, shopClient])

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: "50px",
            }}>
            <Typography variant="h3">
                {intl.formatMessage(
                    { id: "shop_contact_partner" },
                    { partner: shop?.partner.name ?? "" },
                )}
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <PhoneIcon height={40} width={40} />
                <Typography>{shop?.partner.support_phone ?? ""}</Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                }}>
                <MailIcon height={40} width={40} />
                <Typography> {shop?.partner.support_email ?? ""}</Typography>
            </Box>
        </Box>
    )
}
