import { Typography, Grid } from "@mui/material"
import { useCallback, useMemo, useRef, useState } from "react"
import { parseBarcode } from "src/utils/barcodeHelpers"
import { useUser } from "src/contexts/UserConsumer"
import { DefaultWrapperPaper } from "./layout/DefaultPageComponents"
import { useIntl } from "react-intl"
import dayjs from "dayjs"

import { useNavigate } from "react-router-dom"
import { useClient } from "src/hooks/useClient.hook"
import { Bundle, BundleScan, Packaging, StorageUnit } from "@repo/rezip-client/partner_client"
import { AllInOneTable } from "./table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "./table/TableComponents"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import GenericModal from "./modals/GenericModal"
import BarcodeScanInput from "./BarcodeScanInputField"
import { ClientError } from "@repo/rezip-client/client_error"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"
import { ColorThemeName, CustomButton } from "./generalComponents/Buttons"

interface InfoBoxProps {
    title: string
    items: { label: string; value: string }[]
    action?: { action: () => void; text: string; color: ColorThemeName }
    hideAction?: boolean
}
const InfoBox = (props: InfoBoxProps) => {
    const { items, title, action, hideAction } = props
    return (
        <DefaultWrapperPaper sx={{ padding: "20px" }}>
            <Grid container direction="column" spacing={1}>
                <Typography variant="h4">{title}</Typography>
                {items.map(({ label, value }) => (
                    <Grid container spacing={1} justifyContent="space-between" key={label}>
                        <Typography variant="body1">{label}</Typography>
                        <Typography variant="body2">{value}</Typography>
                    </Grid>
                ))}
                {action && !hideAction ? (
                    <Grid container justifyContent="flex-end" sx={{ marginTop: "12px" }}>
                        <CustomButton
                            text={action.text}
                            colorTheme={action.color}
                            onClick={action.action}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </DefaultWrapperPaper>
    )
}

const PackageLookup = () => {
    const [barcode, setBarcode] = useState("")
    const [fetchedPackage, setFetchedPackage] = useState<Packaging | null>(null)
    const [fetchedBundle, setFetchedBundle] = useState<Bundle | null>(null)
    const [storageUnit, setStorageUnit] = useState<StorageUnit | null>(null)
    const [scans, setScans] = useState<BundleScan[] | null>(null)
    const { getSelectedAgreement, getSelectedLocationId } = useUser()
    const intl = useIntl()
    const navigate = useNavigate()
    const focusWhenSelectedElement = useRef<HTMLInputElement>(null)
    const { enqueueAlert } = useSnackbar()
    const { partnerClient } = useClient()
    const location = getSelectedLocationId()
    const partnerId = getSelectedAgreement()?.account.id
    const [bunleToDissolve, setBundleToDisolve] = useState<Bundle | null>(null)
    const [reloadTable, setReloadTable] = useState(true)

    const getCondition = useCallback(
        (condition: number) => {
            switch (condition) {
                case 0:
                    return intl.formatMessage({ id: "common_working" })
                case 1:
                    return intl.formatMessage({ id: "common_recycled" })
                case 2:
                    return intl.formatMessage({ id: "common_landfilled" })
                case 3:
                    return intl.formatMessage({ id: "common_incinerated" })
            }
        },
        [intl],
    )

    const lookupBarcode = async () => {
        if (barcode.length === 0 || !partnerId || !location) {
            return
        }

        const parsedCode = parseBarcode(barcode)
        try {
            const bundleLookup = await partnerClient.bundleLookup(partnerId, parsedCode)

            if (!bundleLookup) {
                return enqueueAlert(
                    intl.formatMessage({ id: "lookup_could_not_find_barcode" }),
                    "warning",
                )
            }

            setFetchedPackage(bundleLookup.packaging)
            setScans(bundleLookup.scan)

            if (bundleLookup.bundle !== null) {
                setFetchedBundle(bundleLookup.bundle)
                const station = await partnerClient.getStation(
                    partnerId,
                    location,
                    bundleLookup.bundle.packaging_station_id,
                )
                if (!station) {
                    return
                }

                const packageItem = bundleLookup.packaging

                const res = await partnerClient.getStorageUnitsOnStation(
                    partnerId,
                    location,
                    station.id,
                    {
                        filters: { barcode: `${packageItem.barcode.substring(0, 4)}` },
                        pageSize: 99,
                    },
                )
                if (res) {
                    const SUS = res

                    const itemWithBundle = SUS.filter((item) => {
                        if (item.packaging.includes(barcode)) {
                            return item
                        }
                    })

                    if (itemWithBundle[0]) {
                        setStorageUnit(itemWithBundle[0])
                    }
                }
            }

            setReloadTable(true)
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            if (mappedError.status === 404) {
                return enqueueAlert(
                    intl.formatMessage({ id: "lookup_could_not_find_barcode" }),
                    "warning",
                )
            }
            displayErrorMessage(mappedError, enqueueAlert)
        }
    }

    const dissolvableBundle = useMemo(() => {
        if (scans) {
            return scans[scans.length - 1].type !== "Bundle"
        }
        return false
    }, [scans])

    const formatDateTime = (date: Date | string) =>
        dayjs(new Date(date)).format("DD/MM/YYYY:HH:mm:ss")

    const generalInfo =
        fetchedPackage && scans ? (
            <Grid container direction="column" spacing={2}>
                <InfoBox
                    title={intl.formatMessage({ id: "lookup_package_info" })}
                    items={[
                        {
                            label: intl.formatMessage({ id: "lookup_barcode" }),
                            value: fetchedPackage.barcode,
                        },
                        {
                            label: intl.formatMessage({ id: "lookup_creation_date" }),
                            value: formatDateTime(fetchedPackage.created_at),
                        },
                        {
                            label: intl.formatMessage({ id: "lookup_updated_at" }),
                            value: formatDateTime(fetchedPackage.updated_at),
                        },
                    ]}
                />
                <DefaultWrapperPaper sx={{ display: "flex", padding: "20px" }}>
                    <Grid container direction="column" spacing={1} sx={{ width: "100%" }}>
                        <Typography variant="h4">
                            {intl.formatMessage({ id: "packaging_lookup_scan_information" })}
                        </Typography>

                        <AllInOneTable<BundleScan>
                            headers={[
                                {
                                    key: "date",
                                    label: intl.formatMessage({ id: "common_date" }),
                                    excludeFromSearch: true,
                                },
                                {
                                    key: "scan type",
                                    label: intl.formatMessage({ id: "packaging_lookup_scan_type" }),
                                    excludeFromSearch: true,
                                },
                                {
                                    key: "circulations",
                                    label: intl.formatMessage({
                                        id: "packaging_lookup_circulations",
                                    }),
                                    excludeFromSearch: true,
                                },
                                {
                                    key: "condition",
                                    label: intl.formatMessage({ id: "packaging_lookup_condition" }),
                                    excludeFromSearch: true,
                                },
                            ]}
                            itemsToRow={(item, index) => {
                                return (
                                    <StyledBodyRow key={`${index}-${item}`}>
                                        <StyledTableCell>
                                            {formatDateTime(item.created_at)}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.type !== null
                                                ? intl.formatMessage({
                                                      id: `packaging_lookup_${item.type.toLowerCase()}`,
                                                  })
                                                : intl.formatMessage({
                                                      id: "packaging_lookup_scan_type_null",
                                                  })}
                                        </StyledTableCell>
                                        <StyledTableCell>{item.circulations}</StyledTableCell>
                                        <StyledTableCell>
                                            {getCondition(item.condition)}
                                        </StyledTableCell>
                                    </StyledBodyRow>
                                )
                            }}
                            defaultSortBy={"created_at"}
                            items={scans}
                            orderDirection="desc"
                            hideSearchBar={true}
                            initialLoad={reloadTable}
                            setInitialLoad={setReloadTable}
                        />
                    </Grid>
                </DefaultWrapperPaper>
                {storageUnit ? (
                    <InfoBox
                        title={intl.formatMessage({ id: "lookup_storageunit_info" })}
                        items={[
                            {
                                label: intl.formatMessage({ id: "lookup_barcode" }),
                                value: storageUnit.barcode,
                            },
                            {
                                label: intl.formatMessage({ id: "lookup_completed" }),
                                value: storageUnit.completed
                                    ? intl.formatMessage({ id: "common_yes" })
                                    : intl.formatMessage({ id: "common_no" }),
                            },
                            {
                                label: intl.formatMessage({ id: "lookup_items" }),
                                value: `${storageUnit.packaging.length}/${storageUnit.size}`,
                            },
                            {
                                label: intl.formatMessage({ id: "lookup_creation_date" }),
                                value: formatDateTime(storageUnit.updated_at),
                            },
                        ]}
                        action={{
                            action: () =>
                                navigate(
                                    `/warehouse/storage-units/view/${location}/unit/${storageUnit.id}`,
                                ),
                            color: ColorThemeName.standard,
                            text: intl.formatMessage({ id: "lookup_go_to_storage" }),
                        }}
                    />
                ) : null}
            </Grid>
        ) : null

    const dissolveBundle = useCallback(async () => {
        if (!partnerId || !fetchedBundle || !fetchedPackage) {
            return
        }
        try {
            const res = await partnerClient.dissloveBundle(partnerId, fetchedPackage.id)
            if (res) {
                setFetchedPackage(null)
                setStorageUnit(null)
                setFetchedBundle(null)
                setBundleToDisolve(null)
                return enqueueAlert(intl.formatMessage({ id: "bundle_dissolved" }), "success")
            }
            return enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
            return enqueueAlert(intl.formatMessage({ id: "something_went_Wrong" }), "error")
        }
    }, [enqueueAlert, fetchedBundle, fetchedPackage, intl, partnerClient, partnerId])

    const bundleInfo =
        fetchedBundle && scans && partnerId ? (
            <Grid container direction="column" spacing={2}>
                <InfoBox
                    title={
                        scans[scans.length - 1].type === "Bundle"
                            ? intl.formatMessage({ id: "lookup_bundle_info" })
                            : intl.formatMessage({ id: "latest_bundle_info" })
                    }
                    items={[
                        {
                            label: intl.formatMessage({ id: "common_status" }),
                            value:
                                scans[scans.length - 1].type === "Bundle"
                                    ? intl.formatMessage({
                                          id: "packaging_lookup_in_bundle",
                                      })
                                    : intl.formatMessage({
                                          id: "packaging_lookup_not_in_bundle",
                                      }),
                        },
                        {
                            label: intl.formatMessage({ id: "lookup_items" }),
                            value: `${fetchedBundle.packaging.length}/${fetchedBundle.size}`,
                        },
                        {
                            label: intl.formatMessage({ id: "lookup_creation_date" }),
                            value: formatDateTime(fetchedBundle.created_at),
                        },
                        {
                            label: intl.formatMessage({ id: "lookup_updated_at" }),
                            value: formatDateTime(fetchedBundle.updated_at),
                        },
                    ]}
                    hideAction={dissolvableBundle}
                    action={{
                        action: () => setBundleToDisolve(fetchedBundle),
                        text: intl.formatMessage({ id: "lookup_dissolve_bunlde" }),
                        color: ColorThemeName.warning,
                    }}
                />
                <DefaultWrapperPaper sx={{ display: "flex", padding: "20px" }}>
                    <Grid container direction="column" spacing={1} size="grow">
                        <Typography variant="h4">
                            {intl.formatMessage({ id: "lookup_items_in_bunlde" })}
                        </Typography>
                        <AllInOneTable<string>
                            headers={[
                                {
                                    key: "barcode",
                                    label: intl.formatMessage({ id: "common_barcode" }),
                                    excludeFromSearch: true,
                                },
                            ]}
                            itemsToRow={(item, index) => {
                                return (
                                    <StyledBodyRow key={`${index}-${item}`}>
                                        <StyledTableCell>{item}</StyledTableCell>
                                    </StyledBodyRow>
                                )
                            }}
                            defaultSortBy={0}
                            items={fetchedBundle.packaging}
                            hideSearchBar={true}
                            initialLoad={reloadTable}
                            setInitialLoad={setReloadTable}
                        />
                    </Grid>
                </DefaultWrapperPaper>
            </Grid>
        ) : null

    return (
        <Grid container direction="column" size="grow">
            <Grid container direction="column" spacing={2}>
                <DefaultWrapperPaper sx={{ maxWidth: "500px" }}>
                    <Grid>
                        <BarcodeScanInput
                            onScan={lookupBarcode}
                            value={barcode}
                            onChange={setBarcode}
                            inputRef={focusWhenSelectedElement}
                            focused={true}
                        />
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{ marginTop: "12px" }}>
                        <CustomButton
                            text={intl.formatMessage({ id: "lookup_lookup" })}
                            onClick={lookupBarcode}
                        />
                    </Grid>
                </DefaultWrapperPaper>

                <Grid container spacing={2}>
                    <Grid sx={{ flexGrow: 1 }}>{generalInfo}</Grid>
                    <Grid sx={{ flexGrow: 1 }}>{bundleInfo}</Grid>
                </Grid>
            </Grid>
            <GenericModal
                open={!!bunleToDissolve}
                CustomContent={
                    <Grid>
                        <Typography>
                            {intl.formatMessage({ id: "lookup_dissolve_warning" })}
                        </Typography>
                    </Grid>
                }
                CustomActions={
                    <Grid>
                        <CustomButton
                            text={intl.formatMessage({ id: "common_no" })}
                            colorTheme={ColorThemeName.noBackground}
                            onClick={() => setBundleToDisolve(null)}
                        />
                        <CustomButton
                            text={intl.formatMessage({ id: "common_yes" })}
                            onClick={() => dissolveBundle()}
                        />
                    </Grid>
                }
                title={""}
                onClose={() => setBundleToDisolve(null)}
            />
        </Grid>
    )
}

export default PackageLookup
