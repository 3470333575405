import { v4 as uuidv4 } from "uuid"
import { ULID } from "./types"
import { AuthenticationError, ClientError } from "./client_error"
import { tryParseRequestError } from "./request_error"

export type HttpHeader = Record<string, string | number | undefined | null>
export type StringifiedHttpHeader = Record<string, string>

export type HttpRecord = {
    [key: string]: number | string | undefined | null | HttpRecord
}
export type StringifiedHttpRecord = {
    [key: string]: string | StringifiedHttpRecord
}

const normalizeToken = (token: string) => {
    const prefix = token.split(" ")[0].toLowerCase()
    if (["bearer", "basic", "pat"].includes(prefix)) {
        return token
    }
    return `Bearer ${token}`
}

export class ReZipClient {
    constantHeaders: Record<string, string>
    baseUrl: string
    statusHandlers: Record<number, () => void>
    constructor(props: {
        url: string
        token?: string
        statusHandlers?: Record<number, () => void>
        headers?: Record<string, string>
    }) {
        this.baseUrl = props.url
        this.constantHeaders = Object.assign(
            props.headers || {},
            {
                "Content-Type": "application/json",
                "Accept-Version": "2.0",
                "X-Session-UUID": uuidv4(),
            } as Record<string, string>,
            props.token
                ? {
                      Authorization: normalizeToken(props.token),
                  }
                : {},
        )
        this.statusHandlers = props.statusHandlers ?? {}
    }

    async get(props: {
        path: string
        queryParams?: HttpRecord
        headers?: HttpHeader
    }): Promise<Response> {
        const headers = this.normalizeHttpHeader(
            Object.assign(this.newHeaders(), props.headers || {}),
        )

        return this.runHandlers(
            await fetch(this.pathToUri(props.path) + this.genQueryParams(props.queryParams), {
                method: "GET",
                headers: headers,
            }),
        )
    }
    async delete(props: {
        path: string
        queryParams?: HttpRecord
        headers?: HttpRecord
        body?: string
    }): Promise<Response> {
        const headers = this.normalizeHttpHeader(
            Object.assign(this.newHeaders(), props.headers || {}),
        )

        return this.runHandlers(
            await fetch(this.pathToUri(props.path) + this.genQueryParams(props.queryParams), {
                method: "DELETE",
                headers: headers,
                body: props.body,
            }),
        )
    }

    async post(props: {
        path: string
        body: string | FormData | Blob
        queryParams?: HttpRecord
        headers?: HttpHeader
    }): Promise<Response> {
        const headers = this.normalizeHttpHeader(
            Object.assign(this.newHeaders(), props.headers || {}),
        )

        return this.runHandlers(
            await fetch(this.pathToUri(props.path) + this.genQueryParams(props.queryParams), {
                method: "POST",
                headers: headers,
                body: props.body,
            }),
        )
    }

    async put(props: {
        path: string
        body: string | FormData | Blob
        queryParams?: HttpRecord
        headers?: HttpHeader
    }): Promise<Response> {
        const headers = this.normalizeHttpHeader(
            Object.assign(this.newHeaders(), props.headers || {}),
        )

        return this.runHandlers(
            await fetch(this.pathToUri(props.path) + this.genQueryParams(props.queryParams), {
                method: "PUT",
                headers: headers,
                body: props.body,
            }),
        )
    }

    async patch(props: {
        path: string
        body: string | FormData | Blob
        queryParams?: HttpRecord
        headers?: HttpHeader
    }): Promise<Response> {
        const headers = this.normalizeHttpHeader(
            Object.assign(this.newHeaders(), props.headers || {}),
        )

        return this.runHandlers(
            await fetch(this.pathToUri(props.path) + this.genQueryParams(props.queryParams), {
                method: "PATCH",
                headers: headers,
                body: props.body,
            }),
        )
    }

    async query(props: {
        path: string
        body: string | FormData | Blob
        queryParams?: HttpRecord
        headers?: HttpHeader
    }): Promise<Response> {
        return this.post(props)
    }

    private runHandlers(response: Response): Response {
        this.statusHandlers[response.status]?.()
        return response
    }

    private newHeaders(): HttpHeader {
        return Object.assign(this.constantHeaders, {
            "X-Request-UUID": uuidv4(),
        })
    }

    pathToUri(path: string): string {
        return new URL(path, this.baseUrl).href
    }

    private normalizeHttpHeader(header: HttpHeader): StringifiedHttpHeader {
        return Object.fromEntries(
            Object.entries(header)
                .filter(([k, v]) => (v ?? undefined) !== undefined && k)
                .map(([k, v]) => [k, `${v}`]),
        )
    }

    private normalizeRecord(record: HttpRecord): StringifiedHttpRecord {
        return Object.fromEntries(
            Object.entries(record)
                .filter(([k, v]) => (v ?? undefined) !== undefined && k)
                .map(([k, v]) => {
                    if (typeof v === "object") {
                        return [k, v]
                    } else {
                        return [k, `${v}`]
                    }
                }),
        )
    }

    private genQueryParams(queryParams?: HttpRecord): string {
        const params = queryParams || {}
        if (Object.keys(params).length === 0) return ""

        // Manually build the query string for other parameters
        const queryParts = Object.entries(this.normalizeRecord(params))
            .map(([key, value]) => {
                const map = this.recursiveObjectToMap([], value)
                return [...map].map(([keys, v]) => {
                    const subKeys = keys
                        .filter((x) => !!x)
                        .map((x) => `[${x}]`)
                        .join("")
                    return `${encodeURIComponent(`${key}${subKeys}`)}=${encodeURIComponent(`${v}`)}`
                })
            })
            .flat()
            .join("&")

        // Combine the query parts

        return `?${queryParts}`
    }
    private recursiveObjectToMap<T extends Record<string, T> | string>(
        previous: string[],
        http: T,
    ): Map<string[], string> {
        if (typeof http === "string") {
            return new Map([[[], http]])
        }
        const result = Object.entries(http).map(([k, v]) => {
            const keys = [...previous, k]
            if (typeof v === "object") {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return this.recursiveObjectToMap(keys, v as any)
            } else {
                return new Map([[keys, v]])
            }
        })
        return result.reduce((r, map) => new Map([...r, ...map]), new Map())
    }
}

export type QueryingOptions = {
    pageSize: number
    pageFrom: ULID | null
    sortBy: string
    sortDir: "desc" | "asc"
    filters: HttpRecord
}
export type OptionalQueryingOptions = Partial<QueryingOptions> | undefined

export const defaultQueringOptions = (
    queryingOptions: OptionalQueryingOptions,
): QueryingOptions => {
    return {
        pageSize: queryingOptions?.pageSize || 10,
        pageFrom: queryingOptions?.pageFrom || null,
        sortBy: queryingOptions?.sortBy || "id",
        sortDir: queryingOptions?.sortDir || "desc",
        filters: queryingOptions?.filters || {},
    }
}

export const baseApiErrorHandling = async (response: Response): Promise<void> => {
    if (response.status === 401) {
        throw new AuthenticationError(response.url)
    }

    if (response.status >= 400) {
        const json = await response.json().catch(() => ({}))
        if (response.status === 400) {
            const requestError = tryParseRequestError(json)
            if (requestError) {
                throw requestError
            }
        }
        throw new ClientError(response.status, json, response.url)
    }
}
