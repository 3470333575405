import { AlertColor } from "@mui/material"
import { ClientError } from "@repo/rezip-client/client_error"
import { IntlShape } from "react-intl"

export const MissingFieldErrorHandler = (
    error: ClientError,
    enqueueAlert: (message: string, alertType: AlertColor) => void,
    intl: IntlShape,
) => {
    if (error.body && "errors" in error.body) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const failedItems = error.body["errors"] as Record<string, any>

        const extractMissingFields = (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            obj: Record<string, any>,
            prefix = "",
        ): string[] => {
            return Object.entries(obj).flatMap(([key, value]) => {
                const newKey = prefix ? `${prefix}_${key}` : key

                if (Array.isArray(value) && value.includes("must be filled")) {
                    return newKey
                } else if (typeof value === "object" && value !== null) {
                    return extractMissingFields(value, newKey)
                }
                return []
            })
        }

        // Get the list of all missing fields
        const missingFields = extractMissingFields(failedItems)

        if (missingFields.length > 0) {
            const formattedKeys = missingFields
                .map((key) => key.replace(/^(billing_address_)/, "").replace("_", " "))
                .join(", ")

            return enqueueAlert(
                intl.formatMessage({ id: "must_be_filled_error" }, { errors: formattedKeys }),
                "error",
            )
        }
    }

    return enqueueAlert(intl.formatMessage({ id: "common_error" }), "error")
}
