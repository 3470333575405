import { DialogContent, DialogTitle, Typography } from "@mui/material"

import { useIntl } from "react-intl"
import { StyledDialog, StyledDialogActions } from "./Modal.styles"
import { YesNoModalProps } from "./Modal.types"
import { ColorThemeName, CustomButton } from "../generalComponents/Buttons"

const YesOrNoModal = ({
    onClose,
    open,
    negativeAction,
    positiveAction,
    title,
    contentString,
    postitiveActionTextOverride,
    negativeActionTextOverride,
    negativeColorOverride,
    positiveColorOverride,
}: YesNoModalProps) => {
    const intl = useIntl()

    return (
        <StyledDialog open={open} onClose={onClose}>
            <DialogTitle>
                <Typography>{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">{contentString}</Typography>
            </DialogContent>

            <StyledDialogActions>
                <CustomButton
                    text={intl.formatMessage({
                        id: negativeActionTextOverride ?? "common_no",
                    })}
                    onClick={negativeAction}
                    colorTheme={negativeColorOverride ?? ColorThemeName.standardOutline}
                />
                <CustomButton
                    text={intl.formatMessage({
                        id: postitiveActionTextOverride ?? "common_yes",
                    })}
                    onClick={positiveAction}
                    colorTheme={positiveColorOverride ?? ColorThemeName.warning}
                />
            </StyledDialogActions>
        </StyledDialog>
    )
}

export default YesOrNoModal
