import { styled, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { EditCalendar } from "@mui/icons-material"
import { useIntl } from "react-intl"
import { FunctionComponent, ReactElement } from "react"
import StyledForm from "../layout/StyledForm"
import dayjs from "dayjs"
import { FieldLabel } from "../styles"

interface DatePickerProps {
    date: Date | undefined
    label?: string
    onchange: (date: Date) => void
    minDate?: Date
    maxDate?: Date
}

interface ContainerProps {
    children: ReactElement | ReactElement[]
}

const StyledDatePicker = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.secondaryBackground.main,
    display: "flex",
    fontSize: "12px",
    borderRadius: "4px",
    border: `1px solid`,
    borderColor: theme.palette.inputField.border,
    color: theme.palette.text.primary,

    "& .react-datepicker__day": {
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    "& .react-datepicker__day--today": {
        borderColor: "transparent",
        fontWeight: 500,
    },
    "& .react-datepicker__day--keyboard-selected": {
        backgroundColor: theme.palette.primary.light,
        fontWeight: 600,
        outline: "2px solid",
        outlineColor: theme.palette.primary.main,
        ...theme.applyStyles("dark", {
            backgroundColor: theme.palette.coreBackground.main,
        }),
    },
    "& .react-datepicker__day--selected": {
        backgroundColor: theme.palette.primary.light,
        fontWeight: 600,
        color: theme.palette.text.primary,
        borderColor: "transparent",
        outline: "2px solid",
        outlineColor: theme.palette.primary.main,
        ...theme.applyStyles("dark", {
            backgroundColor: theme.palette.coreBackground.main,
        }),
    },
    "& .react-datepicker__day--today--keyboard-selected": {
        outline: "2px solid",
        outlineColor: theme.palette.primary.main,
    },
    "& .react-datepicker__day--disabled": {
        color: theme.palette.text.disabled,
    },
    "& .react-datepicker__header": {
        padding: "12px",
        backgroundColor: theme.palette.secondaryBackground.main,
    },
    "& .react-datepicker__current-month": {
        color: theme.palette.text.primary,
        marginTop: "8px",
        marginBottom: "8px",
    },
    "& .react-datepicker__day-name": {
        color: theme.palette.text.primary,
    },
    "& .react-datepicker__navigation--previous": {
        left: "-10px",
    },
    "& .react-datepicker__navigation--next": {
        right: "-10px",
    },
    "& .react-datepicker__month-container": {
        width: "100%",
    },
}))

const CustomContainer: FunctionComponent<ContainerProps> = ({ children }) => {
    return <StyledDatePicker>{children}</StyledDatePicker>
}

// TODO: Add more styling
const DatePicker = ({ date, label, onchange, minDate, maxDate }: DatePickerProps) => {
    const intl = useIntl()

    return (
        <StyledForm>
            <Grid
                container
                direction="column"
                sx={{
                    "& .react-datepicker-wrapper": {
                        backgroundColor: "inputField.background",
                    },
                }}>
                {label ? <FieldLabel>{label}</FieldLabel> : null}
                <ReactDatePicker
                    selected={date}
                    dateFormat={"dd-MM-YYYY"}
                    maxDate={maxDate ?? undefined}
                    minDate={minDate ?? undefined}
                    customInput={
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={0.5}
                            sx={{
                                border: `1px solid`,
                                borderColor: "inputField.border",
                                padding: "2.7px 14px 2.5px 14px",
                                whiteSpace: "normal",
                                lineHeight: "normal",
                                borderRadius: "3px",
                                cursor: "pointer",
                            }}>
                            <EditCalendar sx={{ fontSize: "14px", color: "text.primary" }} />
                            <Typography fontSize={"14px"} fontWeight={400}>
                                {date
                                    ? dayjs(date).format("DD/MM/YYYY")
                                    : intl.formatMessage({
                                          id: "date_picker_placeholder",
                                      })}
                            </Typography>
                        </Grid>
                    }
                    // this is causing a infite loop - we have to look further into this. and maybe change datepicker. this is a huge problem!
                    // locale={intl.locale}
                    monthsShown={1}
                    calendarContainer={CustomContainer}
                    onChange={(x) => {
                        if (x) {
                            onchange(x)
                        }
                    }}
                />
            </Grid>
        </StyledForm>
    )
}
export default DatePicker
