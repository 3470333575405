import React, { useState, useCallback, useMemo } from "react"
import { AlertColor } from "@mui/material"
import CustomSnackbar, { Alert } from "src/components/Snackbar"
import { SnackbarContext } from "./SnackbarConsumer"

type SnackbarProviderProps = {
    children: React.ReactNode
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [alerts, setAlerts] = useState<Alert[]>([])
    const alertLifetimeInSeconds = 8

    // Initialize alertTimeouts with useMemo to make it a stable reference
    const alertTimeouts = useMemo(() => new Map<string, NodeJS.Timeout>(), [])

    const enqueueAlert = useCallback(
        (message: string, alertType: AlertColor) => {
            const id = Math.random().toString(36).substring(2, 9)
            const now = Date.now()

            setAlerts((prev) => {
                // Find an alert with the same message and type
                const existingAlertIndex = prev.findIndex(
                    (alert) => alert.message === message && alert.alertType === alertType,
                )

                if (existingAlertIndex !== -1) {
                    const existingAlert = prev[existingAlertIndex]

                    // Reset the timeout for the existing alert
                    clearTimeout(alertTimeouts.get(existingAlert.id) as NodeJS.Timeout)

                    // Update the alert's timestamp and count
                    const updatedAlerts = [...prev]
                    updatedAlerts[existingAlertIndex] = {
                        ...existingAlert,
                        count: existingAlert.count + 1,
                        timestamp: now, // Reset timestamp
                    }

                    // Restart the timeout for this alert
                    alertTimeouts.set(
                        existingAlert.id,
                        setTimeout(() => {
                            setAlerts((currentAlerts) =>
                                currentAlerts.filter((alert) => alert.id !== existingAlert.id),
                            )
                            alertTimeouts.delete(existingAlert.id)
                        }, alertLifetimeInSeconds * 1000),
                    )

                    return updatedAlerts
                }

                // If no duplicate found, add a new alert with count = 1 and timestamp
                const newAlert = { id, message, alertType, count: 1, timestamp: now }
                alertTimeouts.set(
                    id,
                    setTimeout(() => {
                        setAlerts((currentAlerts) =>
                            currentAlerts.filter((alert) => alert.id !== id),
                        )
                        alertTimeouts.delete(id)
                    }, alertLifetimeInSeconds * 1000),
                )

                return [...prev, newAlert]
            })
        },
        [alertLifetimeInSeconds, alertTimeouts],
    )

    const removeAlert = useCallback(
        (id: string) => {
            clearTimeout(alertTimeouts.get(id) as NodeJS.Timeout)
            alertTimeouts.delete(id)
            setAlerts((prev) => prev.filter((alert) => alert.id !== id))
        },
        [alertTimeouts],
    )

    return (
        <SnackbarContext.Provider value={{ enqueueAlert }}>
            {children}
            <CustomSnackbar alerts={alerts} removeAlert={removeAlert} />
        </SnackbarContext.Provider>
    )
}
