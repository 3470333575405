import { ReactNode, createContext, useState } from "react"

export interface StorageCreationContext {
    searchTerm: string
    setSearchTerm: (term: string) => void
}

export const StorageCreationContext = createContext<StorageCreationContext | undefined>(undefined)

export const StorageCreationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [searchTerm, setSearchTerm] = useState("")

    return (
        <StorageCreationContext.Provider value={{ searchTerm, setSearchTerm }}>
            {children}
        </StorageCreationContext.Provider>
    )
}
