import { Libraries, useJsApiLoader } from "@react-google-maps/api"
import { ReactNode, createContext } from "react"

export interface JsLoader {
    isLoaded: boolean
}
// eslint-disable-next-line react-refresh/only-export-components
export const JsLoaderContext = createContext<JsLoader | undefined>(undefined)

/*
fixes:
hook.js:608 Performance warning! LoadScript has been reloaded unintentionally! You should not pass `libraries` prop as new array.
Please keep an array of libraries as static class property for Components and PureComponents,
or just a const variable outside of component, or somewhere in config files or ENV variables Error Component Stack
*/
const libs = ["visualization", "core", "geocoding"] as Libraries

export const JsLoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: import.meta.env.VITE_MAP_API_KEY,
        libraries: libs,
    })
    return <JsLoaderContext.Provider value={{ isLoaded }}>{children}</JsLoaderContext.Provider>
}
