import { useContext } from "react"
import { StorageCreationContext } from "./StorageCreationProvider"

export const useStorageCreation = (): StorageCreationContext => {
    const context = useContext(StorageCreationContext)
    if (!context) {
        throw new Error("useStorageCreation must be used within a StorageCreationProvider")
    }
    return context
}
