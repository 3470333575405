import { useCallback, useState } from "react"
import { useShopHook } from "./use-shop-hooks"
import { useIntl } from "react-intl"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useNavigate } from "react-router-dom"
import { useClient } from "src/hooks/useClient.hook"
import { CreateShop } from "@repo/rezip-client/types"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"
import { ClientError } from "@repo/rezip-client/client_error"

const useShopViewController = (id: string | undefined) => {
    const shopHookResult = useShopHook(id)
    const { setShop, accountId } = shopHookResult
    const { partnerClient } = useClient()
    const { shop } = shopHookResult
    const intl = useIntl()
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()
    const [userEmail, setUserEmail] = useState("")

    const [dialogOpen, setDialogOpen] = useState(false)

    const updateShop = useCallback(async () => {
        if (shop && accountId && id) {
            try {
                const editedShop = { ...shop }

                if (
                    shop.external_id?.length === 0 ||
                    shop.external_id == undefined ||
                    shop.external_id === null
                ) {
                    delete editedShop.external_id
                }

                const data = await partnerClient.updateShop(accountId, id, editedShop)

                if (data) {
                    enqueueAlert(
                        intl.formatMessage({
                            id: "toast_message_shop_updated",
                        }),
                        "success",
                    )
                    navigate(-1)
                }
            } catch (error) {
                const mappedError = errorConverter(error)

                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                return displayErrorMessage(mappedError, enqueueAlert)
            }
        }
    }, [accountId, enqueueAlert, partnerClient, id, intl, navigate, shop])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cleanAddressFields = (address?: Record<string, any>): void => {
        if (!address) return // Guard clause for undefined or null

        Object.keys(address).forEach((key) => {
            if (address[key] === null || address[key] === undefined) {
                delete address[key]
            }
        })
    }
    const createShop = useCallback(async () => {
        if (shop && accountId) {
            const editedShop: CreateShop = { ...shop, user_email: userEmail }

            // Clean billing address if present
            if (editedShop.billing_address) {
                cleanAddressFields(editedShop.billing_address)
                // Remove billing_address if it is empty after cleaning
                if (Object.keys(editedShop.billing_address).length === 0) {
                    delete editedShop.billing_address
                }
            }

            // Clean shipping address if present
            if (editedShop.shipping_address) {
                cleanAddressFields(editedShop.shipping_address)
                // Remove shipping_address if it is empty after cleaning
                if (Object.keys(editedShop.shipping_address).length === 0) {
                    delete editedShop.shipping_address
                }
            }
            try {
                await partnerClient.createShop(accountId, editedShop)

                enqueueAlert(
                    intl.formatMessage({
                        id: "toast_message_shop_added",
                    }),
                    "success",
                )

                navigate(-1)
            } catch (error) {
                const mappedError = errorConverter(error)

                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                return displayErrorMessage(mappedError, enqueueAlert)
            }
        }
    }, [accountId, enqueueAlert, partnerClient, intl, navigate, shop, userEmail])

    const deleteShop = useCallback(async () => {
        if (shop && accountId) {
            await partnerClient.deleteShop({ partnerId: accountId, shopId: shop.id })
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_shop_deleted",
                }),
                "success",
            )
        }
    }, [accountId, enqueueAlert, partnerClient, intl, shop])

    return {
        shop,
        setShop,
        createShop,
        updateShop,

        dialogOpen,
        setDialogOpen,
        deleteShop,
        accountId,
        userEmail,
        setUserEmail,
    }
}

export default useShopViewController
