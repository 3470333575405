import { useIntl } from "react-intl"
import { StyledBodyRow, StyledTableCell } from "../table/TableComponents"
import { AllInOneTable } from "../table/AllInOneTable"
import { OrderLine } from "@repo/rezip-client/partner_client"
import { formatCurrency } from "src/utils/formatCurrency"

interface OrderLinesTableProps {
    lines: OrderLine[]
    currency: string | null
    hideUnitPrice?: boolean
    initialLoad?: boolean
    setInitialLoad?: (value: boolean) => void
}

const Row = ({
    lineOrder,
    index,
    currency,
    hideUnitPrice,
}: {
    lineOrder: OrderLine
    index: number
    currency: string | null
    hideUnitPrice: boolean
}) => {
    const intl = useIntl()

    return (
        <StyledBodyRow key={index}>
            <StyledTableCell>{lineOrder.sku}</StyledTableCell>
            <StyledTableCell>{lineOrder.name}</StyledTableCell>
            <StyledTableCell>{lineOrder.dimensions}</StyledTableCell>
            {!hideUnitPrice && (
                <StyledTableCell>
                    {formatCurrency({
                        currencyCode: currency ? currency : "",
                        value: lineOrder.price,
                        intl,
                    })}
                </StyledTableCell>
            )}
            <StyledTableCell>{`${lineOrder.fulfilled} / ${lineOrder.quantity}`}</StyledTableCell>
        </StyledBodyRow>
    )
}

const OrderLinesTable = ({
    lines,
    currency,
    hideUnitPrice,
    initialLoad,
    setInitialLoad,
}: OrderLinesTableProps) => {
    const intl = useIntl()

    const orderLineToRow = (line: OrderLine, index: number) => {
        return (
            <Row
                currency={currency}
                lineOrder={line}
                index={index}
                hideUnitPrice={hideUnitPrice ?? false}
            />
        )
    }
    if (!currency) {
        return <></>
    }
    const headers = [
        {
            key: "sku",
            label: intl.formatMessage({
                id: "common_sku",
            }),
        },
        {
            key: "name",
            label: intl.formatMessage({
                id: "common_product",
            }),
        },
        {
            key: "dimensions",
            label: intl.formatMessage({
                id: "common_dimensions",
            }),
        },
        {
            key: "price",
            label: intl.formatMessage({
                id: "common_unit_price",
            }),
        },
        {
            key: "progress",
            label: intl.formatMessage({
                id: "common_progress",
            }),
        },
    ]

    if (hideUnitPrice) {
        headers.splice(3, 1)
    }

    return (
        <AllInOneTable<OrderLine>
            headers={headers}
            items={lines}
            itemsToRow={orderLineToRow}
            defaultSortBy={"id"}
            initialLoad={initialLoad}
            setInitialLoad={setInitialLoad}
            hideSearchBar
        />
    )
}

export default OrderLinesTable
