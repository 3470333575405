import { Address } from "@repo/rezip-client/types"

const formatAddressForGoogle = (x: Address) => {
    return `${x.street} ${x.city} ${x.postal_code} ${x.region ?? ""} ${x.country}`
}

export const guessLatLng = async (address: Address) => {
    const result = await new google.maps.Geocoder().geocode({
        address: formatAddressForGoogle(address),
    })
    if (result.results.length === 0) {
        return null
    }
    const location = result.results[0].geometry.location
    return { latitude: location.lat(), longitude: location.lng() }
}
