import React, { lazy, ReactNode, Suspense } from "react"
import { Navigate } from "react-router-dom"
import { useUser } from "src/contexts/UserConsumer"
import { Permission } from "@repo/rezip-client/types"

interface PermRouteProps {
    allowedRoles: string[]
    element: ReactNode
    permissions?: Permission[]
}

// Helper function to check permissions within the given ACL
const checkPermissions = (acl: Permission[], requiredPermissions: Permission[]): boolean => {
    return requiredPermissions.every((reqPerm) => {
        // Find a matching resource in the ACL

        const matchedPerm = acl.find((perm) => perm.resource === reqPerm.resource)

        if (!matchedPerm) return false

        // Ensure all specified methods in requiredPermissions that are `true` match the ACL
        return Object.keys(reqPerm).every((method) => {
            if (method === "resource") return true // Skip the resource key
            const reqValue = reqPerm[method as keyof Permission]
            const aclValue = matchedPerm[method as keyof Permission]

            // Only check if `reqValue` is `true`, ignore if `reqValue` is `false`
            // if the user are allowed to delete something but it isnt required on the page, it would force no-access before, this is disabled by us not caring of false values.
            return reqValue === false || aclValue === reqValue
        })
    })
}

const combinePermissions = (
    permissionGroups: { acl_permissions: Permission[] }[] | undefined,
): Permission[] => {
    if (!permissionGroups) return []

    const permissionMap = new Map<string, Permission>()

    permissionGroups
        .flatMap((group) => group.acl_permissions)
        .forEach((permission) => {
            const existingPermission = permissionMap.get(permission.resource)

            if (existingPermission) {
                // Merge permissions explicitly by retaining true values
                permissionMap.set(permission.resource, {
                    resource: permission.resource,
                    get: existingPermission.get || permission.get,
                    put: existingPermission.put || permission.put,
                    post: existingPermission.post || permission.post,
                    patch: existingPermission.patch || permission.patch,
                    delete: existingPermission.delete || permission.delete,
                    query: existingPermission.query || permission.query,
                })
            } else {
                // If no existing permission, add it directly
                permissionMap.set(permission.resource, { ...permission })
            }
        })

    return Array.from(permissionMap.values())
}

export const AccountCheckRoute = ({ allowedRoles, permissions, element }: PermRouteProps) => {
    const { getSelectedAccountType, getSelectedAgreement } = useUser()
    const agreement = getSelectedAgreement()
    const acl = agreement?.acl_permissions ?? []
    const groups = agreement?.permission_groups

    // Combine all permissions into a single array
    const combinedPermissions = combinePermissions(groups)

    // Determine which permissions set to use
    const permissionsToCheck = combinedPermissions.length > 0 ? combinedPermissions : acl

    const type = getSelectedAccountType() ?? ""

    // Check permissions if required
    // for now since we only use permissions on partners.
    // There is alot of changes to be made, if we put permissions on other account types.

    if (
        permissions &&
        type === "Partner" &&
        !agreement?.owner &&
        !checkPermissions(permissionsToCheck, permissions)
    ) {
        return <Navigate to={"/no-access"} />
    }

    // Check allowed roles

    if (!allowedRoles.includes(type)) {
        return <Navigate to={"/home"} />
    }

    return element
}

interface PermRouteAsyncProps {
    allowedRoles: string[]

    lazyElement: () => Promise<React.FC>
}

export const AccountCheckRouteAsync = ({
    allowedRoles,

    lazyElement,
}: PermRouteAsyncProps) => {
    const { getSelectedAccountType } = useUser()
    const type = getSelectedAccountType() ?? ""

    if (!allowedRoles.includes(type)) {
        return <Navigate to={"/no-access"} />
    }

    const Comp = lazy(() => lazyElement().then((x) => ({ default: x })))

    return (
        <Suspense fallback={<></>}>
            <Comp />
        </Suspense>
    )
}
