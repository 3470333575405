import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { ProductWithQuant } from "@repo/rezip-client/partner_client"
import { AllInOneTable } from "src/components/table/AllInOneTable"

export const Inventory = () => {
    const [products, setProducts] = useState<ProductWithQuant[]>()
    const { getSelectedLocationId, getSelectedAgreement, getToken } = useUser()
    const intl = useIntl()
    const { partnerClient } = useClient()
    const [initialLoad, setInitialLoad] = useState(true)
    const partnerId = getSelectedAgreement()?.account.id
    useEffect(() => {
        if (!partnerId) {
            return
        }

        async function fetchProducts() {
            const locationId = getSelectedLocationId()
            if (!partnerId || !locationId) {
                return
            }

            const [products, stock] = await Promise.all([
                partnerClient.getProducts(partnerId, { pageSize: 9999 }),
                partnerClient.locationStock({ partnerId, locationId }),
            ])

            if (!products) {
                return
            }

            setProducts(
                products.map((p) => {
                    return { ...p, quantity: stock[p.sku] ?? 0 }
                }),
            )
        }
        fetchProducts()
    }, [getSelectedLocationId, getToken, partnerClient, partnerId])

    const itemToRow = (product: ProductWithQuant, index: number) => {
        return (
            <StyledBodyRow key={index}>
                <StyledTableCell>{product.sku}</StyledTableCell>
                <StyledTableCell>{product.name}</StyledTableCell>
                <StyledTableCell>{product.quantity}</StyledTableCell>
            </StyledBodyRow>
        )
    }

    return (
        <Page title={intl.formatMessage({ id: "menu_listitem_inventory" })}>
            <DefaultWrapperPaper>
                <AllInOneTable<ProductWithQuant>
                    headers={[
                        {
                            key: "sku",
                            label: intl.formatMessage({
                                id: "stockkeeping_sku",
                            }),
                        },
                        {
                            // key most still be name as it is .name in backend otherwise we cant search.
                            key: "name",
                            label: intl.formatMessage({
                                id: "common_product",
                            }),
                        },
                        {
                            key: "quantity",
                            label: intl.formatMessage({
                                id: "stockkeeping_total_quant",
                            }),
                            excludeFromSearch: true,
                        },
                    ]}
                    items={products ?? []}
                    itemsToRow={itemToRow}
                    defaultSortBy={"id"}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                />
            </DefaultWrapperPaper>
        </Page>
    )
}
