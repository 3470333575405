import { FormattedMessage, useIntl } from "react-intl"
import Page from "../layout/Page"
import { Typography, useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import { DefaultWrapperPaper } from "../layout/DefaultPageComponents"
import StyledForm from "../layout/StyledForm"
import { StyledP } from "../styles"
import InputField from "../InputField"
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react"

import { useNavigate } from "react-router-dom"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { Agreement, PartnerClient } from "@repo/rezip-client/partner_client"
import { ShopClient } from "@repo/rezip-client/shop_client"
import { DropPointClient } from "@repo/rezip-client/drop_point_client"
import { ColorThemeName, CustomButton } from "../generalComponents/Buttons"

interface InviteUserProps {
    id: string | undefined
    client: PartnerClient | ShopClient | DropPointClient
}

export const InviteUserComponent = ({ id, client }: InviteUserProps) => {
    const intl = useIntl()
    const [email, setEmail] = useState("")
    const theme = useTheme()
    const navigate = useNavigate()
    const { enqueueAlert } = useSnackbar()

    // Determine the appropriate method to call based on client type
    const addUser = useCallback(
        async (event?: FormEvent) => {
            if (!id) {
                return
            }

            if (event) {
                event.preventDefault()
            }

            try {
                let response: Agreement | null = null

                // Call the appropriate method based on the client type
                if (client instanceof PartnerClient) {
                    // We have to do this since the api doesnt give a error on dublicate email.
                    const users = await client.getAgreements(id, { pageSize: 99 })
                    if (users && users.some((item) => item.user?.email === email)) {
                        return enqueueAlert(
                            intl.formatMessage({
                                id: "dublicate_user_error",
                            }),
                            "error",
                        )
                    }
                    response = await client.postPartnerAgreement(id, {
                        email: email,
                        owner: false,
                    })
                } else if (client instanceof ShopClient) {
                    const users = await client.getAgreements(id, { pageSize: 99 })
                    if (users && users.some((item) => item.user?.email === email)) {
                        return enqueueAlert(
                            intl.formatMessage({
                                id: "dublicate_user_error",
                            }),
                            "error",
                        )
                    }

                    response = await client.postAgreement(id, {
                        email: email,
                        owner: false,
                    })
                } else if (client instanceof DropPointClient) {
                    const users = await client.getAgreements(id, { pageSize: 99 })
                    if (users && users.some((item) => item.user?.email === email)) {
                        return enqueueAlert(
                            intl.formatMessage({
                                id: "dublicate_user_error",
                            }),
                            "error",
                        )
                    }
                    response = await client.postAgreement(id, {
                        email: email,
                        owner: false,
                    })
                }

                if (!response) {
                    enqueueAlert(
                        intl.formatMessage({
                            id: "toast_something_went_wrong",
                        }),
                        "error",
                    )
                    throw new Error("error from server")
                }

                enqueueAlert(
                    intl.formatMessage({
                        id: "toast_message_user_added",
                    }),
                    "success",
                )
                navigate(-1)
            } catch (e) {
                console.log(e)
            }
        },
        [email, enqueueAlert, id, intl, client, navigate],
    )

    const isEmail = useMemo(() => {
        return !(email.length > 0 && email.includes("@") && email.includes("."))
    }, [email])

    return (
        <Page
            backButton
            title={intl.formatMessage({
                id: "users_invite_page",
            })}>
            <Grid container justifyContent="center" alignSelf="center">
                <DefaultWrapperPaper
                    sx={{ alignSelf: "center", justifyContent: "center", maxWidth: "400px" }}>
                    <StyledForm onSubmit={addUser}>
                        <Grid container spacing={0.2}>
                            <Typography> {intl.formatMessage({ id: "user_email" })}</Typography>
                            <Typography sx={{ color: theme.palette.required.main }}>*</Typography>
                        </Grid>
                        <StyledP>
                            <FormattedMessage id="user_invite_page_explanation" />
                            <br />
                            <FormattedMessage id="invite_link_life_span" />
                        </StyledP>

                        <InputField
                            id={"user_email"}
                            name={"user_email"}
                            type={"text"}
                            placeholder={intl.formatMessage({ id: "enter_email" })}
                            value={email}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setEmail(event.target.value)
                            }
                        />
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{ paddingTop: "14px" }}>
                            <CustomButton
                                onClick={() => navigate(-1)}
                                text={intl.formatMessage({ id: "common_cancel" })}
                                colorTheme={ColorThemeName.noBackground}
                            />
                            <CustomButton
                                tooltipText={
                                    isEmail
                                        ? intl.formatMessage({
                                              id: "add_user_tooltip_error",
                                          })
                                        : undefined
                                }
                                onClick={addUser}
                                disabled={isEmail}
                                text={intl.formatMessage({ id: "common_invite" })}
                            />
                        </Grid>
                    </StyledForm>
                </DefaultWrapperPaper>
            </Grid>
        </Page>
    )
}
