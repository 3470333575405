import { Address } from "@repo/rezip-client/types"
import { Typography } from "@mui/material"

export const AddressView = (props: { address: Address }) => {
    return (
        <Typography variant="body2">
            {props.address.street}
            <br />
            {props.address.postal_code} {props.address.city}
            <br />
            {props.address.region && props.address.region}
            {props.address.country}
        </Typography>
    )
}
