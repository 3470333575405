import React, { useState } from "react"
import MuiDrawer from "@mui/material/Drawer"
import Grid from "@mui/material/Grid"

import Typography from "@mui/material/Typography"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import CssBaseline from "@mui/material/CssBaseline"
import { styled, CSSObject } from "@mui/material/styles"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import LanguageSwitcher from "../LanguageSwitcher"
/// <reference types="vite-plugin-svgr/client" />
import { MenuListItems } from "src/utils/menuListItems"

import { useUser } from "src/contexts/UserConsumer"
import { ReactComponent as Logo } from "../../assets/icons/Logo_black.svg"
import { useIntl } from "react-intl"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Theme, useTheme } from "@mui/material/styles"
import { SupportedLocales } from "src/services/i18n"
import AccountMenu from "../accountItems/AccountMenu"

// mui swipeableDrawer? https://mui.com/material-ui/api/swipeable-drawer/

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const drawerWidth = 210

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const widthTransition = (theme: Theme) =>
    theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })

const openSideBar = (theme: Theme): CSSObject => ({
    boxShadow: "1px 1px 3px #00000020",
    backgroundColor: theme.palette.secondaryBackground.main,
    ...theme.applyStyles("dark", {
        boxShadow: "1px 1px 3px #00000050",
    }),
    [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        transition: widthTransition(theme),
        overflowX: "hidden",
        border: "none",
    },
    [theme.breakpoints.down("md")]: {
        width: drawerWidth,
        transition: widthTransition(theme),
        overflowX: "hidden",
        border: "none",
    },
    "::-webkit-scrollbar": {
        display: "none",
    },
})

const closeSideBar = (theme: Theme): CSSObject => ({
    transition: widthTransition(theme),
    backgroundColor: theme.palette.secondaryBackground.main,
    overflowX: "hidden",
    width: `calc(${theme.spacing(6)})`,
    [theme.breakpoints.up("md")]: {
        transition: widthTransition(theme),
        overflowX: "hidden",
        width: `0px`,
        marginLeft: "-1px",
    },
    [theme.breakpoints.down("md")]: {
        transition: widthTransition(theme),
        overflowX: "hidden",
        width: `0px`,
        marginLeft: "-1px",
    },
    "::-webkit-scrollbar": {
        display: "none",
    },
})

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,

    [theme.breakpoints.down("md")]: {
        ...(open && {
            borderRight: "none",
            transition: widthTransition(theme),
        }),
    },
    [theme.breakpoints.up("md")]: {
        ...(open && {
            marginLeft: drawerWidth,
            transition: widthTransition(theme),
        }),
    },
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ open, theme }) => ({
    width: drawerWidth,

    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openSideBar(theme),
        "& .MuiDrawer-paper": openSideBar(theme),
    }),
    ...(!open && {
        ...closeSideBar(theme),
        "& .MuiDrawer-paper": closeSideBar(theme),
    }),
}))

const ThemeLogo = styled(Logo)(({ theme }) => ({
    filter: "brightness(0%)",
    ...theme.applyStyles("dark", {
        filter: "brightness(100%)",
    }),
}))

type Props = {
    children: React.ReactNode
    onChangeLanguage?: (lang: SupportedLocales) => void
}

function Layout({ children, onChangeLanguage }: Props) {
    const theme = useTheme()
    const mdOrUp = useMediaQuery(theme.breakpoints.down("md"))
    const [open, setOpen] = useState(!mdOrUp)
    const toggleDrawer = () => {
        setOpen(!open)
    }
    const intl = useIntl()
    const { getUser, getToken, imposter } = useUser()

    const t = getToken()
    const u = getUser()

    if (!t || !u) {
        return children
    }

    return (
        <Grid>
            <CssBaseline />
            <Grid sx={{ display: "flex", height: "100vh", width: "100%" }}>
                <AppBar position="fixed" open={open}>
                    <Toolbar variant="dense">
                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                            <Grid container justifyContent="center" alignItems="center">
                                <ThemeLogo width="" height="15px" />
                            </Grid>

                            <Typography
                                sx={{ fontSize: "16px", fontWeight: "700" }}
                                color={"text.primary"}>
                                {
                                    // This one is needed since the manager is always called manager
                                }
                                Manager
                            </Typography>

                            <IconButton aria-label="drawer toggle" onClick={toggleDrawer}>
                                {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </Grid>

                        <Grid container justifyContent="center" alignItems="center" size="grow">
                            {imposter ? (
                                <Typography>
                                    {intl.formatMessage(
                                        { id: "imposter_logged_in_as" },
                                        { shop: imposter.account.name },
                                    )}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid container>
                            <LanguageSwitcher onChangeLanguage={onChangeLanguage} />
                            <AccountMenu />
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Drawer variant="permanent" open={open}>
                    <Grid sx={{ paddingTop: "60px" }}>
                        <MenuListItems toggleDarawer={toggleDrawer} drawerOpen={open} />
                    </Grid>
                </Drawer>

                <Grid
                    component="main"
                    sx={{
                        transition: widthTransition,
                        backgroundColor: "coreBackground.main",
                        overflow: "scroll",
                        overflowX: "hidden",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                        padding: "50px 80px",
                        [theme.breakpoints.down("md")]: {
                            padding: "15px",
                        },
                        ...(open && {
                            width: `calc(100vw - ${drawerWidth}px)`,
                        }),
                        ...(!open && {
                            width: "100%",
                        }),
                    }}>
                    <DrawerHeader />
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Layout
