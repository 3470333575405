import { Box, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import Page from "src/components/layout/Page"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useUser } from "src/contexts/UserConsumer"
import { StyledMenuItem, StyledP, StyledStrong } from "src/components/styles"
import CollapseInformation from "src/components/generalComponents/CollapseInformation"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import IdCell from "src/components/table/IdCell"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { QueryingOptions } from "@repo/rezip-client"
import { useClient } from "src/hooks/useClient.hook"
import { Shop } from "@repo/rezip-client/partner_client"
import LoginIcon from "@mui/icons-material/Login"
import CheckboxField from "src/components/generalComponents/CheckboxField"
import { Edit } from "@mui/icons-material"

const Shops = () => {
    const intl = useIntl()
    const { getSelectedAgreement, setImposter } = useUser()

    const navigate = useNavigate()
    const { partnerClient } = useClient()
    const partnerId = getSelectedAgreement()?.account.id
    const [initialLoad, setInitialLoad] = useState(true)
    const [filterSpecialAgreement, setFilterSpecialAgreement] = useState(false)
    const [filterSpecialOrdering, setFilterSpecialOrdering] = useState(false)

    const fetchShops = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!partnerId) {
                return
            }
            if (filterSpecialOrdering && !filterSpecialAgreement) {
                filters = { ...filters, settings: { ordering: "" } }
            }
            if (filterSpecialAgreement && !filterSpecialOrdering) {
                filters = { ...filters, association: { product_assortment: "" } }
            }
            if (filterSpecialAgreement && filterSpecialOrdering) {
                filters = {
                    ...filters,
                    association: { product_assortment: "" },
                    settings: { ordering: "" },
                }
            }
            const options = {
                filters: { ...filters, deleted_at: "" },
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            try {
                const resp = await partnerClient.getShops(partnerId, options)
                return resp
            } catch (error) {
                console.error(error)
            }
        },
        [filterSpecialAgreement, filterSpecialOrdering, partnerClient, partnerId],
    )

    const loginAs = useCallback(
        (shopId: string, name: string) => {
            if (!partnerId) {
                return
            }
            // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
            // window.open inside an async block wont receive a postMessage, therefor we did some hacking! check Stack post if more info is needed
            const newWindow = window.open(`/remote-access`, "_blank")
            ;(async () => {
                const createEmptyAgreement = await partnerClient.loginAs(partnerId, shopId)

                if (createEmptyAgreement) {
                    const asAgreement = createEmptyAgreement

                    const mapped = {
                        ...asAgreement,
                        account: { type: "Shop", name, id: shopId },
                    }

                    // Open the new window and store the reference

                    // Ensure the new window is loaded before sending the message
                    if (newWindow) {
                        const sendMessage = () => {
                            newWindow.postMessage({ imposter: mapped }, window.location.origin)
                            setImposter(null)
                        }
                        // Poll the new window until it's fully loaded, then send the message
                        // safari is dumb!,  if we send it imediatly it wont receive it, therefor we scream at it!
                        newWindow.onload = () => {
                            sendMessage()
                            for (let index = 1; index < 5; index++) {
                                setTimeout(
                                    () => {
                                        sendMessage()
                                    },
                                    Math.pow(10, index),
                                )
                            }
                        }
                    } else {
                        console.error("Failed to open new window")
                    }
                } else {
                    newWindow?.close()
                }
            })()
        },
        [partnerClient, partnerId, setImposter],
    )

    const mapShopToRow = (item: Shop, index: number) => {
        return (
            <StyledBodyRow key={index}>
                <StyledTableCell key={`name-${index}`}>
                    <Typography variant="body2">{item?.name}</Typography>
                </StyledTableCell>
                <IdCell key={`od-${index}`} id={item.id} />
                <StyledTableCell key={`agreements-${index}`}>
                    <ThreeDotMenu
                        menuItems={[
                            <StyledMenuItem
                                key={`view-${index}`}
                                onClick={() => navigate(`/administration/shops/view/${item.id}`)}>
                                <Edit />
                                {intl.formatMessage({ id: "common_edit" })}
                            </StyledMenuItem>,
                            <StyledMenuItem
                                sx={{ color: "warning.main" }}
                                key={`delete-${index}`}
                                onClick={() => loginAs(item.id, item.name)}>
                                <LoginIcon sx={{ fill: "red" }} />
                                {intl.formatMessage({ id: "common_login_as" })}
                            </StyledMenuItem>,
                        ]}
                    />
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_shops",
            })}>
            <CollapseInformation
                header={intl.formatMessage({
                    id: "shop_overview_information_box_title",
                })}>
                <Box>
                    <StyledP>
                        {intl.formatMessage({
                            id: "shop_overview_explation",
                        })}
                    </StyledP>
                </Box>
                <Box>
                    <StyledP>
                        <StyledStrong>
                            {intl.formatMessage({
                                id: "add_shop",
                            })}
                        </StyledStrong>
                        <br />
                        {intl.formatMessage({
                            id: "add_shop_explanation",
                        })}
                    </StyledP>
                </Box>
                <Box>
                    <StyledP>
                        <StyledStrong>
                            {intl.formatMessage({
                                id: "login",
                            })}
                        </StyledStrong>
                        <br />
                        {intl.formatMessage({
                            id: "login_explation",
                        })}
                    </StyledP>
                </Box>
                <Box>
                    <StyledP>
                        <StyledStrong>
                            {intl.formatMessage({
                                id: "edit",
                            })}
                        </StyledStrong>
                        <br />
                        {intl.formatMessage({
                            id: "edit_explation",
                        })}
                    </StyledP>
                </Box>
            </CollapseInformation>
            <DefaultWrapperPaper>
                <AllInOneTable<Shop>
                    headers={[
                        { label: intl.formatMessage({ id: "common_name_shop" }), key: "name" },
                        {
                            label: intl.formatMessage({ id: "common_shop_id" }),
                            key: "id",
                            excludeFromSearch: true,
                        },
                        { label: "", key: "actions", excludeFromSearch: true },
                    ]}
                    itemsToRow={mapShopToRow}
                    fetchItems={fetchShops}
                    defaultSortBy={"id"}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    addFunction={() => navigate("/administration/shops/add")}
                    addButtonText={intl.formatMessage({
                        id: "common_add",
                    })}
                    additionalSortColumns={
                        <Box sx={{ display: "flex", width: "100%", gap: "10px" }}>
                            <CheckboxField
                                id={"filterSpecialAgreement"}
                                name={"filterSpecialAgreement"}
                                text={intl.formatMessage({ id: "shops_filter_custom_agreement" })}
                                checked={filterSpecialAgreement}
                                onChange={() => {
                                    setFilterSpecialAgreement(!filterSpecialAgreement)
                                    setInitialLoad(true)
                                }}
                            />
                            <CheckboxField
                                id={"filterSpecialAgreement"}
                                name={"filterSpecialAgreement"}
                                text={intl.formatMessage({ id: "shops_filter_custom_ordering" })}
                                checked={filterSpecialOrdering}
                                onChange={() => {
                                    setFilterSpecialOrdering(!filterSpecialOrdering)
                                    setInitialLoad(true)
                                }}
                            />
                        </Box>
                    }
                    additionalColumnPosition="above"
                />
            </DefaultWrapperPaper>
        </Page>
    )
}

export default Shops
