import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useUser } from "src/contexts/UserConsumer"
import { User } from "src/contexts/UserProvider"
import { useIntl } from "react-intl"
import { Form, useNavigate } from "react-router-dom"
import { BaseUserAgreement, UserAgreementMeWithPermissionGroups } from "@repo/rezip-client/types"
import { useClient } from "src/hooks/useClient.hook"
import GenericModal from "./GenericModal"
import InputField from "../InputField"
import { CustomButton } from "../generalComponents/Buttons"

const Auth = ({
    onVerify,
    agreement,
    user,
    setOpen,
    open,
}: {
    user: User
    onVerify: () => void
    agreement?: BaseUserAgreement
    setOpen: (open: boolean) => void
    open: boolean
}) => {
    const { setMfaUsed, getMfaUsed } = useUser()
    const mfaUsed = getMfaUsed()
    const { anonymousClient } = useClient()
    const [code, setCode] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const intl = useIntl()

    const authed = user.mfa_required || agreement?.mfa_required ? mfaUsed : true

    useEffect(() => {
        if (authed) {
            setOpen(false)
            onVerify()
        }
    }, [authed, user, onVerify, setOpen])

    const handleSubmit = async () => {
        setLoading(true)
        setError("")
        try {
            const authed = await anonymousClient.verifyMFA(code)
            if (authed) {
                setMfaUsed(true)
                setOpen(false)
                onVerify()
            } else {
                setError(intl.formatMessage({ id: "mfa_invalid_code" }))
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (err) {
            setError(intl.formatMessage({ id: "mfa_error_occured" }))
        } finally {
            setLoading(false)
        }
    }

    return (
        <GenericModal
            CustomContent={
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                    }}>
                    <InputField
                        id="mfa-code"
                        label={intl.formatMessage({ id: "authentication_code" })}
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                        disabled={loading}
                        name={""}
                        type={"text"}
                    />
                    {error && <div style={{ color: "red", marginBottom: "16px" }}>{error}</div>}
                    <CustomButton
                        text={loading ? "Verifying..." : "Authorize"}
                        disabled={loading}
                    />
                </Form>
            }
            open={open}
            aria-labelledby="mfa-modal-title"
            title={intl.formatMessage({ id: "user_settings_mfa" })}
            onClose={() => setOpen(false)}></GenericModal>
    )
}

const NoMfaEnabled = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
    const intl = useIntl()
    const navigate = useNavigate()

    const handleClose = () => setOpen(false)

    return (
        <GenericModal
            title={intl.formatMessage({
                id: "mfa_modal_not_enabled_header",
            })}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            CustomContent={
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {intl.formatMessage({
                        id: "mfa_modal_not_enabled_body",
                    })}
                </Typography>
            }
            CustomActions={
                <CustomButton
                    onClick={() => navigate("/user-settings")}
                    text={"Go to user settings"}
                />
            }
        />
    )
}

export const MfaModal = ({
    onVerify,
    agreement,
    open,
    setOpen,
}: {
    onVerify: (agreement?: UserAgreementMeWithPermissionGroups) => void
    agreement?: BaseUserAgreement
    open: boolean
    setOpen: (open: boolean) => void
}) => {
    const { getUser } = useUser()
    const user = getUser()

    if (!user) {
        return null
    }
    const noMfa =
        user.mfa_required || agreement?.mfa_required || user.mfa_required_by_account
            ? !user.mfa_required
            : false

    return !noMfa && open ? (
        <Auth open={open} setOpen={setOpen} onVerify={onVerify} agreement={agreement} user={user} />
    ) : (
        <NoMfaEnabled open={open} setOpen={setOpen} />
    )
}
