import { Box } from "@mui/material"
import { useIntl } from "react-intl"

import PackageLookup from "src/components/PackageLookup"
import Page from "src/components/layout/Page"

export default function PackagingLookup() {
    const intl = useIntl()

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_packaging_lookup",
            })}>
            <Box sx={{ display: "flex" }}>
                <PackageLookup />
            </Box>
        </Page>
    )
}
