import { MouseEvent } from "react"
import Switch from "@mui/material/Switch"
import { useIntl } from "react-intl"

type SwitchProps = {
    id: string
    value: boolean
    checked: boolean
    onClick: (event: MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean | undefined
}

const SwitchField = ({ id, value, checked, onClick, disabled }: SwitchProps) => {
    const intl = useIntl()
    return (
        <Switch
            id={id}
            value={value}
            checked={checked}
            onClick={onClick}
            disabled={disabled}
            content="on"
            sx={{
                // When switch is off
                "& .MuiSwitch-switchBase": {
                    minWidth: "40px",
                    "& .MuiSwitch-thumb:before": {
                        content: `'${intl.formatMessage({ id: "common_no" })}'`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: "8px",
                        lineHeight: 1,
                    },
                },
                // When switch is on
                "& .MuiSwitch-switchBase.Mui-checked": {
                    "& .MuiSwitch-thumb:before": {
                        content: `'${intl.formatMessage({ id: "common_yes" })}'`,
                    },
                },
            }}
        />
    )
}

export default SwitchField
