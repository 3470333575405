import { Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import YesOrNoModal from "src/components/modals/YesOrNoModal"
import Page from "src/components/layout/Page"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { QueryingOptions } from "@repo/rezip-client"
import { FileInfo } from "@repo/rezip-client/partner_client"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { StyledMenuItem } from "src/components/styles"
import { TrashCan } from "src/components/TrashCan"
export default function FileManager() {
    const intl = useIntl()
    const navigate = useNavigate()
    const { getSelectedAgreement } = useUser()
    const { enqueueAlert } = useSnackbar()

    const [fileToDelete, setFileToDelete] = useState<FileInfo | null>(null)

    const { partnerClient } = useClient()
    const partnerId = getSelectedAgreement()?.account.id
    const [inititalLoad, setInitialLoad] = useState(true)

    const getFileAndOpen = useCallback(
        async (id: string) => {
            if (!partnerId) {
                return
            }
            const url = partnerClient.getFileInfo({
                partnerId: partnerId,
                fileId: id,
            })

            if (url) {
                const newTab = window.open(url, "_blank")
                if (newTab) {
                    newTab.focus()
                }
            }
        },
        [partnerClient, partnerId],
    )

    const fetchItems = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!partnerId) {
                return []
            }
            const options = {
                filters: { ...filters },
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            return await partnerClient.getFiles(partnerId, options)
        },
        [partnerClient, partnerId],
    )

    const deleteFile = useCallback(async () => {
        if (!partnerId) {
            return
        }
        if (!fileToDelete) {
            return
        }
        const data = await partnerClient.deleteFile(partnerId, fileToDelete.id)

        if (data) {
            setFileToDelete(null)
            setInitialLoad(true)
            return enqueueAlert(intl.formatMessage({ id: "file_was_deleted" }), "success")
        }
    }, [enqueueAlert, fileToDelete, intl, partnerClient, partnerId])

    const mapFileToRow = (file: FileInfo, index: number) => {
        return (
            <StyledBodyRow key={`${index}-file`}>
                <StyledTableCell>{file.description}</StyledTableCell>
                <StyledTableCell>{file.file_name}</StyledTableCell>
                <StyledTableCell>
                    <ThreeDotMenu
                        menuItems={[
                            <StyledMenuItem
                                key={`${index}-preview`}
                                onClick={() => getFileAndOpen(file.id)}>
                                {intl.formatMessage({ id: "common_preview" })}
                            </StyledMenuItem>,
                            <StyledMenuItem
                                onClick={() => navigate(`view/${file.id}`)}
                                key={`${index}-view`}>
                                <RemoveRedEyeIcon width={25} height={25} />
                                {intl.formatMessage({ id: "common_view" })}
                            </StyledMenuItem>,
                            <StyledMenuItem
                                onClick={() => {
                                    setFileToDelete(file)
                                }}
                                key={`${index}-delete`}>
                                <TrashCan width={25} height={25} />
                                <Typography sx={{ color: "warning.main" }}>
                                    {intl.formatMessage({ id: "common_delete" })}
                                </Typography>
                            </StyledMenuItem>,
                        ]}
                    />
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    if (!partnerId) {
        return
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_file_manager",
            })}>
            <DefaultWrapperPaper>
                <AllInOneTable<FileInfo>
                    headers={[
                        {
                            key: "description",
                            label: intl.formatMessage({ id: "common_description" }),
                            excludeFromSearch: true,
                        },
                        {
                            key: "file_name",
                            label: intl.formatMessage({ id: "common_file_name" }),
                            excludeFromSearch: true,
                        },

                        { key: "actions", label: "", excludeFromSearch: true },
                    ]}
                    itemsToRow={mapFileToRow}
                    defaultSortBy={"id"}
                    fetchItems={fetchItems}
                    initialLoad={inititalLoad}
                    setInitialLoad={setInitialLoad}
                    addFunction={() => navigate("add")}
                />
            </DefaultWrapperPaper>
            {fileToDelete ? (
                <YesOrNoModal
                    negativeAction={() => {
                        setFileToDelete(null)
                    }}
                    title={intl.formatMessage(
                        { id: "Are_you_to_delete" },
                        { filename: fileToDelete.file_name },
                    )}
                    open={true}
                    onClose={() => {
                        setFileToDelete(null)
                    }}
                    positiveAction={deleteFile}
                    contentString={""}></YesOrNoModal>
            ) : null}
        </Page>
    )
}
