import { Box, styled } from "@mui/material"
import { useIntl } from "react-intl"
import { ReactComponent as DropPoint } from "../assets/icons/DropPoint-Circle.svg"
import { ReactComponent as Partner } from "../assets/icons/Partner-circle.svg"
import { ReactComponent as REZIP } from "../assets/icons/Re-ZIP-Circle.svg"
import { ReactComponent as Shop } from "../assets/icons/Shop-circle.svg"
import { ReactComponent as AccountIcon } from "../assets/icons/Account-Icon.svg"
import { ReactComponent as ActionGreenAccountIcon } from "../assets/icons/ActionGreenAccount-icon.svg"
import { actionGreen } from "src/components/colors"

export const GetName = (type: string) => {
    const intl = useIntl()
    switch (type) {
        case "RE-ZIP":
            return intl.formatMessage({ id: "common_name_re_zip" })
        case "Partner":
            return intl.formatMessage({ id: "common_name_partner" })
        case "DropPoint":
            return intl.formatMessage({ id: "common_name_drop_point" })
        case "Shop":
            return intl.formatMessage({ id: "common_name_shop" })
        default:
            return ""
    }
}
const AccountItemBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "8px",
})

export const GetIconHelper = (type: string) => {
    switch (type) {
        case "RE-ZIP":
            return (
                <AccountItemBox>
                    <ActionGreenAccountIcon style={{ zIndex: 2, marginBottom: "-50px" }} />
                    <REZIP />
                </AccountItemBox>
            )
        case "Partner":
            return (
                <AccountItemBox>
                    <AccountIcon
                        style={{
                            zIndex: 2,
                            stroke: actionGreen,
                            marginBottom: "-50px",
                        }}
                    />
                    <Partner />
                </AccountItemBox>
            )
        case "DropPoint":
            return (
                <AccountItemBox>
                    <AccountIcon
                        style={{
                            zIndex: 2,
                            stroke: actionGreen,
                            marginBottom: "-50px",
                        }}
                    />

                    <DropPoint />
                </AccountItemBox>
            )
        case "Shop":
            return (
                <AccountItemBox>
                    <AccountIcon
                        style={{
                            zIndex: 2,
                            stroke: actionGreen,
                            marginBottom: "-50px",
                        }}
                    />
                    <Shop />
                </AccountItemBox>
            )
    }
}
