import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Page from "src/components/layout/Page"
import { useUser } from "src/contexts/UserConsumer"

import { AddressView } from "src/components/Address"
import { SelectChangeEvent, Switch, Typography, Grid } from "@mui/material"
import OrderLinesTable from "src/components/orderComponents/OrderLinesTable"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { useIntl } from "react-intl"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { OrderPartner, Shop } from "@repo/rezip-client/partner_client"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import { useReactToPrint } from "react-to-print"
import dayjs from "dayjs"
import Invoice from "src/components/Invoice"
import { OrderConfig } from "src/components/shopComponents/OrderConfiguration"

export function OrderPage() {
    const intl = useIntl()
    const orderStateMenuItems: IMenuItemData[] = useMemo(
        () => [
            { id: "0", value: intl.formatMessage({ id: "orders_dropdown_open" }) },
            { id: "1", value: intl.formatMessage({ id: "orders_dropdown_fulfilled" }) },
            { id: "2", value: intl.formatMessage({ id: "orders_dropdown_invoiced" }) },
            { id: "3", value: intl.formatMessage({ id: "orders_dropdown_paid" }) },
        ],
        [intl],
    )
    const { getSelectedAgreement, getToken } = useUser()
    const { orderId } = useParams()
    const [order, setOrder] = useState<OrderPartner>()
    const [shop, setShop] = useState<Shop>()
    const [editState, setEditState] = useState(false)
    const [orderState, setOrderState] = useState(orderStateMenuItems[0])
    const [tempOrderState, setTempOrderState] = useState(orderStateMenuItems[0])
    const { partnerClient } = useClient()
    const navigation = useNavigate()
    const { enqueueAlert } = useSnackbar()
    const partnerId = getSelectedAgreement()?.account.id
    const contentToPrint = useRef(null)
    const [invoice, setInvoice] = useState<ReactNode | null>(null)
    const handlePrint = useReactToPrint({
        documentTitle: intl.formatMessage(
            { id: "common_order_note" },
            { date: dayjs().format("YYYY-MM-DD") },
        ),
        contentRef: contentToPrint,
    })
    const [shouldPrint, setShouldPrint] = useState(false)
    const [orderConfig, setOrderConfig] = useState<OrderConfig>()

    useEffect(() => {
        if (!partnerId) {
            return
        }

        async function fetchShopInfo(shopId: string) {
            if (!partnerId) {
                return
            }
            const res = await partnerClient.getShop({ partnerId: partnerId, shopId: shopId })
            const config = await partnerClient.getShopOrdering({
                partnerId: partnerId,
                shopId: shopId,
            })

            if (res) {
                setShop(res)
            }
            if (config) {
                setOrderConfig(config)
            }
        }

        async function fetchOrder() {
            if (!partnerId || !orderId) {
                return
            }
            const res = await partnerClient.getOrder({ partnerId: partnerId, orderId: orderId })

            if (res) {
                setOrder(res)
                fetchShopInfo(res.shop_id)

                const state = orderStateMenuItems.find((item) => Number(item.id) === res.state)

                if (state) {
                    setOrderState(state)
                    setTempOrderState(state)
                }
            }
        }
        fetchOrder()
    }, [getSelectedAgreement, getToken, orderId, orderStateMenuItems, partnerClient, partnerId])

    const onStateChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const state = orderStateMenuItems.find(
                (item) => Number(item.id) === Number(event.target.value),
            )
            if (state) {
                setTempOrderState(state)
            }
        },
        [orderStateMenuItems],
    )

    const updateOrderState = useCallback(async () => {
        const partnerId = getSelectedAgreement()?.account.id
        if (!partnerId || !orderId) {
            enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
            return
        }
        const res = await partnerClient.patchOrder({
            partnerId,
            orderId,
            body: { state: Number(tempOrderState.id) },
        })
        setOrderState(tempOrderState)
        setOrder(res)

        enqueueAlert(intl.formatMessage({ id: "toast_order_status_updated" }), "success")
    }, [enqueueAlert, getSelectedAgreement, intl, orderId, tempOrderState, partnerClient])
    useEffect(() => {
        if (invoice && shouldPrint) {
            handlePrint()
            setShouldPrint(false)
        }
    }, [handlePrint, invoice, shouldPrint])

    const agreement = getSelectedAgreement()

    const showFulfillButton = useMemo(() => {
        if (!agreement) {
            return false
        }

        if (agreement.owner) {
            return true
        }

        const managedGroups = agreement.permission_groups.filter(
            (item) => item.name === "Warehouse" && item.type === "managed",
        )

        const aclGroups = agreement.permission_groups.filter((item) =>
            item.acl_permissions.some(
                (acl) =>
                    acl.resource === "/partners/:id/orders/:order_id/fulfill" && acl.post === true,
            ),
        )

        return managedGroups.length === 1 || aclGroups.length >= 1
    }, [agreement])

    return (
        <Page backButton title={intl.formatMessage({ id: "menu_listitem_orders" })}>
            <DefaultWrapperPaper>
                <Grid container direction="column" spacing={2}>
                    <Grid container justifyContent="space-between">
                        <Typography variant="h2">{shop?.name}</Typography>
                        <Grid container spacing={2}>
                            {order && order?.state >= 1 ? (
                                <CustomButton
                                    text={intl.formatMessage({ id: "common_print_order" })}
                                    onClick={async () => {
                                        if (!partnerId) {
                                            return
                                        }

                                        const shop = await partnerClient.getShop({
                                            partnerId: partnerId,
                                            shopId: order.shop_id,
                                        })
                                        const partner = await partnerClient.getPartner(partnerId)
                                        if (shop && partner) {
                                            setInvoice(
                                                <Invoice
                                                    partner={partner}
                                                    shop={shop}
                                                    order={order}
                                                />,
                                            )
                                            setShouldPrint(true)
                                        }
                                    }}
                                />
                            ) : null}
                            {showFulfillButton && order && order.state < 1 ? (
                                <CustomButton
                                    text={intl.formatMessage({
                                        id: "orders_fulfill_button",
                                    })}
                                    onClick={() => navigation(`/warehouse/fulfill/${order.id}`)}
                                />
                            ) : null}
                            {shop ? (
                                <CustomButton
                                    text={intl.formatMessage({ id: "orders_go_to_shop" })}
                                    onClick={() =>
                                        navigation(`/administration/shops/view/${shop.id}`)
                                    }
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Typography>{intl.formatMessage({ id: "orders_order_number" })}</Typography>
                        <Grid>{order?.number}</Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                        <Grid size={4}>
                            <Grid
                                container
                                alignItems="center"
                                spacing={5}
                                sx={{ flexWrap: "nowrap" }}>
                                <Typography variant="body2">
                                    {intl.formatMessage({ id: "orders_update_status" })}
                                </Typography>
                                <Switch onClick={() => setEditState(!editState)} />
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                spacing={5}
                                sx={{ flexWrap: "nowrap" }}>
                                <Typography variant="body2">
                                    {intl.formatMessage({ id: "orders_order_status" })}
                                </Typography>
                                <Grid container spacing={2} sx={{ flexWrap: "nowrap" }}>
                                    <SelectField
                                        value={!editState ? orderState.id : tempOrderState.id}
                                        onChange={onStateChange}
                                        menuItems={orderStateMenuItems}
                                        disabled={!editState}
                                    />

                                    {editState && (
                                        <CustomButton
                                            onClick={updateOrderState}
                                            text={intl.formatMessage({
                                                id: "common_update",
                                            })}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        {shop && (
                            <Grid container direction="column" size={4} spacing={1}>
                                <Typography sx={{ fontWeight: "800" }}>
                                    {intl.formatMessage({ id: "billing_address" })}
                                </Typography>
                                <AddressView address={shop.billing_address} />
                            </Grid>
                        )}
                        {shop && (
                            <Grid container direction="column" size={4} spacing={1}>
                                <Typography sx={{ fontWeight: "800" }}>
                                    {intl.formatMessage({ id: "shipping_address" })}
                                </Typography>
                                <AddressView address={shop.shipping_address} />
                            </Grid>
                        )}
                    </Grid>
                    {order && (
                        <Grid>
                            <OrderLinesTable
                                currency={orderConfig ? orderConfig?.currency || null : null}
                                lines={order.lines}
                            />
                        </Grid>
                    )}
                    <Grid sx={{ display: "none" }}>
                        <Grid ref={contentToPrint}>{invoice}</Grid>
                    </Grid>
                </Grid>
            </DefaultWrapperPaper>
        </Page>
    )
}
