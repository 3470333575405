import { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { useUser } from "src/contexts/UserConsumer"
import { useNavigate, useSearchParams } from "react-router-dom"
import loginImage from "../assets/images/LoginImage.jpg"
import { useIntl } from "react-intl"
import { IconButton, InputAdornment, styled } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { ReactComponent as Logo } from "../assets/images/RE-ZIP-logo.svg"
import { useClient } from "src/hooks/useClient.hook"
import { CustomButton, ButtonType } from "src/components/generalComponents/Buttons"

const ThemeLogo = styled(Logo)(({ theme }) => ({
    filter: "brightness(0%)",
    height: "150px",
    width: "150px",
    ...theme.applyStyles("dark", {
        filter: "brightness(100%)",
    }),
}))

export const EmailVerify = () => {
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const { refreshUser, removeUser, login, logout, setImposter } = useUser()
    const { anonymousClient } = useClient()
    const intl = useIntl()
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = () => setShowPassword(!showPassword)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const email = data.get("email")?.toString()
        const password = data.get("password")?.toString()
        const otp = data.get("otp")?.toString()

        if (!email || !password || !otp) {
            return
        }

        await anonymousClient.activate({
            email,
            password,
            otp,
        })

        const token = await login({
            email,
            password,
        })

        const successfullyFetchedUser = await refreshUser(token)
        if (successfullyFetchedUser) {
            navigate("/home")
        }
    }

    useEffect(() => {
        removeUser()
        logout()
        setImposter(null)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Empty dependency makes the effect run only once

    return (
        <Grid container sx={{ height: "100vh", backgroundColor: "secondaryBackground.main" }}>
            <Grid
                alignContent="flex-start"
                display={{ xs: "none", lg: "flex" }}
                sx={{ marginRight: "10%", overflow: "hidden" }}>
                <img style={{ maxHeight: "100vh" }} src={loginImage} />
                <Grid sx={{ zIndex: "4", top: "93%", right: 100, position: "relative" }}>
                    <Logo />
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "inherit",
                            color: "#f6f6f6",
                        }}>
                        Manager
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                spacing={2}
                margin={{ xs: "0 auto", lg: "0" }}
                justifyContent="center"
                sx={{ maxWidth: "80vw", width: "380px" }}>
                <Grid display={{ xs: "flex", lg: "none" }} sx={{ margin: "0 auto" }}>
                    <ThemeLogo />
                </Grid>
                <Grid container direction="column">
                    <Typography component="h1" variant="h2" sx={{ color: "text.primary" }}>
                        Create User
                    </Typography>
                    <Grid
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ width: "100%" }}>
                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "common_email" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiInputBase-root-input": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            margin="normal"
                            required
                            defaultValue={params.get("email")}
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                        />
                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "common_otp" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiInputBase-root-input": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            margin="normal"
                            required
                            defaultValue={params.get("token")}
                            fullWidth
                            id="otp"
                            name="otp"
                            autoComplete="otp"
                        />
                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "common_password" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiOutlinedInput": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            autoFocus
                        />
                        <Grid container direction="column" alignItems="flex-end">
                            <CustomButton
                                type={ButtonType.submit}
                                text={intl.formatMessage({
                                    id: "login",
                                    defaultMessage: "Log in",
                                })}
                                styles={{ marginTop: "10px", width: "120px" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
