import { Box, IconButton, Menu, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useNavigate } from "react-router-dom"
import { DropPointLocation } from "@repo/rezip-client/drop_point_client"
import { formatAddress } from "src/utils/formatAddress"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { useClient } from "src/hooks/useClient.hook"
import EditIcon from "@mui/icons-material/Edit"
import { StyledMenuItem } from "src/components/styles"
import GenericModal from "src/components/modals/GenericModal"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"
import { TrashCan } from "src/components/TrashCan"
interface CustomDropDownProps {
    deleteItem: () => void
    edit: () => void
}
export const CustomIconMenu = (props: CustomDropDownProps) => {
    const { edit, deleteItem } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const intl = useIntl()
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon sx={{ cursor: "pointer" }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <StyledMenuItem onClick={edit}>
                    <EditIcon />
                    {intl.formatMessage({ id: "common_edit" })}
                </StyledMenuItem>
                <StyledMenuItem sx={{ color: "warning.main" }} onClick={deleteItem}>
                    <TrashCan height={25} width={25} />
                    {intl.formatMessage({ id: "common_delete" })}
                </StyledMenuItem>
            </Menu>
        </>
    )
}

const DropPoint = () => {
    const [initialLoad, setInitialLoad] = useState<boolean>(true)
    const { getSelectedAgreement } = useUser()
    const id = getSelectedAgreement()?.account.id
    const { dropPointClient } = useClient()
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()
    const intl = useIntl()
    const [itemToDelete, setItemToDelete] = useState<DropPointLocation | null>(null)

    const deleteLocation = useCallback(
        async (locationId?: string) => {
            if (!id || !locationId) {
                return
            }
            dropPointClient.deleteLocation({ dropPointId: id, locationId })

            enqueueAlert(intl.formatMessage({ id: "location_has_been_deleted" }), "success")
            setItemToDelete(null)
            setInitialLoad(true)
        },
        [dropPointClient, enqueueAlert, id, intl],
    )

    const itemToRow = useCallback(
        (item: DropPointLocation, index: number) => {
            return (
                <StyledBodyRow key={index}>
                    <StyledTableCell>{item.name}</StyledTableCell>
                    <StyledTableCell>{formatAddress(item.billing_address)}</StyledTableCell>
                    <StyledTableCell>{formatAddress(item.shipping_address)}</StyledTableCell>
                    <StyledTableCell>
                        <CustomIconMenu
                            deleteItem={() => setItemToDelete(item)}
                            edit={() => navigate(`${item.id}/edit`)}
                        />
                    </StyledTableCell>
                </StyledBodyRow>
            )
        },
        [navigate],
    )

    if (!id) {
        return <></>
    }

    return (
        <Page title={intl.formatMessage({ id: "menu_list_item_droppoints" })}>
            <DefaultWrapperPaper>
                <AllInOneTable<DropPointLocation>
                    headers={[
                        { key: "name", label: intl.formatMessage({ id: "common_name" }) },
                        {
                            key: "billing_address",
                            label: intl.formatMessage({
                                id: "billing_address",
                            }),
                            excludeFromSearch: true,
                        },
                        {
                            key: "shipping_address",
                            label: intl.formatMessage({
                                id: "shipping_address",
                            }),
                            excludeFromSearch: true,
                        },
                        { key: "actions", label: "", excludeFromSearch: true },
                    ]}
                    itemsToRow={itemToRow}
                    fetchItems={async (o) => {
                        return await dropPointClient.locations(id, o)
                    }}
                    defaultSortBy="id"
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    addFunction={() => navigate("add")}
                />
            </DefaultWrapperPaper>
            <GenericModal
                open={!!itemToDelete}
                CustomContent={
                    <Box>
                        <Typography>
                            {intl.formatMessage(
                                { id: "generic_do_you_want_to_delete" },
                                { item: itemToDelete?.name },
                            )}
                        </Typography>
                    </Box>
                }
                CustomActions={
                    <Box>
                        <CustomButton
                            text={intl.formatMessage({ id: "common_no" })}
                            colorTheme={ColorThemeName.noBackground}
                            onClick={() => setItemToDelete(null)}
                        />
                        <CustomButton
                            text={intl.formatMessage({ id: "common_yes" })}
                            onClick={() => deleteLocation(itemToDelete?.id)}
                        />
                    </Box>
                }
                title={""}
                onClose={() => setItemToDelete(null)}
            />
        </Page>
    )
}

export default DropPoint
