import { z } from "zod"
import {
    baseApiErrorHandling,
    defaultQueringOptions,
    OptionalQueryingOptions,
    ReZipClient,
} from "."
import { Address, Assortment, date, PatchType, ULID } from "./types"
import { PermissionGroupClient } from "./permission_group_client"

export const Partner = z.object({
    name: z.string(),
    description: z.string().nullable(),
    phone: z.string().nullable(),
    vat_no: z.string().nullable(),
    website: z.string().nullable(),
    support_email: z.string().nullable(),
    support_phone: z.string().nullable(),
    billing_email: z.string().nullable(),
    billing_address: Address,
    created_at: date,
    updated_at: date,
    id: z.string(),
})
export const CreatePartner = z.object({
    name: z.string(),
    description: z.string().nullable(),
    phone: z.string().nullable().optional(),
    vat_no: z.string().nullable().optional(),
    website: z.string().nullable().optional(),
    support_email: z.string().nullable().optional(),
    support_phone: z.string().nullable().optional(),
    billing_email: z.string().nullable().optional(),
    billing_address: Address.optional(),
    created_at: date.nullable(),
    updated_at: date.nullable(),
})

export const MotherShipProduct = z.object({
    id: z.string(),
    active: z.boolean(),
    group_id: z.string(),
    anonymous: z.boolean(),
    currency: z.string(),
    dimension: z.object({
        depth: z.number().nullable(),
        height: z.number().nullable(),
        width: z.number().nullable(),
    }),

    name: z.string(),
    price: z.number(),
    sku: z.string(),
    volume: z.number().nullable().optional(),
    weight: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    bundle_size: z.number().nullable().optional(),
    created_at: date,
    updated_at: date,
    deleted_at: date.nullable(),
    primary_material: z.string().nullable(),
    surface_area: z.number().nullable(),
})
export type MotherShipProduct = z.infer<typeof MotherShipProduct>

export type Partner = z.infer<typeof Partner>
export type CreatePartner = z.infer<typeof CreatePartner>

export const ProductGroup = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    created_at: date,
    updated_at: date,
    deleted_at: date.nullable(),
})

export type ProductGroup = z.infer<typeof ProductGroup>

export class MotherShipClient {
    rezipClient: ReZipClient
    constructor(rezipClient: ReZipClient) {
        this.rezipClient = rezipClient
    }
    permissionGroups(partnerId: ULID): PermissionGroupClient {
        return new PermissionGroupClient(this.rezipClient, `re-zip/${partnerId}`)
    }

    async getPartners(options: OptionalQueryingOptions): Promise<Partner[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)

        const response = await this.rezipClient.get({
            path: `/re-zip/partners`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Partner.array().parse(body)
    }

    async getPartner(id: ULID): Promise<Partner | null> {
        const response = await this.rezipClient.get({
            path: `/re-zip/partners/${id}`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Partner.parse(body)
    }

    async patchPartner(partnerId: ULID, args: PatchType<Partner>): Promise<Partner | null> {
        const response = await this.rezipClient.patch({
            path: `/re-zip/partners/${partnerId}`,
            body: JSON.stringify(args),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Partner.parse(body)
    }

    async postPartner(userEmail: string, args: PatchType<Partner>): Promise<Partner | null> {
        const response = await this.rezipClient.post({
            path: "/re-zip/partners",
            body: JSON.stringify({ user_email: userEmail, ...args }),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Partner.parse(body)
    }

    async getProducts(options: OptionalQueryingOptions): Promise<MotherShipProduct[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: "/re-zip/products",
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return MotherShipProduct.array().parse(body)
    }

    async getProduct(id: ULID): Promise<MotherShipProduct | null> {
        const response = await this.rezipClient.get({
            path: `/re-zip/products/${id}`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return MotherShipProduct.parse(body)
    }

    async patchProduct(id: ULID, item: PatchType<ProductGroup>): Promise<MotherShipProduct | null> {
        const response = await this.rezipClient.patch({
            path: `/re-zip/products/${id}`,
            body: JSON.stringify(item),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return MotherShipProduct.parse(body)
    }

    async postProduct(item: PatchType<ProductGroup>): Promise<MotherShipProduct | null> {
        const response = await this.rezipClient.post({
            path: `/re-zip/products`,
            body: JSON.stringify(item),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return MotherShipProduct.parse(body)
    }
    async deleteProduct(id: ULID): Promise<boolean | null> {
        const response = await this.rezipClient.delete({ path: `/re-zip/products/${id}` })
        await baseApiErrorHandling(response)

        return true
    }

    async getProductGroups(options: OptionalQueryingOptions): Promise<ProductGroup[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: "/re-zip/product_groups",
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return ProductGroup.array().parse(body)
    }
    async getProductGroup(groupId: ULID): Promise<ProductGroup | null> {
        const response = await this.rezipClient.get({
            path: `/re-zip/product_groups/${groupId}`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return ProductGroup.parse(body)
    }

    async postProductGroup(item: PatchType<ProductGroup>): Promise<ProductGroup | null> {
        const response = await this.rezipClient.post({
            path: `/re-zip/product_groups`,
            body: JSON.stringify(item),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return ProductGroup.parse(body)
    }

    async patchProductGroup(id: ULID, item: PatchType<ProductGroup>): Promise<ProductGroup | null> {
        const response = await this.rezipClient.patch({
            path: `/re-zip/product_groups/${id}`,
            body: JSON.stringify(item),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return ProductGroup.parse(body)
    }

    async deleteProductGroup(id: ULID): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `/re-zip/product_groups/${id}`,
        })
        await baseApiErrorHandling(response)
        return true
    }

    async getPartnerAssortment(
        partnerId: ULID,
        options: OptionalQueryingOptions,
    ): Promise<Assortment[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `/re-zip/partners/${partnerId}/assortment`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Assortment.array().parse(body)
    }

    async patchPartnerAssortment(
        partnerId: ULID,
        assortmentId: ULID,
        item: PatchType<Assortment>,
    ): Promise<Assortment | null> {
        const response = await this.rezipClient.patch({
            path: `/re-zip/partners/${partnerId}/assortment/${assortmentId}`,
            body: JSON.stringify(item),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Assortment.parse(body)
    }
    async postAssortmentToPartner(
        partnerId: ULID,
        item: PatchType<Assortment>,
    ): Promise<Assortment | null> {
        const response = await this.rezipClient.post({
            path: `re-zip/partners/${partnerId}/assortment`,
            body: JSON.stringify(item),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Assortment.parse(body)
    }

    async deleteAssortmentFromPartner(
        partnerId: ULID,
        assortmentId: ULID,
    ): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `re-zip/partners/${partnerId}/assortment/${assortmentId}`,
        })
        await baseApiErrorHandling(response)
        return true
    }
}
