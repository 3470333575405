import { useMemo } from "react"

import { useReferenceCalculator } from "src/utils/referenceCalculator"

import { useReportController } from "../administration/reports/reports.controller"

export const useFrontPageController = () => {
    const { ReferenceCalculator } = useReferenceCalculator()

    const { simpleProducts, summedEnvStats } = useReportController()

    const calculatedReferenceValues = useMemo(() => {
        return ReferenceCalculator(summedEnvStats, simpleProducts)
    }, [ReferenceCalculator, simpleProducts, summedEnvStats])

    const savingsTotal = useMemo(() => {
        if (summedEnvStats && calculatedReferenceValues) {
            return {
                co2: (calculatedReferenceValues.co2 - summedEnvStats?.totalCo2).toFixed(2),

                water: (calculatedReferenceValues.water - summedEnvStats.totalWater).toFixed(2),

                waste: (calculatedReferenceValues.waste - summedEnvStats.totalWaste).toFixed(2),
            }
        }
    }, [calculatedReferenceValues, summedEnvStats])

    return { savingsTotal }
}
