import { ListItemIcon, MenuItem, styled } from "@mui/material"
import { actionGreen } from "./colors"
import { DefaultWrapperPaper } from "./layout/DefaultPageComponents"

export const StyledP = styled("p")({
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px",
})
export const StyledStrong = styled("strong")({
    letterSpacing: "0.00938em",
})

export const DefaultChartPaper = styled(DefaultWrapperPaper)({
    padding: "4px",
})

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: "16px",
    gap: "8px",
    alignItems: "center",
    justifyContent: "flex-start",
    ...theme.applyStyles("dark", {
        fontWeight: "500",
    }),
}))

export const ItemIcon = styled(ListItemIcon)({
    minWidth: "30px",
    marginRight: "3px",
    ".MuiListItemIcon-root": {
        minWidth: "30px",
    },
    color: actionGreen,
})

export const PageDescription = styled("div")({
    maxWidth: "800px",
    marginBottom: "20px",
    fontWeight: "400",
    lineHeight: "28px",
})

export const DropdownMenuItem = styled(MenuItem)({
    fontSize: "16px",
    fontWeight: "400",
})

export const FieldLabel = styled("span")(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: "500",
    ...theme.applyStyles("dark", {
        fontWeight: "600",
    }),
}))
