import { z } from "zod"
import {
    baseApiErrorHandling,
    defaultQueringOptions,
    OptionalQueryingOptions,
    ReZipClient,
} from "."
import {
    Address,
    Assortment,
    Assortments,
    BaseShop,
    date,
    PatchType,
    Product,
    Products,
    ULID,
    UserAgreementAccount,
} from "./types"
import { PermissionGroupClient } from "./permission_group_client"
import { ReportingClient } from "./reporting_client"

export const Partner = z.object({
    name: z.string(),
    description: z.string().nullable(),
    phone: z.string().nullable(),
    vat_no: z.string().nullable(),
    website: z.string().nullable(),
    support_email: z.string().nullable(),
    support_phone: z.string().nullable(),
    billing_email: z.string().nullable(),
    billing_address: Address,
    created_at: date,
    updated_at: date,
})

export type Partner = z.infer<typeof Partner>

export const Shop = BaseShop.extend({ external_id: z.string().nullable().optional() })

export type Shop = z.infer<typeof Shop>
const Shops = z.array(Shop)
export type Shops = z.infer<typeof Shops>

const OrderLine = z.object({
    id: z.string(),
    sku: z.string(),
    name: z.string(),
    dimensions: z.string(),
    price: z.number(),
    quantity: z.number(),
    fulfilled: z.number(),
})
export type OrderLine = z.infer<typeof OrderLine>
const OrderLines = z.array(OrderLine)
export type OrderLines = z.infer<typeof OrderLines>
const OrderPartner = z.object({
    external_id: z.string().nullable(),
    id: z.string(),
    shop_id: z.string(),
    state: z.number(),
    number: z.number(),
    lines: OrderLines,
    created_at: date,
    updated_at: date,
})
export type OrderPartner = z.infer<typeof OrderPartner>
const OrdersPartner = z.array(OrderPartner)
export type OrdersPartner = z.infer<typeof OrdersPartner>

const OrderWithShopName = OrderPartner.extend({ shopName: z.string() })
export type OrderWithShopName = z.infer<typeof OrderWithShopName>

const FileInfo = z.object({
    id: z.string(),
    file_name: z.string(),
    description: z.string(),
    mime: z.string(),
    created_at: date,
    updated_at: date,
    updated: z.coerce.boolean(),
})
export type FileInfo = z.infer<typeof FileInfo>

const files = z.array(FileInfo)
const StorageUnit = z.object({
    id: z.string(),
    account_id: z.string(),
    location_id: z.string().nullable(),
    station_id: z.string().nullable(),
    barcode: z.string(),
    packaging: z.array(z.string()),
    type: z.string(),
    sku: z.string(),
    size: z.number(),
    quantity: z.number(),
    completed: z.boolean(),
    location: z.string().nullable(),
    created_at: date,
    updated_at: date,
    anonymous: z.boolean(),
})
const StorageUnitPatchType = z.object({
    storage_unit_template_id: z.string().nullable(),
    barcode: z.string(),
    sku: z.string(),
    location: z.string().nullable(),
    station_id: z.string().nullable(),
})

const StorageUnits = z.array(StorageUnit)

export type StorageUnit = z.infer<typeof StorageUnit>
export type StorageUnits = z.infer<typeof StorageUnits>
export type StorageUnitPatchType = z.infer<typeof StorageUnitPatchType>

const ProductWithQuant = Product.extend({ quantity: z.number() })
export type ProductWithQuant = z.infer<typeof ProductWithQuant>

const StorageUnitTemplate = z.object({
    id: z.string(),
    account_id: z.string(),
    type: z.string(),
    config: z
        .object({
            sku: z.string(),
            size: z.number(),
        })
        .array(),
    created_at: date,
    updated_at: date,
    deleted_at: z.string().optional(),
})
export type StorageUnitTemplate = z.infer<typeof StorageUnitTemplate>

const StorageUnitTemplates = z.array(StorageUnitTemplate)
export type StorageUnitTemplates = z.infer<typeof StorageUnitTemplates>

const SettingsOrdering = z.object({
    currency: z
        .string()
        .nullish()
        .transform((x) => x ?? ""),
    shipping_base_cost: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    shipping_unit_cost: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    vat: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
})
export type SettingsOrdering = z.infer<typeof SettingsOrdering>

const Permission = z.object({
    resource: z.string(),
    get: z.boolean(),
    put: z.boolean(),
    post: z.boolean(),
    patch: z.boolean(),
    delete: z.boolean(),
})
const Permissions = z.array(Permission)

const Account = z.object({
    id: z.string(),
    type: z.string(),
    name: z.string(),
})

const Agreement = z.object({
    id: z.string(),
    accepted: z.boolean(),
    owner: z.boolean(),
    account: Account.optional(),
    aclPermissions: Permissions.optional(),
    user: z
        .object({
            name: z.string().nullable(),
            email: z.string().nullable(),
        })
        .optional(),
    created_at: date.optional().nullable(),
    expires_at: date.optional().nullable(),
    mfaRequired: z.boolean().optional(),
})

export type Agreement = z.infer<typeof Agreement>
const Agreements = z.array(Agreement)
export type Agreements = z.infer<typeof Agreements>
const Location = z.object({
    id: z.string(),
    name: z.string(),
    address: Address,
    latitude: z.coerce.number(),
    longitude: z.coerce.number(),
    created_at: date,
    updated_at: date,
})
const AgreementPostType = z.object({ email: z.string(), owner: z.boolean() })
export type AgreementPostType = z.infer<typeof AgreementPostType>

export type Location = z.infer<typeof Location>
const Locations = z.array(Location)
export type Locations = z.infer<typeof Locations>

const Station = z.object({
    id: z.string(),
    partner_location_id: z.string(),
    name: z.string(),
    storage_unit_auto_add: z.boolean(),
    created_at: date,
    deleted_at: date.optional().nullable(),
    updated_at: date,
})
export type StationType = z.infer<typeof Station>

const Bundle = z.object({
    id: z.string(),
    size: z.number(),
    packaging_station_id: z.string(),
    packaging: z.array(z.string()),
    completed: z.boolean(),
    created_at: date,
    updated_at: date,
})
export type Bundle = z.infer<typeof Bundle>

export const Packaging = z.object({
    id: z.string(),
    barcode: z.string(),
    condition: z.number(),
    created_at: z.string(),
    updated_at: date,
    deleted_at: date.optional(),
})
export type Packaging = z.infer<typeof Packaging>

export const ScanSummary = z.array(
    z.object({
        sku: z.string(),
        items: z.array(
            z.object({
                type: z.string(),
                scans: z.number(),
            }),
        ),
    }),
)
export type ScanSummary = z.infer<typeof ScanSummary>

export const BundleScan = z.object({
    id: z.string(),
    partner_location_id: z.string().nullable(),
    packaging_station_id: z.string().nullable(),
    shop_id: z.string().nullable(),
    order_id: z.string().nullable(),
    type: z.string().nullable(),
    barcode: z.string(),
    condition: z.number(),
    circulations: z.number(),
    latitude: z.number().nullable(),
    longitude: z.number().nullable(),
    created_at: date,
})
export type BundleScan = z.infer<typeof BundleScan>

export const BundleLookup = z.object({
    packaging: Packaging,
    bundle: Bundle.nullable(),
    scan: BundleScan.array(),
})
export type BundleLookup = z.infer<typeof BundleLookup>

export class PartnerClient {
    rezipClient: ReZipClient
    constructor(rezipClient: ReZipClient) {
        this.rezipClient = rezipClient
    }

    permissionGroups(partnerId: ULID): PermissionGroupClient {
        return new PermissionGroupClient(this.rezipClient, `partners/${partnerId}`)
    }

    reporting(partnerId: ULID): ReportingClient {
        return new ReportingClient(this.rezipClient, `partners/${partnerId}`)
    }

    async get(id: ULID): Promise<Partner | null> {
        const response = await this.rezipClient.get({ path: `partners/${id}` })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Partner.parse(body)
    }

    async patch(id: ULID, args: PatchType<Partner>): Promise<Partner | null> {
        const response = await this.rezipClient.patch({
            path: `partners/${id}`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Partner.parse(body)
    }

    async scansSummary({
        partnerId,
        from,
        to,
        shopId,
    }: {
        partnerId: ULID
        from: Date
        to: Date
        shopId?: ULID
    }): Promise<ScanSummary> {
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/scans/summary`,
            queryParams: {
                page_size: 9999999,
                filter: {
                    created_at: `[]${from.toISOString()},${to.toISOString()}`,
                    ...(shopId ? { shop_id: shopId } : undefined),
                },
            },
        })
        await baseApiErrorHandling(response)
        return ScanSummary.parse(await response.json())
    }

    async scansRaw({
        partnerId,
        from,
        to,
        shopId,
        type,
        options,
    }: {
        partnerId: ULID
        from: Date
        to: Date
        shopId?: ULID
        type?: string
        options?: OptionalQueryingOptions
    }): Promise<Blob | null> {
        const { pageSize, pageFrom } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/scans`,
            queryParams: {
                page_size: pageSize ?? 1001,
                filter: {
                    created_at: `[]${from.toISOString()},${to.toISOString()}`,
                    ...(shopId ? { shop_id: shopId } : undefined),
                    ...(type ? { type } : undefined),
                    page_from: pageFrom,
                },
            },
            headers: {
                Accept: "application/csv",
            },
        })
        await baseApiErrorHandling(response)
        return await response.blob()
    }

    async scansRawCsv({
        partnerId,
        from,
        to,
        shopId,
        type,
        options,
    }: {
        partnerId: ULID
        from: Date
        to: Date
        shopId?: ULID
        type?: string
        options?: OptionalQueryingOptions
    }): Promise<Blob> {
        const { pageFrom } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/scans`,
            queryParams: {
                page_size: 1001,
                page_from: pageFrom,
                filter: {
                    created_at: `[]${from.toISOString()},${to.toISOString()}`,
                    ...(shopId ? { shop_id: shopId } : undefined),
                    ...(type ? { type } : undefined),
                },
            },
            headers: {
                Accept: "application/csv",
            },
        })
        await baseApiErrorHandling(response)
        return await response.blob()
    }

    async getShops(partnerId: ULID, options?: OptionalQueryingOptions): Promise<Shops | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/shops`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Shops.parse(body)
    }

    async getShop(props: { partnerId: ULID; shopId: ULID }): Promise<Shop | null> {
        const response = await this.rezipClient.get({
            path: `/partners/${props.partnerId}/shops/${props.shopId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Shop.parse(body)
    }

    async deleteShop({ partnerId, shopId }: { partnerId: ULID; shopId: ULID }): Promise<void> {
        const response = await this.rezipClient.delete({
            path: `/partners/${partnerId}/shops/${shopId}`,
        })
        await baseApiErrorHandling(response)
    }

    async updateShop(partnerId: ULID, shopId: ULID, shop: PatchType<Shop>): Promise<Shop> {
        const response = await this.rezipClient.patch({
            path: `/partners/${partnerId}/shops/${shopId}`,
            body: JSON.stringify(shop),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Shop.parse(body)
    }

    async createShop(
        partnerId: ULID,
        shop: PatchType<Shop> & { user_email: string },
    ): Promise<Shop> {
        const response = await this.rezipClient.post({
            path: `/partners/${partnerId}/shops`,
            body: JSON.stringify(shop),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Shop.parse(body)
    }

    async getOrder(props: { partnerId: ULID; orderId: ULID }): Promise<OrderPartner | null> {
        const response = await this.rezipClient.get({
            path: `/partners/${props.partnerId}/orders/${props.orderId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return OrderPartner.parse(body)
    }

    async patchOrder({
        partnerId,
        orderId,
        body,
    }: {
        partnerId: ULID
        orderId: ULID
        body: { state: number }
    }): Promise<OrderPartner> {
        const response = await this.rezipClient.patch({
            path: `/partners/${partnerId}/orders/${orderId}`,
            body: JSON.stringify(body),
        })
        await baseApiErrorHandling(response)
        return OrderPartner.parse(await response.json())
    }

    async getPackaging(
        partnerId: ULID,
        options: OptionalQueryingOptions,
    ): Promise<Packaging[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/packaging`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        return Packaging.array().parse(await response.json())
    }
    async getBundle(
        partnerId: ULID,
        packagingId: ULID,
        options?: OptionalQueryingOptions,
    ): Promise<Bundle | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/packaging/${packagingId}/bundle`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        return Bundle.parse(await response.json())
    }
    async bundleLookup(partnerId: ULID, barocde: string): Promise<BundleLookup | null> {
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/packaging/lookup/${barocde}`,
        })
        await baseApiErrorHandling(response)
        const data = await response.json()
        return BundleLookup.parse(data)
    }

    async discardPackaging({
        partnerId,
        locationId,
        barcode,
        condition,
    }: {
        partnerId: string
        locationId: string
        barcode: string
        condition: string
    }): Promise<Packaging> {
        const response = await this.rezipClient.post({
            path: `/partners/${partnerId}/packaging/discard`,
            body: JSON.stringify({
                barcode,
                partner_location_id: locationId,
                condition,
            }),
        })
        await baseApiErrorHandling(response)
        return Packaging.parse(await response.json())
    }

    async locationStock({
        partnerId,
        locationId,
    }: {
        partnerId: string
        locationId: string
    }): Promise<Record<string, number>> {
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/locations/${locationId}/stock`,
        })
        await baseApiErrorHandling(response)
        return z.record(z.string(), z.number()).parse(await response.json())
    }

    async getStorageUnits(
        partnerId: string,
        locationId: string,
        options?: OptionalQueryingOptions,
    ): Promise<StorageUnits | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)

        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/locations/${locationId}/storage_units`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        const body = await response.json()
        return StorageUnits.parse(body)
    }
    async getStorageUnitsOnStation(
        partnerId: string,
        locationId: string,
        stationId: string,
        options?: OptionalQueryingOptions,
    ): Promise<StorageUnits | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)

        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/locations/${locationId}/stations/${stationId}/storage_units`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        const body = await response.json()
        return StorageUnits.parse(body)
    }

    async getFile(props: { partnerId: ULID; fileId: ULID }): Promise<FileInfo | null> {
        const response = await this.rezipClient.get({
            path: `/partners/${props.partnerId}/files/${props.fileId}/info`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return FileInfo.parse(body)
    }

    async getProducts(partnerId: ULID, props?: OptionalQueryingOptions): Promise<Products | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(props)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/products`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Products.parse(body)
    }

    async getAssortment(
        partnerId: ULID,
        shopId: ULID,
        props?: OptionalQueryingOptions,
    ): Promise<Assortments | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(props)
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/shops/${shopId}/assortment`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Assortments.parse(body)
    }

    async updateAssortment(
        {
            partnerId,
            shopId,
            assortmentId,
        }: { partnerId: string; shopId: string; assortmentId: string },
        body: { price: number; name: string; currency: string; sku: string },
    ): Promise<Assortment> {
        const response = await this.rezipClient.patch({
            path: `partners/${partnerId}/shops/${shopId}/assortment/${assortmentId}`,
            body: JSON.stringify(body),
        })
        await baseApiErrorHandling(response)
        return Assortment.parse(await response.json())
    }

    async createAssortment(
        { partnerId, shopId }: { partnerId: string; shopId: string },
        body: { price: number; currency: string; sku: string },
    ): Promise<Assortment> {
        const response = await this.rezipClient.patch({
            path: `partners/${partnerId}/shops/${shopId}/assortment`,
            body: JSON.stringify(body),
        })
        await baseApiErrorHandling(response)
        return Assortment.parse(await response.json())
    }

    async deleteAssortment({
        partnerId,
        shopId,
        assortmentId,
    }: {
        partnerId: string
        shopId: string
        assortmentId: string
    }): Promise<void> {
        const response = await this.rezipClient.patch({
            path: `partners/${partnerId}/shops/${shopId}/assortment/${assortmentId}`,
            body: "",
        })
        await baseApiErrorHandling(response)
    }

    async updateProduct(
        { partnerId, productId }: { partnerId: ULID; productId: ULID },
        obj: { name: string; price: number; active: boolean },
    ): Promise<Product> {
        const response = await this.rezipClient.patch({
            path: `/partners/${partnerId}/products/${productId}`,
            body: JSON.stringify(obj),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Product.parse(body)
    }

    async getStorageUnitTemplate(props: {
        partnerId: ULID
        templateId: ULID
    }): Promise<StorageUnitTemplate | null> {
        const response = await this.rezipClient.get({
            path: `/partners/${props.partnerId}/settings/storage_unit_templates/${props.templateId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        const body = await response.json()
        return StorageUnitTemplate.parse(body)
    }
    async getStorageUnitTemplates(
        partnerId: ULID,
        options?: OptionalQueryingOptions,
    ): Promise<StorageUnitTemplates | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/settings/storage_unit_templates`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        const body = await response.json()
        return StorageUnitTemplates.parse(body)
    }

    async getLocations(partnerId: ULID, options?: OptionalQueryingOptions): Promise<Location[]> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/locations`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(Location).parse(body)
    }

    async getLocation(partnerId: ULID, locationId: ULID): Promise<Location | null> {
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/locations/${locationId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Location.parse(body)
    }

    async patchLocation(
        partnerId: ULID,
        locationId: ULID,
        args: PatchType<Location>,
    ): Promise<Location | null> {
        const response = await this.rezipClient.patch({
            path: `partners/${partnerId}/locations/${locationId}`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Location.parse(body)
    }

    async postLocation(partnerId: ULID, args: PatchType<Location>): Promise<Location | null> {
        const response = await this.rezipClient.post({
            path: `partners/${partnerId}/locations`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Location.parse(body)
    }

    async deleteWarehouseLocation(partnerId: ULID, locationId: ULID): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `partners/${partnerId}/locations/${locationId}`,
            body: "",
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }
    async getWarehouseLocations(
        partnerId: string,
        options?: OptionalQueryingOptions,
    ): Promise<Locations | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)

        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/locations`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        const body = await response.json()

        return Locations.parse(body)
    }

    async getShopOrdering({
        partnerId,
        shopId,
    }: {
        partnerId: string
        shopId: string
    }): Promise<SettingsOrdering> {
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/shops/${shopId}/settings/ordering`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        const parsed = SettingsOrdering.parse(body)
        return { ...parsed, vat: parsed.vat / 100 }
    }

    async setShopOrdering({
        partnerId,
        shopId,
        body,
    }: {
        partnerId: string
        shopId: string
        body: SettingsOrdering
    }): Promise<SettingsOrdering> {
        const updatedBody = { ...body, vat: body.vat * 100 }
        const response = await this.rezipClient.put({
            path: `partners/${partnerId}/shops/${shopId}/settings/ordering`,
            body: JSON.stringify(updatedBody),
        })
        await baseApiErrorHandling(response)
        const parsed = SettingsOrdering.parse(await response.json())
        return { ...parsed, vat: parsed.vat / 100 }
    }

    async getOrdering(partnerId: ULID): Promise<SettingsOrdering> {
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/settings/ordering`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        const parsed = SettingsOrdering.parse(body)
        return { ...parsed, vat: parsed.vat / 100 }
    }

    async setOrdering(partnerId: ULID, obj: SettingsOrdering): Promise<SettingsOrdering> {
        const updatedObj = { ...obj, vat: obj.vat * 100 }
        const response = await this.rezipClient.put({
            path: `partners/${partnerId}/settings/ordering`,
            body: JSON.stringify(updatedObj),
        })
        await baseApiErrorHandling(response)
        const parsed = SettingsOrdering.parse(await response.json())
        return { ...parsed, vat: parsed.vat / 100 }
    }

    async patchStorageUnitTemplate(props: {
        partnerId: ULID
        templateId: ULID
        args: PatchType<StorageUnitTemplate>
    }): Promise<StorageUnitTemplate | null> {
        const response = await this.rezipClient.patch({
            path: `/partners/${props.partnerId}/settings/storage_unit_templates/${props.templateId}`,
            body: JSON.stringify(props.args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return StorageUnitTemplate.parse(body)
    }

    async postStorageUnitTemplate(props: {
        partnerId: ULID
        args: PatchType<StorageUnitTemplate>
    }): Promise<StorageUnitTemplate | null> {
        const response = await this.rezipClient.post({
            path: `/partners/${props.partnerId}/settings/storage_unit_templates`,
            body: JSON.stringify(props.args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return StorageUnitTemplate.parse(body)
    }

    async deleteStorageUnitTemplate(props: {
        partnerId: ULID
        templateId: ULID
    }): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `/partners/${props.partnerId}/settings/storage_unit_templates/${props.templateId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }

    async getOrders(
        partnerId: ULID,
        props: OptionalQueryingOptions,
    ): Promise<OrdersPartner | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(props)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/orders`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return OrdersPartner.parse(body)
    }

    async fulfillOrder(partnerId: ULID, orderId: ULID, barcode: string): Promise<OrderLine | null> {
        const response = await this.rezipClient.post({
            path: `/partners/${partnerId}/orders/${orderId}/fulfill`,
            body: JSON.stringify({ barcode: barcode }),
        })
        await baseApiErrorHandling(response)
        return OrderLine.parse(await response.json())
    }

    getFileInfo(props: { partnerId: ULID; fileId: ULID }): string | null {
        return this.rezipClient.pathToUri(`/partners/${props.partnerId}/files/${props.fileId}`)
    }

    async getFileInfoAndContent(props: {
        partnerId: ULID
        fileId: ULID
    }): Promise<FileInfo | null> {
        const fileInfoResponse = await this.rezipClient.get({
            path: `/partners/${props.partnerId}/files/${props.fileId}/info`,
        })

        if (fileInfoResponse.status === 404) {
            return null
        }
        await baseApiErrorHandling(fileInfoResponse)

        const body = await fileInfoResponse.json()
        return FileInfo.parse(body)
    }

    async updateFile(props: {
        partnerId: string
        fileId: string
        file: File
        description: string
    }): Promise<FileInfo | null> {
        const formData = new FormData()
        formData.append("description", props.description)
        formData.append("file", props.file)

        const fileResponse = await this.rezipClient.put({
            path: `/partners/${props.partnerId}/files/${props.fileId}`,
            body: formData,
            headers: {
                "Content-Type": null,
            },
        })
        if (fileResponse.status === 404) {
            return null
        }
        await baseApiErrorHandling(fileResponse)

        const body = await fileResponse.json()
        return FileInfo.parse(body)
    }

    async postFile(props: {
        partnerId: string
        file: File
        description: string
    }): Promise<FileInfo | null> {
        const formData = new FormData()
        formData.append("description", props.description)
        formData.append("file", props.file)

        const fileResponse = await this.rezipClient.post({
            path: `/partners/${props.partnerId}/files`,
            body: formData,
            headers: {
                "Content-Type": null,
            },
        })
        await baseApiErrorHandling(fileResponse)

        const body = await fileResponse.json()
        return FileInfo.parse(body)
    }

    async getFiles(partnerId: ULID, props: OptionalQueryingOptions): Promise<FileInfo[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(props)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/files`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return files.parse(body)
    }

    async getAgreements(
        partnerId: ULID,
        options?: OptionalQueryingOptions,
    ): Promise<Agreements | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)

        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/agreements`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })

        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Agreements.parse(body)
    }

    async deleteFile(partnerId: ULID, fileId: ULID): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `/partners/${partnerId}/files/${fileId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }

    async deleteAgreement(partnerId: ULID, agreementId: ULID): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `/partners/${partnerId}/agreements/${agreementId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }

    async getStations(
        partnerId: ULID,
        locationId: ULID,
        props?: OptionalQueryingOptions,
    ): Promise<StationType[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(props)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/locations/${locationId}/stations`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(Station).parse(body)
    }

    async getStation(
        partnerId: ULID,
        locationId: ULID,
        stationId: ULID,
    ): Promise<StationType | null> {
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/locations/${locationId}/stations/${stationId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Station.parse(body)
    }

    async patchStation(
        partnerId: ULID,
        locationId: ULID,
        stationId: ULID,
        args: PatchType<StationType>,
    ): Promise<StationType | null> {
        const response = await this.rezipClient.patch({
            path: `/partners/${partnerId}/locations/${locationId}/stations/${stationId}`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Station.parse(body)
    }

    async deleteStation(
        partnerId: ULID,
        locationId: ULID,
        stationId: ULID,
    ): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `/partners/${partnerId}/locations/${locationId}/stations/${stationId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }

    async postStorageUnit(
        partnerId: ULID,
        locationId: ULID,
        args: PatchType<StorageUnit> & { storage_unit_template_id: ULID },
    ): Promise<StorageUnit | null> {
        const response = await this.rezipClient.post({
            path: `/partners/${partnerId}/locations/${locationId}/storage_units`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return StorageUnit.parse(body)
    }
    async patchStorageUnit(
        partnerId: ULID,
        locationId: ULID,
        storageUnitId: ULID,
        args: PatchType<StorageUnit>,
    ): Promise<StorageUnit | null> {
        const response = await this.rezipClient.patch({
            path: `/partners/${partnerId}/locations/${locationId}/storage_units/${storageUnitId}`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return StorageUnit.parse(body)
    }

    async getStorageUnit(
        partnerId: ULID,
        locationId: ULID,
        unitId: ULID,
    ): Promise<StorageUnit | null> {
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/locations/${locationId}/storage_units/${unitId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return StorageUnit.parse(body)
    }
    async deleteSotrageUnit(
        partnerId: ULID,
        locationId: ULID,
        unitId: ULID,
    ): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `/partners/${partnerId}/locations/${locationId}/storage_units/${unitId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        return true
    }

    async postStation(
        partnerId: ULID,
        locationId: ULID,

        args: PatchType<StationType>,
    ): Promise<StationType | null> {
        const response = await this.rezipClient.post({
            path: `/partners/${partnerId}/locations/${locationId}/stations`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Station.parse(body)
    }

    async getBundles(
        partnerId: ULID,
        locationId: ULID,
        stationId: ULID,
        options?: OptionalQueryingOptions,
    ): Promise<Bundle[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `/partners/${partnerId}/locations/${locationId}/stations/${stationId}/bundles`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return Bundle.array().parse(await response.json())
    }

    async dissloveBundle(partnerId: ULID, packagingId: ULID): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `/partners/${partnerId}/packaging/${packagingId}/bundle`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        return true
    }

    async addBundleToStorageUnit(
        partnerId: ULID,
        locationId: ULID,
        storageUnitId: ULID,
        barcode: string,
    ): Promise<StorageUnit> {
        const response = await this.rezipClient.post({
            path: `partners/${partnerId}/locations/${locationId}/storage_units/${storageUnitId}/packaging`,
            body: JSON.stringify({ barcode: barcode }),
        })
        await baseApiErrorHandling(response)
        return StorageUnit.parse(await response.json())
    }

    async removeBundleFromStorageUnit(
        partnerId: ULID,
        locationId: ULID,
        storageUnitId: ULID,
        barcode: string,
    ): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `partners/${partnerId}/locations/${locationId}/storage_units/${storageUnitId}/packaging/${barcode}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        return true
    }

    async removeBulkFromStorageUnit(
        partnerId: ULID,
        locationId: ULID,
        storageUnitId: ULID,
        barcodes: "all" | string[],
    ): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `partners/${partnerId}/locations/${locationId}/storage_units/${storageUnitId}/packaging/bulk`,
            body: JSON.stringify({ barcodes: barcodes }),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)

        return true
    }

    async postPartnerAgreement(
        partnerId: ULID,
        args: PatchType<AgreementPostType>,
    ): Promise<Agreement | null> {
        const response = await this.rezipClient.post({
            path: `partners/${partnerId}/agreements`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Agreement.parse(body)
    }

    async getUserAgreement(
        partnerId: ULID,
        agreementId: ULID,
    ): Promise<UserAgreementAccount | null> {
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}/agreements/${agreementId}`,
        })
        if (response.status === 404) {
            return null
        }
        const body = await response.json()
        return UserAgreementAccount.parse(body)
    }

    async patchUserAgreement(
        partnerId: ULID,
        agreementId: ULID,
        owner: boolean,
        mfa_required: boolean,
    ): Promise<UserAgreementAccount | null> {
        const response = await this.rezipClient.patch({
            path: `partners/${partnerId}/agreements/${agreementId}`,
            body: JSON.stringify({ owner: owner, mfa_required: mfa_required }),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return UserAgreementAccount.parse(body)
    }

    async loginAs(partnerId: ULID, shopId: ULID): Promise<UserAgreementAccount | null> {
        const response = await this.rezipClient.post({
            path: `partners/${partnerId}/shops/${shopId}/agreement`,
            body: JSON.stringify({}),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return UserAgreementAccount.parse(body)
    }

    async getPartner(partnerId: ULID): Promise<Partner | null> {
        const response = await this.rezipClient.get({
            path: `partners/${partnerId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Partner.parse(body)
    }

    async postBundle(partnerId: ULID, stationId: ULID, barcode: string): Promise<Bundle | null> {
        const response = await this.rezipClient.post({
            path: `partners/${partnerId}/packaging/bundles`,
            body: JSON.stringify({ barcode: barcode, packaging_station_id: stationId }),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Bundle.parse(body)
    }
}
