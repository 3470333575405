import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import loginImage from "../assets/images/LoginImage.jpg"
import { useIntl } from "react-intl"
import { ReactComponent as Logo } from "../assets/images/RE-ZIP-logo.svg"
import { useClient } from "src/hooks/useClient.hook"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { CustomButton, ButtonType } from "src/components/generalComponents/Buttons"
import { useNavigate } from "react-router-dom"
import { styled } from "@mui/material"

const ThemeLogo = styled(Logo)(({ theme }) => ({
    filter: "brightness(0%)",
    height: "150px",
    width: "150px",
    ...theme.applyStyles("dark", {
        filter: "brightness(100%)",
    }),
}))

// missing fonts
export default function PasswordReset() {
    const intl = useIntl()
    const { anonymousClient } = useClient()
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)

        const email = data.get("email")?.toString()
        if (email) {
            const res = await anonymousClient.passwordReset(email)
            if (res) {
                enqueueAlert(intl.formatMessage({ id: "password_reset_email_sent_toast" }), "info")
                navigate(`/reset-password/activate/${email}`)
            }
        }
    }

    return (
        <Grid container sx={{ height: "100vh", backgroundColor: "secondaryBackground.main" }}>
            <Grid
                alignContent="flex-start"
                display={{ xs: "none", lg: "flex" }}
                sx={{ marginRight: "10%", overflow: "hidden" }}>
                <img style={{ maxHeight: "100vh" }} src={loginImage} />
                <Grid sx={{ zIndex: "4", top: "93%", right: 100, position: "relative" }}>
                    <Logo />
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "inherit",
                            color: "#f6f6f6",
                        }}>
                        Manager
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={2}
                margin={{ xs: "0 auto", lg: "0" }}
                sx={{ maxWidth: "80vw", width: "380px" }}>
                <Grid display={{ xs: "flex", lg: "none" }} sx={{ margin: "0 auto" }}>
                    <ThemeLogo />
                </Grid>
                <Grid container direction="column">
                    <Typography component="h1" variant="h2" sx={{ color: "text.primary" }}>
                        {intl.formatMessage({ id: "common_reset_password" })}
                    </Typography>
                    <Grid
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ width: "100%" }}>
                        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
                            {intl.formatMessage({ id: "common_email" })}
                        </Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                marginTop: "0px",
                                "& .MuiInputBase-root": {
                                    padding: "0px",
                                },
                                "& .MuiInputBase-root-input": {
                                    padding: "0px",
                                },
                                ".MuiInputBase-input": {
                                    padding: "8px 16px",
                                },
                            }}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Grid container direction="column" alignItems="flex-end">
                            <CustomButton
                                type={ButtonType.submit}
                                text={intl.formatMessage({ id: "common_reset_password" })}
                                styles={{ marginTop: "10px" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
