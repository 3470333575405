import { Box, Collapse, Select, Typography, Grid } from "@mui/material"
import { styled } from "@mui/system"
import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import GenericModal from "src/components/modals/GenericModal"
import {
    SimplifiedVoucherBatchForm,
    SimplifiedVoucherBatchFormInner,
    VoucherPoolRecord,
} from "src/components/SimplifiedVoucherBatchForm"
import { VoucherAppPreview } from "src/components/VoucherAppPreview"
import { VoucherBatchList } from "src/components/VoucherBatchList"
import { VoucherCodesView } from "src/components/VoucherCodesView"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { ShopClient, VoucherBatch, VoucherUsage } from "@repo/rezip-client/shop_client"
import Page from "src/components/layout/Page"
import InputField from "src/components/InputField"
import StyledForm from "src/components/layout/StyledForm"
import { ArrowBox } from "src/components/generalComponents/CollapseInformation"
import { DropdownMenuItem } from "src/components/styles"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"
import YesOrNoModal from "src/components/modals/YesOrNoModal"

const voucherPoolTypes = ["private", "public", "private_public"] as const

const ensureCorrectVoucherPool = async ({
    id,
    shopClient,
}: {
    id: string
    shopClient: ShopClient
}): Promise<VoucherPoolRecord> => {
    const pools = (await shopClient.voucherPools(id, { pageSize: 9999 })) ?? []
    return Object.fromEntries(
        await Promise.all(
            voucherPoolTypes.map(async (type) => {
                const name = `default ${type}`
                const pool = pools.find((pool) => pool.name === name)
                if (pool) {
                    return [type, pool]
                }
                return [
                    type,
                    await shopClient.createVoucherPools(id, {
                        name,
                        countries: null,
                        visibility: type,
                    }),
                ]
            }),
        ),
    )
}

export type VoucherBatchWithUsage = VoucherBatch & VoucherUsage

const BoxHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
})

export const Vouchers = () => {
    const intl = useIntl()
    const { getSelectedAgreement } = useUser()
    const { enqueueAlert } = useSnackbar()
    const accountId = getSelectedAgreement()?.account.id
    const { shopClient } = useClient()
    const [pools, setPools] = useState<VoucherPoolRecord | null>(null)
    const [batches, setBatches] = useState<VoucherBatchWithUsage[] | null>(null)
    const [viewingBatch, setViewingBatch] = useState<VoucherBatch | null>(null)
    const [editingBatch, setEditingBatch] = useState<VoucherBatch | null>(null)
    const [voucherPreviewIndex, setVoucherPreviewIndex] = useState(0)
    const [sendVoucherIndex, setSendVoucherIndex] = useState(0)
    const [sendVoucherEmail, setSendVoucherEmail] = useState("")
    const [uploadVouchersOpen, setUploadVouchersOpen] = useState(true)
    const [sendVouchersOpen, setSendVouchersOpen] = useState(true)
    const [previewVouchersOpen, setPreviewVouchersOpen] = useState(true)
    const [listVouchersOpen, setListVouchersOpen] = useState(true)
    const [batchIdToBeDeleted, setBatchIdToBeDeleted] = useState<string | null>(null)
    const [reload, setReload] = useState(true)
    const [sendExpiredBatchModal, setSendExpiredBatchModal] = useState(false)

    useEffect(() => {
        ;(async () => {
            if (accountId) {
                const pools = await ensureCorrectVoucherPool({ id: accountId, shopClient })
                setPools(pools)
            }
        })()
        ;(async () => {
            if (accountId && reload) {
                const batches = await shopClient.voucherBatches(accountId, { pageSize: 9999 })
                if (batches) {
                    setBatches(
                        (
                            await Promise.all(
                                batches.map(async (batch) => {
                                    const result = await shopClient.voucherBatchUsage({
                                        shopId: accountId,
                                        batchId: batch.id,
                                    })
                                    if (result) {
                                        return { ...result, ...batch }
                                    }
                                    return null
                                }),
                            )
                        ).filter((x) => !!x),
                    )
                    setReload(false)
                }
            }
        })()
    }, [shopClient, accountId, reload])

    if (!pools || !batches) {
        return <>{intl.formatMessage({ id: "common_loading" })} ...</>
    }
    return (
        <>
            <Page title={intl.formatMessage({ id: "vouchers" })}>
                <Typography sx={{ maxWidth: "800px", marginBottom: "20px", lineHeight: "28px" }}>
                    <FormattedMessage id="vouchers_page_description" />
                </Typography>
                <Grid container spacing={3} direction={"column"}>
                    <Grid>
                        <DefaultWrapperPaper>
                            <BoxHeader>
                                <Typography variant="h2">
                                    <FormattedMessage id="vouchers_upload_vouchers_codes" />
                                </Typography>
                                <ArrowBox
                                    open={uploadVouchersOpen}
                                    setBool={setUploadVouchersOpen}
                                />
                            </BoxHeader>
                            <Collapse in={uploadVouchersOpen}>
                                <Typography variant="subtitle1" sx={{ maxWidth: "1000px" }}>
                                    <FormattedMessage id="vouchers_upload_description" />
                                </Typography>
                                <SimplifiedVoucherBatchForm
                                    voucherPoolRecord={pools}
                                    onSubmit={(batch, codes) => {
                                        enqueueAlert(
                                            intl.formatMessage({
                                                id: "toast_message_voucher_batch_uploaded",
                                            }),
                                            "success",
                                        )
                                        setBatches([
                                            ...batches,
                                            {
                                                ...batch,
                                                total: codes.length,
                                                used: 0,
                                            },
                                        ])
                                    }}
                                />
                            </Collapse>
                        </DefaultWrapperPaper>
                    </Grid>
                    {batches.length > 0 ? (
                        <Grid container spacing={3} direction={"row"}>
                            <Grid container spacing={3} size={4} direction={"column"}>
                                <DefaultWrapperPaper>
                                    <BoxHeader>
                                        <Typography variant="h2">
                                            <FormattedMessage
                                                id="vouchers_send_vouchers"
                                                defaultMessage="Send Vouchers"
                                            />
                                        </Typography>
                                        <ArrowBox
                                            open={sendVouchersOpen}
                                            setBool={setSendVouchersOpen}
                                        />
                                    </BoxHeader>
                                    <Collapse in={sendVouchersOpen}>
                                        <Typography variant="subtitle1">
                                            <FormattedMessage id="vouchers_send_description" />
                                        </Typography>
                                        <Grid container spacing={1} direction={"column"}>
                                            <Grid>
                                                <Select
                                                    value={sendVoucherIndex}
                                                    onChange={(e) =>
                                                        setSendVoucherIndex(
                                                            parseInt(e.target.value.toString()),
                                                        )
                                                    }>
                                                    {batches.map((_, i) => {
                                                        return (
                                                            <DropdownMenuItem value={i}>
                                                                Batch {i + 1}
                                                            </DropdownMenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </Grid>
                                            <Grid>
                                                <StyledForm>
                                                    <InputField
                                                        value={sendVoucherEmail}
                                                        onChange={(e) =>
                                                            setSendVoucherEmail(e.target.value)
                                                        }
                                                        label={intl.formatMessage({
                                                            id: "vouchers_customer_email",
                                                        })}
                                                        id="send_voucher_email"
                                                        name="send_voucher_email"
                                                        type="text"
                                                    />
                                                </StyledForm>
                                            </Grid>
                                            <Grid sx={{ textAlign: "right", marginTop: "4px" }}>
                                                <CustomButton
                                                    text={intl.formatMessage({
                                                        id: "common_send",
                                                    })}
                                                    onClick={async () => {
                                                        if (!accountId) {
                                                            return
                                                        }
                                                        const batch = batches[sendVoucherIndex]
                                                        if (
                                                            batch.expires_at &&
                                                            batch.expires_at < new Date()
                                                        ) {
                                                            setSendExpiredBatchModal(true)
                                                            return
                                                        }
                                                        await shopClient.pluckVoucher({
                                                            shopId: accountId,
                                                            batchId: batch.id,
                                                            email: sendVoucherEmail,
                                                        })
                                                        enqueueAlert(
                                                            intl.formatMessage({
                                                                id: "toast_message_voucher_code_sent",
                                                            }),
                                                            "success",
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </DefaultWrapperPaper>
                                <DefaultWrapperPaper>
                                    <BoxHeader>
                                        <Typography variant="h2">
                                            <FormattedMessage
                                                id="vouchers_voucher_preview"
                                                defaultMessage="Voucher Preview"
                                            />
                                        </Typography>
                                        <ArrowBox
                                            open={previewVouchersOpen}
                                            setBool={setPreviewVouchersOpen}
                                        />
                                    </BoxHeader>
                                    <Collapse in={previewVouchersOpen} sx={{ paddingTop: "8px" }}>
                                        <Select
                                            value={voucherPreviewIndex}
                                            onChange={(e) =>
                                                setVoucherPreviewIndex(
                                                    parseInt(e.target.value.toString()),
                                                )
                                            }>
                                            {batches.map((_, i) => {
                                                return (
                                                    <DropdownMenuItem value={i}>
                                                        Batch {i + 1}
                                                    </DropdownMenuItem>
                                                )
                                            })}
                                        </Select>
                                        <VoucherAppPreview batch={batches[voucherPreviewIndex]} />
                                    </Collapse>
                                </DefaultWrapperPaper>
                            </Grid>
                            <Grid container size={8} direction={"column"}>
                                <DefaultWrapperPaper>
                                    <BoxHeader>
                                        <Typography variant="h2">
                                            <FormattedMessage id="vouchers" />
                                        </Typography>
                                        <ArrowBox
                                            open={listVouchersOpen}
                                            setBool={setListVouchersOpen}
                                        />
                                    </BoxHeader>
                                    <Collapse in={listVouchersOpen}>
                                        <Typography variant="subtitle1">
                                            <FormattedMessage id="vouchers_list_description" />
                                        </Typography>
                                        {batches.map((batch, index) => (
                                            <VoucherBatchList
                                                setBatchIdToBeDeleted={setBatchIdToBeDeleted}
                                                setViewingBatch={setViewingBatch}
                                                setEditingBatch={setEditingBatch}
                                                batch={batch}
                                                pools={pools}
                                                index={index}
                                            />
                                        ))}
                                    </Collapse>
                                </DefaultWrapperPaper>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>

                {viewingBatch ? (
                    <GenericModal
                        CustomContent={<VoucherCodesView batch={viewingBatch} />}
                        open={!!viewingBatch}
                        title={intl.formatMessage({ id: "vouchers_voucher_list" })}
                        onClose={() => {
                            setViewingBatch(null)
                            setReload(true)
                        }}
                    />
                ) : null}

                {sendExpiredBatchModal ? (
                    <YesOrNoModal
                        negativeAction={() => setSendExpiredBatchModal(false)}
                        title={intl.formatMessage({ id: "vouchers_send_expired_voucher" })}
                        open={true}
                        onClose={() => setSendExpiredBatchModal(false)}
                        positiveAction={async () => {
                            if (!accountId) {
                                return
                            }
                            const batch = batches[sendVoucherIndex]
                            await shopClient.pluckVoucher({
                                shopId: accountId,
                                batchId: batch.id,
                                email: sendVoucherEmail,
                            })
                            enqueueAlert(
                                intl.formatMessage({
                                    id: "toast_message_voucher_code_sent",
                                }),
                                "success",
                            )
                            setSendExpiredBatchModal(false)
                        }}
                        positiveColorOverride={ColorThemeName.actionGreen}
                        contentString={intl.formatMessage({ id: "voucher_are_you_sure_to_send" })}
                    />
                ) : null}

                {editingBatch && accountId ? (
                    <GenericModal
                        CustomContent={
                            <SimplifiedVoucherBatchFormInner
                                onSubmit={async (e) => {
                                    const newBatch = await shopClient.updateVoucherBatch({
                                        shopId: accountId,
                                        batchId: editingBatch.id,
                                        body: e,
                                    })
                                    const index = batches.findIndex((x) => x.id === newBatch.id)

                                    if (index !== -1) {
                                        batches[index] = { ...batches[index], ...newBatch }
                                        setBatches([...batches])
                                    }

                                    enqueueAlert(
                                        intl.formatMessage({
                                            id: "toast_message_voucher_batch_updated",
                                        }),
                                        "success",
                                    )
                                    setEditingBatch(null)
                                }}
                                voucherPoolRecord={pools}
                                defaultBatch={editingBatch}
                                edit
                            />
                        }
                        open={!!editingBatch}
                        title={intl.formatMessage({ id: "vouchers_edit_voucher" })}
                        onClose={() => setEditingBatch(null)}
                    />
                ) : null}

                {batchIdToBeDeleted != null ? (
                    <YesOrNoModal
                        negativeAction={() => setBatchIdToBeDeleted(null)}
                        title={intl.formatMessage({ id: "vouchers_delete_voucher_batch" })}
                        open={true}
                        onClose={() => setBatchIdToBeDeleted(null)}
                        positiveAction={async () => {
                            if (!accountId) {
                                return
                            }
                            const res = await shopClient.deleteVoucherBatch({
                                shopId: accountId,
                                batchId: batchIdToBeDeleted,
                            })

                            if (res) {
                                if (batches) {
                                    setBatches(batches.filter((x) => x.id !== batchIdToBeDeleted))
                                }
                                enqueueAlert(
                                    intl.formatMessage({
                                        id: "toast_message_voucher_batch_deleted",
                                    }),
                                    "success",
                                )
                                setBatchIdToBeDeleted(null)
                            }
                        }}
                        positiveColorOverride={ColorThemeName.warning}
                        contentString={intl.formatMessage({ id: "voucher_are_you_sure_to_delete" })}
                    />
                ) : null}
            </Page>
        </>
    )
}
