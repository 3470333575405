import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useUser } from "src/contexts/UserConsumer"

export const PostMessageReceiver = () => {
    const { setImposter } = useUser()
    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener(
            "message",
            (e) => {
                const { imposter } = e.data
                if (imposter) {
                    new BroadcastChannel("imposter_status").postMessage("successful_login")
                    setImposter(imposter)
                    navigate("/home")
                }
            },
            { once: true },
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>
}
