import { useEffect, useMemo, useRef } from "react"
import { GoogleMap } from "@react-google-maps/api"
import { Box, Skeleton, Typography } from "@mui/material"
import { useIntl } from "react-intl"
import { useHeatMapController } from "./heatMap.controller"
import { useDebounce } from "src/utils/debounce"

interface HeatMapProps {
    startDate?: Date
    endDate?: Date
    shopId: string
    setDataUpdated: (bool: boolean) => void
    dataUpdated: boolean
}

export const HeatMap = ({
    shopId,
    endDate,
    startDate,
    dataUpdated,
    setDataUpdated,
}: HeatMapProps) => {
    const { debounce } = useDebounce()
    const { heatMapLocations, googleMap, isLoaded, coords, center, handleBounds, setGoogleMap } =
        useHeatMapController({
            endDate: endDate,
            startDate: startDate,
            shopId: shopId,
            updateData: dataUpdated,
            setUpdateData: setDataUpdated,
        })

    const intl = useIntl()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const heatmapLayerRef = useRef<any>(null)

    const mappedLocations = useMemo(() => {
        return (heatMapLocations ?? []).map((item) => ({
            location: new google.maps.LatLng(item.position.lat, item.position.lng),
            weight: item.amount,
        }))
    }, [heatMapLocations])

    useEffect(() => {
        if (heatmapLayerRef.current) {
            heatmapLayerRef.current.setMap(null)
        }

        if (googleMap && heatMapLocations) {
            heatmapLayerRef.current = new google.maps.visualization.HeatmapLayer({
                data: mappedLocations,
                map: googleMap,
                radius: 15,
            })
        }
    }, [heatMapLocations, googleMap, mappedLocations])

    if (!isLoaded || !coords || !center || !startDate || !endDate) {
        return (
            <Skeleton
                animation={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                }}
                variant="rectangular"
                width={"100%"}
                height={"100%"}>
                {intl.formatMessage({ id: "common_loading" })}
            </Skeleton>
        )
    }

    return (
        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "12px",
                }}>
                <Typography variant="h4">
                    {intl.formatMessage({ id: "reports_heatmap", defaultMessage: "Heatmap" })}
                </Typography>
                <Typography>
                    {intl.formatMessage({
                        id: "reports_heatmap_subtitle",
                    })}
                </Typography>
            </Box>

            <GoogleMap
                key={"googleMap"}
                options={{ disableDefaultUI: true }}
                mapContainerStyle={{
                    width: "inherit",
                    height: "100%",
                    position: "relative",
                    borderRadius: "8px",
                }}
                center={center}
                zoom={coords.zoom ?? 8}
                onIdle={debounce(() => handleBounds(googleMap?.getBounds()), 1000)}
                onLoad={(map) => setGoogleMap(map)}></GoogleMap>
        </Box>
    )
}

export default HeatMap
