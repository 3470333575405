/* eslint-disable @typescript-eslint/no-explicit-any */

import { AlertColor } from "@mui/material"
import { ClientError } from "@repo/rezip-client/client_error"

export const errorConverter = (error: any) => {
    if (
        !(error instanceof ClientError) &&
        (error as any).name &&
        (error as any).name === "ClientError"
    ) {
        const sudoMapped = error as ClientError
        const tempError = new ClientError(sudoMapped.status, sudoMapped.body, sudoMapped.url)
        return tempError
    }
    return error
}

export const extractErrorMessage = (error: ClientError) => {
    if (error.body && "errors" in error.body) {
        const failedItems = error.body["errors"] as Record<string, any>

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const errors: string[] = Object.entries(failedItems).flatMap(([_, value]) => {
            if (Array.isArray(value)) {
                return value
            }
            return []
        })
        if (errors.length === 1) {
            return errors
        }
        return errors
    }
    return []
}

export const displayErrorMessage = (
    error: ClientError,
    enqueueAlert: (message: string, alertType: AlertColor) => void,
) => {
    if (error.body) {
        if ("errors" in error.body) {
            const failedItems = error.body["errors"] as Record<string, any>

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const errors = Object.entries(failedItems).flatMap(([_, value]) => {
                if (Array.isArray(value)) {
                    return value
                }
                return []
            })
            if (errors.length === 1) {
                return enqueueAlert(errors[0], "error")
                // if errors is present but is empty default to message
            } else if ("message" in error.body) {
                const message = error.body["message"] as string
                return enqueueAlert(message, "error")
            }
            // if errors is not present
        } else if ("message" in error.body) {
            const message = error.body["message"] as string
            return enqueueAlert(message, "error")
        }
    }
}

export const extractMessage = (error: ClientError) => {
    if (error.body && "message" in error.body) {
        const message = error.body["message"] as string
        return message
    }
    return ""
}
