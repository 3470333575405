import { TextField } from "@mui/material"
import Grid from "@mui/material/Grid"
import { CurrencyCodeRecord } from "currency-codes"
import { ReactNode } from "react"
import { useCurrencyInput } from "src/hooks/useCurrencyInput.hook"
import { FieldLabel } from "../styles"

export type CurrencyInputProps = {
    currency: CurrencyCodeRecord | null
    onChange: (x: number) => void
    label?: ReactNode
    disabled?: boolean
    defaultValue?: number
    setBlock?: (block: boolean) => void
}

export const CurrencyInput = ({
    currency,
    onChange,
    label,
    disabled = false,
    defaultValue,
    setBlock,
}: CurrencyInputProps) => {
    const controller = useCurrencyInput({ currency, onChange, defaultValue, setBlock })

    return (
        <Grid container direction="column" sx={{ width: "100%" }}>
            {label ? (
                <Grid>
                    <FieldLabel>{label}</FieldLabel>
                </Grid>
            ) : null}
            <TextField {...controller} disabled={currency === null || disabled} />
        </Grid>
    )
}
