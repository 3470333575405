import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { StyledMenuItem } from "src/components/styles"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { useClient } from "src/hooks/useClient.hook"
import { QueryingOptions } from "@repo/rezip-client"

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { useIntl } from "react-intl"
import { Partner } from "@repo/rezip-client/mothership_client"
export const MotherShipPartners = () => {
    const { mothershipClient } = useClient()
    const [inititalLoad, setInititalLoad] = useState(true)
    const navigate = useNavigate()
    const intl = useIntl()

    const partnerToRow = (item: Partner, index: number) => {
        return (
            <StyledBodyRow key={`partner-${index}`}>
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell>
                    <ThreeDotMenu
                        menuItems={[
                            <StyledMenuItem
                                key={`view-${index}`}
                                onClick={() => navigate(`/re-zip/partners/${item.id}/view`)}>
                                <RemoveRedEyeIcon />
                                {intl.formatMessage({ id: "common_view" })}
                            </StyledMenuItem>,
                        ]}
                    />
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    const fetchPartners = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            const options = {
                filters: filters,
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            const resp = await mothershipClient.getPartners(options)

            return resp
        },
        [mothershipClient],
    )

    return (
        <Page title={intl.formatMessage({ id: "mothership_partners" })}>
            <DefaultWrapperPaper>
                <AllInOneTable
                    addFunction={() => navigate("add")}
                    headers={[
                        { key: "name", label: "Name", excludeFromSearch: true },
                        { key: "action", label: "", excludeFromSearch: true },
                    ]}
                    itemsToRow={partnerToRow}
                    defaultSortBy={"name"}
                    fetchItems={fetchPartners}
                    initialLoad={inititalLoad}
                    setInitialLoad={setInititalLoad}
                />
            </DefaultWrapperPaper>
        </Page>
    )
}
