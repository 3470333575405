import { Table, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import { StyledBodyRow } from "../table/TableComponents"

import { useState } from "react"
import { ExtraStyledTableCell } from "src/routes/warehouse/stock-keeping/storageUnitPage"
import { FormattedMessage } from "react-intl"

import BarcodeScanInput from "../BarcodeScanInputField"
import { StorageUnit } from "@repo/rezip-client/partner_client"

interface BundleHandlerProps {
    storageUnit: StorageUnit
    handleAdd: (barcode: string) => void
    handleRemove: (barcode: string) => void
    useIds: boolean
    handleQuantiy: (quantity: number, reduce: boolean) => void
}

const BundleHandler = ({
    storageUnit,
    handleAdd,
    handleRemove,
    useIds,
    handleQuantiy,
}: BundleHandlerProps) => {
    const [addInput, setAddInput] = useState("")
    const [removeInput, setRemoveInput] = useState("")

    return (
        <Table>
            <tbody>
                <StyledBodyRow>
                    <ExtraStyledTableCell>
                        {useIds ? (
                            <FormattedMessage id="bundle_handler_add" />
                        ) : (
                            <FormattedMessage id="bundle_handler_add_quantity" />
                        )}
                    </ExtraStyledTableCell>
                    <ExtraStyledTableCell>
                        <Grid sx={{ padding: "8px" }}>
                            <BarcodeScanInput
                                value={addInput}
                                onChange={(value: string) => setAddInput(value)}
                                onScan={
                                    useIds
                                        ? () => handleAdd(addInput)
                                        : () => handleQuantiy(Number(addInput), false)
                                }
                                focused={true}
                            />
                        </Grid>
                    </ExtraStyledTableCell>
                </StyledBodyRow>
                <StyledBodyRow>
                    <ExtraStyledTableCell>
                        {useIds ? (
                            <FormattedMessage id="bundle_handler_remove" />
                        ) : (
                            <FormattedMessage id="bundle_handler_remove_quantity" />
                        )}
                    </ExtraStyledTableCell>
                    <ExtraStyledTableCell>
                        <Grid sx={{ padding: "8px" }}>
                            <BarcodeScanInput
                                value={removeInput}
                                onChange={(value: string) => setRemoveInput(value)}
                                onScan={
                                    useIds
                                        ? () => handleRemove(removeInput)
                                        : () => handleQuantiy(Number(removeInput), true)
                                }
                            />
                        </Grid>
                    </ExtraStyledTableCell>
                </StyledBodyRow>
                <StyledBodyRow>
                    <ExtraStyledTableCell>
                        <FormattedMessage id="bundle_handler_qunatity" />
                    </ExtraStyledTableCell>
                    <ExtraStyledTableCell>
                        <Grid sx={{ padding: "8px" }}>
                            <Typography>{`${storageUnit.quantity} of ${storageUnit.size}`}</Typography>
                        </Grid>
                    </ExtraStyledTableCell>
                </StyledBodyRow>
            </tbody>
        </Table>
    )
}
export default BundleHandler
