import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Modal,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Grid,
} from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"
import { useCallback, useState } from "react"
import { useUser } from "src/contexts/UserConsumer"
import { Form } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useClient } from "src/hooks/useClient.hook"
import { CustomButton, ButtonType, ColorThemeName } from "src/components/generalComponents/Buttons"
import { useColorScheme } from "@mui/material/styles"
import StyledForm from "src/components/layout/StyledForm"
import InputField from "src/components/InputField"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"
import { ClientError } from "@repo/rezip-client/client_error"
import GenericModal from "src/components/modals/GenericModal"
import { OTPInput } from "src/components/OTPInput"
import { MfaModal } from "src/components/modals/MfaModal"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "secondaryBackground.main",
    boxShadow: "1px 1px 3px #00000020",
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
} as const

const EnableMfa = () => {
    const intl = useIntl()
    const { setUser, getUser, setMfaUsed } = useUser()
    const { anonymousClient } = useClient()

    const [mfaSvg, setMfaSvg] = useState<string | null>(null)
    const [code, setCode] = useState<string>("")
    const user = getUser()

    // If user is not available, do not render the component
    if (!user) return null

    const handleEnableClick = async () => {
        const svg = await anonymousClient.createMFA()
        setMfaSvg(svg)
    }

    const handleVerifyClick = async () => {
        const authed = await anonymousClient.verifyMFA(code)
        if (authed) {
            setMfaUsed(true)
            setUser({ ...user, mfa_enabled: true })
        }
    }

    return (
        <Box>
            <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                <FormattedMessage id="user_settings_mfa_description" />
            </Typography>
            <CustomButton
                text={intl.formatMessage({ id: "user_settings_enable_mfa_button" })}
                onClick={handleEnableClick}
            />

            <Modal
                open={!!mfaSvg}
                onClose={() => setMfaSvg(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography variant="body2">
                        {intl.formatMessage({ id: "user_settings_mfa_verify_description" })}
                    </Typography>

                    {mfaSvg && (
                        <img
                            style={{ paddingTop: "10px" }}
                            width={290}
                            src={`data:image/svg+xml;base64,${window.btoa(mfaSvg)}`}
                            alt="MFA QR Code"
                        />
                    )}

                    <TextField
                        label={intl.formatMessage({ id: "user_settings_mfa_code_label" })}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                    />

                    <CustomButton
                        onClick={handleVerifyClick}
                        text={intl.formatMessage({ id: "user_settings_mfa_verify_button" })}
                    />
                </Box>
            </Modal>
        </Box>
    )
}

const DisableMfa = () => {
    const intl = useIntl()
    const { getUser, setMfaUsed, setUser } = useUser()
    const { anonymousClient } = useClient()
    const [verifyModal, setVerifyModal] = useState<boolean>(false)
    const [otp, setOtp] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const [showMfaForDisable, setShowMfaForDisable] = useState(false)
    const user = getUser()

    return user ? (
        <Box>
            <Typography variant="body2">
                <FormattedMessage id="user_settings_mfa_enabled_message_1" />
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                <FormattedMessage id="user_settings_mfa_enabled_message_2" />
            </Typography>
            <CustomButton
                onClick={() => {
                    ;(async () => {
                        await anonymousClient.resetMFA()
                        setVerifyModal(true)
                    })()
                }}
                text={intl.formatMessage({
                    id: "user_settings_mfa_reset_button",
                })}
            />
            <Modal
                open={verifyModal}
                onClose={() => setVerifyModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Grid sx={style}>
                    <Form
                        onSubmit={async () => {
                            await anonymousClient.deleteMFA({
                                email: user.email,
                                password: password,
                                code: otp,
                                disable: true,
                            })
                            setMfaUsed(false)
                            setUser({ ...user, mfa_enabled: false, mfa_required: false })
                        }}>
                        <Grid container spacing={1} direction={"column"}>
                            <Typography variant="body2">
                                {intl.formatMessage({
                                    id: "user_settings_mfa_reset_email_description",
                                })}
                            </Typography>
                            <Grid>
                                <TextField
                                    label={intl.formatMessage({ id: "common_password" })}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={showPassword ? "text" : "password"}
                                    sx={{ display: "flex" }}
                                    slotProps={{
                                        input: {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label={intl.formatMessage({
                                                            id: "common_toggle_password_visibility",
                                                        })}
                                                        onClick={handleClickShowPassword}>
                                                        {showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            autoComplete: "new-password",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <TextField
                                    label={intl.formatMessage({ id: "one_time_code" })}
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value.replace(/\s/g, ""))}
                                    sx={{ display: "flex" }}
                                />
                            </Grid>
                            <CustomButton
                                text={intl.formatMessage({ id: "common_reset" })}
                                type={ButtonType.submit}
                            />
                        </Grid>
                    </Form>
                </Grid>
            </Modal>
            {showMfaForDisable ? (
                <MfaModal
                    open={showMfaForDisable}
                    setOpen={setShowMfaForDisable}
                    onVerify={async () => {
                        await anonymousClient.patchMFA({ mfa_required: false })
                        setShowMfaForDisable(false)
                        setUser({ ...user, mfa_required: false })
                    }}
                />
            ) : null}
            <Box sx={{ marginTop: "12px" }}>
                <FormControlLabel
                    label={
                        <Typography variant="body2">
                            {intl.formatMessage({
                                id: "user_settings_mfa_required_label",
                            })}
                        </Typography>
                    }
                    labelPlacement="end"
                    control={
                        <Checkbox
                            checked={user.mfa_required}
                            onChange={async (e) => {
                                if (e.target.checked) {
                                    await anonymousClient.patchMFA({ mfa_required: true })
                                    setUser({ ...user, mfa_required: true })
                                } else {
                                    setShowMfaForDisable(true)
                                }
                            }}
                        />
                    }
                />
            </Box>
        </Box>
    ) : null
}

export const UserSettings = () => {
    const { getUser, refreshUser, getToken, logout } = useUser()
    const intl = useIntl()
    const user = getUser()
    const token = getToken()

    const [email, setEmail] = useState<string | null>(user?.email ?? null)
    const [name, setName] = useState<string | null>(user?.name ?? null)
    const { anonymousClient } = useClient()
    const { enqueueAlert } = useSnackbar()
    const [openOTP, setOpenOTP] = useState(false)
    const [openDeleteUser, setOpenDeleteUser] = useState(false)
    const [otpValue, setOtpValue] = useState("")
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
        newPassword: false,
    })

    const handleClickShowPassword = (field: keyof typeof showPassword) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }))
    }

    const handleMouseDownPassword = (field: keyof typeof showPassword) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }))
    }

    const updateName = useCallback(async () => {
        try {
            const resp = await anonymousClient.patchName(name ?? "")
            if (resp) {
                refreshUser(token)
                enqueueAlert("success", "success")
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return displayErrorMessage(mappedError, enqueueAlert)
        }
    }, [anonymousClient, enqueueAlert, name, refreshUser, token])

    const verifyEmail = useCallback(async () => {
        if (!email || !otpValue) {
            return
        }

        const resp = await anonymousClient.emailVerify({ email: email, otp: otpValue })
        if (resp) {
            logout()
        }
    }, [anonymousClient, email, logout, otpValue])

    const updateEmail = useCallback(async () => {
        try {
            anonymousClient.putEmail(email ?? "")

            setOpenOTP(true)
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return displayErrorMessage(mappedError, enqueueAlert)
        }
    }, [anonymousClient, email, enqueueAlert])

    const handleDelete = useCallback(async () => {
        try {
            const resp = await anonymousClient.deleteMe()
            if (resp) {
                logout()
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return displayErrorMessage(mappedError, enqueueAlert)
        }
    }, [anonymousClient, enqueueAlert, logout])

    const handleUpdatePassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const newPassword = data.get("new-password")?.toString()
        const confimPassword = data.get("confirm-password")?.toString()
        const current = data.get("current-password")?.toString()

        if (!newPassword || !confimPassword || !current) {
            return
        }

        if (newPassword !== confimPassword) {
            // Confirm password doesn't match
            return enqueueAlert(intl.formatMessage({ id: "confirm_password_wrong" }), "error")
        }

        try {
            const resp = await anonymousClient.putPassword({
                old_password: current,
                new_password: newPassword,
            })
            if (resp) {
                return enqueueAlert(intl.formatMessage({ id: "password_changed" }), "success")
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return displayErrorMessage(mappedError, enqueueAlert)
        }
    }

    const { mode, setMode } = useColorScheme()
    if (!mode) {
        return null
    }

    return user ? (
        <>
            <Page
                title={intl.formatMessage({
                    id: "user_settings_header",
                })}
                backButton>
                <Grid container direction={"column"} spacing={2}>
                    <DefaultWrapperPaper>
                        <Typography variant="h4">
                            <FormattedMessage id="user_settings_mfa" />
                        </Typography>
                        {user.mfa_enabled ? <DisableMfa /> : <EnableMfa />}

                        <Divider />

                        <Typography variant="h4">
                            <FormattedMessage id="user_settings_theme" />
                        </Typography>
                        <Typography variant="body2">
                            <FormattedMessage id="user_settings_theme_description" />
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="theme-toggle"
                                name="theme-toggle"
                                row
                                value={mode}
                                onChange={(event) =>
                                    setMode(event.target.value as "system" | "light" | "dark")
                                }>
                                <FormControlLabel
                                    value="system"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: "theme_system" })}
                                />
                                <FormControlLabel
                                    value="light"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: "theme_light" })}
                                />
                                <FormControlLabel
                                    value="dark"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: "theme_dark" })}
                                />
                            </RadioGroup>
                        </FormControl>
                    </DefaultWrapperPaper>

                    <Grid container spacing={2}>
                        <Grid container direction={"column"} size={{ lg: 4, md: 4, sm: 4 }}>
                            <Grid>
                                <DefaultWrapperPaper>
                                    <StyledForm>
                                        <InputField
                                            id={""}
                                            name={"email-address"}
                                            label={intl.formatMessage({
                                                id: "common_email",
                                            })}
                                            required
                                            type={"text"}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <Grid container direction="column" alignItems="flex-end">
                                            <CustomButton
                                                styles={{
                                                    marginTop: "10px",

                                                    width: "120px",
                                                }}
                                                text={intl.formatMessage({ id: "common_update" })}
                                                onClick={updateEmail}
                                            />
                                        </Grid>
                                    </StyledForm>
                                </DefaultWrapperPaper>
                            </Grid>
                            <Grid>
                                <DefaultWrapperPaper>
                                    <StyledForm>
                                        <InputField
                                            id={""}
                                            name={"Name"}
                                            label={intl.formatMessage({ id: "common_name" })}
                                            required
                                            type={"text"}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <Grid container direction="column" alignItems="flex-end">
                                            <CustomButton
                                                styles={{
                                                    marginTop: "10px",

                                                    width: "120px",
                                                }}
                                                text={intl.formatMessage({ id: "common_update" })}
                                                onClick={updateName}
                                            />
                                        </Grid>
                                    </StyledForm>
                                </DefaultWrapperPaper>
                            </Grid>
                        </Grid>
                        <Grid direction={"column"} size={{ lg: 4, md: 4, sm: 4 }}>
                            <DefaultWrapperPaper>
                                <Grid component="form" noValidate onSubmit={handleUpdatePassword}>
                                    <Typography sx={{ fontSize: "12px", color: "text.primary" }}>
                                        {intl.formatMessage({ id: "password_current" })}
                                    </Typography>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            marginTop: "0px",
                                            "& .MuiInputBase-root": {
                                                padding: "0px",
                                            },
                                            "& .MuiInputBase-root-input": {
                                                padding: "0px",
                                            },
                                            ".MuiInputBase-input": {
                                                padding: "8px 16px",
                                            },
                                        }}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="current-password"
                                        name="current-password"
                                        aria-label="current-password"
                                        autoComplete="current-password"
                                        type={showPassword.password ? "text" : "password"}
                                        slotProps={{
                                            input: {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                handleClickShowPassword("password")
                                                            }
                                                            onMouseDown={() =>
                                                                handleMouseDownPassword("password")
                                                            }>
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                    />
                                    <Typography sx={{ fontSize: "12px", color: "text.primary" }}>
                                        {intl.formatMessage({ id: "password_new" })}
                                    </Typography>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            marginTop: "0px",
                                            "& .MuiInputBase-root": {
                                                padding: "0px",
                                            },
                                            "& .MuiInputBase-root-input": {
                                                padding: "0px",
                                            },
                                            ".MuiInputBase-input": {
                                                padding: "8px 16px",
                                            },
                                        }}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="new-password"
                                        name="new-password"
                                        aria-label="new-password"
                                        autoComplete="new-password"
                                        type={showPassword.newPassword ? "text" : "password"}
                                        slotProps={{
                                            input: {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                handleClickShowPassword(
                                                                    "newPassword",
                                                                )
                                                            }
                                                            onMouseDown={() =>
                                                                handleMouseDownPassword(
                                                                    "newPassword",
                                                                )
                                                            }>
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                    />
                                    <Typography sx={{ fontSize: "12px", color: "text.primary" }}>
                                        {intl.formatMessage({ id: "password_confirm" })}
                                    </Typography>
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            marginTop: "0px",
                                            "& .MuiInputBase-root": {
                                                padding: "0px",
                                            },
                                            "& .MuiInputBase-root-input": {
                                                padding: "0px",
                                            },
                                            ".MuiInputBase-input": {
                                                padding: "8px 16px",
                                            },
                                        }}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="confirm-password"
                                        name="confirm-password"
                                        aria-label="confirm-password"
                                        autoComplete="confirm-password"
                                        type={showPassword.confirmPassword ? "text" : "password"}
                                        slotProps={{
                                            input: {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                handleClickShowPassword(
                                                                    "confirmPassword",
                                                                )
                                                            }
                                                            onMouseDown={() =>
                                                                handleMouseDownPassword(
                                                                    "confirmPassword",
                                                                )
                                                            }>
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                    />
                                    <Grid container direction="column" alignItems="flex-end">
                                        <CustomButton
                                            type={ButtonType.submit}
                                            text={intl.formatMessage({
                                                id: "coommon_update",
                                                defaultMessage: "Update",
                                            })}
                                            styles={{
                                                marginTop: "10px",
                                                width: "120px",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </DefaultWrapperPaper>
                        </Grid>
                        <Grid direction={"column"} size={{ lg: 4, md: 4, sm: 4 }}>
                            <Grid>
                                <DefaultWrapperPaper>
                                    <Typography variant="h3">
                                        <FormattedMessage id="delete_user" />
                                    </Typography>
                                    <Typography variant="body1">
                                        <FormattedMessage id="delete_user_info" />
                                    </Typography>
                                    <Grid container direction="column" alignItems="flex-end">
                                        <CustomButton
                                            onClick={() => setOpenDeleteUser(true)}
                                            colorTheme={ColorThemeName.warning}
                                            text={intl.formatMessage({
                                                id: "delete_user",
                                                defaultMessage: "Delete User",
                                            })}
                                            styles={{
                                                marginTop: "10px",
                                                width: "120px",
                                            }}
                                        />
                                    </Grid>
                                </DefaultWrapperPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Page>
            <GenericModal
                open={openOTP}
                CustomContent={
                    <Grid container direction={"column"} width={"400px"}>
                        <FormattedMessage id="otp_clarification" />
                        <br />
                        <FormattedMessage id="after_verification" />

                        <OTPInput onChange={setOtpValue} />
                        <Grid container direction="column" alignItems="flex-end">
                            <CustomButton
                                onClick={verifyEmail}
                                text={intl.formatMessage({ id: "user_settings_mfa_verify_button" })}
                            />
                        </Grid>
                    </Grid>
                }
                title={intl.formatMessage({ id: "email_verification" })}
                onClose={() => setOpenOTP(false)}
            />
            <GenericModal
                open={openDeleteUser}
                CustomContent={
                    <Grid container direction={"column"} width={"400px"}>
                        <FormattedMessage id="delete_user_message" />
                    </Grid>
                }
                CustomActions={
                    <Grid container spacing={1}>
                        <CustomButton
                            onClick={() => setOpenDeleteUser(false)}
                            text={intl.formatMessage({ id: "common_no" })}
                        />
                        <CustomButton
                            colorTheme={ColorThemeName.warning}
                            onClick={handleDelete}
                            text={intl.formatMessage({ id: "common_yes" })}
                        />
                    </Grid>
                }
                title={intl.formatMessage({ id: "delete_your_user" })}
                onClose={() => setOpenDeleteUser(false)}
            />
        </>
    ) : null
}
