import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import PeopleIcon from "@mui/icons-material/People"
import BarChartIcon from "@mui/icons-material/BarChart"
import LayersIcon from "@mui/icons-material/Layers"
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag"
import StoreIcon from "@mui/icons-material/Store"
import LoyaltyIcon from "@mui/icons-material/Loyalty"
import { FormattedMessage } from "react-intl"
import { Link, useLocation } from "react-router-dom"
import { Box, Divider, List, styled, Typography, Grid } from "@mui/material"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { ExpandLess, ExpandMore, Phone } from "@mui/icons-material"
import { useUser } from "src/contexts/UserConsumer"
import { ItemIcon } from "src/components/styles"
import { ReactComponent as EULife } from "../assets/EU-Life-Logo-Outline.svg"
import { ReactComponent as BCorp } from "../assets/B-Corp-Logo.svg"
import BalanceIcon from "@mui/icons-material/Balance"

interface StyledListItemTextProps {
    active: number
}

const StyledListItemText = styled(ListItemText)<StyledListItemTextProps>(({ active, theme }) => ({
    color: active ? `${theme.palette.primary.main}` : "inherit",
}))

const StyledExpandLess = styled(ExpandLess)({
    color: "inherit",
})
const StyledExpandMore = styled(ExpandMore)({
    color: "inherit",
})

const StyledListItemButton = styled(ListItemButton)<StyledListItemTextProps>(
    ({ active, theme }) => ({
        color: active ? `${theme.palette.primary.main}` : `${theme.palette.text.primary}`,
    }),
)

const BCorpLogo = styled(BCorp)(({ theme }) => ({
    filter: "brightness(15%)",
    ...theme.applyStyles("dark", {
        filter: "brightness(90%)",
    }),
}))

const EULifeLogo = styled(EULife)(({ theme }) => ({
    filter: "brightness(15%)",
    ...theme.applyStyles("dark", {
        filter: "brightness(90%)",
    }),
}))

type MenuItem = {
    to: string
    icon: ReactNode
    textId: string
    subItems?: MenuItem[]
    component?: string
}

interface RenderMenuItemsProps {
    items: MenuItem[]
    openMap: Record<string, boolean>
    setOpenMap: (x: Record<string, boolean>) => void
    subMenuItem?: boolean
}

interface MenuListProps {
    toggleDarawer: () => void
    drawerOpen: boolean
}

export const MenuListItems = ({ toggleDarawer: toggleDrawer, drawerOpen }: MenuListProps) => {
    const [openMap, setOpenMap] = useState({})
    const { getSelectedAgreement, getSelectedAccountType, getSelectedLocationId } = useUser()
    const agreement = getSelectedAgreement()
    const location = useLocation()
    const locationId = getSelectedLocationId()

    const accountType = getSelectedAccountType()

    const menuItems: MenuItem[] = useMemo(() => {
        const supportRoutes: MenuItem[] = [
            {
                component: "div",
                icon: <Phone />,
                textId: "menu_listitem_header_support",
                to: "support",
            },
        ]

        const shopRoutes: MenuItem[] = [
            {
                to: "home",
                icon: <DashboardIcon />,
                textId: "menu_listitem_home",
            },
            {
                to: "/administration/reports",
                icon: <BarChartIcon />,
                textId: "menu_listitem_reporting",
            },
            {
                to: "/orders",
                textId: "menu_listitem_orders",
                icon: <ShoppingBagIcon />,
            },
            {
                to: "/shop/profile",
                icon: <StoreIcon />,
                textId: "menu_listitem_shop_details",
            },
            {
                to: "/shop/vouchers",
                icon: <LoyaltyIcon />,
                textId: "menu_listitem_vouchers",
            },
            {
                to: "/users",
                icon: <ShoppingCartIcon />,
                textId: "menu_listitem_users",
            },
        ]

        const partnerRoutes: MenuItem[] = [
            {
                to: "home",
                icon: <DashboardIcon />,
                textId: "menu_listitem_home",
            },
            agreement?.permission_groups?.find(
                (x) => x.name === "Warehouse" && x.type === "managed",
            ) || agreement?.owner
                ? {
                      icon: <LayersIcon />,
                      textId: "menu_listitem_header_warehouse",
                      to: "/warehouse/",
                      subItems: [
                          {
                              to: "/warehouse/receive",
                              icon: <LayersIcon />,
                              textId: "menu_listitem_packaging_receive",
                          },
                          {
                              to: "/warehouse/fulfill",
                              icon: <LayersIcon />,
                              textId: "menu_listitem_fulfill",
                          },
                          {
                              to: "/warehouse/storage-units",
                              icon: <LayersIcon />,
                              textId: "menu_listitem_storage_units",
                          },
                          {
                              to: "/warehouse/waste-management",
                              icon: <LayersIcon />,
                              textId: "menu_listitem_waste_management",
                          },
                          {
                              to: "/warehouse/packaging-lookup",
                              icon: <LayersIcon />,
                              textId: "menu_listitem_packaging_lookup",
                          },
                          {
                              to: "/warehouse/stations",
                              icon: <LayersIcon />,
                              textId: "menu_listitem_stations",
                          },
                      ],
                  }
                : undefined,
            agreement?.permission_groups?.find(
                (x) => x.name === "Administration" && x.type === "managed",
            ) || agreement?.owner
                ? {
                      icon: <PeopleIcon />,
                      textId: "menu_listitem_header_administration",
                      to: "/administration/",
                      subItems: [
                          {
                              to: "/administration/shops",
                              icon: <PeopleIcon />,
                              textId: "menu_listitem_shops",
                          },
                          {
                              to: "/administration/reports",
                              icon: <BarChartIcon />,
                              textId: "menu_listitem_reporting",
                          },
                          {
                              to: "/administration/orders",
                              icon: <ShoppingCartIcon />,
                              textId: "menu_listitem_orders",
                          },
                      ],
                  }
                : undefined,
            agreement?.permission_groups?.find(
                (x) => x.name === "Account" && x.type === "managed",
            ) || agreement?.owner
                ? {
                      icon: <ShoppingCartIcon />,
                      textId: "menu_listitem_header_account",
                      to: "/account/",

                      subItems: [
                          {
                              to: "/account/assortment",
                              icon: <ShoppingCartIcon />,
                              textId: "menu_listitem_assortment",
                          },
                          {
                              to: "/account/file-manager",
                              icon: <ShoppingCartIcon />,
                              textId: "menu_listitem_file_manager",
                          },
                          {
                              to: "/account/company-info",
                              icon: <ShoppingCartIcon />,
                              textId: "menu_listitem_company_info",
                          },
                          {
                              to: "/account/warehouses",
                              icon: <ShoppingCartIcon />,
                              textId: "menu_listitem_warehouse_locations",
                          },
                          {
                              to: "/account/settings",
                              icon: <ShoppingCartIcon />,
                              textId: "menu_listitem_storage_unit_types",
                          },
                          {
                              to: "/account/users",
                              icon: <ShoppingCartIcon />,
                              textId: "menu_listitem_users",
                          },
                          {
                              to: "/account/inventory",
                              icon: <ShoppingCartIcon />,
                              textId: "menu_listitem_inventory",
                          },
                      ],
                  }
                : undefined,
        ].filter((x) => !!x)

        // TODO: ADJUST home to handle this new home page
        const dropPointRoutes: MenuItem[] = [
            {
                to: "home",
                textId: "menu_listitem_home",
                icon: <DashboardIcon />,
            },
            {
                to: "droppoint",
                textId: "menu_list_item_droppoints",
                icon: <StoreIcon />,
            },
            {
                to: "droppoint/account",
                textId: "menu_list_item_account",
                icon: <ShoppingCartIcon />,
            },
            {
                to: "/users",
                textId: "menu_listitem_users",
                icon: <PeopleIcon />,
            },
        ]

        const mothership: MenuItem[] = [
            { to: "/re-zip/partners", textId: "mothership_partners", icon: <DashboardIcon /> },
            { to: "/re-zip/products", textId: "mothership_products", icon: <DashboardIcon /> },
            {
                to: "/re-zip/product-groups",
                textId: "mothership_product_groups",
                icon: <DashboardIcon />,
            },
            {
                to: "/re-zip/product-configuration",
                textId: "mothership_product_configuration",
                icon: <DashboardIcon />,
            },
        ]

        const routes = (
            accountType === "Shop"
                ? shopRoutes
                : accountType === "Partner" && locationId && locationId !== "default"
                  ? partnerRoutes
                  : accountType === "DropPoint"
                    ? dropPointRoutes
                    : accountType === "RE-ZIP"
                      ? mothership
                      : []
        ).concat(supportRoutes)

        const items = agreement ? routes : []

        return items
    }, [accountType, agreement, locationId])

    const renderMenuItems = useCallback(
        ({ items, subMenuItem, setOpenMap, openMap }: RenderMenuItemsProps) =>
            items.map((item) => {
                const active = location.pathname.startsWith(item.to) ? 1 : 0
                if ((item.subItems || []).length === 0) {
                    return (
                        <Box key={item.to}>
                            <StyledListItemButton
                                key={`listButton-${item.to}`}
                                active={active}
                                // eslint-disable-next-line
                                // @ts-ignore
                                component={Link}
                                to={item.to}>
                                <ItemIcon>{subMenuItem ? null : item.icon}</ItemIcon>
                                <StyledListItemText active={active}>
                                    <Typography
                                        sx={{
                                            fontSize: subMenuItem ? "12px" : "14px",
                                            fontWeight: subMenuItem ? 400 : 700,
                                            lineHeight: "150%",
                                            letterSpacing: "0.108px",
                                        }}>
                                        <FormattedMessage id={item.textId} />
                                    </Typography>
                                </StyledListItemText>
                            </StyledListItemButton>
                        </Box>
                    )
                } else {
                    return (
                        <Box key={item.to}>
                            <StyledListItemButton
                                key={`listButton-${item.to}`}
                                active={active}
                                onClick={() => {
                                    openMap[item.to] = !openMap[item.to]
                                    setOpenMap(structuredClone(openMap))
                                    if (!drawerOpen) {
                                        toggleDrawer()
                                    }
                                }}>
                                <ItemIcon>{subMenuItem ? null : item.icon}</ItemIcon>
                                <StyledListItemText active={active}>
                                    <Typography
                                        sx={{
                                            fontSize: subMenuItem ? "12px" : "14px",
                                            fontWeight: subMenuItem ? 400 : 700,
                                            lineHeight: "150%",
                                            letterSpacing: "0.108px",
                                        }}>
                                        <FormattedMessage id={item.textId} />
                                    </Typography>
                                </StyledListItemText>
                                {openMap[item.to] ? <StyledExpandLess /> : <StyledExpandMore />}
                            </StyledListItemButton>
                            {openMap[item.to]
                                ? renderMenuItems({
                                      items: item.subItems || [],
                                      openMap,
                                      setOpenMap,
                                      subMenuItem: true,
                                  })
                                : null}
                        </Box>
                    )
                }
            }),
        [drawerOpen, toggleDrawer, location],
    )

    useEffect(() => {
        const url = location.pathname
        setOpenMap({
            ...openMap,
            ...Object.fromEntries(
                menuItems.filter((x) => url.startsWith(x.to)).map((x) => [x.to, true]),
            ),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        if (!drawerOpen) {
            setOpenMap({})
        }
    }, [drawerOpen])

    const renderedMenuitems = useMemo(() => {
        return renderMenuItems({
            items: menuItems,
            setOpenMap,
            openMap,
        })
    }, [menuItems, renderMenuItems, openMap, setOpenMap])

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "91vh",
            }}>
            <List>{renderedMenuitems}</List>

            <Grid container direction="column">
                <StyledListItemButton
                    // eslint-disable-next-line
                    // @ts-ignore
                    component={Link}
                    to="/legal">
                    <ItemIcon>
                        <BalanceIcon />
                    </ItemIcon>
                    <StyledListItemText active={0}>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "150%",
                                letterSpacing: "0.108px",
                            }}>
                            <FormattedMessage id="menu_listitem_header_legal" />
                        </Typography>
                    </StyledListItemText>
                </StyledListItemButton>
                <Divider />
                <Grid container justifyContent="center" spacing={4}>
                    <Grid>
                        <Link to={"https://re-zip.com/eu-life/"}>
                            <EULifeLogo width="" height="66px" />
                        </Link>
                    </Grid>
                    <Grid>
                        <BCorpLogo width="" height="66px" />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
