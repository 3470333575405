import { Dialog, DialogActions, styled } from "@mui/material"

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        background: theme.palette.secondaryBackground.main,
        borderRadius: "12px",
        minWidth: "300px",
        maxWidth: "none",
    },
    "& .MuiBackdrop-root": {
        //should prolly be fiddled with
        backgroundColor: "rgba(174, 184, 174, .1) ",
        backdropFilter: "blur(2px)",
        opacity: "20%",
    },
}))

export const StyledDialogActions = styled(DialogActions)({
    padding: "0px 24px 20px",
})
