import { Box, Card, Typography, Grid } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import InputField from "src/components/InputField"
import GenericModal from "src/components/modals/GenericModal"
import Page from "src/components/layout/Page"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { PriceBreakDown } from "./priceBreakDown"
import { useNavigate } from "react-router-dom"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { Product } from "@repo/rezip-client/types"
import { formatCurrency } from "src/utils/formatCurrency"
import { roundingHelper } from "src/utils/roundingHelper"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { useClient } from "src/hooks/useClient.hook"
import { OrderConfiguration } from "@repo/rezip-client/shop_client"
import { useDebounce } from "src/utils/debounce"

interface LineProps {
    quantity: number
    cost: number
}

type TotalCostProduct = { total: number | undefined } & Product

interface ProductRowProps {
    item: TotalCostProduct
    index: number
    updateLine: (sku: string, quantity: number, cost: number) => void
    totalPrice: number
    lines: Record<string, LineProps>
}

const ProductRow = ({ item, index, updateLine, totalPrice, lines }: ProductRowProps) => {
    const { enqueueAlert } = useSnackbar()
    const intl = useIntl()
    const [inputValue, setInputValue] = useState<string>(
        lines[item.sku] ? String(lines[item.sku].quantity) : "",
    )

    const { debounce } = useDebounce()

    const updateQuantity = useCallback(
        (value: number) => {
            const nearestTen = roundingHelper({
                intl,
                number: value,
                enqueueAlert,
            })
            if (nearestTen === 0) {
                setInputValue("")
                updateLine(item.sku, 0, item.price * 0)
            } else {
                setInputValue(String(nearestTen))
                updateLine(item.sku, nearestTen, item.price * nearestTen)
            }
        },
        [enqueueAlert, intl, item.price, item.sku, updateLine],
    )

    const debouncedUpdateQuantity = useMemo(
        () => debounce(updateQuantity, 1000),
        [debounce, updateQuantity],
    )

    const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
        const rawValue = Number(e.target.value) || ""
        setInputValue(e.target.value)
        debouncedUpdateQuantity(rawValue)
    }

    if (item.id === "total-item") {
        return (
            <StyledBodyRow key="total-row">
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell colSpan={5} />
                <StyledTableCell>
                    {formatCurrency({
                        currencyCode: item.currency ?? "",
                        value: totalPrice,
                        intl,
                    })}
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    return (
        <StyledBodyRow key={`product-${index}`}>
            <StyledTableCell>{item.name}</StyledTableCell>
            <StyledTableCell>{item.sku}</StyledTableCell>
            <StyledTableCell>
                {item.dimension &&
                item.dimension.height &&
                item.dimension.width &&
                item.dimension.depth
                    ? `${item.dimension.height} x ${item.dimension.width} x ${item.dimension.depth}`
                    : "N/A"}
            </StyledTableCell>
            <StyledTableCell>
                {formatCurrency({ currencyCode: item.currency ?? "", value: item.price, intl })}
            </StyledTableCell>
            <StyledTableCell>{10}</StyledTableCell>
            <StyledTableCell width={"16%"}>
                <InputField
                    minimum={0}
                    id={`${item.sku}-input-field`}
                    type="number"
                    stepIncrement={10}
                    value={inputValue}
                    onChange={handleQuantityChange}
                    name={""}
                    placeholder="0"
                />
            </StyledTableCell>
            <StyledTableCell width={"16%"}>
                {formatCurrency({
                    currencyCode: item.currency ?? "",
                    value: (lines[item.sku]?.quantity || 0) * item.price,
                    intl,
                })}
            </StyledTableCell>
        </StyledBodyRow>
    )
}

const ShopOrderingPage = () => {
    const { getSelectedAgreement, getToken } = useUser()
    const token = getToken()
    const accountId = getSelectedAgreement()?.account.id
    const [availableProducts, setAvailableProducts] = useState<TotalCostProduct[]>()
    const [lines, setLines] = useState<Record<string, LineProps>>({})
    const [orderSettings, setOrderSettings] = useState<OrderConfiguration>()
    const [showBreakdown, setShowBreakdown] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
    const [orderReceived, setOrderReceived] = useState(false)
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()
    const intl = useIntl()
    const { BlockerAlert, setBlock, isBlocked } = useCustomBlocker()
    const { shopClient } = useClient()

    const toggleBreakdown = () => {
        setShowBreakdown((prev) => !prev)
    }

    useEffect(() => {
        if (!availableProducts && accountId) {
            const fetchProducts = async () => {
                const filters = { active: "true", deleted_at: "" }
                const data = await shopClient.getProducts(accountId, {
                    pageSize: 200,
                    filters: { ...filters },
                })
                if (data) {
                    const items = data.filter((item) => item.active && !item.deleted_at)
                    setAvailableProducts(items as TotalCostProduct[])
                }
            }
            fetchProducts()
        }
    }, [accountId, availableProducts, shopClient, token])

    useEffect(() => {
        if (!orderSettings && accountId) {
            const fetchOrderSettings = async () => {
                const data = await shopClient.getOrderConfiguration(accountId)
                if (data) {
                    setOrderSettings(data)
                }
            }
            fetchOrderSettings()
        }
    }, [accountId, orderSettings, shopClient, token])

    const handleLineUpdate = useCallback(
        (sku: string, quantity: number, cost: number) => {
            setLines((prevLines) => ({
                ...prevLines,
                [sku]: { quantity: quantity, cost: cost },
            }))
            if (!isBlocked) {
                setBlock(true)
            }
        },
        [isBlocked, setBlock],
    )

    const totalPrice = useMemo(() => {
        const totalKeys = Object.keys(lines)
        let total = 0
        totalKeys.forEach((item) => {
            total += lines[item].cost ?? 0
        })
        return total
    }, [lines])

    const endProduct = useMemo(() => {
        if (!orderSettings) {
            return
        }
        return {
            total: totalPrice ?? 0,
            id: "total-item",
            active: false,
            anonymous: false,
            currency: orderSettings.currency,
            dimension: {
                depth: 0,
                height: 0,
                width: 0,
            },
            group: {
                description: "",
                name: "",
            },
            name: intl.formatMessage({ id: "common_total" }),
            price: 0,
            sku: "",
            volume: 0,
            weight: 0,
            bundle_size: 0,
            created_at: new Date(),
            updated_at: new Date(),
            deleted_at: null,
            primary_material: "",
            surface_area: 0,
        } as TotalCostProduct
    }, [intl, orderSettings, totalPrice])

    const productGroups = useMemo(() => {
        if (!orderSettings || !availableProducts || !endProduct) {
            return {}
        }

        const groups: Record<string, TotalCostProduct[]> = {
            "Self-Standing Box": [],
            "Paper Bag": [],
            Bag: [],
            Box: [],
            "Premium Bag": [],
            Accessory: [],
        }

        availableProducts.forEach((product) => {
            if (!groups[product.group.name]) {
                groups[product.group.name] = []
            }
            groups[product.group.name].push(product)
        })

        return groups
    }, [availableProducts, endProduct, orderSettings])

    const groupKeys = useMemo(() => {
        return Object.keys(productGroups)
    }, [productGroups])

    const totalQuantity = useMemo(() => {
        let totalQuantity = 0
        const totalKeys = Object.keys(lines)
        totalKeys.forEach((item) => {
            totalQuantity += lines[item].quantity
        })
        return totalQuantity
    }, [lines])

    const totalCost = useMemo(() => {
        const totalKeys = Object.keys(lines)

        let totalCost = 0
        totalKeys.forEach((item) => {
            totalCost += lines[item].cost
        })
        return totalCost
    }, [lines])

    const costTotal = useMemo(() => {
        const totalKeys = Object.keys(lines)
        if (!orderSettings || totalKeys.length === 0) {
            return { cost: 0, vat: 0, shippingFee: 0 }
        }

        const shippingPrice =
            totalQuantity > 0
                ? totalQuantity * orderSettings.shipping_unit_cost +
                  orderSettings.shipping_base_cost
                : 0

        const vat = (totalCost + shippingPrice) * (orderSettings.vat / 100)

        return { cost: totalCost, vat: vat, shippingFee: shippingPrice }
    }, [lines, orderSettings, totalCost, totalQuantity])

    const itemToRow = useCallback(
        (item: TotalCostProduct, index: number) => {
            return (
                <ProductRow
                    key={`item-${index}`}
                    item={item}
                    index={index}
                    updateLine={handleLineUpdate}
                    totalPrice={totalPrice}
                    lines={lines}
                />
            )
        },
        [handleLineUpdate, lines, totalPrice],
    )

    const openModal = useCallback(() => {
        if (totalQuantity <= 9) {
            return enqueueAlert(
                intl.formatMessage({ id: "minimum_order_quantity_warning" }),
                "warning",
            )
        }
        if (totalQuantity % 10 !== 0) {
            return enqueueAlert(intl.formatMessage({ id: "must_be_multiple_of_MOQ" }), "error")
        }
        if (totalQuantity === 0) {
            return enqueueAlert(intl.formatMessage({ id: "add_more_items_error" }), "error")
        }

        setConfirmationModal(true)
    }, [enqueueAlert, intl, totalQuantity])

    const placeOrder = useCallback(async () => {
        if (!lines || !orderSettings || !accountId) {
            return
        }
        const totalKeys = Object.keys(lines)
        const products: { sku: string; quantity: number }[] = []

        totalKeys.forEach((item) => {
            if (lines[item].quantity > 0) {
                products.push({ sku: item, quantity: lines[item].quantity })
            }
        })

        const data = {
            currency: orderSettings?.currency,
            lines: products,
        }

        const order = await shopClient.postOrder(accountId, data)

        if (order) {
            setBlock(false)
            return setOrderReceived(true)
        } else {
            return enqueueAlert(intl.formatMessage({ id: "something_went_wrong_error" }), "error")
        }
    }, [accountId, enqueueAlert, intl, lines, orderSettings, setBlock, shopClient])

    const dialog = useMemo(() => {
        if (!orderSettings) {
            return
        }
        return (
            <GenericModal
                open={confirmationModal}
                CustomContent={
                    !orderReceived ? (
                        <PriceBreakDown costTotal={costTotal} orderSettings={orderSettings} />
                    ) : (
                        <Box>
                            <Typography>
                                {intl.formatMessage({ id: "orders_order_shipped_soon" })}
                            </Typography>
                        </Box>
                    )
                }
                title={
                    !orderReceived
                        ? intl.formatMessage({ id: "orders_order_details" })
                        : intl.formatMessage({ id: "common_thank_you" })
                }
                onClose={!orderReceived ? () => setConfirmationModal(false) : () => navigate(-1)}
                CustomActions={
                    !orderReceived ? (
                        <Box sx={{ display: "flex", gap: "12px" }}>
                            <CustomButton
                                onClick={() => setConfirmationModal(false)}
                                text={intl.formatMessage({ id: "common_cancel" })}
                                colorTheme={ColorThemeName.warning}
                            />
                            <CustomButton
                                onClick={() => placeOrder()}
                                text={intl.formatMessage({ id: "common_confirm" })}
                            />
                        </Box>
                    ) : (
                        <Box>
                            <CustomButton
                                onClick={() => navigate(-1)}
                                text={intl.formatMessage({ id: "common_great" })}
                            />
                        </Box>
                    )
                }
            />
        )
    }, [confirmationModal, costTotal, intl, navigate, orderReceived, orderSettings, placeOrder])
    const header = [
        { key: "name", label: intl.formatMessage({ id: "common_product" }) },
        {
            key: "sku",
            label: intl.formatMessage({ id: "common_sku" }),
            excludeFromSearch: true,
        },
        {
            key: "size",
            label: intl.formatMessage({ id: "common_size" }),
            excludeFromSearch: true,
        },
        {
            key: "item_cost",
            label: intl.formatMessage({ id: "common_unit_price" }),
            excludeFromSearch: true,
        },
        {
            key: "moq",
            label: intl.formatMessage({ id: "common_moq" }),
            excludeFromSearch: true,
        },
        {
            key: "quantity",
            label: intl.formatMessage({ id: "common_quantity" }),
            excludeFromSearch: true,
        },
        {
            key: "cost",
            label: intl.formatMessage({ id: "common_cost" }),
            excludeFromSearch: true,
        },
    ]

    if (!orderSettings) {
        return <></>
    }

    return (
        <Page title={intl.formatMessage({ id: "ordering_rezip_title" })}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                {groupKeys.map((item) => {
                    if (productGroups[item].length === 0) {
                        return
                    }

                    const itemWIthremovedSpaceAtEnd = item.endsWith(" ") ? item.slice(0, -1) : item
                    return (
                        <DefaultWrapperPaper>
                            <Grid container spacing={1} direction={"column"}>
                                <Typography variant="h3">
                                    {item !== "Accessory"
                                        ? intl.formatMessage(
                                              {
                                                  id: `order_plural_item`,
                                              },
                                              {
                                                  item: itemWIthremovedSpaceAtEnd,
                                                  suffix: itemWIthremovedSpaceAtEnd.includes("x")
                                                      ? "es"
                                                      : item.endsWith("s")
                                                        ? ""
                                                        : "s",
                                              },
                                          )
                                        : intl.formatMessage({ id: "order_accessories" })}
                                </Typography>
                                <AllInOneTable<TotalCostProduct>
                                    headers={header}
                                    items={productGroups[item] ?? []}
                                    itemsToRow={itemToRow}
                                    defaultSortBy={"sku"}
                                    hideSearchBar
                                    bottomRow={endProduct ? itemToRow(endProduct, 10) : undefined}
                                />
                            </Grid>
                        </DefaultWrapperPaper>
                    )
                })}

                <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
                    <Card
                        sx={{
                            display: "flex",
                            gap: "12px",
                            justifyContent: "space-between",
                            position: "fixed",
                            right: 40,
                            bottom: 0,
                            minWidth: "320px",
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "12px",
                                width: "100%",
                            }}>
                            {showBreakdown && orderSettings && (
                                <PriceBreakDown
                                    costTotal={costTotal}
                                    orderSettings={orderSettings}
                                />
                            )}

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "12px",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "70%",
                                    }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}>
                                        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                                            {intl.formatMessage({ id: "total_label" })}
                                        </Typography>
                                        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                                            {formatCurrency({
                                                currencyCode: orderSettings?.currency,
                                                value: costTotal.cost + costTotal.shippingFee,
                                                intl: intl,
                                            })}
                                        </Typography>
                                    </Box>

                                    <Typography
                                        sx={{ fontSize: "10px", cursor: "pointer" }}
                                        onClick={toggleBreakdown}>
                                        {intl.formatMessage({ id: "click_here_to_view_breakdown" })}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        width: "150px",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}>
                                    <CustomButton
                                        onClick={openModal}
                                        text={intl.formatMessage({ id: "place_order_button" })}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
            {dialog}
            <BlockerAlert />
        </Page>
    )
}

export default ShopOrderingPage
