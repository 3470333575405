import { MouseEvent, useState, useEffect } from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { Tooltip } from "@mui/material"
import { actionGreen, ecoGreen, ecoGreenLight } from "../colors"

// eslint-disable-next-line react-refresh/only-export-components
export enum ColorThemeName {
    standard = "standard",
    standardOutline = "standardOutline",
    ecoGreen = "ecoGreen",
    noBackground = "noBackground",
    warning = "warning",
    actionGreen = "actionGreen",
}

// eslint-disable-next-line react-refresh/only-export-components
export enum IconFigure {
    plus = "plus",
    delete = "delete",
    view = "view",
    edit = "edit",
    packaging = "packaging",
}

// eslint-disable-next-line react-refresh/only-export-components
export enum ButtonType {
    button = "button",
    submit = "submit",
    reset = "reset",
}

type ButtonTheme = {
    colorCode: string
    backgroundColor: string
    borderTop: string
    borderBottom: string
    borderLeft: string
    borderRight: string
    borderColor: string
    borderStyle: string
    hoverBackgroundColor: string
    disabled?: string
    hoverTextColor?: string
}

type Props = {
    text: string
    icon?: IconFigure
    colorTheme?: ColorThemeName
    type?: ButtonType
    disabled?: boolean | undefined
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void
    tooltipText?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles?: any
    autoFocus?: boolean
}

const ButtonThemes: Record<ColorThemeName, ButtonTheme> = {
    standard: {
        colorCode: "primary.contrastText",
        backgroundColor: "primary.main",
        borderTop: "none",
        borderLeft: "none",
        borderBottom: "none",
        borderRight: "none",
        borderColor: "none",
        borderStyle: "none",
        hoverBackgroundColor: "primary.dark",
        hoverTextColor: "primary.main",
        disabled: "primary.disabled",
    },
    ecoGreen: {
        colorCode: "primary.main",
        backgroundColor: ecoGreen,
        borderTop: "none",
        borderLeft: "none",
        borderBottom: "none",
        borderRight: "none",
        borderColor: "none",
        borderStyle: "none",
        hoverBackgroundColor: ecoGreenLight,
        hoverTextColor: "primary.main",
        disabled: "primary.disabled",
    },
    standardOutline: {
        colorCode: "standardOutlineButton.contrastText",
        backgroundColor: "transparent",
        borderTop: "1px",
        borderLeft: "1px",
        borderBottom: "1px",
        borderRight: "1px",
        borderColor: "standardOutlineButton.main",
        borderStyle: "solid",
        hoverBackgroundColor: "standardOutlineButton.main",
        hoverTextColor: "standardOutlineButton.light",
    },
    noBackground: {
        colorCode: "primary.contrastText",
        backgroundColor: "inherit",
        borderTop: "none",
        borderLeft: "none",
        borderBottom: "none",
        borderRight: "none",
        borderColor: "none",
        borderStyle: "none",
        hoverBackgroundColor: "inherit",
    },
    warning: {
        colorCode: "warning.contrastText",
        backgroundColor: "warning.main",
        borderTop: "none",
        borderLeft: "none",
        borderBottom: "none",
        borderRight: "none",
        borderColor: "none",
        borderStyle: "none",
        hoverBackgroundColor: "warning.light",
        hoverTextColor: "warning.main",
    },
    actionGreen: {
        colorCode: ecoGreen,
        backgroundColor: actionGreen,
        borderTop: "none",
        borderLeft: "none",
        borderBottom: "none",
        borderRight: "none",
        borderColor: "none",
        borderStyle: "none",
        hoverBackgroundColor: actionGreen,
    },
}

export const CustomButton = ({
    text,
    icon,
    colorTheme,
    disabled,
    type,
    onClick,
    tooltipText,
    styles = {},
    autoFocus = false,
}: Props) => {
    const [buttonColor, setButtonColor] = useState<ButtonTheme>({
        colorCode: "",
        backgroundColor: "",
        borderTop: "",
        borderBottom: "",
        borderLeft: "",
        borderRight: "",
        borderColor: "",
        borderStyle: "",
        hoverBackgroundColor: "",
        disabled: "",
    })
    const [iconColor, setIconColor] = useState<string>("")
    const [iconPath, setIconPath] = useState<string>("")
    const [iconSize, setIconSize] = useState<string>("")

    // To find the right color
    useEffect(() => {
        switch (colorTheme) {
            case ColorThemeName.standard:
                setButtonColor(ButtonThemes.standard)
                setIconColor("green")
                break
            case ColorThemeName.standardOutline:
                setButtonColor(ButtonThemes.standardOutline)
                setIconColor("black")
                break
            case ColorThemeName.ecoGreen:
                setButtonColor(ButtonThemes.ecoGreen)
                setIconColor("green")
                break
            case ColorThemeName.noBackground:
                setButtonColor(ButtonThemes.noBackground)
                setIconColor("black")
                break
            case ColorThemeName.warning:
                setButtonColor(ButtonThemes.warning)
                setIconColor("red")
                break
            case ColorThemeName.actionGreen:
                setButtonColor(ButtonThemes.actionGreen)
                setIconColor("green")
                break
            default:
                setButtonColor(ButtonThemes.standard)
                setIconColor("green")
        }
    }, [colorTheme])

    // To find the right Icon
    useEffect(() => {
        switch (icon) {
            case IconFigure.plus:
                setIconPath("/Icons/icons_plus.svg")
                setIconSize("20px")
                break
            case IconFigure.delete:
                setIconPath("/Icons/Icons_" + iconColor + "_Trashcan.png")
                setIconSize("20px")
                break
            case IconFigure.view:
                setIconPath("/Icons/icons_view.svg")
                setIconSize("16px")
                break
            case IconFigure.edit:
                setIconPath("/Icons/icons_edit.svg")
                setIconSize("14px")
                break
            case IconFigure.packaging:
                setIconPath("/Icons/Icons_" + iconColor + "_Regular_Box.png")
                setIconSize("20px")
                break
        }
    }, [iconColor, icon])

    const StyledButton = () => {
        return tooltipText ? (
            <Tooltip title={tooltipText}>
                <span>
                    <Button
                        autoFocus={autoFocus}
                        sx={{
                            color: buttonColor.colorCode,
                            backgroundColor: buttonColor.backgroundColor,
                            padding: "3px 13px",
                            borderTop: buttonColor.borderTop,
                            borderBottom: buttonColor.borderBottom,
                            borderLeft: buttonColor.borderLeft,
                            borderRight: buttonColor.borderRight,
                            borderColor: buttonColor.borderColor,
                            borderStyle: buttonColor.borderStyle,
                            ":hover": {
                                backgroundColor: buttonColor.hoverBackgroundColor,
                            },
                            ":disabled": {
                                backgroundColor: buttonColor.disabled,
                                color: "text.disabled",
                            },
                            borderRadius: "6px",
                            ...styles,
                        }}
                        onClick={onClick}
                        type={type ?? "button"}
                        disabled={disabled ? true : false}>
                        {icon ? <img src={iconPath} height={iconSize} width={iconSize} /> : null}
                        <Typography sx={{ textTransform: "none" }} variant="button">
                            {text}
                        </Typography>
                    </Button>
                </span>
            </Tooltip>
        ) : (
            <Button
                autoFocus={autoFocus}
                sx={{
                    color: buttonColor.colorCode,
                    backgroundColor: buttonColor.backgroundColor,
                    padding: "3px 13px",
                    borderTop: buttonColor.borderTop,
                    borderBottom: buttonColor.borderBottom,
                    borderLeft: buttonColor.borderLeft,
                    borderRight: buttonColor.borderRight,
                    borderColor: buttonColor.borderColor,
                    borderStyle: buttonColor.borderStyle,
                    ":hover": {
                        backgroundColor: buttonColor.hoverBackgroundColor,
                        color: buttonColor.hoverTextColor,
                    },
                    ":disabled": {
                        backgroundColor: buttonColor.disabled,
                        color: "text.disabled",
                    },
                    borderRadius: "6px",
                    ...styles,
                }}
                onClick={onClick}
                type={type ?? "button"}
                disabled={disabled ? true : false}>
                {icon ? <img src={iconPath} height={iconSize} width={iconSize} /> : null}
                <Typography sx={{ textTransform: "none", fontWeight: "700" }} variant="button">
                    {text}
                </Typography>
            </Button>
        )
    }
    return <StyledButton />
}
