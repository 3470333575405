import { CustomButton, ColorThemeName } from "src/components/generalComponents/Buttons"
import { useNavigate } from "react-router-dom"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { useUser } from "src/contexts/UserConsumer"
import { useIntl } from "react-intl"

export default function NoAccessPage() {
    const navigate = useNavigate()
    const { removeUser, logout } = useUser()
    const intl = useIntl()
    const goToLogin = () => {
        removeUser()
        logout()
        navigate("/login")
    }

    const goBack = () => {
        navigate("/home")
    }

    return (
        <Box
            sx={{
                backgroundColor: "coreBackground.main",
                height: "100vh",
            }}>
            <Box
                sx={{
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "coreBackground.main",
                }}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography variant="h2">
                        {intl.formatMessage({ id: "common_no_access" })}
                    </Typography>
                    <Typography variant="body1">
                        {intl.formatMessage({ id: "common_no_permission" })}
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            marginTop: "10px",
                        }}>
                        <CustomButton
                            text={intl.formatMessage({ id: "back_to_home" })}
                            colorTheme={ColorThemeName.standardOutline}
                            onClick={goBack}
                        />
                        <Box
                            sx={{
                                marginLeft: "20px",
                            }}>
                            <CustomButton
                                text={intl.formatMessage({ id: "common_logout" })}
                                colorTheme={ColorThemeName.standardOutline}
                                onClick={goToLogin}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
