import { StatisticsEntry } from "@repo/rezip-client/reporting_client"

export interface Summary {
    accounts: string
    startDate: Date
    endDate: Date
}
export interface Scan {
    accounts: string
    type: string
    startDate?: Date
    endDate?: Date
}
export interface item {
    sku: string
    reclaim: number
    fulfill: number
    reward: number
    waste: number
}
export interface summaryItems {
    type: string
    scans: number
}
export type MapOfItems = {
    [id: string]: {
        reclaim: number
        fulfill: number
        reward: number
        waste: number
    }
}

export const defualtSummary: Summary = {
    accounts: "All",
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    endDate: new Date(),
}
export const defaultScanData: Scan = {
    accounts: "All",
    type: "placeholder",
    startDate: undefined,
    endDate: undefined,
}
export type PerSkuData = {
    produced: number
    reused: number
    recycled: number
    shipped: number
    transported_km: number
}

export type PerSku = {
    [sku: string]: PerSkuData
}

export type MappedPerSku = {
    sku: string
    produced: number
    reused: number
    recycled: number
}
export interface EnvStats {
    time_increment: string
    transport_co2e: number
    transport_water: number
    production_co2e: number
    production_water: number
    destruction_co2e: number
    destruction_water: number
    assembly_co2e: number
    assembly_water: number
    sorting_co2e: number
    sorting_water: number
    waste_kg: number
    per_sku: { [sku: string]: PerSkuData }
}
export interface EnvStatsWIthTotal {
    each: EnvStats[]
    sum: EnvStats
}

export interface EnhancedEnvStats {
    each: StatisticsEntry[]
    sum: SummedTotals
}

export interface ReturnStats {
    reused: number
    recycled: number
    reclailmed: number
}

export interface EnvironmentDataObject {
    uData: number[]
    pData: number[]
}

export interface EnvironmentData {
    rezipDataPoints: {
        co2: EnvironmentDataObject
        water: EnvironmentDataObject
        waste: EnvironmentDataObject
    }
    totalCollectedData: {
        recycled: number[]
        reclaimed: number[]
        reused: number[]
        fulFilled: number[]
        produced: number[]
    }
    perSku: PerSku
}
export interface GroupedEnvData {
    [id: string]: EnvironmentData
}

export interface SummedTotals {
    totalWater: number
    totalWaste: number
    totalCo2: number
    perSku: PerSku
}

export interface SimpleProducts {
    primary_material: string | null
    surface_area: number | null
    sku: string
    weight: number
}

export interface ReportingChartsProps {
    startDate?: Date
    endDate?: Date
    shopId: string
    timeFrame: string
    envStats?: EnvironmentData
    summedEnvStats?: SummedTotals
    labels?: string[]
    simpleProducts?: SimpleProducts[]
    dateUpdated: boolean
    setDateUpdated: (bool: boolean) => void
    graphsReady: boolean
}

export const DAY = 86400
export const WEEK = DAY * 7
export const MONTH = DAY * 30
export const QUATER = MONTH * 3
export const YEAR = DAY * 365
