import { z } from "zod"
import {
    baseApiErrorHandling,
    defaultQueringOptions,
    OptionalQueryingOptions,
    ReZipClient,
} from "."
import { PermissionGroupClient } from "./permission_group_client"
import {
    Address,
    Agreement,
    AgreementPostType,
    BaseUserAgreement,
    date,
    PatchType,
    ULID,
    UserAgreementAccount,
} from "./types"

const defaultedString = z
    .string()
    .nullable()
    .optional()
    .transform((x) => x || "")

export const DropPointLocation = z.object({
    id: z.string(),
    drop_point_id: z.string(),
    external_id: z.string().nullable(),
    name: z.string(),
    billing_address: Address,
    billing_email: defaultedString,
    shipping_address: Address,
    shipping_email: defaultedString,
    vat_no: defaultedString,
    opening_hours: (() => {
        const inner = z.object({ opens_at: z.string(), closes_at: z.string() }).nullable()
        return z.object({
            monday: inner,
            tuesday: inner,
            wednesday: inner,
            thursday: inner,
            friday: inner,
            saturday: inner,
            sunday: inner,
        })
    })(),
    opening_hours_notes: z.string().nullable(),
    created_at: date,
    updated_at: date,
    deleted_at: date.nullable(),
})

export type DropPointLocation = z.infer<typeof DropPointLocation>

export const DropPoint = z.object({
    id: z.string(),
    name: z.string(),
    billing_address: Address,
    billing_email: z.string().nullable(),
    shipping_address: Address,
    shipping_email: z.string().nullable(),
    created_at: date,
    updated_at: date,
})

export type DropPoint = z.infer<typeof DropPoint>

export class DropPointClient {
    rezipClient: ReZipClient
    constructor(rezipClient: ReZipClient) {
        this.rezipClient = rezipClient
    }

    permissionGroups(dropPointId: ULID): PermissionGroupClient {
        return new PermissionGroupClient(this.rezipClient, `drop_points/${dropPointId}`)
    }

    async locations(
        dropPointId: ULID,
        options: OptionalQueryingOptions,
    ): Promise<DropPointLocation[]> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `drop_points/${dropPointId}/locations`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(DropPointLocation).parse(body)
    }

    async location({
        dropPointId,
        locationId,
    }: {
        dropPointId: ULID
        locationId: ULID
    }): Promise<DropPointLocation | null> {
        const response = await this.rezipClient.get({
            path: `drop_points/${dropPointId}/locations/${locationId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return DropPointLocation.parse(await response.json())
    }

    async createLocation(obj: PatchType<DropPointLocation>): Promise<DropPointLocation> {
        const response = await this.rezipClient.post({
            path: `drop_points/${obj.drop_point_id}/locations`,
            body: JSON.stringify(obj),
        })
        await baseApiErrorHandling(response)
        return DropPointLocation.parse(await response.json())
    }

    async updateLodation(id: ULID, obj: PatchType<DropPointLocation>): Promise<DropPointLocation> {
        const response = await this.rezipClient.patch({
            path: `drop_points/${obj.drop_point_id}/locations/${id}`,
            body: JSON.stringify(obj),
        })
        await baseApiErrorHandling(response)
        return DropPointLocation.parse(await response.json())
    }

    async deleteLocation({
        dropPointId,
        locationId,
    }: {
        dropPointId: ULID
        locationId: ULID
    }): Promise<void> {
        const response = await this.rezipClient.delete({
            path: `drop_points/${dropPointId}/locations/${locationId}`,
        })
        await baseApiErrorHandling(response)
    }

    async getDropPoint(dropPointId: ULID): Promise<DropPoint | null> {
        const response = await this.rezipClient.get({
            path: `/drop_points/${dropPointId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return DropPoint.parse(await response.json())
    }

    async updateDropPoint(dropPointId: ULID, body: PatchType<DropPoint>): Promise<DropPoint> {
        const response = await this.rezipClient.patch({
            path: `/drop_points/${dropPointId}`,
            body: JSON.stringify(body),
        })
        await baseApiErrorHandling(response)
        return DropPoint.parse(await response.json())
    }

    async getAgreements(dropPointId: ULID, options: OptionalQueryingOptions): Promise<Agreement[]> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `/drop_points/${dropPointId}/agreements`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(Agreement).parse(body)
    }
    async postAgreement(
        partnerId: ULID,
        args: PatchType<AgreementPostType>,
    ): Promise<BaseUserAgreement | null> {
        const response = await this.rezipClient.post({
            path: `/drop_points/${partnerId}/agreements`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return BaseUserAgreement.parse(body)
    }

    async patchAgreement(
        partnerId: ULID,
        agreementId: ULID,
        owner: boolean,
        mfa_required: boolean,
    ): Promise<UserAgreementAccount | null> {
        const response = await this.rezipClient.patch({
            path: `/drop_points/${partnerId}/agreements/${agreementId}`,
            body: JSON.stringify({ owner: owner, mfa_required: mfa_required }),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return UserAgreementAccount.parse(body)
    }

    async deleteAgreement(partnerId: ULID, agreementId: ULID): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `/drop_points/${partnerId}/agreements/${agreementId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }

    async getAgreement(partnerId: ULID, agreementId: ULID): Promise<UserAgreementAccount | null> {
        const response = await this.rezipClient.get({
            path: `/drop_points/${partnerId}/agreements/${agreementId}`,
        })
        if (response.status === 404) {
            return null
        }
        const body = await response.json()
        return UserAgreementAccount.parse(body)
    }
}
