import { useIntl } from "react-intl"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"

import { useCallback, useEffect, useMemo, useState } from "react"
import { useClient } from "src/hooks/useClient.hook"
import { MotherShipProduct, Partner, ProductGroup } from "@repo/rezip-client/mothership_client"
import { Assortment, PatchType } from "@repo/rezip-client/types"
import { Box, SelectChangeEvent, Switch, Typography, Grid } from "@mui/material"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import CollapseInformation from "src/components/generalComponents/CollapseInformation"
import { StyledP } from "src/components/styles"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"
import { ClientError } from "@repo/rezip-client/client_error"

const ItemRow = ({
    item,
    index,
    assortment,
    selectedPartner,
    removeAssortment,
    addAssortment,
}: {
    item: MotherShipProduct
    index: number
    assortment: Assortment[]
    selectedPartner: string | null
    removeAssortment: (assortmentId: string) => void
    addAssortment: (assortment: PatchType<Assortment>) => void
}) => {
    const itemActive = useMemo(() => {
        const find = assortment.find((assortmentItem) => assortmentItem.sku === item.sku)
        if (find) {
            return true
        }
        return false
    }, [assortment, item.sku])

    const handleSwitch = useCallback(async () => {
        console.log(itemActive)
        if (itemActive) {
            const find = assortment.find((assortmentItem) => assortmentItem.sku === item.sku)
            if (find) {
                removeAssortment(find.id)
            }
            return
        } else
            addAssortment({
                sku: item.sku,
                name: item.name,
                price: item.price,
                active: item.active,
            })
        return
    }, [
        addAssortment,
        assortment,
        item.active,
        item.name,
        item.price,
        item.sku,
        itemActive,
        removeAssortment,
    ])

    return (
        <StyledBodyRow key={`product-${item.id}-${index}`}>
            <StyledTableCell width={"20%"}>{item.name}</StyledTableCell>
            <StyledTableCell>{item.sku}</StyledTableCell>
            <StyledTableCell id={`${index}-Size`}>
                {item.dimension &&
                item.dimension.height &&
                item.dimension.width &&
                item.dimension.depth
                    ? `${item.dimension.height} x ${item.dimension.width} x ${item.dimension.depth}`
                    : "N/A"}
            </StyledTableCell>
            <StyledTableCell>{item.bundle_size}</StyledTableCell>
            {selectedPartner ? (
                <StyledTableCell>
                    <Switch checked={itemActive} onClick={() => handleSwitch()} />
                </StyledTableCell>
            ) : null}
        </StyledBodyRow>
    )
}

const ProductConfiguration = () => {
    const [initialLoad, setInitialLoad] = useState(true)
    const [partners, setPartners] = useState<Partner[] | null>(null)
    const [products, setProducts] = useState<MotherShipProduct[] | null>(null)
    const [partnerAssortments, setPartnerAssortments] = useState<Record<
        string,
        Assortment[]
    > | null>(null)
    const [productGroups, setProductGroups] = useState<ProductGroup[] | null>(null)
    const { mothershipClient } = useClient()
    const intl = useIntl()
    const [selectedPartner, setSelectedPartner] = useState<string | null>(null)
    const { enqueueAlert } = useSnackbar()

    const addAssortment = useCallback(
        async (assortment: PatchType<Assortment>) => {
            if (!assortment || !selectedPartner) {
                return
            }
            try {
                const response = await mothershipClient.postAssortmentToPartner(
                    selectedPartner,
                    assortment,
                )
                if (response) {
                    enqueueAlert("Product is now available", "success")

                    setPartnerAssortments((prev) => {
                        if (!prev) return { [selectedPartner]: [response] }

                        return {
                            ...prev,
                            [selectedPartner]: [...(prev[selectedPartner] || []), response],
                        }
                    })
                }
            } catch (error) {
                const mappedError = errorConverter(error)
                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                return displayErrorMessage(mappedError, enqueueAlert)
            }
        },
        [enqueueAlert, mothershipClient, selectedPartner],
    )

    const removeAssortment = useCallback(
        async (assortmentId: string) => {
            if (!assortmentId || !selectedPartner || !partnerAssortments) {
                return
            }
            try {
                const response = await mothershipClient.deleteAssortmentFromPartner(
                    selectedPartner,
                    assortmentId,
                )
                if (response) {
                    enqueueAlert("Product has been removed", "success")

                    const updatedData = partnerAssortments[selectedPartner].filter(
                        (item) => item.id !== assortmentId,
                    )

                    setPartnerAssortments((prev) => {
                        if (!prev || !prev[selectedPartner]) {
                            return prev
                        }

                        return {
                            ...prev,
                            [selectedPartner]: updatedData,
                        }
                    })
                }
            } catch (error) {
                const mappedError = errorConverter(error)
                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                return displayErrorMessage(mappedError, enqueueAlert)
            }
        },
        [enqueueAlert, mothershipClient, partnerAssortments, selectedPartner],
    )

    useEffect(() => {
        if (!productGroups) {
            const fetch = async () => {
                const response = await mothershipClient.getProductGroups({ pageSize: 99 })
                if (response) {
                    setProductGroups(response)
                }
            }
            fetch()
        }
    }, [mothershipClient, productGroups])

    useEffect(() => {
        if (!partners) {
            const fetch = async () => {
                const response = await mothershipClient.getPartners({ pageSize: 99 })
                if (response) {
                    setPartners(response)
                }
            }
            fetch()
        }
    }, [mothershipClient, partners])

    useEffect(() => {
        if (!products) {
            const fetch = async () => {
                const response = await mothershipClient.getProducts({ pageSize: 99 })
                if (response) {
                    setProducts(response)
                }
            }
            fetch()
        }
    }, [mothershipClient, products, setProducts])

    useEffect(() => {
        if (initialLoad && partners && products && productGroups) {
            const fetch = async () => {
                const partnerAssortments: Record<string, Assortment[]> = {}
                for (const partner of partners) {
                    const response = await mothershipClient.getPartnerAssortment(partner.id, {
                        pageSize: 99,
                    })
                    if (response) {
                        partnerAssortments[partner.id] = response.filter((item) => !item.deleted_at)
                    }
                }
                setPartnerAssortments(partnerAssortments)
            }
            fetch()
        }
    }, [initialLoad, mothershipClient, partners, productGroups, products])

    const groups = useMemo(() => {
        if (!products || !productGroups) {
            return {}
        }

        const groups: Record<string, MotherShipProduct[]> = {
            "Self-Standing Box": [],
            "Paper Bag": [],
            Bag: [],
            Box: [],
            "Premium Bag": [],
            Accessory: [],
        }

        products.forEach((product) => {
            const group = productGroups.find((item) => item.id === product.group_id)
            if (!group) {
                return
            }
            if (!groups[group.name]) {
                groups[group.name] = []
            }
            groups[group.name].push(product)
        })

        return groups
    }, [productGroups, products])

    const groupKeys = useMemo(() => {
        return Object.keys(groups)
    }, [groups])

    const header = [
        {
            label: intl.formatMessage({
                id: "common_product",
            }),
            key: "name",
        },
        {
            label: intl.formatMessage({
                id: "common_sku",
            }),
            key: "SKU",
        },
        {
            label: intl.formatMessage({
                id: "common_size",
            }),
            key: "Size",
        },
        {
            label: intl.formatMessage({
                id: "common_moq",
            }),
            key: "MOQ",
        },
    ]
    const partnerMenuItems: IMenuItemData[] = useMemo(() => {
        if (!partners) {
            return []
        }
        return partners.map((partner) => {
            return {
                id: partner.id,
                label: partner.name,
                value: partner.name,
            }
        })
    }, [partners])

    const itemToRow = useCallback(
        (item: MotherShipProduct, index: number) => {
            return (
                <ItemRow
                    key={`${item.id}-wrapper`}
                    item={item}
                    index={index}
                    assortment={
                        partnerAssortments && selectedPartner
                            ? partnerAssortments[selectedPartner]
                            : []
                    }
                    selectedPartner={selectedPartner}
                    removeAssortment={removeAssortment}
                    addAssortment={addAssortment}
                />
            )
        },
        [partnerAssortments, selectedPartner, removeAssortment, addAssortment],
    )

    const onPartnerChange = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            const partner = partnerMenuItems.find((item) => item.id === e.target.value)
            if (partner) {
                setSelectedPartner(partner.id)
                setInitialLoad(true)
            }
        },
        [partnerMenuItems],
    )

    return (
        <Page title={intl.formatMessage({ id: "mothership_product_configuration" })}>
            <CollapseInformation
                header={intl.formatMessage({
                    id: "mothership_product_configuration_infobox_header",
                })}>
                <Box>
                    <StyledP>
                        {intl.formatMessage({
                            id: "mothership_product_configuration_infobox_page_info",
                        })}
                    </StyledP>
                </Box>
                <Box>
                    <StyledP>
                        {intl.formatMessage({
                            id: "mothership_product_configuration_infobox_usage",
                        })}
                    </StyledP>
                </Box>
                <Box>
                    <StyledP>
                        {intl.formatMessage({
                            id: "mothership_product_configuration_infobox_availlable",
                        })}
                    </StyledP>
                </Box>
                <Box>
                    <StyledP>
                        {intl.formatMessage({
                            id: "mothership_product_configuration_infobox_availlable_warning",
                        })}
                    </StyledP>
                </Box>
            </CollapseInformation>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <Grid container size={{ lg: 12, md: 10, sm: 8 }} spacing={2}>
                    <Grid size={{ lg: 12, md: 10, sm: 8 }}>
                        <DefaultWrapperPaper>
                            <SelectField
                                placeholder="Select Partner"
                                required
                                value={selectedPartner}
                                onChange={onPartnerChange}
                                menuItems={partnerMenuItems}
                                label="Select Partner"
                            />
                        </DefaultWrapperPaper>
                    </Grid>
                </Grid>
                {groupKeys.map((item) => {
                    if (groups[item].length === 0) {
                        return
                    }
                    return (
                        <DefaultWrapperPaper key={`${item}`}>
                            <Grid container spacing={1} direction={"column"}>
                                <Typography variant="h3">
                                    {item !== "Accessory"
                                        ? intl.formatMessage(
                                              {
                                                  id: `order_plural_item`,
                                              },
                                              {
                                                  item: item,
                                                  suffix: item.endsWith("x")
                                                      ? "es"
                                                      : item.endsWith("s")
                                                        ? ""
                                                        : "s",
                                              },
                                          )
                                        : intl.formatMessage({ id: "order_accessories" })}
                                </Typography>
                                <AllInOneTable<MotherShipProduct>
                                    headers={
                                        selectedPartner
                                            ? header.concat({
                                                  label: intl.formatMessage({
                                                      id: "common_availlable",
                                                  }),
                                                  key: "availlable",
                                              })
                                            : header
                                    }
                                    items={groups[item] ?? []}
                                    itemsToRow={itemToRow}
                                    defaultSortBy={"sku"}
                                    hideSearchBar
                                    initialLoad={initialLoad}
                                    setInitialLoad={setInitialLoad}
                                />
                            </Grid>
                        </DefaultWrapperPaper>
                    )
                })}
            </Box>
        </Page>
    )
}
export default ProductConfiguration
