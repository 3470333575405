import { SelectChangeEvent, styled, Switch, Table, Typography, Grid } from "@mui/material"

import { FormattedMessage, useIntl } from "react-intl"

import Page from "src/components/layout/Page"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import useStorageUnitPageController from "./storageUnitPage.controller"
import BundleHandler from "src/components/stockKeepingComponents/BundleHandler"
import { useMemo, useState } from "react"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import { StorageUnit } from "@repo/rezip-client/partner_client"
import GenericModal from "src/components/modals/GenericModal"
import { useParams } from "react-router-dom"
import BarcodeScanInput from "src/components/BarcodeScanInputField"
import SelectField from "src/components/SelectField"
import InputField from "src/components/InputField"
import IdCell from "src/components/table/IdCell"
import { CustomHelp } from "src/components/generalComponents/CustomHelp"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"

export const StyledBox = styled(Grid)({
    display: "flex",
    alignItems: "center",
    minWidth: "230px",
    padding: 0,
})
export const ExtraStyledTableCell = styled(StyledTableCell)({
    padding: "4px 8px",
    alignItems: "center",
    alignContent: "center",
    width: "260px",
})
export interface StorageProps {
    create: boolean
}

const StoragePage = () => {
    const { id, stationId } = useParams()

    const {
        createStorageUnit,
        selectedType,
        selectedSku,
        selectedStation,
        setSelectedStation,
        description,
        setDescription,
        searchTerm,
        setSearchTerm,
        productsAsMenuItems,
        stationMenuItems,
        templatesAsMenuItems,
        addPackaging,
        removePackaging,
        useIds,
        setUseIds,
        selectType,
        handleQuantity,
        deleteStorageUnit,
        selectSku,
        storageUnit,
        BlockerAlert,
        setBlock,
    } = useStorageUnitPageController(id, stationId)

    const intl = useIntl()
    const [itemToDelete, setItemToDelete] = useState<StorageUnit | null>(null)
    const disbaleButton = useMemo(() => {
        return !id ? !(selectedSku && selectedType && searchTerm !== "") : false
    }, [id, selectedSku, selectedType, searchTerm])

    const stationChange = (e: SelectChangeEvent<unknown>) => {
        const item = stationMenuItems.find((p) => p.id === e.target.value)
        if (item) {
            setSelectedStation(item)
            setBlock(true)
        }
    }

    return (
        <Page backButton additionalBackAction={() => setSearchTerm("")}>
            <Grid container justifyContent="center" spacing={2}>
                <Grid
                    container
                    direction="column"
                    size={{ md: 12, lg: 7 }}
                    alignSelf={storageUnit ? "center" : ""}>
                    <DefaultWrapperPaper>
                        <Grid container direction="column" spacing={2}>
                            <Grid container justifyContent="space-between">
                                <Typography variant="h4">
                                    <FormattedMessage
                                        id={
                                            id
                                                ? intl.formatMessage({
                                                      id: "storage_page_edit_title",
                                                  })
                                                : intl.formatMessage({
                                                      id: "storage_page_create_title",
                                                  })
                                        }
                                    />
                                </Typography>
                                {storageUnit && (
                                    <CustomHelp
                                        title={intl.formatMessage({
                                            id: "storage_unit_settings_tooltip",
                                        })}
                                    />
                                )}
                            </Grid>

                            <Table>
                                <tbody>
                                    <StyledBodyRow>
                                        <ExtraStyledTableCell>
                                            <Grid container spacing={1}>
                                                <FormattedMessage id="storage_unit_settings_items_id" />
                                                <CustomHelp
                                                    title={intl.formatMessage({
                                                        id: "storage_unit_settings_items_id_tooltip",
                                                    })}
                                                />
                                            </Grid>
                                        </ExtraStyledTableCell>
                                        <ExtraStyledTableCell>
                                            <Switch
                                                checked={useIds}
                                                onClick={() => setUseIds(!useIds)}
                                                disabled={
                                                    storageUnit ? storageUnit?.quantity >= 1 : false
                                                }
                                            />
                                        </ExtraStyledTableCell>
                                    </StyledBodyRow>
                                    {storageUnit ? (
                                        <StyledBodyRow>
                                            <ExtraStyledTableCell>
                                                <FormattedMessage id="common_status" />
                                            </ExtraStyledTableCell>
                                            <ExtraStyledTableCell>
                                                <SelectField
                                                    value={
                                                        storageUnit.completed
                                                            ? intl.formatMessage({
                                                                  id: "common_closed",
                                                              })
                                                            : intl.formatMessage({
                                                                  id: "common_open",
                                                              })
                                                    }
                                                    menuItems={[
                                                        {
                                                            id: intl.formatMessage({
                                                                id: "common_open",
                                                            }),
                                                            value: intl.formatMessage({
                                                                id: "common_open",
                                                            }),
                                                        },
                                                        {
                                                            id: intl.formatMessage({
                                                                id: "common_closed",
                                                            }),
                                                            value: intl.formatMessage({
                                                                id: "common_closed",
                                                            }),
                                                        },
                                                    ]}
                                                    disabled={true}
                                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                    onChange={() => {}}
                                                    large
                                                />
                                            </ExtraStyledTableCell>
                                        </StyledBodyRow>
                                    ) : null}

                                    <StyledBodyRow>
                                        <ExtraStyledTableCell>
                                            <FormattedMessage
                                                id={!id ? "common_type_required" : "common_type"}
                                            />
                                        </ExtraStyledTableCell>
                                        <ExtraStyledTableCell>
                                            <SelectField
                                                value={selectedType ? selectedType.id : ""}
                                                onChange={selectType}
                                                menuItems={templatesAsMenuItems}
                                                disabled={!!id}
                                                placeholder={intl.formatMessage({
                                                    id: "storage_page_select_type",
                                                })}
                                                large
                                            />
                                        </ExtraStyledTableCell>
                                    </StyledBodyRow>
                                    <StyledBodyRow>
                                        <ExtraStyledTableCell>
                                            <FormattedMessage
                                                id={
                                                    !id
                                                        ? "common_name_sku_required"
                                                        : "common_name_sku"
                                                }
                                            />
                                        </ExtraStyledTableCell>
                                        <ExtraStyledTableCell>
                                            {selectedSku ? (
                                                <SelectField
                                                    value={selectedSku.id}
                                                    onChange={selectSku}
                                                    menuItems={productsAsMenuItems}
                                                    disabled={!!id || !selectedType}
                                                    placeholder={intl.formatMessage({
                                                        id: "storage_page_select_name",
                                                    })}
                                                    large
                                                />
                                            ) : (
                                                <SelectField
                                                    value={""}
                                                    onChange={selectSku}
                                                    menuItems={productsAsMenuItems}
                                                    disabled={!!id || !selectedType}
                                                    placeholder={intl.formatMessage({
                                                        id: "storage_page_select_name",
                                                    })}
                                                    large
                                                />
                                            )}
                                        </ExtraStyledTableCell>
                                    </StyledBodyRow>
                                    {id ? (
                                        <StyledBodyRow>
                                            <ExtraStyledTableCell>
                                                <FormattedMessage id="common_station" />
                                            </ExtraStyledTableCell>
                                            <ExtraStyledTableCell>
                                                <SelectField
                                                    value={
                                                        selectedStation ? selectedStation.id : ""
                                                    }
                                                    onChange={stationChange}
                                                    menuItems={stationMenuItems}
                                                    disabled={!!id}
                                                    placeholder={intl.formatMessage({
                                                        id: "storage_unit_settings_select_station",
                                                    })}
                                                    large
                                                />
                                            </ExtraStyledTableCell>
                                        </StyledBodyRow>
                                    ) : null}

                                    <StyledBodyRow>
                                        <ExtraStyledTableCell>
                                            <Grid container spacing={1}>
                                                <FormattedMessage id="storage_unit_settings_sku_location" />
                                                <CustomHelp
                                                    title={intl.formatMessage({
                                                        id: "storage_unit_location_tooltip",
                                                    })}
                                                />
                                            </Grid>
                                        </ExtraStyledTableCell>
                                        <ExtraStyledTableCell>
                                            <InputField
                                                value={description ?? ""}
                                                onChange={(e) => {
                                                    setDescription(e.target.value)
                                                    setBlock(true)
                                                }}
                                                id={"storage-unit-location"}
                                                name={"storage-unit-location"}
                                                type={"text"}
                                            />
                                        </ExtraStyledTableCell>
                                    </StyledBodyRow>

                                    <>
                                        <StyledBodyRow>
                                            <ExtraStyledTableCell>
                                                <FormattedMessage
                                                    id={
                                                        !id
                                                            ? "storage_unit_barcode_required"
                                                            : "storage_unit_barcode"
                                                    }
                                                />
                                            </ExtraStyledTableCell>
                                            {id && storageUnit ? (
                                                <IdCell
                                                    id={
                                                        storageUnit
                                                            ? storageUnit.barcode
                                                            : searchTerm
                                                    }
                                                />
                                            ) : (
                                                <ExtraStyledTableCell>
                                                    {searchTerm || !id ? (
                                                        <BarcodeScanInput
                                                            value={
                                                                storageUnit
                                                                    ? storageUnit.barcode
                                                                    : searchTerm
                                                            }
                                                            onScan={() => createStorageUnit(!!id)}
                                                            onChange={(value: string) => {
                                                                setSearchTerm(value)
                                                                setBlock(true)
                                                            }}
                                                            focused={true}
                                                            label={intl.formatMessage({
                                                                id: "stock_keeping_enter_or_scan",
                                                            })}
                                                            disabled={!!id}
                                                        />
                                                    ) : null}
                                                </ExtraStyledTableCell>
                                            )}
                                        </StyledBodyRow>
                                    </>
                                </tbody>
                            </Table>
                        </Grid>
                        <Grid container justifyContent="space-between" sx={{ marginTop: "16px" }}>
                            <Grid>
                                {storageUnit && (
                                    <CustomButton
                                        onClick={() => setItemToDelete(storageUnit)}
                                        text={intl.formatMessage({ id: "common_delete" })}
                                        colorTheme={ColorThemeName.warning}
                                    />
                                )}
                            </Grid>
                            <CustomButton
                                text={
                                    !id
                                        ? intl.formatMessage({ id: "common_create" })
                                        : intl.formatMessage({ id: "common_update" })
                                }
                                onClick={() => createStorageUnit(!id ? true : false)}
                                disabled={disbaleButton}
                            />
                        </Grid>
                    </DefaultWrapperPaper>
                </Grid>
                {storageUnit && (
                    <Grid container direction="column" size={{ md: 12, lg: 5 }}>
                        <DefaultWrapperPaper sx={{ overflow: "auto" }}>
                            <BundleHandler
                                storageUnit={storageUnit}
                                handleAdd={addPackaging}
                                handleRemove={removePackaging}
                                useIds={useIds}
                                handleQuantiy={handleQuantity}
                            />
                        </DefaultWrapperPaper>
                    </Grid>
                )}
            </Grid>
            <GenericModal
                open={!!itemToDelete}
                CustomContent={
                    <Grid>
                        <Typography>
                            {intl.formatMessage(
                                { id: "generic_do_you_want_to_delete" },
                                { item: itemToDelete?.barcode },
                            )}
                        </Typography>
                    </Grid>
                }
                CustomActions={
                    <Grid>
                        <CustomButton
                            text={intl.formatMessage({ id: "common_no" })}
                            colorTheme={ColorThemeName.noBackground}
                            onClick={() => setItemToDelete(null)}
                        />
                        <CustomButton
                            text={intl.formatMessage({ id: "common_yes" })}
                            onClick={() => {
                                setItemToDelete(null)
                                deleteStorageUnit()
                            }}
                        />
                    </Grid>
                }
                title={""}
                onClose={() => setItemToDelete(null)}
            />
            <BlockerAlert />
        </Page>
    )
}
export default StoragePage
