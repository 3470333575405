import { useCallback } from "react"

export const useDebounce = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const debounce = useCallback((func: (...args: any[]) => void, delay: number) => {
        let timer: NodeJS.Timeout
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (...args: any[]) => {
            clearTimeout(timer)
            timer = setTimeout(() => func(...args), delay)
        }
    }, [])

    return { debounce }
}
