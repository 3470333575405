import { styled } from "@mui/material"
import Grid from "@mui/material/Grid"
import React from "react"

export const Banner = styled(Grid)(({ theme }) => ({
    padding: "15px 40px",
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    backgroundColor: theme.palette.secondaryBackground.main,
    zIndex: 99,
    left: 0,
    right: 0,
    bottom: 0,
    alignContent: "center",
    justifyContent: "flex-end",
    borderTop: "2px solid",
    borderTopColor: theme.palette.coreBackground.main,
}))

interface Props {
    children: React.ReactNode
}

export default function BottomBanner({ children }: Props) {
    return (
        <Grid sx={{ marginTop: "75px" }}>
            {/*The outer grid is required to stop BottomBanner from overlapping the page content*/}
            <Banner>{children}</Banner>
        </Grid>
    )
}
