import Grid from "@mui/material/Grid"
import { SupportCard } from "src/components/support/SupportCard"
import VoucherPdfUrl from "./pdfs/RE-ZIP Manager - Vouchers - Shop.pdf"
import OrderPdfUrl from "./pdfs/RE-ZIP Manager - Ordering RE-ZIP - Shop.pdf"
import OrderHistoryPdfUrl from "./pdfs/RE-ZIP Manager - Order History - Shop.pdf"
import WooCommercePdfUrl from "./pdfs/RE-ZIP Setup Guide for WooCommerce.pdf"
import HowToPackAReZipPdfUrl from "./pdfs/How to Pack a RE-ZIP.pdf"
import PackagingLookupPdfUrl from "./pdfs/RE-ZIP Manager - Packaging Lookup - Partner Guide.pdf"
import ReceivePackagingPdfUrl from "./pdfs/RE-ZIP Manager - Receive Packaging - Partner Guide.pdf"
import StockKeepingPdfUrl from "./pdfs/RE-ZIP Manager - Stock Keeping - Partner Guide.pdf"
import StockCondigurationPdfUrl from "./pdfs/RE-ZIP Manager - Stock Configuration - Partner Guide.pdf"
import UsersPdfUrl from "./pdfs/RE-ZIP Manager - Users - Partner Guide.pdf"
import ReportingPdfUrl from "./pdfs/RE-ZIP Manager - Packaging Lookup - Partner Guide.pdf"
import TwoFactorAuthPdfUrl from "./pdfs/RE-ZIP Manager - Two-Factor Authentication - Partner.pdf"
import ManagerFullGuidePdfUrl from "./pdfs/RE-ZIP Manager Guide_Full Guide.pdf"
import InventoryGuidePdfUrl from "./pdfs/RE-ZIP Inventory Guide.pdf"
import OperationsGuidePdfUrl from "./pdfs/RE-ZIP Operations Guide.pdf"
import PackagingMethodsPdfUrl from "./pdfs/RE-ZIP Packing Methods.pdf"
import MarketingGuide from "./pdfs/RE-ZIP MarketingGuide.pdf"
import { useIntl } from "react-intl"

export const ShopManagerGuides = () => {
    const intl = useIntl()
    return (
        <Grid sx={{ paddingBottom: "3em" }} container spacing={10}>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={VoucherPdfUrl}
                    title={intl.formatMessage({ id: "vouchers" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={OrderPdfUrl}
                    title={intl.formatMessage({ id: "menu_listitem_order_rezip" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={OrderHistoryPdfUrl}
                    title={intl.formatMessage({ id: "common_order_history" })}
                />
            </Grid>
        </Grid>
    )
}

export const ShopMarketingGuides = () => {
    const intl = useIntl()
    return (
        <Grid sx={{ paddingBottom: "3em" }} container spacing={10}>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink="https://rezipdk.sharepoint.com/:f:/s/RE-ZIP/EuWCQJROtHVCnW4npii6gQgBB6mPHylEOFaJobQrHMiZ2g?e=iL1vRx"
                    title={intl.formatMessage({ id: "support_media_gallery" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={WooCommercePdfUrl}
                    title={intl.formatMessage({ id: "support_woocommerce" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={MarketingGuide}
                    title={intl.formatMessage({ id: "support_marketing" })}
                />
            </Grid>

            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={HowToPackAReZipPdfUrl}
                    title={intl.formatMessage({ id: "support_how_to_pack" })}
                />
            </Grid>
        </Grid>
    )
}

interface PartnerGuideProps {
    setLink: (link: string) => void
}

export const PartnerManagerGuides = (props: PartnerGuideProps) => {
    const intl = useIntl()
    const { setLink } = props
    return (
        <Grid sx={{ paddingBottom: "3em" }} container spacing={10}>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    videoLink="https://player.vimeo.com/video/840051726?h=f71a1fe1ba"
                    title={intl.formatMessage({ id: "menu_listitem_shops" })}
                    setVideoLink={setLink}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    videoLink="https://player.vimeo.com/video/840043899?h=920803185a"
                    title={intl.formatMessage({ id: "common_order_history" })}
                    setVideoLink={setLink}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    videoLink="https://player.vimeo.com/video/840041550?h=3ec1fd433e"
                    title={intl.formatMessage({ id: "menu_listitem_orders" })}
                    setVideoLink={setLink}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    videoLink="https://player.vimeo.com/video/840040080?h=b00397c36f"
                    title={intl.formatMessage({ id: "menu_listitem_waste_management" })}
                    setVideoLink={setLink}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={PackagingLookupPdfUrl}
                    title={intl.formatMessage({ id: "menu_listitem_packaging_lookup" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={ReceivePackagingPdfUrl}
                    title={intl.formatMessage({ id: "receive_page_header" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={StockKeepingPdfUrl}
                    title={intl.formatMessage({ id: "stock_keeping_title" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={StockCondigurationPdfUrl}
                    title={intl.formatMessage({ id: "support_stockconfiguration" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={UsersPdfUrl}
                    title={intl.formatMessage({ id: "menu_listitem_users" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={ReportingPdfUrl}
                    title={intl.formatMessage({ id: "support_reporting" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={TwoFactorAuthPdfUrl}
                    title={intl.formatMessage({ id: "support_two_factor_authentication" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={ManagerFullGuidePdfUrl}
                    title={intl.formatMessage({ id: "support_manager_full_guides" })}
                />
            </Grid>
        </Grid>
    )
}

export const PartnerMarketingGuides = () => {
    const intl = useIntl()
    return (
        <Grid sx={{ paddingBottom: "3em" }} container spacing={10}>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink="https://rezipdk.sharepoint.com/:f:/s/RE-ZIP/EuWCQJROtHVCnW4npii6gQgBB6mPHylEOFaJobQrHMiZ2g?e=dOXZYe"
                    title={intl.formatMessage({ id: "support_media_gallery" })}
                />
            </Grid>
        </Grid>
    )
}

export const LogisticsWaregouseGuides = () => {
    const intl = useIntl()
    return (
        <Grid sx={{ paddingBottom: "3em" }} container spacing={10}>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={InventoryGuidePdfUrl}
                    title={intl.formatMessage({ id: "support_inventory_guides" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={OperationsGuidePdfUrl}
                    title={intl.formatMessage({ id: "support_operations_guides" })}
                />
            </Grid>
            <Grid size={{ lg: 4, md: 4, xs: 10 }}>
                <SupportCard
                    pdf
                    downloadLink={PackagingMethodsPdfUrl}
                    title={intl.formatMessage({ id: "support_packing_methods" })}
                />
            </Grid>
        </Grid>
    )
}
