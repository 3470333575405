import { Box, Button, styled } from "@mui/material"
import appFrame from "../assets/images/appframe.png"
import voucherCircleIcon from "../assets/images/voucherCircelIcon.png"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Shop, VoucherBatch } from "@repo/rezip-client/shop_client"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { formatCurrency } from "src/utils/formatCurrency"

const StyledFrameContainer = styled(Box)(() => ({
    backgroundImage: `url(${appFrame})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "250px",
    height: "510px",
    paddingTop: "32px",
    margin: "0 auto",
    marginTop: "16px",
    color: "#000000",
}))

const StyledRewards = styled(Box)(() => ({
    display: "flex",
    justifyContent: " space-around",
    alignItems: "center",
    boxShadow: "0 0 20px #ccc",
    borderRadius: "20px",
    padding: "0 5px",
}))

const StyledPreviewText = styled("p")(() => ({
    margin: "0 0 0 0",
    color: "#000000",
}))

const StyledTextOneLine = styled(Box)(() => ({
    display: "flex",
    color: "#000000",
    fontSize: "12px",
    ":nth-type(even)": {
        paddingLeft: "10px",
    },
}))

const StyledSubmit = styled(Button)(() => ({
    margin: "0 0 0 21px",
    width: "83%",
    height: "35px",
    backgroundColor: "#00BC71",
    color: "#ffffff",
    fontSize: "12px",
    borderRadius: "40px",
    ":hover": {
        backgroundColor: "#00BC71",
    },
}))

export const VoucherAppPreview = ({ batch }: { batch: VoucherBatch }) => {
    const { getSelectedAgreement } = useUser()
    const { shopClient } = useClient()
    const accountId = getSelectedAgreement()?.account.id || ""
    const [shop, setShop] = useState<Shop | null>(null)
    const intl = useIntl()
    useEffect(() => {
        ;(async () => {
            const shop = await shopClient.getShop(accountId)
            setShop(shop)
        })()
    }, [accountId, shopClient])

    return (
        <StyledFrameContainer>
            <Box
                sx={{
                    marginBottom: "15px",
                    padding: "0 12px 0 22px",
                    overflow: "auto",
                    width: "236px",
                    height: "334px",
                }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                    }}>
                    <ArrowBackIcon
                        sx={{
                            width: "17px",
                            height: "17px",
                        }}
                    />
                    <p>
                        <b> {intl.formatMessage({ id: "common_details" })}</b>
                    </p>
                </Box>
                <StyledRewards>
                    <img width={40} src={shopClient.getLogoUrl(accountId)} />
                    <Box
                        sx={{
                            backgroundImage: `url(${voucherCircleIcon})`,
                            backgroundRepeat: "repeat",
                            width: "8px",
                            height: "60px",
                        }}
                    />
                    <div
                        style={{
                            fontSize: "11px",
                        }}>
                        <StyledPreviewText
                            style={{
                                margin: "2px 0",
                            }}>
                            {batch.type === "amount" ? (
                                <b>
                                    {formatCurrency({
                                        currencyCode: batch.unit,
                                        value: batch.value,
                                        intl,
                                    })}
                                </b>
                            ) : (
                                <b>
                                    {batch.value} {batch.unit}
                                </b>
                            )}
                        </StyledPreviewText>
                        <StyledPreviewText
                            style={{
                                margin: "2px 0",
                            }}>
                            123456789
                        </StyledPreviewText>
                    </div>
                    <ContentCopyIcon />
                </StyledRewards>
                <StyledTextOneLine>
                    <p>
                        <b>{intl.formatMessage({ id: "common_shop" })}:</b>
                    </p>
                    <p>{shop?.name}</p>
                </StyledTextOneLine>
                <Box
                    sx={{
                        fontSize: "12px",
                        marginBottom: "1em",
                    }}>
                    <StyledPreviewText>
                        <b>{intl.formatMessage({ id: "common_conditions" })}:</b>
                    </StyledPreviewText>
                    <StyledPreviewText>{batch.conditions}</StyledPreviewText>
                </Box>
                <Box
                    sx={{
                        fontSize: "12px",
                        marginBottom: "1em",
                    }}>
                    <StyledPreviewText>
                        <b>{intl.formatMessage({ id: "common_about" })}</b>
                    </StyledPreviewText>
                    <StyledPreviewText>{shop?.description}</StyledPreviewText>
                </Box>
            </Box>
            <StyledSubmit>{intl.formatMessage({ id: "orders_go_to_shop" })}</StyledSubmit>
        </StyledFrameContainer>
    )
}
