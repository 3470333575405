import { Box, Select, Grid } from "@mui/material"
import { ReactNode } from "react"
import { FieldLabel, StyledMenuItem } from "./styles"
import { CustomHelp } from "./generalComponents/CustomHelp"

export interface MultiSelectPlaceholder {
    setSelected: (selected: string[]) => void
    selected: string[]
    renderMap: Record<string, ReactNode>
    placeholder: ReactNode
    label: string
    helpText?: string
}

export const MultiSelectPlaceholder = ({
    setSelected,
    selected,
    renderMap,
    placeholder,
    label,
    helpText,
}: MultiSelectPlaceholder) => {
    return (
        <Box
            sx={{
                width: "100%",
            }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <FieldLabel>{label}</FieldLabel>
                {helpText && <CustomHelp title={helpText} />}
            </Grid>
            <Select
                sx={{ width: 300 }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                multiple
                displayEmpty
                value={selected}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return placeholder
                    }

                    return selected.map((x) => renderMap[x]).join(", ")
                }}
                onChange={(e) => {
                    const value = e.target.value
                    const newValue = typeof value === "string" ? value.split(",") : value
                    setSelected(newValue)
                }}>
                <StyledMenuItem disabled value="">
                    {placeholder}
                </StyledMenuItem>
                {Object.entries(renderMap).map(([id, value]) => (
                    <StyledMenuItem key={id} value={id}>
                        {value}
                    </StyledMenuItem>
                ))}
            </Select>
        </Box>
    )
}
