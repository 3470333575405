import { z } from "zod"
import {
    baseApiErrorHandling,
    defaultQueringOptions,
    OptionalQueryingOptions,
    QueryingOptions,
    ReZipClient,
} from "."
import {
    Agreement,
    AgreementPostType,
    BaseProduct,
    BaseShop,
    BaseUserAgreement,
    date,
    PatchType,
    ULID,
    UserAgreementAccount,
} from "./types"
import { PermissionGroupClient } from "./permission_group_client"
import { ReportingClient } from "./reporting_client"

export const VoucherBatch = z.object({
    id: z.string(),
    type: z.enum(["amount", "percentage", "gift"]),
    value: z.number(),
    unit: z.string(),
    conditions: z.string(),
    voucher_pool_id: z.string(),
    created_at: date,
    updated_at: date,
    expires_at: date.nullable(),
})

export type VoucherBatch = z.infer<typeof VoucherBatch>

export const VoucherPool = z.object({
    id: z.string(),
    visibility: z.enum(["public", "private", "private_public"]),
    countries: z.array(z.string()).nullable(),
    name: z.string(),
    created_at: date,
    updated_at: date,
})

export type VoucherPool = z.infer<typeof VoucherPool>

export const VoucherUsage = z.object({
    total: z.number(),
    used: z.number(),
})

export type VoucherUsage = z.infer<typeof VoucherUsage>

export const Settings = z.object({
    odering: z
        .object({
            code: z.string(),
            conditions: z.string(),
            type: z.string(),
            unit: z.string(),
            value: z.number(),
        })
        .nullable()
        .optional(),
    return_policy_url: z.string().nullable().optional(),
})
export type Settings = z.infer<typeof Settings>

export const Voucher = z.object({
    id: z.string(),
    batch_id: z.string(),
    code: z.string(),
    created_at: date,
    updated_at: date,
    plucked_at: date.nullable(),
})

export type Voucher = z.infer<typeof Voucher>

export const OrderLine = z.object({
    id: z.string(),
    sku: z.string(),
    name: z.string(),
    dimensions: z.string(),
    price: z.number(),
    quantity: z.number(),
    fulfilled: z.number(),
})

export type OrderLine = z.infer<typeof OrderLine>

export const OrderShop = z.object({
    id: z.string(),
    number: z.number(),
    state: z.number(),
    lines: z.array(OrderLine),
    created_at: date,
    updated_at: date,
})

export type OrderShopType = z.infer<typeof OrderShop>

export const Shop = BaseShop.extend({
    partner: z.object({
        id: z.string(),
        name: z.string(),
        support_email: z.string().nullable(),
        support_phone: z.string().nullable(),
    }),
})

export type Shop = z.infer<typeof Shop>

export const OrderConfiguration = z.object({
    currency: z.string(),
    shipping_base_cost: z.number(),
    shipping_unit_cost: z.number(),
    vat: z.number(),
})
export type OrderConfiguration = z.infer<typeof OrderConfiguration>

export class ShopClient {
    rezipClient: ReZipClient
    constructor(rezipClient: ReZipClient) {
        this.rezipClient = rezipClient
    }

    getLogoUrl(shopId: ULID): string {
        return this.rezipClient.pathToUri(`/shops/${shopId}/logo`)
    }

    permissionGroups(shopId: ULID): PermissionGroupClient {
        return new PermissionGroupClient(this.rezipClient, `shops/${shopId}`)
    }

    reporting(partnerId: ULID): ReportingClient {
        return new ReportingClient(this.rezipClient, `shops/${partnerId}`)
    }

    async getOrders(shopId: ULID, options?: QueryingOptions): Promise<OrderShopType[]> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)

        const response = await this.rezipClient.get({
            path: `shops/${shopId}/orders`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(OrderShop).parse(body)
    }

    async getOrder(shopId: ULID, orderId: ULID): Promise<OrderShopType | null> {
        const response = await this.rezipClient.get({
            path: `shops/${shopId}/orders/${orderId}`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return OrderShop.parse(body)
    }
    async postOrder(
        shopId: ULID,
        args: PatchType<{
            currency: string
            lines: { sku: string; quantity: number }[]
        }>,
    ) {
        const response = await this.rezipClient.post({
            path: `shops/${shopId}/orders`,
            body: JSON.stringify(args),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return OrderShop.parse(body)
    }

    async deleteVoucher({ shopId, voucherId }: { shopId: ULID; voucherId: ULID }): Promise<void> {
        const response = await this.rezipClient.delete({
            path: `shops/${shopId}/vouchers/${voucherId}`,
            body: "",
        })
        await baseApiErrorHandling(response)
    }

    async voucherBatches(
        id: ULID,
        options?: { pageSize?: number },
    ): Promise<VoucherBatch[] | null> {
        const pageSize = options?.pageSize || 25
        const response = await this.rezipClient.get({
            path: `shops/${id}/vouchers/batches`,
            queryParams: { page_size: pageSize },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(VoucherBatch).parse(body)
    }

    async getVouchers(shopId: ULID, options?: OptionalQueryingOptions): Promise<Voucher[]> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)

        const response = await this.rezipClient.get({
            path: `shops/${shopId}/vouchers`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(Voucher).parse(body)
    }

    async voucherBatchUsage({
        shopId,
        batchId,
    }: {
        shopId: ULID
        batchId: ULID
    }): Promise<VoucherUsage | null> {
        const response = await this.rezipClient.get({
            path: `shops/${shopId}/vouchers/batches/${batchId}/usage`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return VoucherUsage.parse(body)
    }

    async createVoucherBatch(id: ULID, args: PatchType<VoucherBatch>): Promise<VoucherBatch> {
        const response = await this.rezipClient.post({
            path: `shops/${id}/vouchers/batches`,
            body: JSON.stringify(args),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return VoucherBatch.parse(body)
    }

    async updateVoucherBatch({
        shopId,
        batchId,
        body,
    }: {
        shopId: ULID
        batchId: ULID
        body: PatchType<VoucherBatch>
    }): Promise<VoucherBatch> {
        const response = await this.rezipClient.patch({
            path: `shops/${shopId}/vouchers/batches/${batchId}`,
            body: JSON.stringify(body),
        })
        await baseApiErrorHandling(response)
        const returnBody = await response.json()
        return VoucherBatch.parse(returnBody)
    }

    async deleteVoucherBatch({
        shopId,
        batchId,
    }: {
        shopId: ULID
        batchId: ULID
    }): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `shops/${shopId}/vouchers/batches/${batchId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }

    async batchUploadCodes({
        shopId,
        batchId,
        codes,
    }: {
        shopId: ULID
        batchId: ULID
        codes: string[]
    }): Promise<void> {
        const response = await this.rezipClient.post({
            path: `shops/${shopId}/vouchers/batches/${batchId}/codes`,
            body: JSON.stringify(codes),
        })
        await baseApiErrorHandling(response)
    }

    async voucherPools(id: ULID, options?: { pageSize?: number }): Promise<VoucherPool[] | null> {
        const pageSize = options?.pageSize || 25
        const response = await this.rezipClient.get({
            path: `shops/${id}/vouchers/pools/`,
            queryParams: { page_size: pageSize },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(VoucherPool).parse(body)
    }

    async createVoucherPools(id: ULID, args: PatchType<VoucherPool>): Promise<VoucherPool> {
        const response = await this.rezipClient.post({
            path: `shops/${id}/vouchers/pools`,
            body: JSON.stringify(args),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return VoucherPool.parse(body)
    }

    async pluckVoucher({
        shopId,
        batchId,
        email,
    }: {
        shopId: ULID
        batchId: ULID
        email: string
    }): Promise<void> {
        const response = await this.rezipClient.post({
            path: `shops/${shopId}/vouchers/batches/${batchId}/pluck-voucher`,
            body: JSON.stringify({ email }),
        })
        await baseApiErrorHandling(response)
    }

    async getShop(shopId: ULID): Promise<Shop | null> {
        const response = await this.rezipClient.get({
            path: `shops/${shopId}`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Shop.parse(body)
    }

    async getSettings(shopId: ULID): Promise<Settings | null> {
        const response = await this.rezipClient.get({
            path: `shops/${shopId}/settings`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()

        return Settings.parse(body)
    }
    async patchShop(shopId: ULID, body: PatchType<Shop>): Promise<Shop> {
        const response = await this.rezipClient.patch({
            path: `/shops/${shopId}`,
            body: JSON.stringify(body),
        })
        await baseApiErrorHandling(response)
        return Shop.parse(await response.json())
    }

    async setLogo(shopId: ULID, body: Blob) {
        const response = await this.rezipClient.put({
            path: `/shops/${shopId}/logo`,
            body,
            headers: {
                "Content-Type": "image/png",
            },
        })
        await baseApiErrorHandling(response)
    }

    async putReturnUrl(shopId: ULID, returnUrl: string): Promise<boolean | null> {
        const response = await this.rezipClient.put({
            path: `/shops/${shopId}/settings`,
            body: JSON.stringify({ return_policy_url: returnUrl }),
        })
        await baseApiErrorHandling(response)
        return true
    }

    async getOrderConfiguration(shopId: ULID): Promise<OrderConfiguration | null> {
        const response = await this.rezipClient.get({
            path: `/shops/${shopId}/settings/ordering`,
        })
        await baseApiErrorHandling(response)
        const parsed = OrderConfiguration.parse(await response.json())
        return { ...parsed, vat: parsed.vat / 100 }
    }

    async getProducts(
        shopId: ULID,
        props?: OptionalQueryingOptions,
    ): Promise<BaseProduct[] | null> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(props)
        const response = await this.rezipClient.get({
            path: `/shops/${shopId}/products`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(BaseProduct).parse(body)
    }

    async getAgreements(shopId: ULID, options: OptionalQueryingOptions): Promise<Agreement[]> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: `shops/${shopId}/agreements`,
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(Agreement).parse(body)
    }
    async postAgreement(
        partnerId: ULID,
        args: PatchType<AgreementPostType>,
    ): Promise<Agreement | null> {
        const response = await this.rezipClient.post({
            path: `shops/${partnerId}/agreements`,
            body: JSON.stringify(args),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return BaseUserAgreement.parse(body)
    }

    async patchAgreement(
        partnerId: ULID,
        agreementId: ULID,
        owner: boolean,
        mfa_required: boolean,
    ): Promise<UserAgreementAccount | null> {
        const response = await this.rezipClient.patch({
            path: `shops/${partnerId}/agreements/${agreementId}`,
            body: JSON.stringify({ owner: owner, mfa_required: mfa_required }),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return UserAgreementAccount.parse(body)
    }

    async deleteAgreement(partnerId: ULID, agreementId: ULID): Promise<boolean | null> {
        const response = await this.rezipClient.delete({
            path: `shops/${partnerId}/agreements/${agreementId}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }
    async getAgreement(partnerId: ULID, agreementId: ULID): Promise<UserAgreementAccount | null> {
        const response = await this.rezipClient.get({
            path: `shops/${partnerId}/agreements/${agreementId}`,
        })
        if (response.status === 404) {
            return null
        }
        const body = await response.json()
        return UserAgreementAccount.parse(body)
    }
}
