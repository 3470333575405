import { useState } from "react"

export const useIntInput = ({
    defaultValue,
    onChange,
}: {
    defaultValue?: number
    onChange?: (x: number) => void
}) => {
    const [value, setValue] = useState<string>(defaultValue?.toString() ?? "")

    return {
        value: value,

        onChange: (x: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = x.target.value.replace(/[^0-9]/, "")
            setValue(value)
            const intValue = parseInt(value)
            if (!isNaN(intValue)) {
                onChange?.(intValue)
            }
        },
    }
}
