import { TextField } from "@mui/material"
import Grid from "@mui/material/Grid"
import { ChangeEvent, Ref, useState } from "react"
import { useIntl } from "react-intl"

type BarcodeScanInputProps = {
    value: string
    onScan: () => void
    onChange: (value: string) => void
    label?: string
    disabled?: boolean
    focused?: boolean
    inputRef?: Ref<HTMLInputElement>
    maxWidth?: boolean
}

const ID_QUERY_PARAM_REGEX = /ID[^A-Z0-9]([A-Z0-9]+)$/

const BarcodeScanInput = (props: BarcodeScanInputProps) => {
    const [val, setVal] = useState<string>(props.value)
    const intl = useIntl()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = parseBarcode(e.target.value)
        setVal(val)
        props.onChange(val)
    }

    const parseBarcode = (barcodeString: string) => {
        let barcode = barcodeString.toUpperCase().trim()
        const match = barcode.match(ID_QUERY_PARAM_REGEX)
        if (match) {
            barcode = match[1]
        }

        return barcode
    }
    return (
        <form
            style={{ width: props.maxWidth ? "100%" : "auto" }}
            onSubmit={(e) => {
                e.preventDefault()
                props.onScan()
                setVal("")
            }}>
            <Grid container>
                <TextField
                    value={val}
                    label={props.label ?? intl.formatMessage({ id: "barcode_scan_input_default" })}
                    onChange={handleChange}
                    fullWidth={true}
                    disabled={props.disabled}
                    autoFocus={props.focused}
                    inputRef={props.inputRef}
                />
            </Grid>
        </form>
    )
}

export default BarcodeScanInput
