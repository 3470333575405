import { Box, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import IdCell from "src/components/table/IdCell"
import GenericModal from "src/components/modals/GenericModal"
import Page from "src/components/layout/Page"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { QueryingOptions } from "@repo/rezip-client"
import { Location, StationType } from "@repo/rezip-client/partner_client"
import { StyledMenuItem } from "src/components/styles"
import QRCode from "qrcode"
import { Edit, QrCode } from "@mui/icons-material"
import { TrashCan } from "src/components/TrashCan"

export const Stations = () => {
    const intl = useIntl()
    const { getSelectedAgreement, getSelectedLocationId } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const locationId = getSelectedLocationId()
    const { partnerClient } = useClient()
    const [initialLoad, setInitialLoad] = useState(true)
    const [locations, setLocations] = useState<Location[] | null>(null)
    const [stationToBeDeleted, setStationToBeDeleted] = useState<StationType | null>(null)
    const navigate = useNavigate()
    const { enqueueAlert } = useSnackbar()

    useEffect(() => {
        if (partnerId && !locations) {
            const fetch = async () => {
                const resp = await partnerClient.getWarehouseLocations(partnerId)
                setLocations(resp)
            }
            fetch()
        }
    }, [locations, partnerClient, partnerId])

    const generateQRCode = useCallback(
        async (id: string) => {
            try {
                const qrCodeDataURL = await QRCode.toDataURL(id)
                const link = document.createElement("a")
                link.href = qrCodeDataURL
                link.download = `station-${id}.png`
                link.click()
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
            }
        },
        [enqueueAlert, intl],
    )

    const fetchMore = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!partnerId || !locationId) {
                return
            }
            filters = { ...filters, deleted_at: "" }
            const options = {
                filters: { ...filters },
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            const response = await partnerClient.getStations(partnerId, locationId, options)
            return response
        },
        [locationId, partnerClient, partnerId],
    )

    const deleteItem = useCallback(async () => {
        if (!partnerId || !locationId || !stationToBeDeleted) {
            return
        }
        const response = await partnerClient.deleteStation(
            partnerId,
            locationId,
            stationToBeDeleted.id,
        )
        if (response) {
            enqueueAlert(
                intl.formatMessage({ id: "item_was_deleted" }, { item: stationToBeDeleted.name }),
                "success",
            )
            setStationToBeDeleted(null)
            setInitialLoad(true)
            return
        } else {
            enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
            setStationToBeDeleted(null)
            return
        }
    }, [enqueueAlert, intl, locationId, partnerClient, partnerId, stationToBeDeleted])

    const itemToRow = useCallback(
        (item: StationType, index: number) => {
            return (
                <StyledBodyRow id={`station-${index}`}>
                    <IdCell id={item.id}></IdCell>
                    <StyledTableCell>{item.name}</StyledTableCell>
                    <StyledTableCell>
                        <ThreeDotMenu
                            menuItems={[
                                <StyledMenuItem
                                    key={`station-${index}-view`}
                                    onClick={() => navigate(`view/${item.id}`)}>
                                    <Edit width={25} height={25} sx={{ paddingRight: "2px" }} />
                                    {intl.formatMessage({ id: "common_edit" })}
                                </StyledMenuItem>,
                                <StyledMenuItem
                                    key={`station-${index}-qr`}
                                    onClick={() => generateQRCode(item.id)}>
                                    <QrCode />
                                    {intl.formatMessage({ id: "common_download_qr" })}
                                </StyledMenuItem>,
                                <StyledMenuItem
                                    key={`station-${index}-delete`}
                                    onClick={() => setStationToBeDeleted(item)}
                                    sx={{ color: "warning.main" }}>
                                    <TrashCan width={25} height={25} />
                                    {intl.formatMessage({ id: "common_delete" })}
                                </StyledMenuItem>,
                            ]}
                        />
                    </StyledTableCell>
                </StyledBodyRow>
            )
        },
        [generateQRCode, intl, navigate],
    )

    return (
        <Page title={intl.formatMessage({ id: "menu_listitem_stations" })}>
            <DefaultWrapperPaper>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
                    <Typography variant="h4">
                        {intl.formatMessage({ id: "stations_subtitle" })}
                    </Typography>
                </Box>
                <AllInOneTable<StationType>
                    headers={[
                        {
                            key: "id",
                            label: intl.formatMessage({ id: "id" }),
                        },
                        {
                            key: "name",
                            label: intl.formatMessage({ id: "stations_work_station_name" }),
                        },
                        { key: "actions", label: "", excludeFromSearch: true },
                    ]}
                    itemsToRow={itemToRow}
                    defaultSortBy={"id"}
                    fetchItems={fetchMore}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    addFunction={() => navigate("add")}
                />
            </DefaultWrapperPaper>
            <GenericModal
                open={!!stationToBeDeleted}
                CustomContent={
                    <Box>
                        <Typography>
                            {intl.formatMessage(
                                { id: "generic_do_you_want_to_delete" },
                                { item: stationToBeDeleted?.name },
                            )}
                        </Typography>
                    </Box>
                }
                CustomActions={
                    <Box>
                        <CustomButton
                            text={intl.formatMessage({ id: "common_no" })}
                            colorTheme={ColorThemeName.noBackground}
                            onClick={() => setStationToBeDeleted(null)}
                        />
                        <CustomButton
                            text={intl.formatMessage({ id: "common_yes" })}
                            onClick={deleteItem}
                        />
                    </Box>
                }
                title={""}
                onClose={() => setStationToBeDeleted(null)}
            />
        </Page>
    )
}
