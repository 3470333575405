import { Box, Divider, Modal, Typography, Grid } from "@mui/material"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"

import { useUser } from "src/contexts/UserConsumer"
import {
    LogisticsWaregouseGuides,
    PartnerMarketingGuides,
    ShopMarketingGuides,
} from "./supportPageLists"
import { useState } from "react"
import { useIntl } from "react-intl"
import { ReactComponent as Help_icon } from "../../assets/images/help_icon.svg"
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone_icon.svg"
import { ReactComponent as MailIcon } from "../../assets/icons/mail_icon.svg"
import { QRScannerTester } from "src/components/QRScannerTester"
import { ShopSupport } from "src/components/shopComponents/shopSupport"
export const SupportPage = () => {
    const { getSelectedAccountType } = useUser()
    const accountType = getSelectedAccountType()
    const [videoLink, setVideoLink] = useState<string | null>(null)
    const intl = useIntl()

    return (
        <Page title={intl.formatMessage({ id: "menu_listitem_header_support" })}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                    alignContent: "center",
                    textAlign: "center",
                    paddingBottom: "2em",
                }}>
                <Typography variant="h2">
                    {intl.formatMessage({ id: "support_seeking_assistnace" })}
                </Typography>
                <Typography variant="h2">
                    {intl.formatMessage({ id: "support_explore_collection" })}
                </Typography>
            </Box>
            <DefaultWrapperPaper
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingTop: "2em",
                    width: "100%",
                    px: 7,
                    gap: "20px",
                }}>
                <Typography variant="h2">
                    {intl.formatMessage({ id: "support_marketing_guides" })}
                </Typography>

                <Divider />

                {accountType === "Shop" ? <ShopMarketingGuides /> : <PartnerMarketingGuides />}

                {accountType === "Shop" ? null : (
                    <>
                        <Typography variant="h2">
                            {intl.formatMessage({ id: "support_logistics_guides" })}
                        </Typography>

                        <Divider />

                        <LogisticsWaregouseGuides />
                    </>
                )}
            </DefaultWrapperPaper>

            {accountType === "Shop" ? null : (
                <DefaultWrapperPaper>
                    <Typography variant="h2">
                        {intl.formatMessage({ id: "qr_tester_header" })}
                    </Typography>
                    <QRScannerTester />
                </DefaultWrapperPaper>
            )}
            <Box
                sx={{
                    marginTop: "80px",
                }}>
                <Grid
                    container
                    direction={{ xs: "column", md: "row" }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}>
                    <Box>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Box
                                sx={{
                                    height: "110px",
                                }}>
                                <Help_icon height={110} width={140} />
                            </Box>
                            <Box
                                sx={{
                                    width: "350px",
                                    marginLeft: "15px",
                                }}>
                                <Typography variant="h3">
                                    {intl.formatMessage({ id: "support_didnt_find" })}
                                </Typography>
                            </Box>
                        </Grid>
                    </Box>
                    {accountType === "Shop" ? (
                        <ShopSupport />
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                marginLeft: "50px",
                            }}>
                            <Typography variant="h3">
                                {intl.formatMessage({ id: "support_contact_us" })}
                            </Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                <PhoneIcon height={40} width={40} />
                                <Typography>
                                    {intl.formatMessage({ id: "support_phone_number" })}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "8px",
                                }}>
                                <MailIcon height={40} width={40} />
                                <Typography>
                                    {" "}
                                    {intl.formatMessage({ id: "support_mail" })}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Grid>
            </Box>
            <Modal
                sx={{
                    display: "flex",
                    backgroundColor: "secondaryBackground.main",
                    maxWidth: "910px",
                    width: "100%",
                    maxHeight: "577px",
                    height: "100%",
                    top: "10%",
                    left: "20%",
                    padding: "20px",
                }}
                open={videoLink !== null}
                onClose={() => setVideoLink(null)}>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                    }}>
                    <iframe
                        src={`${videoLink}&autoplay=1`}
                        width={"100%"}
                        height={"100%"}
                        style={{ border: "none" }}
                        allow="autoplay; fullscreen; picture-in-picture"
                    />
                </Box>
            </Modal>
        </Page>
    )
}
