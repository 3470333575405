import { Typography, Link, Grid } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import InputField from "src/components/InputField"
import Page from "src/components/layout/Page"
import { useUser } from "src/contexts/UserConsumer"
import { FileUploadButton } from "src/components/FileUploadButton"
import { FormattedMessage, useIntl } from "react-intl"

import { useClient } from "src/hooks/useClient.hook"
import StyledForm from "src/components/layout/StyledForm"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import { ClientError } from "@repo/rezip-client/client_error"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"

export const AddFile = () => {
    const [description, setDescription] = useState<string>("")
    const [file, setFile] = useState<File | null>(null)
    const [fileURL, setFileURL] = useState<string | null>(null)
    const [fileName, setFileName] = useState<string | null>(null)
    const { getSelectedAgreement } = useUser()
    const navigate = useNavigate()
    const { fileId } = useParams()
    const { partnerClient } = useClient()
    const { enqueueAlert } = useSnackbar()
    const intl = useIntl()
    const { BlockerAlert, setBlock } = useCustomBlocker()

    const partnerId = getSelectedAgreement()?.account.id

    const handleFileChange = useCallback(
        (file: File) => {
            setFile(file)
            const filePreviewURL = URL.createObjectURL(file)
            setFileName(file.name)
            setFileURL(filePreviewURL)
            setBlock(true)
        },
        [setBlock],
    )

    useEffect(() => {
        if (fileId && partnerId && !file) {
            const fetchData = async () => {
                try {
                    const fileInfoResult = await partnerClient.getFileInfoAndContent({
                        partnerId,
                        fileId,
                    })

                    if (fileInfoResult) {
                        setFileName(fileInfoResult.file_name)
                        setFileURL(partnerClient.getFileInfo({ partnerId, fileId }))
                        setDescription(fileInfoResult.description)
                        const response = await fetch(
                            `${partnerClient.getFileInfo({
                                partnerId: partnerId,
                                fileId: fileId,
                            })}`,
                        )

                        const blob = await response.blob()
                        const fileObject = new File([blob], fileInfoResult.file_name, {
                            type: blob.type,
                        })

                        setFile(fileObject)
                    }
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                } catch (error) {
                    enqueueAlert(intl.formatMessage({ id: "something_went_wrong" }), "error")
                }
            }
            fetchData()
        }
    }, [fileId, file, partnerId, partnerClient, intl, enqueueAlert])

    const handleUpdate = useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault() // Prevent default form submission

            if (!file || !description || !partnerId || !fileId) {
                enqueueAlert(intl.formatMessage({ id: "missing_required_fields" }), "error")
                return
            }

            try {
                const response = await partnerClient.updateFile({
                    partnerId,
                    fileId,
                    file,
                    description,
                })

                if (response) {
                    setBlock(false)
                    enqueueAlert(intl.formatMessage({ id: "file_was_updated" }), "success")
                }

                navigate("/account/file-manager")
            } catch (error) {
                const mappedError = errorConverter(error)
                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                displayErrorMessage(mappedError, enqueueAlert)

                return enqueueAlert(intl.formatMessage({ id: "something_went_wrong" }), "error")
            }
        },
        [
            file,
            description,
            partnerId,
            fileId,
            partnerClient,
            enqueueAlert,
            intl,
            setBlock,
            navigate,
        ],
    )

    const handleAdd = useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault() // Prevent default form submission

            if (!file || !description || !partnerId) {
                enqueueAlert(intl.formatMessage({ id: "missing_required_fields" }), "error")
                return
            }

            try {
                {
                    const response = await partnerClient.postFile({
                        partnerId,
                        file,
                        description,
                    })

                    if (response) {
                        setBlock(false)
                        enqueueAlert(
                            response.updated
                                ? intl.formatMessage({ id: "file_was_updated" })
                                : intl.formatMessage({ id: "file_was_uploaded" }),
                            "success",
                        )
                    }
                }

                navigate("/account/file-manager")
            } catch (error) {
                const mappedError = errorConverter(error)
                if (!(mappedError instanceof ClientError)) {
                    throw error
                }
                displayErrorMessage(mappedError, enqueueAlert)
                return enqueueAlert(intl.formatMessage({ id: "common_error" }), "warning")
            }
        },
        [file, description, partnerId, partnerClient, enqueueAlert, intl, setBlock, navigate],
    )

    return (
        <Page backButton>
            <Grid container direction="column">
                <DefaultWrapperPaper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        width: "100%",
                        maxWidth: "500px",
                    }}>
                    <Grid container direction="column" spacing={2}>
                        <Typography variant="h4">
                            <FormattedMessage
                                id={
                                    fileId
                                        ? intl.formatMessage({ id: "update_file" })
                                        : intl.formatMessage({ id: "add_file" })
                                }
                            />
                        </Typography>

                        <StyledForm onSubmit={fileId ? handleUpdate : handleAdd}>
                            <Grid container direction="column" spacing={2}>
                                <InputField
                                    label={intl.formatMessage({ id: "common_description" })}
                                    id="desc"
                                    name="desc"
                                    type="text"
                                    value={description}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setDescription(event.target.value)
                                        setBlock(true)
                                    }}
                                />

                                <Grid>
                                    <FileUploadButton
                                        multiple={false}
                                        labelWhenNotDragging={
                                            <Typography>
                                                <FormattedMessage id="upload_file" />
                                            </Typography>
                                        }
                                        labelWhenDragging={
                                            <Typography>
                                                <FormattedMessage id="drop_file_here" />
                                            </Typography>
                                        }
                                        onUpload={handleFileChange}
                                    />

                                    {fileURL && fileName && (
                                        <Typography variant="body2" sx={{ marginTop: 2 }}>
                                            {intl.formatMessage({ id: "selected_file" })}{" "}
                                            <Link
                                                href={fileURL}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {fileName}
                                            </Link>
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid container justifyContent="flex-end">
                                    <CustomButton
                                        text={intl.formatMessage({
                                            id: fileId ? "common_update" : "add_file",
                                        })}
                                        onClick={fileId ? handleUpdate : handleAdd}
                                    />
                                </Grid>
                            </Grid>
                        </StyledForm>
                    </Grid>
                </DefaultWrapperPaper>
            </Grid>
            <BlockerAlert />
        </Page>
    )
}
