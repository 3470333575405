import { useEffect, useState } from "react"
import { IntlProvider as IntlProvider_ } from "react-intl"
import { Outlet } from "react-router-dom"
import Layout from "src/components/layout/Layout"
import Spinner from "src/components/Spinner"
import { SupportedLocales, defaultLocale, importMessages, LocaleMessages } from "src/services/i18n"
import { useLocalStorage } from "usehooks-ts"

export const IntlRoute = () => {
    const [lang, setLang] = useLocalStorage<SupportedLocales>("pref_locale", defaultLocale)
    const [messages, setMessages] = useState<LocaleMessages | null>(null)

    const onChangeLanguage = (locale: SupportedLocales) => {
        setLang(locale)
    }

    const IntlProvider: React.FC<
        Omit<React.ComponentProps<typeof IntlProvider_>, "messages"> & {
            messages: LocaleMessages
        }
    > = (props) => <IntlProvider_ {...props} />

    useEffect(() => {
        importMessages(lang)?.then(setMessages)
    }, [lang])

    return (
        <>
            {messages ? (
                <IntlProvider
                    messages={messages}
                    locale={lang.replace("_", "-")}
                    defaultLocale={defaultLocale.replace("_", "-")}
                    key={lang.replace("_", "-")}>
                    <Layout onChangeLanguage={onChangeLanguage}>
                        <Outlet />
                    </Layout>
                </IntlProvider>
            ) : (
                <Spinner />
            )}
        </>
    )
}
