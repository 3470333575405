import {
    Checkbox,
    FormControlLabel,
    InputAdornment,
    Select,
    TextField,
    Typography,
    Grid,
} from "@mui/material"

import { FormattedMessage, useIntl } from "react-intl"
import { VoucherBatch, VoucherPool } from "@repo/rezip-client/shop_client"
import { FileUploadButton } from "./FileUploadButton"
import { useState } from "react"
import { z } from "zod"

import { CurrencyCodeRecord } from "currency-codes"

import DatePicker from "./datePicker/DatePicker"

import { useClient } from "src/hooks/useClient.hook"
import { useUser } from "src/contexts/UserConsumer"
import { PatchType } from "@repo/rezip-client/types"
import cc from "currency-codes"
import { DropdownMenuItem } from "./styles"
import { getEveryExcelNode } from "src/utils/getEveryExcelNode"
import { useIntInput } from "src/hooks/useIntInput.hook"
import { useFloatInput } from "src/hooks/useFloatInput.hook"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { CurrencySelect } from "./generalComponents/CurrencySelect"
import { CurrencyInput } from "./generalComponents/CurrencyInput"
import { CustomButton } from "./generalComponents/Buttons"

const typeValidator = z.enum(["amount", "gift", "percentage"])
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const visibilityValidator = z.enum(["private", "public", "private_public"])

export type VoucherPoolRecord = Record<z.infer<typeof visibilityValidator>, VoucherPool>
type VisibilityType = z.infer<typeof visibilityValidator>
type FailableVisibilityType = VisibilityType | ""
type FormErrors = "currency" | "visibility" | "conditions" | "item"

const VisibilityCheckboxes = ({
    setVisibility,
    visibility,
}: {
    setVisibility: (x: FailableVisibilityType) => void
    visibility: FailableVisibilityType
}) => {
    const intl = useIntl()
    const [isPrivate, setPrivate] = useState<boolean>(
        visibility === "private" || visibility === "private_public",
    )
    const [isPublic, setPublic] = useState<boolean>(
        visibility === "public" || visibility === "private_public",
    )
    const handleUpdate = (isPrivate: boolean, isPublic: boolean) => {
        setPrivate(isPrivate)
        setPublic(isPublic)
        switch (true) {
            case isPublic && isPrivate:
                setVisibility("private_public")
                break
            case isPublic && !isPrivate:
                setVisibility("public")
                break
            case !isPublic && isPrivate:
                setVisibility("private")
                break
            case !isPublic && !isPrivate:
                setVisibility("")
                break
        }
    }

    return (
        <Grid container spacing={1} direction="column" size="grow">
            <Grid container>
                <Typography>
                    <FormattedMessage id="vouchers_availability" />
                </Typography>
                {/*
                    TODO: add a good explanation on how availability works
                        <CustomHelp title={undefined} />
                    */}
            </Grid>

            <Grid>
                <Grid>
                    <FormControlLabel
                        label={
                            <Typography variant="label">
                                {intl.formatMessage({
                                    id: "vouchers_private_label",
                                })}
                            </Typography>
                        }
                        labelPlacement="end"
                        control={
                            <Checkbox
                                checked={isPrivate}
                                onChange={(e) => handleUpdate(e.target.checked, isPublic)}
                            />
                        }
                    />
                </Grid>
                <Grid>
                    <FormControlLabel
                        label={
                            <Typography variant="label">
                                {intl.formatMessage({
                                    id: "vouchers_public_label",
                                })}
                            </Typography>
                        }
                        labelPlacement="end"
                        control={
                            <Checkbox
                                checked={isPublic}
                                onChange={(e) => handleUpdate(isPrivate, e.target.checked)}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export const SimplifiedVoucherBatchFormInner = ({
    onSubmit,
    voucherPoolRecord,
    defaultBatch,
    edit = false,
}: {
    onSubmit: (x: PatchType<VoucherBatch>) => Promise<void>
    voucherPoolRecord: VoucherPoolRecord
    defaultBatch?: VoucherBatch
    edit?: boolean
}) => {
    const { enqueueAlert } = useSnackbar()
    const intl = useIntl()
    const [type, setType] = useState<z.infer<typeof typeValidator>>(
        defaultBatch?.type || "percentage",
    )
    const [currency, setCurrency] = useState<CurrencyCodeRecord | null>(
        defaultBatch?.type === "amount" ? cc.code(defaultBatch.unit) || null : null,
    )
    const [amount, setAmount] = useState<number>(defaultBatch?.value || 0)
    const [conditions, setConditions] = useState<string>(defaultBatch?.conditions || "")
    const [giftContent, setGiftContent] = useState<string>(defaultBatch?.unit || "")
    const [expires, setExpires] = useState<boolean>(!!defaultBatch?.expires_at)
    const [expiresAt, setExpiresAt] = useState(
        defaultBatch?.expires_at || new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    )
    const [visibility, setVisibility] = useState<FailableVisibilityType>(
        defaultBatch?.voucher_pool_id
            ? Object.values(voucherPoolRecord).find((x) => x.id == defaultBatch.voucher_pool_id)
                  ?.visibility || "private"
            : "private",
    )
    const [errors, setErrors] = useState<FormErrors[]>([])
    const giftAmountController = useIntInput({
        onChange: setAmount,
        defaultValue: defaultBatch?.type === "gift" ? amount : 0,
    })
    const percentageController = useFloatInput({
        onChange: setAmount,
        defaultValue: defaultBatch?.type === "percentage" ? amount : 0,
    })

    return (
        <Grid container spacing={2} direction="column">
            <Grid>
                <FormattedMessage id="vouchers_voucher_value" />
            </Grid>
            <Grid container size={12} spacing={2}>
                <Grid container size={3} direction="column">
                    <Select
                        value={type}
                        onChange={(e) => {
                            const type = typeValidator.parse(e.target.value)
                            if (type) {
                                setType(type)
                                setAmount(0)
                            }
                        }}>
                        <DropdownMenuItem value="amount">
                            <FormattedMessage id="vouchers_amount" />
                        </DropdownMenuItem>
                        <DropdownMenuItem value="gift">
                            <FormattedMessage id="vouchers_gift" />
                        </DropdownMenuItem>
                        <DropdownMenuItem value="percentage">
                            <FormattedMessage id="vouchers_percentage" />
                        </DropdownMenuItem>
                    </Select>
                </Grid>
                {type === "amount" ? (
                    <>
                        <Grid container size="grow" direction="column">
                            <CurrencySelect
                                error={errors.includes("currency")}
                                onChange={setCurrency}
                                defaultValue={currency ?? undefined}
                            />
                        </Grid>
                        <Grid container size="grow" direction="column">
                            <CurrencyInput
                                defaultValue={amount}
                                currency={currency}
                                onChange={setAmount}
                            />
                        </Grid>
                    </>
                ) : null}
                {type === "percentage" ? (
                    <>
                        <Grid container size="grow" direction="column">
                            <TextField
                                {...percentageController}
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        </Grid>
                    </>
                ) : null}
                {type === "gift" ? (
                    <>
                        <Grid container size="grow" direction="column">
                            <TextField
                                {...giftAmountController}
                                placeholder={intl.formatMessage({
                                    id: "vouchers_gift_amount",
                                })}
                            />
                        </Grid>
                        <Grid container size="grow" direction="column">
                            <TextField
                                value={giftContent}
                                placeholder={intl.formatMessage({
                                    id: "common_item",
                                })}
                                error={errors.includes("item")}
                                onChange={(x) => {
                                    setGiftContent(x.target.value)
                                }}
                            />
                        </Grid>
                    </>
                ) : null}
            </Grid>

            <Grid>
                <FormattedMessage id="vouchers_conditions" />
            </Grid>
            <Grid container spacing={2}>
                <Grid container size={12} direction="column">
                    <TextField
                        error={errors.includes("conditions")}
                        value={conditions}
                        onChange={(x) => {
                            setConditions(x.target.value)
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <VisibilityCheckboxes setVisibility={setVisibility} visibility={visibility} />
                <Grid container direction="column" minHeight={"130px"}>
                    <Grid>
                        <FormControlLabel
                            label={
                                <Typography variant="label">
                                    {intl.formatMessage({
                                        id: "vouchers_expires",
                                    })}
                                </Typography>
                            }
                            labelPlacement="end"
                            sx={{ float: "right" }}
                            control={
                                <Checkbox
                                    checked={expires}
                                    onChange={(x) => {
                                        setExpires(x.target.checked)
                                    }}
                                />
                            }
                        />
                    </Grid>

                    {expires ? (
                        <Grid container direction="column">
                            <Grid>
                                <FormattedMessage id="vouchers_expiration_date" />
                            </Grid>
                            <Grid>
                                <DatePicker
                                    minDate={new Date()}
                                    onchange={setExpiresAt}
                                    date={expiresAt}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction="column"></Grid>
                    )}
                </Grid>
            </Grid>

            <Grid sx={{ textAlign: "right" }}>
                <CustomButton
                    text={
                        !edit
                            ? intl.formatMessage({
                                  id: "vouchers_upload_vouchers",
                              })
                            : intl.formatMessage({ id: "common_update" })
                    }
                    onClick={async () => {
                        const errors: FormErrors[] = []
                        if (!currency && type === "amount") {
                            errors.push("currency")
                        }

                        if (visibility === "") {
                            errors.push("visibility")
                            enqueueAlert(
                                intl.formatMessage({ id: "availability_must_be_selected" }),
                                "error",
                            )
                        }
                        if (conditions === "") {
                            errors.push("conditions")
                        }
                        if (type === "gift" && !giftContent) {
                            errors.push("item")
                        }

                        if (errors.length !== 0 || visibility === "") {
                            setErrors(errors)
                            return
                        }
                        await onSubmit({
                            type: type,
                            value: amount,
                            unit: (() => {
                                switch (type) {
                                    case "percentage":
                                        return "pct"
                                    case "gift":
                                        return giftContent
                                    case "amount":
                                        return currency?.code
                                }
                            })(),
                            conditions: conditions,
                            voucher_pool_id: voucherPoolRecord[visibility].id,
                            expires_at: (expires ? expiresAt : null) || undefined,
                        })
                        setType("percentage")
                        setCurrency(null)
                        setAmount(0)
                        setConditions("")
                        setGiftContent("")
                        setExpires(false)
                        setExpiresAt(new Date(new Date().setFullYear(new Date().getFullYear() + 1)))
                        setVisibility("private")
                    }}
                />
            </Grid>
        </Grid>
    )
}

export type SimplifiedVoucherBatchFormProps = {
    voucherPoolRecord: VoucherPoolRecord
    onSubmit: (batch: VoucherBatch, codes: string[]) => void
}

const csvParse = async (files: File[]): Promise<string[]> => {
    return (await Promise.all(files.map((x) => x.text())))
        .flatMap((x) => x.split(/[\r\n]/))
        .map((x) => x.trim())
        .filter((x) => !!x)
}
const excelParse = async (files: File[]): Promise<string[]> => {
    return (await Promise.all(files.map((x) => x.arrayBuffer().then(getEveryExcelNode))))
        .map((x) => x.map((y) => y.value))
        .flat()
}

export const SimplifiedVoucherBatchForm = ({
    voucherPoolRecord,
    onSubmit,
}: SimplifiedVoucherBatchFormProps) => {
    const { getSelectedAgreement } = useUser()
    const intl = useIntl()
    const { shopClient } = useClient()
    const accountId = getSelectedAgreement()?.account.id
    const [codes, setCodes] = useState<string[]>([])
    const codesEmpty = codes.length === 0
    const { enqueueAlert } = useSnackbar()
    if (!accountId) {
        return <>{intl.formatMessage({ id: "common_loading" })}...</>
    }

    return (
        <>
            <Grid container spacing={2} direction="column">
                <FileUploadButton
                    baseStyle={{ minHeight: "200px", fontSize: "20px", lineHeight: "34px" }}
                    accept={{
                        "text/csv": [".csv"],
                        "application/vnd.ms-excel": [".xlsx"],
                    }}
                    labelWhenDragging={
                        codesEmpty
                            ? intl.formatMessage({ id: "vouchers_fileupload_dragging_empty" })
                            : intl.formatMessage({ id: "vouchers_fileupload_dragging_not_empty" })
                    }
                    labelWhenNotDragging={
                        codesEmpty
                            ? intl.formatMessage({ id: "vouchers_fileupload_not_dragging_empty" })
                            : intl.formatMessage({
                                  id: "vouchers_fileupload_not_dragging_not_empty",
                              })
                    }
                    multiple
                    onUpload={async (files) => {
                        const excel = files.filter((x) => x.name.endsWith(".xlsx"))
                        const csv = files.filter((x) => x.name.endsWith(".csv"))
                        const [excelCodes, csvCodes] = await Promise.all([
                            excelParse(excel),
                            csvParse(csv),
                        ])
                        setCodes([...codes, ...excelCodes, ...csvCodes])
                        enqueueAlert(
                            intl.formatMessage({ id: "toast_message_voucher_batch_uploaded" }),
                            "success",
                        )
                    }}
                />
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="vouchers_selected_codes"
                        values={{ amount: codes.length }}
                    />
                </Typography>

                <SimplifiedVoucherBatchFormInner
                    voucherPoolRecord={voucherPoolRecord}
                    onSubmit={async (x) => {
                        const batch = await shopClient.createVoucherBatch(accountId, x)
                        await shopClient.batchUploadCodes({
                            shopId: accountId,
                            batchId: batch.id,
                            codes: codes,
                        })
                        onSubmit(batch, codes)
                        setCodes([])
                    }}
                />
            </Grid>
        </>
    )
}
