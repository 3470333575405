import { useState, useEffect, useCallback } from "react"
import { Divider, Typography, Grid } from "@mui/material"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import StyledForm from "src/components/layout/StyledForm"
import InputField from "src/components/InputField"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { useUser } from "src/contexts/UserConsumer"
import BottomBanner from "src/components/layout/BottomBanner"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { DropPointLocation } from "@repo/rezip-client/drop_point_client"
import { useClient } from "src/hooks/useClient.hook"
import ShippingAndBilling from "src/components/shopComponents/ShippingAndBilling"
import { useCustomBlocker } from "src/utils/useCustomBlocker"

const WEEKDAYS = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
] as const

type DayHours = { opens_at: string; closes_at: string } | null | undefined

type OpeningHours = {
    monday: DayHours
    tuesday: DayHours
    wednesday: DayHours
    thursday: DayHours
    friday: DayHours
    saturday: DayHours
    sunday: DayHours
}

const DropPointEditPage = () => {
    const { dropPointId } = useParams<{ dropPointId: string }>()
    const [dropPoint, setDropPoint] = useState<DropPointLocation | undefined>()
    const intl = useIntl()
    const { getSelectedAgreement } = useUser()
    const accountID = getSelectedAgreement()?.account.id
    const { enqueueAlert } = useSnackbar()
    const { dropPointClient } = useClient()
    const { BlockerAlert, setBlock } = useCustomBlocker()
    // Fetch template for edit
    useEffect(() => {
        if (!dropPointId && !dropPoint) {
            // If no dropPointId is present, create an empty DropPoint
            setDropPoint({
                id: "",
                drop_point_id: "",
                external_id: "",
                name: "",
                shipping_email: "",
                shipping_address: {
                    street: "",
                    city: "",
                    postal_code: "",
                    region: "",
                    country: "",
                    latitude: 0,
                    longitude: 0,
                },
                billing_email: "",
                billing_address: {
                    street: "",
                    city: "",
                    postal_code: "",
                    region: "",
                    country: "",
                    latitude: 0,
                    longitude: 0,
                },
                vat_no: "",
                opening_hours: {
                    monday: { opens_at: "00:00", closes_at: "00:00" },
                    tuesday: { opens_at: "00:00", closes_at: "00:00" },
                    wednesday: { opens_at: "00:00", closes_at: "00:00" },
                    thursday: { opens_at: "00:00", closes_at: "00:00" },
                    friday: { opens_at: "00:00", closes_at: "00:00" },
                    saturday: { opens_at: "", closes_at: "" },
                    sunday: { opens_at: "", closes_at: "" },
                },
                opening_hours_notes: "",
                deleted_at: new Date(),
                updated_at: new Date(),
                created_at: new Date(),
            })
        } else if (!dropPoint && accountID && dropPointId) {
            const fetch = async () => {
                const data = await dropPointClient.location({
                    dropPointId: accountID,
                    locationId: dropPointId,
                })
                if (data) {
                    setDropPoint(data)
                }
            }

            fetch()
        }
    }, [dropPointId, dropPoint, accountID, dropPointClient])

    const cleanOpeningHours = useCallback((openingHours: OpeningHours): void => {
        Object.keys(openingHours).forEach((key) => {
            const day = key as keyof OpeningHours
            const value = openingHours[day]

            if (
                value &&
                (day === "sunday" || day === "saturday") &&
                (value.opens_at === "" || value.opens_at === null) &&
                (value.closes_at === "" || value.closes_at === null)
            ) {
                delete openingHours[day]
            }
        })
    }, [])

    const createLocation = useCallback(async () => {
        if (!dropPoint) {
            return
        }
        const edited = { ...dropPoint }
        cleanOpeningHours(edited.opening_hours)

        await dropPointClient.createLocation({ ...edited, drop_point_id: accountID })
        setBlock(false)
        enqueueAlert(intl.formatMessage({ id: "location_has_been_created" }), "success")
    }, [dropPoint, cleanOpeningHours, dropPointClient, accountID, setBlock, enqueueAlert, intl])

    const updateLocation = useCallback(async () => {
        if (!dropPoint) {
            return
        }
        await dropPointClient.updateLodation(dropPoint.id, {
            ...dropPoint,
            drop_point_id: accountID,
        })
        setBlock(false)
        enqueueAlert("Location updated", "success")
    }, [dropPoint, dropPointClient, accountID, setBlock, enqueueAlert])

    const handleDropPointChange = <K extends keyof DropPointLocation>(
        field: K,
        value: DropPointLocation[K],
    ) => {
        setDropPoint((prevDropPoint) => {
            if (!prevDropPoint) return undefined
            return {
                ...prevDropPoint,
                [field]: value,
            }
        })
        setBlock(true)
    }

    const handleOpeningHoursChange = (
        day: (typeof WEEKDAYS)[number],
        field: "opens_at" | "closes_at",
        value: string,
    ) => {
        setDropPoint((prevDropPoint) => {
            if (!prevDropPoint) return undefined
            return {
                ...prevDropPoint,
                opening_hours: {
                    ...prevDropPoint.opening_hours,
                    [day]: {
                        ...prevDropPoint.opening_hours[day],
                        [field]: value,
                    },
                },
            }
        })
        setBlock(true)
    }

    return (
        <Grid container flexDirection="row" spacing={2} sx={{ display: "flex" }}>
            <Grid container flexDirection="column" size={{ xs: 12, md: 8 }}>
                {dropPoint ? (
                    <>
                        <DefaultWrapperPaper>
                            <InputField
                                id={"name"}
                                name={"name"}
                                type={"text"}
                                label={intl.formatMessage({ id: "common_name" })}
                                value={dropPoint?.name}
                                onChange={(e) =>
                                    setDropPoint({ ...dropPoint, name: e.target.value })
                                }></InputField>
                            <InputField
                                id={"external_id"}
                                name={"external_id"}
                                type={"text"}
                                label={intl.formatMessage({ id: "external_id" })}
                                value={dropPoint?.external_id}
                                onChange={(e) =>
                                    setDropPoint({ ...dropPoint, external_id: e.target.value })
                                }></InputField>
                        </DefaultWrapperPaper>
                        <ShippingAndBilling
                            collectiveAddresses={dropPoint}
                            setCollectiveAddresses={setDropPoint}
                            disableFields={false}
                            setBlock={setBlock}
                            creation={!dropPointId}
                        />
                    </>
                ) : null}
            </Grid>
            {/* Opening Hours Section */}
            <Grid container flexDirection="column" size={{ xs: 12, md: 4 }}>
                <DefaultWrapperPaper>
                    <Grid container flexDirection="column" spacing={2}>
                        <Typography variant="h4">
                            {intl.formatMessage({ id: "common_opening_hours" })}
                        </Typography>

                        {WEEKDAYS.map((day) => (
                            <StyledForm key={day}>
                                <Typography variant="body1">
                                    {intl.formatMessage({ id: `common_${day}` })}
                                </Typography>

                                <Grid container spacing={1} sx={{ display: "flex" }}>
                                    <Grid container flexDirection="column" size={6}>
                                        <InputField
                                            label={intl.formatMessage({ id: "common_opens_at" })}
                                            type="time"
                                            value={dropPoint?.opening_hours[day]?.opens_at ?? ""}
                                            id={`${day}_opens`}
                                            name={`${day}_opens`}
                                            required={
                                                day === "sunday" || day === "saturday"
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e) =>
                                                handleOpeningHoursChange(
                                                    day,
                                                    "opens_at",
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid container flexDirection="column" size={6}>
                                        <InputField
                                            label={intl.formatMessage({ id: "common_closes_at" })}
                                            type="time"
                                            value={dropPoint?.opening_hours[day]?.closes_at ?? ""}
                                            id={`${day}_closes`}
                                            name={`${day}_closes`}
                                            required={
                                                day === "sunday" || day === "saturday"
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e) =>
                                                handleOpeningHoursChange(
                                                    day,
                                                    "closes_at",
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                            </StyledForm>
                        ))}
                        <Grid container flexDirection="column">
                            <InputField
                                label={intl.formatMessage({ id: "opening_hours_notes" })}
                                type="text"
                                value={dropPoint?.opening_hours_notes ?? ""}
                                id="drop_point_opening_hours_notes"
                                name="drop_point_opening_hours_notes"
                                onChange={(e) =>
                                    handleDropPointChange("opening_hours_notes", e.target.value)
                                }
                            />
                        </Grid>
                    </Grid>
                </DefaultWrapperPaper>
            </Grid>

            <BottomBanner>
                <CustomButton
                    text={
                        !dropPointId
                            ? intl.formatMessage({
                                  id: "add_shop",
                              })
                            : intl.formatMessage({
                                  id: "save_changes",
                              })
                    }
                    onClick={!dropPointId ? createLocation : updateLocation}></CustomButton>
            </BottomBanner>

            <BlockerAlert />
        </Grid>
    )
}

export default DropPointEditPage
