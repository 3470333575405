import { SelectChangeEvent, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import InputField from "../InputField"
import { useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useSnackbar } from "src/contexts/SnackbarConsumer"

import { DefaultWrapperPaper } from "../layout/DefaultPageComponents"
import StyledForm from "../layout/StyledForm"

import cc, { CurrencyCodeRecord } from "currency-codes"

import { useClient } from "src/hooks/useClient.hook"
import { SettingsOrdering } from "@repo/rezip-client/partner_client"
import { CurrencyInput } from "../generalComponents/CurrencyInput"
import { CurrencySelect } from "../generalComponents/CurrencySelect"
import { CustomButton } from "../generalComponents/Buttons"

interface orderConfigProps {
    shopId: string | undefined
    partnerId: string
    edit: boolean
    setBlock: (blokc: boolean) => void
}
export interface OrderConfig {
    currency: string
    shipping_base_cost: number
    shipping_unit_cost: number
    vat: number
}

export const OrderConfiguration = ({ partnerId, shopId, edit, setBlock }: orderConfigProps) => {
    const intl = useIntl()

    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCodeRecord | null>(null)
    const [orderConfig, setOrderConfig] = useState<SettingsOrdering>()

    const { enqueueAlert } = useSnackbar()
    const { partnerClient } = useClient()

    useEffect(() => {
        if (shopId && !orderConfig && partnerId) {
            const fetchConfig = async () => {
                const config = await partnerClient.getShopOrdering({ partnerId, shopId })
                setOrderConfig(config)
                const currency = cc.code(config.currency)
                if (currency) {
                    setSelectedCurrency(currency)
                }
            }
            fetchConfig()
        }
    }, [orderConfig, partnerId, partnerClient, shopId])

    const updateOrdeConfig = useCallback(async () => {
        if (!orderConfig || !shopId) {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_something_went_wrong",
                }),
                "error",
            )
            return
        }
        await partnerClient.setShopOrdering({
            partnerId,
            shopId,
            body: {
                currency: selectedCurrency?.code || "",
                shipping_base_cost: orderConfig.shipping_base_cost,
                shipping_unit_cost: orderConfig.shipping_unit_cost,
                vat: orderConfig.vat,
            },
        })

        setBlock(false)
        enqueueAlert(
            intl.formatMessage({
                id: "toast_order_configuration_updated",
            }),
            "success",
        )
    }, [
        orderConfig,
        shopId,
        partnerClient,
        partnerId,
        selectedCurrency?.code,
        setBlock,
        enqueueAlert,
        intl,
    ])

    return (
        <Grid container direction="column">
            <DefaultWrapperPaper>
                <Grid container spacing={1} direction="column">
                    <Typography variant="h4">
                        <FormattedMessage id="order_configuration" />
                    </Typography>
                    {orderConfig ? (
                        <Grid container direction="column" spacing={2}>
                            <Grid>
                                <StyledForm>
                                    <Grid container spacing={1}>
                                        <Grid container direction="column" size={12}>
                                            <CurrencySelect
                                                label={intl.formatMessage({
                                                    id: "common_currency",
                                                })}
                                                defaultValue={cc.code(orderConfig.currency)}
                                                onChange={(e) => {
                                                    setSelectedCurrency(e)
                                                    setOrderConfig({
                                                        ...orderConfig,
                                                        currency: e.code || "",
                                                    })
                                                    setBlock(true)
                                                }}
                                            />
                                        </Grid>
                                        <Grid container direction="column" size={12}>
                                            <CurrencyInput
                                                label={intl.formatMessage({
                                                    id: "orders_shipping_base",
                                                })}
                                                defaultValue={orderConfig?.shipping_base_cost ?? 0}
                                                onChange={(e) => {
                                                    setOrderConfig({
                                                        ...orderConfig,
                                                        shipping_base_cost: e,
                                                    })
                                                }}
                                                currency={selectedCurrency}
                                                setBlock={setBlock}
                                            />
                                        </Grid>
                                        <Grid container direction="column" size={12}>
                                            <CurrencyInput
                                                label={intl.formatMessage({
                                                    id: "orders_shipping_unit",
                                                })}
                                                defaultValue={orderConfig?.shipping_unit_cost ?? 0}
                                                onChange={(e) => {
                                                    setOrderConfig({
                                                        ...orderConfig,
                                                        shipping_unit_cost: e,
                                                    })
                                                }}
                                                currency={selectedCurrency}
                                                setBlock={setBlock}
                                            />
                                        </Grid>
                                        <Grid container direction="column" size={12}>
                                            <InputField
                                                label={intl.formatMessage({
                                                    id: "common_vat",
                                                })}
                                                id={"vat"}
                                                name={""}
                                                disabled={edit}
                                                type={"number"}
                                                value={orderConfig?.vat ?? ""}
                                                onChange={(event: SelectChangeEvent) => {
                                                    setBlock(true)
                                                    setOrderConfig({
                                                        ...orderConfig,
                                                        vat: parseInt(event.target.value),
                                                    })
                                                }}
                                                input={true}
                                                unitText={"%"}
                                            />
                                        </Grid>
                                    </Grid>
                                </StyledForm>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <CustomButton
                                    onClick={updateOrdeConfig}
                                    text={intl.formatMessage({
                                        id: "common_update",
                                    })}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </DefaultWrapperPaper>
        </Grid>
    )
}
