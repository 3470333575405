import { Box } from "@mui/material"
import { CSSProperties, ReactNode, useMemo } from "react"
import { Accept, useDropzone } from "react-dropzone"

const defaultBaseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "fileUpload.border",
    borderStyle: "dashed",
    backgroundColor: "fileUpload.background",
    color: "fileUpload.text",
    outline: "none",
    transition: "border .24s ease-in-out",
} as const

const defaultFocusedStyle = {
    borderColor: "fileUpload.focus",
} as const

const defaultAcceptStyle = {
    borderColor: "fileUpload.accept",
} as const

const defaultRejectStyle = {
    borderColor: "fileUpload.reject",
} as const

export type FileUploadButtonProps = {
    labelWhenDragging: ReactNode
    labelWhenNotDragging: ReactNode
    baseStyle?: CSSProperties
    focusedStyle?: CSSProperties
    acceptStyle?: CSSProperties
    rejectStyle?: CSSProperties
    accept?: Accept
} & (
    | {
          multiple: true
          onUpload: (x: File[]) => void
      }
    | {
          multiple: false
          onUpload: (x: File) => void
      }
)

export const FileUploadButton = ({
    accept,
    labelWhenDragging,
    labelWhenNotDragging,
    multiple,
    onUpload,
    baseStyle,
    focusedStyle,
    acceptStyle,
    rejectStyle,
}: FileUploadButtonProps) => {
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive } =
        useDropzone({
            onDrop: (files) => {
                if (files.length === 0) {
                    return
                }
                if (multiple) {
                    onUpload(files)
                } else {
                    onUpload(files[0])
                }
            },
            accept,
            multiple,
        })

    const style = useMemo(
        () => ({
            ...{ ...defaultBaseStyle, ...baseStyle },
            ...(isFocused ? { ...defaultFocusedStyle, ...focusedStyle } : {}),
            ...(isDragAccept ? { ...defaultAcceptStyle, ...acceptStyle } : {}),
            ...(isDragReject ? { ...defaultRejectStyle, ...rejectStyle } : {}),
        }),
        [baseStyle, isFocused, focusedStyle, isDragAccept, acceptStyle, isDragReject, rejectStyle],
    )
    const rootProps = getRootProps({ style })
    return (
        <div className="container">
            <Box {...rootProps} sx={rootProps.style} style={undefined}>
                <input {...getInputProps()} />
                <p style={{ maxWidth: "400px", margin: "0 auto" }}>
                    {isDragActive ? labelWhenDragging : labelWhenNotDragging}
                </p>
            </Box>
        </div>
    )
}
