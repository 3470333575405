import { Dialog, DialogActions, DialogTitle, Grid } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import useShopViewController from "./ShopView.controller"
import ShopDetails from "../../../components/shopComponents/ShopDetails"
import ShippingAndBilling from "../../../components/shopComponents/ShippingAndBilling"
import ShopDetailsInformationBox from "../../../components/shopComponents/ShopDetailsInformationBox"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"

import { useIntl } from "react-intl"

import { OrderConfiguration } from "src/components/shopComponents/OrderConfiguration"
import Page from "src/components/layout/Page"
import BottomBanner from "src/components/layout/BottomBanner"
import { useCustomBlocker } from "src/utils/useCustomBlocker"

const ShopView = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const intl = useIntl()
    const {
        shop,
        setShop,
        createShop,
        updateShop,
        dialogOpen,
        setDialogOpen,
        deleteShop,
        accountId,
        userEmail,
        setUserEmail,
    } = useShopViewController(id)
    const { BlockerAlert, setBlock, isBlocked } = useCustomBlocker()

    const customUpdate = () => {
        setBlock(false)
        updateShop()
    }
    const customCreate = () => {
        setBlock(false)
        createShop()
    }

    return (
        <Page
            backButton
            title={
                !id
                    ? intl.formatMessage({
                          id: "add_shop",
                      })
                    : intl.formatMessage({ id: "edit_shop" })
            }>
            <Grid>
                {id && (
                    <Grid container justifyContent={"space-between"} spacing={1}>
                        <CustomButton
                            onClick={() => navigate(`/administration/shops/${id}/assortment`)}
                            text={intl.formatMessage({
                                id: "go_to_shop_assortment",
                            })}
                        />
                        <CustomButton
                            onClick={() => setDialogOpen(true)}
                            text={intl.formatMessage({
                                id: "delete_shop",
                            })}
                            colorTheme={ColorThemeName.warning}
                        />
                    </Grid>
                )}

                <ShopDetailsInformationBox editPage={id !== undefined} />

                <Grid container flexDirection="column" spacing={2}>
                    <Grid container spacing={2}>
                        <Grid container direction="column" size={id ? { xs: 12, md: 8 } : 12}>
                            <ShopDetails
                                shop={shop}
                                setShop={setShop}
                                create={!id}
                                disableFields={false}
                                setUserEmail={setUserEmail}
                                userEmail={userEmail}
                                setBlock={setBlock}
                            />
                        </Grid>
                        {id && (
                            <Grid container direction="column" size={{ xs: 12, md: 4 }}>
                                <OrderConfiguration
                                    shopId={id}
                                    partnerId={accountId ?? ""}
                                    edit={false}
                                    setBlock={setBlock}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {shop ? (
                        <Grid>
                            <ShippingAndBilling
                                collectiveAddresses={shop}
                                setCollectiveAddresses={setShop}
                                disableFields={false}
                                setBlock={setBlock}
                                creation={!id}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
            <Dialog open={dialogOpen}>
                <DialogTitle>
                    {intl.formatMessage(
                        { id: "generic_do_you_want_to_delete" },
                        { item: shop?.name },
                    )}
                </DialogTitle>
                <DialogActions>
                    <CustomButton
                        onClick={() => setDialogOpen(false)}
                        text={intl.formatMessage({ id: "common_no" })}
                    />
                    <CustomButton
                        onClick={() => {
                            deleteShop()
                            setDialogOpen(false)
                            navigate(-1)
                        }}
                        text={intl.formatMessage({ id: "common_yes" })}
                        colorTheme={ColorThemeName.warning}
                    />
                </DialogActions>
            </Dialog>
            {isBlocked ? (
                <BottomBanner>
                    <CustomButton
                        text={
                            !id
                                ? intl.formatMessage({
                                      id: "add_shop",
                                  })
                                : intl.formatMessage({
                                      id: "save_changes",
                                  })
                        }
                        onClick={!id ? customCreate : customUpdate}
                    />
                </BottomBanner>
            ) : null}
            <BlockerAlert />
        </Page>
    )
}

export default ShopView
