import { Box, styled, Tooltip } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import { ReactNode } from "react"

const HelpIcon = styled(InfoIcon)(({ theme }) => ({
    fill: theme.palette.secondary.dark,
}))

export interface CustomProps {
    title: string | ReactNode
}

export const CustomHelp = ({ title }: CustomProps) => {
    return (
        <Box>
            <Tooltip title={title}>
                <HelpIcon />
            </Tooltip>
        </Box>
    )
}
