import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { StyledMenuItem } from "src/components/styles"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { useClient } from "src/hooks/useClient.hook"
import { QueryingOptions } from "@repo/rezip-client"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import GenericModal from "src/components/modals/GenericModal"
import { Box, Typography } from "@mui/material"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"
import { TrashCan } from "src/components/TrashCan"
import { Edit } from "@mui/icons-material"
import { ProductGroup } from "@repo/rezip-client/mothership_client"

export const ProductGroups = () => {
    const { mothershipClient } = useClient()
    const [initialLoad, setInitialLoad] = useState(true)
    const intl = useIntl()
    const navigate = useNavigate()
    const [groupToDelete, setGroupToDelete] = useState<ProductGroup | null>(null)
    const { enqueueAlert } = useSnackbar()

    const fetchProducts = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            filters = { ...filters, filters: { deleted_at: "" } }
            const options = {
                filters: filters,
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            const resp = await mothershipClient.getProductGroups(options)

            return (resp ?? []).filter((item) => !item.deleted_at)
        },

        [mothershipClient],
    )

    const deleteGroup = useCallback(async () => {
        if (!groupToDelete) {
            return
        }
        const resp = await mothershipClient.deleteProductGroup(groupToDelete.id)
        if (resp) {
            enqueueAlert(
                intl.formatMessage({ id: "item_was_deleted" }, { item: groupToDelete.name }),
                "success",
            )
            setGroupToDelete(null)
            setInitialLoad(true)
            return
        }
    }, [enqueueAlert, groupToDelete, intl, mothershipClient])

    const itemToRow = useCallback(
        (item: ProductGroup, index: number) => {
            return (
                <StyledBodyRow key={`${item.id}-${index}`}>
                    <StyledTableCell>{item.name}</StyledTableCell>
                    <StyledTableCell>{item.description}</StyledTableCell>
                    <StyledTableCell>
                        <ThreeDotMenu
                            menuItems={[
                                <StyledMenuItem
                                    key={`${item.id}-${index}-edit`}
                                    onClick={() => navigate(`${item.id}`)}>
                                    <Edit width={25} height={25} sx={{ paddingRight: "2px" }} />
                                    {intl.formatMessage({ id: "common_edit" })}
                                </StyledMenuItem>,
                                <StyledMenuItem
                                    sx={{ color: "warning.main" }}
                                    key={`${item.id}-${index}-remove`}
                                    onClick={() => setGroupToDelete(item)}>
                                    <TrashCan width={25} height={25} />
                                    {intl.formatMessage({ id: "common_delete" })}
                                </StyledMenuItem>,
                            ]}
                        />
                    </StyledTableCell>
                </StyledBodyRow>
            )
        },
        [intl, navigate],
    )

    return (
        <Page title={intl.formatMessage({ id: "mothership_product_groups" })}>
            <DefaultWrapperPaper>
                <AllInOneTable
                    headers={[
                        { key: "name", label: intl.formatMessage({ id: "common_name" }) },

                        { key: "price", label: intl.formatMessage({ id: "common_description" }) },
                        { key: "actions", label: "" },
                    ]}
                    itemsToRow={itemToRow}
                    defaultSortBy={"id"}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    fetchItems={fetchProducts}
                    addFunction={() => navigate("add")}
                />
            </DefaultWrapperPaper>
            <GenericModal
                open={!!groupToDelete}
                CustomContent={
                    <Box>
                        <Typography>
                            {intl.formatMessage(
                                { id: "generic_do_you_want_to_delete" },
                                { item: groupToDelete?.name },
                            )}
                        </Typography>
                    </Box>
                }
                CustomActions={
                    <Box>
                        <CustomButton
                            text={intl.formatMessage({ id: "common_no" })}
                            colorTheme={ColorThemeName.noBackground}
                            onClick={() => setGroupToDelete(null)}
                        />
                        <CustomButton
                            text={intl.formatMessage({ id: "common_yes" })}
                            onClick={deleteGroup}
                        />
                    </Box>
                }
                title={""}
                onClose={() => setGroupToDelete(null)}
            />
        </Page>
    )
}
