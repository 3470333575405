import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { ReZipClient } from "@repo/rezip-client"
import { AnonymousClient } from "@repo/rezip-client/anonymous_client"
import { DropPointClient } from "@repo/rezip-client/drop_point_client"
import { MotherShipClient } from "@repo/rezip-client/mothership_client"
import { PartnerClient } from "@repo/rezip-client/partner_client"
import { ShopClient } from "@repo/rezip-client/shop_client"

export const useClient = (): {
    client: ReZipClient
    partnerClient: PartnerClient
    shopClient: ShopClient
    dropPointClient: DropPointClient
    anonymousClient: AnonymousClient
    mothershipClient: MotherShipClient
    selectedClient: ShopClient | PartnerClient | DropPointClient | MotherShipClient | null
} => {
    const { getToken, getSelectedAccountType } = useUser()
    const token = getToken()
    const { enqueueAlert } = useSnackbar()
    const intl = useIntl()
    const selectedAccountType = getSelectedAccountType()

    const result = useMemo(() => {
        const client = new ReZipClient({
            url: import.meta.env.VITE_REZIP_API_URL,
            token: token,
            statusHandlers: {
                403: () => {
                    enqueueAlert(intl.formatMessage({ id: "toast_message_unathorized" }), "error")
                },
            },
        })
        const partnerClient = new PartnerClient(client)
        const shopClient = new ShopClient(client)
        const dropPointClient = new DropPointClient(client)
        const anonymousClient = new AnonymousClient(client)
        const mothershipClient = new MotherShipClient(client)
        let selectedClient = null
        switch (selectedAccountType) {
            case "Partner":
                selectedClient = partnerClient
                break
            case "Shop":
                selectedClient = shopClient
                break
            case "DropPoint":
                selectedClient = dropPointClient
                break
            case "RE-ZIP":
                selectedClient = mothershipClient
        }
        return {
            client,
            partnerClient,
            selectedClient,
            shopClient,
            dropPointClient,
            anonymousClient,
            mothershipClient,
        }
    }, [token, selectedAccountType, enqueueAlert, intl])

    return result
}
