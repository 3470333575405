import { isRouteErrorResponse, useRouteError } from "react-router-dom"

export default function ErrorPage() {
    const error = useRouteError()
    return (
        <div id="error-page">
            <h1>RE-ZIP</h1>
            <h1>Oops!</h1>
            <h2>Sorry, an unexpected error has occurred.</h2>
            {isRouteErrorResponse(error) && (
                <>
                    <p>
                        <i>{error.status + " " + error.statusText}</i>
                    </p>
                </>
            )}
        </div>
    )
}
