import { DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import { StyledDialog, StyledDialogActions } from "./Modal.styles"
import CloseIcon from "@mui/icons-material/Close"

import { ReactElement } from "react"
interface NewModalProps {
    open: boolean
    CustomActions?: ReactElement
    CustomContent: ReactElement
    title: string
    onClose: () => void
}

const GenericModal = ({ open, title, CustomActions, CustomContent, onClose }: NewModalProps) => {
    return (
        <StyledDialog
            sx={{ ".MuiPaper-root": { maxWidth: "75%", width: "auto", overflowY: "visible" } }}
            onClose={onClose}
            open={open}>
            <IconButton
                onClick={onClose}
                sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                }}>
                <CloseIcon />
            </IconButton>

            <DialogTitle sx={{ paddingTop: 6 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ display: "flex", width: "auto" }}>{CustomContent}</DialogContent>
            <StyledDialogActions>{CustomActions}</StyledDialogActions>
        </StyledDialog>
    )
}

export default GenericModal
