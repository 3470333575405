import intlMessagesENGB from "src/locales/en_GB.json"

import { ReactComponent as SVGIconENGB } from "@repo/assets/flags/en_GB.svg"
import { ReactComponent as SVGIconDADK } from "@repo/assets/flags/da_DK.svg"
import { ReactComponent as SVGIconDEDE } from "@repo/assets/flags/de_DE.svg"
import { ReactComponent as SVGIconDEAT } from "@repo/assets/flags/de_AT.svg"
import { ReactComponent as SVGIconFRBE } from "@repo/assets/flags/fr_BE.svg"
import { ReactComponent as SVGIconFRFR } from "@repo/assets/flags/fr_FR.svg"
import { ReactComponent as SVGIconNLBE } from "@repo/assets/flags/nl_BE.svg"
import { ReactComponent as SVGIconNLNL } from "@repo/assets/flags/nl_NL.svg"
import { ReactComponent as SVGIconSQAL } from "@repo/assets/flags/sq_AL.svg"
import { ReactComponent as SVGIconHUHU } from "@repo/assets/flags/hu_HU.svg"

export type LocaleMessages = typeof intlMessagesENGB
export type SupportedLocales =
    | "en_GB"
    | "da_DK"
    | "de_DE"
    | "de_AT"
    | "fr_BE"
    | "fr_FR"
    | "nl_BE"
    | "nl_NL"
    | "sq_AL"
    | "hu_HU"

export const iconMap: Record<SupportedLocales, React.FunctionComponent> = {
    en_GB: SVGIconENGB,
    da_DK: SVGIconDADK,
    de_DE: SVGIconDEDE,
    de_AT: SVGIconDEAT,
    fr_BE: SVGIconFRBE,
    fr_FR: SVGIconFRFR,
    nl_BE: SVGIconNLBE,
    nl_NL: SVGIconNLNL,
    sq_AL: SVGIconSQAL,
    hu_HU: SVGIconHUHU,
}

export const importMessages = (locale: SupportedLocales): Promise<LocaleMessages> | undefined => {
    switch (locale) {
        case "en_GB":
            return import("src/locales/en_GB.json").then((p) => p.default)
        case "da_DK":
            return import("src/locales/da_DK.json").then((p) => p.default)
        case "de_DE":
            return import("src/locales/de_DE.json").then((p) => p.default)
        case "de_AT":
            return import("src/locales/de_AT.json").then((p) => p.default)
        case "fr_BE":
            return import("src/locales/fr_BE.json").then((p) => p.default)
        case "fr_FR":
            return import("src/locales/fr_FR.json").then((p) => p.default)
        case "nl_BE":
            return import("src/locales/nl_BE.json").then((p) => p.default)
        case "nl_NL":
            return import("src/locales/nl_NL.json").then((p) => p.default)
        case "sq_AL":
            return import("src/locales/sq_AL.json").then((p) => p.default)
        case "hu_HU":
            return import("src/locales/hu_HU.json").then((p) => p.default)
    }
}

export const defaultLocale = "en_GB"
