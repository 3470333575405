import { Box, useTheme } from "@mui/material"
import React, { useState } from "react"

interface OTPInputProps {
    onChange: (otp: string) => void
}

export const OTPInput: React.FC<OTPInputProps> = ({ onChange }) => {
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(""))
    const theme = useTheme()

    const handleChange = (element: HTMLInputElement, index: number) => {
        const value = element.value.replace(/\s+/g, "") // Remove spaces
        if (/^[0-9a-zA-Z]$/.test(value) || value === "") {
            const newOtp = [...otp]
            newOtp[index] = value
            setOtp(newOtp)
            onChange(newOtp.join("")) // Notify parent of updated OTP

            // Move focus to the next input box if not the last one
            const nextInput = document.getElementById(`otp-input-${index + 1}`)
            if (value && index < 5) {
                ;(nextInput as HTMLInputElement | null)?.focus() // Safe focus
            }
        }
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pasteData = event.clipboardData.getData("text").replace(/\s+/g, "")
        const newOtp = [...otp]
        pasteData
            .split("")
            .slice(0, 6)
            .forEach((char, index) => {
                if (/^[0-9a-zA-Z]$/.test(char)) {
                    newOtp[index] = char
                }
            })
        setOtp(newOtp)
        onChange(newOtp.join("")) // Notify parent of updated OTP

        // Focus the last filled input box
        const filledIndex = Math.min(pasteData.length - 1, 5)
        const lastInput = document.getElementById(`otp-input-${filledIndex}`)
        ;(lastInput as HTMLInputElement | null)?.focus() // Safe focus
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === "Backspace" && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`)
            ;(prevInput as HTMLInputElement | null)?.focus() // Safe focus
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                padding: "16px 0",
            }}>
            {otp.map((value, index) => (
                <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={(e) => {
                        e.preventDefault()
                        handlePaste(e)
                    }}
                    style={{
                        width: "40px",
                        height: "40px",
                        background: `${theme.palette.inputField.background}`,
                        color: `${theme.palette.text.primary}`,
                        textAlign: "center",
                        fontSize: "18px",
                        border: `1px solid ${theme.palette.inputField.border}`,
                        borderRadius: "4px",
                    }}
                />
            ))}
        </Box>
    )
}
