import { VoucherBatchWithUsage } from "src/routes/shop/vouchers"
import { Typography, Grid } from "@mui/material"

import { styled } from "@mui/system"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { VoucherBatch } from "@repo/rezip-client/shop_client"
import { formatCurrency } from "src/utils/formatCurrency"
import { VoucherPoolRecord } from "./SimplifiedVoucherBatchForm"
import { ColorThemeName, CustomButton } from "./generalComponents/Buttons"

const formatValue = (batch: VoucherBatch, intl: IntlShape): string => {
    switch (batch.type) {
        case "percentage":
            return `${batch.value}%`
        case "gift":
            return `${batch.value} ${batch.unit}(s)`
        case "amount":
            return formatCurrency({ currencyCode: batch.unit, value: batch.value, intl })
    }
}

export type VoucherBatchListProps = {
    batch: VoucherBatchWithUsage
    pools: VoucherPoolRecord
    setBatchIdToBeDeleted: (x: string) => void
    setEditingBatch: (x: VoucherBatch) => void
    setViewingBatch: (x: VoucherBatch) => void
    index: number
}

const VoucherBox = styled(Grid)({
    boxShadow: "1px 1px 5px 0 rgba(0,0,0,0.1)",
    padding: "15px 30px",
    marginBottom: "22px",
})

export const VoucherBatchList = ({
    batch,
    setBatchIdToBeDeleted,
    setEditingBatch,
    setViewingBatch,
    pools,
    index,
}: VoucherBatchListProps) => {
    const intl = useIntl()
    const batchType =
        pools.private.id === batch.voucher_pool_id
            ? intl.formatMessage({ id: "pools_private" })
            : pools.public.id === batch.voucher_pool_id
              ? intl.formatMessage({ id: "pools_public" })
              : pools.private_public.id === batch.voucher_pool_id
                ? intl.formatMessage({ id: "pools_private_public" })
                : ""

    const expired = batch.expires_at ? batch.expires_at < new Date() : false
    return (
        <Grid>
            <VoucherBox>
                <Grid container spacing={1} direction="column">
                    <Grid container spacing={1}>
                        <Grid>
                            <Typography variant="h4">
                                {intl.formatMessage({ id: "vouchers_voucher" })} {index + 1}
                            </Typography>
                        </Grid>
                        <Grid sx={{ alignContent: "center", marginLeft: "auto" }}>
                            {batch.expires_at ? (
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        ...(expired && { color: "warning.main" }),
                                    }}>
                                    {expired
                                        ? intl.formatMessage(
                                              { id: "vouchers_expired" },
                                              { at: intl.formatDate(`${batch.expires_at}`) },
                                          )
                                        : intl.formatMessage(
                                              { id: "voucher_expires_at" },
                                              { at: intl.formatDate(`${batch.expires_at}`) },
                                          )}
                                </Typography>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid>
                        <Typography>{formatValue(batch, intl)}</Typography>
                    </Grid>
                    <Grid>
                        <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                            {batchType}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                            {batch.conditions}
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="space-between" sx={{ marginTop: "16px" }}>
                        <Grid>
                            <Typography sx={{ fontWeight: "400" }}>
                                <FormattedMessage id="vouchers_codes_sent" values={batch} />
                            </Typography>
                        </Grid>
                        <Grid container spacing={1}>
                            <CustomButton
                                colorTheme={ColorThemeName.warning}
                                onClick={() => setBatchIdToBeDeleted(batch.id)}
                                text={intl.formatMessage({ id: "common_delete" })}
                            />
                            <CustomButton
                                colorTheme={ColorThemeName.ecoGreen}
                                onClick={() => setEditingBatch(batch)}
                                text={intl.formatMessage({ id: "common_edit" })}
                            />
                            <CustomButton
                                colorTheme={ColorThemeName.ecoGreen}
                                onClick={() => setViewingBatch(batch)}
                                text={intl.formatMessage({ id: "vouchers_view_codes" })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </VoucherBox>
        </Grid>
    )
}
