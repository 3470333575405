import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableFooter from "@mui/material/TableFooter"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
// import countries from '@utils/countries'
import { FormattedMessage } from "react-intl"
import Grid from "@mui/material/Grid"
import { OrderPartner, Partner, Shop } from "@repo/rezip-client/partner_client"
import BlackLogo from "@repo/assets/logo_black.png"

interface InvoiceProps {
    partner: Partner
    shop: Shop
    order: OrderPartner
}

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#242832",
        color: "#FFFFFF",
        fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: 500,
        color: "#000",
    },
    [`&.${tableCellClasses.footer}`]: {
        fontSize: 14,
        fontWeight: 700,
    },
}))

const StyledTableRow = styled(TableRow)(() => ({
    backgroundColor: "#fff",
    "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
    },
    "& td:last-of-type": {
        textAlign: "right",
    },
    // hide last border
    "&:last-of-type td, &:last-of-type th": {
        border: 0,
    },
}))

const StyledListItem = styled(ListItem)(() => ({
    lineHeight: 1.5,
}))

const Invoice = ({ partner, shop, order }: InvoiceProps) => {
    return (
        <div
            style={{
                fontSize: "10px",
                color: "#000",
                fontWeight: "500",
                margin: 40,
            }}>
            <Box className="header">
                <Grid container sx={{ mb: 2 }}>
                    <Grid size={{ xs: 12 }}>
                        <img src={BlackLogo} width={"30%"} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid size={{ xs: 6 }}>
                        <Box component="div" sx={{ overflow: "hidden", zoom: 1 }}>
                            <List>
                                <StyledListItem disablePadding>
                                    <Typography sx={{ fontSize: 12, fontWeight: 800 }}>
                                        {partner.name ?? ""}
                                    </Typography>
                                </StyledListItem>
                                {partner.billing_address.street && (
                                    <StyledListItem disablePadding>
                                        {partner.billing_address.street}
                                    </StyledListItem>
                                )}
                                {partner.billing_address.postal_code ||
                                    (partner.billing_address.city && (
                                        <StyledListItem disablePadding>{`${
                                            partner.billing_address.postal_code
                                                ? partner.billing_address.postal_code + " "
                                                : ""
                                        }${
                                            partner.billing_address.city
                                                ? partner.billing_address.city
                                                : ""
                                        }`}</StyledListItem>
                                    ))}
                                {partner.billing_address.region && (
                                    <StyledListItem disablePadding>
                                        {partner.billing_address.region}
                                    </StyledListItem>
                                )}
                                {partner.billing_address.country && (
                                    <StyledListItem disablePadding>
                                        {partner.billing_address.country}
                                    </StyledListItem>
                                )}
                                {partner.support_phone && (
                                    <StyledListItem disablePadding>
                                        {partner.support_phone}
                                    </StyledListItem>
                                )}
                                {partner.billing_email && (
                                    <StyledListItem disablePadding>
                                        {partner.billing_email}
                                    </StyledListItem>
                                )}
                            </List>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 1 }}></Grid>
                    <Grid size={{ xs: 5 }}>
                        <Box component="div" sx={{ overflow: "hidden", zoom: 1 }}>
                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 500,
                                    mb: 1,
                                }}>
                                <FormattedMessage id="invoice_delivery_note" />
                            </Typography>
                            {order.created_at && (
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 500,
                                    }}>
                                    <FormattedMessage
                                        id="invoice_fullfillment_date"
                                        values={{
                                            invoiceDate: order.created_at
                                                .toISOString()
                                                .slice(0, 10),
                                        }}
                                    />
                                </Typography>
                            )}
                            {order.number && (
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 500,
                                        marginTop: "5px",
                                    }}>
                                    <FormattedMessage
                                        id="invoice_label_order_no"
                                        values={{ order_no: order.number }}
                                    />
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="content">
                <Grid container sx={{ mb: 2 }} spacing={2}>
                    <Grid size={{ xs: 6 }}>
                        <Box
                            component="div"
                            sx={{
                                border: "0.5px solid #000000",
                                height: "100%",
                            }}>
                            <Box component="div">
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 500,
                                        p: "10px",
                                        pb: 0,
                                    }}>
                                    <FormattedMessage id="common_customer" />
                                </Typography>
                            </Box>
                            <List sx={{ paddingLeft: "10px" }}>
                                {shop.name && (
                                    <StyledListItem disablePadding>{shop.name}</StyledListItem>
                                )}
                                {shop.billing_address.street && (
                                    <StyledListItem disablePadding>
                                        {shop.billing_address.street}
                                    </StyledListItem>
                                )}
                                {shop.billing_address.postal_code && shop.billing_address.city && (
                                    <StyledListItem disablePadding>
                                        {shop.billing_address.postal_code}{" "}
                                        {shop.billing_address.city}
                                    </StyledListItem>
                                )}
                                {shop.billing_address.region && (
                                    <StyledListItem disablePadding>
                                        {shop.billing_address.region}
                                    </StyledListItem>
                                )}
                                {shop.billing_address.country && (
                                    <StyledListItem disablePadding>
                                        {shop.billing_address.country}
                                    </StyledListItem>
                                )}
                                {shop.billing_email && (
                                    <StyledListItem disablePadding>
                                        {shop.billing_email}
                                    </StyledListItem>
                                )}
                                {shop.vat_no && (
                                    <StyledListItem disablePadding>{shop.vat_no}</StyledListItem>
                                )}
                            </List>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                        <Box
                            component="div"
                            sx={{
                                border: "0.5px solid #000000",
                                height: "100%",
                            }}>
                            <Box component="div">
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 500,
                                        p: "10px",
                                        pb: 0,
                                    }}>
                                    <FormattedMessage id="invoice_delivery_address" />
                                </Typography>
                            </Box>
                            <List sx={{ paddingLeft: "10px" }}>
                                {shop.name && (
                                    <StyledListItem disablePadding>{shop.name}</StyledListItem>
                                )}
                                {shop.shipping_address.street && (
                                    <StyledListItem disablePadding>
                                        {shop.shipping_address.street}
                                    </StyledListItem>
                                )}
                                {shop.shipping_address.postal_code &&
                                    shop.shipping_address.city && (
                                        <StyledListItem disablePadding>
                                            {shop.shipping_address.postal_code}{" "}
                                            {shop.shipping_address.city}
                                        </StyledListItem>
                                    )}
                                {shop.shipping_address.region && (
                                    <StyledListItem disablePadding>
                                        {shop.shipping_address.region}
                                    </StyledListItem>
                                )}
                                {shop.shipping_address.country && (
                                    <StyledListItem disablePadding>
                                        {shop.shipping_address.country}
                                    </StyledListItem>
                                )}
                            </List>
                        </Box>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ mb: 2, boxShadow: 0, fontWeight: 500 }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell width={"20%"}>
                                    <FormattedMessage id={"common_sku"} />
                                </StyledTableCell>
                                <StyledTableCell width={"60%"}>
                                    <FormattedMessage id={"common_product"} />
                                </StyledTableCell>
                                <StyledTableCell align="right" width={"20%"}>
                                    <FormattedMessage id={"common_quantity"} />
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.lines.map((line, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{line.sku ?? ""}</StyledTableCell>
                                    <StyledTableCell>{line.name ?? ""}</StyledTableCell>
                                    <StyledTableCell>{line.fulfilled ?? ""}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <StyledTableCell
                                    component="th"
                                    colSpan={2}
                                    align="right"
                                    sx={{
                                        fontWeight: 800,
                                        color: "#000000",
                                        backgroundColor: "#FFFFFF",
                                    }}>
                                    <FormattedMessage id="invoice_total_quantity" />
                                </StyledTableCell>
                                <StyledTableCell
                                    sx={{
                                        color: "#000000",
                                        backgroundColor: "#FFFFFF",
                                        fontWeight: 800,
                                    }}>
                                    {order.lines
                                        .map(({ fulfilled }) => fulfilled)
                                        .reduce((sum, i) => sum + i, 0)}
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Grid container>
                    <Grid size={{ xs: 7 }}>
                        <TableContainer
                            component={Paper}
                            sx={{ boxShadow: 0, backgroundColor: "unset" }}>
                            <Table aria-label="customize table">
                                <caption style={{ textAlign: "center" }}>
                                    <FormattedMessage id="invoice_payment_collected_provision_delivery_note" />
                                </caption>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            <FormattedMessage id="invoice_special_notes" />
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell height="76px" sx={{ verticalAlign: "top" }} />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid size={{ xs: 7 }}></Grid>
                    <Grid size={{ xs: 5 }}>
                        <Box sx={{ fontSize: "14px", marginBottom: "15px" }}>
                            <Box>
                                <FormattedMessage id="invoice_fulfilled_by" />
                            </Box>
                            <Box sx={{ paddingTop: "15px" }}>
                                <FormattedMessage id="common_initials" />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer">
                <Grid container>
                    <Grid size={{ xs: 12 }}>
                        <Typography
                            variant="h3"
                            align="center"
                            gutterBottom={true}
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                            }}>
                            <FormattedMessage id="invoice_thank_you" />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider color="#000" sx={{ my: 1 }} />
                <Grid container={true}>
                    <Grid size={{ xs: 12 }}>
                        <Typography
                            component="div"
                            align="center"
                            sx={{ fontWeight: "500", fontSize: 10 }}>
                            <strong>{partner.name}</strong>,{" "}
                            {`${
                                partner.billing_address.street
                                    ? partner.billing_address.street + ", "
                                    : ""
                            }${
                                partner.billing_address.postal_code
                                    ? partner.billing_address.postal_code + ", "
                                    : ""
                            }${
                                partner.billing_address.city
                                    ? partner.billing_address.city + ", "
                                    : ""
                            }${
                                partner.billing_address.region
                                    ? partner.billing_address.region + ", "
                                    : ""
                            }${partner.billing_address.country ?? ""}${
                                partner.vat_no ? ". reg. " + partner.vat_no : ""
                            }`}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Invoice
