import { FormControl, Select } from "@mui/material"
import { useIntl } from "react-intl"
import { StyledMenuItem } from "./styles"
import { iconMap, SupportedLocales } from "src/services/i18n"

type Props = {
    onChangeLanguage?: (lang: SupportedLocales) => void
}

export default function LanguageSwitcher({ onChangeLanguage }: Props) {
    const intl = useIntl()
    const switchLanguage = (e: { target: { value: string } }) => {
        if (onChangeLanguage) {
            onChangeLanguage(e.target.value as SupportedLocales)
        }
    }

    return (
        <FormControl variant="filled">
            <Select
                sx={{
                    color: "secondaryBackground.main",
                    ".MuiInputBase-input": {
                        fontSize: "20px",
                        padding: "5px 0 6px 14px",
                        alignContent: "center",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        backgroundColor: "secondaryBackground.main",
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            "& .MuiMenuItem-root": {
                                fontSize: "20px",
                                justifyContent: "center",
                            },
                        },
                    },
                }}
                id="language-switcher-select"
                value={intl.locale.replace("-", "_")}
                onChange={switchLanguage}>
                {Object.entries(iconMap).map(([key, Cmp]) => {
                    return (
                        <StyledMenuItem key={key} value={key}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                            {/*@ts-ignore*/}
                            <Cmp width="25px" height="25px" />
                        </StyledMenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
