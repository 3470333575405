import { Typography, Grid } from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { AddressView } from "src/components/Address"
import BarcodeScanInputField from "src/components/BarcodeScanInputField"
import Invoice from "src/components/Invoice"
import Page from "src/components/layout/Page"
import GenericModal from "src/components/modals/GenericModal"
import YesOrNoModal from "src/components/modals/YesOrNoModal"
import OrderLinesTable from "src/components/orderComponents/OrderLinesTable"
import { useUser } from "src/contexts/UserConsumer"
import { OrderState } from "src/utils/commonTypes"
import { useReactToPrint } from "react-to-print"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { OrderLine, OrderPartner, Partner, Shop } from "@repo/rezip-client/partner_client"
import dayjs from "dayjs"
import { ClientError } from "@repo/rezip-client/client_error"
import { RequestError } from "@repo/rezip-client/request_error"
import { OrderConfig } from "src/components/shopComponents/OrderConfiguration"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"

export default function FulfillOrder() {
    const intl = useIntl()
    const { getSelectedAgreement, getToken } = useUser()
    const { orderId } = useParams()

    const [order, setOrder] = useState<OrderPartner>()
    const [shop, setShop] = useState<Shop | null>()
    const [partner, setPartner] = useState<Partner>()
    const [showClosedOrderDialog, setShowClosedOrderDialog] = useState(false)
    const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false)
    const [orderComplete, setOrderComplete] = useState(false)
    const { enqueueAlert } = useSnackbar()
    const { partnerClient } = useClient()
    const [orderConfig, setOrderConfig] = useState<OrderConfig>()
    const [inputFieldValue, setInputFieldValue] = useState("")
    const [initialLoad, setInitialLoad] = useState(true)

    const contentToPrint = useRef(null)
    const handlePrint = useReactToPrint({
        documentTitle: intl.formatMessage(
            { id: "common_order_note" },
            { date: dayjs().format("YYYY-MM-DD") },
        ),
        contentRef: contentToPrint,
    })
    const partnerId = getSelectedAgreement()?.account.id

    useEffect(() => {
        if (!partnerId || !orderId) {
            return
        }

        const fetchShopInfo = async (shopId: string) => {
            const res = await partnerClient.getShop({ partnerId: partnerId, shopId: shopId })

            const config = await partnerClient.getShopOrdering({
                partnerId: partnerId,
                shopId: shopId,
            })

            if (config) {
                setOrderConfig(config)
            }

            setShop(res)
        }

        const fetchOrder = async () => {
            const order = await partnerClient.getOrder({ partnerId, orderId })
            if (order) {
                setOrder(order)
                fetchShopInfo(order.shop_id)
            }
        }
        fetchOrder()

        const fetchPartner = async () => {
            const res = await partnerClient.getPartner(partnerId)

            if (res) {
                setPartner(res)
            }
        }

        fetchPartner()
    }, [getSelectedAgreement, getToken, orderId, partnerClient, partnerId])

    // used to check if a order is filled. used to check wether the buttons should be green or red
    const orderFilled = useMemo(() => {
        return order?.lines.every((line) => line.quantity === line.fulfilled)
    }, [order?.lines])

    const postBarcodeToOrder = async () => {
        const partnerId = getSelectedAgreement()?.account.id

        if (!partnerId || !orderId) {
            return
        }

        try {
            const res = await partnerClient.fulfillOrder(partnerId, orderId, inputFieldValue)

            if (res) {
                setInputFieldValue("") // Clear input field
                if (order) {
                    let lines = order.lines
                    lines = lines.map((line) => {
                        if (line.sku == res.sku) {
                            return res as OrderLine
                        } else {
                            return line
                        }
                    })

                    setOrder({ ...order, lines: lines })
                    setInitialLoad(true)

                    // Returns -1 if no index is found where fulfilled is less than quantity
                    if (
                        lines.findIndex((line) => {
                            return line.fulfilled < line.quantity
                        }) == -1
                    ) {
                        setShowConfirmCloseDialog(true)
                    }
                }

                return enqueueAlert(intl.formatMessage({ id: "common_scanned" }), "success")
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const requestError = new RequestError({ type: (error as any).name })
                if (requestError) {
                    return enqueueAlert(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        intl.formatMessage({ id: `${(error as any).name}` }),
                        "error",
                    )
                }

                throw error
            }
            displayErrorMessage(mappedError, enqueueAlert)

            return enqueueAlert(intl.formatMessage({ id: "common_error" }), "error")
        }
    }

    const closeOrder = async () => {
        if (!partnerId || !orderId) {
            return
        }

        const closeOrder = await partnerClient.patchOrder({
            partnerId,
            orderId,
            body: { state: OrderState.Fulfilled },
        })

        setOrder(closeOrder)
        setOrderComplete(true)
    }

    const handleCloseOrder = () => {
        closeOrder()
        setShowClosedOrderDialog(true)
    }

    return (
        <Page backButton title={intl.formatMessage({ id: "menu_listitem_fulfill" })}>
            <DefaultWrapperPaper>
                <Grid container direction="column" spacing={3}>
                    <Grid container justifyContent="space-between">
                        <Typography>{shop?.name}</Typography>
                        <Grid container spacing={4}>
                            <Typography>
                                {intl.formatMessage({ id: "orders_order_number" })}
                            </Typography>
                            <Grid>{order?.number}</Grid>
                        </Grid>
                        <Grid>
                            {!orderComplete ? (
                                <CustomButton
                                    onClick={() => {
                                        setShowConfirmCloseDialog(true)
                                    }}
                                    text={intl.formatMessage({ id: "orders_close_order" })}
                                    colorTheme={
                                        orderFilled
                                            ? ColorThemeName.actionGreen
                                            : ColorThemeName.warning
                                    }
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                    {!orderComplete && (
                        <Grid size={8}>
                            <BarcodeScanInputField
                                value={inputFieldValue}
                                onScan={async () => {
                                    await postBarcodeToOrder()
                                }}
                                onChange={(value: string) => {
                                    setInputFieldValue(value)
                                }}
                                focused={true}
                            />
                        </Grid>
                    )}
                    {orderComplete && (
                        <Typography variant="h2">
                            {intl.formatMessage({ id: "fulfill_order_complete" })}
                        </Typography>
                    )}
                    <Grid container direction="column" spacing={4}>
                        {order && (
                            <Grid>
                                <OrderLinesTable
                                    currency={orderConfig ? orderConfig.currency : null}
                                    lines={order.lines}
                                    hideUnitPrice={true}
                                    initialLoad={initialLoad}
                                    setInitialLoad={setInitialLoad}
                                />
                            </Grid>
                        )}
                        {shop && (
                            <Grid container spacing={2}>
                                <Grid container direction="column" size={6} spacing={1}>
                                    <Typography sx={{ fontWeight: "800" }}>
                                        {intl.formatMessage({ id: "billing_address" })}
                                    </Typography>
                                    <AddressView address={shop.billing_address} />
                                </Grid>
                                <Grid container direction="column" size={6} spacing={1}>
                                    <Typography sx={{ fontWeight: "800" }}>
                                        {intl.formatMessage({ id: "shipping_address" })}
                                    </Typography>
                                    <AddressView address={shop.shipping_address} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DefaultWrapperPaper>
            <YesOrNoModal
                negativeAction={() => {
                    setShowConfirmCloseDialog(false)
                }}
                title={intl.formatMessage({
                    id: "common_confirm",
                })}
                open={showConfirmCloseDialog}
                onClose={() => {
                    setShowConfirmCloseDialog(false)
                }}
                positiveAction={() => {
                    setShowConfirmCloseDialog(false)
                    handleCloseOrder()
                }}
                positiveColorOverride={
                    orderFilled ? ColorThemeName.actionGreen : ColorThemeName.warning
                }
                contentString={intl.formatMessage({
                    id: "fulfill_confirm_dialog_content",
                })}
            />
            <GenericModal
                open={showClosedOrderDialog}
                title={intl.formatMessage({
                    id: "fulfill_order_closed_dialog_title",
                })}
                onClose={() => setShowClosedOrderDialog(false)}
                CustomContent={<FormattedMessage id="fulfill_order_closed_dialog_content" />}
                CustomActions={
                    <Grid container spacing={1}>
                        <CustomButton
                            onClick={() => {
                                handlePrint()
                                setShowClosedOrderDialog(false)
                            }}
                            text={intl.formatMessage({ id: "common_yes" })}
                            colorTheme={ColorThemeName.standardOutline}
                        />

                        <CustomButton
                            onClick={() => setShowClosedOrderDialog(false)}
                            text={intl.formatMessage({
                                id: "common_no",
                            })}
                        />
                    </Grid>
                }
            />
            <Grid sx={{ display: "none" }}>
                <Grid ref={contentToPrint}>
                    {order && partner && shop && (
                        <Invoice partner={partner} shop={shop} order={order} />
                    )}
                </Grid>
            </Grid>
        </Page>
    )
}
