import { Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import { useIntl, FormattedMessage } from "react-intl"
import InputField from "src/components/InputField"
import StyledForm from "src/components/layout/StyledForm"

import { DefaultWrapperPaper } from "../layout/DefaultPageComponents"
import { MultiCountrySelector } from "../MultiCountrySelector"
import { Shop } from "@repo/rezip-client/partner_client"
import { Shop as ShopShop } from "@repo/rezip-client/shop_client"

interface ShopDetailsProps {
    shop: Shop | ShopShop | undefined
    hideExternalId?: boolean
    hideOperatingCountries?: boolean
    disableFields: boolean
    setShop: (shop: Shop | ShopShop) => void
    create: boolean
    setUserEmail?: (email: string) => void
    userEmail?: string
    setBlock: (block: boolean) => void
    setReturnUrl?: (url: string) => void
    returnUrl?: string
}

// Type guard to check if the shop is of type ShopShop
const isShopShop = (shop: Shop | ShopShop): shop is ShopShop => {
    return "return_policy_url" in shop
}

const ShopDetails = ({
    shop,
    disableFields,
    hideExternalId,
    hideOperatingCountries,
    setShop,
    create,
    userEmail,
    setUserEmail,
    setBlock,
    setReturnUrl,
    returnUrl,
}: ShopDetailsProps) => {
    const intl = useIntl()

    const handleShopChange = <T extends keyof Shop>(field: T, value: Shop[T]) => {
        if (shop && !isShopShop(shop)) {
            setShop({ ...shop, [field]: value })
            setBlock(true)
        }
    }

    return (
        <Grid container direction="column" spacing={2}>
            {create && (
                <Grid container direction="column">
                    <DefaultWrapperPaper>
                        <Grid container direction="column" spacing={1}>
                            <Typography variant="h4">
                                {intl.formatMessage({ id: "common_owner" })}
                            </Typography>

                            {setUserEmail && (
                                <StyledForm>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "user_email",
                                        })}
                                        type="text"
                                        value={userEmail ?? ""}
                                        id="user_email"
                                        name="user_email"
                                        disabled={disableFields}
                                        required
                                        onChange={(e) => {
                                            setUserEmail(e.target.value)
                                            setBlock(true)
                                        }}
                                    />
                                </StyledForm>
                            )}
                        </Grid>
                    </DefaultWrapperPaper>
                </Grid>
            )}
            <Grid container direction="column">
                <DefaultWrapperPaper>
                    <Grid container direction="column" spacing={1}>
                        <Typography variant="h4">
                            <FormattedMessage id="shop_details" />
                        </Typography>

                        <StyledForm>
                            {!create ? (
                                <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                    <Typography>
                                        {intl.formatMessage(
                                            { id: "shop_deltails_shop_id" },
                                            { id: shop?.id },
                                        )}
                                    </Typography>
                                </Grid>
                            ) : null}
                            <Grid container spacing={1}>
                                <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "shop_name",
                                        })}
                                        type="text"
                                        value={shop?.name ?? ""}
                                        id="shop_name"
                                        name="shop_name"
                                        disabled={disableFields}
                                        required
                                        onChange={(e) => handleShopChange("name", e.target.value)}
                                    />
                                </Grid>

                                <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "phone",
                                        })}
                                        type="text"
                                        value={shop?.phone ?? ""}
                                        id="shop_phone"
                                        name="shop_phone"
                                        disabled={disableFields}
                                        required={!create}
                                        onChange={(e) => handleShopChange("phone", e.target.value)}
                                    />
                                </Grid>
                                <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "url",
                                        })}
                                        type="text"
                                        id="shop_web"
                                        name="shop_web"
                                        disabled={disableFields}
                                        required={!create}
                                        onChange={(e) =>
                                            handleShopChange("website", e.target.value)
                                        }
                                        value={shop?.website ?? ""}
                                    />
                                </Grid>
                                {shop && setReturnUrl && (
                                    <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                        <InputField
                                            label={intl.formatMessage({
                                                id: "shop_details_return_policy",
                                            })}
                                            type="text"
                                            id="return_url"
                                            name="shop_return_url"
                                            disabled={disableFields}
                                            onChange={(e) => setReturnUrl(e.target.value)}
                                            value={returnUrl ?? ""}
                                        />
                                    </Grid>
                                )}

                                <Grid container direction="column" size={12}>
                                    <InputField
                                        value={shop?.description ?? ""}
                                        label={intl.formatMessage({
                                            id: "about_us",
                                        })}
                                        helpText={intl.formatMessage({
                                            id: "shop_details_about_us_help",
                                        })}
                                        id="shop_description"
                                        name="shop_description"
                                        disabled={disableFields}
                                        type="text"
                                        required={!create}
                                        expandable
                                        onChange={(e) =>
                                            handleShopChange("description", e.target.value)
                                        }
                                    />
                                </Grid>

                                {!hideOperatingCountries && (
                                    <Grid container direction="column" size={12}>
                                        <MultiCountrySelector
                                            defaultValue={shop?.operating_countries ?? null}
                                            onChange={(e) =>
                                                handleShopChange("operating_countries", e)
                                            }
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </StyledForm>
                    </Grid>
                </DefaultWrapperPaper>
            </Grid>

            {!hideExternalId && (
                <Grid container direction="column">
                    <DefaultWrapperPaper>
                        <StyledForm>
                            <InputField
                                value={shop && "external_id" in shop ? shop.external_id : ""}
                                label={intl.formatMessage({
                                    id: "external_id",
                                })}
                                helpText={intl.formatMessage({
                                    id: "external_id_explanation",
                                })}
                                id="external_id"
                                name="external_id"
                                disabled={disableFields}
                                type="text"
                                onChange={(e) => handleShopChange("external_id", e.target.value)}
                            />
                        </StyledForm>
                    </DefaultWrapperPaper>
                </Grid>
            )}
        </Grid>
    )
}

export default ShopDetails
