import { useUser } from "src/contexts/UserConsumer"
import EditUser from "./account/users/editUser"
import { EditDropPointUser } from "./dropPoint/users/editUser"
import { EditShopUser } from "./shop/users/editUser"

export const EditUserSelector = () => {
    const { getSelectedAccountType } = useUser()
    const accountType = getSelectedAccountType()

    if (accountType === "Partner") {
        return <EditUser />
    } else if (accountType === "DropPoint") {
        return <EditDropPointUser />
    } else if (accountType === "Shop") {
        return <EditShopUser />
    }

    return <></>
}
