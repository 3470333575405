import QRCode from "qrcode"
import { useEffect, useState } from "react"
import BarcodeScanInput from "./BarcodeScanInputField"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useIntl } from "react-intl"

const testString = "HTTPS://APP.RE-ZIP.COM/INSTALL?ID=10RZABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"

export const QRScannerTester = () => {
    const [url, setUrl] = useState<string | null>("")
    const [value, setValue] = useState("")
    const { enqueueAlert } = useSnackbar()
    const intl = useIntl()
    useEffect(() => {
        ;(async () => {
            setUrl(await QRCode.toDataURL(testString))
        })()
    }, [])
    return url ? (
        <>
            <img src={url} alt="QR Code" style={{ width: "200px", height: "200px" }} />
            <BarcodeScanInput
                value={value}
                onChange={setValue}
                onScan={() => {
                    if (value === "10RZABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890") {
                        enqueueAlert(intl.formatMessage({ id: "qr_tester_works" }), "success")
                    } else {
                        enqueueAlert(intl.formatMessage({ id: "qr_tester_doesnt_works" }), "error")
                    }
                }}
            />
        </>
    ) : null
}
