import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Navigate, useNavigate } from "react-router-dom"
import { useUser } from "src/contexts/UserConsumer"
import { SelectChangeEvent, styled, Typography } from "@mui/material"

import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Grid from "@mui/material/Grid"
import { useClient } from "src/hooks/useClient.hook"
import { Agreement, UserAgreementMeWithPermissionGroups } from "@repo/rezip-client/types"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import { GetIconHelper, GetName } from "src/utils/acountChangerHelpers"

import { PartnerLocations } from "src/contexts/UserProvider"
import { Location, Locations } from "@repo/rezip-client/partner_client"
import { MfaModal } from "../modals/MfaModal"
import { CustomButton } from "../generalComponents/Buttons"

const AccountItemWrapper = styled(DefaultWrapperPaper)({
    display: "flex",
    borderRadius: "16px",
    padding: "0",
    flexDirection: "column",
    minHeight: "100%",
})

interface AccountItemProps {
    selected: boolean
}
export type DropDownMap = {
    [id: string]: IMenuItemData[]
}

const AccountItem = styled(Grid)<AccountItemProps>(({ theme, selected }) => ({
    borderWidth: selected ? "2px" : "1px",
    borderStyle: "solid",
    borderColor: selected ? theme.palette.primary.dark : theme.palette.inputField.border,
    borderRadius: "8px",
    cursor: "pointer",
    padding: "10px",
    width: "100%",
    marginBottom: "8px",
    "& p": {
        fontSize: "16px",
    },
}))

export const AccountSelectItem = () => {
    const {
        getUser,
        selectAgreement,
        getSelectedAccountType,
        getSelectedAgreement,
        getToken,
        refreshUser,
        setSelectedLocationId,
        getSelectedLocationId,
        getMfaUsed,
    } = useUser()
    const navigate = useNavigate()
    const user = getUser()
    const type = getSelectedAccountType() ?? ""
    const userAgreements = user?.agreements
    const intl = useIntl()
    const [accepting, setAccepting] = useState(false)
    const selectedAgreement = getSelectedAgreement()
    const { anonymousClient } = useClient()
    const selectedLocationId = getSelectedLocationId()
    const [selectedDropDown, setSelectedDropDown] = useState<IMenuItemData>()
    const [ensureMfa, setEnsureMfa] = useState(false)
    const [mfaOpen, setMfaOpen] = useState(true)
    const [partnerLocations, setPartnerLocations] = useState<PartnerLocations | undefined>(
        undefined,
    )

    const mfaUsed = getMfaUsed()

    const { partnerClient } = useClient()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const agreements = user?.agreements ?? []

    const [loading, setLoading] = useState<boolean>(true)

    const fetchLocations = useCallback(
        async (partnerId: string): Promise<Locations | null> => {
            try {
                const options = { filters: { deleted_at: "" } }
                return await partnerClient.getLocations(partnerId, options)
            } catch (error) {
                console.error("Error fetching locations for partner:", partnerId, error)
                return null // Gracefully handle errors
            }
        },
        [partnerClient],
    )

    const fetchAllLocations = useCallback(async (): Promise<PartnerLocations | undefined> => {
        setLoading(false)
        if (agreements.length === 0) {
            setLoading(false)
            return
        }

        const partnerIds = agreements
            .filter((item) => item.account.type === "Partner")
            .map((item) => item.account.id)

        if (partnerIds.length === 0) {
            setLoading(false)
            return
        }

        try {
            const fetches = partnerIds.map(async (id) => [id, await fetchLocations(id)])
            const locations = await Promise.all(fetches)
            const locationObjects: PartnerLocations = Object.fromEntries(locations)

            setPartnerLocations(locationObjects)
            return locationObjects
        } catch (error) {
            console.error("Error fetching all partner locations:", error)
        }
        setLoading(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchLocations])

    const mfaCheck = useMemo(() => {
        if (!user) return false
        if (!user.mfa_required) return true
        return user.mfa_required && mfaUsed
    }, [mfaUsed, user])

    useEffect(() => {
        if (partnerLocations === undefined && mfaCheck && agreements.length > 0 && loading) {
            const fetch = async () => {
                setLoading(false)
                await fetchAllLocations()
            }
            fetch()
        }
    }, [
        accepting,
        agreements,
        fetchAllLocations,
        loading,
        mfaCheck,
        navigate,
        partnerLocations,
        selectAgreement,
        type,
        userAgreements,
    ])

    const dropDownItems: DropDownMap = useMemo(() => {
        if (!partnerLocations) return {}

        return Object.entries(partnerLocations).reduce((items, [partnerId, locations]) => {
            if (!locations) {
                return items
            }

            const data = locations.map((location: Location) => ({
                id: location.id,
                value: location.name,
            }))

            items[partnerId] = data

            return items
        }, {} as DropDownMap)
    }, [partnerLocations])

    useEffect(() => {
        if (
            partnerLocations &&
            !selectedDropDown &&
            selectedAgreement &&
            dropDownItems[selectedAgreement.account.id] &&
            !loading
        ) {
            if (selectedLocationId) {
                const item = dropDownItems[selectedAgreement.account.id].find(
                    (item) => item.id === selectedLocationId,
                )
                if (item) {
                    setSelectedDropDown(item)
                    return
                } else {
                    const item = dropDownItems[selectedAgreement.account.id].find(
                        (item) => item.id === "default",
                    )
                    if (item) {
                        setSelectedDropDown(item)
                        return
                    }
                }
            } else {
                const item = dropDownItems[selectedAgreement.account.id].find(
                    (item) => item.id === "default",
                )
                if (item) {
                    setSelectedDropDown(item)
                    return
                }
            }
        }
    }, [
        dropDownItems,
        loading,
        navigate,
        partnerLocations,
        selectedAgreement,
        selectedDropDown,
        selectedLocationId,
    ])

    const handleAccept = useCallback(
        async (agreementId: string) => {
            setAccepting(true)
            await anonymousClient.acceptAgreement(agreementId).then(() => refreshUser(getToken()))
            await fetchAllLocations()
        },
        [anonymousClient, fetchAllLocations, refreshUser, getToken],
    )
    const onChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            event.preventDefault()
            if (!selectedAgreement) {
                return
            }
            const value = event.target.value as string
            const item = dropDownItems[selectedAgreement.account.id].find(
                (item) => item.id === value,
            )
            if (item) {
                setSelectedLocationId(item.id)
                setSelectedDropDown(item)
                navigate("/home")
            }
        },
        [dropDownItems, navigate, selectedAgreement, setSelectedLocationId],
    )

    const handleAgreementClick = useCallback(
        (agreement: Agreement) => {
            setEnsureMfa(true)
            if (agreement.account?.type === "Partner") {
                selectAgreement(agreement.id)
                if (
                    dropDownItems &&
                    dropDownItems[agreement.account.id] &&
                    dropDownItems[agreement.account.id].length === 0
                ) {
                    return navigate("/account/warehouses/create")
                }
                if (
                    dropDownItems &&
                    dropDownItems[agreement.account.id] &&
                    dropDownItems[agreement.account.id].length === 1
                ) {
                    setSelectedLocationId(dropDownItems[agreement.account.id][0].id)
                    navigate("/home")
                }
            } else {
                selectAgreement(agreement.id)
                navigate("/home")
            }
        },
        [dropDownItems, navigate, selectAgreement, setSelectedLocationId],
    )
    const verify = (agreement: UserAgreementMeWithPermissionGroups) => {
        if (!agreement.account) {
            return
        }
        if (dropDownItems[agreement.account.id] && dropDownItems[agreement.account.id].length < 1) {
            selectAgreement(agreement.id)
            if (agreement.account.type === "Partner") {
                navigate("/account/warehouses/create")
            } else {
                navigate("/home")
            }
        }
    }

    const listItems: { [key: string]: ReactNode[] } = useMemo(() => {
        const items: { [key: string]: ReactNode[] } = {
            "RE-ZIP": [],
            Partner: [],
            DropPoint: [],
            Shop: [],
            default: [],
        }

        if (userAgreements) {
            userAgreements.forEach((agreement) => {
                const agree = (
                    <>
                        <AccountItem
                            key={agreement.id}
                            onClick={() => handleAgreementClick(agreement)}
                            selected={selectedAgreement?.id === agreement.id}>
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ cursor: "pointer" }}>
                                <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                    {agreement.account.name}
                                </Typography>
                                {!agreement.accepted ? (
                                    <CustomButton
                                        onClick={() => handleAccept(agreement.id)}
                                        text={intl.formatMessage({
                                            id: "common_accept",
                                        })}
                                    />
                                ) : null}
                            </Grid>

                            {selectedAgreement?.id === agreement.id &&
                            dropDownItems[agreement.account.id] &&
                            dropDownItems[agreement.account.id].length > 1 ? (
                                <Grid container sx={{ minWidth: "100%", marginTop: "8px" }}>
                                    <SelectField
                                        value={selectedDropDown?.id ?? ""}
                                        onChange={onChange}
                                        menuItems={dropDownItems[agreement.account.id]}
                                        label={intl.formatMessage({ id: "common_location" })}
                                        placeholder={
                                            dropDownItems[agreement.account.id].length === 0
                                                ? intl.formatMessage({ id: "common_no_locations" })
                                                : intl.formatMessage({
                                                      id: "common_select_location",
                                                  })
                                        }
                                        large
                                    />
                                </Grid>
                            ) : null}
                        </AccountItem>
                    </>
                )

                items[agreement.account.type].push(agree)
            })
        }

        return items
    }, [
        dropDownItems,
        handleAccept,
        handleAgreementClick,
        intl,
        onChange,
        selectedAgreement?.id,
        selectedDropDown?.id,
        userAgreements,
    ])

    if (!userAgreements) {
        return <Navigate to="/home" />
    }

    if (loading) {
        return <FormattedMessage id="common_loading" defaultMessage="Loading..." />
    }

    return (
        <Grid container direction="column">
            {ensureMfa && selectedAgreement ? (
                <MfaModal
                    open={mfaOpen}
                    setOpen={setMfaOpen}
                    agreement={selectedAgreement}
                    onVerify={() => verify(selectedAgreement)}
                />
            ) : null}
            <Typography sx={{ fontSize: "16px", marginBottom: "20px" }}>
                {selectedAgreement
                    ? intl.formatMessage({ id: "change_account" })
                    : intl.formatMessage({
                          id: "pick_account",
                      })}
            </Typography>
            <Grid container direction={"row"} spacing={6} sx={{ width: "100%" }}>
                {Object.keys(listItems).map((key) =>
                    listItems[key].length > 0 ? (
                        <Grid
                            container
                            direction={"column"}
                            spacing={6}
                            size={{ xs: 12, sm: 6, md: 3 }}>
                            <AccountItemWrapper>
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    sx={{ padding: "15px" }}>
                                    {GetIconHelper(key)}
                                    <Typography sx={{ fontSize: "20px", fontWeight: "800" }}>
                                        {GetName(key)}
                                    </Typography>
                                </Grid>
                                <Grid sx={{ padding: "15px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            marginLeft: "2px",
                                            marginBottom: "2px",
                                        }}>
                                        {intl.formatMessage({ id: "common_account" })}
                                    </Typography>
                                    {listItems[key]}
                                </Grid>
                            </AccountItemWrapper>
                        </Grid>
                    ) : null,
                )}
            </Grid>
        </Grid>
    )
}
