import { Box, Skeleton, Typography } from "@mui/material"
import {
    axisClasses,
    BarPlot,
    ChartsGrid,
    ChartsLegend,
    ChartsXAxis,
    ChartsYAxis,
    LinePlot,
    ResponsiveChartContainer,
} from "@mui/x-charts"
import { useIntl } from "react-intl"

interface BarAndLineGraphProps {
    height: number
    barData?: number[]
    lineData?: number[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    xBandValues?: any[]
    metric: string
    title: string
    total: string
    barColor: string
    lineColor: string
    graphsReady: boolean
}

export const BarAndLineGraph = ({
    barData,
    xBandValues,
    metric,
    title,
    total,

    lineData,
    graphsReady,
    lineColor,
}: BarAndLineGraphProps) => {
    const intl = useIntl()

    if (
        !graphsReady ||
        !barData ||
        !xBandValues ||
        barData.length === 0 ||
        xBandValues.length === 0
    ) {
        return (
            <Skeleton
                animation={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    padding: "12px",
                }}
                variant="rectangular"
                width={"100%"}
                height={"380px"}>
                {intl.formatMessage({ id: "common_loading", defaultMessage: "Loading" })}
            </Skeleton>
        )
    }
    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "12px",
                }}>
                <Typography variant="h4">{title}</Typography>
                <Typography variant="h5">
                    {intl.formatNumber(Number(total))} {metric}
                </Typography>
            </Box>
            <ResponsiveChartContainer
                height={250}
                margin={{ bottom: 100, top: 10, left: 60 }}
                xAxis={[
                    {
                        scaleType: "band",
                        data: xBandValues,
                        id: "band",
                    },
                ]}
                yAxis={[
                    {
                        id: "right-line-Y",
                        valueFormatter: (item: number) => `${item}`,
                    },
                    {
                        id: "left-bar-Y",
                        valueFormatter: (item: number) =>
                            item.toString().length >= 5 ? `${item.toExponential()}` : `${item}`,
                    },
                ]}
                series={[
                    {
                        type: "line",
                        id: "right-lineY",
                        yAxisKey: "right-line-Y",
                        data: lineData,
                        color: lineColor,
                        label: intl.formatMessage({
                            id: "reports_acc_reduction",
                        }),
                    },
                ]}
                sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {},
                    [`.${axisClasses.right} .${axisClasses.label}`]: {},
                }}>
                <ChartsGrid horizontal />
                <BarPlot />
                <LinePlot />

                <ChartsXAxis axisId="band" tickLabelStyle={{ angle: -90, textAnchor: "end" }} />
                <ChartsYAxis axisId="right-line-Y" position="left" />

                <ChartsLegend
                    slotProps={{
                        legend: {
                            padding: 4,
                            itemGap: 20,
                            labelStyle: { fontFamily: "Manrope", fontSize: "12px" },
                        },
                    }}
                    position={{ horizontal: "middle", vertical: "bottom" }}
                />
            </ResponsiveChartContainer>
        </div>
    )
}
