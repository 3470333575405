import dayjs from "dayjs"
import {
    DAY,
    EnvironmentDataObject,
    EnvStats,
    MONTH,
    PerSku,
    PerSkuData,
    QUATER,
    SimpleProducts,
    WEEK,
    YEAR,
} from "./reports.types"
import { calcuclateRefPerSku } from "src/utils/referenceCalculator"
import { StatisticsEntry } from "@repo/rezip-client/reporting_client"

export const accumulatePerSkuData = (perSku: PerSku): PerSkuData => {
    const accumulatedData: PerSkuData = {
        produced: 0,
        reused: 0,
        recycled: 0,
        shipped: 0,
        transported_km: 0,
    }

    Object.values(perSku).forEach((skuData) => {
        accumulatedData.produced += skuData.produced ?? 0
        accumulatedData.reused += skuData.reused ?? 0
        accumulatedData.recycled += skuData.recycled ?? 0
        accumulatedData.shipped += skuData.shipped ?? 0
        accumulatedData.transported_km += skuData.transported_km ?? 0
    })

    return accumulatedData
}

export const createDefault = (label: string): StatisticsEntry => {
    return {
        time_increment: label,
        transport_co2e: 0,
        transport_water: 0,
        production_co2e: 0,
        production_water: 0,
        destruction_co2e: 0,
        destruction_water: 0,
        assembly_co2e: 0,
        assembly_water: 0,
        sorting_co2e: 0,
        sorting_water: 0,
        waste_kg: 0,
        per_sku: {},
    }
}

export const fillPerSku = (existingPerSku: PerSku): PerSku => {
    const item = Object.fromEntries(
        Object.entries(existingPerSku).map(([sku, data]) => [
            sku,
            {
                produced: data.produced ?? 0,
                reused: data.reused ?? 0,
                recycled: data.recycled ?? 0,
                shipped: data.shipped ?? 0,
                transported_km: data.transported_km ?? 0,
            },
        ]),
    )
    return item
}

export const mergePerSku = (perSku1: PerSku, perSku2: PerSku): PerSku => {
    // Create a copy of perSku1 to start with
    const merged: PerSku = { ...perSku1 }

    // Iterate over each SKU in the second PerSku object
    Object.entries(perSku2).forEach(([sku, data]) => {
        // Initialize the SKU in the merged object if it doesn't exist
        if (!merged[sku]) {
            merged[sku] = {
                produced: 0,
                reused: 0,
                recycled: 0,
                shipped: 0,
                transported_km: 0,
            }
        }

        // Accumulate the values from perSku2 into merged
        ;(Object.keys(data) as Array<keyof PerSkuData>).forEach((key) => {
            // Ensure the key is valid in the PerSkuData type
            merged[sku][key] += data[key] ?? 0
        })
    })

    return merged
}
export const combineEnvStats = (data: EnvStats[]) => {
    const statsMap: Record<string, EnvStats> = {}

    data.forEach((item) => {
        const timeIncrement = item.time_increment

        // If the time increment doesn't exist in the map, initialize it with default values
        if (!statsMap[timeIncrement]) {
            statsMap[timeIncrement] = createDefault(timeIncrement)
        }

        const existing = statsMap[timeIncrement]

        // Create a new object to accumulate the values to avoid mutating `existing`
        statsMap[timeIncrement] = {
            time_increment: existing.time_increment,
            transport_co2e: (existing.transport_co2e ?? 0) + (item.transport_co2e ?? 0),
            transport_water: (existing.transport_water ?? 0) + (item.transport_water ?? 0),
            production_co2e: (existing.production_co2e ?? 0) + (item.production_co2e ?? 0),
            production_water: (existing.production_water ?? 0) + (item.production_water ?? 0),
            destruction_co2e: (existing.destruction_co2e ?? 0) + (item.destruction_co2e ?? 0),
            destruction_water: (existing.destruction_water ?? 0) + (item.destruction_water ?? 0),
            assembly_co2e: (existing.assembly_co2e ?? 0) + (item.assembly_co2e ?? 0),
            assembly_water: (existing.assembly_water ?? 0) + (item.assembly_water ?? 0),
            sorting_co2e: (existing.sorting_co2e ?? 0) + (item.sorting_co2e ?? 0),
            sorting_water: (existing.sorting_water ?? 0) + (item.sorting_water ?? 0),
            waste_kg: (existing.waste_kg ?? 0) + (item.waste_kg ?? 0),
            per_sku: mergePerSku(existing.per_sku, item.per_sku),
        }
    })
    // Return an array of combined results
    return Object.values(statsMap)
}

export const combineSum = (data: StatisticsEntry[]): StatisticsEntry => {
    if (data.length === 1) {
        return data[0]
    }
    return data.reduce((acc, item) => {
        acc.transport_co2e += item ? (item.transport_co2e ?? 0) : 0
        acc.transport_water += item ? (item.transport_water ?? 0) : 0
        acc.production_co2e += item ? (item.production_co2e ?? 0) : 0
        acc.production_water += item ? (item.production_water ?? 0) : 0
        acc.destruction_co2e += item ? (item.destruction_co2e ?? 0) : 0
        acc.destruction_water += item ? (item.destruction_water ?? 0) : 0
        acc.assembly_co2e += item ? (item.assembly_co2e ?? 0) : 0
        acc.assembly_water += item ? (item.assembly_water ?? 0) : 0
        acc.sorting_co2e += item ? (item.sorting_co2e ?? 0) : 0
        acc.sorting_water += item ? (item.sorting_water ?? 0) : 0
        acc.waste_kg += item ? (item.waste_kg ?? 0) : 0
        acc.per_sku = mergePerSku(acc.per_sku, item.per_sku)

        return acc
    }, createDefault(""))
}

export const createWithTimeIncrement = (item: EnvStats, timeLabel: string) => {
    const mapped = {
        transport_co2e: item.transport_co2e ?? 0,
        transport_water: item.transport_water ?? 0,
        production_co2e: item.production_co2e ?? 0,
        production_water: item.production_water ?? 0,
        destruction_co2e: item.destruction_co2e ?? 0,
        destruction_water: item.destruction_water ?? 0,
        assembly_co2e: item.assembly_co2e ?? 0,
        assembly_water: item.assembly_water ?? 0,
        sorting_co2e: item.sorting_co2e ?? 0,
        sorting_water: item.sorting_water ?? 0,
        waste_kg: item.waste_kg ?? 0,
        time_increment: timeLabel,
        per_sku: fillPerSku(item.per_sku),
    } as EnvStats

    return mapped
}

export const getApiUrl = (path: string, partnerId: string) =>
    `${import.meta.env.VITE_REZIP_API_URL}${path}/${partnerId}`

export const alignDateToTimeFrame = (date: Date, timeFrame: number) => {
    const timeFrameInMilliseconds = timeFrame * 1000
    const alignedTime =
        Math.floor(date.getTime() / timeFrameInMilliseconds) * timeFrameInMilliseconds
    return new Date(alignedTime)
}

export const fixDatesForSafari = (isoDate: string) => {
    return dayjs(isoDate).isValid() ? dayjs(isoDate) : dayjs(isoDate.replace(/-/gi, "/"))
}

export const correctDates = (start: Date, end: Date, precision: number) => {
    end = new Date(end)
    end.setDate(end.getDate() + 1)
    const startSec = Math.floor(start.getTime() / 1000)
    const endSec = Math.floor(end.getTime() / 1000)

    const startOfMiddle = dayjs(Math.ceil(startSec / precision) * (precision * 1000))
    const endOfMiddle = dayjs(Math.floor(endSec / precision) * (precision * 1000))

    //batch fetch and combine day fetches to week doesnt matter if the week is only 1 day
    const acctualStart = dayjs(alignDateToTimeFrame(new Date(start.getTime()), DAY))
    const accutualEnd = dayjs(alignDateToTimeFrame(new Date(end.getTime()), DAY))

    return {
        startOfMiddle: startOfMiddle,
        endOfMiddle: endOfMiddle,
        acctualStart: acctualStart,
        accutualEnd: accutualEnd,
    }
}

export const mapSumToEnviormentData = (data: StatisticsEntry) => {
    const totalWater =
        data.sorting_water +
        data.transport_water +
        data.production_water +
        data.destruction_water +
        data.assembly_water

    const totalCo2 =
        data.production_co2e +
        data.sorting_co2e +
        data.transport_co2e +
        data.destruction_co2e +
        data.assembly_co2e

    const totalWaste = data.waste_kg
    const perSku = data.per_sku

    return { totalWater: totalWater, totalWaste: totalWaste, totalCo2: totalCo2, perSku: perSku }
}

const getAccumulatedData = (uData: number[]) => {
    let cumulativeSum = 0
    return uData.map((value) => {
        cumulativeSum += value
        return cumulativeSum
    })
}

export const mapDataToEnviormentData = (data: EnvStats[], products: SimpleProducts[]) => {
    const co2: EnvironmentDataObject = { pData: [], uData: [] }
    const waste: EnvironmentDataObject = { pData: [], uData: [] }
    const water: EnvironmentDataObject = { pData: [], uData: [] }
    const reclaimed: number[] = []
    const reused: number[] = []
    const recycled: number[] = []
    const perSku: PerSku = {}
    const fulFilled: number[] = []
    const produced: number[] = []

    data.forEach((item) => {
        const accumulatedData = accumulatePerSkuData(item.per_sku)

        const { calculatedWater, calculatedCo2, calculatedWaste } = calcuclateRefPerSku(
            item.per_sku,
            products,
        )

        Object.entries(item.per_sku).forEach(([sku, skuData]) => {
            // Initialize the SKU data if it hasn't been initialized yet
            perSku[sku] = perSku[sku] || {
                produced: 0,
                reused: 0,
                recycled: 0,
                shipped: 0,
                transported_km: 0,
            }
            ;(
                ["produced", "reused", "recycled", "shipped", "transported_km"] as Array<
                    keyof PerSkuData
                >
            ).forEach((key) => {
                perSku[sku][key] += skuData[key] ?? 0
            })
        })

        const reclaimedForItem = Number(accumulatedData.reused) + Number(accumulatedData.recycled)

        reclaimed.push(reclaimedForItem)
        reused.push(Number(accumulatedData.reused))
        recycled.push(Number(accumulatedData.recycled))
        fulFilled.push(Number(accumulatedData.shipped))
        produced.push(Number(accumulatedData.produced))

        const co2e =
            calculatedCo2 -
            (item.production_co2e +
                item.sorting_co2e +
                item.transport_co2e +
                item.destruction_co2e +
                item.assembly_co2e)
        co2.uData.push(co2e)

        waste.uData.push(calculatedWaste - item.waste_kg)

        const waterUse =
            calculatedWater -
            (item.sorting_water +
                item.transport_water +
                item.production_water +
                item.destruction_water +
                item.assembly_water)
        water.uData.push(waterUse)
    })

    return {
        rezipDataPoints: {
            co2: { ...co2, pData: getAccumulatedData(co2.uData) },
            waste: { ...waste, pData: getAccumulatedData(waste.uData) },
            water: { ...water, pData: getAccumulatedData(water.uData) },
        },
        totalCollectedData: { reclaimed, reused, recycled, fulFilled, produced },
        perSku,
    }
}
// 1480

export const mapSimpleScan = (data: { created_at: string }[], interval: number) => {
    // Helper function to convert date based on interval
    const convertDate = (dateStr: string, interval: number) => {
        const date = dayjs(dateStr)
        switch (interval) {
            case DAY:
                return date.format("DD/MM/YYYY")
            case WEEK:
                return `Week ${date.week()}`
            case MONTH:
                return date.format("MMM YYYY")
            case QUATER:
                return `Q${Math.floor((date.month() + 3) / 3)} ${date.year()}`
            case YEAR:
                return date.year().toString()
            default:
                return "" // Default case to handle unknown intervals
        }
    }

    // Transform data based on interval
    return data.map((item) => ({
        created_at: convertDate(item.created_at, interval),
    }))
}

export const sumSimpleScans = (
    scans: { created_at: string }[],
    interval: number,
    labels: string[],
) => {
    const sums: number[] = []

    const mappedScans = mapSimpleScan(scans, interval)
    labels.forEach((label) => {
        let fulFills = 0
        mappedScans.forEach((scan) => {
            if (scan.created_at === label) {
                fulFills += 1
            }
        })
        sums.push(fulFills)
    })

    return sums
}

// this somewhat backfills the data, so MUICharts can handle them correctly
// This also combines data if we get two or maybe three datapoint with the same time increment, we merge them
export const mapEnvStats = (
    labels: string[],
    data: EnvStats[],
    interval: number,
    simpleProducts: SimpleProducts[],
) => {
    if (interval === DAY) {
        const dataWithConvertedDate = data.map((item) => {
            const day = fixDatesForSafari(item.time_increment).format("DD/MM/YYYY")
            return createWithTimeIncrement(item, day)
        })

        const combinedData = combineEnvStats(dataWithConvertedDate)

        const filledData = labels.map((label) => {
            const existingDataPoint = combinedData.find((item) => {
                if (item.time_increment === label) {
                    return item
                }
            })

            if (existingDataPoint) {
                return existingDataPoint
            } else {
                return createDefault(label)
            }
        })

        return mapDataToEnviormentData(filledData, simpleProducts)
    }
    if (interval === WEEK) {
        const dataWithConvertedDate = data.map((item) => {
            const week = fixDatesForSafari(item.time_increment).week()
            return createWithTimeIncrement(item, `Week ${week}`)
        })

        const combinedData = combineEnvStats(dataWithConvertedDate)
        const filledData = labels.map((label) => {
            const existingDataPoint = combinedData.find((item) => {
                if (item.time_increment === label) {
                    return item
                }
            })

            if (existingDataPoint) {
                return existingDataPoint
            } else {
                return createDefault(label)
            }
        })

        return mapDataToEnviormentData(filledData, simpleProducts)
    }

    if (interval === MONTH) {
        const dataWithConvertedDate = data.map((item) => {
            const month = fixDatesForSafari(item.time_increment).format("MMM YYYY")
            return createWithTimeIncrement(item, month)
        })

        const combinedData = combineEnvStats(dataWithConvertedDate)
        const filledData = labels.map((label) => {
            const existingDataPoint = combinedData.find((item) => {
                if (item.time_increment === label) {
                    return item
                }
            })

            if (existingDataPoint) {
                return existingDataPoint
            } else {
                return createDefault(label)
            }
        })

        return mapDataToEnviormentData(filledData, simpleProducts)
    }

    if (interval === QUATER) {
        const dataWithConvertedDate = data.map((item) => {
            const quarter = Math.floor(
                (new Date(fixDatesForSafari(item.time_increment).toISOString()).getMonth() + 3) / 3,
            )
            return createWithTimeIncrement(
                item,
                `Q${quarter} ${new Date(
                    fixDatesForSafari(item.time_increment).toISOString(),
                ).getFullYear()}`,
            )
        })
        const combinedData = combineEnvStats(dataWithConvertedDate)
        const filledData = labels.map((label) => {
            const existingDataPoint = combinedData.find((item) => {
                if (item.time_increment === label) {
                    return item
                }
            })

            if (existingDataPoint) {
                return existingDataPoint
            } else {
                return createDefault(label)
            }
        })

        return mapDataToEnviormentData(filledData, simpleProducts)
    }

    if (interval === YEAR) {
        const dataWithConvertedDate = data.map((item) => {
            const year = new Date(fixDatesForSafari(item.time_increment).toISOString())
                .getFullYear()
                .toString()
            return createWithTimeIncrement(item, `${year}`)
        })
        const combinedData = combineEnvStats(dataWithConvertedDate)
        const filledData = labels.map((label) => {
            const existingDataPoint = combinedData.find((item) => {
                if (item.time_increment === label) {
                    return item
                }
            })

            if (existingDataPoint) {
                return existingDataPoint
            } else {
                return createDefault(label)
            }
        })

        return mapDataToEnviormentData(filledData, simpleProducts)
    }
}

// used for cleaning a date, so it's always at 00.00.00.0000. This is very important for the request
export const cleanDate = (date: Date) => {
    const day = 1000 * 60 * 60 * 24
    const time = Math.floor(date.getTime() / day) * day
    const test = new Date(time)
    return test
}

// creates labels based on what interval is selected
export const labelsBasedOnTime = (interval: string, startDate: Date, endDate: Date) => {
    const content: string[] = []
    const parsedInterval = parseInt(interval)
    const start = dayjs(startDate)
    const end = dayjs(endDate)

    // Helper function to format labels based on interval
    const formatLabel = (date: dayjs.Dayjs) => {
        switch (parsedInterval) {
            case DAY:
                return date.format("DD/MM/YYYY")
            case WEEK:
                return `Week ${date.week()}`
            case MONTH:
                return date.format("MMM YYYY")
            case QUATER:
                return `Q${Math.floor((date.month() + 3) / 3)} ${date.year()}`
            case YEAR:
                return date.year().toString()
            default:
                return ""
        }
    }

    let currentDate = start

    while (currentDate <= end) {
        content.push(formatLabel(currentDate))

        switch (parsedInterval) {
            case DAY:
                currentDate = currentDate.add(1, "day")
                break
            case WEEK:
                currentDate = currentDate.add(1, "week")
                break
            case MONTH:
                currentDate = currentDate.add(1, "month")
                break
            case QUATER:
                currentDate = currentDate.add(3, "month")
                break
            case YEAR:
                currentDate = currentDate.add(1, "year")
                break
            default:
                break
        }
    }
    if (!content.includes(formatLabel(end))) {
        content.push(formatLabel(end))
    }

    return Array.from(new Set(content)) // Ensure labels are unique
}
