import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress"
import { styled } from "@mui/material/styles"

const StyledSpinner = styled(CircularProgress)<CircularProgressProps>(({ theme }) => ({
    color: theme.palette.primary.main,
}))

export default function Spinner() {
    return <StyledSpinner />
}
