import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import InputField from "src/components/InputField"
import Page from "src/components/layout/Page"
import StyledForm from "src/components/layout/StyledForm"
import { useClient } from "src/hooks/useClient.hook"
import Grid from "@mui/material/Grid"
import { ClientError } from "@repo/rezip-client/client_error"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"
import { CustomButton } from "src/components/generalComponents/Buttons"
export const ProductGroupPage = () => {
    const { id } = useParams()
    const { mothershipClient } = useClient()
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const intl = useIntl()
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()

    useEffect(() => {
        if (id && !name && !description) {
            const fetch = async () => {
                const response = await mothershipClient.getProductGroup(id)
                if (response) {
                    setName(response?.name)
                    setDescription(response.description)
                }
            }
            fetch()
        }
    }, [description, id, mothershipClient, name])

    const save = useCallback(async () => {
        if (!id || !name || !description) {
            return
        }
        try {
            const resp = await mothershipClient.patchProductGroup(id, {
                name: name,
                description: description,
            })
            if (resp) {
                setName(resp.name)
                setDescription(resp.description)
                return enqueueAlert(intl.formatMessage({ id: "common_success" }), "success")
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return displayErrorMessage(mappedError, enqueueAlert)
        }
    }, [description, enqueueAlert, id, intl, mothershipClient, name])

    const create = useCallback(async () => {
        if (!name || !description) {
            return
        }
        try {
            const resp = await mothershipClient.postProductGroup({
                name: name,
                description: description,
            })
            if (resp) {
                setName(resp.name)
                setDescription(resp.description)
                navigate(-1)
                return enqueueAlert(intl.formatMessage({ id: "common_success" }), "success")
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return displayErrorMessage(mappedError, enqueueAlert)
        }
    }, [description, enqueueAlert, intl, mothershipClient, name, navigate])

    return (
        <Page
            title={
                id
                    ? intl.formatMessage({ id: "mothership_edit_product_group" })
                    : intl.formatMessage({ id: "mothership_create_product_group" })
            }
            backButton>
            <Grid justifyContent={"center"} container>
                <Grid size={{ xs: 10, md: 10, lg: 5 }} sx={{ margin: "10px" }}>
                    <DefaultWrapperPaper
                        sx={{
                            alignSelf: "center",
                            justifyContent: "center",
                        }}>
                        <StyledForm sx={{ gap: "12px" }}>
                            <InputField
                                label={intl.formatMessage({ id: "common_name" })}
                                id={"groupName"}
                                name={"groupName"}
                                type={"text"}
                                value={name}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    setName(event.target.value)
                                }
                            />
                            <InputField
                                label={intl.formatMessage({
                                    id: "description",
                                    defaultMessage: "Description",
                                })}
                                value={description}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    setDescription(event.target.value)
                                }
                                expandable
                                id="description"
                                name="description"
                                type={"Text"}
                            />
                            <CustomButton
                                onClick={id ? save : create}
                                text={
                                    id
                                        ? intl.formatMessage({ id: "common_save" })
                                        : intl.formatMessage({ id: "common_create" })
                                }
                            />
                        </StyledForm>
                    </DefaultWrapperPaper>
                </Grid>
            </Grid>
        </Page>
    )
}
