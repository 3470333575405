import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useStorageCreation } from "src/contexts/StorageCreationConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { QueryingOptions } from "@repo/rezip-client"
import { Location, StorageUnit } from "@repo/rezip-client/partner_client"
import { Product } from "@repo/rezip-client/types"

export type ProductWithQuant = {
    quantity: number
} & Product

export type StorageList = {
    [id: string]: StorageUnit[]
}

const useStockKeepingController = () => {
    const { getSelectedAgreement, getToken, getSelectedLocationId } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const locationId = getSelectedLocationId()
    const [locations, setLocations] = useState<Array<Location>>()
    const { setSearchTerm } = useStorageCreation()
    const { partnerClient } = useClient()
    const [initialLoad, setInitialLoad] = useState(true)
    const navigate = useNavigate()

    const scanBarcode = useCallback(
        async (code: string) => {
            if (!partnerId || !locationId) {
                return
            }

            const options = {
                filters: { barcode: `*${code}*` },
                pageSize: 1,
            }

            const hasUnit = await partnerClient.getStorageUnits(partnerId, locationId, options)

            if (hasUnit && hasUnit.length > 0) {
                setSearchTerm(hasUnit[0].barcode)
                return navigate(
                    `/warehouse/storage-units/view/${hasUnit[0].location_id}/unit/${hasUnit[0].id}`,
                )
            } else {
                setSearchTerm(code)
                return navigate("create")
            }
        },
        [locationId, navigate, partnerClient, partnerId, setSearchTerm],
    )

    useEffect(() => {
        const partnerId = getSelectedAgreement()?.account.id

        if (!partnerId) {
            return
        }

        const fetchLocations = async () => {
            if (!partnerId) {
                return
            }
            const locationsResult = await partnerClient.getLocations(partnerId)

            if (locationsResult) {
                const tempArray = new Array<Location>()
                for (let index = 0; index < locationsResult.length; index++) {
                    const location = locationsResult[index] as Location
                    tempArray.push(location)
                }
                setLocations(tempArray)
            }
        }
        fetchLocations()
    }, [getSelectedAgreement, getSelectedLocationId, getToken, partnerClient])

    const fetchItems = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            const selectedLocationId = getSelectedLocationId()
            if (!partnerId || !selectedLocationId) {
                return
            }

            const options = {
                filters: { ...filters },
                pageFrom: pageFrom,
                pageSize: pageSize,
                sortBy: sortBy,
                sortDir: sortDir,
            }
            const items = await partnerClient.getStorageUnits(
                partnerId,
                selectedLocationId,
                options,
            )
            return items
        },

        [getSelectedLocationId, partnerClient, partnerId],
    )

    return {
        locations,
        scanBarcode,
        fetchItems,
        initialLoad,
        setInitialLoad,
    }
}
export default useStockKeepingController
