import { Box, Button, styled, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { ClientError } from "@repo/rezip-client/client_error"
import { PermissionGroup } from "@repo/rezip-client/types"
import { displayErrorMessage, errorConverter } from "src/utils/errorConverter"

interface StatusElementProps {
    active: number
}

const StatusButton = styled(Button)<StatusElementProps>(({ active, theme }) => ({
    alignContent: "center",
    whiteSpace: "nowrap",
    padding: "8px 16px",
    borderRadius: "6px",
    minWidth: "200px",
    color: active ? theme.palette.infoButton.contrastText : theme.palette.infoButton.main,
    backgroundColor: active ? theme.palette.infoButton.main : "transparent",
    border: "1px solid",
    fontWeight: 800,
    ":hover": {
        backgroundColor: theme.palette.infoButton.light,
        color: theme.palette.infoButton.contrastText,
    },
}))

export const Permissions = () => {
    const intl = useIntl()
    const { id } = useParams()
    const { getSelectedAgreement } = useUser()
    const { selectedClient } = useClient()
    const partnerId = getSelectedAgreement()?.account.id
    const [groups, setGroups] = useState<PermissionGroup[]>([])
    const [toAdd, setToAdd] = useState<string[]>([])
    const [toDelete, setToDelete] = useState<string[]>([])
    const { enqueueAlert } = useSnackbar()
    useEffect(() => {
        ;(async () => {
            if (selectedClient && partnerId) {
                setGroups(await selectedClient.permissionGroups(partnerId).getAll())
            }
        })()
    }, [selectedClient, partnerId])
    return id ? (
        <Page title={intl.formatMessage({ id: "user_permissions" })} backButton>
            <DefaultWrapperPaper sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignSelf: "center",

                        flexDirection: "column",
                    }}>
                    <Typography>
                        {intl.formatMessage({ id: "permission_page_header_text" })}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignSelf: "center",
                        paddingBottom: "20px",
                        flexDirection: "column",
                    }}>
                    <Typography>{intl.formatMessage({ id: "permissions_explanation" })}</Typography>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        paddingBottom: "40px",
                    }}>
                    {groups.map((group) => {
                        const i = group.user_agreement_ids.includes(id)
                        return (
                            <StatusButton
                                active={i ? 1 : 0}
                                key={group.id}
                                onClick={async () => {
                                    if (i) {
                                        group.user_agreement_ids = group.user_agreement_ids.filter(
                                            (x) => x !== id,
                                        )
                                        if (toAdd.includes(group.id)) {
                                            setToAdd(toAdd.filter((x) => x !== group.id))
                                        } else {
                                            setToDelete([...toDelete, group.id])
                                        }
                                    } else {
                                        group.user_agreement_ids.push(id)
                                        if (toDelete.includes(group.id)) {
                                            setToDelete(toDelete.filter((x) => x !== group.id))
                                        } else {
                                            setToAdd([...toAdd, group.id])
                                        }
                                    }
                                    setGroups([...groups])
                                }}>
                                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                    <Typography variant="body1"> {i ? "✓" : " "}</Typography>
                                    <Typography> {group.name}</Typography>
                                </Box>
                            </StatusButton>
                        )
                    })}
                </Box>
                <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
                    <CustomButton
                        onClick={async () => {
                            if (selectedClient && partnerId) {
                                const groupClient = selectedClient.permissionGroups(partnerId)

                                await Promise.all(
                                    [
                                        toAdd.map((x) => {
                                            try {
                                                groupClient.addAgreement({
                                                    groupId: x,
                                                    agreementId: id,
                                                })
                                            } catch (error) {
                                                const mappedError = errorConverter(error)
                                                if (!(mappedError instanceof ClientError)) {
                                                    throw error
                                                }
                                                return displayErrorMessage(
                                                    mappedError,
                                                    enqueueAlert,
                                                )
                                            }
                                        }),
                                        toDelete.map((x) => {
                                            try {
                                                return groupClient.removeAgreement({
                                                    groupId: x,
                                                    agreementId: id,
                                                })
                                            } catch (error) {
                                                const mappedError = errorConverter(error)
                                                if (!(mappedError instanceof ClientError)) {
                                                    throw error
                                                }
                                                return displayErrorMessage(
                                                    mappedError,
                                                    enqueueAlert,
                                                )
                                            }
                                        }),
                                    ].flat(),
                                )
                            }
                            setToAdd([])
                            setToDelete([])
                            enqueueAlert(
                                intl.formatMessage({ id: "permission_change_message" }),
                                "success",
                            )
                        }}
                        text={intl.formatMessage({ id: "common_save" })}
                    />
                </Box>
            </DefaultWrapperPaper>
        </Page>
    ) : null
}
