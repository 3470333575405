import { IconButton, Menu } from "@mui/material"
import { ReactNode, useState } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"

interface ThreeDotMenuProps {
    menuItems: ReactNode[]
}

const ThreeDotMenu = (props: ThreeDotMenuProps) => {
    const { menuItems } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton aria-label="3 dot menu" onClick={handleClick}>
                <MoreVertIcon sx={{ cursor: "pointer" }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                {menuItems.map((item) => item)}
            </Menu>
        </>
    )
}

export default ThreeDotMenu
