import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { StyledMenuItem } from "src/components/styles"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { useClient } from "src/hooks/useClient.hook"
import { QueryingOptions } from "@repo/rezip-client"
import { MotherShipProduct } from "@repo/rezip-client/mothership_client"
import Edit from "@mui/icons-material/Edit"
import { formatCurrency } from "src/utils/formatCurrency"
import { TrashCan } from "src/components/TrashCan"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"

import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"

export const Products = () => {
    const { mothershipClient } = useClient()
    const [initialLoad, setInitialLoad] = useState(true)
    const intl = useIntl()
    const navigate = useNavigate()
    const [removeId, setRemoveId] = useState<string>("")
    const { enqueueAlert } = useSnackbar()

    const fetchProducts = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            filters = { ...filters, filters: { deleted_at: "" } }
            const options = {
                filters: filters,
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            const resp = await mothershipClient.getProducts(options)
            return resp?.filter((item) => !item.deleted_at)
        },

        [mothershipClient],
    )

    const deleteProduct = useCallback(async () => {
        if (!removeId) {
            return
        }
        const resp = await mothershipClient.deleteProduct(removeId)
        if (resp) {
            setRemoveId("")
            setInitialLoad(true)
            return enqueueAlert(intl.formatMessage({ id: "product_deleted" }), "success")
        }
    }, [enqueueAlert, intl, mothershipClient, removeId])

    const itemToRow = useCallback(
        (item: MotherShipProduct, index: number) => {
            return (
                <StyledBodyRow key={`product-${item.id}-${index}`}>
                    <StyledTableCell>{item.name}</StyledTableCell>
                    <StyledTableCell>{item.sku}</StyledTableCell>
                    <StyledTableCell>
                        {item.dimension &&
                        item.dimension.height &&
                        item.dimension.width &&
                        item.dimension.depth
                            ? `${item.dimension.height} x ${item.dimension.width} x ${item.dimension.depth}`
                            : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell>{item.weight}</StyledTableCell>
                    <StyledTableCell>{item.volume}</StyledTableCell>
                    <StyledTableCell>{item.bundle_size}</StyledTableCell>
                    <StyledTableCell>
                        {formatCurrency({
                            currencyCode: item.currency,
                            value: item.price,
                            intl: intl,
                        })}
                    </StyledTableCell>
                    <StyledTableCell>
                        <ThreeDotMenu
                            menuItems={[
                                <StyledMenuItem
                                    key={`product-${index}-navigate}`}
                                    onClick={() => navigate(`${item.id}`)}>
                                    <Edit />
                                    {intl.formatMessage({ id: "common_edit" })}
                                </StyledMenuItem>,
                                <StyledMenuItem
                                    key={`product-${index}-delete}`}
                                    onClick={() => setRemoveId(item.id)}>
                                    <TrashCan width={25} height={25} />
                                    {intl.formatMessage({ id: "common_delete" })}
                                </StyledMenuItem>,
                            ]}
                        />
                    </StyledTableCell>
                </StyledBodyRow>
            )
        },
        [intl, navigate],
    )

    return (
        <Page title={""}>
            <DefaultWrapperPaper>
                <AllInOneTable<MotherShipProduct>
                    headers={[
                        { key: "name", label: intl.formatMessage({ id: "common_product" }) },
                        {
                            key: "sku",
                            label: intl.formatMessage({ id: "common_sku" }),
                        },
                        {
                            key: "dimension",
                            label: intl.formatMessage({ id: "common_dimensions" }),
                        },
                        {
                            key: "weight",
                            label: intl.formatMessage({ id: "common_weight" }),
                        },
                        {
                            key: "volume",
                            label: intl.formatMessage({ id: "common_volume" }),
                        },
                        {
                            key: "bundleSize",
                            label: intl.formatMessage({ id: "common_bundle_size" }),
                        },
                        { key: "price", label: intl.formatMessage({ id: "common_unit_price" }) },
                        { key: "actions", label: "" },
                    ]}
                    itemsToRow={itemToRow}
                    defaultSortBy={"id"}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    fetchItems={fetchProducts}
                    addFunction={() => navigate("add")}
                />
            </DefaultWrapperPaper>
            <Dialog open={!!removeId}>
                <DialogTitle>{intl.formatMessage({ id: "are_you_sure" })}</DialogTitle>
                <DialogContent>
                    {intl.formatMessage({ id: "Delete_product_message" })}
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        onClick={() => setRemoveId("")}
                        text={intl.formatMessage({ id: "common_cancel" })}
                        colorTheme={ColorThemeName.noBackground}
                    />
                    <CustomButton
                        onClick={() => deleteProduct()}
                        text={intl.formatMessage({ id: "common_yes" })}
                        colorTheme={ColorThemeName.warning}
                    />
                </DialogActions>
            </Dialog>
        </Page>
    )
}
