import { useEffect, useState } from "react"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { BaseUserAgreement } from "@repo/rezip-client/types"
import { Shop } from "@repo/rezip-client/partner_client"

export const useShopHook = (id: string | undefined) => {
    const [shop, setShop] = useState<Shop>()
    const { getSelectedAgreement } = useUser()
    const { partnerClient } = useClient()
    const accountId = getSelectedAgreement()?.account.id

    useEffect(() => {
        const defaultShop = {
            billing_address: {
                city: null,
                country: null,
                postal_code: null,
                street: null,
                region: null,
            },
            billing_email: "",
            description: "",
            name: "",
            external_id: null,
            phone: "",
            shipping_address: {
                city: null,
                country: null,
                postal_code: null,
                street: null,
                region: null,
            },
            shipping_email: "",
            vat_no: "",
            website: "",
        } as Shop
        if (!shop) {
            if (accountId && id) {
                const fetchShop = async () => {
                    try {
                        const result = await partnerClient.getShop({
                            partnerId: accountId,
                            shopId: id,
                        })
                        if (result) {
                            setShop(result)
                        } else {
                            setShop(defaultShop)
                        }
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    } catch (e) {
                        setShop(defaultShop)
                    }
                }
                fetchShop()
            } else {
                setShop(defaultShop)
            }
        }
    }, [accountId, partnerClient, id, shop])
    return { shop, setShop, accountId }
}

export const useShopsHook = (getToken: () => string, agreement: BaseUserAgreement | undefined) => {
    const [shops, setShops] = useState<Shop[] | null>()
    const { partnerClient } = useClient()
    useEffect(() => {
        if (!shops && agreement) {
            const fecthShops = async () => {
                const data = await partnerClient.getShops(agreement.id, { pageSize: 999 })
                if (data) {
                    setShops(data)
                } else {
                    setShops([])
                }
            }
            fecthShops()
        }
    }, [getToken, agreement, shops, partnerClient])

    return { shops: shops }
}
