import React, { useMemo } from "react"
import { Snackbar, Alert as MuiAlert, AlertColor } from "@mui/material"

export type Alert = {
    id: string
    message: string
    alertType: AlertColor
    count: number
    timestamp?: number
}

type SnackbarProps = {
    alerts: Alert[]
    removeAlert: (id: string) => void
}

const CustomSnackbar: React.FC<SnackbarProps> = ({ alerts, removeAlert }) => {
    const alertItems = useMemo(() => {
        return alerts.map((alert) => (
            <MuiAlert
                sx={{
                    padding: "10px",
                    margin: "8px",
                }}
                onClick={() => removeAlert(alert.id)}
                style={{ cursor: "pointer" }}
                severity={alert.alertType}
                key={alert.id}>
                {alert.count >= 2 ? (
                    <>
                        {alert.count} x {alert.message}
                    </>
                ) : (
                    <>{alert.message}</>
                )}
            </MuiAlert>
        ))
    }, [alerts, removeAlert])

    return (
        <Snackbar
            sx={{ display: "flex" }}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={alertItems.length > 0}>
            <div>{alertItems}</div>
        </Snackbar>
    )
}

export default CustomSnackbar
