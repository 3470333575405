import { IntlShape } from "react-intl"
import cc from "currency-codes"

export const formatCurrency = ({
    currencyCode,
    value,
    intl,
}: {
    currencyCode: string
    value: number
    intl: IntlShape
}): string => {
    // for some reason the intl library doesnt handle dealing with the number of digits in a currency itself
    const amount = value / Math.pow(10, cc.code(currencyCode)?.digits || 0)
    return intl.formatNumber(amount, { style: "currency", currency: currencyCode })
}
