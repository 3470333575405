import { z } from "zod"
import {
    baseApiErrorHandling,
    defaultQueringOptions,
    OptionalQueryingOptions,
    ReZipClient,
} from "."
import {
    ULID,
    AccountType,
    UserAgreementMe,
    UserAgreementMeWithPermissionGroups,
    date,
    Address,
} from "./types"
import { PermissionGroupClient } from "./permission_group_client"
import { DropPointLocation } from "./drop_point_client"

const Me = z.object({
    name: z.string().nullable(),
    email: z.string(),
    created_at: date,
    updated_at: date,
    mfa_enabled: z.boolean(),
    mfa_required: z.boolean(),
    mfa_required_by_account: z.boolean(),
})

const SimpleUser = z.object({
    name: z.string(),
    email: z.string(),
    created_at: date,
    updated_at: date,
})
export type SimpleUser = z.infer<typeof SimpleUser>

export type Me = z.infer<typeof Me>

export const Pong = z.object({
    message: z.enum(["pong"]),
    user: z.union([
        z.enum(["<anonymous>"]),
        z.object({ name: z.string().nullish(), email: z.string() }),
    ]),
})

export type Pong = z.infer<typeof Pong>

export const PackagingLookup = z.object({
    packaging: z.object({
        id: z.string(),
        barcode: z.string(),
        condition: z.number(),
        created_at: date,
        updated_at: date,
    }),
    scan: z.object({
        id: z.string(),
        user_id: z.string().nullish(),
        shop_id: z.string().nullable(),
        circulations: z.number(),
        created_at: date,
    }),
    product: z.object({
        id: z.string(),
        sku: z.string(),
        name: z.string(),
        group: z.object({
            id: z.string(),
            name: z.string(),
            description: z.string(),
        }),
    }),
    shop: z
        .object({
            id: z.string(),
            name: z.string(),
            description: z.string(),
            website: z.string(),
            logo_url: z.string(),
            return_policy_url: z.string().nullish(),
        })
        .nullable(),
})

export type PackagingLookup = z.infer<typeof PackagingLookup>

export const RewardBase = z.object({
    id: z.string(),
    created_at: date,
    updated_at: date,
    deleted_at: date.nullish(),
})

export const RewardVoucher = RewardBase.extend({
    type: z.literal("Voucher"),
    data: z.object({
        code: z.string(),
        value: z.string(),
        value_data: z.object({
            value: z.number(),
            unit: z.string(),
            type: z.enum(["amount", "percentage", "gift"]),
        }),
        conditions: z.string(),
        expires_at: date.nullish(),
        shop: z.object({
            name: z.string(),
            description: z.string().nullish(),
            logo_url: z.string(),
        }),
    }),
})

export type RewardVoucher = z.infer<typeof RewardVoucher>

export const RewardTicket = RewardBase.extend({
    type: z.literal("Ticket"),
    data: z.object({
        drop_point_address: Address,
        shop: z.object({
            id: z.string(),
            name: z.string(),
            description: z.string().nullish(),
            website: z.string().nullish(),
            logo_url: z.string(),
        }),
    }),
})

export type RewardTicket = z.infer<typeof RewardTicket>

export const Reward = z.discriminatedUnion("type", [RewardTicket, RewardVoucher])

export type Reward = z.infer<typeof Reward>

export const TicketOption = z.object({
    id: z.string(),
    type: z.enum(["amount", "percentage", "gift"]),
    value: z.number(),
    unit: z.string(),
    conditions: z.string(),
    shop_id: z.string(),
    created_at: date,
    updated_at: date,
    expires_at: date.nullish(),
    cursor: z.string(),
    shop: z.object({
        id: z.string(),
        name: z.string(),
        description: z.string().nullish(),
        website: z.string().nullish(),
        logo_url: z.string(),
    }),
})

export type TicketOption = z.infer<typeof TicketOption>

export class AnonymousClient {
    rezipClient: ReZipClient
    constructor(rezipClient: ReZipClient) {
        this.rezipClient = rezipClient
    }

    async dropPointLocationLookup(props: {
        latitude: number
        longitude: number
        radius: number
    }): Promise<DropPointLocation[]> {
        const response = await this.rezipClient.get({
            path: "/drop_points/locations",
            queryParams: { ...props, page_size: 25 },
            headers: {
                Authorization: undefined,
            },
        })
        await baseApiErrorHandling(response)
        return z.array(DropPointLocation).parse(await response.json())
    }

    async closestDropPointLocation(props: {
        latitude: number
        longitude: number
    }): Promise<DropPointLocation | null> {
        const response = await this.rezipClient.get({
            path: "/drop_points/locations",
            queryParams: { ...props, page_size: 1, radius: 40_000_000 },
            headers: {
                Authorization: undefined,
            },
        })
        await baseApiErrorHandling(response)
        return z.array(DropPointLocation).parse(await response.json())[0] || null
    }

    async activate(props: {
        email: string
        password: string
        otp: string
    }): Promise<boolean | null> {
        const response = await this.rezipClient.post({
            path: "/me/activate",
            body: JSON.stringify(props),
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return true
    }

    async passwordReset(email: string): Promise<boolean | null> {
        const response = await this.rezipClient.post({
            path: "/me/password/reset",
            body: JSON.stringify({ email }),
        })
        await baseApiErrorHandling(response)
        return true
    }

    async login({ email, password }: { email: string; password: string }): Promise<string> {
        const response = await this.rezipClient.post({
            path: "/me/access-token",
            body: "",
            headers: {
                Authorization: "Basic " + btoa(email + ":" + password),
            },
        })
        await baseApiErrorHandling(response)
        return await response.text()
    }

    async me(): Promise<Me> {
        const response = await this.rezipClient.get({
            path: "/me",
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Me.parse(body)
    }

    async acceptAgreement(agreementId: ULID): Promise<void> {
        const response = await this.rezipClient.post({
            path: `/me/agreements/${agreementId}/accept`,
            body: "",
        })
        await baseApiErrorHandling(response)
    }

    async agreements(): Promise<UserAgreementMe[]> {
        const response = await this.rezipClient.get({
            path: "/me/agreements",
            queryParams: {
                page_size: 200,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(UserAgreementMe).parse(body)
    }

    async fetchPermissionGroups(
        agreements: UserAgreementMe[],
    ): Promise<UserAgreementMeWithPermissionGroups[]> {
        const set = new Set<[AccountType, string, string]>()

        agreements.forEach((x) =>
            x.acl_permission_group_ids.forEach((y) => set.add([x.account.type, x.account.id, y])),
        )

        const groups = await Promise.all(
            Array.from(set).map(([type, accountId, groupId]) => {
                const prefixMap = {
                    "RE-ZIP": "re-zip",
                    Partner: "partners",
                    Shop: "shops",
                    DropPoint: "drop_points",
                } as Record<AccountType, string>
                const client = new PermissionGroupClient(
                    this.rezipClient,
                    `${prefixMap[type]}/${accountId}`,
                )

                return client.get(groupId)
            }),
        )
        const groupObj = Object.fromEntries(groups.filter((x) => !!x).map((x) => [x.id, x]))

        return agreements.map((x) => {
            const groups = x.acl_permission_group_ids.map((x) => groupObj[x]).filter((x) => !!x)
            return { ...x, permission_groups: groups }
        })
    }

    async packagingLookup(barcode: string): Promise<PackagingLookup | null> {
        const response = await this.rezipClient.get({
            path: `/me/packaging/lookup/${barcode}`,
        })
        if (response.status === 404) {
            return null
        }
        await baseApiErrorHandling(response)
        return PackagingLookup.parse(await response.json())
    }

    async getRewards(options?: OptionalQueryingOptions): Promise<Reward[]> {
        const { pageSize, pageFrom, sortBy, sortDir, filters } = defaultQueringOptions(options)
        const response = await this.rezipClient.get({
            path: "/me/rewards",
            queryParams: {
                page_size: pageSize,
                page_from: pageFrom,
                sort_by: sortBy,
                sort_dir: sortDir,
                filter: filters,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(Reward).parse(body)
    }

    async getReward(id: string): Promise<Reward> {
        const response = await this.rezipClient.get({
            path: `/me/rewards/${id}`,
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Reward.parse(body)
    }

    async deleteReward(id: string): Promise<void> {
        const response = await this.rezipClient.delete({
            path: `/me/rewards/${id}`,
        })
        await baseApiErrorHandling(response)
    }

    async scanPackaging({
        barcode,
        hardwareId,
        dropPointLocationId,
    }: {
        barcode: string
        hardwareId: string
        dropPointLocationId: string
    }): Promise<Reward & { generatedToken: string | null }> {
        const response = await this.rezipClient.post({
            path: "/me/packaging/scan",
            body: JSON.stringify({
                barcode,
                hardware_id: hardwareId,
                drop_point_location_id: dropPointLocationId,
            }),
        })
        const token = response.headers.get("Authorization")
        await baseApiErrorHandling(response)
        const body = await response.json()
        return { ...Reward.parse(body), generatedToken: token }
    }

    async ticketOptions(
        ticketId: string,
        cursor?: string,
        pageSize?: number,
    ): Promise<TicketOption[]> {
        const response = await this.rezipClient.get({
            path: `/me/rewards/${ticketId}/options`,
            queryParams: {
                page_size: pageSize || 25,
                page_from: cursor,
            },
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(TicketOption).parse(body)
    }

    async ticketExchange(ticketId: string, optionId: string): Promise<RewardVoucher> {
        const response = await this.rezipClient.post({
            path: `/me/rewards/${ticketId}/exchange`,
            body: JSON.stringify({
                type: "Voucher",
                source: optionId,
            }),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return RewardVoucher.parse(body)
    }

    async createMFA(): Promise<string> {
        const response = await this.rezipClient.post({
            path: "/me/mfa",
            body: "",
        })
        await baseApiErrorHandling(response)
        return await response.text()
    }

    async verifyMFA(code: string): Promise<boolean> {
        const response = await this.rezipClient.patch({
            path: "/me/mfa/verify",
            body: JSON.stringify({ code }),
        })
        return response.status === 200
    }

    async resetMFA(): Promise<void> {
        const response = await this.rezipClient.post({
            path: "/me/mfa/reset",
            body: "",
        })
        await baseApiErrorHandling(response)
    }

    async deleteMFA(props: {
        email: string
        password: string
        code: string
        disable: boolean
    }): Promise<void> {
        const response = await this.rezipClient.patch({
            path: "/me/mfa/delete",
            body: JSON.stringify(props),
        })
        await baseApiErrorHandling(response)
    }

    async deleteMe(): Promise<boolean> {
        const response = await this.rezipClient.delete({ path: "/me" })
        await baseApiErrorHandling(response)
        return true
    }

    async patchName(name: string): Promise<boolean> {
        const response = await this.rezipClient.patch({
            path: "/me",
            body: JSON.stringify({ name: name }),
        })
        await baseApiErrorHandling(response)
        return true
    }

    async putEmail(email: string): Promise<SimpleUser | null> {
        const response = await this.rezipClient.put({
            path: "/me/email",
            body: JSON.stringify({ email: email }),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return SimpleUser.parse(body)
    }

    async emailVerify({ email, otp }: { email: string; otp: string }): Promise<SimpleUser | null> {
        const response = await this.rezipClient.post({
            path: "/me/email/verify",
            body: JSON.stringify({ email: email, otp: otp }),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return SimpleUser.parse(body)
    }

    async putPassword({
        old_password,
        new_password,
    }: {
        old_password: string
        new_password: string
    }): Promise<boolean> {
        const response = await this.rezipClient.put({
            path: "/me/password",
            body: JSON.stringify({ old_password: old_password, new_password: new_password }),
        })
        await baseApiErrorHandling(response)
        return true
    }

    async patchMFA(props: { mfa_required: boolean }): Promise<void> {
        const response = await this.rezipClient.patch({
            path: "/me/mfa",
            body: JSON.stringify(props),
        })
        await baseApiErrorHandling(response)
    }

    async ping(): Promise<Pong> {
        const response = await this.rezipClient.get({
            path: "/ping",
        })
        if (response.status === 401) {
            return { message: "pong", user: "<anonymous>" }
        }
        await baseApiErrorHandling(response)
        return Pong.parse(await response.json())
    }
}
