import { IconButton, Menu, Switch, Typography } from "@mui/material"
import { Grid } from "@mui/system"
import { useState } from "react"
import { useIntl } from "react-intl"
import SettingsIcon from "@mui/icons-material/Settings"
import { StyledMenuItem } from "src/components/styles"
interface SettingsModal {
    setOpen: (bool: boolean) => void
    autoAdd: boolean
    toggleAutoAdd: (bool: boolean) => void
}

export const CustomSettings = ({ setOpen, autoAdd, toggleAutoAdd }: SettingsModal) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const intl = useIntl()
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Grid>
            <IconButton
                onClick={handleClick}
                sx={{ display: "flex", justifyContent: "center", padding: 0 }}>
                <SettingsIcon />
            </IconButton>
            <Menu onClose={handleClose} anchorEl={anchorEl} open={open} sx={{ marginTop: "8px" }}>
                <StyledMenuItem
                    sx={{ borderBottom: "1px solid", borderBottomColor: "inputField.border" }}
                    key={"change-station"}
                    onClick={() => setOpen(true)}>
                    <Typography sx={{ fontSize: "14px" }}>
                        {intl.formatMessage({ id: "receive_change_station" })}
                    </Typography>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={() => toggleAutoAdd(!autoAdd)}
                    sx={{ display: "flex", flexDirection: "row" }}
                    key={"auto-add-toggle"}>
                    <Typography
                        sx={{
                            wordBreak: "break-word",
                            maxWidth: "130px",
                            fontSize: "14px",
                            whiteSpace: "normal",
                        }}>
                        {intl.formatMessage({ id: "receive_auto_add_switch" })}
                    </Typography>

                    <Switch checked={autoAdd} onClick={() => toggleAutoAdd(!autoAdd)}></Switch>
                </StyledMenuItem>
            </Menu>
        </Grid>
    )
}
