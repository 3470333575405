import { Collapse, Typography, Divider } from "@mui/material"
import Grid from "@mui/material/Grid"
import { FunctionComponent, ReactElement, useState } from "react"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

interface CollapseInformationProps {
    header: string | ReactElement
    children: ReactElement | ReactElement[]
}

interface ArrowBoxProps {
    open: boolean
    setBool: (bool: boolean) => void
}

export const ArrowBox = ({ open, setBool }: ArrowBoxProps) => {
    return <div onClick={() => setBool(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</div>
}

// this element is only for the top bar with information about the page.
// we could consider adding a style attribute that can override the sx or add to the existing sx

const CollapseInformation: FunctionComponent<CollapseInformationProps> = (
    props: CollapseInformationProps,
) => {
    const { header, children } = props
    const [open, setOpen] = useState(false)

    return (
        <Grid sx={{ maxWidth: "800px", marginBottom: "12px" }}>
            <Grid container spacing={3} flexWrap="nowrap">
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    {header}
                </Typography>
                <ArrowBox open={open} setBool={setOpen} />
            </Grid>
            <Collapse in={open}>
                <Divider />
                {children}
            </Collapse>
        </Grid>
    )
}

export default CollapseInformation
