import {
    PerSku,
    SimpleProducts,
    SummedTotals,
} from "src/routes/administration/reports/reports.types"
import { refereneceScenario } from "./referenceScenario"
import { useCallback } from "react"

type referenceType = "box" | "board" | "vogue"

const getPrimaryMaterial = (code: string): referenceType => {
    let primaryMaterial: referenceType = "box" // Default value

    switch (code) {
        // box cases
        case "61RZ":
        case "66FA":
        case "66LP":
        case "66RZ":
        case "66UA":
        case "71RZ":
        case "76RZ":
        case "81RZ":
        case "86RZ":
        case "69RZ":
        case "80RZ":
        case "75RZ":
        case "70RZ":
        case "60RZ":
        case "95RZ":
        case "86LP":
        case "60AP":
        case "70AP":
        case "76LP":
            primaryMaterial = "box"
            break

        // vouge cases
        case "10RZ":
        case "20RZ":
        case "30RZ":
        case "40RZ":
        case "10AP":
        case "20AP":
        case "30AP":
        case "40AP":
        case "01AP":
        case "02AP":
        case "03AP":
        case "21RZ":
            primaryMaterial = "vogue"
            break

        // board cases
        case "15RZ":
        case "25RZ":
        case "35RZ":
            primaryMaterial = "board"
            break

        default:
            primaryMaterial = "box" // Fallback for any unmapped codes
            break
    }

    return primaryMaterial as referenceType
}

export const useReferenceCalculator = () => {
    const ReferenceCalculator = useCallback(
        (stats: SummedTotals | undefined, products: SimpleProducts[] | undefined) => {
            if (!stats || !products) {
                return
            }

            let totalWater = 0
            let totalco2 = 0
            let totalwaste = 0

            products.forEach((item) => {
                if (!stats.perSku[item.sku]) {
                    return
                }

                const itemTotal = stats.perSku[item.sku].shipped

                const primaryMaterial = (item.primary_material ??
                    getPrimaryMaterial(item.sku)) as referenceType

                const weight =
                    (item.surface_area ?? 0) * refereneceScenario[primaryMaterial].refWeight

                const waste = weight * itemTotal

                totalWater += itemTotal * weight * refereneceScenario[primaryMaterial].water
                totalco2 += itemTotal * weight * refereneceScenario[primaryMaterial].co2e
                totalwaste += waste
            })

            return { water: totalWater, co2: totalco2, waste: totalwaste }
        },
        [],
    )

    return { ReferenceCalculator }
}

export const calcuclateRefPerSku = (stats: PerSku, products: SimpleProducts[]) => {
    let totalWater = 0
    let totalco2 = 0
    let totalwaste = 0

    products.forEach((item) => {
        if (!stats[item.sku]) {
            return
        }

        const itemTotal = stats[item.sku].shipped

        const primaryMaterial = (item.primary_material ??
            getPrimaryMaterial(item.sku)) as referenceType

        const weight = (item.surface_area ?? 0) * refereneceScenario[primaryMaterial].refWeight

        const waste = weight * itemTotal

        totalWater += itemTotal * weight * refereneceScenario[primaryMaterial].water
        totalco2 += itemTotal * weight * refereneceScenario[primaryMaterial].co2e
        totalwaste += waste
    })

    return { calculatedWater: totalWater, calculatedCo2: totalco2, calculatedWaste: totalwaste }
}
