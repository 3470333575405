import Page from "src/components/layout/Page"
import Grid from "@mui/material/Grid"
import { FormattedMessage, useIntl } from "react-intl"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { StorageUnitTemplate } from "@repo/rezip-client/partner_client"
import { useClient } from "src/hooks/useClient.hook"
import { useUser } from "src/contexts/UserConsumer"
import InputField from "src/components/InputField"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useNavigate, useParams } from "react-router-dom"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { Product } from "@repo/rezip-client/types"
import { roundingHelper } from "src/utils/roundingHelper"
import { Typography } from "@mui/material"
import StyledForm from "src/components/layout/StyledForm"
import { CustomHelp } from "src/components/generalComponents/CustomHelp"
import { useDebounce } from "src/utils/debounce"
import { ClientError } from "@repo/rezip-client/client_error"
import { errorConverter } from "src/utils/errorConverter"

const ItemToRow = ({
    item,
    index,
    productLimits,
    handleLimitChange,
}: {
    item: Product
    index: number
    productLimits: Record<string, number>
    handleLimitChange: (e: number, sku: string) => void
}) => {
    const [itemQuantity, setItemQuantity] = useState<string>(
        productLimits[item.sku] ? String(productLimits[item.sku]) : "",
    )
    const { enqueueAlert } = useSnackbar()
    const intl = useIntl()
    const { debounce } = useDebounce()

    const updateQuantity = useCallback(
        (value: number) => {
            const nearestTen = roundingHelper({
                intl,
                number: value,
                enqueueAlert,
            })
            if (nearestTen === 0) {
                setItemQuantity("")
                handleLimitChange(0, item.sku)
            } else {
                setItemQuantity(String(nearestTen))
                handleLimitChange(nearestTen, item.sku)
            }
        },
        [enqueueAlert, handleLimitChange, intl, item.sku],
    )

    const debouncedUpdateQuantity = useMemo(
        () => debounce(updateQuantity, 1000),
        [debounce, updateQuantity],
    )

    const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
        const rawValue = Number(e.target.value) || 0
        setItemQuantity(String(rawValue))
        debouncedUpdateQuantity(rawValue)
    }

    return (
        <StyledBodyRow key={`row-${index}`}>
            <StyledTableCell>{item.sku}</StyledTableCell>
            <StyledTableCell>{item.name}</StyledTableCell>
            <StyledTableCell>
                <InputField
                    minimum={0}
                    id={item.sku}
                    name={item.sku}
                    type={"number"}
                    stepIncrement={10}
                    value={itemQuantity}
                    onChange={handleQuantityChange}
                />
            </StyledTableCell>
        </StyledBodyRow>
    )
}

export const TemplateView = () => {
    const intl = useIntl()
    const { partnerClient } = useClient()
    const { getSelectedAgreement } = useUser()
    const [products, setProducts] = useState<Product[] | null>(null)
    const partnerId = getSelectedAgreement()?.account.id
    const [name, setName] = useState<string>("")
    const [productLimits, setProductLimits] = useState<Record<string, number>>({})
    const [editableItem, setEditableItem] = useState<StorageUnitTemplate | null>()
    const { setBlock, BlockerAlert } = useCustomBlocker()
    const [initialLoad, setInitialLoad] = useState(true)
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        if (!products && partnerId) {
            const fetchProducts = async () => {
                const resp = await partnerClient.getProducts(partnerId, { pageSize: 9999 })

                setProducts(resp)
            }
            fetchProducts()
        }
    })

    useEffect(() => {
        if (id && partnerId && !editableItem) {
            const fetch = async () => {
                const resp = await partnerClient.getStorageUnitTemplate({
                    partnerId: partnerId,
                    templateId: id,
                })
                if (resp) {
                    setEditableItem(resp)
                    resp.config?.forEach((item) => {
                        setProductLimits((prevLimits) => ({
                            ...prevLimits,
                            [item.sku]: item.size,
                        }))
                    })
                    setName(resp.type)
                }
            }
            fetch()
        }
    })
    const productGroups = useMemo(() => {
        if (!products) {
            return {}
        }

        const groups: Record<string, Product[]> = {
            "Self-Standing Box": [],
            "Paper Bag": [],
            Bag: [],
            Box: [],
            "Premium Bag": [],
            Accessory: [],
        }

        products.forEach((product) => {
            if (!groups[product.group.name]) {
                groups[product.group.name] = []
            }
            groups[product.group.name].push(product)
        })

        return groups
    }, [products])

    const groupKeys = useMemo(() => {
        return Object.keys(productGroups)
    }, [productGroups])

    const handleLimitChange = useCallback(
        (e: number, sku: string) => {
            setProductLimits((prevLimits) => ({
                ...prevLimits,
                [sku]: isNaN(e) ? 0 : e,
            }))
            setInitialLoad(true)
            setBlock(true)
        },
        [setBlock],
    )

    const handleSave = useCallback(async () => {
        if (!partnerId) {
            return
        }
        const config = []
        for (const [sku, quantity] of Object.entries(productLimits)) {
            config.push({ sku: sku, size: quantity })
        }
        try {
            const data = await partnerClient.postStorageUnitTemplate({
                partnerId: partnerId,
                args: { type: name, config: config },
            })
            if (data) {
                setBlock(false)
                enqueueAlert(intl.formatMessage({ id: "toast_message_type_created" }), "success")
                navigate(-1)
            } else {
                enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
            }
        } catch (error) {
            const mappedError = errorConverter(error)
            if (!(mappedError instanceof ClientError)) {
                throw error
            }
            return enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
        }
    }, [enqueueAlert, intl, name, navigate, partnerClient, partnerId, productLimits, setBlock])

    const handleUpdate = useCallback(async () => {
        if (!partnerId || !id) {
            return
        }
        const config = []
        for (const [sku, quantity] of Object.entries(productLimits)) {
            config.push({ sku: sku, size: quantity })
        }

        const data = await partnerClient.patchStorageUnitTemplate({
            partnerId: partnerId,
            templateId: id,
            args: { type: name, config: config },
        })
        if (data) {
            setBlock(false)
            enqueueAlert(intl.formatMessage({ id: "toast_message_type_updated" }), "success")
            navigate(-1)
        } else {
            enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
        }
    }, [enqueueAlert, id, intl, name, navigate, partnerClient, partnerId, productLimits, setBlock])

    const headers = [
        {
            key: "sku",
            label: intl.formatMessage({ id: "common_sku" }),
            excludeFromSearch: true,
        },
        {
            key: "name",
            label: intl.formatMessage({ id: "common_product" }),
            excludeFromSearch: true,
        },
        {
            key: "limit",
            label: intl.formatMessage({ id: "common_limit" }),
            excludeFromSearch: true,
        },
    ]

    return (
        <Page backButton>
            <Grid container justifyContent="center">
                <DefaultWrapperPaper>
                    <Grid container direction="column" spacing={2}>
                        <Grid container direction="row" justifyContent={"space-between"}>
                            <Typography variant="h4">
                                <FormattedMessage
                                    id={
                                        id
                                            ? intl.formatMessage({ id: "edit_su_type" })
                                            : intl.formatMessage({ id: "add_new_type" })
                                    }
                                />
                            </Typography>
                            {id ? (
                                <CustomHelp
                                    title={intl.formatMessage({
                                        id: "templates_disclaimer_on_edit",
                                    })}
                                />
                            ) : null}
                        </Grid>

                        <StyledForm>
                            <InputField
                                id={""}
                                name={"Name"}
                                type={"text"}
                                label={intl.formatMessage({ id: "templates_type_name" })}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                    setBlock(true)
                                }}
                            />
                        </StyledForm>
                        {groupKeys.map((item) => {
                            if (productGroups[item].length === 0) {
                                return
                            }
                            return (
                                <Grid container spacing={1} direction={"column"}>
                                    <Typography variant="h3">
                                        {item !== "Accessory"
                                            ? intl.formatMessage(
                                                  {
                                                      id: `order_plural_item`,
                                                  },
                                                  {
                                                      item: item,
                                                      suffix: item.endsWith("x")
                                                          ? "es"
                                                          : item.endsWith("s")
                                                            ? ""
                                                            : "s",
                                                  },
                                              )
                                            : intl.formatMessage({ id: "order_accessories" })}
                                    </Typography>
                                    <AllInOneTable<Product>
                                        headers={headers}
                                        items={productGroups[item] ?? []}
                                        itemsToRow={(item: Product, index: number) => {
                                            return (
                                                <ItemToRow
                                                    item={item}
                                                    index={index}
                                                    productLimits={productLimits}
                                                    handleLimitChange={handleLimitChange}
                                                />
                                            )
                                        }}
                                        defaultSortBy={"sku"}
                                        hideSearchBar
                                        setInitialLoad={setInitialLoad}
                                        initialLoad={initialLoad}
                                    />
                                </Grid>
                            )
                        })}

                        <Grid container justifyContent="flex-end">
                            <CustomButton
                                onClick={id ? handleUpdate : handleSave}
                                text={
                                    id
                                        ? intl.formatMessage({ id: "common_save" })
                                        : intl.formatMessage({ id: "create_type" })
                                }
                            />
                        </Grid>
                    </Grid>
                </DefaultWrapperPaper>
            </Grid>
            <BlockerAlert />
        </Page>
    )
}
