import { IntlShape } from "react-intl"

export const RecieveHeaders = (autoComplete: boolean, intl: IntlShape) => {
    return autoComplete
        ? [
              {
                  key: "sku",
                  label: intl.formatMessage({
                      id: "common_sku",
                  }),
              },
              {
                  key: "name",
                  label: intl.formatMessage({
                      id: "common_product",
                  }),
              },
              {
                  key: "bundle",
                  label: intl.formatMessage({
                      id: "common_bundle",
                  }),
              },

              {
                  key: "su_type",
                  label: intl.formatMessage({
                      id: "common_su_type",
                  }),
              },
              {
                  key: "quantity",
                  label: intl.formatMessage({
                      id: "common_quantity",
                  }),
              },
              {
                  key: "action",
                  label: "",
              },
          ]
        : [
              {
                  key: "sku",
                  label: intl.formatMessage({
                      id: "common_sku",
                  }),
              },
              {
                  key: "name",
                  label: intl.formatMessage({
                      id: "common_name",
                  }),
              },
              {
                  key: "bundle",
                  label: intl.formatMessage({
                      id: "common_bundle",
                  }),
              },

              {
                  key: "action",
                  label: "",
              },
          ]
}
