import { Typography, Grid } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import InputField from "src/components/InputField"
import Page from "src/components/layout/Page"
import SwitchField from "src/components/SwitchField"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { StationType } from "@repo/rezip-client/partner_client"
import QRCode from "qrcode" // Import QRCode library
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { CustomHelp } from "src/components/generalComponents/CustomHelp"
import StyledForm from "src/components/layout/StyledForm"

export const ViewStation = () => {
    const intl = useIntl()
    const { id } = useParams()
    const { getSelectedLocationId, getSelectedAgreement } = useUser()
    const [station, setStation] = useState<StationType | null>(null)
    const { partnerClient } = useClient()
    const locationId = getSelectedLocationId()
    const partnerId = getSelectedAgreement()?.account.id
    const [name, setName] = useState<string>("")
    const [autoAdd, setAutoAdd] = useState<boolean>(true)
    const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null)
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()
    const { BlockerAlert, setBlock } = useCustomBlocker()

    useEffect(() => {
        if (id && !station) {
            if (!locationId || !partnerId) {
                return
            }
            const fetch = async () => {
                const resp = await partnerClient.getStation(partnerId, locationId, id)
                if (resp) {
                    setName(resp.name)
                    setAutoAdd(resp.storage_unit_auto_add)
                    setStation(resp)

                    // Generate QR Code for the station ID
                    const qrCode = await QRCode.toDataURL(resp.id)
                    setQrCodeUrl(qrCode)
                }
            }
            fetch()
        }
    }, [id, locationId, partnerClient, partnerId, station])

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value)
            setBlock(true)
        },
        [setBlock],
    )

    const handleUpdate = useCallback(async () => {
        if (!partnerId || !id || !locationId) {
            return
        }

        const response = await partnerClient.patchStation(partnerId, locationId, id, {
            name: name,
            storage_unit_auto_add: autoAdd,
        })
        if (response) {
            setBlock(false)
            enqueueAlert(intl.formatMessage({ id: "toast_message_station_updated" }), "success")
            navigate(-1)
        } else {
            enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
        }
    }, [
        autoAdd,
        enqueueAlert,
        id,
        intl,
        locationId,
        name,
        navigate,
        partnerClient,
        partnerId,
        setBlock,
    ])

    const handleSave = useCallback(async () => {
        if (!partnerId || !locationId) {
            return
        }

        const response = await partnerClient.postStation(partnerId, locationId, {
            name: name,
            storage_unit_auto_add: autoAdd,
        })

        if (response) {
            setBlock(false)
            enqueueAlert(intl.formatMessage({ id: "toast_message_station_created" }), "success")
            navigate(-1)
        } else {
            enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
        }
    }, [
        autoAdd,
        enqueueAlert,
        intl,
        locationId,
        name,
        navigate,
        partnerClient,
        partnerId,
        setBlock,
    ])

    const downloadQrCode = useCallback(() => {
        if (qrCodeUrl) {
            const link = document.createElement("a")
            link.href = qrCodeUrl
            link.download = `station-${id}.png`
            link.click()
        }
    }, [qrCodeUrl, id])

    return (
        <Page backButton>
            <Grid container direction="column" spacing={2}>
                <DefaultWrapperPaper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        width: "100%",
                        maxWidth: "500px",
                    }}>
                    <Grid container direction="column" spacing={2}>
                        <Typography variant="h4">
                            <FormattedMessage
                                id={
                                    id
                                        ? intl.formatMessage(
                                              { id: "common_edit_item" },
                                              { item: station?.name },
                                          )
                                        : intl.formatMessage({ id: "add_station" })
                                }
                            />
                        </Typography>
                        <StyledForm
                            onSubmit={(e) => {
                                e.preventDefault()
                                if (id) {
                                    handleUpdate()
                                    return
                                }
                                handleSave()
                            }}>
                            {id ? (
                                <InputField
                                    id={"id"}
                                    label={intl.formatMessage({ id: "id" })}
                                    name={"id"}
                                    type={"text"}
                                    value={id}
                                    onChange={onChange}
                                    disabled
                                />
                            ) : null}
                            <InputField
                                id={"name"}
                                label={intl.formatMessage({ id: "stations_work_station_name" })}
                                name={"name"}
                                type={"text"}
                                value={name}
                                onChange={onChange}
                            />
                            <Grid container alignItems="center">
                                <Grid container spacing={1}>
                                    <Typography>
                                        {intl.formatMessage({ id: "common_auto_add" })}
                                    </Typography>
                                    <CustomHelp
                                        title={intl.formatMessage({
                                            id: "stations_add_auto_add_tooltip",
                                        })}
                                    />
                                </Grid>
                                <SwitchField
                                    id={"autoAdd"}
                                    value={autoAdd}
                                    checked={autoAdd}
                                    onClick={() => {
                                        setAutoAdd(!autoAdd)
                                        setBlock(true)
                                    }}
                                />
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <CustomButton
                                    onClick={id ? handleUpdate : handleSave}
                                    text={
                                        id
                                            ? intl.formatMessage({ id: "save_changes" })
                                            : intl.formatMessage({ id: "create_station" })
                                    }
                                />
                            </Grid>
                        </StyledForm>
                    </Grid>
                </DefaultWrapperPaper>

                {/* QR Code Display Section */}
                {qrCodeUrl && (
                    <DefaultWrapperPaper
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                            width: "100%",
                            maxWidth: "500px",
                        }}>
                        <Grid
                            container
                            direction="column"
                            alignSelf="center"
                            justifyContent="center">
                            <Grid container spacing={1}>
                                <Typography variant="h4">
                                    {intl.formatMessage({ id: "station_qr_code" })}
                                </Typography>
                                <CustomHelp
                                    title={intl.formatMessage({ id: "stations_qr_code_tooltip" })}
                                />
                            </Grid>
                            <Grid container>
                                <img
                                    src={qrCodeUrl}
                                    alt="QR Code"
                                    style={{ width: "200px", height: "200px" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" marginTop="8px">
                            <CustomButton
                                onClick={downloadQrCode}
                                text={intl.formatMessage({ id: "common_download_qr" })}
                            />
                        </Grid>
                    </DefaultWrapperPaper>
                )}
            </Grid>
            <BlockerAlert />
        </Page>
    )
}
