import { ChangeEvent, KeyboardEvent, FocusEvent } from "react"

import { FormattedMessage } from "react-intl"
import { Grid, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material"
import { FieldLabel } from "./styles"
import { CustomHelp } from "./generalComponents/CustomHelp"

type InputProps = {
    id: string
    name: string
    type: string
    placeholder?: string
    label?: string
    helpText?: string
    value: unknown
    required?: boolean
    disabled?: boolean
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void
    error?: boolean
    errorText?: string
    expandable?: boolean
    input?: boolean
    unitText?: string
    minimum?: number
    stepIncrement?: number
}

const InputField = ({
    id,
    name,
    type,
    placeholder,
    label,
    helpText,
    value,
    required,
    disabled,
    onChange,
    onKeyUp,
    onBlur,
    error,
    errorText,
    expandable,
    input,
    unitText,
    minimum,
    stepIncrement,
}: InputProps) => {
    const requirement = required ? (
        <Typography
            variant="subtitle2"
            component={"span"}
            sx={{
                color: "warning.main",
                paddingLeft: "3px",
            }}>
            *
        </Typography>
    ) : null

    const errorMessage = error ? (
        <Typography
            variant="subtitle2"
            component="span"
            sx={{
                color: "warning.main",
                marginTop: "8px",
            }}>
            <FormattedMessage id={errorText} />
        </Typography>
    ) : null

    return (
        <Grid container direction="column" sx={{ width: "100%" }}>
            <Grid container direction="row">
                <FieldLabel>{label}</FieldLabel>
                {helpText ? <CustomHelp title={helpText} /> : null}
                {requirement}
            </Grid>
            {input ? (
                <OutlinedInput
                    id={id}
                    name={name}
                    aria-label={label}
                    placeholder={placeholder}
                    value={value}
                    required={required}
                    type={type}
                    disabled={disabled}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    endAdornment={<InputAdornment position="end">{unitText}</InputAdornment>}
                />
            ) : type === "time" ? (
                <OutlinedInput
                    id={id}
                    name={name}
                    aria-label={label}
                    placeholder={placeholder}
                    value={value}
                    required={required}
                    type={"time"}
                    disabled={disabled}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    endAdornment={<InputAdornment position="end">{unitText}</InputAdornment>}
                />
            ) : (
                <TextField
                    id={id}
                    name={name}
                    aria-label={label}
                    placeholder={placeholder ?? ""}
                    value={value}
                    required={required}
                    type={type}
                    disabled={disabled ? true : false}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    onBlur={onBlur}
                    error={error}
                    helperText={errorMessage}
                    multiline={expandable}
                    fullWidth={expandable}
                    autoFocus={false}
                    sx={{
                        width: "inherit",
                        ".MuiInputBase-input": {
                            fontSize: "14px",
                            display: "flex",
                        },
                        ".MuiFormHelperText-root": {
                            margin: 0,
                            padding: 0,
                        },
                    }}
                    slotProps={{
                        input: {
                            inputProps: { min: minimum, step: stepIncrement ?? 1 },
                            endAdornment: unitText ? (
                                <InputAdornment position="end">{unitText}</InputAdornment>
                            ) : null,
                        },
                    }}
                />
            )}
        </Grid>
    )
}

export default InputField
