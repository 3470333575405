import { Box, Divider, Typography } from "@mui/material"
import { useIntl } from "react-intl"
import { OrderConfig } from "src/components/shopComponents/OrderConfiguration"
import { formatCurrency } from "src/utils/formatCurrency"

interface PriceBreakDownProps {
    costTotal: {
        cost: number
        shippingFee: number
        vat: number
    }
    orderSettings: OrderConfig
}

export const PriceBreakDown = (props: PriceBreakDownProps) => {
    const { costTotal, orderSettings } = props
    const intl = useIntl()

    return (
        <Box sx={{ marginBottom: "12px", width: "100%" }}>
            <Typography>{intl.formatMessage({ id: "breakdown_label" })}</Typography>
            <Box
                sx={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "space-between",
                }}>
                <Typography sx={{ fontSize: "12px" }}>
                    {intl.formatMessage({ id: "subtotal_label" })}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                    {formatCurrency({
                        currencyCode: orderSettings.currency,
                        value: costTotal.cost,
                        intl: intl,
                    })}
                </Typography>
            </Box>
            <Divider></Divider>
            <Box
                sx={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "space-between",
                }}>
                <Typography sx={{ fontSize: "12px" }}>
                    {intl.formatMessage({ id: "shipping_fee_label" })}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                    {formatCurrency({
                        currencyCode: orderSettings.currency,
                        value: costTotal.shippingFee,
                        intl: intl,
                    })}
                </Typography>
            </Box>

            <Divider></Divider>
            <Box
                sx={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "space-between",
                }}>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    {intl.formatMessage({ id: "total_label_excluding" })}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    {formatCurrency({
                        currencyCode: orderSettings.currency,
                        value: costTotal.cost + costTotal.shippingFee,
                        intl: intl,
                    })}
                </Typography>
            </Box>
            <Divider></Divider>

            <Box
                sx={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "space-between",
                }}>
                <Typography sx={{ fontSize: "12px" }}>
                    {intl.formatMessage({ id: "vat_label" }, { vat: orderSettings.vat })}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                    {formatCurrency({
                        currencyCode: orderSettings.currency,
                        value: costTotal.vat,
                        intl: intl,
                    })}
                </Typography>
            </Box>
        </Box>
    )
}
