import { Box, Typography, styled, Grid } from "@mui/material"
import { useIntl } from "react-intl"
import { Navigate, useNavigate } from "react-router-dom"
import { useUser } from "src/contexts/UserConsumer"
import image from "../../assets/images/Re-zip_hello.jpg?srcset"
import { actionGreen } from "src/components/colors"
import { ReactNode, useMemo } from "react"
import { ReactComponent as Icons_green_trashcan } from "../../assets/icons/TrashCan.svg"
import { ReactComponent as waterDroplet } from "../../assets/icons/WaterDroplet.svg"
import { ReactComponent as co2 } from "../../assets/icons/Co2.svg"
import { lightGreen } from "@mui/material/colors"
import { useFrontPageController } from "./frontpage.controller"
import { SourceSetImage } from "@repo/react-utils/sourceSetImage"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import { CustomButton } from "src/components/generalComponents/Buttons"

interface InformationCardProps {
    title: string
    icon: ReactNode
    amount: string
    metric: string
}

const TrashCan = styled(Icons_green_trashcan)({
    width: "48px",
    height: "44px",
    "@media only screen and (min-width: 767px)": {
        scale: "100%",
    },
    "@media only screen and (min-width: 992px)": {
        scale: "100%",
    },
    "@media only screen and (min-width: 1200px)": {
        scale: "100%",
    },
    "@media only screen and (min-width: 1600px)": {
        scale: "120%",
    },
})

const Water = styled(waterDroplet)({
    width: "48px",
    height: "44px",
    "& .cls-2": {
        fill: lightGreen,
    },
    "@media only screen and (min-width: 767px)": {
        scale: "100%",
    },
    "@media only screen and (min-width: 992px)": {
        scale: "100%",
    },
    "@media only screen and (min-width: 1200px)": {
        scale: "100%",
    },
    "@media only screen and (min-width: 1600px)": {
        scale: "120%",
    },
})

const Co2Cloud = styled(co2)({
    width: "48px",
    height: "44px",
    color: actionGreen,
    fill: actionGreen,
    "@media only screen and (min-width: 767px)": {
        scale: "200%",
    },
    "@media only screen and (min-width: 992px)": {
        scale: "200%",
    },
    "@media only screen and (min-width: 1200px)": {
        scale: "200%",
    },
    "@media only screen and (min-width: 1600px)": {
        scale: "250%",
    },
})

const InformationCard = (props: InformationCardProps) => {
    const { title, icon, amount, metric } = props
    const intl = useIntl()

    return (
        <DefaultWrapperPaper
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: "22px",
                borderRadius: "11px",
                alignItems: "center",
                minWidth: "20vw",
                minHeight: "15vh",
            }}>
            <Grid sx={{ marginBottom: "4px" }}>{icon}</Grid>
            <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>{title}</Typography>
            <Typography
                sx={{
                    fontFamily: "Changa",
                    fontSize: "25px",
                    paddingTop: "10px",
                }}>
                {intl.formatNumber(Number(amount))} {metric}
            </Typography>
        </DefaultWrapperPaper>
    )
}

const WithReporting = () => {
    const navigate = useNavigate()
    const { getSelectedAgreement } = useUser()

    const intl = useIntl()

    const name = useMemo(() => {
        return getSelectedAgreement()?.account.name
    }, [getSelectedAgreement])
    const { savingsTotal } = useFrontPageController()

    return (
        <Grid container flexDirection="column" spacing={3}>
            <Grid container flexDirection="row" spacing={2} justifyContent="space-around">
                <Grid container flexDirection="column" spacing={2}>
                    <Grid>
                        <Typography variant="h5">
                            {intl.formatMessage({
                                id: "welcome_message",
                                defaultMessage: `Welcome `,
                            })}
                        </Typography>

                        <Typography variant="h5">{name}!</Typography>
                    </Grid>
                    <Grid sx={{ maxWidth: "550px" }}>
                        <Typography>
                            {intl.formatMessage({
                                id: "welcome_info_text",
                                defaultMessage:
                                    "Manage your packaging efficiently with the RE-ZIP Manager. Order packaging, track orders, view reports, access marketing materials, and much more - all in one place",
                            })}
                        </Typography>
                    </Grid>
                </Grid>
                <SourceSetImage
                    sourceSet={image}
                    style={{
                        borderRadius: "30px",
                        maxWidth: "50vw",
                        minWidth: "10vw",
                        maxHeight: "45vh",
                        minHeight: "15vh",
                    }}
                />
            </Grid>
            <Grid>
                <DefaultWrapperPaper
                    sx={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
                    <Typography
                        variant="body2"
                        sx={{ fontWeight: "800", margin: "2%", alignSelf: "center" }}>
                        {intl.formatMessage({
                            id: "front_page_thank_you",
                        })}
                    </Typography>
                    <Grid container justifyContent="space-around">
                        <InformationCard
                            title={intl.formatMessage({ id: "frontpage_co2" })}
                            icon={<Co2Cloud />}
                            amount={savingsTotal ? savingsTotal.co2 : "0"}
                            metric={intl.formatMessage({
                                id: "metrics_kg",
                                defaultMessage: "kg",
                            })}
                        />
                        <InformationCard
                            title={intl.formatMessage({ id: "frontpage_water" })}
                            icon={<Water />}
                            amount={savingsTotal ? savingsTotal.water : "0"}
                            metric={intl.formatMessage({
                                id: "metrics_l",
                                defaultMessage: "L",
                            })}
                        />
                        <InformationCard
                            title={intl.formatMessage({ id: "frontpage_waste" })}
                            icon={<TrashCan />}
                            amount={savingsTotal ? savingsTotal.waste : "0"}
                            metric={intl.formatMessage({
                                id: "metrics_kg",
                                defaultMessage: "kg",
                            })}
                        />
                    </Grid>

                    <Grid
                        container
                        justifyContent="flex-end"
                        sx={{ marginTop: "28px", marginRight: "6%" }}>
                        <CustomButton
                            styles={{ width: "180px" }}
                            text={intl.formatMessage({
                                id: "see_all_savings",
                            })}
                            onClick={() => navigate("/administration/reports")}
                        />
                    </Grid>
                </DefaultWrapperPaper>
            </Grid>
        </Grid>
    )
}

const WithoutReporting = () => {
    const intl = useIntl()
    const { getSelectedAccountType } = useUser()
    const accountType = getSelectedAccountType()

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "1%",
                flexDirection: "column",
                zIndex: 2,
            }}>
            <Typography variant="h5">
                {intl.formatMessage({
                    id: "welcome_droppoint",
                    defaultMessage: `Welcome `,
                })}
            </Typography>
            {accountType === "DropPoint" ? (
                <DefaultWrapperPaper
                    sx={{
                        display: "flex",
                        width: "650px",
                        marginTop: "2%",
                        flexDirection: "column",
                        gap: "12px",
                    }}>
                    <Typography>
                        {intl.formatMessage({
                            id: "welcome_droppoint_info_1",
                        })}
                    </Typography>
                    <Typography>
                        {intl.formatMessage({
                            id: "welcome_droppoint_info_2",
                        })}
                    </Typography>
                </DefaultWrapperPaper>
            ) : null}
        </Box>
    )
}

const FrontPage = () => {
    const { getSelectedAgreement, getSelectedAccountType } = useUser()

    const useReporting = useMemo(() => {
        const type = getSelectedAccountType()

        if (type === "Partner" || type === "Shop") {
            return true
        } else {
            return false
        }
    }, [getSelectedAccountType])

    const name = useMemo(() => {
        return getSelectedAgreement()?.account.name
    }, [getSelectedAgreement])

    if (!name) {
        return <Navigate to={"/change-account"} />
    }

    if (useReporting) {
        return <WithReporting />
    } else {
        return <WithoutReporting />
    }
}

export default FrontPage
