import { Voucher, VoucherBatch } from "@repo/rezip-client/shop_client"
import { AllInOneTable } from "./table/AllInOneTable"
import { useClient } from "src/hooks/useClient.hook"
import { useUser } from "src/contexts/UserConsumer"
import { Button } from "@mui/material"
import { StyledBodyRow, StyledTableCell } from "./table/TableComponents"
import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { TrashCan } from "./TrashCan"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import Grid from "@mui/material/Grid"
import { QueryingOptions } from "@repo/rezip-client"

export type VoucherCodesViewProps = {
    batch: VoucherBatch
}

export const VoucherCodesView = ({ batch }: VoucherCodesViewProps) => {
    const intl = useIntl()
    const { getSelectedAgreement } = useUser()
    const [initialLoad, setInitialLoad] = useState(true)
    const accountId = getSelectedAgreement()?.account.id
    const { shopClient } = useClient()
    const { enqueueAlert } = useSnackbar()
    const fetchVouchers = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!accountId) {
                return
            }

            const options = {
                filters: { ...filters, batch_id: batch.id },
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            const data = await shopClient.getVouchers(accountId, options)

            return data
        },
        [accountId, batch.id, shopClient],
    )

    return accountId ? (
        <Grid container>
            <AllInOneTable<Voucher>
                headers={[
                    {
                        label: intl.formatMessage({ id: "vouchers_voucher_codes" }),
                        key: "code",
                        excludeFromSearch: false,
                        sortable: false,
                    },
                    {
                        label: intl.formatMessage({ id: "common_status" }),
                        key: "plucked_at",
                        excludeFromSearch: true,
                        sortable: false,
                    },

                    {
                        label: "",
                        key: "action",
                        excludeFromSearch: true,
                        sortable: false,
                    },
                ]}
                defaultSortBy="id"
                initialLoad={initialLoad}
                setInitialLoad={setInitialLoad}
                itemsToRow={(voucher, i) => {
                    return (
                        <StyledBodyRow key={i}>
                            <StyledTableCell>{voucher.code}</StyledTableCell>
                            <StyledTableCell>
                                {voucher.plucked_at
                                    ? intl.formatMessage({ id: "vouchers_used" })
                                    : intl.formatMessage({ id: "vouchers_available" })}
                            </StyledTableCell>
                            <StyledTableCell>
                                <Button
                                    onClick={async () => {
                                        await shopClient
                                            .deleteVoucher({
                                                shopId: accountId,
                                                voucherId: voucher.id,
                                            })
                                            .then(() =>
                                                enqueueAlert(
                                                    intl.formatMessage({
                                                        id: "voucher_code_deleted",
                                                    }),
                                                    "success",
                                                ),
                                            )
                                            .catch(() =>
                                                enqueueAlert(
                                                    intl.formatMessage({
                                                        id: "something_went_wrong",
                                                    }),
                                                    "error",
                                                ),
                                            )
                                        setInitialLoad(true)
                                    }}>
                                    <TrashCan width={35} height={35} />
                                </Button>
                            </StyledTableCell>
                        </StyledBodyRow>
                    )
                }}
                fetchItems={fetchVouchers}
            />
        </Grid>
    ) : null
}
