import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Page from "src/components/layout/Page"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { UserAgreementAccount } from "@repo/rezip-client/types"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { EditUserComponent } from "src/components/EditUserComponent"

export const EditDropPointUser = () => {
    const { getToken, getSelectedAgreement } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const intl = useIntl()
    const { id } = useParams()
    const [agreement, setAgreement] = useState<UserAgreementAccount>()
    const { dropPointClient } = useClient()

    const { enqueueAlert } = useSnackbar()
    const { BlockerAlert, setBlock } = useCustomBlocker()

    useEffect(() => {
        if (!partnerId || !id) {
            return
        }
        if (!agreement) {
            const fetchData = async () => {
                const data = await dropPointClient.getAgreement(partnerId, id)
                if (data) {
                    setAgreement(data)
                }
            }
            fetchData()
        }
    }, [agreement, getToken, id, dropPointClient, partnerId])

    const updateUser = useCallback(async () => {
        if (!agreement || !partnerId || !id) {
            return
        }
        const data = await dropPointClient.patchAgreement(
            partnerId,
            id,
            agreement?.owner,
            agreement?.mfa_required,
        )
        if (data) {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_message_user_updated",
                }),
                "success",
            )

            setAgreement(data)
            setBlock(false)
        } else {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_message_something_went_Wrong",
                }),
                "error",
            )
        }
    }, [agreement, dropPointClient, enqueueAlert, id, intl, partnerId, setBlock])

    return (
        <Page
            title={intl.formatMessage({
                id: "users_edit_user",
            })}
            backButton>
            <Box
                sx={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                }}>
                <EditUserComponent
                    agreement={agreement}
                    setAgreement={setAgreement}
                    setBlock={setBlock}
                    updateUser={updateUser}
                />
            </Box>
            <BlockerAlert />
        </Page>
    )
}
