import ReactDOM from "react-dom/client"
import { Root } from "./root"
import posthog from "posthog-js"

window.onerror = (_, url, lineNo, columnNo, error) =>
    error ? posthog.captureException(error, { url, lineNo, columnNo }) : null

window.onunhandledrejection = (event) => posthog.captureException(event.reason)

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<Root />)
