import { z } from "zod"
import { baseApiErrorHandling, ReZipClient } from "."
import { ULID } from "./types"

interface ISOStringable {
    toISOString(): string
}

export const ActivityMap = z.array(
    z.object({
        position: z.object({
            x: z.number(),
            y: z.number(),
        }),
        amount: z.number(),
    }),
)

export type ActivityMap = z.infer<typeof ActivityMap>

export const StatisticsEntry = z.object({
    time_increment: z
        .string()
        .nullish()
        .transform((x) => x ?? ""),
    transport_co2e: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    transport_water: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    production_co2e: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    production_water: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    destruction_co2e: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    destruction_water: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    assembly_co2e: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    assembly_water: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    sorting_co2e: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    sorting_water: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    waste_kg: z
        .number()
        .nullish()
        .transform((x) => x ?? 0),
    per_sku: z.record(
        z.string(),
        z.object({
            produced: z
                .number()
                .nullish()
                .transform((x) => x ?? 0),
            reused: z
                .number()
                .nullish()
                .transform((x) => x ?? 0),
            recycled: z
                .number()
                .nullish()
                .transform((x) => x ?? 0),
            shipped: z
                .number()
                .nullish()
                .transform((x) => x ?? 0),
            transported_km: z
                .number()
                .nullish()
                .transform((x) => x ?? 0),
        }),
    ),
})

export type StatisticsEntry = z.infer<typeof StatisticsEntry>

export const Statistics = z.object({
    sum: StatisticsEntry,
    each: z.array(StatisticsEntry),
})

export type Statistics = z.infer<typeof Statistics>

export class ReportingClient {
    rezipClient: ReZipClient
    prefix: string
    constructor(rezipClient: ReZipClient, prefix: string) {
        this.rezipClient = rezipClient
        this.prefix = prefix
    }

    async activityMap({
        precision,
        locationBounds,
        from,
        to,
        shopId,
    }: {
        precision: number
        locationBounds: { up: number; left: number; right: number; down: number }
        from: ISOStringable
        to: ISOStringable
        shopId?: ULID
    }): Promise<ActivityMap> {
        const response = await this.rezipClient.query({
            path: `${this.prefix}/reporting/activity_map`,
            body: JSON.stringify({
                precision,
                location_bounds: locationBounds,
                to: to.toISOString(),
                from: from.toISOString(),
                shop_id: shopId,
            }),
        })
        await baseApiErrorHandling(response)
        return ActivityMap.parse(await response.json())
    }

    async statistics({
        precision,
        to,
        from,
        shopId,
    }: {
        precision: number
        to: ISOStringable
        from: ISOStringable
        shopId?: ULID
    }): Promise<Statistics> {
        const response = await this.rezipClient.query({
            path: `${this.prefix}/reporting/statistics`,
            body: JSON.stringify({
                precision,
                to: to.toISOString(),
                from: from.toISOString(),
                shop_id: shopId,
            }),
        })
        await baseApiErrorHandling(response)
        const body = await response.json()
        return Statistics.parse(body)
    }
}
