import { SelectChangeEvent, Grid } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import InputField from "src/components/InputField"
import MapComponent from "src/components/MapComponent"
import Page from "src/components/layout/Page"
import SelectField, { IMenuItemData } from "src/components/SelectField"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import useCountryList from "src/utils/countries"
import StyledForm from "src/components/layout/StyledForm"
import { useJsLoader } from "src/contexts/JsLoaderConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { Location } from "@repo/rezip-client/partner_client"
import { Address } from "@repo/rezip-client/types"
import { useCustomBlocker } from "src/utils/useCustomBlocker"

const formatAddressForGoogle = (x: Address) => {
    return `${x.street} ${x.city} ${x.postal_code} ${x.region ?? ""} ${x.country}`
}
const guessLatLng = async (address: Address) => {
    const result = await new google.maps.Geocoder().geocode({
        address: formatAddressForGoogle(address),
    })
    if (result.results.length === 0) {
        return null
    }
    const location = result.results[0].geometry.location
    return { latitude: location.lat(), longitude: location.lng() }
}

const LocationPage = () => {
    const CountryList = useCountryList()
    const { locationId } = useParams()
    const { getSelectedAgreement } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const [location, setLocation] = useState<Partial<Location | null>>(null)
    const [selectedCountry, setSelectedCountry] = useState<IMenuItemData>(CountryList[0])
    const { enqueueAlert } = useSnackbar()
    const intl = useIntl()
    const { partnerClient } = useClient()
    const navigate = useNavigate()
    const [address, setAddress] = useState<Address | null>(null)
    const { BlockerAlert, setBlock, isBlocked } = useCustomBlocker()

    const [coordsSource, setCoordsSource] = useState<"google" | "user">("google")
    const { isLoaded } = useJsLoader()
    const setLatLong = useCallback(async () => {
        if (isLoaded && coordsSource == "google" && address) {
            ;(async () => {
                const coords = await guessLatLng(address)
                if (coords) {
                    // typescript complains about this, not sure why it 100% follows react docs
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setAddress((x: T) => ({
                        ...x,
                        ...coords,
                    }))
                }
            })()
        }
    }, [isLoaded, coordsSource, address])

    useEffect(() => {
        if (partnerId && locationId && !location) {
            const fetchData = async () => {
                const data = await partnerClient.getLocation(partnerId, locationId)
                if (data) {
                    setLocation(data)
                    setAddress(data.address)
                    const country = CountryList.find((item) => item.id === data.address.country)
                    if (country) {
                        setSelectedCountry(country)
                    }
                }
            }
            fetchData()
        }
    }, [CountryList, location, locationId, partnerClient, partnerId])

    const createLocation = async () => {
        if (!partnerId || !location || !address) {
            return
        }

        const newLocation = {
            ...location,
            address: { ...address },
        }
        setBlock(false)

        const create = await partnerClient.postLocation(partnerId, newLocation)
        if (create) {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_message_location_created",
                }),
                "success",
            )
            navigate(-1)
        } else {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_something_went_wrong",
                }),
                "error",
            )
        }
    }

    const updateLocation = async () => {
        if (!partnerId || !locationId || !location || !address) {
            return
        }

        const newLocation = {
            ...location,
            address: { ...address }, // Safely add the address to the new object
        }
        const create = await partnerClient.patchLocation(partnerId, locationId, newLocation)
        setBlock(false)
        if (create) {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_message_location_updated",
                }),
                "success",
            )
        } else {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_something_went_wrong",
                }),
                "error",
            )
        }
    }

    const onDragEnd = useCallback(
        (coord: google.maps.MapMouseEvent) => {
            if (!location) {
                return
            }
            const { latLng } = coord
            if (!latLng) {
                return
            }
            const lat = latLng.lat()
            const lng = latLng.lng()
            setCoordsSource("user")

            setLocation({ ...location, latitude: lat, longitude: lng })
            setBlock(true)
        },
        [location, setBlock],
    )
    const onCountryChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const item = CountryList.find((item) => item.id === event.target.value)
            if (item) {
                setSelectedCountry(item)
                setAddress((prevLocation) => ({
                    ...(prevLocation || {
                        street: "",
                        city: "",
                        postal_code: "",
                        country: item.id,
                        longitude: null,
                        latitude: null,
                        region: null,
                    }),
                    country: item.id,
                }))
                setBlock(true)
            }
        },
        [CountryList, setBlock],
    )

    return (
        <Page
            backButton
            title={
                locationId
                    ? intl.formatMessage({
                          id: "edit_location",
                      })
                    : intl.formatMessage({
                          id: "add_new_location",
                      })
            }>
            <DefaultWrapperPaper>
                <Grid container spacing={2}>
                    <Grid container size={{ md: 12, lg: 6 }} sx={{ width: "100%" }}>
                        <StyledForm sx={{ width: "100%" }}>
                            <Grid container direction="column" spacing={1}>
                                <InputField
                                    id={"warehouse_name"}
                                    name={"warehouse_name"}
                                    type={"Text"}
                                    required
                                    label={intl.formatMessage({
                                        id: "common_warehouse_name",
                                    })}
                                    value={location?.name || ""}
                                    onChange={(e) => {
                                        setLocation((prevLocation) => ({
                                            ...prevLocation, // Keep existing properties
                                            name: e.target.value, // Update the `name` field
                                        }))
                                        setBlock(true)
                                    }}
                                />
                                <InputField
                                    id={"latitude"}
                                    name={"latitude"}
                                    type={"Number"}
                                    label={intl.formatMessage({
                                        id: "common_latitude",
                                    })}
                                    value={address?.latitude ?? ""}
                                    onChange={(e) => {
                                        setAddress((prevAddress) => ({
                                            ...(prevAddress || {
                                                street: "",
                                                city: "",
                                                postal_code: "",
                                                country: selectedCountry.id,
                                                longitude: null,
                                                latitude: null,
                                                region: null,
                                            }),
                                            latitude: Number(e.target.value),
                                        }))
                                        setBlock(true)
                                    }}
                                />
                                <InputField
                                    id={"longtitude"}
                                    name={"longtitude"}
                                    type={"Number"}
                                    label={intl.formatMessage({
                                        id: "common_longtitude",
                                    })}
                                    value={address?.longitude ?? ""}
                                    onChange={(e) => {
                                        setAddress((prevAddress) => ({
                                            ...(prevAddress || {
                                                street: "",
                                                city: "",
                                                postal_code: "",
                                                country: selectedCountry.id,
                                                longitude: null,
                                                latitude: null,
                                                region: null,
                                            }),
                                            longitude: Number(e.target.value),
                                        }))
                                        setBlock(true)
                                    }}
                                />
                                {address && location && isLoaded ? (
                                    <Grid sx={{ marginTop: "8px" }}>
                                        <MapComponent
                                            defaultLocation={{
                                                lat: address.latitude ?? 0,
                                                lng: address.longitude ?? 0,
                                            }}
                                            onDragEnd={onDragEnd}
                                            locations={[
                                                {
                                                    location: {
                                                        lat: address.latitude ?? 0,
                                                        lng: address.longitude ?? 0,
                                                    },
                                                    name: location.name ?? "",
                                                },
                                            ]}
                                            allowDrag={true}
                                            height={400}
                                            showOwnLocation={false}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </StyledForm>
                    </Grid>
                    <Grid container size={{ md: 12, lg: 6 }} sx={{ width: "100%" }}>
                        <StyledForm
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                            <Grid container direction="column" spacing={1}>
                                <InputField
                                    id={"street"}
                                    name={"street"}
                                    type={"text"}
                                    label={intl.formatMessage({
                                        id: "street",
                                    })}
                                    value={address?.street}
                                    required
                                    onChange={(e) => {
                                        setAddress((prevLocation) => ({
                                            ...(prevLocation || {
                                                street: "",
                                                city: "",
                                                postal_code: "",
                                                country: selectedCountry.id,
                                                longitude: null,
                                                latitude: null,
                                                region: null,
                                            }),
                                            street: e.target.value,
                                        }))
                                        setLatLong()
                                        setBlock(true)
                                    }}
                                />
                                <InputField
                                    id={"city"}
                                    name={"city"}
                                    type={"text"}
                                    label={intl.formatMessage({
                                        id: "city",
                                    })}
                                    required
                                    value={address?.city}
                                    onChange={(e) => {
                                        setAddress((prevLocation) => ({
                                            ...(prevLocation || {
                                                street: "",
                                                city: "",
                                                postal_code: "",
                                                country: selectedCountry.id,
                                                longitude: null,
                                                latitude: null,
                                                region: null,
                                            }),
                                            city: e.target.value,
                                        }))
                                        setLatLong()
                                        setBlock(true)
                                    }}
                                />
                                <InputField
                                    id={"zipcode"}
                                    name={"zipcode"}
                                    type={"text"}
                                    label={intl.formatMessage({
                                        id: "zip_code",
                                    })}
                                    required
                                    value={address?.postal_code}
                                    onChange={(e) => {
                                        setAddress((prevLocation) => ({
                                            ...(prevLocation || {
                                                street: "",
                                                city: "",
                                                postal_code: "",
                                                country: selectedCountry.id,
                                                longitude: null,
                                                latitude: null,
                                                region: null,
                                            }),
                                            postal_code: e.target.value,
                                        }))
                                        setLatLong()
                                        setBlock(true)
                                    }}
                                />
                                <InputField
                                    id={"region"}
                                    name={"region"}
                                    type={"text"}
                                    label={intl.formatMessage({
                                        id: "region",
                                    })}
                                    value={address?.region}
                                    onChange={(e) => {
                                        setAddress((prevLocation) => ({
                                            ...(prevLocation || {
                                                street: "",
                                                city: "",
                                                postal_code: "",
                                                country: selectedCountry.id,
                                                longitude: null,
                                                latitude: null,
                                                region: null,
                                            }),
                                            region: e.target.value,
                                        }))
                                        setLatLong()
                                        setBlock(true)
                                    }}
                                />
                                <Grid sx={{ maxWidth: "250px" }}>
                                    <SelectField
                                        label={intl.formatMessage({
                                            id: "country",
                                        })}
                                        required
                                        value={selectedCountry.id}
                                        onChange={onCountryChange}
                                        menuItems={CountryList}
                                        large
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end" sx={{ marginTop: "12px" }}>
                                {isBlocked ? (
                                    <CustomButton
                                        onClick={locationId ? updateLocation : createLocation}
                                        text={
                                            locationId
                                                ? intl.formatMessage({
                                                      id: "common_save",
                                                  })
                                                : intl.formatMessage({
                                                      id: "create_location",
                                                  })
                                        }
                                    />
                                ) : null}
                            </Grid>
                        </StyledForm>
                    </Grid>
                </Grid>
            </DefaultWrapperPaper>

            <BlockerAlert />
        </Page>
    )
}

export default LocationPage
