import { SelectChangeEvent, Table, TableBody, TableContainer, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import { startTransition, Suspense, useCallback, useEffect, useMemo, useState } from "react"

import { IMenuItemData } from "../SelectField"
import { useIntl } from "react-intl"
import { TableHeader } from "./CustomTableHeader"

import { QueryingOptions } from "@repo/rezip-client"
import dayjs from "dayjs"
import React, { JSX } from "react"

const TablePagination = React.lazy(() => import("./TablePagnination"))
const SearchBar = React.lazy(() => import("./SearchBar"))

export interface Header<T> {
    label: string
    key: keyof T | string
    excludeFromSearch?: boolean
    sortable?: boolean
}

export type SortOrder = "asc" | "desc"
export interface AllInOneTableProps<T> {
    headers: Header<T | string>[]
    itemsToRow: (item: T, index: number) => JSX.Element
    defaultSortBy: keyof T
    fetchItems?: (props: QueryingOptions) => Promise<T[] | null | undefined>
    initialLoad?: boolean
    setInitialLoad?: (bool: boolean) => void
    additionalSortColumns?: JSX.Element
    onSubmit?: (item: string) => Promise<void> | void
    addFunction?: () => void
    addButtonText?: string
    hideSearchBar?: boolean
    items?: T[]
    tableName?: string
    additionalColumnPosition?: "above" | "inline"
    orderDirection?: SortOrder
    bottomRow?: JSX.Element
}
const isObjectWithKey = <T,>(
    value: T,
    key: keyof T | string,
): value is T & Record<string, unknown> => {
    return typeof value === "object" && value !== null && key in value
}

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T | string): number => {
    if (isObjectWithKey(a, orderBy) && isObjectWithKey(b, orderBy)) {
        const aValue = a[orderBy as keyof T]
        const bValue = b[orderBy as keyof T]

        // Handle date comparison for specific fields
        if (["created_at", "updated_at", "deleted_at"].includes(String(orderBy))) {
            const aDate = dayjs(aValue as string | number | Date | null | undefined) // Explicit cast
            const bDate = dayjs(bValue as string | number | Date | null | undefined) // Explicit cast

            if (aDate.isBefore(bDate)) return -1
            if (aDate.isAfter(bDate)) return 1
            return 0
        }

        // Handle other types (e.g., numbers, strings)
        if (typeof aValue === "string" && typeof bValue === "string") {
            return aValue.localeCompare(bValue)
        } else if (typeof aValue === "number" && typeof bValue === "number") {
            return aValue - bValue
        }
    }

    return 0
}

const getComparator = <T,>(
    order: SortOrder,
    orderBy: keyof T | string | null,
): ((a: T, b: T) => number) => {
    if (orderBy === null) {
        return () => 0
    }

    // Check if orderBy is a date field
    const isDateField = ["created_at", "updated_at", "deleted_at"].includes(String(orderBy))

    return order === "desc"
        ? (a, b) => {
              if (isDateField) {
                  // Use date comparison directly
                  const aValue = dayjs(
                      a[orderBy as keyof T] as string | number | Date | null | undefined,
                  )
                  const bValue = dayjs(
                      b[orderBy as keyof T] as string | number | Date | null | undefined,
                  )

                  if (aValue.isBefore(bValue)) return 1
                  if (aValue.isAfter(bValue)) return -1
                  return 0
              }

              // Use the generic comparator for other fields
              return descendingComparator(a, b, orderBy)
          }
        : (a, b) => {
              if (isDateField) {
                  // Use date comparison directly
                  const aValue = dayjs(
                      a[orderBy as keyof T] as string | number | Date | null | undefined,
                  )
                  const bValue = dayjs(
                      b[orderBy as keyof T] as string | number | Date | null | undefined,
                  )

                  if (aValue.isBefore(bValue, "day")) return -1
                  if (aValue.isAfter(bValue, "day")) return 1
                  return 0
              }

              // Use the generic comparator for other fields
              return -descendingComparator(a, b, orderBy)
          }
}

/*
    SUDO readme
    This table is intended to incapsulate everything we need in a table.
    It handles fetching of data if a fetch method is provided, and handles pagination.
    the fetch is structured in such a way that it also handles searches if queryPrams are provided.

    The table also contains functionallity to add sortable headers if this is needed in the future.


    reason for making this table.
    We simply needed a better way of handling pagination, switching between locations, and a streamlined way to make a tables, since we have alot.
    as we intend to reuse alot of our components this will make sure that we a change made here will persist everywhere we have a table.


    props explation:
    Headers: simple object containing label: key, excludeFromSearch ie searchable, and sortable.
    ItemsToRow: function that tells the table how to render each row. this is something im thinking about if we could change somehow to make it easier
    defualtSortBy: needed for fetch, the default sort in the backend is id.
    inititalLoad and setInitialLoad: is needed if changes are made to the data, and we need to refetchs from the beginning
    additionalcolumns: kinda edgecase but we need this for specials scenarios like switching location, see stock-keeping for and example
    onSubmit: if a submit is needed on the searchfield, ie if you scan a storageunit id, we want it to navigate to the storageunit
    addFunction: for tables where you need to navigate to a "add-page", the button auto-hides if the function isnt present
    addButtonText: if you want to change the text on the button from "Add" to "add some name"
    hideSearchBar: if you want to hide the searchbar, notable the searchbar is also hidden if no items in the columnSelectItems are <=1 since we alwasy have 1  saying "select-field"

    If you need a simple table you can now parse items into the table without a fetchItems, and it will use the items provided and not load more

*/

export const AllInOneTable = <T extends { [key: string]: unknown } | string>({
    headers,
    itemsToRow,
    defaultSortBy,
    fetchItems,
    initialLoad,
    setInitialLoad,
    additionalSortColumns,
    onSubmit,
    addFunction,
    addButtonText,
    hideSearchBar,
    items,
    tableName,
    additionalColumnPosition,
    orderDirection = "desc",
    bottomRow,
}: AllInOneTableProps<T>) => {
    const [orderBy, setOrderBy] = useState<keyof T | null | string>(defaultSortBy || null)
    const [sortOrder, setSortOrder] = useState<SortOrder>(orderDirection)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [objs, setObjs] = useState<T[]>([])
    const [searchValue, setSearchValue] = useState("")
    const intl = useIntl()

    const headerKeys = headers
        .filter((item) => !item.excludeFromSearch)
        .map((item) => {
            return item
        })
    const defaultMenu = useMemo(() => {
        return [
            {
                id: "select-field",
                value: intl.formatMessage({ id: "search_filter" }),
                disabled: true,
            } as IMenuItemData,
        ]
    }, [intl])

    const columnSelectItems: IMenuItemData[] = useMemo(() => {
        const items = headerKeys.map((item) => {
            return { id: item.key, value: item.label, disabled: false } as IMenuItemData
        })

        return [...defaultMenu, ...items]
    }, [defaultMenu, headerKeys])
    const [selectedColumn, setSelectedColumn] = useState<IMenuItemData>(columnSelectItems[0])

    useEffect(() => {
        if (objs.length === 0 || initialLoad) {
            if (fetchItems && setInitialLoad) {
                const filter: Record<string, string> | null = searchValue
                    ? {
                          [selectedColumn.id === "select-field" ? "id" : selectedColumn.id]:
                              `*${searchValue}*`,
                      }
                    : null
                const fetchObjs = async () => {
                    try {
                        const data = await fetchItems({
                            pageSize: rowsPerPage + 1,
                            sortDir: sortOrder,
                            pageFrom: null,
                            sortBy: String(orderBy),
                            filters: filter || {},
                        })
                        if (data) {
                            setObjs(data)
                            setInitialLoad(false)
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
                fetchObjs()
            }
            if (items) {
                const filter: Record<string, string> | null = searchValue
                    ? {
                          [selectedColumn.id === "select-field" ? "id" : selectedColumn.id]:
                              `${searchValue}`,
                      }
                    : null

                const filteredItems = items.filter((item) => {
                    if (filter) {
                        const filterKey = Object.keys(filter)[0] as keyof typeof item
                        const filterValue = filter[filterKey as string]
                        // Check if item[filterKey] exists and exclude if it matches filterValue
                        // check is for lowercase so no one gets frustraded by case sensitivity
                        return String(item[filterKey])
                            .toLocaleLowerCase()
                            .includes(filterValue.toLocaleLowerCase())
                    }
                    return true
                })

                setObjs(filteredItems)
                if (setInitialLoad) {
                    setInitialLoad(false)
                }
            }
        }
    }, [
        fetchItems,
        initialLoad,
        items,
        objs.length,
        orderBy,
        rowsPerPage,
        searchValue,
        selectedColumn.id,
        setInitialLoad,
        sortOrder,
    ])

    const handleRequestSort = (property: keyof T | string) => {
        const isAsc = orderBy === property && sortOrder === "asc"
        startTransition(() => {
            setSortOrder(isAsc ? "desc" : "asc")
            setOrderBy(property)
            setCurrentPage(1)
            setObjs([])
        })
    }

    const onNextPage = useCallback(async () => {
        if (objs.length === 0 || !orderBy) {
            return
        }
        if (!fetchItems) {
            setCurrentPage(currentPage + 1)
            return
        }
        // since we fetch 1 more than needed to check wether we can go next or not
        const rightLenght = objs.slice(0, rowsPerPage)
        const lastObj = rightLenght[rightLenght.length - 1]
        const isDateField = ["created_at", "updated_at", "deleted_at"].includes(String(orderBy))
        const dynamicKey = orderBy as keyof typeof lastObj
        const dynamicValue = String(lastObj[dynamicKey]) // Ensure dynamicValue is a string

        const filter: Record<string, string> | null = searchValue
            ? {
                  [selectedColumn.id === "Select field" ? "id" : selectedColumn.id]:
                      `*${searchValue}*`,
              }
            : null
        const data = await fetchItems({
            pageSize: rowsPerPage + 1,
            sortDir: sortOrder,
            pageFrom: isDateField ? dayjs(dynamicValue).toISOString() : dynamicValue,
            sortBy: String(orderBy),
            filters: filter || {},
        })

        if (data && data.length > 0) {
            setObjs(data)
            setCurrentPage(currentPage + 1)
        }
    }, [
        currentPage,
        fetchItems,
        objs,
        orderBy,
        rowsPerPage,
        searchValue,
        selectedColumn.id,
        sortOrder,
    ])

    const onPreviousPage = useCallback(async () => {
        if (currentPage <= 1 || objs.length === 0 || !orderBy) {
            return
        }
        if (!fetchItems) {
            setCurrentPage(currentPage - 1)
            return
        }
        const rightLenght = objs.slice(0, rowsPerPage)
        const firstObj = rightLenght[0]
        const isDateField = ["created_at", "updated_at", "deleted_at"].includes(String(orderBy))
        const dynamicKey = orderBy as keyof typeof firstObj
        const dynamicValue = String(firstObj[dynamicKey]) // Ensure dynamicValue is a string
        const selectedOrder = sortOrder === "desc" ? "asc" : "desc"

        const filter: Record<string, string> | null = searchValue
            ? {
                  [selectedColumn.id === "Select field" ? "id" : selectedColumn.id]:
                      `*${searchValue}*`,
              }
            : null
        // Fetch previous page items
        const data = await fetchItems({
            pageSize: rowsPerPage,
            sortDir: selectedOrder, // Fetch in ascending order to get the previous items
            pageFrom: isDateField ? dayjs(dynamicValue).toISOString() : dynamicValue,
            sortBy: String(orderBy),
            filters: filter || {},
        })

        if (data && data.length > 0) {
            // Reverse data if it was fetched in ascending order
            const corrected = selectedOrder === "asc" ? data.reverse() : data
            setObjs(corrected)
            setCurrentPage(currentPage - 1)
        }
    }, [
        currentPage,
        fetchItems,
        objs,
        orderBy,
        rowsPerPage,
        searchValue,
        selectedColumn.id,
        sortOrder,
    ])

    const createSortHandler = (property: keyof T | string) => () => {
        handleRequestSort(property)
    }

    const handleChangeRowsPerPage = useCallback((count: string) => {
        setRowsPerPage(parseInt(count, 10))
        setObjs([])
        setCurrentPage(1)
    }, [])

    const handleSearchValueChange = useCallback(
        (value: string) => {
            setSearchValue(value)
            if (setInitialLoad) {
                setInitialLoad(true)
            }
            if (currentPage !== 1) {
                setCurrentPage(1)
            }
        },
        [currentPage, setInitialLoad],
    )

    const onColumnChange = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            const column = columnSelectItems.find((si) => si.id == e.target.value)
            if (column) {
                setSelectedColumn(column)
                if (searchValue && setInitialLoad) {
                    setInitialLoad(true)
                }
            }
        },
        [columnSelectItems, searchValue, setInitialLoad],
    )

    const sortedAndVisibleRows = useMemo(() => {
        if (!items) {
            return objs.slice(0, rowsPerPage).sort(getComparator<T>(sortOrder, orderBy))
        }

        const startIdx = rowsPerPage * (currentPage - 1)
        const endIdx = startIdx + rowsPerPage
        const visibleRows = objs.slice(startIdx, endIdx).sort(getComparator<T>(sortOrder, orderBy))

        return visibleRows
    }, [objs, items, rowsPerPage, sortOrder, orderBy, currentPage])

    const renderedRows = useMemo(() => {
        const rows = sortedAndVisibleRows.map((item, index) => itemsToRow(item, index))

        return bottomRow ? rows.concat(bottomRow) : rows
    }, [sortedAndVisibleRows, bottomRow, itemsToRow])

    return (
        <Grid container direction="column">
            {hideSearchBar ? null : (
                <SearchBar
                    searchValue={searchValue}
                    setSearchValue={handleSearchValueChange}
                    onColumnChange={onColumnChange}
                    columnSelectItems={columnSelectItems}
                    selectedColumn={selectedColumn}
                    intl={intl}
                    additionalSortColumns={additionalSortColumns}
                    addButtonText={addButtonText ? addButtonText : "Add"}
                    addFunction={addFunction}
                    setInitialLoad={setInitialLoad}
                    onSubmit={onSubmit}
                    hideSearchBar={hideSearchBar}
                    additionalColumnPosition={additionalColumnPosition ?? "inline"}
                />
            )}

            <TableContainer>
                {tableName ?? <Typography sx={{ alignSelf: "flex-start" }}>{tableName}</Typography>}
                <Table
                    size="small"
                    sx={{ border: "5px solid", borderColor: "inputField.background" }}>
                    <TableHeader
                        headers={headers}
                        orderBy={orderBy}
                        sortOrder={sortOrder}
                        onRequestSort={createSortHandler}
                    />
                    <Suspense>
                        <TableBody>{renderedRows}</TableBody>
                    </Suspense>
                </Table>
            </TableContainer>

            {items && items.length <= rowsPerPage ? null : (
                <TablePagination
                    onNextPage={onNextPage}
                    onPreviousPage={onPreviousPage}
                    rowsPerPage={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    disbalePrevious={currentPage === 1}
                    disbaleNext={
                        items
                            ? items.length <= rowsPerPage * currentPage
                            : objs.length < rowsPerPage
                    }
                    currentPage={currentPage}
                />
            )}
        </Grid>
    )
}
