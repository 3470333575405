import React from "react"
import router from "src/utils/router"
import "src/index.css"
import { RouterProvider } from "react-router-dom"
import { UserProvider } from "src/contexts/UserProvider"
import { PostHogProvider } from "posthog-js/react"
import { SnackbarProvider } from "src/contexts/SnackbarProvider"
import { APIProvider } from "@vis.gl/react-google-maps"
import { JsLoaderProvider } from "src/contexts/JsLoaderProvider"
import { ThemeProvider } from "./contexts/ThemeProvider"

export const Root = () => {
    return (
        <React.StrictMode>
            <PostHogProvider
                apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
                options={{ api_host: import.meta.env.VITE_POSTHOG_API }}>
                <APIProvider apiKey={import.meta.env.VITE_MAP_API_KEY}>
                    <JsLoaderProvider>
                        <UserProvider>
                            <ThemeProvider>
                                <SnackbarProvider>
                                    <RouterProvider router={router} />
                                </SnackbarProvider>
                            </ThemeProvider>
                        </UserProvider>
                    </JsLoaderProvider>
                </APIProvider>
            </PostHogProvider>
        </React.StrictMode>
    )
}
