import { Paper, styled } from "@mui/material"

export const DefaultWrapperPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.secondaryBackground.main,
    backgroundImage: "none",
    padding: "30px",
    borderRadius: "8px",
    boxShadow: "1px 1px 3px #00000020",
    ...theme.applyStyles("dark", {
        boxShadow: "1px 1px 3px #00000050",
    }),
}))
