import { Collapse, SelectChangeEvent, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/system"
import { useCallback, useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import CheckboxField from "src/components/generalComponents/CheckboxField"
import InputField from "src/components/InputField"
import StyledForm from "src/components/layout/StyledForm"
import { Address } from "@repo/rezip-client/types"
import { ArrowBox } from "../generalComponents/CollapseInformation"
import { DefaultWrapperPaper } from "../layout/DefaultPageComponents"
import { useJsLoader } from "src/contexts/JsLoaderConsumer"
import MapComponent from "../MapComponent"
import GenericModal from "../modals/GenericModal"

import SelectField, { IMenuItemData } from "../SelectField"
import useCountryList from "src/utils/countries"
import { CustomButton } from "../generalComponents/Buttons"
import { guessLatLng } from "src/utils/latLngHelper"

export type CollectiveAddresses = {
    shipping_address: Address
    billing_address: Address
    shipping_email: string | null
    billing_email: string | null
    vat_no: string | null
}

export interface ShippingAndBillingProps<T extends CollectiveAddresses> {
    collectiveAddresses: T
    setCollectiveAddresses: (x: T) => void
    disableFields: boolean
    setBlock: (block: boolean) => void
    creation: boolean
}

const BoxHeader = styled(Grid)({
    display: "flex",
    justifyContent: "space-between",
})

export const ShippingAndBilling = <T extends CollectiveAddresses = CollectiveAddresses>({
    disableFields,
    collectiveAddresses,
    setCollectiveAddresses,
    setBlock,
    creation,
}: ShippingAndBillingProps<T>) => {
    const intl = useIntl()
    const CountryList = useCountryList()
    const [billingCountry, setBillingCountry] = useState<IMenuItemData | null | undefined>(
        undefined,
    )
    const [shippingCountry, setShippingCountry] = useState<IMenuItemData | null | undefined>(
        undefined,
    )
    const [billingAddressOpen, setBillingAddressOpen] = useState(true)
    const [shippingAddressOpen, setShippingAddressOpen] = useState(true)
    const [coordsSource, setCoordsSource] = useState<"google" | "user">("google")
    const [sameAddress, setSameAddress] = useState(false)
    const { isLoaded } = useJsLoader()
    const [noLatLng, setNoLatLng] = useState(false)
    const [modalOpened, setModalOpened] = useState(false)
    const collective = useRef<CollectiveAddresses>(collectiveAddresses)

    useEffect(() => {
        if (!creation && (shippingCountry === undefined || billingCountry === undefined)) {
            const shipping = CountryList.find(
                (item) => item.id === collective.current.shipping_address.country,
            )
            const billing = CountryList.find(
                (item) => item.id === collective.current.billing_address.country,
            )
            if (shipping) {
                setShippingCountry(shipping)
            } else if (!shipping) {
                setShippingCountry(null)
            }
            if (billing) {
                setBillingCountry(billing)
            } else if (!billing) {
                setBillingCountry(null)
            }
        }
    }, [
        CountryList,
        billingCountry,
        collectiveAddresses.billing_address.country,
        collectiveAddresses.shipping_address.country,
        creation,
        shippingCountry,
    ])

    useEffect(() => {
        if (
            collective &&
            (collective.current.shipping_address.latitude === null ||
                collective.current.shipping_address.longitude === null) &&
            collective.current.shipping_address.street &&
            collective.current.shipping_address.city &&
            collective.current.shipping_address.postal_code
        ) {
            setNoLatLng(true)
        }
    }, [collectiveAddresses, collectiveAddresses.shipping_address])

    const setLatLong = useCallback(async () => {
        if (isLoaded && coordsSource === "google") {
            try {
                const coords = await guessLatLng(collective.current.shipping_address)
                if (coords) {
                    // typescript complains about this, not sure why it 100% follows react docs
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setCollectiveAddresses((x: T) => ({
                        ...x,
                        shipping_address: {
                            ...collective.current.shipping_address,
                            ...coords,
                        },
                    }))
                }
            } catch (e) {
                console.log(e)
            }
        }
    }, [isLoaded, coordsSource, setCollectiveAddresses])

    const setAdd = useCallback(
        (collectiveAddresses: T) => {
            setCollectiveAddresses(collectiveAddresses)
            collective.current = collectiveAddresses
        },
        [setCollectiveAddresses],
    )

    const onBillingCountryChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const item = CountryList.find((item) => item.id === event.target.value)
            if (item) {
                setBillingCountry(item)
                setAdd({
                    ...collectiveAddresses,
                    billing_address: {
                        ...collectiveAddresses.billing_address,
                        country: item.id,
                    },
                })
                if (sameAddress) {
                    setAdd({
                        ...collectiveAddresses,
                        shipping_address: {
                            ...collectiveAddresses.shipping_address,
                            country: item.id,
                        },
                    })
                }

                setBlock(true)
            }
        },
        [CountryList, collectiveAddresses, sameAddress, setAdd, setBlock],
    )
    const onShippingCountryChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const item = CountryList.find((item) => item.id === event.target.value)
            if (item) {
                setShippingCountry(item)
                setAdd({
                    ...collectiveAddresses,
                    shipping_address: {
                        ...collectiveAddresses.shipping_address,
                        country: item.id,
                    },
                })
                setBlock(true)
            }
        },
        [CountryList, collectiveAddresses, setAdd, setBlock],
    )

    return (
        <DefaultWrapperPaper>
            <Grid container spacing={5}>
                <Grid
                    container
                    id={"billing-box"}
                    direction="column"
                    spacing={1}
                    size={{ xs: 12, md: 6 }}>
                    <BoxHeader id={"billing-address-box"}>
                        <Typography variant="h4">
                            <FormattedMessage id="billing_address" />
                        </Typography>

                        <ArrowBox open={billingAddressOpen} setBool={setBillingAddressOpen} />
                    </BoxHeader>
                    <Collapse in={billingAddressOpen}>
                        <StyledForm>
                            <Grid container spacing={1}>
                                <Grid container direction="column" size={12}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "invoice_email",
                                        })}
                                        type="text"
                                        value={collectiveAddresses.billing_email ?? ""}
                                        id={"billing_email"}
                                        name={"billing_email"}
                                        disabled={disableFields}
                                        error={
                                            !creation
                                                ? !collectiveAddresses.billing_email ||
                                                  collectiveAddresses.billing_email.length === 0
                                                : false
                                        }
                                        errorText={intl.formatMessage({
                                            id: "error_text_invalid_email",
                                        })}
                                        required={!creation}
                                        onChange={(e) => {
                                            setCollectiveAddresses({
                                                ...collectiveAddresses,
                                                billing_email: e.target.value,
                                                shipping_email: sameAddress
                                                    ? e.target.value
                                                    : collectiveAddresses.shipping_email,
                                            })
                                            setBlock(true)
                                        }}
                                    />
                                </Grid>
                                <Grid container size={12}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "street",
                                        })}
                                        type="text"
                                        value={collectiveAddresses.billing_address.street ?? ""}
                                        id={"billing_street"}
                                        name={"Billing_street"}
                                        disabled={disableFields}
                                        error={
                                            !creation
                                                ? !collectiveAddresses.billing_address.street ||
                                                  collectiveAddresses.billing_address.street
                                                      .length === 0
                                                : false
                                        }
                                        errorText={intl.formatMessage({
                                            id: "error_text_invalid_street",
                                        })}
                                        required={!creation}
                                        onChange={(e) => {
                                            setCollectiveAddresses({
                                                ...collectiveAddresses,
                                                billing_address: {
                                                    ...collectiveAddresses.billing_address,
                                                    street: e.target.value,
                                                },
                                                shipping_address: sameAddress
                                                    ? {
                                                          ...collectiveAddresses.shipping_address,
                                                          street: e.target.value,
                                                      }
                                                    : collectiveAddresses.shipping_address,
                                            })
                                            setBlock(true)
                                            setLatLong()
                                        }}
                                    />
                                </Grid>
                                <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "city",
                                        })}
                                        type="text"
                                        value={collectiveAddresses.billing_address.city ?? ""}
                                        id={"billing_city"}
                                        error={
                                            !creation
                                                ? !collectiveAddresses.billing_address.city ||
                                                  collectiveAddresses.billing_address.city
                                                      .length === 0
                                                : false
                                        }
                                        errorText={intl.formatMessage({
                                            id: "error_text_invalid_city",
                                        })}
                                        disabled={disableFields}
                                        name={""}
                                        required={!creation}
                                        onChange={(e) => {
                                            setCollectiveAddresses({
                                                ...collectiveAddresses,
                                                billing_address: {
                                                    ...collectiveAddresses.billing_address,
                                                    city: e.target.value,
                                                },
                                                shipping_address: sameAddress
                                                    ? {
                                                          ...collectiveAddresses.shipping_address,
                                                          city: e.target.value,
                                                      }
                                                    : collectiveAddresses.shipping_address,
                                            })
                                            setBlock(true)
                                            setLatLong()
                                        }}
                                    />
                                </Grid>
                                <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "postal_code",
                                        })}
                                        type="text"
                                        value={
                                            collectiveAddresses.billing_address.postal_code ?? ""
                                        }
                                        id={"billing_postal_code"}
                                        required={!creation}
                                        error={
                                            !creation
                                                ? !collectiveAddresses.billing_address
                                                      .postal_code ||
                                                  collectiveAddresses.billing_address.postal_code
                                                      .length === 0
                                                : false
                                        }
                                        errorText={intl.formatMessage({
                                            id: "error_text_invalid_postal_code",
                                        })}
                                        disabled={disableFields}
                                        name={"billing_postal_code"}
                                        onChange={(e) => {
                                            setCollectiveAddresses({
                                                ...collectiveAddresses,
                                                billing_address: {
                                                    ...collectiveAddresses.billing_address,
                                                    postal_code: e.target.value,
                                                },
                                                shipping_address: sameAddress
                                                    ? {
                                                          ...collectiveAddresses.shipping_address,
                                                          postal_code: e.target.value,
                                                      }
                                                    : collectiveAddresses.shipping_address,
                                            })
                                            setBlock(true)
                                            setLatLong()
                                        }}
                                    />
                                </Grid>
                                <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "region",
                                        })}
                                        type="text"
                                        value={collectiveAddresses.billing_address.region || ""}
                                        disabled={disableFields}
                                        id={"region"}
                                        name={""}
                                        onChange={(e) => {
                                            const val =
                                                e.target.value.length === 0
                                                    ? undefined
                                                    : e.target.value

                                            setCollectiveAddresses({
                                                ...collectiveAddresses,
                                                billing_address: {
                                                    ...collectiveAddresses.billing_address,
                                                    region: val,
                                                },
                                                shipping_address: sameAddress
                                                    ? {
                                                          ...collectiveAddresses.shipping_address,
                                                          region: e.target.value,
                                                      }
                                                    : collectiveAddresses.shipping_address,
                                            })
                                            setBlock(true)
                                            setLatLong()
                                        }}
                                    />
                                </Grid>
                                <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                    <SelectField
                                        label={intl.formatMessage({
                                            id: "country",
                                        })}
                                        required
                                        value={billingCountry?.id}
                                        onChange={onBillingCountryChange}
                                        menuItems={CountryList}
                                        large
                                    />
                                </Grid>
                                <Grid container size={12}>
                                    <InputField
                                        label={intl.formatMessage({
                                            id: "vat_no",
                                        })}
                                        type="text"
                                        value={collectiveAddresses.vat_no ?? ""}
                                        id={"vat_no"}
                                        error={
                                            !creation
                                                ? !collectiveAddresses.vat_no ||
                                                  collectiveAddresses.vat_no.length === 0
                                                : false
                                        }
                                        required={!creation}
                                        errorText={intl.formatMessage({
                                            id: "error_text_invalid_vat",
                                        })}
                                        name={"billing_vat_no"}
                                        disabled={disableFields}
                                        onChange={(e) => {
                                            setCollectiveAddresses({
                                                ...collectiveAddresses,
                                                vat_no: e.target.value,
                                            })
                                            setBlock(true)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </StyledForm>
                    </Collapse>
                </Grid>

                <Grid
                    container
                    id={"shipping-box"}
                    direction="column"
                    spacing={1}
                    size={{ xs: 12, md: 6 }}>
                    <BoxHeader id={"shipping-address-box"}>
                        <Typography variant="h4">
                            <FormattedMessage id="shipping_address" />
                        </Typography>

                        <ArrowBox open={shippingAddressOpen} setBool={setShippingAddressOpen} />
                    </BoxHeader>
                    <Collapse in={shippingAddressOpen}>
                        <StyledForm>
                            <Grid container direction="column" spacing={2}>
                                <Grid sx={{ marginTop: "10px" }}>
                                    <CheckboxField
                                        text={intl.formatMessage({
                                            id: "shipping_as_billing",
                                        })}
                                        id={"same-as"}
                                        name={"same-as"}
                                        disabled={disableFields}
                                        checked={sameAddress}
                                        onChange={(x) => {
                                            if (x.target.checked) {
                                                setCollectiveAddresses({
                                                    ...collectiveAddresses,
                                                    shipping_address:
                                                        collectiveAddresses.billing_address,
                                                    shipping_email:
                                                        collectiveAddresses.billing_email,
                                                })
                                                setShippingCountry(billingCountry)
                                            }
                                            setSameAddress(x.target.checked)
                                            setBlock(true)
                                        }}
                                    />
                                </Grid>

                                {sameAddress ? null : (
                                    <Grid container spacing={1}>
                                        <Grid container direction="column" size={12}>
                                            <InputField
                                                id={"shipping_email"}
                                                name={"shipping_email"}
                                                disabled={disableFields}
                                                label={intl.formatMessage({
                                                    id: "shipping_email",
                                                })}
                                                type={"text"}
                                                required={!creation}
                                                error={
                                                    !creation
                                                        ? !collectiveAddresses.shipping_email ||
                                                          collectiveAddresses.shipping_email
                                                              .length === 0
                                                        : false
                                                }
                                                errorText={intl.formatMessage({
                                                    id: "error_text_invalid_email",
                                                })}
                                                value={collectiveAddresses.shipping_email}
                                                onChange={(e) => {
                                                    setAdd({
                                                        ...collectiveAddresses,
                                                        shipping_email: e.target.value,
                                                    })
                                                    setBlock(true)
                                                }}
                                            />
                                        </Grid>
                                        <Grid container direction="column" size={12}>
                                            <InputField
                                                id={"shipping_street"}
                                                name={"shipping_street"}
                                                disabled={disableFields}
                                                type={"text"}
                                                error={
                                                    !creation
                                                        ? !collectiveAddresses.shipping_address
                                                              .street ||
                                                          collectiveAddresses.shipping_address
                                                              .street.length === 0
                                                        : false
                                                }
                                                errorText={intl.formatMessage({
                                                    id: "error_text_invalid_street",
                                                })}
                                                label={intl.formatMessage({
                                                    id: "street",
                                                })}
                                                required={!creation}
                                                value={collectiveAddresses.shipping_address.street}
                                                onChange={(e) => {
                                                    setAdd({
                                                        ...collectiveAddresses,
                                                        shipping_address: {
                                                            ...collectiveAddresses.shipping_address,
                                                            street: e.target.value,
                                                        },
                                                    })
                                                    setBlock(true)
                                                    setLatLong()
                                                }}
                                            />
                                        </Grid>
                                        <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                            <InputField
                                                id={"shipping_city"}
                                                name={"shipping_city"}
                                                type={"text"}
                                                disabled={disableFields}
                                                error={
                                                    !creation
                                                        ? !collectiveAddresses.shipping_address
                                                              .city ||
                                                          collectiveAddresses.shipping_address.city
                                                              .length === 0
                                                        : false
                                                }
                                                errorText={intl.formatMessage({
                                                    id: "error_text_invalid_city",
                                                })}
                                                label={intl.formatMessage({
                                                    id: "city",
                                                })}
                                                required={!creation}
                                                value={collectiveAddresses.shipping_address.city}
                                                onChange={(e) => {
                                                    setAdd({
                                                        ...collectiveAddresses,
                                                        shipping_address: {
                                                            ...collectiveAddresses.shipping_address,
                                                            city: e.target.value,
                                                        },
                                                    })
                                                    setBlock(true)
                                                    setLatLong()
                                                }}
                                            />
                                        </Grid>
                                        <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                            <InputField
                                                id={"shipping_postal_code"}
                                                name={"shipping_postal_code"}
                                                disabled={disableFields}
                                                required={!creation}
                                                error={
                                                    !creation
                                                        ? !collectiveAddresses.shipping_address
                                                              .postal_code ||
                                                          collectiveAddresses.shipping_address
                                                              .postal_code.length === 0
                                                        : false
                                                }
                                                errorText={intl.formatMessage({
                                                    id: "error_text_invalid_postal_code",
                                                })}
                                                type={"text"}
                                                label={intl.formatMessage({
                                                    id: "postal_code",
                                                })}
                                                value={
                                                    collectiveAddresses.shipping_address.postal_code
                                                }
                                                onChange={(e) => {
                                                    setAdd({
                                                        ...collectiveAddresses,
                                                        shipping_address: {
                                                            ...collectiveAddresses.shipping_address,
                                                            postal_code: e.target.value,
                                                        },
                                                    })
                                                    setBlock(true)
                                                    setLatLong()
                                                }}
                                            />
                                        </Grid>
                                        <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                            <InputField
                                                id={"shipping_region"}
                                                name={"shipping_region"}
                                                disabled={disableFields}
                                                type={"text"}
                                                label={intl.formatMessage({
                                                    id: "region",
                                                })}
                                                value={collectiveAddresses.shipping_address.region}
                                                onChange={(e) => {
                                                    const val =
                                                        e.target.value.length === 0
                                                            ? undefined
                                                            : e.target.value
                                                    setAdd({
                                                        ...collectiveAddresses,
                                                        shipping_address: {
                                                            ...collectiveAddresses.shipping_address,
                                                            region: val,
                                                        },
                                                    })
                                                    setBlock(true)
                                                    setLatLong()
                                                }}
                                            />
                                        </Grid>
                                        <Grid container direction="column" size={{ xs: 12, md: 6 }}>
                                            <SelectField
                                                label={intl.formatMessage({
                                                    id: "country",
                                                })}
                                                required
                                                value={shippingCountry?.id}
                                                onChange={onShippingCountryChange}
                                                menuItems={CountryList}
                                                large
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {!sameAddress && isLoaded && collective ? (
                                    <Grid>
                                        <MapComponent
                                            defaultLocation={{
                                                lat:
                                                    collective.current.shipping_address.latitude ??
                                                    0,
                                                lng:
                                                    collective.current.shipping_address.longitude ??
                                                    0,
                                            }}
                                            locations={[
                                                {
                                                    location: {
                                                        lat:
                                                            collective.current.shipping_address
                                                                .latitude ?? 0,
                                                        lng:
                                                            collective.current.shipping_address
                                                                .longitude ?? 0,
                                                    },
                                                    name: "location",
                                                },
                                            ]}
                                            allowDrag={true}
                                            height={400}
                                            showOwnLocation={false}
                                            onDragEnd={(coord) => {
                                                const { latLng } = coord
                                                if (!latLng) {
                                                    return
                                                }
                                                const lat = latLng.lat()
                                                const lng = latLng.lng()
                                                setCoordsSource("user")
                                                setCollectiveAddresses({
                                                    ...collectiveAddresses,
                                                    shipping_address: {
                                                        ...collectiveAddresses.shipping_address,
                                                        latitude: lat,
                                                        longitude: lng,
                                                    },
                                                })
                                                setBlock(true)
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </StyledForm>
                    </Collapse>
                </Grid>
            </Grid>
            {!modalOpened && noLatLng ? (
                <GenericModal
                    open={true}
                    CustomContent={
                        <Typography>
                            {intl.formatMessage({ id: "shipping_and_billing_no_lat_lng" })}
                        </Typography>
                    }
                    title={""}
                    CustomActions={
                        <CustomButton
                            onClick={() => setModalOpened(true)}
                            text={intl.formatMessage({ id: "common_ok" })}
                        />
                    }
                    onClose={() => setModalOpened(true)}
                />
            ) : null}
        </DefaultWrapperPaper>
    )
}

export default ShippingAndBilling
