import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import IdCell from "src/components/table/IdCell"
import Page from "src/components/layout/Page"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import ThreeDotMenu from "src/components/generalComponents/ThreeDotMenu"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { StorageUnitTemplate } from "@repo/rezip-client/partner_client"
import { Box, Typography } from "@mui/material"

import GenericModal from "src/components/modals/GenericModal"
import { ColorThemeName, CustomButton } from "src/components/generalComponents/Buttons"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { QueryingOptions } from "@repo/rezip-client"
import { StyledMenuItem } from "src/components/styles"
import { TrashCan } from "src/components/TrashCan"
import { Edit } from "@mui/icons-material"
export default function Settings() {
    const intl = useIntl()
    const { partnerClient } = useClient()
    const { getSelectedAgreement } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const [initialLoad, setInitialLoad] = useState<boolean>(true)
    const [itemToDelete, setItemToDelete] = useState<StorageUnitTemplate | null>(null)
    const { enqueueAlert } = useSnackbar()
    const navigate = useNavigate()

    const deleteType = useCallback(async () => {
        if (!partnerId || !itemToDelete) {
            return
        }
        const data = await partnerClient.deleteStorageUnitTemplate({
            partnerId: partnerId,
            templateId: itemToDelete.id,
        })
        if (data) {
            setInitialLoad(true)
            enqueueAlert(
                intl.formatMessage({ id: "item_was_deleted" }, { item: itemToDelete.type }),
                "success",
            )
            setItemToDelete(null)
            return
        } else {
            enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
            setItemToDelete(null)
            return
        }
    }, [enqueueAlert, intl, itemToDelete, partnerClient, partnerId])

    const fetchItems = useCallback(
        async ({ pageSize, pageFrom, sortBy, sortDir, filters }: QueryingOptions) => {
            if (!partnerId) {
                return []
            }
            const options = {
                filters: { ...filters },
                pageFrom: pageFrom,
                sortBy: sortBy,
                sortDir: sortDir,
                pageSize: pageSize,
            }
            return await partnerClient.getStorageUnitTemplates(partnerId, options)
        },
        [partnerClient, partnerId],
    )

    const itemToRow = useCallback(
        (item: StorageUnitTemplate, index: number) => {
            return (
                <StyledBodyRow key={index}>
                    <IdCell id={item.id} />
                    <StyledTableCell>{item.type}</StyledTableCell>
                    <StyledTableCell>
                        <ThreeDotMenu
                            menuItems={[
                                <StyledMenuItem
                                    key={`view-${index}`}
                                    onClick={() => navigate(`view/${item.id}`)}>
                                    <Edit />

                                    <Typography>
                                        {intl.formatMessage({ id: "common_edit" })}
                                    </Typography>
                                </StyledMenuItem>,
                                <StyledMenuItem
                                    key={`delete-${index}`}
                                    onClick={() => setItemToDelete(item)}>
                                    <TrashCan width={25} height={25} />
                                    <Typography color={"warning.main"}>
                                        {intl.formatMessage({ id: "common_delete" })}
                                    </Typography>
                                </StyledMenuItem>,
                            ]}
                        />
                    </StyledTableCell>
                </StyledBodyRow>
            )
        },
        [intl, navigate],
    )

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_storage_unit_types",
            })}>
            <DefaultWrapperPaper>
                <AllInOneTable<StorageUnitTemplate>
                    headers={[
                        {
                            key: "id",
                            label: intl.formatMessage({ id: "id" }),
                            excludeFromSearch: true,
                        },
                        { key: "type", label: intl.formatMessage({ id: "common_type_name" }) },
                        { key: "actions", label: "", excludeFromSearch: true },
                    ]}
                    itemsToRow={itemToRow}
                    defaultSortBy={"id"}
                    fetchItems={fetchItems}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    addFunction={() => navigate("add")}
                    addButtonText={intl.formatMessage({ id: "common_add" })}
                />
            </DefaultWrapperPaper>
            <GenericModal
                open={!!itemToDelete}
                CustomContent={
                    <Box>
                        <Typography>
                            {intl.formatMessage(
                                { id: "generic_do_you_want_to_delete" },
                                { item: itemToDelete?.type },
                            )}
                        </Typography>
                    </Box>
                }
                CustomActions={
                    <Box>
                        <CustomButton
                            text={intl.formatMessage({ id: "common_no" })}
                            colorTheme={ColorThemeName.noBackground}
                            onClick={() => setItemToDelete(null)}
                        />
                        <CustomButton
                            text={intl.formatMessage({ id: "common_yes" })}
                            onClick={deleteType}
                        />
                    </Box>
                }
                title={""}
                onClose={() => setItemToDelete(null)}
            />
        </Page>
    )
}
