import { Route, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import Root from "src/routes/root"
import ErrorPage from "src/error-page"

import Login from "src/routes/login"
import { NeedAgreemntRoute, ProtectedRoute } from "./protectedRoute"
import Shops from "src/routes/administration/shops/Shops"
import Reports from "src/routes/administration/reports/reports"
import PackagingLookup from "src/routes/warehouse/packaging-lookup"

import Assortment from "src/routes/account/assortment"
import CompanyInfo from "src/routes/account/company-info"

import WarehouseLocations from "src/routes/account/warehouse-locations/warehouse-locations"
import Fulfill from "src/routes/warehouse/fulfill"
import FulfillOrder from "src/routes/warehouse/fulfill-order"
import WasteManagement from "src/routes/warehouse/waste-management"
import NoAccessPage from "src/routes/no-access-page"
import { OrderPage } from "src/routes/shared/order"
import ShopView from "src/routes/administration/shops/ShopView"
import Orders from "src/routes/administration/orders/orders"

import EditUser from "src/routes/account/users/editUser"
import StockKeeping from "src/routes/warehouse/stock-keeping/stock-keeping"
import LocationPage from "src/routes/account/warehouse-locations/locationPage"
import { StorageCreationProvider } from "src/contexts/StorageCreationProvider"
import StorageUnitPage from "src/routes/warehouse/stock-keeping/storageUnitPage"

import { ShopAssortment } from "src/routes/administration/shops/ShopAssortment"
import FrontPage from "src/routes/frontpage/frontpage"

import { IntlRoute } from "./intlProvider"
import { AccountCheckRoute, AccountCheckRouteAsync } from "./permissionRoute"
import Receive from "src/routes/warehouse/receive/receive"
import DropPoint from "src/routes/dropPoint/dropPoint"
import { SupportPage } from "src/routes/support/support"
import { PostMessageReceiver } from "src/routes/postMessagereceiver"
import DropPointEditPage from "src/routes/dropPoint/dropPointEditPage"
import ShopOrderingPage from "src/routes/shop/orderRezip"
import Account from "src/routes/dropPoint/account"
import OrderHistory from "src/routes/shop/orderHistory"
import ViewShopOrder from "src/routes/shop/viewOrder"
import FileManager from "src/routes/account/file-manager/file-manager"
import { AddFile } from "src/routes/account/file-manager/add-file"
import { Permissions } from "src/routes/account/users/permissions"
import { EmailVerify } from "src/routes/email_verify"
import { UserSettings } from "src/routes/user-settings"
import Settings from "src/routes/account/settings/settings"
import { Vouchers } from "src/routes/shop/vouchers"
import { Inventory } from "src/routes/account/inventory"
import PasswordReset from "src/routes/password-reset"
import { TemplateView } from "src/routes/account/settings/templateView"
import { Stations } from "src/routes/warehouse/stations/stations"
import { ViewStation } from "src/routes/warehouse/stations/viewStation"
import { ActivatePassword } from "src/routes/activatePassword"

import permissionGroups from "./permissions.json"
import { Permission } from "@repo/rezip-client/types"
import { MotherShipPartners } from "src/routes/mothership/partners/partners"
import { ViewPartner } from "src/routes/mothership/partners/viewPartner"
import { Products } from "src/routes/mothership/products/products"
import { ProductGroups } from "src/routes/mothership/productGroups/productGroups"
import { ProductGroupPage } from "src/routes/mothership/productGroups/ProductGroup"
import { Product } from "src/routes/mothership/products/product"
import { UserOverView } from "src/routes/userOverview"
import { AddUser } from "src/routes/addUser"
import { EditUserSelector } from "src/routes/editUserSelector"
import { AccountChanger } from "src/routes/home/accountChanger"
import { Legal } from "src/routes/legal"
import ProductConfiguration from "src/routes/mothership/productConfiguration/productConfiguration"

// le hack to use a context only for these routes
// eslint-disable-next-line react-refresh/only-export-components
const WarehouseRoutes: React.FC = () => (
    <Routes>
        <Route
            index
            element={
                <AccountCheckRoute
                    permissions={permissionGroups.warehouse.storageUnitsOverview as Permission[]}
                    allowedRoles={["Partner"]}
                    element={<StockKeeping />}
                />
            }
        />
        <Route
            path="/create"
            element={
                <AccountCheckRoute
                    permissions={permissionGroups.warehouse.storageUnitCreation as Permission[]}
                    allowedRoles={["Partner"]}
                    element={<StorageUnitPage />}
                />
            }
        />
        <Route
            path="/create/:stationId"
            element={
                <AccountCheckRoute
                    permissions={permissionGroups.warehouse.storageUnitCreation as Permission[]}
                    allowedRoles={["Partner"]}
                    element={<StorageUnitPage />}
                />
            }
        />
        <Route
            path="view/:locationId/unit/:id"
            element={
                <AccountCheckRoute
                    permissions={permissionGroups.warehouse.storageUnitView as Permission[]}
                    allowedRoles={["Partner"]}
                    element={<StorageUnitPage />}
                />
            }
        />
    </Routes>
)

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
            <Route element={<IntlRoute />}>
                <Route path="me/email/verify" element={<EmailVerify />} />
                <Route path="login" element={<Login />} />
                <Route path="__vitest_test__/*" element={<Login />} />
                <Route path="password-reset" element={<PasswordReset />} />
                <Route path="reset-password/activate/:email" element={<ActivatePassword />} />
                <Route path="no-access" element={<NoAccessPage />} />
                <Route path="legal" element={<Legal />} />
                <Route element={<ProtectedRoute />}>
                    <Route element={<NeedAgreemntRoute />}>
                        <Route path="re-zip">
                            <Route path="product-configuration">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            element={<ProductConfiguration />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                            </Route>
                            <Route path="partners">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            element={<MotherShipPartners />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                                <Route
                                    path=":partnerId/view"
                                    element={
                                        <AccountCheckRoute
                                            element={<ViewPartner creation={false} />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AccountCheckRoute
                                            element={<ViewPartner creation={true} />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                            </Route>

                            <Route path="products">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            element={<Products />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                                <Route
                                    path=":id"
                                    element={
                                        <AccountCheckRoute
                                            element={<Product />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AccountCheckRoute
                                            element={<Product />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                            </Route>
                            <Route path="product-groups">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            element={<ProductGroups />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AccountCheckRoute
                                            element={<ProductGroupPage />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                                <Route
                                    path=":id"
                                    element={
                                        <AccountCheckRoute
                                            element={<ProductGroupPage />}
                                            allowedRoles={["RE-ZIP"]}
                                        />
                                    }
                                />
                            </Route>
                        </Route>
                        <Route path="orders">
                            <Route
                                index
                                element={
                                    <AccountCheckRoute
                                        allowedRoles={["Shop"]}
                                        element={<OrderHistory />}
                                    />
                                }></Route>
                            <Route
                                path=":id/view"
                                element={
                                    <AccountCheckRoute
                                        allowedRoles={["Shop"]}
                                        element={<ViewShopOrder />}
                                    />
                                }
                            />
                            <Route
                                path="order-products"
                                element={
                                    <AccountCheckRoute
                                        allowedRoles={["Shop"]}
                                        element={<ShopOrderingPage />}
                                    />
                                }
                            />
                        </Route>
                        <Route path="droppoint">
                            <Route
                                index
                                element={
                                    <AccountCheckRoute
                                        allowedRoles={["DropPoint"]}
                                        element={<DropPoint />}
                                    />
                                }></Route>
                            <Route
                                path=":dropPointId/edit"
                                element={
                                    <AccountCheckRoute
                                        element={<DropPointEditPage />}
                                        allowedRoles={["DropPoint"]}
                                    />
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <AccountCheckRoute
                                        element={<DropPointEditPage />}
                                        allowedRoles={["DropPoint"]}
                                    />
                                }
                            />
                            <Route
                                path="account"
                                element={
                                    <AccountCheckRoute
                                        element={<Account />}
                                        allowedRoles={["DropPoint"]}
                                    />
                                }
                            />
                        </Route>

                        <Route path="account">
                            <Route
                                path="assortment"
                                element={
                                    <AccountCheckRoute
                                        permissions={
                                            permissionGroups.account.assortment as Permission[]
                                        }
                                        allowedRoles={["Partner"]}
                                        element={<Assortment />}
                                    />
                                }
                            />
                            <Route
                                path="company-info"
                                element={
                                    <AccountCheckRoute
                                        permissions={
                                            permissionGroups.account.companyInfo as Permission[]
                                        }
                                        allowedRoles={["Partner"]}
                                        element={<CompanyInfo />}
                                    />
                                }
                            />

                            <Route path="file-manager">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account.fileManager as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<FileManager />}
                                        />
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account
                                                    .fileManagerAdd as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<AddFile />}
                                        />
                                    }
                                />
                                <Route
                                    path="view/:fileId"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account
                                                    .fileManagerview as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<AddFile />}
                                        />
                                    }
                                />
                            </Route>
                            <Route path="settings">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account
                                                    .settingsOverview as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<Settings />}
                                        />
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account.settingsAdd as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<TemplateView />}
                                        />
                                    }
                                />
                                <Route
                                    path="view/:id"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account
                                                    .settingsView as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<TemplateView />}
                                        />
                                    }
                                />
                            </Route>
                            <Route
                                path="inventory"
                                element={
                                    <AccountCheckRoute
                                        permissions={
                                            permissionGroups.account.inventory as Permission[]
                                        }
                                        allowedRoles={["Partner"]}
                                        element={<Inventory />}
                                    />
                                }
                            />

                            <Route path="users">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account.users as Permission[]
                                            }
                                            allowedRoles={["Partner", "Shop", "DropPoint"]}
                                            element={<UserOverView />}
                                        />
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account.usersAdd as Permission[]
                                            }
                                            allowedRoles={["Partner", "Shop", "DropPoint"]}
                                            element={<AddUser />}
                                        />
                                    }
                                />
                                <Route
                                    path="edit/:id"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account.usersEdit as Permission[]
                                            }
                                            allowedRoles={["Partner", "Shop", "DropPoint"]}
                                            element={<EditUser />}
                                        />
                                    }
                                />

                                <Route
                                    path="permissions/:id"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account
                                                    .userPermission as Permission[]
                                            }
                                            allowedRoles={["Partner", "Shop", "DropPoint"]}
                                            element={<Permissions />}
                                        />
                                    }
                                />
                            </Route>
                            <Route path="warehouses">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account.warehouses as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<WarehouseLocations />}
                                        />
                                    }
                                />
                                <Route
                                    path="create"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account
                                                    .warehousesAdd as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<LocationPage />}
                                        />
                                    }
                                />
                                <Route
                                    path=":locationId/view"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.account
                                                    .warehousesEdit as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<LocationPage />}
                                        />
                                    }
                                />
                            </Route>
                        </Route>

                        <Route path="administration">
                            <Route path="orders">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.administration
                                                    .ordersOverview as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<Orders />}
                                        />
                                    }
                                />
                                <Route
                                    path=":orderId"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.administration
                                                    .ordersView as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<OrderPage />}
                                        />
                                    }
                                />
                            </Route>

                            <Route
                                path="reports"
                                element={
                                    <AccountCheckRoute
                                        permissions={
                                            permissionGroups.administration.reports as Permission[]
                                        }
                                        allowedRoles={["Partner", "Shop"]}
                                        element={<Reports />}
                                    />
                                }
                            />

                            <Route path="shops">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.administration
                                                    .shopsOverview as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<Shops />}
                                        />
                                    }
                                />
                                <Route
                                    path="view/:id"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.administration
                                                    .shopView as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<ShopView />}
                                        />
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.administration
                                                    .shopAdd as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<ShopView />}
                                        />
                                    }
                                />
                                <Route
                                    path=":id/assortment"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.administration
                                                    .shopAssortment as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<ShopAssortment />}
                                        />
                                    }
                                />
                            </Route>
                        </Route>

                        <Route path="warehouse">
                            <Route path="fulfill">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.warehouse
                                                    .fulfillOverview as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<Fulfill />}
                                        />
                                    }
                                />
                                <Route
                                    path=":orderId"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.warehouse
                                                    .fulfillPage as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<FulfillOrder />}
                                        />
                                    }
                                />
                            </Route>
                            <Route
                                path="packaging-lookup"
                                element={
                                    <AccountCheckRoute
                                        permissions={
                                            permissionGroups.warehouse.packageLookup as Permission[]
                                        }
                                        allowedRoles={["Partner"]}
                                        element={<PackagingLookup />}
                                    />
                                }
                            />
                            <Route
                                path="receive"
                                element={
                                    <AccountCheckRoute
                                        permissions={
                                            permissionGroups.warehouse.recieve as Permission[]
                                        }
                                        allowedRoles={["Partner"]}
                                        element={<Receive />}
                                    />
                                }
                            />
                            <Route
                                path="storage-units/*"
                                element={
                                    <StorageCreationProvider>
                                        <WarehouseRoutes />
                                    </StorageCreationProvider>
                                }></Route>

                            <Route
                                path="waste-management"
                                element={
                                    <AccountCheckRoute
                                        permissions={
                                            permissionGroups.warehouse
                                                .wasteManagement as Permission[]
                                        }
                                        allowedRoles={["Partner"]}
                                        element={<WasteManagement />}
                                    />
                                }
                            />
                            <Route path="stations">
                                <Route
                                    index
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.warehouse
                                                    .stationsOverview as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<Stations />}
                                        />
                                    }
                                />
                                <Route
                                    path="view/:id"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.warehouse
                                                    .stationView as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<ViewStation />}
                                        />
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AccountCheckRoute
                                            permissions={
                                                permissionGroups.warehouse
                                                    .stationCreation as Permission[]
                                            }
                                            allowedRoles={["Partner"]}
                                            element={<ViewStation />}
                                        />
                                    }
                                />
                            </Route>
                        </Route>
                        <Route
                            path="shop/profile"
                            element={
                                <AccountCheckRouteAsync
                                    allowedRoles={["Shop"]}
                                    lazyElement={() =>
                                        import("../routes/shop/profile").then((x) => x.ShopProfile)
                                    }
                                />
                            }
                        />
                        <Route
                            path="/shop/vouchers"
                            element={
                                <AccountCheckRoute allowedRoles={["Shop"]} element={<Vouchers />} />
                            }
                        />
                        <Route
                            path="support"
                            element={
                                <AccountCheckRoute
                                    allowedRoles={["Shop", "Partner"]}
                                    element={<SupportPage />}
                                />
                            }
                        />
                        <Route path="users">
                            <Route
                                index
                                element={
                                    <AccountCheckRoute
                                        permissions={permissionGroups.account.users as Permission[]}
                                        allowedRoles={["Partner", "Shop", "DropPoint"]}
                                        element={<UserOverView />}
                                    />
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <AccountCheckRoute
                                        permissions={
                                            permissionGroups.account.usersAdd as Permission[]
                                        }
                                        allowedRoles={["Partner", "Shop", "DropPoint"]}
                                        element={<AddUser />}
                                    />
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <AccountCheckRoute
                                        allowedRoles={["Shop", "DropPoint"]}
                                        element={<EditUserSelector />}
                                    />
                                }
                            />
                        </Route>
                    </Route>
                    <Route path="change-account" element={<AccountChanger />} />
                    <Route path="home" element={<FrontPage />} />

                    <Route path="user-settings" element={<UserSettings />} />
                </Route>
            </Route>
            <Route path="remote-access" element={<PostMessageReceiver />} />
        </Route>,
    ),
)

export default router
