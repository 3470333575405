import dayjs from "dayjs"
import { useState } from "react"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import Page from "src/components/layout/Page"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useUser } from "src/contexts/UserConsumer"
import { useNavigate } from "react-router-dom"
import IdCell from "src/components/table/IdCell"
import { useIntl } from "react-intl"
import { getOrderStateTitle } from "src/utils/orderStateTitle"
import { CustomButton } from "src/components/generalComponents/Buttons"
import { OrderShopType } from "@repo/rezip-client/shop_client"
import { AllInOneTable } from "src/components/table/AllInOneTable"
import { useClient } from "src/hooks/useClient.hook"
import { getPercentageOfOrder, getProgressOfOrder } from "src/utils/orderCalcualations"

const OrderHistory = () => {
    const { getSelectedAgreement } = useUser()
    const [initialLoad, setInitialLoad] = useState(true)
    const id = getSelectedAgreement()?.account.id
    const { shopClient } = useClient()
    const intl = useIntl()
    const navigate = useNavigate()

    const itemToRow = (item: OrderShopType, index: number) => (
        <StyledBodyRow key={`order-row-${index}`}>
            <StyledTableCell>{dayjs(item.created_at).format("DD/MM/YYYY HH:mm")}</StyledTableCell>
            <IdCell id={item.id} />
            <StyledTableCell>{getOrderStateTitle(item.state)}</StyledTableCell>
            <StyledTableCell>{getProgressOfOrder(item)}</StyledTableCell>
            <StyledTableCell>{getPercentageOfOrder(item)}</StyledTableCell>
            <StyledTableCell>
                <CustomButton
                    onClick={() => navigate(`${item.id}/view`)}
                    text={intl.formatMessage({ id: "view_order" })}
                />
            </StyledTableCell>
        </StyledBodyRow>
    )

    if (!id) {
        return <></>
    }

    return (
        <Page title={intl.formatMessage({ id: "menu_listitem_orders" })}>
            <DefaultWrapperPaper>
                <AllInOneTable<OrderShopType>
                    headers={[
                        {
                            label: intl.formatMessage({
                                id: "orders_table_order_date",
                            }),
                            key: "created_at",
                            excludeFromSearch: true,
                        },
                        {
                            label: intl.formatMessage({ id: "orders_order_no" }),
                            key: "id",
                            excludeFromSearch: true,
                        },
                        {
                            label: intl.formatMessage({ id: "orders_status" }),
                            key: "state",
                            excludeFromSearch: true,
                        },
                        {
                            key: "progress",
                            label: intl.formatMessage({
                                id: "common_progress",
                            }),
                            excludeFromSearch: true,
                        },
                        {
                            key: "percentage",
                            label: intl.formatMessage({
                                id: "common_percentage",
                            }),
                            excludeFromSearch: true,
                        },
                        { label: "", key: "actions", excludeFromSearch: true },
                    ]}
                    defaultSortBy="created_at"
                    fetchItems={async (o) => {
                        return await shopClient.getOrders(id, o)
                    }}
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    itemsToRow={itemToRow}
                    addFunction={() => navigate("order-products")}
                    addButtonText={intl.formatMessage({ id: "place_order_button" })}
                    orderDirection="desc"
                />
            </DefaultWrapperPaper>
        </Page>
    )
}

export default OrderHistory
