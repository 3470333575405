import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { CustomButton } from "src/components/generalComponents/Buttons"

import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { useUser } from "src/contexts/UserConsumer"
import { useClient } from "src/hooks/useClient.hook"
import { UserAgreementAccount } from "@repo/rezip-client/types"
import { useCustomBlocker } from "src/utils/useCustomBlocker"
import { EditUserComponent } from "src/components/EditUserComponent"
import Page from "src/components/layout/Page"

const EditUser = () => {
    const { getToken, getSelectedAgreement } = useUser()
    const partnerId = getSelectedAgreement()?.account.id
    const navigate = useNavigate()
    const intl = useIntl()
    const { id } = useParams()
    const [agreement, setAgreement] = useState<UserAgreementAccount>()
    const { partnerClient } = useClient()

    const { enqueueAlert } = useSnackbar()
    const { setBlock, BlockerAlert } = useCustomBlocker()

    useEffect(() => {
        if (!partnerId || !id) {
            return
        }
        if (!agreement) {
            const fetchData = async () => {
                const data = await partnerClient.getUserAgreement(partnerId, id)
                if (data) {
                    setAgreement(data)
                }
            }
            fetchData()
        }
    }, [agreement, getToken, id, partnerClient, partnerId])

    const updateUser = useCallback(async () => {
        if (!agreement || !partnerId || !id) {
            return
        }
        const data = await partnerClient.patchUserAgreement(
            partnerId,
            id,
            agreement?.owner,
            agreement?.mfa_required,
        )
        if (data) {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_message_user_updated",
                }),
                "success",
            )

            setAgreement(data)
            setBlock(false)
        } else {
            enqueueAlert(
                intl.formatMessage({
                    id: "toast_message_something_went_Wrong",
                }),
                "error",
            )
        }
    }, [agreement, enqueueAlert, id, intl, partnerClient, partnerId, setBlock])

    return (
        <Page
            title={intl.formatMessage({
                id: "users_edit_user",
            })}
            backButton>
            <Box
                sx={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                }}>
                <EditUserComponent
                    agreement={agreement}
                    setAgreement={setAgreement}
                    setBlock={setBlock}
                    updateUser={updateUser}
                    partnerAccount={true}
                    optionalComponent={
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingBottom: "10px",
                            }}>
                            <Box>
                                <CustomButton
                                    text={intl.formatMessage({
                                        id: "users_go_to_permissions",
                                    })}
                                    onClick={() => navigate(`../permissions/${id}`)}
                                />
                            </Box>
                        </Box>
                    }
                />
            </Box>
            <BlockerAlert />
        </Page>
    )
}

export default EditUser
