export class ClientError extends Error {
    status: number
    body: object
    url: string
    constructor(status: number, body: object, url: string) {
        super(`${url} ${status} ${JSON.stringify(body)}`)
        this.status = status
        this.body = body
        this.url = url
        this.name = "ClientError"
        Object.setPrototypeOf(this, new.target.prototype)
    }
}

export class AuthenticationError extends ClientError {
    constructor(url: string) {
        super(401, { message: "Authentication failed" }, url)
    }
}
