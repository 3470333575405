import { z } from "zod"
import { baseApiErrorHandling, ReZipClient } from "."
import { PermissionGroup, ULID } from "./types"

export class PermissionGroupClient {
    rezipClient: ReZipClient
    prefix: string
    constructor(rezipClient: ReZipClient, prefix: string) {
        this.rezipClient = rezipClient
        this.prefix = prefix
    }

    async get(id: ULID): Promise<PermissionGroup | null> {
        const response = await this.rezipClient.get({
            path: `${this.prefix}/permission_groups/${id}`,
        })
        if (response.status === 404) {
            return null
        }
        //await baseApiErrorHandling(response)
        const body = await response.json()
        return PermissionGroup.parse(body)
    }

    async getAll(): Promise<PermissionGroup[]> {
        const response = await this.rezipClient.get({ path: `${this.prefix}/permission_groups` })
        if (response.status === 404) {
            return []
        }
        await baseApiErrorHandling(response)
        const body = await response.json()
        return z.array(PermissionGroup).parse(body)
    }

    async addAgreement({
        groupId,
        agreementId,
    }: {
        groupId: string
        agreementId: string
    }): Promise<boolean> {
        const response = await this.rezipClient.post({
            path: `${this.prefix}/permission_groups/${groupId}/user_agreement/${agreementId}`,
            body: "",
        })
        await baseApiErrorHandling(response)
        return true
    }

    async removeAgreement({
        groupId,
        agreementId,
    }: {
        groupId: string
        agreementId: string
    }): Promise<boolean> {
        const response = await this.rezipClient.delete({
            path: `${this.prefix}/permission_groups/${groupId}/user_agreement/${agreementId}`,
            body: "",
        })
        await baseApiErrorHandling(response)
        return true
    }
}
