import * as React from "react"
import Grid from "@mui/material/Grid"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import MyLocationIcon from "@mui/icons-material/MyLocation"
import Menu from "@mui/material/Menu"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Logout from "@mui/icons-material/Logout"
import { useUser } from "src/contexts/UserConsumer"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle"
import SettingsIcon from "@mui/icons-material/Settings"
import { useCallback } from "react"
import { MenuItem, styled, Typography } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import { ExpandMore } from "@mui/icons-material"
import { Location } from "@repo/rezip-client/partner_client"

import { useClient } from "src/hooks/useClient.hook"
import DescriptionIcon from "@mui/icons-material/Description"
import { ItemIcon } from "../styles"

const AccountMenuItem = styled(MenuItem)({
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "flex-start",
})

export default function AccountMenu() {
    const {
        getUser,
        getSelectedAgreement,
        logout,
        imposter,
        getSelectedLocationId,
        getSelectedAccountType,
    } = useUser()
    const { partnerClient } = useClient()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const userAgreements = getUser()?.agreements
    const intl = useIntl()
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const accountType = getSelectedAccountType()
    const locationId = getSelectedLocationId()
    const [location, setLocation] = React.useState<Location>()
    const partnerId = getSelectedAgreement()?.account.id

    React.useEffect(() => {
        if (
            (!location && accountType === "Partner" && locationId && partnerId) ||
            (location &&
                location.id !== locationId &&
                accountType === "Partner" &&
                locationId &&
                partnerId)
        ) {
            const fetchLocation = async () => {
                const partnerId = getSelectedAgreement()?.account.id
                if (!partnerId) {
                    return
                }
                const data = await partnerClient.getLocation(partnerId, locationId)
                if (data) {
                    setLocation(data)
                }
            }
            fetchLocation()
        }
    }, [accountType, getSelectedAgreement, partnerClient, location, locationId, partnerId])

    const navigate = useNavigate()
    const handleClose = () => {
        setAnchorEl(null)
    }
    const endSession = useCallback(() => {
        logout()
        window.close()
    }, [logout])

    return (
        <>
            <Grid container alignItems="center" sx={{ textAlign: "center" }}>
                <Tooltip
                    title={intl.formatMessage({
                        id: "account_menu_account_settings",
                    })}>
                    <IconButton
                        onClick={handleClick}
                        sx={{
                            ml: 2,
                            borderRadius: "10px",
                            "&:hover": {
                                backgroundColor: "unset",
                            },
                        }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}>
                        {getUser()?.name ? (
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "text.primary",
                                    marginRight: "20px",
                                }}>
                                {getUser()?.name}
                            </Typography>
                        ) : (
                            ""
                        )}
                        <PersonIcon />
                        <ExpandMore />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                {!imposter ? (
                    <Grid>
                        <AccountMenuItem disabled>
                            <ItemIcon>
                                <AssignmentIndIcon fontSize="small" />
                            </ItemIcon>
                            {getSelectedAgreement()?.account.name ?? "-"}
                        </AccountMenuItem>
                        {location && accountType === "Partner" ? (
                            <AccountMenuItem disabled>
                                <ItemIcon>
                                    <MyLocationIcon fontSize="small" />
                                </ItemIcon>
                                {location?.name ?? "-"}
                            </AccountMenuItem>
                        ) : null}

                        <Divider />
                        {userAgreements && userAgreements.length > 1 ? (
                            <AccountMenuItem onClick={() => navigate("/change-account")}>
                                <ItemIcon>
                                    <ChangeCircleIcon fontSize="small" />
                                </ItemIcon>
                                <FormattedMessage id="change_account" />
                            </AccountMenuItem>
                        ) : null}

                        <AccountMenuItem onClick={() => navigate("/user-settings")}>
                            <ItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ItemIcon>
                            <FormattedMessage id="user_settings" />
                        </AccountMenuItem>
                        <AccountMenuItem
                            onClick={() => {
                                window.open("https://api.re-zip.com/docs/", "_blank")
                            }}>
                            <ItemIcon>
                                <DescriptionIcon fontSize="small" />
                            </ItemIcon>
                            <FormattedMessage
                                id="account_menu_documentation"
                                defaultMessage="Documentation"
                            />
                        </AccountMenuItem>

                        <AccountMenuItem onClick={logout}>
                            <ItemIcon>
                                <Logout fontSize="small" />
                            </ItemIcon>
                            <FormattedMessage id="common_logout" />
                        </AccountMenuItem>
                    </Grid>
                ) : (
                    <AccountMenuItem onClick={endSession}>
                        <ItemIcon>
                            <Logout fontSize="small" />
                        </ItemIcon>
                        <FormattedMessage id="common_end_session" />
                    </AccountMenuItem>
                )}
            </Menu>
        </>
    )
}
