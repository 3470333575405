import { useIntl } from "react-intl"
import Page from "src/components/layout/Page"
import { useNavigate } from "react-router-dom"
import useStockKeepingController from "./stockKeeping.controller"
import { DefaultWrapperPaper } from "src/components/layout/DefaultPageComponents"
import IdCell from "src/components/table/IdCell"

import { AllInOneTable } from "src/components/table/AllInOneTable"
import { StorageUnit } from "@repo/rezip-client/partner_client"
import { StyledBodyRow, StyledTableCell } from "src/components/table/TableComponents"
import { useStorageCreation } from "src/contexts/StorageCreationConsumer"
import { Box, Typography } from "@mui/material"
import { CustomHelp } from "src/components/generalComponents/CustomHelp"
import { CustomButton } from "src/components/generalComponents/Buttons"

export default function StockKeeping() {
    const intl = useIntl()
    const navigate = useNavigate()
    const { locations, scanBarcode, fetchItems, initialLoad, setInitialLoad } =
        useStockKeepingController()
    const { setSearchTerm } = useStorageCreation()
    const storageUnitToRow = (su: StorageUnit, index: number) => {
        const locationName = locations?.find((l) => l.id === su.location_id)
        return (
            <StyledBodyRow key={index}>
                <StyledTableCell>{locationName?.name ?? ""}</StyledTableCell>
                <IdCell id={su.barcode} />
                <StyledTableCell>{su.sku}</StyledTableCell>
                <StyledTableCell>
                    {su.quantity} / {su.size}
                </StyledTableCell>
                <StyledTableCell>{su.type}</StyledTableCell>
                <StyledTableCell>
                    <CustomButton
                        text={intl.formatMessage({
                            id: "stockkeeping_view",
                        })}
                        onClick={() => {
                            setSearchTerm(su.barcode)
                            navigate(
                                `/warehouse/storage-units/view/${su.location_id}/unit/${su.id}`,
                            )
                        }}
                    />
                </StyledTableCell>
            </StyledBodyRow>
        )
    }

    return (
        <Page
            title={intl.formatMessage({
                id: "menu_listitem_storage_units",
            })}>
            <DefaultWrapperPaper>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
                    <Typography variant="h4">
                        {intl.formatMessage({ id: "stock_keeping_title" })}
                    </Typography>
                    <CustomHelp title={intl.formatMessage({ id: "stock_keeping_tooltip" })} />
                </Box>
                <AllInOneTable<StorageUnit>
                    headers={[
                        {
                            key: "location",
                            label: intl.formatMessage({
                                id: "stockkeeping_location",
                            }),

                            excludeFromSearch: true,
                        },
                        {
                            key: "barcode",
                            label: intl.formatMessage({
                                id: "stockkeeping_barcode",
                            }),
                        },
                        {
                            key: "sku",
                            label: intl.formatMessage({
                                id: "stockkeeping_sku",
                            }),
                        },
                        {
                            key: "progress",
                            label: intl.formatMessage({
                                id: "stockkeeping_progess",
                            }),
                            excludeFromSearch: true,
                        },
                        {
                            key: "type",
                            label: intl.formatMessage({
                                id: "stockkeeping_type",
                            }),
                            excludeFromSearch: true,
                        },
                        { key: "actions", label: "", excludeFromSearch: true },
                    ]}
                    onSubmit={scanBarcode}
                    fetchItems={fetchItems}
                    itemsToRow={storageUnitToRow}
                    defaultSortBy="id"
                    initialLoad={initialLoad}
                    setInitialLoad={setInitialLoad}
                    addFunction={() => navigate("create")}
                />
            </DefaultWrapperPaper>
        </Page>
    )
}
