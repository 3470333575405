export const softPink = "#ffd2d9"
export const warmCoral = "#ff826f"
export const cozyPurple = "#1D1D3C"
export const softBlack = "#283228"
export const softBlue = "#d0e7dd"
export const softGreen = "#dff9b8"
export const actionGreen = "#9FE870"
export const ecoGreen = "#00443c"
export const softSand = "#f4f1e6"
export const backgroundColor = "#FAFAFF"
export const ecoGreenLight = "#00443C80"
export const mutedGreen = "#EDF4E9"
export const borderGray = "#E6E6E6"
export const lightGray = "#FAFAFA"
export const lightBlue = "#FAFAFF"
export const warningColor = "#F06F5B"
// Dark Mode colors
export const dmDark = "#1A1A1A"
export const dmLight = "#2B2D31"
export const dmMediumDark = "#1E1F22"
export const dmText = "#DBE4E9"
