import { IMenuItemData } from "src/components/SelectField"
import { useUser } from "src/contexts/UserConsumer"

import { useCallback, useEffect, useMemo, useState } from "react"
import { useSnackbar } from "src/contexts/SnackbarConsumer"
import { SelectChangeEvent } from "@mui/material"
import { useIntl } from "react-intl"
import weekOfYear from "dayjs/plugin/weekOfYear"
import { useReportsHelper } from "./reports.fetches"

import {
    DAY,
    defaultScanData,
    defualtSummary,
    EnvironmentData,
    item,
    MapOfItems,
    MONTH,
    QUATER,
    SimpleProducts,
    SummedTotals,
    WEEK,
    YEAR,
} from "./reports.types"
import dayjs from "dayjs"
import { cleanDate, labelsBasedOnTime, mapEnvStats } from "./reports.helpers"
import { PartnerClient, Shop } from "@repo/rezip-client/partner_client"
import { useClient } from "src/hooks/useClient.hook"
import { downloadBlob } from "src/utils/downloadBlob"
import { ShopClient } from "@repo/rezip-client/shop_client"

dayjs.extend(weekOfYear)

export const useReportController = () => {
    const [initialLoad, setInitialLoad] = useState(true)
    const [dateUpdated, setDateUpdated] = useState(false)
    const { getSelectedAgreement, getSelectedAccountType } = useUser()
    const accountType = getSelectedAccountType()
    const { partnerClient, selectedClient } = useClient()
    const selectedAgreement = getSelectedAgreement()

    const [summary, setSummary] = useState(defualtSummary)
    const [scanData, setScanData] = useState(defaultScanData)
    const [summaryTableData, setSummaryTableData] = useState<item[]>([])
    const { enqueueAlert } = useSnackbar()
    const intl = useIntl()
    const [envStats, setEnvStats] = useState<EnvironmentData>()
    const [summedEnvStats, setSummedEnvStats] = useState<SummedTotals>()
    const [labels, setLabels] = useState<string[]>()
    const { fetchEnvData } = useReportsHelper()
    const [simpleProducts, setSimpleProducts] = useState<SimpleProducts[]>()
    const [graphsReady, setGraphsReady] = useState(false)

    // State checks for effects
    const [shopsFetched, setShopsFetched] = useState(false)
    const [productsFetched, setProductsFetched] = useState(false)
    const [initialSummaryFetched, setInitialSummaryFetched] = useState(false)
    const [envDataFetched, setEnvDataFetched] = useState(false)
    const [labelsInitialized, setLabelsInitialized] = useState(false)

    const [shops, setShops] = useState<Shop[] | null>()

    const accounts: IMenuItemData[] = useMemo(() => {
        return [
            { id: "All", value: intl.formatMessage({ id: "common_all", defaultMessage: "All" }) },
        ]
    }, [intl])

    const dropDownShop = useMemo(() => {
        const items = (shops ?? []).map((shop) => {
            return { id: shop.id, value: shop.name } as IMenuItemData
        })
        return accounts.concat(items)
    }, [shops, accounts])

    const scanTypes: IMenuItemData[] = useMemo(() => {
        return [
            {
                id: "placeholder",
                value: intl.formatMessage({
                    id: "reports_select_type_placeholder",
                }),
            },
            { id: "All", value: intl.formatMessage({ id: "common_all", defaultMessage: "All" }) },
            {
                id: "Fulfill",
                value: intl.formatMessage({ id: "common_fulfilled", defaultMessage: "Fulfilled" }),
            },
            {
                id: "Bundle",
                value: intl.formatMessage({ id: "common_bundle", defaultMessage: "Bundle" }),
            },
            {
                id: "Reward",
                value: intl.formatMessage({ id: "common_rewards", defaultMessage: "Rewards" }),
            },
            {
                id: "Waste",
                value: intl.formatMessage({ id: "common_waste", defaultMessage: "Waste" }),
            },
        ]
    }, [intl])

    const timeSelction: IMenuItemData[] = useMemo(() => {
        return [
            {
                id: `${DAY}`,
                value: intl.formatMessage({ id: "reports_days", defaultMessage: "Days" }),
            },
            {
                id: `${WEEK}`,
                value: intl.formatMessage({ id: "reports_weeks", defaultMessage: "Weeks" }),
            },
            {
                id: `${MONTH}`,
                value: intl.formatMessage({ id: "reports_months", defaultMessage: "Months" }),
            },
            {
                id: `${QUATER}`,
                value: intl.formatMessage({ id: "reports_quaters", defaultMessage: "Quaters" }),
            },
            {
                id: `${YEAR}`,
                value: intl.formatMessage({ id: "reports_years", defaultMessage: "Years" }),
            },
        ]
    }, [intl])

    const [selectedTime, setSelectedTime] = useState<IMenuItemData>(timeSelction[2])

    const getSummary = useCallback(
        async (startDate: Date, endDate: Date, account: string) => {
            if (!selectedAgreement) {
                return
            }
            if (getSelectedAccountType() === "Shop") {
                setInitialSummaryFetched(true)
                return
            }

            const items = await partnerClient.scansSummary({
                partnerId: selectedAgreement.account.id,
                from: startDate,
                to: endDate,
                shopId: account === "All" ? undefined : account,
            })

            const mapOfItems: MapOfItems = {}

            items.forEach((element) => {
                mapOfItems[element.sku] = { reclaim: 0, fulfill: 0, reward: 0, waste: 0 }
                element.items.forEach((item) => {
                    switch (item.type) {
                        case "Reclaim":
                            mapOfItems[element.sku].reclaim += item.scans
                            break
                        case "Fulfill":
                            mapOfItems[element.sku].fulfill += item.scans
                            break
                        case "Reward":
                            mapOfItems[element.sku].reward += item.scans
                            break
                        case "Waste":
                            mapOfItems[element.sku].waste += item.scans
                            break
                        default:
                            console.warn(`Unknown type: ${item.type}`)
                    }
                })
            })

            setSummaryTableData(
                Object.entries(mapOfItems).map(([sku, stats]) => ({
                    sku,
                    ...stats,
                })),
            )
            setInitialSummaryFetched(true)
            setInitialLoad(true)
        },
        [getSelectedAccountType, partnerClient, selectedAgreement],
    )

    const getScansCSV = useCallback(async () => {
        if (
            !selectedAgreement ||
            !scanData.type ||
            !scanData.accounts ||
            !scanData.endDate ||
            !scanData.startDate
        ) {
            return enqueueAlert(intl.formatMessage({ id: "toast_something_went_wrong" }), "error")
        }

        const collectedCSV: string[] = []

        const fetchScans = async (pageFrom: string | null = null) => {
            console.log("called")
            if (
                !selectedAgreement ||
                !scanData.type ||
                !scanData.accounts ||
                !scanData.endDate ||
                !scanData.startDate
            ) {
                return enqueueAlert(
                    intl.formatMessage({ id: "toast_something_went_wrong" }),
                    "error",
                )
            }

            const response = await partnerClient.scansRawCsv({
                partnerId: selectedAgreement.account.id,
                from: scanData.startDate,
                to: scanData.endDate,
                shopId: scanData.accounts === "All" ? undefined : scanData.accounts,
                type: scanData.type === "All" ? undefined : scanData.type,
                options: { pageFrom: pageFrom },
            })

            if (response) {
                const textData = await response.text()
                const lines = textData.split("\n").filter((line) => line.trim() !== "") // Remove empty lines

                if (collectedCSV.length === 0 && lines.length > 0) {
                    // Only add headers from the first response
                    collectedCSV.push(lines[0])
                }

                // Add CSV rows, skipping the header in subsequent pages
                if (lines.length > 1) {
                    collectedCSV.push(...lines.slice(1).filter((line) => line.trim() !== ""))
                }

                if (lines.length >= 1001) {
                    const lastLine = lines[lines.length - 1] // Get the last non-empty line
                    console.log("Last valid line:", lastLine)

                    const lastItemId = lastLine.split(";")[0] // Assuming first column is the ID
                    console.log("Fetching next page with lastItemId:", lastItemId)

                    await fetchScans(lastItemId)
                }
            }
        }

        await fetchScans()
        const finalCSV = collectedCSV.join("\n")

        // Convert into a Blob for download
        const finalBlob = new Blob([finalCSV], { type: "text/csv;charset=utf-8;" })

        // Trigger file download
        downloadBlob(
            `Scan-Data-${scanData.accounts}-${
                scanData.type
            }-${scanData.startDate.toLocaleDateString()}-${scanData.endDate.toLocaleDateString()}.csv`,
            finalBlob,
        )

        enqueueAlert(intl.formatMessage({ id: "reports_search_successful" }), "success")
    }, [
        enqueueAlert,
        intl,
        partnerClient,
        scanData.accounts,
        scanData.endDate,
        scanData.startDate,
        scanData.type,
        selectedAgreement,
    ])

    const onTypeChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const item = scanTypes.find((item) => item.id === event.target.value)
            if (item) setScanData((prev) => ({ ...prev, type: item.id }))
        },
        [scanTypes],
    )

    const updateData = useCallback(
        async (
            startDate: Date,
            endDate: Date,
            timeLabels: string[],
            interval: number,
            selectedAccount: string,
        ) => {
            const data = await fetchEnvData(
                startDate,
                endDate,
                timeLabels,
                parseInt(selectedTime.id),
                getSelectedAccountType() === "Shop" ? selectedAgreement?.id : selectedAccount,
            )
            await getSummary(startDate, endDate, selectedAccount)

            if (data && simpleProducts) {
                const mappedData = mapEnvStats(timeLabels, data.each, interval, simpleProducts)
                setSummedEnvStats(data.sum)
                setEnvStats(mappedData)
            }
            setGraphsReady(true)
        },
        [
            fetchEnvData,
            getSelectedAccountType,
            getSummary,
            selectedAgreement?.id,
            selectedTime.id,
            simpleProducts,
        ],
    )

    const onSummaryShopChange = useCallback(
        async (id: SelectChangeEvent<unknown>, isScan?: boolean) => {
            if (isScan) {
                const item = dropDownShop.find((item) => item.id === id.target.value)
                if (item) {
                    setScanData({ ...scanData, accounts: item.id })
                }
            } else {
                const item = dropDownShop.find((item) => item.id === id.target.value)
                if (item) {
                    setSummary({ ...summary, accounts: item.id })

                    if (labels) {
                        const data = await fetchEnvData(
                            summary.startDate,
                            summary.endDate,

                            labels,
                            parseInt(selectedTime.id),
                            item.id,
                        )
                        if (data && simpleProducts) {
                            setSummedEnvStats(data.sum)
                            const mappedData = mapEnvStats(
                                labels,
                                data.each,
                                parseInt(selectedTime.id),
                                simpleProducts,
                            )

                            updateData(
                                summary.startDate,
                                summary.endDate,
                                labels,
                                parseInt(selectedTime.id),
                                item.id,
                            )
                            setEnvStats(mappedData)
                        }
                    }

                    setGraphsReady(true)
                }
            }
        },
        [
            dropDownShop,
            fetchEnvData,
            labels,
            scanData,
            selectedTime.id,
            simpleProducts,
            summary,
            updateData,
        ],
    )

    const correctedSummaryData = useMemo(() => {
        if (!summaryTableData || !envStats) {
            return [] as item[]
        }
        // change this if some one disagrees with this way of counting reclaimed
        return summaryTableData.map((item) => {
            if (envStats.perSku[item.sku]) {
                return {
                    ...item,
                } as item
            } else {
                return {
                    ...item,
                } as item
            }
        })
    }, [envStats, summaryTableData])

    const updateIntervalType = useCallback(
        (startDate: Date, endDate: Date) => {
            if (endDate && startDate) {
                const diffInMs = endDate.getTime() - startDate.getTime()
                const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

                let newIntervalType = ""
                if (diffInDays < 30) {
                    newIntervalType = `${DAY}`
                } else if (diffInDays <= 30 * 6) {
                    newIntervalType = `${WEEK}`
                } else if (diffInDays <= 30 * 12 * 2) {
                    newIntervalType = `${MONTH}`
                } else if (diffInDays <= 30 * 12 * 4) {
                    newIntervalType = `${QUATER}`
                } else {
                    newIntervalType = `${YEAR}`
                }

                const obj = timeSelction.find((item) => item.id === newIntervalType)

                if (obj) {
                    setSelectedTime(obj)
                    const timeLabels = labelsBasedOnTime(obj.id, startDate, endDate)
                    setLabels(timeLabels)
                    // we wait for the labels to be computed, then we find the data
                    if (timeLabels) {
                        updateData(
                            startDate,
                            endDate,
                            timeLabels,
                            parseInt(obj.id),
                            summary.accounts,
                        )
                    }
                }
            }
        },
        [summary.accounts, timeSelction, updateData],
    )

    useEffect(() => {
        if (!shopsFetched && selectedAgreement) {
            if (accountType === "Shop") {
                setShopsFetched(true)
                return
            }
            if (accountType === "Partner") {
                const fetchShops = async () => {
                    try {
                        const data = await partnerClient.getShops(selectedAgreement.account.id, {
                            pageSize: 9999999,
                        })
                        setShops(data)
                    } finally {
                        setShopsFetched(true)
                    }
                }
                fetchShops()
            }
        }
    }, [shopsFetched, selectedAgreement, accountType, partnerClient])

    // Fetch products only after shops are fetched.
    useEffect(() => {
        if (shopsFetched && !productsFetched && selectedAgreement && accountType) {
            const fetchProducts = async () => {
                try {
                    if (
                        selectedClient instanceof ShopClient ||
                        selectedClient instanceof PartnerClient
                    ) {
                        const result = await selectedClient.getProducts(
                            selectedAgreement.account.id,
                        )
                        if (result) {
                            setSimpleProducts(result)
                        }
                    }
                } finally {
                    setProductsFetched(true)
                }
            }
            fetchProducts()
        }
    }, [productsFetched, shopsFetched, selectedAgreement, accountType, selectedClient])

    // Set labels based on the time selection once shops and products are ready.
    useEffect(() => {
        if (
            productsFetched &&
            shopsFetched &&
            !labelsInitialized &&
            summary.startDate &&
            summary.endDate
        ) {
            const generatedLabels = labelsBasedOnTime(
                selectedTime.id,
                summary.startDate,
                summary.endDate,
            )
            setLabels(generatedLabels)
            setLabelsInitialized(true)
        }
    }, [productsFetched, shopsFetched, labelsInitialized, selectedTime, summary])

    // Fetch initial summary once initial scans are done.
    useEffect(() => {
        if (labelsInitialized && !initialSummaryFetched && summary.startDate && summary.endDate) {
            const fetchInitialSummary = async () => {
                await getSummary(summary.startDate, summary.endDate, summary.accounts)
            }
            fetchInitialSummary()
        }
    }, [getSummary, initialSummaryFetched, labelsInitialized, summary])

    // Fetch environmental data once summary is done.
    useEffect(() => {
        if (
            initialSummaryFetched &&
            !envDataFetched &&
            summary.startDate &&
            summary.endDate &&
            labels &&
            simpleProducts
        ) {
            const selectedAccount = getSelectedAccountType()
            const fetchEnvStats = async () => {
                const data = await fetchEnvData(
                    summary.startDate,
                    summary.endDate,
                    labels,
                    parseInt(selectedTime.id),
                    selectedAccount === "Shop" ? selectedAgreement?.id : summary.accounts,
                )
                if (data) {
                    setSummedEnvStats(data.sum)
                    const mappedData = mapEnvStats(
                        labels,
                        data.each,
                        parseInt(selectedTime.id),
                        simpleProducts,
                    )

                    setEnvStats(mappedData)
                }
                setEnvDataFetched(true)
                setGraphsReady(true)
            }
            fetchEnvStats()
        }
    }, [
        initialSummaryFetched,
        envDataFetched,
        summary,
        labels,
        selectedTime,
        fetchEnvData,
        simpleProducts,
        getSelectedAccountType,
        selectedAgreement,
    ])

    const onStartDateChange = useCallback(
        (date: Date) => {
            if (!summary.endDate) {
                return
            }
            setGraphsReady(false)
            const newDate = cleanDate(date)
            setSummary((prevSummary) => ({
                ...prevSummary,
                startDate: newDate,
            }))
            setDateUpdated(true)
            updateIntervalType(newDate, summary.endDate)
        },
        [summary.endDate, updateIntervalType],
    )

    const onEndDateChange = useCallback(
        (date: Date) => {
            if (!summary.startDate) {
                return
            }
            setGraphsReady(false)
            const newDate = cleanDate(date)

            setSummary((prevSummary) => ({
                ...prevSummary,
                endDate: newDate,
            }))
            setDateUpdated(true)
            updateIntervalType(summary.startDate, newDate)
        },
        [summary.startDate, updateIntervalType],
    )

    return {
        onTypeChange,
        onSummaryShopChange,
        getSummary,
        summaryTableData,
        setSummaryTableData,
        setSummary,
        summary,
        scanData,
        setScanData,
        dropDownShop,
        scanTypes,
        timeSelction,
        selectedTime,
        onStartDateChange,
        onEndDateChange,
        envStats,
        labels,
        simpleProducts,
        graphsReady,
        correctedSummaryData,
        summedEnvStats,
        setDateUpdated,
        dateUpdated,
        initialLoad,
        setInitialLoad,
        getScansCSV,
    }
}
