import { Navigate, Outlet } from "react-router-dom"
import { useUser } from "src/contexts/UserConsumer"

// none of these are routes, they are more like wrappers or even helpers to protect the page
// but they dont serve the purpose of routing the user, they just redicret if a param isnt met
// might have to rename

export const ProtectedRoute = () => {
    const { getUser, getToken } = useUser()
    const t = getToken()
    const u = getUser()

    if (t === "" || u == null) {
        // If both the token/user are missing and no valid cookie, redirect to login
        return <Navigate to="/login" replace />
    }

    return <Outlet />
}

export const NeedAgreemntRoute = () => {
    const { getSelectedAgreement } = useUser()
    const agreement = getSelectedAgreement()
    if (!agreement) {
        return <Navigate to={"/change-account"} />
    }

    return <Outlet />
}
