import { Card, styled, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import { PlayCircle } from "@mui/icons-material"
import { ReactComponent as SupportPlantSvg } from "src/assets/images/support_plant.svg"
import { useIntl } from "react-intl"
import { softBlack } from "../colors"
interface SupportCardProps {
    pdf?: boolean
    title: string
    downloadLink?: string
    videoLink?: string
    setVideoLink?: (link: string) => void
}

const StyledLink = styled("a")({
    fontSize: "14px",
    color: softBlack,
    textDecoration: "underline",

    "&:visited": {
        color: "inherit" /* Keep the same color after visiting */,
    },

    "&:active": {
        color: "inherit" /* Maintain the same color while clicking */,
    },
})

const StyledPlayCircle = styled(PlayCircle)({
    fontSize: 70,
    color: "grey",
    cursor: "pointer",
    "&:hover": {
        color: "black",
    },
})

export const SupportCard = (props: SupportCardProps) => {
    const intl = useIntl()
    const { pdf, title, downloadLink, videoLink, setVideoLink } = props

    return (
        <Card
            sx={{
                borderRadius: 2,
                boxShadow: 1,
                overflow: "hidden",
                height: "100%",
                maxWidth: "500px",
                color: softBlack,
            }}>
            <Grid container sx={{ backgroundColor: "#f9f8f7", position: "relative" }}>
                {videoLink && setVideoLink && (
                    <Grid
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1,
                        }}>
                        <StyledPlayCircle onClick={() => setVideoLink(videoLink)} />
                    </Grid>
                )}
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: "100%",
                        padding: "0 0 0 16px",
                        maxHeight: "175px",
                        flexWrap: "nowrap",
                    }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        sx={{ height: "100%" }}>
                        {pdf && (
                            <Grid container direction="column">
                                <Grid
                                    container
                                    alignItems="center"
                                    alignContent="center"
                                    spacing={1}>
                                    <PictureAsPdfIcon />
                                    <Typography variant="caption" sx={{ color: "text.secondary" }}>
                                        {intl.formatMessage({ id: "common_pdf_guide" })}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                        {title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container direction="column" alignSelf="flex-end">
                        {/* Example for a placeholder image or icon */}
                        <SupportPlantSvg height={175} width={"auto"} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                direction="column"
                sx={{ backgroundColor: "#f3e7dc", p: 2, height: "100%" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {title}
                </Typography>
                {pdf && (
                    <StyledLink target="about:blank" href={downloadLink}>
                        {intl.formatMessage({ id: "common_download_pdf_guide" })}
                    </StyledLink>
                )}
            </Grid>
        </Card>
    )
}
