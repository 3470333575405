// only import this when needed cause its fucking huge
let XLSX: Promise<typeof import("xlsx")> | undefined = undefined

const getXLSX = async () => {
    if (XLSX) {
        return await XLSX
    }
    const x = import("xlsx")
    XLSX = x
    return await x
}

export const getEveryExcelNode = async (buffer: ArrayBuffer) => {
    const sheet = (await getXLSX()).read(buffer)
    const result: { value: string; cell: string; sheet: string }[] = []
    Object.entries(sheet.Sheets).forEach(([sheetName, sheet]) => {
        Object.entries(sheet).forEach(([cell, value]) => {
            if (cell.startsWith("!")) {
                return
            }
            result.push({ sheet: sheetName, cell, value: value.w })
        })
    })
    return result
}
